import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { saveAs } from 'file-saver';
import { Cacheable } from 'ts-cacheable';

import { AccessTokenService } from '@common/modules/auth/services/access-token.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';

import { ApiService } from '../../../api/services/api.service';
import { formatFileName } from '../../../utils/string';
import { resources } from './resources';
import { DownloadClosedCaptionsComponent } from './components/download-closed-captions/download-closed-captions.component';
import { DownloadCaptionType, IDownloadCaption, UIDownloadActionType } from './interfaces';
import { IAction } from '../../interfaces';
import { DataService } from '../../services/data.service';
import { DialogService } from '../dialog/dialog.service';
import { IDialogData, IDialogEvent } from '../dialog/interfaces';
import { IDownloadCationsData, IDownloadCationsOptions } from './components/download-closed-captions/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(
    private apiService: ApiService,
    private accessTokenService: AccessTokenService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private translate: TranslateHelperService,
    private dataService: DataService,
    private focusManager: FocusManagerService
  ) {}

  public async onSelectAction(
    action: IAction,
    id: string,
    accountId: string,
    isBase: boolean,
    name: string,
    downloadedLang: Microsoft.VideoIndexer.Contracts.LanguageV2,
    captionsOptions: IDownloadCationsOptions,
    downloadMenuId: string
  ) {
    switch (action.value) {
      case UIDownloadActionType.JSON:
        await this.downloadJson(id, accountId, isBase, downloadedLang);
        break;
      case UIDownloadActionType.CLOSED_CAPTIONS:
        this.openClosedCaptionsDialog(id, accountId, isBase, name, downloadedLang, captionsOptions, downloadMenuId);
        break;
      case DownloadCaptionType.SRT:
      case DownloadCaptionType.VTT:
      case DownloadCaptionType.TXT:
      case DownloadCaptionType.CSV:
        this.downloadCaption(id, accountId, isBase, name, downloadedLang, action.value);
        break;
      case DownloadCaptionType.TTML:
        this.downloadCaption(id, accountId, isBase, name, downloadedLang, DownloadCaptionType.TTML, 'application/ttml+xml');
        break;
      case UIDownloadActionType.VIDEO:
        this.downloadVideo(id, accountId, name);
        break;
      case UIDownloadActionType.ARTIFACTS_ZIP:
        this.downloadArtifactZip(id, accountId, name);
        break;
      case UIDownloadActionType.PROJECT_RENDERED_VIDEO:
        this.downloadRenderedProject(id, accountId, name);
        break;
    }
  }

  public downloadFile(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileContent: any,
    fileName: string,
    fileType: string,
    fileProperties: BlobPropertyBag,
    encodeUtf8: boolean = false
  ): Subject<Blob> {
    const downloadSubject = new Subject<Blob>();
    if (!fileName) {
      downloadSubject.error('FileName not exists');
    } else {
      try {
        let file: Blob;
        if (encodeUtf8) {
          const UTF8_BOM_ENCODING = new Uint8Array([0xef, 0xbb, 0xbf]);
          file = new Blob()
            ? new Blob([UTF8_BOM_ENCODING, fileContent], fileProperties)
            : new File([UTF8_BOM_ENCODING, fileContent], fileName, fileProperties);
        } else {
          file = new Blob() ? new Blob([fileContent], fileProperties) : new File([fileContent], fileName, fileProperties);
        }
        saveAs(file, formatFileName(fileName, fileType));
        downloadSubject.next(file);
      } catch (e) {
        downloadSubject.error(e);
        throw e;
      }
    }
    return downloadSubject;
  }

  public createFakeLinkAndClick(href: string, fileName: string, download?: boolean) {
    const tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.target = '_blank';
    if (download) {
      tempLink.target = '_self';
      tempLink.setAttribute('download', fileName);
    }
    tempLink.click();
    tempLink.remove();
  }

  private async getJsonUrl(id: string, accountId: string, language: Microsoft.VideoIndexer.Contracts.LanguageV2, isBase: boolean): Promise<string> {
    const token = await this.accessTokenService.getAccessTokenAsync(null, accountId);
    const queryParams = { includeSummarizedInsights: 'false' };

    if (isBase) {
      return this.apiService.Account.Video.getVideoIndexUrl(accountId, id, token, language, queryParams);
    }

    return this.apiService.Account.Project.getProjectIndexUrl(accountId, id, token, language, queryParams);
  }

  private getCaptionAsync(
    accountId: string,
    videoId: string,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2,
    format: string,
    isBase: boolean,
    includeAudioEffects = false,
    includeSpeakers = false
  ) {
    const reqParams = { format: format, includeAudioEffects: includeAudioEffects, includeSpeakers: includeSpeakers };
    if (language) {
      reqParams['language'] = language;
    }

    return this.apiService.Account.Video.getCaptions(accountId, videoId, isBase, reqParams);
  }

  private getArtifactsZipAsync(accountId: string, videoId: string) {
    return this.apiService.Account.Video.getArtifactZip(accountId, videoId);
  }

  @Cacheable({ maxAge: 2000 })
  private download(accountId: string, videoId: string) {
    return this.apiService.Account.Video.getSourceFileDownloadUrl(accountId, videoId);
  }

  private async downloadJson(id: string, accountId: string, isBase: boolean, downloadedLang: Microsoft.VideoIndexer.Contracts.LanguageV2) {
    const jsonURL = await this.getJsonUrl(id, accountId, downloadedLang, isBase);
    this.createFakeLinkAndClick(jsonURL, null, false);
  }

  private openClosedCaptionsDialog(
    id: string,
    accountId: string,
    isBase: boolean,
    name: string,
    downloadedLang: Microsoft.VideoIndexer.Contracts.LanguageV2,
    captionsOptions: IDownloadCationsOptions,
    downloadMenuId: string
  ) {
    const componentData: IDownloadCationsData = {
      prefixId: 'mediaDialog',
      ...captionsOptions
    };
    const dialogData: IDialogData = {
      class: 'closed-captions-dialog',
      title: resources.DownloadClosedCaptions,
      componentData: componentData,
      component: DownloadClosedCaptionsComponent
    };
    const dialogRef = this.dialogService.openDialog(dialogData, '480px', '340px');
    dialogRef.componentInstance.actionChange.subscribe((res: IDialogEvent) => {
      // Focus download menu after dialog close
      this.focusManager.focusByQuery(`#${downloadMenuId}`);
      const data = <IDownloadCaption>res?.dialogEventData;
      if (!data) {
        return;
      }
      this.downloadCaption(id, accountId, isBase, name, downloadedLang, data.type, data.fileType, data.includeAudioEffects, data.includeSpeakers);
    });
  }

  private downloadCaption(
    id: string,
    accountId: string,
    isBase: boolean,
    name: string,
    downloadedLang: Microsoft.VideoIndexer.Contracts.LanguageV2,
    type: DownloadCaptionType,
    fileType?: string,
    includeAudioEffects = false,
    includeSpeakers = false
  ) {
    this.getCaptionAsync(accountId, id, downloadedLang, type.toLowerCase(), isBase, includeAudioEffects, includeSpeakers).subscribe({
      next: file => {
        this.downloadFile(
          file,
          name,
          type.toLowerCase(),
          {
            type: fileType || `text/${type.toLowerCase()};charset=utf-8`
          },
          true
        ).subscribe();
      },
      error: err => {
        this.toastService.error(err, this.getDownloadFileErrorText(type));
      }
    });
  }

  // Returns error text for download file failure
  private getDownloadFileErrorText(fileType: string): string {
    const itemResources = { DownloadFileFailed: '' };
    this.translate.translateResources(itemResources, { name: fileType });
    return itemResources.DownloadFileFailed;
  }

  private downloadVideo(id: string, accountId: string, name: string) {
    const fileName = formatFileName(name, 'mp4');
    this.download(accountId, id).subscribe({
      next: (url: string) => {
        this.createFakeLinkAndClick(url, fileName, true);
      },
      error: err => {
        this.toastService.error(err, this.getDownloadFileErrorText(''), false);
      }
    });
  }

  private downloadArtifactZip(id: string, accountId: string, name: string) {
    this.getArtifactsZipAsync(accountId, id).subscribe({
      next: zip => {
        this.downloadFile(zip, name, 'zip', {
          type: 'application/zip'
        }).subscribe();
      },
      error: err => {
        this.toastService.error(err, this.getDownloadFileErrorText('ZIP'));
      }
    });
  }

  private downloadRenderedProject(id: string, accountId: string, name: string) {
    this.dataService.getProjectRenderedFileDownloadUrl(accountId, id).subscribe({
      next: renderFileObj => {
        if (renderFileObj.downloadUrl) {
          const downloadUrl = renderFileObj.downloadUrl.toString();
          const fileName = `${name.replace(/\s/g, '_')}.mp4`;
          this.createFakeLinkAndClick(downloadUrl, fileName, true);
        } else {
          this.toastService.error(null, this.getDownloadFileErrorText(''));
        }
      },
      error: err => {
        this.toastService.error(err, this.getDownloadFileErrorText(''));
      }
    });
  }
}
