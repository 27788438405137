import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Inject,
  ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IAction } from '../../interfaces';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { UIActionType } from '../../../insights/interfaces';
import { resources } from './resources';
import { isMainButtonPressed } from '../../../utils/event';
import { ActionButtonType } from '../action-button/interfaces';
import { CheckBoxActionMenuSize, CheckBoxActionMenuType } from './interfaces';

@Component({
  selector: 'app-vi-check-box-action-menu',
  templateUrl: './check-box-action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./check-box-action-menu.component.scss']
})
export class CheckBoxActionMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public actionList: IAction[] = [];
  @Input() public hasOpenerIcon = true;
  @Input() public placeholder;
  @Input() public isDisabled = false;
  @Input() public selectedAction: IAction;
  @Input() public hasTooltip = false;
  @Input() public labelForId = '';
  @Input() public type: CheckBoxActionMenuType = ActionButtonType.MAIN_ACTION_MENU;
  @Input() public size: CheckBoxActionMenuSize = CheckBoxActionMenuSize.Large;
  @Input() public uid?: string;
  @Input() public ariaLabelledBy?: string;

  @Output() public selectedActionsChange = new EventEmitter<IAction[]>(true);

  public placeholderAction;
  public value = '';
  public expanded = false;
  public isEmptySelectedActions: boolean;
  public actions: IAction[] = [];
  public resources = resources;
  public UIActionType = UIActionType;
  public ActionButtonType = ActionButtonType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private timeout: any;
  private isActionButtonClick = false;
  private isCheckboxClick = false;
  private documentClickBound = false;
  private documentClickHandlerRef: (event: MouseEvent) => void;

  constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateHelperService, private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.translate.translateResourcesInstant(this.resources);
    this.placeholder = this.placeholder ?? this.resources?.CheckboxSelectLabel;
    this.placeholderAction = { title: this.placeholder };
    this.cdr.detectChanges();
    this.initActions();

    // Set selected menu actions according to input selected actions
    if (this.selectedAction) {
      this.setValue();
    } else {
      this.resetSelectedActions();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // If new actions list - set action
    if (changes && changes.actionList) {
      this.initActions();
      this.setValue();
    }
  }

  public ngOnDestroy(): void {
    this.unbindToDocumentClick();
  }

  public handleChange(action: IAction) {
    if (this.isEmptySelectedActions) {
      this.selectedAction = action;
      this.isEmptySelectedActions = false;
    }

    this.isCheckboxClick = true;
    this.updateSelectionByGroup(action);

    // Set new value for menu
    this.setValue();

    // Get selected actions
    const selectedActions = this.actions.filter(currentAction => currentAction.selected && currentAction.type !== UIActionType.GROUP);

    // Reset selected actions if no action is chosen
    if (selectedActions.length === 0) {
      this.resetSelectedActions();
    }

    this.selectedActionsChange.emit(selectedActions);
  }

  public toggleDropdown() {
    this.expanded = !this.expanded;
    this.isActionButtonClick = this.expanded;

    if (this.expanded) {
      this.bindToDocumentClick();
    }
  }

  private bindToDocumentClick() {
    if (!this.documentClickBound) {
      this.documentClickHandlerRef = this.documentClickHandler.bind(this);
      this.document.addEventListener('mouseup', this.documentClickHandlerRef);
      this.documentClickBound = true;
    }
  }

  private unbindToDocumentClick() {
    if (this.documentClickBound) {
      this.document.removeEventListener('mouseup', this.documentClickHandlerRef);
      this.documentClickBound = false;
    }
  }

  // Hide dropdown if user clicked on document area which is not current component area
  private collapse() {
    if (!this.isActionButtonClick && !this.isCheckboxClick) {
      this.expanded = false;
      this.unbindToDocumentClick();
      this.cdr.detectChanges();
    }
    this.isActionButtonClick = false;
    this.isCheckboxClick = false;
  }

  private documentClickHandler = (event: MouseEvent): void => {
    if (!isMainButtonPressed(event)) {
      return;
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.collapse();
    });
  };

  private initActions() {
    this.actions = [];
    const actionsGroup: string[] = [];
    let groupCounter = 0;
    for (const action of this.actionList) {
      // Add group actions
      if (action.group && !actionsGroup.includes(action.group)) {
        const actionsInGroup = this.actionList.filter(currentAction => currentAction.group === action.group);
        const isGroupSelected = actionsInGroup.every(currentAction => currentAction.selected);
        const groupAction: IAction = {
          title: action.group,
          selected: isGroupSelected,
          value: action.group,
          id: `group_${groupCounter}`,
          type: UIActionType.GROUP
        };
        groupCounter++;
        actionsGroup.push(action.group);
        this.actions.push(groupAction);
      }

      // Push action
      this.actions.push(action);
    }
  }

  private updateSelectionByGroup(action: IAction) {
    // If a group action - select all or deselect sub actions, if all group actions selected - mark group
    if (action.type === UIActionType.GROUP) {
      for (const currentAction of this.actions) {
        if (currentAction.group === action.value) {
          currentAction.selected = action.selected;
        }
      }
    } else {
      let currentGroup;
      for (const currentAction of this.actions) {
        if (currentAction.type === UIActionType.GROUP) {
          currentGroup = currentAction;
          currentGroup.selected = true;
        } else if (currentAction.group && currentGroup && currentAction.group === currentGroup.value) {
          currentGroup.selected = currentGroup.selected && currentAction.selected;
        }
      }
    }
  }

  private resetSelectedActions() {
    this.selectedAction = this.placeholderAction;
    this.value = this.placeholder;
    this.isEmptySelectedActions = true;
  }

  private setValue() {
    this.value = '';
    // If action is selected - add to value
    this.actions.forEach(action => {
      this.value += `${action.selected && action.type !== UIActionType.GROUP ? action.title + ', ' : ''}`;
    });

    // Remove last ' ,'
    if (this.value) {
      this.value = this.value.slice(0, -2);
    }

    this.placeholderAction = { title: this.value ? this.value : this.placeholder };
    this.cdr.detectChanges();
  }
}
