export const resources = {
  IndexingLogoGroupsSettings: '',
  IndexingLogosManageLogoGroups: '',
  IndexingLogosSelectLogoGroupsLabel: '',
  IndexingLogoGroupsLabel: '',
  IndexingLogoEmptyLogoGroupsList: '',
  IndexingLogoEmptyLogoGroupsListLogoCreationText: '',
  IndexingLogoEmptyLogoGroupsListLogoCreationLink: '',
  IndexingLogoAdvancedPresetNotSelectedWarningInfo: '',
  IndexingLogoAdvancedPresetNotSelectedWarningInfoLink: '',
  SettingsLoading: '',
  CheckboxSelectLabel: ''
};
