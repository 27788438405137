import { Inject, Injectable } from '@angular/core';

import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';

import { TrackService } from '../track';
import { WINDOW } from '../window.token';

export interface ICacheResponse<T> {
  json?: T;
  date?: string;
}

@Injectable()
export class CacheStorageService {
  // cachable resources
  private resources_ = {
    ArmAccounts: 'ArmAccounts',
    EdgeExtensions: 'EdgeExtensions'
  };

  private cacheName_: string;
  private cacheMap: { [key: string]: Cache } = {};

  constructor(@Inject(WINDOW) private window: Window, private coreStoreService: CoreStoreService, private trackService: TrackService) {
    this.coreStoreService.userDetails$.subscribe(userDetails => {
      if (userDetails?.id) {
        const { id, tenantId } = userDetails;
        // to maintain uniqueness, cache name is based on user and tenant id
        this.cacheName_ = `user-${id}-tenant-${tenantId}`;
      }
    });
  }

  public get resources() {
    return this.resources_;
  }

  public get cacheName() {
    return this.cacheName_;
  }

  /**
   * match cached content with key
   * @param key cached content key
   * @param options
   * @returns
   */
  public async match<T>(key: string, options: { maxAge: number }): Promise<ICacheResponse<T>> {
    try {
      const cache = await this.open();
      const response = await cache.match(key);

      // return empty object if cache is empty
      if (!response) {
        return {};
      }

      const date = response.headers?.get('Date');
      const dateExpired = date && this.isDateExpired(new Date(date), options.maxAge);

      // return empty object if cache is stale
      if (!dateExpired) {
        return {};
      }

      const json = await response.json();

      return {
        json,
        date
      };
    } catch (error) {
      this.trackService.trackError(error);

      return {};
    }
  }

  /**
   * update cache with new data and set the date header of the response for freshness check
   * @param key cached content key
   * @param data content to be cached
   * @returns cached content
   */
  public async put(key: string, data: unknown) {
    try {
      const cache = await this.open();

      /**
       * update cache with new data and set the date of the response
       */
      await cache.put(
        key,
        new Response(JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
            /**
             * set date header to the current date to indicate when the cache was last updated
             */
            Date: new Date().toUTCString()
          }
        })
      );
    } catch (error) {
      this.trackService.trackError(error);
    }
  }

  private open = async () => {
    // if cache is not found, open a new cache and store for fast access
    if (!this.cacheMap[this.cacheName_]) {
      const cache = await this.window.caches.open(this.cacheName_);
      this.cacheMap[this.cacheName_] = cache;
    }

    return this.cacheMap[this.cacheName_];
  };

  private isDateExpired(date: Date, threshold: number) {
    return new Date().getTime() - date.getTime() <= threshold;
  }
}
