import { Injectable } from '@angular/core';

import { Subject, fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ICustomData } from '@azure/video-indexer-widgets';

import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { AuthService } from '../../auth/services/auth.service';
import { ApiService } from '../../api/services/api.service';
import { UIFaceType } from '../components/faces/interfaces';
import { IArtifactRequestParams, IGetIndexRequestParams } from '../../api/interfaces';
import { ISceneTimeFrame } from '../components/scenes/interfaces';
import { IDeleteFaceAPI } from './interfaces';
import { SpriteType } from '../../insights-common/interfaces';
import { ITabListTab } from '../../shared/components/tablist/interfaces';
import { ICustomInsightsData, IUITab } from '../interfaces';
import { ICustomInsightsWidgetConfigSerialization, IVIInsightsWidgetConfigSerialization } from '../../insights-widget/services/interfaces';
import { CommunicationService } from '../../insights-common/communication.service';
import { IUIPresetType } from '../presetInsights';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  public initWidgetData: Subject<IVIInsightsWidgetConfigSerialization>;
  public initCustomWidgetData: Subject<ICustomInsightsWidgetConfigSerialization>;
  public showFaceGateError: Subject<boolean>;

  // In case there is custom images location - get the images from there
  public customImagesLocation: string;
  public tabChangeSubject: Subject<ITabListTab<IUITab>>;
  public sceneChangeSubject: Subject<ISceneTimeFrame>;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private communicationService: CommunicationService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.tabChangeSubject = new Subject<ITabListTab<IUITab>>();
    this.sceneChangeSubject = new Subject<ISceneTimeFrame>();
    this.initWidgetData = new Subject<IVIInsightsWidgetConfigSerialization>();
    this.initCustomWidgetData = new Subject<ICustomInsightsWidgetConfigSerialization>();
    this.showFaceGateError = new Subject<boolean>();
  }

  public getVideoTestIndex(accountId: string, videoId: string) {
    return this.apiService.Account.Video.getVideoTestIndex();
  }

  public getVideoIndex(accountId: string, videoId: string, language: Microsoft.VideoIndexer.Contracts.LanguageV2 = 'en-US') {
    if (accountId && videoId) {
      const params: IGetIndexRequestParams = {
        includeSummarizedInsights: !this.featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights),
        includeStreamingUrls: false
      };
      if (language) {
        params.language = language;
      }

      return this.apiService.Account.Video.getVideoIndex(accountId, videoId, params);
    } else {
      return this.apiService.Account.Video.getVideoTestIndex();
    }
  }

  public parseCustomDataItem(item: ICustomData | Microsoft.VideoIndexer.Contracts.CustomInsight) {
    const customDataItem: ICustomInsightsData = {
      key: (item as ICustomData).key || (item as Microsoft.VideoIndexer.Contracts.CustomInsight).name,
      title: (item as ICustomData).title || (item as Microsoft.VideoIndexer.Contracts.CustomInsight).displayName,
      insightType: (item as ICustomData).type || (item as Microsoft.VideoIndexer.Contracts.CustomInsight).displayType,
      items: (item as ICustomData).items || (item as Microsoft.VideoIndexer.Contracts.CustomInsight).results,
      sortedBy: (item as ICustomData).sortedBy,
      presets: (item as ICustomData).presets || (['all'] as IUIPresetType[])
    };
    return customDataItem;
  }

  public getThumbnailUrl(accountId: string, videoId: string, thumbnailId: string): string {
    return this.apiService.Account.Video.getThumbnailUrl(accountId, videoId, thumbnailId);
  }

  public getKeyframesSpriteUrl(accountId: string, videoId: string, page: number): string {
    if (this.customImagesLocation) {
      return this.apiService.Account.Video.getKeyframesSpriteCustomUrl(this.customImagesLocation, page);
    } else if (accountId && videoId) {
      let accessToken = this.authService.getUserAccessTokenOrEmptyString(videoId, accountId);
      // Fallback - if access token is empty try to get from account token
      if (!accessToken) {
        accessToken = this.authService.getUserAccessTokenOrEmptyString(null, accountId);
      }
      return this.apiService.Account.Video.getSpriteUrl(accountId, videoId, accessToken, page, SpriteType.KeyFrames);
    } else {
      return this.apiService.Account.Video.getTestKeyframesSpriteUrl(page);
    }
  }

  public getAccessToken(accountId: string, videoId: string): string {
    return this.authService.getAccessToken(videoId, accountId);
  }

  public getPermissionFromURLToken() {
    return this.authService.getPermissionFromURLToken();
  }

  public saveFace(accountId: string, videoId: string, faceId: number, facesType: UIFaceType, newName: string) {
    const paramsArg = [accountId, videoId, faceId];
    // Check face type value
    if (facesType === UIFaceType.people) {
      this.communicationService.faceUpdated(faceId);
      return this.apiService.Account.Video.updateFace(accountId, videoId, faceId, { newName: newName });
    }
  }

  public deleteFace(accountId: string, videoId: string, faceId: number, facesType: UIFaceType) {
    const { ...deleteFacesApiArgs }: IDeleteFaceAPI = { accountId, videoId, faceId };

    if (facesType === UIFaceType.people) {
      this.communicationService.faceUpdated(faceId);
      return this.apiService.Account.Video.removeFaces(deleteFacesApiArgs);
    }
  }

  public search(accountId: string, videoId: string, query: string) {
    return this.apiService.Account.search(accountId, {
      id: videoId,
      query: query
    });
  }

  public setThumbnail(accountId: string, videoId: string, imageUrl: string, thumbnailId?: string) {
    const accessToken = this.authService.getAccessToken(videoId, accountId, true);
    return this.apiService.Account.Video.setThumbnail(accountId, videoId, thumbnailId, {
      imageUrl: `${imageUrl}?accessToken=${accessToken}`
    });
  }

  public setThumbnailId(accountId: string, videoId: string, keyframeThumbnailId: string) {
    return this.apiService.Account.Video.setThumbnailId(accountId, videoId, {
      keyframeThumbnailId: keyframeThumbnailId
    });
  }

  public onTimeChange() {
    if (!window) {
      return;
    }
    const source = fromEvent(window, 'message');
    return source.pipe(distinctUntilChanged());
  }

  public getContainerWidthAsync(el: HTMLElement): Promise<number> {
    return new Promise((resolve, reject) => {
      const w = el && el.getBoundingClientRect().width;
      resolve(w);
    });
  }

  public getContainerHeightAsync(el: HTMLElement): Promise<number> {
    return new Promise((resolve, reject) => {
      const h = el && el.getBoundingClientRect().height;
      resolve(h);
    });
  }

  public getCaptionUrl(
    video: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2,
    format: string
  ): string {
    const reqParams = { language: language, format: format };

    return this.apiService.Account.Video.getCaptionsUrl(video.accountId, video.id, reqParams);
  }

  public getThumbnail(video: Microsoft.VideoIndexer.Contracts.PlaylistContractV2, thumbnailId: string, allowEdit?: boolean) {
    return this.apiService.Account.Video.getThumbnail(video.accountId, video.id, thumbnailId, { allowEdit: allowEdit });
  }

  public getMetadataUrlAsync(video: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    const reqParams: IArtifactRequestParams = { type: 'Metadata' };
    return this.apiService.Account.Video.getArtifactUrl(video.accountId, video.id, reqParams);
  }

  public getMetadataAsync(url: string) {
    return this.apiService.Account.Video.getArtifactByUrl(url);
  }
}
