import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { VIMainSidebarService } from '@common/modules/core/services/vi-main-sidebar/vi-sidebar.service';

import { closeAction } from './actions';
import { resources } from './resources';

@Component({
  selector: 'vi-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent extends FocusableComponent implements OnInit, OnDestroy {
  public closeAction = closeAction;
  public resources = resources;
  public show$: Observable<boolean>;
  @ViewChild('viSidebar', { read: ViewContainerRef }) public viSidebar: ViewContainerRef;

  private destroy$ = new Subject<void>();

  constructor(private viMainSidebar: VIMainSidebarService, private cdr: ChangeDetectorRef, private translate: TranslateHelperService) {
    super();
  }

  public ngOnInit(): void {
    this.setTranslations();
    this.show$ = this.viMainSidebar.isOpenSubject.pipe(takeUntil(this.destroy$));
    setTimeout(() => {
      this.viMainSidebar.setVISidebarElement(this.viSidebar);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public closeSidebar() {
    this.viMainSidebar.closeSidebar();
  }

  private setTranslations() {
    this.translate.translateResources(this.resources).subscribe(() => {
      this.closeAction.titleAttribute = this.resources[this.closeAction.key];
      this.cdr.detectChanges();
    });
  }
}
