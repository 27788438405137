import { createSelector } from '@ngrx/store';

import { selectGalleryState } from '../reducers';

export const getProjectsState = createSelector(selectGalleryState, state => state.projects);

export const isProjectsLoaded = createSelector(getProjectsState, state => state.loaded);

export const isProjectsLoading = createSelector(getProjectsState, state => state.loading);

export const projectsError = createSelector(getProjectsState, state => state.error);

export const projectsErrorType = createSelector(getProjectsState, state => state.errorType);
