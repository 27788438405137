import { Inject, Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ClipboardService } from 'ngx-clipboard';

import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { WINDOW } from '@common/modules/core/services/window.token';
import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { AccessibilityUtilsService } from '@common/modules/accessibility/accessibility-utils.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { AccountPermission } from '@common/modules/shared/interfaces';

import * as InvitationsActions from '../actions/invitations.actions';
import * as RoleAssignmentsActions from '../actions/roleAssignments.actions';
import { IState } from '../reducers';
import * as fromCore from '../../core/selectors';
import { resources } from '../resources';

@Injectable()
export class InvitationsEffects {
  public loadInvitations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvitationsActions.loadInvitations),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([, accountId]) => {
        return this.apiService.Account.getInvitations(accountId, { allowEdit: true, permission: AccountPermission.OWNER }).pipe(
          switchMap(invitations => {
            this.trackService.track('invitations.load.success', {
              category: EventCategory.INVITE
            });
            return [InvitationsActions.loadInvitationsSuccess({ invitations }), RoleAssignmentsActions.loadRoleAssignments()];
          }),
          catchError(err => {
            this.trackService.track('invitations.load.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [InvitationsActions.loadInvitationsFailed({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  public inviteToAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvitationsActions.inviteToAccount),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectInvitationsLength)),
      switchMap(([[{ formDetails }, accountId], invitationsLength]) => {
        this.store.dispatch(InvitationsActions.inviteToAccountInProgress());
        return this.apiService.Account.inviteToAccount(accountId, formDetails, { permission: AccountPermission.OWNER }, { cache: false }).pipe(
          switchMap(invitations => {
            this.trackService.track('invitations.invite_user.success', {
              category: EventCategory.INVITE
            });
            const emails = formDetails.map(invite => invite.email);
            this.accessibilityUtils.announceDynamicMessage('InviteSuccessNarrator', {
              emails: emails.toString(),
              count: formDetails?.length + invitationsLength
            });
            return [InvitationsActions.inviteToAccountSuccess({ invitations: invitations })];
          }),
          catchError(err => {
            this.trackService.track('invitations.invite_user.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [InvitationsActions.invitationsError({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  public deleteInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvitationsActions.deleteInvitation),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectInvitations)),
      withLatestFrom(this.store.select(fromCore.selectInvitationsLength)),
      switchMap(([[[{ invitationId }, accountId], invitations], invitationsLength]) => {
        return this.apiService.Account.deleteInvitation(accountId, invitationId, { permission: AccountPermission.OWNER }).pipe(
          switchMap(() => {
            this.trackService.track('invitations.delete.success', {
              category: EventCategory.INVITE
            });
            this.accessibilityUtils.announceDynamicMessage('InviteRemovingSuccessNarrator', {
              email: invitations[invitationId]?.email,
              count: invitationsLength - 1
            });
            return [InvitationsActions.deleteInvitationSuccess({ invitationId })];
          }),
          catchError(err => {
            this.trackService.track('invitations.delete.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [InvitationsActions.invitationsError({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  public copyInvitationLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvitationsActions.copyInvitationLink),
        withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
        tap(([{ invitationId }, accountId]) => {
          const inviteUrl = this.createInviteUrl(invitationId, accountId);
          this.clipboardService.copyFromContent(inviteUrl);
          this.trackService.track('invitations.copy_url', {
            category: EventCategory.INVITE
          });
          this.toastService.success(resources.InviteCopyInviteUrlSuccessNotification, false);
        })
      ),
    {
      dispatch: false
    }
  );

  private toastService: ToastService;

  constructor(
    private store: Store<IState>,
    private actions$: Actions,
    private apiService: ApiService,
    private trackService: TrackService,
    @Inject(WINDOW) private window,
    private clipboardService: ClipboardService,
    private accessibilityUtils: AccessibilityUtilsService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.toastService = this.injector.get(ToastService);
  }

  private createInviteUrl(invitationId: number, accountId: string): string {
    let inviteUrl = '';
    if (invitationId) {
      inviteUrl = `${this.window.location.origin}/account/login/?returnUrl=/`;
      inviteUrl += encodeURIComponent(`?accountId=${accountId}&invitationId=${invitationId}`);

      if (!this.apiService.isTrialApi()) {
        inviteUrl += encodeURIComponent(`&location=${this.apiService.getApiLocation()}`);
      }
    }
    return inviteUrl;
  }
}
