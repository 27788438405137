import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { ListKeyManager } from '@angular/cdk/a11y';

import { IUIInsight, IWidgetExpandable, IUIInsightType } from '../../interfaces';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { resources } from './resources';
import { isMainButtonPressed } from '../../../utils/event';
import { FocusableComponent } from '../../../accessibility/focusable/focusable.component';

@Component({
  selector: 'app-vi-insights-content-list',
  templateUrl: './insights-content-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./insights-content-list.component.scss']
})
export class InsightsContentListComponent extends FocusableComponent implements OnInit, OnChanges, AfterViewInit {
  // list of items to render capsule from
  @Input() public items: IUIInsight[];
  @Input() public edit: boolean;
  @Input() public activeContainer = false;
  @Input() public type: IUIInsightType;
  @Input() public timeline?: boolean;
  @Input() public dir = 'ltr';
  @Input() public listLabel = '';
  // Widget configurations
  @Input() public conf: IWidgetExpandable;
  // determines if to show add input box
  @Input() public enableAddNewItem = true;
  // search filter
  @Input() public textFilter = '';
  // onItemSelected event
  @Output() public itemSelected = new EventEmitter<IUIInsight>();

  // onToggleExpand event
  @Output() public toggleExpandList = new EventEmitter<void>();

  // add new item
  @Output() public add = new EventEmitter<string>();
  @Output() public remove = new EventEmitter<number>();

  @ViewChild('itemObject', { static: false }) public itemObject: ElementRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChildren('itemObject') public listItems: QueryList<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChild('injectedInput', { static: false }) public injectedInputTemplate: TemplateRef<any>;
  // Publics
  public placeholderText = '';
  public placeholderEnterText = '';
  public resources = resources;

  private keyboardEventsManager;

  constructor(private focusManager: FocusManagerService, public cdr: ChangeDetectorRef, private translate: TranslateHelperService) {
    super();
  }

  public ngOnInit() {
    // Translate resources
    this.translate.translateResources(this.resources);
    this.initPlaceholderText();
  }

  public ngAfterViewInit() {
    this.keyboardEventsManager = new ListKeyManager(this.listItems);
    this.keyboardEventsManager.setFirstItemActive();
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public selectItem(item: IUIInsight) {
    this.itemSelected.emit(item);
  }

  public initPlaceholderText() {
    switch (this.type) {
      case 'namedEntities':
        // Can only add brands on named entities
        this.placeholderText = this.resources.BrandAddBrandAction;
        this.placeholderEnterText = this.resources.BrandsEnterBrand;
        break;
      case 'keywords':
        this.placeholderText = this.resources.KeywordAddKeyword;
        this.placeholderEnterText = this.resources.KeywordsEnterKeyword;
        break;
      case 'labels':
        this.placeholderText = this.resources.LabelAddLabel;
        this.placeholderEnterText = this.resources.LabelsEnterLabel;
        break;
      default:
        break;
    }
  }

  public toggleExpandMouse($event: MouseEvent) {
    if (isMainButtonPressed($event)) {
      this.toggleExpand();
    }
  }

  public toggleExpand(bFromEnter: boolean = false) {
    const currentLength: number = this.listItems.toArray().length;
    this.toggleExpandList.emit();

    if (bFromEnter) {
      this.focusManager.focusVia(() => {
        // Update view
        this.cdr.detectChanges();
        const listItems = this.listItems.toArray();
        return listItems[currentLength - 1];
      }, this.cdr);
    }

    this.cdr.detectChanges();
  }

  public handleAddCapsule(input: string) {
    this.add.emit(input);
  }

  public removeItem(item: IUIInsight, $event?) {
    this.remove.emit(item.id);
    if ($event) {
      $event.stopPropagation();

      if ($event.type !== 'keyup') {
        return;
      }
    }

    const currentObj = $event.target.parentNode;

    // Find previous item at list
    const currentArray = this.listItems.toArray();

    let index;
    for (index = 0; index < currentArray.length; index++) {
      const element: ElementRef = currentArray[index];
      if (element.nativeElement === currentObj) {
        break;
      }
    }

    this.focusManager.focusVia(() => {
      // Update view
      this.cdr.detectChanges();
      const listItmes = this.listItems.toArray();
      return listItmes[index - 1];
    }, this.cdr);
  }

  public markForCheck() {
    this.cdr.markForCheck();
  }

  public getShowingInsightTimelineText(insight: IUIInsight): string {
    const itemResources = { InsightsShowingTimeline: '' };
    this.translate.translateResources(itemResources, { name: insight.name });
    return itemResources.InsightsShowingTimeline;
  }

  public trackByIndex(i) {
    return i;
  }

  public getCapsuleId(item: IUIInsight): string {
    return `capsuleListItemId${item.id === -1 ? item.name : item.id}_${this.type}_${item?.appearances && item.appearances[0]?.adjustedStart}`;
  }
}
