import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { ElementSize } from '../interfaces';

@Directive({
  selector: '[viElementResize]'
})
export class ElementResizeDirective implements OnChanges, OnDestroy {
  // User can decide if listen or not to the dimensions change
  @Input() public elementResizeListener: boolean = true;

  // Element height has changed
  @Output() public heightChanged = new EventEmitter<number>();
  // Element width has changed
  @Output() public widthChanged = new EventEmitter<number>();
  // Element size has changed
  @Output() public sizeChanged = new EventEmitter<ElementSize>();

  private height: number;
  private width: number;

  constructor(private el: ElementRef) {
    this.height = this.el.nativeElement.offsetHeight;
    this.width = this.el.nativeElement.offsetWidth;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.elementResizeListener.currentValue) {
      this.el.nativeElement.addEventListener('input', this.onInput.bind(this));
    } else {
      this.el.nativeElement.removeEventListener('input', this.onInput);
    }
  }

  public ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('input', this.onInput);
  }

  public onInput(event) {
    const isHeightChanged = event.target.offsetHeight !== this.height;
    const isWidthChanged = event.target.offsetWidth !== this.width;
    if (isHeightChanged) {
      this.height = event.target.offsetHeight;
      this.heightChanged.emit(this.height);
    }
    if (isWidthChanged) {
      this.width = event.target.offsetWidth;
      this.widthChanged.emit(this.width);
    }
    if (isHeightChanged && isWidthChanged) {
      this.sizeChanged.emit({ height: this.height, width: this.width });
    }
  }
}
