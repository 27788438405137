import { Directive, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[viUiAccessibleClick]'
})
export class AccessibleClickDirective {
  @Input() public appClickDisabled = false;
  @Input() public appSpaceClickEnabled = false;
  @Output() public viUiAccessibleClick = new EventEmitter();

  @HostListener('mouseup', ['$event']) public onMouseUp(event: MouseEvent) {
    // Main button it's usually the left button or the un-initialized state
    if (!this.appClickDisabled && this.isMainButtonPressed(event)) {
      this.viUiAccessibleClick.emit(event);
    }
  }

  @HostListener('keyup.enter', ['$event']) public onEnterKeyUp(event: KeyboardEvent) {
    if (!this.appClickDisabled) {
      this.viUiAccessibleClick.emit(event);
    }
  }

  @HostListener('keyup.Space', ['$event']) public onSpaceKeyUp(event: KeyboardEvent) {
    if (this.appSpaceClickEnabled && !this.appClickDisabled) {
      this.viUiAccessibleClick.emit(event);
    }
  }

  private isMainButtonPressed(event: MouseEvent): boolean {
    // MouseEvent.button with 0 value means that the main button pressed.
    // It's usually the left button or the un-initialized state
    return event && event.button === 0;
  }
}
