import { Injectable } from '@angular/core';

import { ApiService } from '@common/modules/api/services/api.service';
import { IAbuseParams } from '@common/modules/api/interfaces';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private resources = resources;
  constructor(private apiService: ApiService, private toastService: ToastService, private translate: TranslateHelperService) {
    this.translate.translateResources(this.resources);
  }

  public report(formData: IAbuseParams, id: string, accountId: string) {
    return this.apiService.Account.Video.reportAbuse(accountId, id, formData);
  }

  public handelUpdateIndex(isSuccess: boolean) {
    isSuccess
      ? this.toastService.success(this.resources.MediaUpdateVideoSuccess, false)
      : this.toastService.error(null, this.resources.MediaUpdateVideoFailed, false);
  }

  public handelRequestReview(isSuccess: boolean) {
    isSuccess
      ? this.toastService.success(this.resources.MediaReviewRequestSucceeded, false)
      : this.toastService.error(null, this.resources.MediaReviewRequestFailed, false);
  }
}
