import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { MediaDataCommonModule } from '@common/modules/media-data-common/media-data.common.module';

import { MediaEffects } from './effects/media.effects';
import { MediaStoreService } from './services/media-store.service';

@NgModule({
  declarations: [],
  imports: [MediaDataCommonModule, EffectsModule.forFeature([MediaEffects])],
  providers: [MediaStoreService]
})
export class MediaDataModule {}
