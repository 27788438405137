import { createSelector } from '@ngrx/store';

import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { selectLogoGroups, getPeopleModels, getTrainedSpeechModels } from '../../../customization-data/selectors';
import { ILanguage } from './../../../core/interfaces';
import { ILanguageModelContract } from './../../../customization-data/reducers/language-model.reducer';
import { getTrainedLanguageModels } from './../../../customization-data/selectors/language.selectors';
import { selectSupportedLanguagesIds } from './../../../customization-data/selectors/supported-languages-ids.selectors';
import { IndexingLanguageType, IndexingMode, UploadingState } from '../../interfaces';
import { selectIndexingViewState } from './indexing-view.selectors';
import { selectUploadFiles, selectUploadState } from './upload.selectors';
import { selectOriginalLanguageId, selectReIndexState } from './re-index.selectors';
import { additionalUploadLanguages, keepSourceLanguageId } from '../../../core/languages';
import { isAdvancedIndexingPresetSelected } from '../effects/utils/indexing-utils';

export const selectIndexingSettings = createSelector(
  selectIndexingViewState,
  selectUploadState,
  selectReIndexState,
  (indexingViewState, uploadState, reIndexState) => {
    if (indexingViewState.indexingMode === IndexingMode.Upload) {
      return uploadState?.indexingSettings;
    } else {
      return reIndexState?.indexingSettings;
    }
  }
);

export const selectSupportedAIs = createSelector(
  selectIndexingViewState,
  selectUploadState,
  selectReIndexState,
  (indexingViewState, uploadState, reIndexState) => {
    if (indexingViewState.indexingMode === IndexingMode.Upload) {
      return uploadState?.supportedAIs;
    } else {
      return reIndexState?.supportedAIs;
    }
  }
);

export const isSupportedAIsLoaded = createSelector(
  selectIndexingViewState,
  selectUploadState,
  selectReIndexState,
  (indexingViewState, uploadState, reIndexState) => {
    if (indexingViewState.indexingMode === IndexingMode.Upload) {
      return uploadState?.isSupportedAIsLoaded;
    } else {
      return reIndexState?.isSupportedAIsLoaded;
    }
  }
);

export const isLoadSupportedAIsError = createSelector(
  selectIndexingViewState,
  selectUploadState,
  selectReIndexState,
  (indexingViewState, uploadState, reIndexState) => {
    if (indexingViewState.indexingMode === IndexingMode.Upload) {
      return uploadState?.isLoadSupportedAIsError;
    } else {
      return reIndexState?.isLoadSupportedAIsError;
    }
  }
);

export const getAllFiles = createSelector(selectUploadFiles, selectIndexingViewState, (uploadFiles, indexingViewState) => {
  if (indexingViewState?.indexingMode === IndexingMode.Upload) {
    return uploadFiles;
  } else {
    return null;
  }
});

export const getAllValidFiles = createSelector(getAllFiles, files => {
  return files?.filter(file => !file.error);
});

export const getAllUploadValidFilesNames = createSelector(getAllValidFiles, files => {
  return files?.map(file => file.name);
});

export const getAllCompletedFiles = createSelector(getAllFiles, files => {
  return files?.filter(file => file.uploadingState === UploadingState.Completed);
});

export const isMultiFileMode = createSelector(selectUploadState, selectIndexingViewState, (uploadState, indexingViewState) => {
  if (indexingViewState.indexingMode === IndexingMode.Upload && Object.keys(uploadState.entities).length > 1) {
    return true;
  } else {
    return false;
  }
});

export const selectIndexingPreset = createSelector(selectIndexingSettings, state => state?.indexingPreset);

export const selectExcludeRAI = createSelector(selectIndexingSettings, state => state?.excludeRAI);

export const selectExcludeAIs = createSelector(selectIndexingSettings, state => state?.excludeAIs);

export const selectStreamingPreset = createSelector(selectIndexingSettings, state => state?.streamingPreset);

export const selectPrivacy = createSelector(selectIndexingSettings, state => state?.privacy);

export const selectMetadata = createSelector(selectIndexingSettings, state => state?.metadata);

export const selectDescription = createSelector(selectIndexingSettings, state => state?.description);

export const selectLanguageId = createSelector(selectIndexingSettings, state => state?.languageId);

export const selectFileName = createSelector(selectIndexingSettings, state => state.name);

export const selectFilesNames = createSelector(
  selectIndexingViewState,
  selectFileName,
  getAllUploadValidFilesNames,
  (indexingViewState, ReindexFileName, UploadFilesNames) => {
    if (indexingViewState?.indexingMode === IndexingMode.Upload) {
      return UploadFilesNames;
    } else {
      return [ReindexFileName];
    }
  }
);

export const getLanguagesObject = createSelector(
  selectSupportedLanguagesIds,
  getTrainedLanguageModels,
  getTrainedSpeechModels,
  (supportedLanguages, trainedLanguageModels, speechModels) => {
    return {
      additionalUploadLanguages: additionalUploadLanguages.map(l => ({
        ...l,
        type: IndexingLanguageType.Auto
      })),
      supportedLanguages: supportedLanguages.map(l => ({ ...l, type: IndexingLanguageType.Language })),
      trainedLanguageModels: trainedLanguageModels.map(l => ({ ...l, type: IndexingLanguageType.LanguageModel })),
      speechModels: speechModels.map(l => ({ ...l, type: IndexingLanguageType.SpeechModel })),
      keepSource: { id: keepSourceLanguageId, type: IndexingLanguageType.KeepSource }
    };
  }
);
export const getAllLanguages = createSelector(getLanguagesObject, languages => [
  ...languages.additionalUploadLanguages,
  ...languages.supportedLanguages,
  ...languages.trainedLanguageModels,
  ...languages.speechModels
]);

// TODO: Will be used by the upload click effect
export const getLanguage = createSelector(
  getAllLanguages,
  selectLanguageId,
  selectOriginalLanguageId,
  (languages, languageId, originalLanguageId) => {
    // in case languageId is keepSource so search for the language of the original language id
    const selectedLanguageId = languageId === keepSourceLanguageId ? originalLanguageId : languageId;
    return languages.find(
      l =>
        (l.type === IndexingLanguageType.Language &&
          (<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract>l)?.languageCode === selectedLanguageId) ||
        (l.type === IndexingLanguageType.Auto && (<ILanguage>l)?.key === selectedLanguageId) ||
        (l.type === IndexingLanguageType.LanguageModel && (<ILanguageModelContract>l)?.languageModelId === selectedLanguageId) ||
        (l.type === IndexingLanguageType.SpeechModel && (<Microsoft.VideoIndexer.Contracts.CustomSpeechModel>l)?.id === selectedLanguageId)
    );
  }
);

export const selectPeopleModelId = createSelector(selectIndexingSettings, state => state?.peopleModelId);

export const selectedPeopleModel = createSelector(selectPeopleModelId, getPeopleModels, (id, peopleModels) => {
  if (!id) {
    return peopleModels.find(model => model.isDefault);
  }
  return peopleModels.find(model => model.id === id);
});

export const selectLogoGroupId = createSelector(selectIndexingSettings, state => state?.logoGroupId);

export const selectedLogoGroup = createSelector(selectLogoGroupId, selectLogoGroups, (id, logoGroups) => {
  return logoGroups.find(group => group.id === id);
});

export const selectBrandCategories = createSelector(selectIndexingSettings, state => state?.brandsCategories);

export const getIncludedAIs = createSelector(selectSupportedAIs, selectExcludeAIs, (SupportedAIsMap, excludedAIs) => {
  const supportedAIs = Object.keys(SupportedAIsMap);
  return supportedAIs.filter(ai => !excludedAIs.includes(ai as ExcludableAIsModels));
});

export const getIndexingSettingSummary = createSelector(
  selectIndexingSettings,
  selectedPeopleModel,
  selectedLogoGroup,
  getIncludedAIs,
  selectExcludeAIs,
  (indexingSettings, selectedPeopleModel, selectedLogoGroup, includedAIs, excludedAIs) => {
    const selectedBrands = indexingSettings.brandsCategories?.split(',').filter(brand => brand);
    const summary = {
      languageId: indexingSettings.languageId,
      indexingPreset: indexingSettings.indexingPreset,
      excludeRAI: indexingSettings.excludeRAI,
      includedAIs,
      excludedAIs,
      streamingPreset: indexingSettings.streamingPreset,
      privacy: indexingSettings.privacy,
      brandsCategories: selectedBrands,
      peopleModel: indexingSettings.peopleModelId && selectedPeopleModel ? selectedPeopleModel.name : null,
      logoGroup:
        isAdvancedIndexingPresetSelected(indexingSettings.indexingPreset) && indexingSettings.logoGroupId && selectedLogoGroup
          ? selectedLogoGroup.name
          : null
    };
    return summary;
  }
);
