import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { trimEnd } from 'lodash-es';

import { InsightsService } from '@common/modules/insights/services/insights.service';

import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { DataService } from '../../services/data.service';
import { resources } from './resources';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit, OnDestroy, OnChanges {
  // Selected item object
  @Input() public selectedItem;

  // Item type (Brand, Person, ..)
  @Input() public type: string;

  // Show text - Show biography / Show description ..
  @Input() public showButtonText: string;

  // Selected item info title
  @Input() public infoTitle = '';

  // Show selected item name
  @Input() public showName = true;
  @Input() public disabled?: boolean;
  @Input() public activeContainer?: boolean;
  @Input() public additionalHeight?: number = 0;
  @Input() public disableEllipsis = false;

  public showDescription = false;
  public resources = resources;
  public readonly MIN_HEIGHT = 21;
  public readonly LINE_HEIGHT = 20;
  public readonly PADDING = 20;
  public readonly FONT_SIZE = 13;
  public height = this.MIN_HEIGHT;

  @ViewChild('showMoreContainer', { static: false })
  private showMoreContainer: ElementRef;
  @ViewChild('textContainer', { static: false })
  private textContainer: ElementRef;
  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private insightsCommonUtils: InsightsCommonUtilsService,
    private dataService: DataService,
    private insightsService: InsightsService,
    private liveAnnouncer: LiveAnnouncer
  ) {}

  public ngOnInit() {
    this.translate.translateResources(resources);
    this.showButtonText = this.showButtonText || this.resources.ShowDescription;
    this.dataService
      .onResizeEventStream(200)
      .pipe(takeUntil(this.destroy$))
      .subscribe(e => this.changeHeight());
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    // If selected item was change, reset description
    if (changes.selectedItem && this.showDescription) {
      this.toggleShowDescription();
    }
  }

  public toggleShowDescription(): void {
    this.showDescription = !this.showDescription;
    const msg = this.showDescription ? resources.DescriptionBioExpanded : resources.DescriptionCollapsed;
    this.changeHeight();
    this.liveAnnouncer.announce(msg);
  }

  public getDescriptionLink(): string {
    return this.insightsCommonUtils.getDescriptionLink(this.selectedItem);
  }

  public hasLink(): boolean {
    if (this.selectedItem.description && (this.selectedItem.referenceUrl || this.selectedItem.referenceId)) {
      return true;
    }
    return false;
  }

  public descriptionID(): string {
    const prefix = this.type ? `${this.type}Description` : 'Description';
    const id = this.selectedItem ? this.selectedItem.id : '';
    return this.insightsCommonUtils.generateComponentID(prefix, id);
  }

  public getItemDescriptions(): string {
    if (this.selectedItem && this.selectedItem.description) {
      // If description ends with three dots - only add space
      if (this.selectedItem.description.endsWith('…') || this.disableEllipsis) {
        return `${this.selectedItem.description} `;
      }
      //  Remove dot and add three dots with space
      return `${trimEnd(this.selectedItem.description, '.')}… `;
    }
    return '';
  }

  private changeHeight() {
    setTimeout(() => {
      if (!this.showMoreContainer) {
        return;
      }
      // get the links container height
      this.insightsService.getContainerHeightAsync(this.showMoreContainer?.nativeElement).then(showMoreHeight => {
        this.height = showMoreHeight ? Math.max(this.MIN_HEIGHT, showMoreHeight) : this.MIN_HEIGHT;
        if (this.showDescription) {
          if (this.selectedItem && this.selectedItem.description) {
            this.insightsService.getContainerWidthAsync(this.textContainer.nativeElement).then(textContainerWidth => {
              const textWidth = this.insightsCommonUtils.measureWithTextSpacing(
                this.selectedItem.description + this.resources.ReadMore,
                this.FONT_SIZE
              );
              // divide the total line length by the host element's width and multiply by the line height to fet the required height.
              this.height += (textWidth / textContainerWidth) * this.LINE_HEIGHT + this.PADDING;
              this.height += this.additionalHeight;
              this.cdr.markForCheck();
            });
          }
        } else {
          this.cdr.markForCheck();
        }
      });
    }, 0);
  }
}
