import { IErrorTypes } from './interfaces';

export enum APIErrors {
  GENERAL = 'GENERAL',
  INVALID_INPUT = 'INVALID_INPUT',
  USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED',
  USER_NOT_SIGNED_IN = 'USER_NOT_SIGNED_IN',
  USER_NOT_SIGNED_IN_AAD = 'USER_NOT_SIGNED_IN_AAD',
  USER_NOT_REGISTERED = 'USER_NOT_REGISTERED',
  USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
  USER_NOT_ADMIN = 'USER_NOT_ADMIN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_SUSPENDED = 'USER_SUSPENDED',
  ACCESS_TOKEN_VALIDATION_FAILED = 'ACCESS_TOKEN_VALIDATION_FAILED',
  ANTI_FORGERY_TOKEN_VALIDATION_FAILED = 'ANTI_FORGERY_TOKEN_VALIDATION_FAILED',
  VIDEO_UPLOAD_LIMIT_EXCEEDED = 'VIDEO_UPLOAD_LIMIT_EXCEEDED',
  ACCOUNT_UPLOAD_QUOTA_EXCEEDED = 'ACCOUNT_UPLOAD_QUOTA_EXCEEDED',
  BREAKDOWN_NOT_FOUND = 'BREAKDOWN_NOT_FOUND',
  NOT_FOUND = 'NOT_FOUND',
  URL_UNREACHABLE = 'URL_UNREACHABLE',
  OFFLINE = 'OFFLINE',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  WIKI_ALREADY_EXISTS = 'WIKI_ALREADY_EXISTS',
  VIDEO_ALREADY_IN_PROGRESS = 'VIDEO_ALREADY_IN_PROGRESS',
  VIDEO_ALREADY_EXISTS = 'VIDEO_ALREADY_EXISTS',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  EVENT_GRID_NOT_REGISTERED = 'EVENT_GRID_NOT_REGISTERED',
  VIDEO_REMOVED = 'VIDEO_REMOVED',
  EMPTY_INSIGHTS = 'EMPTY_INSIGHTS',
  QuotaViolation = 'QuotaViolation',
  Unauthorized = 'Unauthorized',
  INVALID_PICTURE_SIZE = 'INVALID_PICTURE_SIZE',
  AMS_UNREACHABLE = 'AMS_UNREACHABLE',
  CUSTOM_VISION_CHARACTER_NOT_FOUND = 'CUSTOM_VISION_CHARACTER_NOT_FOUND',
  CUSTOM_VISION_MODEL_NOT_FOUND = 'CUSTOM_VISION_MODEL_NOT_FOUND',
  CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED = 'CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED',
  CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND = 'CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND',
  TOO_MANY_SOURCE_VIDEOS = 'TOO_MANY_SOURCE_VIDEOS ',
  CUSTOM_VISION_ACCOUNT_NOT_FOUND = 'CUSTOM_VISION_ACCOUNT_NOT_FOUND',
  CUSTOM_VISION_UNSUPPORTED_LOCATION = 'CUSTOM_VISION_UNSUPPORTED_LOCATION',
  INVALID_URL = 'INVALID_URL',
  CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION = 'CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION',
  CUSTOM_VISION_INVALID_TRAINING_KEY = 'CUSTOM_VISION_INVALID_TRAINING_KEY',
  CUSTOM_VISION_INVALID_PREDICTION_KEY = 'CUSTOM_VISION_INVALID_PREDICTION_KEY',
  OPERATION_NOT_SUPPORTED_ON_TRIAL = 'OPERATION_NOT_SUPPORTED_ON_TRIAL',
  VIDEO_NAME_TOO_LONG = 'VIDEO_NAME_TOO_LONG',
  AMS_AAD_APPLICATION_NOT_IN_TENANT = 'AMS_AAD_APPLICATION_NOT_IN_TENANT',
  VIDEO_INDEXING_IN_PROGRESS = 'VIDEO_INDEXING_IN_PROGRESS',
  USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER = 'USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER',
  INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS = 'INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS',
  LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND',
  FACE_IDENTIFICATION_NOT_ALLOWED = 'FACE_IDENTIFICATION_NOT_ALLOWED',
  FACE_CUSTOMIZATION_NOT_ENABLED = 'FACE_CUSTOMIZATION_NOT_ENABLED',
  DATASET_IN_USE = 'DATASET_IN_USE',
  MODEL_IN_USE = 'MODEL_IN_USE',
  SOURCE_VIDEO_DELETED = 'SOURCE_VIDEO_DELETED',
  PROJECT_RENDER_IN_PROGRESS = 'PROJECT_RENDER_IN_PROGRESS',
  VIDEO_CURRENTLY_BEING_INDEXED = 'VIDEO_CURRENTLY_BEING_INDEXED',
  STORAGE_UNREACHABLE = 'STORAGE_UNREACHABLE',
  VIDEO_NO_STREAMING_PRESET = 'VIDEO_NO_STREAMING_PRESET',
  STORAGE_ACCESS_DENIED = 'STORAGE_ACCESS_DENIED',
  MANAGED_IDENTITY_MISSING = 'MANAGED_IDENTITY_MISSING',
  NETWORK_ACCESS_NOT_ALLOWED = 'NETWORK_ACCESS_NOT_ALLOWED'
}

export const Errors: IErrorTypes = {
  GENERAL: {
    key: APIErrors.GENERAL,
    msg: 'General Error'
  },
  INVALID_INPUT: {
    key: APIErrors.INVALID_INPUT,
    msg: 'There is some problem with the input values'
  },
  USER_NOT_AUTHENTICATED: {
    key: APIErrors.USER_NOT_AUTHENTICATED,
    msg: 'Your session has timed out. Login again to resume your work.'
  },
  USER_NOT_SIGNED_IN: {
    key: APIErrors.USER_NOT_SIGNED_IN,
    msg: 'Your session has timed out. Please login again to resume your work.'
  },
  USER_NOT_SIGNED_IN_AAD: {
    key: APIErrors.USER_NOT_SIGNED_IN_AAD,
    msg: 'You are not logged in. Please login again to resume your work.'
  },
  USER_NOT_REGISTERED: {
    key: APIErrors.USER_NOT_REGISTERED,
    msg: 'You are not registered in the system'
  },
  USER_NOT_ALLOWED: {
    key: APIErrors.USER_NOT_ALLOWED,
    msg: 'You are not allowed to perform this action.'
  },
  USER_NOT_ADMIN: {
    key: APIErrors.USER_NOT_ADMIN,
    msg: 'You must be admin to perform this operation'
  },
  USER_NOT_FOUND: {
    key: APIErrors.USER_NOT_FOUND,
    msg: 'User not found in the system'
  },
  USER_SUSPENDED: {
    key: APIErrors.USER_SUSPENDED,
    msg: 'You are suspended. Please contact support.'
  },
  ACCESS_TOKEN_VALIDATION_FAILED: {
    key: APIErrors.ACCESS_TOKEN_VALIDATION_FAILED,
    msg: 'Your session is expired please refresh the page.'
  },
  ANTI_FORGERY_TOKEN_VALIDATION_FAILED: {
    key: APIErrors.ANTI_FORGERY_TOKEN_VALIDATION_FAILED,
    msg: 'Your session has timed out. Login again to resume your work.'
  },
  VIDEO_UPLOAD_LIMIT_EXCEEDED: {
    key: APIErrors.VIDEO_UPLOAD_LIMIT_EXCEEDED,
    msg: 'You cannot upload a video which is more than 4 GBs or over 4 hours.'
  },
  ACCOUNT_UPLOAD_QUOTA_EXCEEDED: {
    key: APIErrors.ACCOUNT_UPLOAD_QUOTA_EXCEEDED,
    msg: 'Your account has reached its maximum uploads quota.'
  },
  BREAKDOWN_NOT_FOUND: {
    key: APIErrors.BREAKDOWN_NOT_FOUND,
    msg: 'Breakdown is not found.'
  },
  NOT_FOUND: { key: APIErrors.NOT_FOUND, msg: 'Page is not found.' },
  URL_UNREACHABLE: {
    key: APIErrors.URL_UNREACHABLE,
    msg: 'The file url is unreachable.'
  },
  OFFLINE: {
    key: APIErrors.OFFLINE,
    msg: "Seems that you're gone offline. Please check your internet connection and try again."
  },
  ALREADY_EXISTS: {
    key: APIErrors.ALREADY_EXISTS,
    msg: 'Creating linguistic training model failed since the name is already exits.'
  },
  WIKI_ALREADY_EXISTS: {
    key: APIErrors.WIKI_ALREADY_EXISTS,
    msg: "Brand's URL address is already exits."
  },
  VIDEO_ALREADY_IN_PROGRESS: {
    key: APIErrors.VIDEO_ALREADY_IN_PROGRESS,
    msg: `This video is currently being uploaded. If you’d like to upload it again,
       please wait for the current upload to finish, and try again.`
  },
  VIDEO_ALREADY_EXISTS: {
    key: APIErrors.VIDEO_ALREADY_EXISTS,
    msg: `An attempt to upload this video recently failed. If you’d like to try to upload it again,
       please wait at least two hours from the last attempt, and try again.`
  },
  ACCOUNT_NOT_FOUND: {
    key: APIErrors.ACCOUNT_NOT_FOUND,
    msg: 'Account not found'
  },
  EVENT_GRID_NOT_REGISTERED: {
    key: APIErrors.EVENT_GRID_NOT_REGISTERED,
    msg: 'EventGrid resource provider is not registered'
  },
  QuotaViolation: {
    key: APIErrors.QuotaViolation,
    msg: 'The input text is too big. Please limit the input text to 200KB.'
  },
  VIDEO_REMOVED: {
    key: APIErrors.VIDEO_REMOVED,
    msg: 'The video has been removed'
  },
  EMPTY_INSIGHTS: {
    key: APIErrors.EMPTY_INSIGHTS,
    msg: 'No insights available'
  },
  Unauthorized: {
    key: APIErrors.Unauthorized,
    msg: 'Unauthorized'
  },
  INVALID_PICTURE_SIZE: {
    key: APIErrors.INVALID_PICTURE_SIZE,
    msg: 'INVALID_PICTURE_SIZE'
  },
  AMS_UNREACHABLE: {
    key: APIErrors.AMS_UNREACHABLE,
    msg: 'Failed to connect to Media Services account'
  },
  CUSTOM_VISION_MODEL_NOT_FOUND: {
    key: APIErrors.CUSTOM_VISION_MODEL_NOT_FOUND,
    msg: 'CUSTOM_VISION_MODEL_NOT_FOUND'
  },
  CUSTOM_VISION_CHARACTER_NOT_FOUND: {
    key: APIErrors.CUSTOM_VISION_CHARACTER_NOT_FOUND,
    msg: 'CUSTOM_VISION_CHARACTER_NOT_FOUND'
  },
  CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED: {
    key: APIErrors.CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED,
    msg: 'CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED'
  },
  CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND: {
    key: APIErrors.CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND,
    msg: 'CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND'
  },
  TOO_MANY_SOURCE_VIDEOS: {
    key: APIErrors.TOO_MANY_SOURCE_VIDEOS,
    msg: 'TOO_MANY_SOURCE_VIDEOS'
  },
  CUSTOM_VISION_ACCOUNT_NOT_FOUND: {
    key: APIErrors.CUSTOM_VISION_ACCOUNT_NOT_FOUND,
    msg: 'CUSTOM_VISION_ACCOUNT_NOT_FOUND'
  },
  CUSTOM_VISION_UNSUPPORTED_LOCATION: {
    key: APIErrors.CUSTOM_VISION_UNSUPPORTED_LOCATION,
    msg: 'CUSTOM_VISION_UNSUPPORTED_LOCATION'
  },
  INVALID_URL: {
    key: APIErrors.INVALID_URL,
    msg: 'INVALID_URL'
  },
  CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION: {
    key: APIErrors.CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION,
    msg: 'CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION'
  },
  CUSTOM_VISION_INVALID_TRAINING_KEY: {
    key: APIErrors.CUSTOM_VISION_INVALID_TRAINING_KEY,
    msg: 'CUSTOM_VISION_INVALID_TRAINING_KEY'
  },
  CUSTOM_VISION_INVALID_PREDICTION_KEY: {
    key: APIErrors.CUSTOM_VISION_INVALID_PREDICTION_KEY,
    msg: 'CUSTOM_VISION_INVALID_PREDICTION_KEY'
  },
  OPERATION_NOT_SUPPORTED_ON_TRIAL: {
    key: APIErrors.OPERATION_NOT_SUPPORTED_ON_TRIAL,
    msg: 'OPERATION_NOT_SUPPORTED_ON_TRIAL'
  },
  VIDEO_NAME_TOO_LONG: {
    key: APIErrors.VIDEO_NAME_TOO_LONG,
    msg: 'Name is too long. Maximum name length is 80'
  },
  AMS_AAD_APPLICATION_NOT_IN_TENANT: {
    key: APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT,
    msg: 'AMS_AAD_APPLICATION_NOT_IN_TENANT'
  },
  VIDEO_INDEXING_IN_PROGRESS: {
    key: APIErrors.VIDEO_INDEXING_IN_PROGRESS,
    msg: 'VIDEO_INDEXING_IN_PROGRESS'
  },
  INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS: {
    key: APIErrors.INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS,
    msg: 'INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS'
  },
  LOCATION_NOT_FOUND: {
    key: APIErrors.LOCATION_NOT_FOUND,
    msg: 'LOCATION_NOT_FOUND'
  },
  FACE_IDENTIFICATION_NOT_ALLOWED: {
    key: APIErrors.FACE_IDENTIFICATION_NOT_ALLOWED,
    msg: 'FACE_IDENTIFICATION_NOT_ALLOWED'
  },
  FACE_CUSTOMIZATION_NOT_ENABLED: {
    key: APIErrors.FACE_CUSTOMIZATION_NOT_ENABLED,
    msg: 'FACE_CUSTOMIZATION_NOT_ENABLED'
  },
  DATASET_IN_USE: {
    key: APIErrors.DATASET_IN_USE,
    msg: 'DATASET_IN_USE'
  },
  MODEL_IN_USE: {
    key: APIErrors.MODEL_IN_USE,
    msg: 'MODEL_IN_USE'
  },
  STORAGE_UNREACHABLE: {
    key: APIErrors.STORAGE_UNREACHABLE,
    msg: 'Failed to connect to Storage account'
  }
};

export const unauthorizedErrorTypes = [APIErrors.USER_NOT_AUTHENTICATED, APIErrors.USER_NOT_SIGNED_IN, APIErrors.USER_NOT_ALLOWED];
