import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';

import { CustomizationFilterType } from '../../customization/core/data-sources/filters';

type FluentListFilterValue = string | number | boolean;
export type FluentListFilter = Map<string, string | string[] | number[] | boolean>;

@Injectable()
export class CustomizationFilterService {
  private filter$: BehaviorSubject<FluentListFilter>;
  private currentFilter: FluentListFilter;

  constructor(private loggerService: LoggerService) {
    this.currentFilter = new Map();
    this.filter$ = new BehaviorSubject<FluentListFilter>(this.currentFilter);
    this.loggerService.log(`CustomizationFilterService:init`);
  }

  public getFilter(): BehaviorSubject<FluentListFilter> {
    return this.filter$;
  }

  public get filterLength() {
    return this.currentFilter.size;
  }

  public clearAllFilters(): void {
    this.currentFilter.clear();
    this.filter$.next(this.currentFilter);
    this.loggerService.log(`CustomizationFilterService:clearAllFilters`);
  }

  public addFilter(columnName: string, value: FluentListFilterValue): void {
    this.loggerService.log(`CustomizationFilterService:addFilter`);
    if (columnName in CustomizationFilterType) {
      this.currentFilter.set(columnName, <string>value);
      this.filter$.next(this.currentFilter);
      return;
    }
    this.loggerService.error(`CustomizationFilterService:unsupported filter name ${columnName}`);
  }

  public removeFilter(columnName: string): void {
    this.loggerService.log(`CustomizationFilterService:removeFilter`);
    if (columnName in CustomizationFilterType) {
      this.currentFilter.delete(columnName);
      this.filter$.next(this.currentFilter);
      return;
    }
    this.loggerService.error(`CustomizationFilterService:unsupported filter ${columnName}`);
  }
}
