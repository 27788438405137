import { Injectable } from '@angular/core';

import { EnvironmentNames } from '@common/modules/shared/environments';

import { IConfiguration, IFeatureSwitches } from '../interfaces';
import { environment } from '../../../../../environments/environment';
import { UrlService } from '../../../core/services/url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: IConfiguration = {
    codeLocation: '/',
    clientVersion: '0.0.0'
  };

  constructor(private urlUtility: UrlService) {
    this.loadConfig();
  }

  public loadConfig(): void {
    // Get filename based on location or host.
    const hostn = location.hostname.split('.')[0];

    // Get env type config
    const envTypeConfig = environment;

    // Get server config
    const nodeConfig = window.viewContext;

    this.config = Object.assign({}, envTypeConfig, nodeConfig);
  }

  public get Config(): IConfiguration {
    return this.config;
  }

  public get cdnDomain(): string {
    return (
      this.config.codeLocation
        // remove protocol
        ?.replace(/https:\/\//, '')
        // remove relative path (/spa)
        ?.replace(/\/[^\\]+$/, '')
    );
  }

  public get featureSwitches(): IFeatureSwitches {
    return this.config?.featureSwitches || {};
  }

  public isDebugEnabled(): boolean {
    return this.config.debug;
  }

  public getCodeVersion(): string {
    return this.config.clientVersion;
  }

  public getCodeLocation(): string {
    return this.config.codeLocation && this.config.clientVersion
      ? `${this.config.codeLocation}/${environment?.localhost ? '' : `${window.viewContext.clientVersion}/`}`
      : '/';
  }

  public getLocationQueryParam(): string {
    // Select for location param only if it's embed
    if (!this.isEmbedMode()) {
      return '';
    }
    const locationParam = this.urlUtility.getQueryParam('location');
    return locationParam ? locationParam.toLowerCase() : '';
  }

  public isEmbedMode() {
    const path = window.location.pathname;
    const regex = new RegExp(/embed/i);

    return regex.test(path);
  }

  public isFairFaxEnv(): boolean {
    return this.Config?.environmentName?.includes('fairfax') || false;
  }

  public isEnvType(environmentName: EnvironmentNames): boolean {
    return this.Config?.environmentName?.includes(environmentName) || false;
  }
}
