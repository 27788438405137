import { createAction, props } from '@ngrx/store';

export const loadLastEdgeExtension = createAction('[EdgeExtension/API] Load Last Edge Extension');
export const clearEdgeExtensions = createAction('[EdgeExtensions] Clear Edge Extensions');
export const clearSelectedEdgeExtension = createAction('[EdgeExtensions] Clear Selected Edge Extension');
export const selectEdgeExtension = createAction('[EdgeExtensions] Select Edge Extension', props<{ id: string }>());
export const selectEdgeExtensionClicked = createAction('[EdgeExtensions] Select Edge Extension Clicked', props<{ id: string }>());
export const selectEdgeExtensionByPrincipalId = createAction('[EdgeExtensions] Select Edge Extension By Principal', props<{ principalId: string }>());
export const uploadFile = createAction('[EdgeExtensions] Upload file to Edge Extension', props<{ file: File; name?: string }>());
export const loadEdgeExtensionsNotNeededForInit = createAction('[EdgeExtensions] Load Edge Extensions Not Needed For Init');
export const handshakeEdgeExtension = createAction('[EdgeExtension/API] Handshake Edge Extension');
export const handshakeSuccess = createAction('[EdgeExtension] Handshake Success');
export const handshakeFailed = createAction('[EdgeExtension] Handshake Failed');
export const liveModuleLoaded = createAction('[EdgeExtension] Live module Loaded');
