import { Action, createReducer, on } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';
import { PrivacyMode } from '@common/modules/core/interfaces';
import { guid } from '@common/modules/utils/string';
import { StreamingPreset } from '@common/modules/shared/interfaces';
import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { IIndexingSettings, LanguageAutoDetectMode, SupportedAIsMap } from '../../interfaces';
import * as ReIndexActions from '../actions/re-index.actions';
import { loadSupportedAIs, loadSupportedAIsFailed } from '../actions';
import { IndexingPreset } from '../../components/shared/settings';
import { SpecialLanguageKey } from '../../../core/languages';
import { ReIndexPages, FailureCode } from '../../components/re-index/interfaces';

import PlaylistContractV2 = Microsoft.VideoIndexer.Contracts.PlaylistContractV2;

export interface ReIndexState {
  displayedReIndexPage: ReIndexPages;
  videoId: string;
  videoIndex: PlaylistContractV2;
  supportedAIs?: SupportedAIsMap;
  isSupportedAIsLoaded?: boolean;
  isLoadSupportedAIsError?: boolean;
  indexingSettings?: IIndexingSettings;
  isIndexingSettingsLoaded: boolean;
  isProcessing: boolean;
  originalLanguageId: string;
  reviewState?: string;
  moderationState?: string;
  reIndexErrorType: APIErrors | FailureCode;
}

export const initialReIndexState: ReIndexState = {
  displayedReIndexPage: ReIndexPages.IndexingForm,
  videoId: null,
  videoIndex: null,
  supportedAIs: {} as SupportedAIsMap,
  isSupportedAIsLoaded: false,
  isLoadSupportedAIsError: false,
  indexingSettings: null,
  isIndexingSettingsLoaded: false,
  isProcessing: false,
  originalLanguageId: '',
  reviewState: 'None',
  moderationState: 'OK',
  reIndexErrorType: null
};

const reIndexReducer = createReducer(
  initialReIndexState,
  on(ReIndexActions.initReIndexIndexingSettings, (state, { videoIndex, streamingPreset, reviewState, moderationState }) => {
    return {
      ...state,
      videoIndex,
      indexingSettings: {
        privacy: videoIndex?.privacyMode as PrivacyMode,
        name: videoIndex?.name,
        indexingPreset: videoIndex?.videos[0].indexingPreset as IndexingPreset,
        excludeAIs: (videoIndex?.videos[0].excludedAIs || []) as ExcludableAIsModels[],
        peopleModelId: getPersonId(videoIndex?.videos[0].personModelId),
        streamingPreset: streamingPreset as StreamingPreset,
        description: videoIndex?.description,
        metadata: videoIndex?.videos[0].metadata,
        languageId: getLanguageId(videoIndex),
        brandsCategories: null,
        logoGroupId: videoIndex?.videos[0].logoGroupId
      },
      originalLanguageId: getLanguageId(videoIndex),
      reviewState,
      moderationState
    };
  }),
  on(ReIndexActions.resetReIndexState, () => {
    return Object.assign({}, initialReIndexState);
  }),
  on(ReIndexActions.loadVideoIndex, state => {
    return {
      ...state,
      isIndexingSettingsLoaded: false
    };
  }),
  on(ReIndexActions.initVideoId, (state, { videoId }) => {
    return {
      ...state,
      videoId: videoId
    };
  }),
  on(ReIndexActions.updateReIndexIndexingSettings, (state, { settings }) => {
    return {
      ...state,
      indexingSettings: {
        ...state.indexingSettings,
        ...settings
      }
    };
  }),
  on(loadSupportedAIs, state => {
    return {
      ...state,
      isSupportedAIsLoaded: false
    };
  }),
  on(loadSupportedAIsFailed, state => {
    return {
      ...state,
      supportedAIs: {},
      isLoadSupportedAIsError: true,
      isSupportedAIsLoaded: true
    };
  }),
  on(ReIndexActions.updateReIndexPage, (state, { page }) => {
    return {
      ...state,
      displayedReIndexPage: page
    };
  }),
  on(ReIndexActions.updateReIndexSupportedAIsState, (state, { supportedAIs: ais }) => {
    const updatedSupportedAIs = {};
    ais.forEach(({ name, checked, disabled }) => {
      updatedSupportedAIs[name] = {
        ...state.supportedAIs[name],
        checked: checked,
        disabled: disabled
      };
    });
    return {
      ...state,
      supportedAIs: updatedSupportedAIs
    };
  }),
  on(ReIndexActions.updateReIndexSupportedAIs, (state, { supportedAIs }) => {
    return {
      ...state,
      supportedAIs: supportedAIs,
      isSupportedAIsLoaded: true,
      isLoadSupportedAIsError: false
    };
  }),
  on(ReIndexActions.indexingSettingsLoaded, state => {
    return {
      ...state,
      isIndexingSettingsLoaded: true
    };
  }),
  on(ReIndexActions.updateOriginalLanguageId, (state, { languageId }) => {
    return {
      ...state,
      originalLanguageId: languageId
    };
  }),
  on(ReIndexActions.reIndexVideo, state => {
    return {
      ...state,
      isProcessing: true
    };
  }),
  on(ReIndexActions.reIndexVideoSucceeded, state => {
    return {
      ...state,
      isProcessing: false
    };
  }),
  on(ReIndexActions.reIndexVideoFailed, (state, { errorType }) => {
    return {
      ...state,
      isProcessing: false,
      reIndexErrorType: errorType
    };
  }),
  on(ReIndexActions.getVideoIndexFailed, (state, { errorType }) => {
    return {
      ...state,
      isIndexingSettingsLoaded: true,
      reIndexErrorType: errorType
    };
  })
);

export function reducer(state: ReIndexState | undefined, action: Action) {
  return reIndexReducer(state, action);
}

function getLanguageId(videoIndex: PlaylistContractV2) {
  // if video was indexed with language/speech model
  if (videoIndex?.videos[0]?.linguisticModelId !== guid(0)) {
    return videoIndex?.videos[0].linguisticModelId;
  }

  // if video was indexed with auto detect single language
  if (videoIndex?.videos[0]?.languageAutoDetectMode === LanguageAutoDetectMode.Single) {
    return SpecialLanguageKey.AUTO;
  }

  // if video was indexed with auto detect multi language
  if (videoIndex?.videos[0]?.languageAutoDetectMode === LanguageAutoDetectMode.Multi || videoIndex?.videos[0]?.sourceLanguages?.length > 1) {
    return SpecialLanguageKey.MULTI;
  }

  // detect with some language
  return videoIndex?.videos[0]?.language;
}

function getPersonId(modelId: string) {
  // model id can be guid(0) when the default model has not been used yet.
  // In this case we save empty string - same as we save in upload state
  if (modelId === guid(0)) {
    return '';
  }
  return modelId;
}
