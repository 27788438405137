import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, mergeMap, withLatestFrom, EMPTY } from 'rxjs';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ApiService } from '@common/modules/api/services/api.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { IFile, ILanguage, UploadMode, UploadingState, UploadingStateError } from './../../interfaces';
import { IState } from './../reducers/index';
import * as actions from '../actions';
import * as fromIndexing from '../selectors';
import * as fromEdgeSupportedLanguages from '../../../customization-data/selectors/edge-supported-languages.selectors';
import * as fromCore from '../../../core/selectors';
import * as LibraryActions from '../../../gallery/core/actions/library.actions';
import {
  getLanguageParam,
  UPLOAD_PROCESS_FINISHED_PERCENTAGE,
  getFileTraceData,
  getUploadSettingsTraceData,
  getExcludeAIsParams
} from './utils/indexing-utils';
import { convertToEdgeUploadParams, getCustomEdgeLanguagesParam } from './utils/edge-indexing-utils';

@Injectable()
export class EdgeUploadEffects {
  public uploadFilesToEdge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadFileToEdge),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingSettings)),
      withLatestFrom(this.store.select(fromIndexing.getLanguage)),
      withLatestFrom(this.store.select(fromEdgeSupportedLanguages.selectEdgeSupportedLanguages)),
      withLatestFrom(this.store.select(fromIndexing.selectUploadMode)),
      mergeMap(([[[[[{ storeFile }, accountId], indexingSettings], language], languageList], mode]) => {
        if (!accountId || !indexingSettings || !language || !storeFile) {
          return [actions.uploadFileToEdgeFailed({ storeFile, uploadingStateError: UploadingStateError.UploadToEdgeInitFailed })];
        }

        const isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);

        const customLanguages = getCustomEdgeLanguagesParam(language as ILanguage, languageList);
        const languageSettings = getLanguageParam(language);
        const uploadSettings = convertToEdgeUploadParams(indexingSettings, languageSettings, storeFile, customLanguages);
        const excludeAIParams = isExcludeAIsEnabled ? getExcludeAIsParams(indexingSettings.excludeAIs) : [];

        const file = mode === UploadMode.File ? storeFile.fileContent : null;

        this.trackService.track('upload_dialog.upload_file_to_edge.started', {
          category: EventCategory.UPLOAD,
          data: { ...getFileTraceData(storeFile), ...getUploadSettingsTraceData(uploadSettings), excludeAIParams }
        });

        return this.apiService.EdgeExtensions.uploadFile(accountId, file, uploadSettings, excludeAIParams).pipe(
          mergeMap((event: HttpEvent<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                return [
                  actions.updateFile({
                    fileId: storeFile.id,
                    file: { progress: Math.floor((event.loaded / event.total) * UPLOAD_PROCESS_FINISHED_PERCENTAGE) }
                  }),
                  actions.uploadingFilesInProgress()
                ];
              case HttpEventType.Response:
                this.trackService.track('upload_dialog.upload_file_to_edge.succeeded', {
                  category: EventCategory.UPLOAD,
                  data: getFileTraceData(storeFile)
                });
                const fileUpdates: Partial<IFile> = { uploadingState: UploadingState.Completed, progress: UPLOAD_PROCESS_FINISHED_PERCENTAGE };
                return [
                  actions.updateFile({
                    fileId: storeFile.id,
                    file: fileUpdates
                  }),
                  actions.uploadFileFinished({ file: { ...storeFile, ...fileUpdates } }),
                  LibraryActions.loadVideo({ id: event?.body?.id })
                ];
              default:
                return EMPTY;
            }
          }),
          catchError(error => {
            this.trackService.track('upload_dialog.upload_file_to_edge.failed', {
              category: EventCategory.UPLOAD,
              data: { ...getFileTraceData(storeFile), error: error?.error }
            });
            return [actions.uploadFileToEdgeFailed({ storeFile, uploadingStateError: UploadingStateError.UploadToEdgeFailed, error })];
          })
        );
      })
    )
  );
  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private store: Store<IState>,
    private trackService: TrackService,
    private apiService: ApiService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.init();
  }

  private init() {
    this.logger.log('[EdgeUploadEffects] On Init Effects');
  }
}
