import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { camelCase, isEmpty, orderBy, random, reduce, upperFirst } from 'lodash-es';
import * as pixelWidth from 'string-pixel-width';

import { BING_SEARCH } from '@common/modules/shared/links';

import { ConfigService } from '../core/services/config/config.service';
import { FeatureSwitchService } from '../core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '../core/services/interfaces';
import {
  IInsightsSortedBy,
  IInstancesToAppearancesOutput,
  InsightsSortedProperty,
  ISubscriptions,
  IUIAppearance,
  IUIBasicInsight,
  IUIInsight,
  IUIPreset,
  IUIPresetInsight
} from '../insights/interfaces';
import { IndexState } from '../shared/interfaces';
import { getSeconds } from '../utils/time';
import { IInsightJsonKeyType, IUIInsightJsonKey, ThumbnailClass } from './interfaces';
import { InsightsWithAppearances, InsightsWithInstances } from '../insights/components/mentioned-entities/interfaces';

@Injectable()
export class InsightsCommonUtilsService {
  public FaceFallbackUrl: string;
  public canvasElem: HTMLCanvasElement;
  public ctx: CanvasRenderingContext2D;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.canvasElem = this.document.createElement('canvas');
    this.ctx = this.canvasElem.getContext('2d');

    const base = this.getBasePath();
    this.FaceFallbackUrl = `${base}assets/images/face_empty.png`;
  }

  /**
   * @param {string} dataKey
   * @param {Microsoft.VideoIndexer.Contracts.PlaylistContractV2} videoIndex
   * @returns {boolean}
   * @memberof InsightsCommonService
   * @description Checks if given insights has data inside the video index.
   */
  public hasData(dataKey: IInsightJsonKeyType, videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2): boolean {
    const summarizedInsights = ['faces'];

    const insights = [
      'transcript',
      'ocr',
      'shots',
      'speakers',
      'scenes',
      'emotions',
      'sentiments',
      'keywords',
      'labels',
      'topics',
      'brands',
      'namedLocations',
      'namedPeople',
      'audioEffects',
      'observedPeople',
      'clapperboards',
      'logos',
      'detectedObjects'
    ];

    if (dataKey === IUIInsightJsonKey.logos && !this.featureSwitchService.featureSwitch(FeatureSwitch.Logos)) {
      return false;
    }

    if (summarizedInsights.includes(dataKey)) {
      return !(
        !videoIndex ||
        !videoIndex.summarizedInsights ||
        !videoIndex.summarizedInsights[dataKey] ||
        !videoIndex.summarizedInsights[dataKey].length
      );
    } else if (insights.includes(dataKey)) {
      return !(!videoIndex || !videoIndex.videos || !this.hasVideosData(dataKey, videoIndex?.videos));
    } else if (dataKey === 'spokenLanguage') {
      // Custom data
      return this.isMultiLanguage(videoIndex);
    } else {
      return false;
    }
  }

  public hasVideosData(dataKey: IInsightJsonKeyType, videos: Microsoft.VideoIndexer.Contracts.VideoContract[]): boolean {
    for (const video of videos) {
      if (video && video.insights && video.insights[dataKey] && video.insights[dataKey].length) {
        return true;
      }
    }
    return false;
  }

  // text width is being measured by 2 different methods , max score is being returned
  public measureText(txt = '', fontSize = 13, fontFamily = 'Segoe UI'): number {
    this.ctx.font = `${fontSize}px ${fontFamily}`;
    const maxInput = reduce(txt.split('<br>'), (a, b) => (a.toString().length > b.toString().length ? a : b));

    return Math.ceil(this.ctx.measureText(maxInput).width ?? pixelWidth(maxInput, { size: fontSize, font: 'open sans' }));
  }

  public getCalculatedFontFamily(el: Element): string {
    const computedStyle = window.getComputedStyle(el);
    return computedStyle.getPropertyValue('font-family');
  }

  // returns an approximation of the maximum required padding for text spacing (spacing values accurate for WCAG 2.1)
  public measureTextSpacing(txt = '', fontSize = 13): number {
    const numSpaces = txt.split(' ').length - 1;
    const numLetters = txt.length - numSpaces * 2;
    return Math.ceil(fontSize * (numSpaces * 0.2 + numLetters * 0.2));
  }

  public measureWithTextSpacing(txt = '', fontSize = 13): number {
    this.ctx.font = `${fontSize}px Segoe UI`;
    const maxInput = reduce(txt.split('<br>'), (a, b) => (a.toString().length > b.toString().length ? a : b));
    const numSpaces = txt.split(' ').length - 1;
    const numLetters = txt.length - numSpaces * 2;
    const padding = Math.ceil(fontSize * (numSpaces * 0.2 + numLetters * 0.2));
    const txtLength = Math.ceil(this.ctx.measureText(maxInput).width ?? pixelWidth(maxInput, { size: fontSize, font: 'open sans' }));
    return padding + txtLength;
  }
  public unsubscribeSubscriptions(subscriptions: ISubscriptions): void {
    const keys = Object.keys(subscriptions);
    keys.forEach(k => {
      if (subscriptions[k]) {
        subscriptions[k].unsubscribe();
      }
    });
  }

  public isIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public getBasePath(): string {
    return this.configService.getCodeLocation();
  }

  public isEmptyID(url: string): boolean {
    return !url || url.includes('00000000-0000-0000-0000-000000000000');
  }

  public getThumbnailClass(state: string, thumbnailUrl: string): ThumbnailClass {
    if (this.isEmptyID(thumbnailUrl)) {
      /**
       * @todo temp change. For now all thumb except audio are set-thumb
       */
      if (!state || state === IndexState.Processing || state === IndexState.Uploaded) {
        return 'set-thumb';
      } else if (state === IndexState.Failed) {
        return 'block';
      } else if (state === IndexState.Deleting) {
        return 'block';
      } else {
        return 'audio';
      }
    } else if (state === IndexState.Failed) {
      return 'block';
    }

    return 'image';
  }

  // Define Array<IUIPresetInsight | IUIPreset> = IUIPresetInsight[] | IUIPreset[]
  // 2nd option results typescript error compilation.
  public resetPresets(presets: Array<IUIPresetInsight | IUIPreset>): void {
    presets.forEach(p => (p.selected = false));
  }

  public isMultiLanguage(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2): boolean {
    if (!videoIndex) {
      return false;
    }
    // Multi language video
    if (videoIndex?.videos && videoIndex?.videos[0]?.sourceLanguages?.length > 1) {
      return true;
    }

    // Multi language project
    if (videoIndex.videos.length > 1) {
      // Video is a project - check if multi language project
      const firstLanguage = videoIndex.videos[0].sourceLanguage;
      for (let index = 1; index < videoIndex.videos.length; index++) {
        const video = videoIndex.videos[index];
        if (firstLanguage !== video.sourceLanguage) {
          return true;
        }
      }
    }

    return false;
  }

  public getDescriptionLink(selectedItem): string {
    if (!selectedItem) {
      return '';
    }

    if (selectedItem.referenceUrl) {
      return selectedItem.referenceUrl;
    }

    if (selectedItem.name) {
      return this.getBingLink(selectedItem.name);
    }
    return '';
  }

  public getBingLink(itemName): string {
    if (!itemName) {
      return '';
    }
    return `${BING_SEARCH}?q=${itemName}`;
  }

  public generateComponentID(prefix: string, id: string): string {
    // If there is no prefix, add general
    if (!prefix) {
      prefix = 'component';
    }

    // If there is no ID, generate an id
    if (!id) {
      id = random(1, 100).toString();
    }

    return `${prefix}${id}`;
  }

  public createInsightsArray(videos: Microsoft.VideoIndexer.Contracts.VideoContract[], type: string) {
    let insightRef = [];
    let items = [];
    for (const video of videos) {
      insightRef = video.insights[type];
      if (insightRef) {
        items = [...items, ...insightRef];
      }
    }

    return items;
  }

  public hasInsightsData(index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2, type: IUIInsightJsonKey) {
    if (!index || !type) {
      return false;
    }
    // check if at least one video has type data
    for (const video of index?.videos) {
      if (video.insights && video.insights[type]) {
        return true;
      }
    }
    return false;
  }

  public convertInstancesToAppearances(instances: Microsoft.VideoIndexer.Contracts.ElementInstance[]): IInstancesToAppearancesOutput {
    const appearances = [];
    let seenDurationRatio = 0;
    for (const instance of instances) {
      const appearance: IUIAppearance = {
        startSeconds: getSeconds(instance?.adjustedStart),
        endSeconds: getSeconds(instance?.adjustedEnd),
        endTime: instance?.adjustedEnd,
        startTime: instance?.adjustedStart
      };
      if (instance?.adjustedStart && instance?.adjustedEnd) {
        seenDurationRatio += appearance.endSeconds - appearance.startSeconds;
      }
      appearances.push(appearance);
    }

    return {
      appearances: appearances,
      seenDurationRatio: seenDurationRatio
    };
  }

  public getUIObservedClothing(clothing: Microsoft.VideoIndexer.Contracts.PersonAttributeInsight[], resources: {}): IUIBasicInsight[] {
    const observedClothing: IUIBasicInsight[] = [];
    if (!clothing) {
      return observedClothing;
    }

    let id = 0;
    let name = '';
    for (const clothingItem of clothing) {
      if (clothingItem.properties && !isEmpty(clothingItem.properties)) {
        for (const propertyValue of Object.values(clothingItem.properties)) {
          name = resources[`${upperFirst(camelCase(propertyValue))}${upperFirst(camelCase(clothingItem.type))}`];
          if (name) {
            observedClothing.push({
              id: ++id,
              name: name
            });
          }
        }
      } else {
        name = resources[upperFirst(camelCase(clothingItem.type))];
        if (name) {
          observedClothing.push({
            id: ++id,
            name: name
          });
        }
      }
    }

    return observedClothing;
  }

  public getSortedItems(items: IUIInsight[], sortedBy: IInsightsSortedBy) {
    if (sortedBy?.property === InsightsSortedProperty.START_TIME || sortedBy?.property === InsightsSortedProperty.END_TIME) {
      const orderByMethod = (item: IUIInsight) => {
        const index = sortedBy?.property == InsightsSortedProperty.START_TIME ? 0 : item.appearances.length - 1;
        // Convert from start time to the correct property from contract
        const property = sortedBy?.property === InsightsSortedProperty.START_TIME ? 'startSeconds' : 'endSeconds';
        return item.appearances[index][property];
      };
      return orderBy(items, orderByMethod, [sortedBy?.order]);
    } else if (sortedBy?.property === InsightsSortedProperty.NAME) {
      return orderBy(items, [item => item && item?.name?.toLowerCase()], [sortedBy?.order]);
    } else {
      return orderBy(items, [sortedBy?.property], [sortedBy?.order]);
    }
  }

  public removeDuplicates2<T extends IUIInsight>(items: T[]): T[] {
    const insightItemsMap: { [name: string]: T } = {};

    for (const item of items) {
      const { name, appearances } = item;

      if (insightItemsMap[name]) {
        insightItemsMap[name].appearances = insightItemsMap[name].appearances.concat(appearances);
        delete insightItemsMap[name].confidence; // Remove confidence as it's irrelevant across multiple videos
      } else {
        insightItemsMap[name] = item;
      }
    }

    return Object.values(insightItemsMap);
  }

  /**
   * Remove duplicate insights, was created to support merging of insights multiple videos in projects
   * @param items
   * @returns list of insights
   */
  public mergeInsightsWithInstances<T extends InsightsWithInstances | InsightsWithAppearances>(items: T[]): T[] {
    const insightItemsMap: { [name: string]: T } = {};

    for (const item of items) {
      // eslint-disable-next-line max-len
      const uniqueKey = (item as InsightsWithAppearances).name || (item as Microsoft.VideoIndexer.Contracts.Keyword).text; // add type assertion to item
      const appearancesOrInstancesKey = 'appearances' in item ? 'appearances' : 'instances';
      const appearancesOrInstances = item[appearancesOrInstancesKey];
      if (insightItemsMap[uniqueKey]) {
        insightItemsMap[uniqueKey][appearancesOrInstancesKey] = insightItemsMap[uniqueKey][appearancesOrInstancesKey].concat(appearancesOrInstances);
        delete insightItemsMap[uniqueKey].confidence; // Remove confidence as it's irrelevant across multiple videos
      } else {
        // First occurrence of this insight
        insightItemsMap[uniqueKey] = item;
      }
    }

    return Object.values(insightItemsMap);
  }
}
