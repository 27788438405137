import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';

import { find } from 'lodash-es';

import { ZoneWrapperService } from '@common/modules/core/services/zone-wrapper/zone-wrapper.service';

import { UIAddCapsuleMode } from './interfaces';
import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { isMainButtonPressed } from '../../../utils/event';
import { IUIInsight } from '../../interfaces';

@Component({
  selector: 'app-vi-insights-capsule-input',
  templateUrl: './capsule-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./capsule-input.component.scss']
})
export class CapsuleInputComponent implements OnInit {
  // Inputs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public items?: IUIInsight[] = [];
  @Input() public text = 'Add';
  @Input() public EnterText = 'Enter';
  @Input() public activeContainer = false;

  // Outputs
  @Output() public addCapsule = new EventEmitter<string>();

  // Publics
  @ViewChild('addCapsuleInput', { static: false }) public input: ElementRef;

  public UIAddCapsuleMode = UIAddCapsuleMode;
  public addCapsuleMode = UIAddCapsuleMode.DEFAULT;
  public placeholder = '';
  public resources = resources;

  constructor(private translate: TranslateHelperService, private cdr: ChangeDetectorRef, private zone: ZoneWrapperService) {}

  public ngOnInit() {
    // Translate resources
    this.translate.translateResources(this.resources);
  }

  public changeState(input?: string, $event?: Event) {
    if (this.addCapsuleMode === UIAddCapsuleMode.DEFAULT) {
      this.addCapsuleMode = UIAddCapsuleMode.CLICKED;
      this.focusInput();
    }

    if ($event) {
      $event.stopPropagation();
    }

    return false;
  }

  public add(input?: string) {
    if (this.isExistingCapsule(input)) {
      return false;
    }
    this.addCapsuleMode = UIAddCapsuleMode.ADDING;
    this.addCapsule.emit(input);
    this.addCapsuleMode = UIAddCapsuleMode.DEFAULT;
    this.cdr.markForCheck();
  }

  public handleInputClick($event: MouseEvent) {
    if (isMainButtonPressed($event)) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  public reset() {
    this.addCapsuleMode = UIAddCapsuleMode.DEFAULT;
  }

  public focusInput() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 10);
    });
  }

  private isExistingCapsule(input: string) {
    if (!this.items.length || !input) {
      return false;
    }
    return find(this.items, item => {
      return item.name.toLocaleLowerCase() === input.toLowerCase();
    });
  }
}
