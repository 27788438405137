import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

@Directive({
  selector: '[appDebounce]'
})
export class DebounceDirective implements OnInit, OnDestroy {
  // The distinct value
  @Input() public debounceValue;

  // Debounce duration time
  @Input() public debounceDueTime = 800;

  // Event names array which debounce should consider
  @Input() public debounceEventNames = ['click'];

  // Output complete debounce
  @Output() public debounceEvent = new EventEmitter();

  private subscription: Subscription;
  private eventsSubject;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  public ngOnInit() {
    // Initial value for distinctUntilChanged function
    this.eventsSubject = new BehaviorSubject(this.debounceValue);
    // Add listener to all events
    this.addListen();
    // Create subscription
    this.subscription = this.eventsSubject
      .pipe(debounceTime(this.debounceDueTime))
      .pipe(distinctUntilChanged())
      // Pass emit initial value
      .pipe(skip(1))
      .subscribe(e => {
        this.debounceEvent.emit(e);
      });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private addListen(): void {
    // add listen to each event
    this.debounceEventNames.forEach(eventName => {
      this.renderer.listen(this.el.nativeElement, eventName, event => {
        event.preventDefault();
        event.stopPropagation();
        this.eventsSubject.next(this.debounceValue);
      });
    });
  }
}
