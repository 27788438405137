import { Injectable } from '@angular/core';

import urlParse from 'url-parse';

@Injectable()
export class UrlService {
  constructor() {
    window['global'] = window['global'] || null;
  }

  public getQueryParam(queryParam: string) {
    return this.urlParse.query[queryParam];
  }

  public getRouteParam(param: string) {
    return this.captureParamFromUrl(param, this.url);
  }

  public getParam(param: string) {
    return this.getQueryParam(param) || this.getRouteParam(param);
  }

  public paramsToUrl(obj): string {
    // Check obj not null or empty
    if (!obj) {
      return '';
    }
    let str = '';
    const params = Object.keys(obj);
    params.forEach(param => {
      // In case of an empty value, don't chain it to the url params
      if (!obj[param]) {
        return;
      }
      if (str !== '') {
        str += '&';
      }
      str += param + '=' + encodeURIComponent(obj[param]);
    });

    return str;
  }

  private captureParamFromUrl(param, url): string {
    if (!param || (param !== 'videoId' && param !== 'accountId')) {
      return '';
    }

    const idRegex = /(videos|[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})\/([0-9a-zA-Z]{10,12})/;
    const accountRegex = /(accounts|insights|player|media)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})/;
    let regex = idRegex;
    if (param === 'accountId') {
      regex = accountRegex;
    }
    const match = url.match(regex);

    return (match && match[2]) || '';
  }

  private get url() {
    return window.location.href;
  }

  private get urlParse() {
    return urlParse(this.url, null, true);
  }
}
