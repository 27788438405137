export enum IndexingSettingKeys {
  IncludedAIs = 'IncludedModelsLabel',
  ExcludedAIs = 'ExcludedModelsLabel',
  IndexedBy = 'IndexedBy',
  GalleryItemLastModified = 'GalleryItemLastModified',
  ReindexModalVideoSourceLanguage = 'ReindexModalVideoSourceLanguage',
  IndexingPresetLabel = 'IndexingPresetLabel',
  UploadPrivacyLabel = 'UploadPrivacyLabel',
  UploadStreaming = 'UploadStreaming',
  UploadPeopleModel = 'UploadPeopleModel',
  UploadLogoGroupOption = 'UploadLogoGroupOption',
  BrandsModel = 'BrandsModel'
}

export interface indexingSetting {
  value: string;
  children?: indexingSetting[];
  key: IndexingSettingKeys;
  text: string;
  childrenClassName?: string;
}

export interface indexingSummaryData {
  videoThumbnailUrl: string;
  videoTitle: string;
  videoDescription: indexingSetting[];
  indexingSettingsDetails: indexingSetting[];
}
