import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import { INextPage } from '../models/gallery';
import * as SamplesActions from '../actions/samples.actions';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<SinglePlaylistSearchResultV2> {
  // additional entities state properties
  selectedVideoId: string;
  loaded: boolean;
  loading: boolean;
  nextPage?: INextPage;
  loadingNext: boolean;
  error: boolean;
  errorType?: APIErrors;
}

function selectVideoId(a: SinglePlaylistSearchResultV2): string {
  return a.id;
}

const adapter: EntityAdapter<SinglePlaylistSearchResultV2> = createEntityAdapter<SinglePlaylistSearchResultV2>({
  selectId: selectVideoId
});

const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedVideoId: null,
  loaded: false,
  loading: false,
  nextPage: {
    pageSize: null,
    skip: null,
    done: false
  },
  loadingNext: false,
  error: false
});

const samplesReducer = createReducer(
  initialState,
  on(SamplesActions.upsertVideos, (state, { videos, nextPage }) => {
    return adapter.upsertMany(videos, {
      ...state,
      loaded: true,
      nextPage: nextPage,
      loadingNext: false,
      loading: false
    });
  }),
  on(SamplesActions.clearSamples, (state, {}) => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      loading: false,
      nextPage: {
        pageSize: null,
        skip: null,
        done: false
      },
      loadingNext: false,
      error: false
    });
  }),
  on(SamplesActions.loadSamplesNextPage, (state, {}) => {
    return {
      ...state,
      loadingNext: true
    };
  }),
  on(SamplesActions.loadSamplesError, (state, {}) => {
    return {
      ...state,
      error: true
    };
  }),
  on(SamplesActions.loadingSamples, (state, {}) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(SamplesActions.deleteVideoSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(SamplesActions.updateVideo, (state, { video }) => {
    return adapter.upsertOne(video, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return samplesReducer(state, action);
}
