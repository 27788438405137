import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appViImageFallback]'
})
export class ImageFallbackDirective {
  @Input('appViImageFallback') public fallbackSrc: string;

  constructor() {}

  @HostListener('error', ['$event']) public onError(event) {
    event.srcElement.src = this.fallbackSrc;
  }
}
