import { Injectable, OnDestroy } from '@angular/core';

import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { ApiService } from '@common/modules/api/services/api.service';
import { AuthService } from '@common/modules/auth/services/auth.service';

import { EdgeExtensionsStoreService } from './edge-extensions-store.service';

@Injectable({
  providedIn: 'root'
})
export class EdgeExtensionsApiHandlerService implements OnDestroy {
  protected destroy$ = new Subject<void>();

  private hasSelectedEdgeExtension = false;

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private extensionsStore: EdgeExtensionsStoreService,
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init() {
    if (!this.featureSwitchService.featureSwitch(FeatureSwitch.Edge)) {
      return;
    }

    this.extensionsStore.accountEdgeExtensionUrl$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.extensionsStore.selectedEdgeExtensionId$))
      .subscribe(([url, extensionId]) => {
        this.apiService.edgeExtensionApiUrl = url;
        this.hasSelectedEdgeExtension = !!url;
        this.authService.SelectedEdgeExtensionId = extensionId;
      });
  }

  public get HasSelectedEdgeExtension() {
    return this.hasSelectedEdgeExtension;
  }
}
