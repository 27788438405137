// Please find reference at : https://aka.ms/upload-standard-formats

export function generateSupportedMediaTypesPattern(): string {
  const mediaTypes = Object.values(CloudMediaFileFormats);

  return mediaTypes ? `audio/*,.${mediaTypes.join(',.')}` : '';
}

export enum CloudMediaFileFormats {
  FLV = 'flv',
  MXF = 'mxf',
  GXF = 'gxf',
  TS = 'ts',
  PS = 'ps',
  THREE_GP = '3gp',
  THREE_GPP = '3gpp',
  MPG = 'mpg',
  MP4 = 'mp4',
  WMV = 'wmv',
  ASF = 'asf',
  AVI = 'avi',
  M4A = 'm4a',
  M4V = 'm4v',
  ISMV = 'ismv',
  ISMA = 'isma',
  DVR_MS = 'dvr-ms',
  MKV = 'mkv',
  WAV = 'wav',
  MOV = 'mov',
  WEBM = 'webm',
  GSM = 'gsm'
}

export enum ImageFileFormats {
  PNG = 'png',
  MJPEG = 'mjpeg',
  GIF = 'gif',
  JPG = 'jpg',
  JPEG = 'jpeg',
  TIFF = 'tiff'
}

export enum EdgeMediaFileFormats {
  FLV = 'flv',
  MXF = 'mxf',
  MP4 = 'mp4',
  AVI = 'avi',
  WAV = 'wav',
  MOV = 'mov',
  MP3 = 'mp3'
}

export function getFileType(file: string): string {
  // Take file name if it has an extension
  const typeRegex = new RegExp(/.+(\.\w{2,4})$/g);
  const typeValue = typeRegex.exec(file);

  if (typeValue && typeValue.length > 1) {
    return typeValue[1];
  }
  return '';
}
