import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { IFile } from '../../../interfaces';
import { MAX_VIDEO_NAME_LENGTH } from '../../../utils/file';

@Component({
  selector: 'vi-indexing-file-name-input',
  templateUrl: './file-name-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileNameInputComponent implements OnInit {
  @Input() public file: IFile;
  @Input() public disabled?: boolean;
  @Input() public label?: string;
  @Input() public errorMessage?: string;
  @Output() public nameChanged = new EventEmitter<IFile>();

  public filename: string;
  public MAX_VIDEO_NAME_LENGTH = MAX_VIDEO_NAME_LENGTH;
  private lastValidFilename: string;
  private lastEmittedValue: string;

  constructor() {}
  public ngOnInit(): void {
    this.filename = this.file.name;
    this.lastValidFilename = this.file.name;
  }

  public onFilenameChanged(value: string) {
    this.filename = value;

    if (this.isFilenameValid(this.filename)) {
      this.lastValidFilename = this.filename;
    }
  }

  public onFocusout(value: string) {
    if (this.lastEmittedValue === value) {
      return;
    }
    if (this.isFilenameValid(value)) {
      this.onFilenameChanged(value);
    } else {
      // restore to last valid file-name in case of inalid file name
      this.onFilenameChanged(this.lastValidFilename);
    }

    this.nameChanged.emit({ ...this.file, name: this.filename });
    this.lastEmittedValue = this.filename;
  }

  private isFilenameValid(filename: string): boolean {
    if (!filename) {
      return false;
    }

    return true;
  }
}
