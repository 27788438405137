import { IUIInsightJsonKey } from '../insights-common/interfaces';
import { IUIPresetInsight, UIPresetInsight, IUIPreset, PresetRanking, IUITab, PresetInsightType } from './interfaces';

export const presetInsights: IUIPresetInsight[] = [
  {
    name: 'People',
    value: UIPresetInsight.PEOPLE,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.faces,
    ranking: PresetRanking.PEOPLE,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Detected Objects',
    value: UIPresetInsight.DETECTED_OBJECTS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.detectedObjects,
    ranking: PresetRanking.DETECTED_OBJECTS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Keywords',
    value: UIPresetInsight.KEYWORDS,
    selected: true,
    dataKey: IUIInsightJsonKey.keywords,
    config: true,
    ranking: PresetRanking.KEYWORDS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Labels',
    value: UIPresetInsight.LABELS,
    selected: true,
    dataKey: IUIInsightJsonKey.labels,
    config: true,
    ranking: PresetRanking.LABELS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Named entities',
    value: UIPresetInsight.NAMED_ENTITIES,
    selected: true,
    dataKey: 'namedEntities',
    config: true,
    ranking: PresetRanking.NAMED_ENTITIES,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Acoustic Events',
    value: UIPresetInsight.ACOUSTIC_EVENTS,
    selected: true,
    dataKey: IUIInsightJsonKey.audioEffects,
    config: true,
    ranking: PresetRanking.ACOUSTIC_EVENTS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Emotions',
    value: UIPresetInsight.EMOTIONS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.emotions,
    ranking: PresetRanking.EMOTIONS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Sentiments',
    value: UIPresetInsight.SENTIMENTS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.sentiments,
    ranking: PresetRanking.SENTIMENTS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Keyframes',
    value: UIPresetInsight.KEYFRAMES,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.shots,
    ranking: PresetRanking.KEYFRAMES,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Topics',
    value: UIPresetInsight.TOPICS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.topics,
    ranking: PresetRanking.TOPICS,
    availableTabs: [IUITab.INSIGHTS],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Scenes',
    value: UIPresetInsight.SCENES,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.scenes,
    ranking: PresetRanking.SCENES,
    availableTabs: [IUITab.INSIGHTS],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Speakers',
    value: UIPresetInsight.SPEAKERS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.speakers,
    ranking: PresetRanking.SPEAKERS,
    availableTabs: [IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Transcript',
    value: UIPresetInsight.TRANSCRIPT,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.transcript,
    ranking: PresetRanking.TRANSCRIPT,
    availableTabs: [IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'OCR',
    value: UIPresetInsight.OCR,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.ocr,
    ranking: PresetRanking.OCR,
    availableTabs: [IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Spoken Language',
    value: UIPresetInsight.SPOKEN_LANGUAGE,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.spokenLanguage,
    ranking: PresetRanking.SPOKEN_LANGUAGE,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Observed People',
    value: UIPresetInsight.OBSERVED_PEOPLE,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.observedPeople,
    ranking: PresetRanking.OBSERVED_PEOPLE,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Matched Person',
    value: UIPresetInsight.MATCHED_PERSON,
    selected: false,
    config: true,
    dataKey: IUIInsightJsonKey.observedPeople,
    ranking: PresetRanking.MATCHED_PERSON,
    availableTabs: [IUITab.INSIGHTS],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Post-production',
    value: UIPresetInsight.POST_PRODUCTION,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.postProduction,
    ranking: PresetRanking.POST_PRODUCTION,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  },
  {
    name: 'Logos',
    value: UIPresetInsight.LOGOS,
    selected: true,
    config: true,
    dataKey: IUIInsightJsonKey.logos,
    ranking: PresetRanking.LOGOS,
    availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
    type: PresetInsightType.REGULAR
  }
];

export type IUIPresetType = 'all' | 'storyboard' | 'accessibility' | 'captioning' | 'monitoring';

export const presets: IUIPreset[] = [
  {
    value: 'all',
    name: 'All',
    insights: [
      UIPresetInsight.OCR,
      UIPresetInsight.KEYFRAMES,
      UIPresetInsight.SENTIMENTS,
      UIPresetInsight.KEYWORDS,
      UIPresetInsight.PEOPLE,
      UIPresetInsight.SPEAKERS,
      UIPresetInsight.TRANSCRIPT,
      UIPresetInsight.LABELS,
      UIPresetInsight.ACOUSTIC_EVENTS,
      UIPresetInsight.EMOTIONS,
      UIPresetInsight.TOPICS,
      UIPresetInsight.SCENES,
      UIPresetInsight.SPOKEN_LANGUAGE,
      UIPresetInsight.NAMED_ENTITIES,
      UIPresetInsight.OBSERVED_PEOPLE,
      UIPresetInsight.MATCHED_PERSON,
      UIPresetInsight.POST_PRODUCTION,
      UIPresetInsight.LOGOS,
      UIPresetInsight.DETECTED_OBJECTS
    ]
  },
  {
    name: 'Storyboard',
    value: 'storyboard',
    insights: [UIPresetInsight.PEOPLE, UIPresetInsight.KEYFRAMES, UIPresetInsight.LABELS, UIPresetInsight.LOGOS]
  },
  {
    name: 'Captioning',
    value: 'captioning',
    insights: [UIPresetInsight.TRANSCRIPT, UIPresetInsight.SPOKEN_LANGUAGE]
  },
  {
    name: 'Accessibility',
    value: 'accessibility',
    insights: [UIPresetInsight.OCR, UIPresetInsight.TRANSCRIPT, UIPresetInsight.LABELS, UIPresetInsight.ACOUSTIC_EVENTS]
  },
  {
    name: 'Monitoring',
    value: 'monitoring',
    insights: [UIPresetInsight.OBSERVED_PEOPLE, UIPresetInsight.MATCHED_PERSON, UIPresetInsight.DETECTED_OBJECTS]
  }
];
