import { HttpResponse } from '@angular/common/http';

import { AccountPermission } from '@common/modules/shared/interfaces';

import { CustomizationApi } from './customization/customization-api.class';
import { concatParamsListToURL, getRequestSettings } from '../../api/utils/request.function';
import { IGetPersonMaxConfidenceInVideosRequestParams, IHttpWrapper, IRequestParam, ISupportedAIsRequestParams } from '../../api/interfaces';
import { ProjectApi } from './project-api.class';
import { getCustomEncodingParams, trialLocationForSampleAccount } from '../utils/api-utils';
import { VideoApi } from '../../api/classes/video-api.class';
import {
  IAddReaderParams,
  IAPIRequestParams,
  IApiSasContract,
  IGetIdByExternalRequestParams,
  IGetSasRequestParams,
  INetworkFileInfo,
  IRemoveInviteParams,
  ISearchRequestParams,
  IUpdateRole,
  IUploadRequestParams
} from '../interfaces';

export class AccountApi {
  public Video: VideoApi;
  public Project: ProjectApi;

  public Customization: CustomizationApi;

  private apiBaseRoute = '/accounts';

  constructor(private ApiWrapper: IHttpWrapper) {
    this.Video = new VideoApi(this.ApiWrapper);
    this.Project = new ProjectApi(this.ApiWrapper);
    this.Customization = new CustomizationApi(this.ApiWrapper);
  }

  // GET [Route("Api/v2/auth/Accounts/{accountId}/AccessToken")]
  public getAccountAccessToken(accountId: string, allowEdit?: boolean, permission?: AccountPermission, reqSettings?: object) {
    const url = `${this.apiAuthBase}/${accountId}/accessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;
    if (permission) {
      requestSettings['params'].permission = permission;
    }
    if (allowEdit) {
      requestSettings['params'].allowEdit = allowEdit;
    }

    requestSettings['params'].location = trialLocationForSampleAccount(accountId) || this.apiLocation;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts")]
  public getAccounts(reqSettings?: object, allowEdit: boolean = true) {
    const url = `${this.apiOperationBase}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);
    requestSettings['params'].generateAccessTokens = true;
    requestSettings['params'].allowEdit = allowEdit;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountContract[]>(url, requestSettings as object);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}")]
  public getAccount(accountId: string, includeUsage?: boolean, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, {
      includeUsage: includeUsage,
      includeAmsInfo: true,
      includeStatistics: true
    }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountContract>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}")]
  public deleteAccount(accountId: string, cancel?: boolean, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, { cancel: cancel }) as object;

    return this.ApiWrapper.HttpClient.delete(url, requestSettings);
  }

  // [Route("/{location}/Accounts/{accountId}/Videos")]
  // [HttpPost]
  public upload(accountId: string, uploadParams: IUploadRequestParams, reqSettings?: object, paramsList?: IRequestParam[]) {
    let url = `${this.apiOperationBase}/${accountId}/videos/`;
    // Add list of params to the url. e.g. ?excludeAI=Faces&excludeAI=Labels...
    url = concatParamsListToURL(url, paramsList);
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, uploadParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, null, requestSettings);
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/sas/")]
  public getSas(accountId: string, reqParams: IGetSasRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsCloudBase}/${accountId}/sas/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<IApiSasContract>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("/api/MediaBreakdown/videoInfo/")]
  public getVideoInfo(accountId: string, videoUrl: string, reqSettings?: object, allowEdit = true) {
    const url = `${this.apiInternalsBase}/${accountId}/videoInfo/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, { allowEdit: allowEdit });
    requestSettings['params'].videoUrl = videoUrl;
    requestSettings['observe'] = 'response';

    return this.ApiWrapper.HttpClient.get<HttpResponse<INetworkFileInfo>>(url, requestSettings as object);
  }

  // [HttpPost]
  // https://{accountName}.blob.core.windows.net/{mycontainer}/{myblob}
  public uploadToBlobStorage(url: string, file: Blob, contentType: string) {
    return this.ApiWrapper.HttpClient.put(url, file, {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-version': '2017-04-17',
        'Content-Type': contentType
      },
      reportProgress: true,
      observe: 'events'
    } as object);
  }

  // [HttpPost]
  // https://{accountName}.blob.core.windows.net/{mycontainer}/{myblob}&comp=blocklist
  public commitUploadBlockList(url: string, data: string, contentType: string) {
    return this.ApiWrapper.HttpClient.put(url, data, {
      headers: {
        'x-ms-blob-content-type': contentType
      },
      observe: 'events'
    });
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos")]
  public getList(accountId: string, pageSize?: number, skip?: number, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/videos/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos/Search")]
  public search(accountId: string, searchParams?: ISearchRequestParams, reqSettings?: object, suffixUrl = '') {
    const url = `${this.apiOperationBaseWithLocation(accountId)}/${accountId}/videos/search/?${suffixUrl}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, searchParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistSearchResultV2>(url, {
      ...requestSettings,
      params: getCustomEncodingParams(requestSettings['params'])
    });
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos/PersonMaxConfidenceInVideos")]
  public searchPersonMaxConfidenceInVideos(accountId: string, searchParams?: IGetPersonMaxConfidenceInVideosRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithLocation(this.apiLocation)}/${accountId}/videos/PersonMaxConfidenceInVideos`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, searchParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PersonMaxConfidenceInVideos>(url, {
      ...requestSettings,
      params: getCustomEncodingParams(requestSettings['params'])
    });
  }

  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/GetIdByExternalId")]
  public getIdByExternalId(accountId: string, reqParams: IGetIdByExternalRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/videos/getIdByExternalId/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("/internals/{location}/Accounts/{accountId}/invitations")]
  public getInvitations(accountId: string, reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/invitations`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.InvitationContract[]>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("/internals/{location}/Accounts/{accountId}/roleAssignments")]
  public getRoleAssignments(accountId: string, reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/roleAssignments`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountRoleAssignment[]>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/invitations/${invitationId}")]
  public deleteInvitation(accountId: string, invitationId: number, reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/invitations/${invitationId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/roleAssignments/${roleAssignmentId}")]
  public deleteRoleAssignment(accountId: string, roleAssignmentId: number, reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/roleAssignments/${roleAssignmentId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/roleAssignments/${roleAssignmentId}")]
  public updateRoleAssignment(accountId: string, roleAssignmentId: number, data: IUpdateRole, reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/roleAssignments/${roleAssignmentId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.AccountRoleAssignment>(url, data, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/CreateVideoPlaylist")]
  public createVideoPlaylist(
    accountId: string,
    userPlaylist: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
    reqParams?: IAPIRequestParams,
    reqSettings?: object
  ) {
    const url = `${this.apiInternalsBase}/${accountId}/videos/createVideoPlaylist/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, userPlaylist, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}")]
  public updateAccount(accountId: string, account: Microsoft.VideoIndexer.Contracts.AccountContract, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, account, requestSettings);
  }

  // [HttpPost]
  // [Route("/internals/{location}/Accounts/{accountId}/invitations")]
  public inviteToAccount(accountId: string, inviteData: Microsoft.VideoIndexer.Contracts.InviteContract[], reqParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/invitations/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.InvitationContract[]>(url, inviteData, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/settings")]
  public getAccountSettings(accountId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/settings`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountSettings>(url, requestSettings);
  }

  // [HttpGet]
  public getAccountSupportedAIs(accountId: string, reqParams?: ISupportedAIsRequestParams, paramsList?: IRequestParam[], reqSettings?: object) {
    let url = `${this.apiInternalsBase}/${accountId}/SupportedAIs`;
    // Add list of params to the url. e.g. ?excludeAI=Faces&excludeAI=Labels...
    url = concatParamsListToURL(url, paramsList);
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.SupportedAIsContract>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/settings")]
  public updateAccountSettings(
    accountId: string,
    settings: Microsoft.VideoIndexer.Contracts.AccountSettings,
    reqParams?: object,
    reqSettings?: object
  ) {
    const url = `${this.apiInternalsBase}/${accountId}/settings`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, settings, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Join")]
  public join(accountId: string, inviteCode: string, reqSettings?: object, location?: string) {
    const url = `${this.apiInternalsBaseWithLocation(location)}/${accountId}/join/${inviteCode}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/AddReader")]
  public addReader(accountId: string, addReaderParams?: IAddReaderParams, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/addReader/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, addReaderParams, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/videos/{videoId}")]
  public deleteClip(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/videos/${videoId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/Accounts/{accountId}/face-features")]
  public updateFaceGateFeatures(accountId: string, signature: string, useCase: string, reqSettings?: object) {
    const url = `${this.apiAuthBase}/${accountId}/face-features/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    const body: Microsoft.VideoIndexer.Contracts.InternalFaceGatingRequest = {
      value: true,
      signature: signature,
      useCase: useCase
    };

    requestSettings['params'].location = this.apiLocation;

    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.LimitedAccessFeaturesContract>(url, body, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/AddContributor")]
  public addContributor(accountId: string, addContributorParams?: IAddReaderParams, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/addContributor/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, addContributorParams, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/RemoveReader")]
  public removeReader(accountId: string, removeReaderParams?: IAddReaderParams, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/removeReader/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, removeReaderParams) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/RemoveMyself")]
  public removeMyself(accountId: string, removeMySelfParams?: object, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/removeMyself/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, removeMySelfParams) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/RemoveInvite")]
  public removeInvite(accountId: string, removeInviteParams?: IRemoveInviteParams, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/removeInvite/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, removeInviteParams) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/RecommendVideos")]
  public getRecommendedVideos(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBase}/${accountId}/recommendVideos/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, { videoId: videoId }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpPatch]
  // [Route("Api/v2/Accounts/{accountId}/MediaServices")]
  public updateAccountMediaServices(
    accountId: string,
    amsUpdateConfig: Microsoft.VideoIndexer.Contracts.UpdateAmsConnectionContract,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/MediaServices`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.patch(url, amsUpdateConfig, requestSettings);
  }

  private get apiOperationBaseWithoutLocation(): string {
    return this.ApiWrapper.GetApiOperationsBase(true);
  }

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase() + this.apiBaseRoute;
  }

  private get apiInternalsBase(): string {
    return this.ApiWrapper.GetInternalApiBase() + this.apiBaseRoute;
  }

  private get apiInternalsCloudBase(): string {
    return this.ApiWrapper.GetInternalApiBase(null, null, true) + this.apiBaseRoute;
  }

  private get apiAuthBase(): string {
    return this.ApiWrapper.GetAuthApiBase(false) + this.apiBaseRoute;
  }

  private get apiLocation(): string {
    return this.ApiWrapper.GetApiLocation();
  }

  private apiInternalsBaseWithLocation(location?: string): string {
    return this.ApiWrapper.GetInternalApiBase(location) + this.apiBaseRoute;
  }

  private apiOperationBaseWithLocation(accountId: string): string {
    const location = trialLocationForSampleAccount(accountId);
    return `${this.ApiWrapper.GetApiOperationsBase(false, location)}${this.apiBaseRoute}`;
  }
}
