export enum SelectComponentAppearance {
  Outline = 'outline',
  Filled = 'filled',
  Stealth = 'stealth'
}

export enum SelectComponentSize {
  Small = 'small',
  Medium = 'medium'
}
export enum SelectComponentIndicatorSize {
  Small = 'small',
  Medium = 'medium'
}
