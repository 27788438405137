import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';

import { ClipboardService } from 'ngx-clipboard';

import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { ISearchRequestParams, RegionType } from '@common/modules/api/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { SamplesAccountId } from '@common/modules/core/interfaces';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { IGallerySearchFilter } from '@common/modules/gallery-common/core/models/interfaces';
import { InsightsCommonUtilsService } from '@common/modules/insights-common/insights-common-utils.service';
import { ThumbnailClass } from '@common/modules/insights-common/interfaces';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { IAction, VideoReviewState } from '@common/modules/shared/interfaces';
import { DataService } from '@common/modules/shared/services/data.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { NotificationIcon, NotificationMessageType, NotificationType } from '@common/modules/core/services/toast/interfaces';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { CoreStoreService } from '../../core/services/core-store.service';
import { DeleteVideoComponent } from '../components/delete-video/delete-video.component';
import { UIGalleryActionType } from '../core/models/gallery';
import { AppListItem } from '../data-source/app-list-data-source';
import { GalleryTab, SearchParams } from '../interfaces';
import { resources } from '../resources';
import { IndexingStoreService } from '../../indexing/services/indexing-store.service';
import { EdgeExtensionsApiHandlerService } from '../../core/services/edge-extensions-api.handler.service';
import { IndexingSummaryDialogComponent } from '../../indexing/components/shared/indexing-summary-dialog/indexing-summary-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  public resources = resources;

  constructor(
    private logger: LoggerService,
    private dataService: DataService,
    private insightsCommonUtils: InsightsCommonUtilsService,
    private coreStore: CoreStoreService,
    private dialog: MatDialog,
    private translate: TranslateHelperService,
    private focusManager: FocusManagerService,
    private toastService: ToastService,
    private apiService: ApiService,
    private clipboardService: ClipboardService,
    private trackService: TrackService,
    private featureSwitchService: FeatureSwitchService,
    private indexingStoreService: IndexingStoreService,
    private edgeExtensionsApiHandlerService: EdgeExtensionsApiHandlerService,
    private authService: AuthService,
    private dialogService: DialogService
  ) {
    this.setTranslations();
  }

  public getThumbnailUrl(accountId: string, videoId: string, thumbnailId: string): string {
    return this.dataService.getThumbnailUrl(accountId, videoId, thumbnailId);
  }

  public getThumbnailUrlWithToken(accountId: string, videoId: string, thumbnailId: string): string {
    const accessToken = this.authService.getAccessToken(null, accountId);
    const url = this.getThumbnailUrl(accountId, videoId, thumbnailId);

    return `${url}?accessToken=${accessToken}`;
  }

  public getThumbnailClass(item: AppListItem): ThumbnailClass {
    return this.insightsCommonUtils.getThumbnailClass(item.state, item.thumbnailId);
  }

  public uniqueId(itemId: string, prefix?: string): string {
    return `${prefix}_vid_${itemId}`;
  }

  public openDeleteDialog(deleteData: AppListItem, callbackAfterClose?: string) {
    const deleteDialogData: IDialogData = {
      class: 'delete-dialog',
      title: this.resources.GalleryDeleteVideoDialogTitle,
      component: DeleteVideoComponent,
      componentData: deleteData
    };
    this.dialog
      .open(DialogComponent, {
        width: '440px',
        height: 'auto',
        data: deleteDialogData,
        disableClose: false,
        ariaLabel: deleteDialogData.title,
        panelClass: 'delete-dialog-container'
      })
      .afterClosed()
      .subscribe(res => {
        if (callbackAfterClose) {
          this.focusManager.focusByQuery(callbackAfterClose);
        }
      });
  }

  public toastDeleteSuccess(name: string) {
    this.translate.translateResourcesInstant(resources, { name });

    this.toastService.notify({
      id: `${Date.now()}`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Toast,
      icon: NotificationIcon.Success,
      title: this.resources.GalleryDeleteVideoSuccessTitle,
      text: `'${name}' ${this.resources.GalleryDeleteVideoSuccess}`
    });
  }

  public toastDeleteError(name: string, error) {
    this.translate.translateResourcesInstant(resources, { name });
    let title = this.resources.GalleryDeleteVideoErrorTitle;
    let text = this.resources.GalleryDeleteVideoErrorText;

    if (error?.ErrorType === APIErrors.VIDEO_CURRENTLY_BEING_INDEXED) {
      title = this.resources.GalleryVideoCurrentlyBeingIndexedErrorTitle;
      text = this.resources.GalleryVideoCurrentlyBeingIndexedErrorText;
    }

    this.toastService.notify({
      id: `${Date.now()}`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Toast,
      icon: NotificationIcon.Error,
      title,
      text
    });
  }

  public copyVideoId(videoId: string) {
    this.clipboardService.copyFromContent(videoId);
    this.toastService.success(this.resources.CopyFileIDSuccess, false);
    this.trackService.track('gallery.copy_file_id.clicked', {
      category: EventCategory.GALLERY
    });
  }

  public openVideoInNewTab(videoId: string, accountId: string) {
    const path = `/accounts/${accountId}/videos/${videoId}`;
    window.open(path, '_blank');
    this.trackService.track('gallery.open_in_new_tab.clicked', {
      category: EventCategory.GALLERY
    });
  }

  public openIndexingSummaryDialog(openIndexingSummaryData: AppListItem) {
    this.dialogService.openDialog(
      {
        class: 'indexing-settings-dialog',
        component: IndexingSummaryDialogComponent,
        componentData: openIndexingSummaryData,
        title: this.resources.IndexingSettingsModalHeader,
        primaryButton: {
          type: ActionButtonType.SECONDARY,
          action: { title: this.resources.Close, value: null, id: 'close-indexing-settings' }
        }
      },
      '740px',
      '564px',
      'indexing-settings-dialog-container',
      false,
      true,
      'auto',
      true
    );
  }

  public openReIndexDialog(reIndexData: AppListItem) {
    this.indexingStoreService.openReIndexDialog(reIndexData.id);
  }

  public trackAppListItemBy(index: number, item: AppListItem): string {
    return `${index}:${item.accountId}:${item.id}`;
  }

  public hadExpandedDetails(appListItem: AppListItem): boolean {
    if (!appListItem.searchMatches) {
      return false;
    }

    return appListItem.searchMatches.filter(sm => sm.type !== 'Title' && sm.type !== 'Owner').length > 0;
  }

  public getParamsAsObject(queryParams: Params): ISearchRequestParams {
    const result: ISearchRequestParams = Object.create(null);
    if (queryParams.query) {
      result.query = queryParams.query;
    }
    if (queryParams.scope) {
      result.scope = queryParams.scope;
    }
    if (queryParams.people) {
      result.face = queryParams.people;
    }
    if (queryParams.face) {
      result.face = queryParams.face;
    }
    if (queryParams.owner) {
      result.owner = queryParams.owner;
    }
    if (queryParams.textScope) {
      if (queryParams.textScope === 'entities') {
        result.textScope = ['brands', 'namedLocations', 'namedPeople'];
      } else {
        result.textScope = queryParams.textScope;
      }
    }
    // Send language only if there is query
    if (queryParams.language) {
      result.language = queryParams.language;
    }

    return result;
  }

  public getSearchRequestParams(filters: IGallerySearchFilter[], scopeMainAction: IAction, addEntities = true): ISearchRequestParams {
    const searchRequestParams: ISearchRequestParams = {};
    const faceFilters = filters.filter(f => f.key === (SearchParams.Face || SearchParams.People));
    const ownerFilters = filters.filter(f => f.key === SearchParams.Owner);
    const languageFilters = filters.filter(f => f.key === SearchParams.Language);

    if (faceFilters.length) {
      const filteredFaces = faceFilters.filter(f => f.text !== '')?.map(f => f.text);
      if (filteredFaces.length) {
        searchRequestParams.face = filteredFaces;
      }
    }

    if (ownerFilters.length) {
      const filteredOwner = ownerFilters.filter(f => f.text !== '')?.map(f => f.text);
      if (filteredOwner.length) {
        searchRequestParams.owner = filteredOwner;
      }
    }

    if (languageFilters.length) {
      searchRequestParams.language = languageFilters.map(f => f.value);
    }

    if (scopeMainAction?.type !== UIGalleryActionType.ALL_INSIGHTS_FILTER) {
      // If user filters on entities - send multiple text scopes
      // change to entities after backend update
      if (scopeMainAction.value === 'entities' && addEntities) {
        searchRequestParams.textScope = ['brands', 'namedLocations', 'namedPeople'];
      } else {
        searchRequestParams.textScope = scopeMainAction.value;
      }
    }

    return searchRequestParams;
  }

  public getGalleryTabFromUrl(url: string): GalleryTab {
    switch (url) {
      case '/library':
        return GalleryTab.Library;
      case '/projects':
        return GalleryTab.Project;
      case '/samples':
        return GalleryTab.Sample;
    }
  }

  public navigateToVideo(accountId: string, videoId: string, isBase: boolean, queryParams?: object) {
    this.logger.log('[GalleryService] navigate to media');

    const location = this.apiService.getApiLocation();
    const params = {};
    if (location !== RegionType.TRIAL && isBase && accountId !== SamplesAccountId) {
      params['location'] = location;
    }

    const path = `/accounts/${accountId}/${isBase ? 'videos' : 'projects'}/${videoId}`;
    this.coreStore.navigate([path], { ...queryParams, ...params });
  }

  public navigateToEdgeExtensionVideo(extensionId: string, accountId: string, videoId: string, queryParams?: object) {
    this.logger.log('[GalleryService] navigate to edge extension media');

    const path = `/accounts/${accountId}/extensions/${extensionId}/videos/${videoId}`;
    this.coreStore.navigate([path], { ...queryParams });
  }

  public navigateToEditor(accountId: string, videoId: string, isBase: boolean) {
    this.logger.log('[GalleryService] navigate to editor');

    const path = `/editor/${accountId}/${isBase ? 'videos' : 'projects'}/${videoId}`;
    this.coreStore.navigate([path]);
  }

  public createNewProject(accountId: string) {
    this.logger.log('[GalleryService] navigate to editor = createNewProject');

    const path = `/editor/${accountId}`;
    this.coreStore.navigate([path]);
  }

  public isProject(isBase: boolean): boolean {
    return !isBase;
  }

  public getModerationStateText(videoReviewState: VideoReviewState | string) {
    if (this.edgeExtensionsApiHandlerService?.HasSelectedEdgeExtension) {
      return '';
    }

    switch (videoReviewState) {
      case VideoReviewState.NONE:
        return this.resources.GalleryItemFlaggedRequestedReviewApproval;
      case VideoReviewState.IN_PROGRESS:
        return this.resources.GalleryItemFlaggedRequestedReview;
      case VideoReviewState.COMPLETED:
        return this.resources.GalleryItemFlaggedOffensiveContent;
      default:
        return '';
    }
  }

  private setTranslations(): void {
    this.translate.translateResourcesInstant(resources);
  }
}
