import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { IGetIndexRequestParams } from '@common/modules/api/interfaces';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { VideoReviewState } from '@common/modules/shared/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { AccountResourceType } from '@common/modules/auth/interfaces';
import { ConfigService } from '@common/modules/core/services/config/config.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import * as fromMedia from '@common/modules/media-data-common/selectors/media.selectors';
import * as MediaActions from '@common/modules/media-data-common/actions/media.actions';

import { MediaService } from '../../media/services/media.service';
import { getARMLocation } from '../../core/effects/utils';
import * as fromCore from '../../core/selectors';
import * as fromRouter from '../../core/reducers/router';
import * as GalleryActions from '../../gallery/core/actions/gallery.actions';
import * as AccountsActions from '../../core/actions/accounts.actions';

@Injectable()
export class MediaEffects {
  public loadMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadVideoIndex),
      withLatestFrom(this.routerStore.select(fromRouter.getRouteParams)),
      withLatestFrom(this.store.select(fromMedia.getMediaIndexIds)),
      withLatestFrom(this.store.select(fromCore.selectAllAccounts)),
      switchMap(([[[{ selectAccount }, routeParams], mediaIds], userAccounts]) => {
        const accountId: string = routeParams?.accountId;
        const videoId: string = routeParams?.videoId;
        this.store.dispatch(MediaActions.selectMediaIndex({ id: videoId }));
        if (mediaIds && mediaIds.includes(videoId)) {
          this.logger.log(`[MediaEffects] Index already loaded :) videoId: ${videoId}`);
          this.trackService.track('media.effects.load_media.index_already_loaded', {
            category: EventCategory.MEDIA
          });
          return EMPTY;
        }
        this.logger.log(`[MediaEffects] Load Index ny route accountId: ${accountId} videoId: ${videoId}`);

        this.trackService.track('media.effects.load_media.load_index', {
          category: EventCategory.MEDIA
        });

        const params: IGetIndexRequestParams = {};
        params.includeStreamingUrls = false;
        params.includeSummarizedInsights = !this.featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights);
        const account = userAccounts[accountId];
        if (account) {
          params.allowEdit = true;
          let location = account.location;
          if (account?.resourceType === AccountResourceType.ARM) {
            // For dev and local environment we need to map the Arm Account location to the environment location
            location = getARMLocation(account, this.configService);
          }
          this.apiService.setApiBaseLocation(location, account.accountType);
          this.apiService.setApiMediaLocation(location);
          this.authService.UserAccount = account;
          if (selectAccount) {
            this.store.dispatch(AccountsActions.selectAccount({ id: account.id }));
          }
        }

        const timeStamp = window.performance.now();
        return this.apiService.Account.Video.getVideoIndex(accountId, videoId, params).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) => {
            const timeTaken = window.performance.now() - timeStamp;
            this.trackService.track('media.effects.load_media.get_video_index_success', {
              category: EventCategory.MEDIA,
              data: {
                timeTaken: timeTaken,
                videoDuration: result?.durationInSeconds
              }
            });
            return [MediaActions.upsertMediaIndex({ mediaIndex: result })];
          }),
          catchError((err: HttpErrorResponse) => {
            const timeTaken = window.performance.now() - timeStamp;
            this.trackService.track('media.effects.load_media.get_video_index_failed', {
              category: EventCategory.MEDIA,
              data: {
                timeTaken: timeTaken,
                isAuthenticated: this.authService.isAuthenticated(),
                error: err
              }
            });
            return [MediaActions.loadMediaFailure({ error: err })];
          })
        );
      })
    )
  );

  public loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadProjectIndex),
      withLatestFrom(this.routerStore.select(fromRouter.getRouteParams)),
      withLatestFrom(this.store.select(fromMedia.getMediaIndexIds)),
      withLatestFrom(this.store.select(fromCore.selectAllAccounts)),
      switchMap(([[[{}, routeParams], mediaIds], accountIds]) => {
        const accountId: string = routeParams?.accountId;
        const videoId: string = routeParams?.videoId;
        this.store.dispatch(MediaActions.selectMediaIndex({ id: videoId }));
        if (mediaIds && mediaIds.includes(videoId)) {
          this.logger.log(`[MediaEffects] Project already loaded :) videoId: ${videoId}`);
          return EMPTY;
        }

        this.logger.log(`[MediaEffects] Load Project ny route accountId: ${accountId} videoId: ${videoId}`);

        let params: IGetIndexRequestParams = {};
        params = { language: 'en-US' };
        params.includeStreamingUrls = false;
        params.includeSummarizedInsights = !this.featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights);

        const account = accountIds[accountId];
        if (account) {
          let location = account.location;
          if (account?.resourceType === AccountResourceType.ARM) {
            // For dev and local environment we need to map the Arm Account location to the environment location
            location = getARMLocation(account, this.configService);
          }
          this.apiService.setApiBaseLocation(location, account.accountType);
          this.apiService.setApiMediaLocation(location);
          this.authService.UserAccount = account;
          this.store.dispatch(AccountsActions.selectAccount({ id: account.id }));
        }

        return this.apiService.Account.Project.getProjectIndex(accountId, videoId, params).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) => {
            return [MediaActions.upsertMediaIndex({ mediaIndex: result })];
          }),
          catchError((error: HttpErrorResponse) => {
            return [MediaActions.loadMediaFailure({ error })];
          })
        );
      })
    )
  );

  public updateIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.updateMediaIndex),
      withLatestFrom(this.routerStore.select(fromRouter.getRouteParams)),
      withLatestFrom(this.store.select(fromMedia.selectIsBase)),
      switchMap(([[{ id, index, newIndex }, params], isBase]) => {
        if (!isBase) {
          this.store.dispatch(MediaActions.updateProjectMediaIndex({ id: id, index: index, newIndex: newIndex }));
          return EMPTY;
        }
        this.store.dispatch(MediaActions.updateIndex({ mediaIndex: { id: id, changes: newIndex } }));
        this.logger.log('Update index in store');
        const accountId = params?.accountId;
        return this.apiService.Account.Video.saveVideoIndex(accountId, id, newIndex, null).pipe(
          switchMap(() => {
            this.logger.log('Update index success');
            this.mediaService.handelUpdateIndex(true);
            return [
              MediaActions.updateIndexSaving({ saving: false }),
              GalleryActions.loadProcessingVideoOrProjectById({ id }) // update gallery, for example with the new video name
            ];
          }),
          catchError(() => {
            this.logger.log('Update index failed');
            this.mediaService.handelUpdateIndex(false);
            return [MediaActions.updateIndex({ mediaIndex: { id: id, changes: index } }), MediaActions.updateIndexSaving({ saving: false })];
          })
        );
      })
    )
  );

  public updateProjectIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.updateProjectMediaIndex),
      withLatestFrom(this.routerStore.select(fromRouter.getRouteParams)),
      withLatestFrom(this.store.select(fromMedia.selectedMediaIndex)),
      switchMap(([[{ id, index, newIndex }, params], selectedIndex]) => {
        this.store.dispatch(MediaActions.updateIndex({ mediaIndex: { id: id, changes: newIndex } }));
        this.logger.log('Update project index in store');
        const accountId = params?.accountId;
        const projectIndex = {
          ...selectedIndex,
          name: newIndex.name
        };
        return this.apiService.Account.Project.updateProject(accountId, id, projectIndex, null).pipe(
          switchMap(() => {
            this.logger.log('Update project index success');
            this.mediaService.handelUpdateIndex(true);
            return [
              MediaActions.updateIndexSaving({ saving: false }),
              GalleryActions.loadProcessingVideoOrProjectById({ id }) // update gallery, for example with the new project name
            ];
          }),
          catchError(() => {
            this.logger.log('Update project index failed');
            this.mediaService.handelUpdateIndex(false);
            return [MediaActions.updateIndex({ mediaIndex: { id: id, changes: index } }), MediaActions.updateIndexSaving({ saving: false })];
          })
        );
      })
    )
  );

  public requestReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.requestReview),
      switchMap(({ accountId, videoId }) => {
        return this.apiService.Account.Video.requestReview(accountId, videoId).pipe(
          switchMap(() => {
            this.mediaService.handelRequestReview(true);
            return EMPTY;
          }),
          catchError(() => {
            this.mediaService.handelRequestReview(false);
            return [MediaActions.updateMediaReviewState({ videoId: videoId, reviewState: VideoReviewState.NONE })];
          })
        );
      })
    )
  );

  private mediaService: MediaService;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private apiService: ApiService,
    private store: Store<fromMedia.IState>,
    private logger: LoggerService,
    private readonly routerStore: Store<fromRouter.IState>,
    private trackService: TrackService,
    private injector: Injector,
    private configService: ConfigService,
    private featureSwitchService: FeatureSwitchService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.mediaService = this.injector.get(MediaService);
  }
}
