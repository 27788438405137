import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { TrackService } from '@common/modules/core/services/track/track.service';
import { WINDOW } from '@common/modules/core/services/window.token';
import { EventCategory } from '@common/modules/core/services/track';
import { getLastArmStorageKey } from '@common/modules/shared/arm';
import { CacheService } from '@common/modules/auth/services/cache.service';
import { UrlService } from '@common/modules/core/services/url/url.service';

import * as actions from '../actions/index';
import * as fromDirectories from '../reducers/directories.reducer';
import * as fromCore from '../selectors';

import DirectoryContract = Microsoft.VideoIndexer.Contracts.DirectoryContract;

@Injectable()
export class DirectoriesEffects {
  public loadDirectories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadDirectories),
      withLatestFrom(this.store.select(fromCore.selectDirectoriesLoaded)),
      withLatestFrom(this.store.select(fromCore.selectDirectoriesError)),
      switchMap(([[, loaded], error]) => {
        if (loaded && !error) {
          return EMPTY;
        }

        if (error) {
          this.store.dispatch(actions.reloadDirectories());
        }

        return this.apiService.ArmAccount.getArmDirectories().pipe(
          switchMap((result: DirectoryContract[]) => {
            this.trackService.track('directories.load_directories.success', {
              category: EventCategory.DIRECTORIES,
              data: {
                count: result?.length
              }
            });

            return [actions.loadDirectoriesSuccess({ directories: result.length > 0 ? result : [] })];
          }),
          catchError(error => {
            this.trackService.track('directories.load_directories.failed', {
              category: EventCategory.DIRECTORIES,
              error: JSON.stringify(error)
            });

            return [actions.loadDirectoriesFailed()];
          })
        );
      })
    )
  );

  public selectDirectory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.selectDirectory),
        withLatestFrom(this.store.select(fromCore.getTenantId)),
        switchMap(([{ tenantId }, currentTenantId]) => {
          if (!tenantId) {
            return EMPTY;
          }

          this.trackService.track('directories.select_directory', {
            category: EventCategory.DIRECTORIES,
            data: {
              tenantId
            }
          });

          this.localStorageService.removeItem(StorageCacheKey.LastAccountType);
          this.localStorageService.removeItem(getLastArmStorageKey(currentTenantId));
          this.cacheService.removeUserAccessTokens();
          const env = this.urlService.getQueryParam('env') || '';
          let envParam = '';
          if (env) {
            envParam = `&env=${env}`;
          }
          const url = `/Account/SignIn?provider=OpenIdConnect&tenantId=${tenantId}${envParam}`;
          setTimeout(() => {
            this.window.location.replace(url);
          });
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromDirectories.IState>,
    private trackService: TrackService,
    private localStorageService: LocalStorageService,
    private cacheService: CacheService,
    private urlService: UrlService,
    @Inject(WINDOW) private window
  ) {}
}
