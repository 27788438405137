import { Store } from '@ngrx/store';

import { Observable, switchMap, EMPTY, withLatestFrom } from 'rxjs';

import { orderBy } from 'lodash-es';

import { InsightsImagesService } from '@common/modules/shared/components/insights-images/insights-images.service';
import { InsightsCommonDataService } from '@common/modules/insights-common/insights-common-data.service';
import { InsightsCommonUtilsService } from '@common/modules/insights-common/insights-common-utils.service';
import { IUIAppearance } from '@common/modules/insights/interfaces';
import { zeroPad } from '@common/modules/utils/string';
import {
  DETECTED_OBJECT_WIDTH,
  DETECTED_OBJECT_WIDTH_LARGE,
  IUIDetectedObject,
  IUIDetectedObjectGroup,
  SPRITE_WIDTH
} from '@common/modules/insights/components/detected-objects/interfaces';
import { IUIInsightJsonKey, SpriteType } from '@common/modules/insights-common/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import * as fromMedia from '../selectors/media.selectors';
import { resources } from '../resources';

export class DetectedObjectsStore {
  private readonly ZERO_PAD_PLACES = 3;
  private resources = resources;

  constructor(
    private readonly store: Store<fromMedia.IState>,
    private insightsImagesService: InsightsImagesService,
    private dataService: InsightsCommonDataService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private trackService: TrackService,
    private translate: TranslateHelperService
  ) {
    this.init();
  }

  private init() {
    this.translate.translateResources(this.resources);
  }

  public get selectedAll$(): Observable<IUIDetectedObjectGroup[]> {
    const timeStamp = window.performance.now();

    return this.store.select(fromMedia.selectedMediaIndex).pipe(
      withLatestFrom(this.store.select(fromMedia.selectMediaDetails)),
      switchMap(([mediaIndex, mediaDetails]) => {
        if (!this.insightsCommonUtilsService.hasInsightsData(mediaIndex, IUIInsightJsonKey.detectedObjects)) {
          return EMPTY;
        }

        const detectedObjectsGroupsMap: { [key: string]: IUIDetectedObjectGroup } = {};

        for (const video of mediaIndex.videos) {
          const detectedObjects = video.insights?.detectedObjects;
          detectedObjects?.forEach((obj, index) => {
            const objectAppearances: IUIAppearance[] = this.insightsCommonUtilsService.convertInstancesToAppearances(obj.instances).appearances;

            const object: IUIDetectedObject = {
              appearances: objectAppearances,
              id: obj.id,
              selected: false,
              name: zeroPad(obj.id, this.ZERO_PAD_PLACES),
              className: obj.type,
              displayClassName: obj.displayName.toLowerCase(),
              titleAttribute: `#${zeroPad(obj.id, this.ZERO_PAD_PLACES)}`,
              spriteIndex: index,
              positionSmall: this.insightsImagesService.getSpritePosition(index, DETECTED_OBJECT_WIDTH, SPRITE_WIDTH),
              positionLarge: this.insightsImagesService.getSpritePosition(index, DETECTED_OBJECT_WIDTH_LARGE, SPRITE_WIDTH),
              spriteUrl: this.dataService.getSpriteUrl(mediaDetails.accountId, mediaDetails.videoId, index, SPRITE_WIDTH, SpriteType.DetectedObjects),
              style: null,
              videoId: video.id,
              texts: {
                pictureTitle: this.resources?.DetectedObjectsComponentPictureLabel
              }
            };

            object.style = this.insightsImagesService.getThumbStyle(object, object.positionSmall);

            if (!detectedObjectsGroupsMap[object.className]) {
              detectedObjectsGroupsMap[object.className] = {
                id: object.id,
                name: object.displayClassName,
                className: object.className,
                objects: [],
                appearances: null,
                selected: false
              };
            }
            detectedObjectsGroupsMap[object.className].objects.push(object);
          });
        }

        // Sort by number of detected objects in a group
        const detectedObjectsGroups = orderBy(Object.values(detectedObjectsGroupsMap), [group => group.objects.length], ['desc']);

        const timeTaken = window.performance.now() - timeStamp;
        this.trackService.trackPerformance('detected_objects.prepare_data', EventCategory.INSIGHTS, timeTaken);

        return [detectedObjectsGroups];
      })
    );
  }
}
