import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  // Input
  @Input() public isVisible = false;
  @Input() public expandedTxt: string;
  @Input() public collapsedTxt: string;
  @Input() public showIcon = true;
  @Input() public isDisabled = false;
  @Input() public buttonId: string;

  // Output
  @Output() public filterClick = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public handleClick(e: MouseEvent) {
    this.filterClick.emit();
    e.stopPropagation();
    e.preventDefault();
  }
}
