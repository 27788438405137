import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ITelemetryItem } from '@microsoft/1ds-core-js';
import { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-web-js';

import { LoggerService } from '../logger/logger.service';
import { ICustomEventsTrackData } from '../interfaces';
import { ConfigService } from '../config/config.service';
import { OneDSClient, EventType as OneDSEventType } from './1DSClient';
import { SessionHandlerService } from '../../../shared/services/session-handler.service';
import { CommonTrackingDataService } from './common-tracking-data.service';
import { GuidPattern, VideoIdPattern } from '../../../utils/regexes';
import { IVIRoute } from '../../../../../apps/web/src/app/routing/interfaces';
import { replaceJWTToken } from '../../../utils/string';
import { EventCategory } from './interfaces';
import { SanitizerService } from '../sanitizer/sanitizer.service';

enum EventType {
  Action = 'FeActionEvent',
  Timing = 'FePageTimingEvent'
}

export interface IEventProperties {
  data?: object;
  category?: EventCategory;
  // @TODO: remove this property when all events will be migrated to use the data property
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

@Injectable()
export class TrackService {
  private commonData: ICustomEventsTrackData;
  private onedsClient: OneDSClient;
  private readonly SERVICE_NAME = 'WebApp';
  private readonly ONE_DS_APPLICATION_ID = 'js:videoindexer.ai';

  constructor(
    private logger: LoggerService,
    private configService: ConfigService,
    private sessionHandlerService: SessionHandlerService,
    private commonTrackingDataService: CommonTrackingDataService,
    private activeRoute: ActivatedRoute,
    private sanitizerService: SanitizerService
  ) {
    this.setOnError();
    this.setCommonData();

    this.onedsClient = new OneDSClient({
      appId: this.ONE_DS_APPLICATION_ID,
      instrumentationKey: this.configService?.Config?.instrumentationKey,
      endpointUrl: this.configService?.Config?.collectorUrl || undefined,
      commonData: this.commonData,
      onEventSent: (events: ITelemetryItem[]) => events.map(this.logEvent.bind(this)),
      propertyConfiguration: {
        serviceName: this.SERVICE_NAME,
        env: this.monitoringEnvironment,
        gpcDataSharingOptIn: false
      },
      telemetryInitializers: [this.performanceEventTelemetryInitializer.bind(this)]
    });

    this.logger.log('[track] init 1DS Client', this.onedsClient.config);
  }

  private get runtimeData() {
    return {
      locale: this.commonTrackingDataService.locale,
      accountId: this.commonTrackingDataService.accountId,
      edgeExtensionId: this.commonTrackingDataService.edgeExtensionId,
      sessionId: this.sessionHandlerService.sessionId,
      sessionFromCookie: this.sessionHandlerService.sessionFromCookie,
      accountType: this.commonTrackingDataService.accountType,
      amsless: this.commonTrackingDataService.isAmslessAccount,
      faceGated: this.commonTrackingDataService.isAccountFaceGated,
      userId: this.commonTrackingDataService.aiUserId,
      effectiveType: this.commonTrackingDataService.effectiveType,
      accountPermission: this.commonTrackingDataService.accountPermission
    };
  }

  public track(eventName: string, additionalProperties: IEventProperties = {}) {
    const { data = {}, ...restAdditionalProperties } = additionalProperties;
    const cleanedData = this.sanitizerService.sanitizeObject(data);
    const properties = Object.assign({}, this.runtimeData, {
      ...restAdditionalProperties,
      data: JSON.stringify(cleanedData),
      context: eventName
    });

    this.onedsClient.trackEvent({ name: EventType.Action }, properties);
  }

  public trackPageView(pageName: string) {
    this.onedsClient.trackPageView({ name: pageName, uri: location.origin + location.pathname }, this.runtimeData);
  }

  public trackError(error: Error, properties: object = {}) {
    const data = Object.assign({}, this.runtimeData, properties);
    const cleanedData = this.sanitizerService.sanitizeObject(data);
    this.onedsClient.trackException(
      {
        exception: error
      },
      cleanedData
    );
  }

  public trackPageTimings(properties: object) {
    const data = Object.assign({}, this.runtimeData, properties);
    this.onedsClient.trackEvent({ name: EventType.Timing }, data);
  }

  public trackPerformance(eventName: string, category: EventCategory, time: number) {
    this.track(`${eventName}.timing`, {
      category,
      data: { measureTime: time }
    });
  }

  private logEvent(event: ITelemetryItem) {
    this.logger.log(`[track] ${event.name} ${event.data?.context}`, { event });
  }

  private performanceEventTelemetryInitializer(item: IExtendedTelemetryItem) {
    if (item?.baseData?.refUri) {
      // Remove token
      item.baseData.refUri = replaceJWTToken('' + item?.baseData?.refUri);
    }
    if (!(<string[]>[OneDSEventType.Performance, EventType.Timing]).includes(item.name)) {
      return;
    }

    // Get route from router
    const routeData = this.activeRoute?.snapshot?.firstChild?.data as IVIRoute;

    let pageName = '';
    if (routeData?.traceName) {
      pageName = `route.${routeData.traceName}`;
    } else {
      // Fallback - take from url
      pageName = location.pathname
        .replace(new RegExp(`\/${GuidPattern}`, 'g'), '')
        .replace(new RegExp(`\/${VideoIdPattern}`, 'g'), '')
        // remove leading/trailing back slashes
        .replace(/^\/|\/$/g, '')
        // convert inner backslashes to dots so eventually the result will be in the form of
        // route.subroute
        .replace(/\//g, '.');
    }

    this.logger.log(`[track] Set page name to ${pageName}`);

    // Update base data
    Object.assign(item.baseData, this.runtimeData, { name: pageName });
  }

  private get monitoringEnvironment(): string {
    const monitoringEnvironment = this.configService?.Config?.monitoringEnvironment || window?.viewContext?.monitoringEnvironment;
    return monitoringEnvironment || 'Local';
  }

  private get appVersion(): string {
    return this.configService.getCodeVersion();
  }

  private setCommonData() {
    this.commonData = {
      monitoringEnvironment: this.monitoringEnvironment,
      host: window.location.host,
      appVersion: this.appVersion,
      category: EventCategory.GENERAL,
      serviceName: this.SERVICE_NAME,
      browserLanguage: this.commonTrackingDataService.browserLang,
      embed: this.commonTrackingDataService.embedMode.toString(),
      location: this.commonTrackingDataService.location,
      referrer: this.commonTrackingDataService.referrer
    };
  }

  private setOnError() {
    window.onerror = (message, url, lineNumber, columnNumber, error) => {
      // Take error data
      const errorData = {
        message: message,
        scriptUrl: url,
        lineNumber: lineNumber,
        columnNumber: columnNumber
      };
      let currentError = error;
      if (!currentError) {
        // Try to retrieve error data from message if error is undefined
        currentError = message ? (message as unknown as Error) : new Error('Window on Error');
      }
      this.trackError(currentError, errorData);
    };
  }
}
