export const resources = {
  SessionExpiredTitle: '',
  SessionExpiredContent: '',
  SessionExpiredButton: '',
  ForceCloseDialog: '',
  InviteHeader: '',
  Close: '',
  InternalErrorReloadTitle: '',
  InternalErrorReloadContent: '',
  InternalErrorReloadButton: '',
  InviteCopyInviteUrlSuccessNotification: '',
  InviteSuccessNarrator: '',
  InviteRemovingSuccessNarrator: '',
  SurveyDialogTitle: '',
  SurveyDialogMessage: '',
  SurveyDialogOkButton: '',
  SurveyDialogCancelButton: '',
  RestrictedViewerPopupTitle: '',
  RestrictedViewerPopupContent: '',
  FaceGatePopupTitle: '',
  FaceGateInternalApproveSuccess: '',
  FaceGateInternalApproveFailed: '',
  StripAmsMigrationPending: '',
  StripAmsMigrationComplete: '',
  NotificationAmsMigrationCompleteTitle: '',
  NotificationAmsMigrationCompleteMessage: '',
  StripAmsMigrationInProgress: '',
  NotificationAmsMigrationInProgressTitle: '',
  NotificationAmsMigrationInProgressMessage: '',
  StripAmsMigrationFailed: '',
  NotificationAmsMigrationFailedTitle: '',
  NotificationAmsMigrationFailedTitleUntranslated: '',
  NotificationAmsMigrationFailedMessage: '',
  NotificationAmsMigrationFailedLinkText: '',
  StripAmsMigrationPartlySuccessful: '',
  StripAmsMigrationPartlySuccessfulUntranslated: '',
  NotificationAmsMigrationPartlySuccessfulTitle: '',
  NotificationAmsMigrationPartlySuccessfulMessage: '',
  NotificationAmsMigrationPartlySuccessfulLinkText: '',
  NotificationAmsMigrationErrorLinkText: '',
  NotificationAmsMigrationErrorTitle: '',
  NotificationAmsMigrationErrorTitleUntranslated: '',
  NotificationAmsMigrationErrorMessage: '',
  StripMigrationFallbackMessage: '',
  AzurePortalLinkText: '',
  AmsMigrationReportLinkText: ''
};
