import { createSelector } from '@ngrx/store';

import { orderBy } from 'lodash-es';

import { AccountPermission, IUIAccountRoleAssignment } from '@common/modules/shared/interfaces';

import { contributorAction, ownerAction } from '../../invite';
import { selectCoreState } from '../reducers';

export const getRoleAssignmentsState = createSelector(selectCoreState, state => state.roleAssignments);

export const selectRoleAssignmentsLoaded = createSelector(getRoleAssignmentsState, state => state.loaded);

export const selectRoleAssignmentsError = createSelector(getRoleAssignmentsState, state => state.error);

export const selectRoleAssignments = createSelector(getRoleAssignmentsState, state => state.entities);

export const selectUIRoleAssignments = createSelector(selectRoleAssignments, roleAssignments => {
  let accountUIRoleAssignments: IUIAccountRoleAssignment[] = Object.values(roleAssignments).map(roleAssignment => {
    return {
      id: roleAssignment.id,
      user: { ...roleAssignment.user },
      role: roleAssignment.role,
      removing: false,
      selectedRoleAction: roleAssignment.role === AccountPermission.OWNER ? ownerAction : contributorAction
    };
  });
  accountUIRoleAssignments = orderBy(accountUIRoleAssignments, ['role'], ['desc']);
  return accountUIRoleAssignments;
});
