import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const reIndexAction: IAction = {
  title: '',
  key: 'Reindex',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'reIndexBtn'
};

export const reviewAndReIndexAction: IAction = {
  title: '',
  key: 'ReviewAndReIndex',
  type: UIActionType.PREVIEW,
  value: UIActionType.PREVIEW,
  id: 'reviewAndReIndexBtn'
};

export const cancelAction: IAction = {
  title: '',
  key: 'Cancel',
  type: UIActionType.CANCEL,
  value: UIActionType.CANCEL,
  id: 'cancelReIndexBtn'
};

export const closeIconAction: IAction = {
  title: '',
  key: 'Close',
  type: UIActionType.CLOSE,
  value: '',
  icon: 'i-vi-close-big',
  id: 'closeIconReIndex',
  titleAttribute: 'Close'
};

export const closeAction: IAction = {
  title: '',
  key: 'Close',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'closeReIndex'
};
