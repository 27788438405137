import { catchError, concatMap, from, mergeMap, of, switchMap, toArray } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

import { IHttpWrapper } from '@common/modules/api/interfaces';

import { getRequestSettings } from '../../utils/request.function';

import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;
import SpeechDatasetFile = Microsoft.VideoIndexer.Contracts.SpeechDatasetFile;
import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;
import SpeechModelInput = Microsoft.VideoIndexer.Contracts.CustomSpeechModelInput;
import SpeechDatasetInput = Microsoft.VideoIndexer.Contracts.SpeechDatasetInput;

export class CustomizationSpeechApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/models')]
  public listModels(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/models`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<SpeechModel[]>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets')]
  public listDatasets(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<SpeechDataset[]>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets/{datasetId}')]
  public getDataset(accountId: string, datasetId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets/${datasetId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<SpeechDataset>(url, requestSettings);
  }

  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets/{datasetId}/files')]
  public getDatasetFiles(accountId: string, datasetId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets/${datasetId}/files`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<SpeechDatasetFile[]>(url, requestSettings);
  }

  // [HttpPatch]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets/{datasetId}')]
  public updateDataset(
    accountId: string,
    datasetId: string,
    properties: Pick<SpeechDataset, 'displayName' | 'description' | 'customProperties'>,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets/${datasetId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.patch<SpeechDataset>(url, properties, requestSettings);
  }

  // [HttpDelete]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets/{datasetId}')]
  public deleteDataset(accountId: string, datasetId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets/${datasetId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<SpeechDataset>(url, requestSettings);
  }

  // [HttpPost]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/datasets')]
  public createDataset(accountId: string, properties: SpeechDatasetInput, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/datasets`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.post<SpeechDataset>(url, properties, requestSettings);
  }

  // [HttpPatch]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/models/{modelId}')]
  public updateModel(
    accountId: string,
    modelId: string,
    properties: Pick<SpeechModel, 'displayName' | 'description' | 'customProperties'>,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/models/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.patch<SpeechModel>(url, properties, requestSettings);
  }

  // [HttpPost]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/models')]
  public createModel(accountId: string, properties: SpeechModelInput, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/models`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.post<SpeechModel>(url, properties, requestSettings);
  }

  // [HttpDelete]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/models/{modelId}')]
  public deleteModel(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/models/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<SpeechModel>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Speech/models/{modelId}')]
  public getModel(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/speech/models/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<SpeechModel>(url, requestSettings);
  }

  public getDatasetFilesContent(files: SpeechDatasetFile[]) {
    return of(files).pipe(
      mergeMap(files => from(files)),
      concatMap(file =>
        fromFetch(file.contentUrl).pipe(
          switchMap(response => {
            if (response.ok) {
              const method = response.headers.get('Content-Type').includes('text/plain') ? 'text' : 'json';

              return response[method]().then(content => {
                return { content, ...file };
              });
            } else {
              return of({ error: response.statusText });
            }
          }),
          catchError(error => {
            return of({ error });
          })
        )
      ),
      toArray()
    );
  }

  private get apiInternalsBase(): string {
    return this.ApiWrapper.GetInternalApiBase() + this.apiBaseRoute;
  }

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }
}
