import { Action, createReducer, on } from '@ngrx/store';

import { AdvancedSettings, IndexingMode } from './../../interfaces';
import * as indexingViewActions from '../actions/indexing-view.actions';
import * as actions from '../actions';

export interface IndexingViewState {
  permissionDenied: boolean;
  showAdvanced: boolean;
  isIndexingProcessDirty: boolean;
  indexingMode?: IndexingMode;
  selectedAdvancedSettings: AdvancedSettings;
}

export const initialIndexingViewState: IndexingViewState = {
  permissionDenied: false,
  showAdvanced: false,
  isIndexingProcessDirty: false,
  indexingMode: null,
  selectedAdvancedSettings: AdvancedSettings.General
};

const indexingViewStateReducer = createReducer(
  initialIndexingViewState,
  on(indexingViewActions.resetIndexingViewState, () => {
    return Object.assign({}, initialIndexingViewState);
  }),
  on(indexingViewActions.updateIndexingMode, (state, { mode }) => {
    return {
      ...state,
      indexingMode: mode
    };
  }),
  on(indexingViewActions.updateSelectedAdvancedSettings, (state, { settings }) => {
    return {
      ...state,
      selectedAdvancedSettings: settings
    };
  }),
  on(indexingViewActions.toggleAdvancedMode, state => {
    return {
      ...state,
      showAdvanced: !state.showAdvanced
    };
  }),
  on(actions.reIndexVideoFailed, actions.reIndexVideoSucceeded, actions.uploadFiles, state => {
    return {
      ...state,
      isIndexingProcessDirty: false
    };
  }),
  on(actions.updateIndexingSettings, (state, { isDirty }) => {
    return isDirty
      ? {
          ...state,
          isIndexingProcessDirty: true
        }
      : state;
  }),
  on(actions.addUploadFilesSuccess, state => {
    return {
      ...state,
      isIndexingProcessDirty: true
    };
  })
);

export function reducer(state: IndexingViewState | undefined, action: Action) {
  return indexingViewStateReducer(state, action);
}
