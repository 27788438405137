import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { ONLINE_SERVICES_TERMS } from '@common/modules/shared/links';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';

@Component({
  selector: 'vi-upload-consent',
  templateUrl: './upload-consent.component.html',
  styleUrls: ['./upload-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadConsentComponent implements OnInit, OnChanges {
  @Input() consent: boolean = false;
  @Input() isUSAccount: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isTrialAccount: boolean;
  @Output() consentChange = new EventEmitter();

  public resources = resources;

  constructor(private translate: TranslateHelperService, private utils: UtilsService) {}

  public ngOnInit(): void {
    this.initTranslations();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isTrialAccount) {
      this.initTranslations();
    }
  }

  public get label() {
    return this.isUSAccount ? resources.UploadConsentUSSubmitTerms : resources.ViUploadConsentSubmitTerms;
  }

  public get inputLabel() {
    return this.isUSAccount ? resources.UploadConsentUSSubmitTermsTitle : resources.UploadSubmitTermsTitle;
  }

  public toggle(value: boolean) {
    this.consent = value;
    this.consentChange.emit(this.consent);
  }

  private initTranslations() {
    this.translate.translateResources(this.resources, {
      documentationLink: ONLINE_SERVICES_TERMS,
      documentationTitle: this.resources.UploadOnlineServicesTerms,
      serviceAgreementLink: this.utils.getLinkByValue(this.isTrialAccount, 'ServiceAgreement'),
      serviceAgreementTitle: this.resources.UploadServiceAgreement,
      privacyStatementLink: this.utils.getLinkByValue(this.isTrialAccount, 'PrivacyStatement'),
      privacyStatementTitle: this.resources.UploadPrivacyStatement
    });
  }
}
