export const resources = {
  DeleteAccountPermanentlyDeletedMessage: '',
  DeleteAccountNoLongerAccessMessage: '',
  DeleteAccountSharedPeopleTitle: '',
  DeleteAccountEnterNameAccountLabel: '',
  DeleteAccountEnterNameAccountTitle: '',
  DeleteAccountEnterNameAccountPlaceholder: '',
  DeleteAccountDeletedAccountName: '',
  SettingsLeaveAccountPending: '',
  DeleteAccountPending: '',
  DeleteAccountPulledOutSuccessfully: '',
  DeleteAccountDialogOptionsActionBtnText: '',
  DeleteAccountTitle: '',
  DeleteAccountEmptyAccountSharedList: ''
};
