import { map } from 'rxjs';

import { IHttpWrapper, IAPIRequestParams, ICaptionsRequestParams, IListRequestParams, ISearchRequestParams } from '../interfaces';
import { getCustomEncodingParams, prepareProjectSummarizedInsights } from '../utils/api-utils';
import { getRequestSettings } from '../utils/request.function';

export class ProjectApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiInternalBase(): string {
    return this.ApiWrapper.GetInternalApiBase() + this.apiBaseRoute;
  }

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase() + this.apiBaseRoute;
  }

  private get apiAuthBase(): string {
    return this.ApiWrapper.GetAuthApiBase(true) + this.apiBaseRoute;
  }

  private get apiLocation(): string {
    return this.ApiWrapper.GetApiLocation();
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Projects")]
  public list(accountId: string, requestParams?: IListRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/projects/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, requestParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Projects/{projectId}")]
  public get(accountId: string, projectId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/projects/${projectId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Projects/Search")]
  public search(accountId: string, searchParams?: ISearchRequestParams, reqSettings?: object, suffixUrl = '') {
    const url = `${this.apiInternalBase}/${accountId}/projects/search/?${suffixUrl}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, searchParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistSearchResultV2>(url, {
      ...requestSettings,
      params: getCustomEncodingParams(requestSettings['params'])
    });
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Projects/{projectId}/Index")]
  public getProjectIndex(accountId: string, projectId: string, reqParams?: IAPIRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Projects/${projectId}/index`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.ProjectPlaylistContractV2>(url, requestSettings).pipe(
      map(index => prepareProjectSummarizedInsights(index, this.ApiWrapper.Injector))
    );
  }

  // [HttpGet]
  // [Route("Api/v2/auth/Accounts/{accountId}/Projects/{videoId}/AccessToken")]
  public getProjectAccessToken(accountId: string, projectId: string, allowEdit?: boolean, reqSettings?: object) {
    const url = `${this.apiAuthBase}/${accountId}/projects/${projectId}/accessToken/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;
    requestSettings['params'].allowEdit = allowEdit;
    requestSettings['params'].location = this.apiLocation;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  public getProjectIndexUrl(
    accountId: string,
    projectId: string,
    accessToken: string,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2,
    query: { [key: string]: string | boolean } = {}
  ): string {
    const params = {
      language: language,
      accessToken: accessToken,
      ...query
    };

    const serializedParams = this.ApiWrapper.UrlUtility.paramsToUrl(params);

    return `${this.apiOperationBase}/${accountId}/Projects/${projectId}/Index/${serializedParams ? '?' + serializedParams : ''}`;
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Projects")]
  public createProject(
    accountId: string,
    playlist: Microsoft.VideoIndexer.Contracts.CreateOrUpdateProjectContract,
    reqParams?: IAPIRequestParams,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/Projects`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams);
    return this.ApiWrapper.HttpClient.post(url, playlist, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Projects/{projectId}")]
  public updateProject(
    accountId: string,
    projectId: string,
    playlist: Microsoft.VideoIndexer.Contracts.CreateOrUpdateProjectContract,
    reqParams?: IAPIRequestParams,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/Projects/${projectId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams);
    return this.ApiWrapper.HttpClient.put(url, playlist, requestSettings);
  }

  // [HttpDelete]
  // [Route("/{location}/Accounts/{accountId}/Projects/{videoId}")]
  public deleteVideo(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/projects/${videoId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Projects/{projectId}/captions")]
  public getCaptionsUrl(accountId: string, projectId: string, reqParams: ICaptionsRequestParams): string {
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, null, reqParams);
    const serializedParams = this.ApiWrapper.UrlUtility.paramsToUrl(requestSettings['params']);
    return `${this.apiOperationBase}/${accountId}/projects/${projectId}/captions/?${serializedParams}`;
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/projects/{videoId}/Captions")]
  public getCaptions(accountId: string, projectId: string, reqParams?: ICaptionsRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/projects/${projectId}/captions/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams);
    requestSettings['responseType'] = 'text';
    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }
}
