import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { IDialogData, IDialogButton, IData } from './interfaces';
import { IEventCell } from '../../../../../apps/web/src/grid/components/grid-cell/interfaces';
import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {
  private readonly MOBILE_FULL_SCREEN = 'mobile-full-screen';
  private readonly DYNAMIC_HEIGHT_CLASS = 'dynamic-height';

  constructor(private dialog: MatDialog, private liveAnnounce: LiveAnnouncer) {}

  public getDialogData(
    c: string,
    t: string,
    content: string,
    sb: IDialogButton,
    pb: IDialogButton,
    e?: IEventCell,
    component?: Type<object>,
    componentData?: IData,
    disableAutoClose?: boolean
  ): IDialogData {
    return {
      class: c,
      title: t,
      content: content,
      secondaryButton: sb,
      primaryButton: pb,
      event: e,
      component: component,
      componentData: componentData,
      disableAutoClose: disableAutoClose
    };
  }

  public openDialog(
    dialogData: IDialogData,
    width: string,
    height?: string,
    panelClass?: string,
    disableClose = false,
    isDynamicHeight = false,
    minHeight = 'auto',
    isMobileFullScreen = false
  ) {
    const panelClasses = [];
    if (panelClass) {
      panelClasses.push(panelClass);
    }
    if (isDynamicHeight) {
      panelClasses.push(this.DYNAMIC_HEIGHT_CLASS);
    }
    if (isMobileFullScreen) {
      panelClasses.push(this.MOBILE_FULL_SCREEN);
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: width,
      height: height,
      data: dialogData,
      disableClose: disableClose,
      ariaLabel: dialogData.title,
      panelClass: panelClasses,
      minHeight: minHeight
    });

    if (isMobileFullScreen) {
      this.addMobileFullScreenOverlay();
    }

    return dialogRef;
  }

  public closeAllDialogs(announceMessage: string) {
    if (this.dialog.openDialogs?.length) {
      this.liveAnnounce.announce(announceMessage);
      this.dialog.closeAll();
    }
  }

  // add background (--bg-tertiary) instead of overlay
  public addBackgroundToOverlay() {
    const overlay = document?.querySelector('.cdk-global-overlay-wrapper');
    overlay?.classList?.add('bg-background');
  }

  private addMobileFullScreenOverlay() {
    const overlay = document?.querySelector('.cdk-global-overlay-wrapper');
    overlay?.classList?.add(this.MOBILE_FULL_SCREEN);
  }
}
