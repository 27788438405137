import LanguageModel = Microsoft.VideoIndexer.Contracts.LanguageModel;
import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;
import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;

export interface LanguageModelListContract {
  language: string;
  languageKey: string;
  languageModels: LanguageModel[];
  isExpanded?: boolean;
  isCreating?: boolean;
  editMode?: boolean;
}

export enum DatasetKindEnum {
  Language = 'Language',
  Acoustic = 'Acoustic',
  Pronunciation = 'Pronunciation',
  AudioFiles = 'AudioFiles',
  LanguageMarkdown = 'LanguageMarkdown'
}

export enum SpeechPermission {
  UNDEFINED = 'UNDEFINED',
  PERMITTED = 'PERMITTED',
  REJECTED = 'REJECTED'
}

export enum SpeechDatasetKind {
  LANGUAGE = 'Language',
  PRONUNCIATION = 'Pronunciation'
}

export enum SpeechObjectState {
  NONE = 'None',
  WAITING = 'Waiting',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
  FAILED = 'Failed'
}

export enum UIDatasetState {
  UPLOADING = 'Uploading'
}

export type UISpeechObjectState = SpeechObjectState | UIDatasetState;

export interface IUISpeechModelTableDetail extends Pick<SpeechModel, 'id' | 'description' | 'locale' | 'createdDateTime' | 'datasets'> {
  name: string;
  language: string;
  date: string;
  status: SpeechObjectState;
}

export interface IUISpeechDatasetTableDetail extends Pick<SpeechDataset, 'id' | 'description' | 'locale'> {
  name: string;
  type: string;
  language: string;
  numOfLines: string;
  date: string;
  disabled?: boolean;
  disabledReason?: string;
  selected?: boolean;
  status: UISpeechObjectState;
}

export enum TrainModelDialogSource {
  DATASETS = 'DATASETS',
  MODELS = 'MODELS'
}
