import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Observable } from 'rxjs';

import { INotification } from '@common/modules/core/services/toast/interfaces';

import { IState } from '../reducers';
import * as actions from '../actions';
import * as fromCore from '../selectors';

@Injectable()
export class NotificationsStoreService {
  constructor(private readonly store: Store<IState>) {}

  // Notifications common
  public get getNotificationPanelAlerts$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotificationPanelAlerts);
  }

  public get getNotificationPanelMessages$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotificationPanelMessages);
  }

  public get getNotificationsLoadingState$(): Observable<boolean> {
    return this.store.select(fromCore.getNotificationsLoadingState);
  }

  public clearAlertsAndBannersNotifications() {
    this.store.dispatch(actions.clearNotificationsBanners());
    this.store.dispatch(actions.clearNotificationsAlerts());
  }

  public clearMessagesNotifications() {
    this.store.dispatch(actions.clearNotificationsMessages());
  }

  public removeNotifications(ids: string[]) {
    this.store.dispatch(actions.removeNotifications({ ids }));
  }

  // Banners
  public get bannerNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.selectAllBannersNotifications);
  }

  public get notSeenBannersNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotSeenBannersNotifications);
  }

  public get notToastedBannersNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotToastedBannersNotifications);
  }

  public get bannerNotificationsIds$(): Observable<string[]> {
    return this.store.select(fromCore.selectBannersNotificationsIds);
  }

  public updateBannersNotifications(notifications: Update<INotification>[]) {
    this.store.dispatch(actions.updateNotificationsBanners({ notifications }));
  }

  public clearBannersNotifications() {
    this.store.dispatch(actions.clearNotificationsBanners());
  }

  // Alerts
  public get alertsNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.selectAllAlertsNotifications);
  }

  public get notSeenAlertsNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotSeenAlertsNotifications);
  }

  public get notToastedAlertsNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotToastedAlertsNotifications);
  }

  public updateAlertsNotifications(notifications: Update<INotification>[]) {
    this.store.dispatch(actions.updateNotificationsAlerts({ notifications }));
  }

  // Messages
  public get messageNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.selectAllMessagesNotifications);
  }

  public get notSeenMessagesNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotSeenMessagesNotifications);
  }

  public get notToastedMessagesNotifications$(): Observable<INotification[]> {
    return this.store.select(fromCore.getNotToastedMessagesNotifications);
  }

  public get notClearedNotificationsCount$(): Observable<number> {
    return this.store.select(fromCore.getNotClearedNotificationsCount);
  }

  public updateMessagesNotifications(notifications: Update<INotification>[]) {
    this.store.dispatch(actions.updateNotificationsMessages({ notifications }));
  }
}
