import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { Observable, Subject } from 'rxjs';
import { take, takeUntil, debounceTime, skip } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';
import { ClipboardService } from 'ngx-clipboard';

import { envTypes } from '@common/modules/api/interfaces';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { FeatureSwitch, IUIAccount, NavigationState } from '@common/modules/core/services/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { AccountResourceType } from '@common/modules/auth/interfaces';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { VIRoutingMap } from '../../../app/routing/routes';
import { resources } from './resources';
import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';
import { BadgeClasses } from '../../../shell/interfaces';
import { connectToAzureAction } from '../../../settings/components/trial-account-settings-details/actions';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./accounts-list.component.scss']
})
export class AccountsListComponent implements OnInit, OnDestroy {
  @Output() public close = new EventEmitter();

  public resources = resources;
  public createAccount;
  public ready = false;
  public readonly BUTTON_WIDTH = '208px';
  public readonly MIN_ACCOUNTS_FOR_FILTER = 6;
  public ActionButtonType = ActionButtonType;

  public accounts$: Observable<IUIAccount[]>;
  public selectAccountsCount = 1;
  public edgeExtensions: Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension[];
  public selectedAccount: IUIAccount;
  public selectedAccountContract$: Observable<Microsoft.VideoIndexer.Contracts.AccountContract>;
  public userRule$: Observable<Microsoft.VideoIndexer.Contracts.UserRole>;
  public armAccountsError = false;
  public armAccountsLoaded = false;
  public AccountResourceType = AccountResourceType;
  public currentPermission$: Observable<AccountPermission>;
  public isZeroAccounts$: Observable<boolean>;
  public AccountPermission = AccountPermission;
  public accountFilterValue = '';

  private destroy$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private navigationService: AppNavigationService,
    private coreStore: CoreStoreService,
    private trackService: TrackService,
    private featureSwitchService: FeatureSwitchService,
    private liveAnnouncer: LiveAnnouncer,
    private toastService: ToastService,
    private clipboardService: ClipboardService,
    private focusManager: FocusManagerService,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {}

  public get showAccountsFilter(): boolean {
    return this.selectAccountsCount >= this.MIN_ACCOUNTS_FOR_FILTER;
  }

  public ngOnInit() {
    if (this.featureSwitchService.featureSwitch(FeatureSwitch.ARM)) {
      this.coreStore.armAccountsLoaded$.pipe(takeUntil(this.destroy$)).subscribe(armAccountsLoaded => {
        this.armAccountsLoaded = armAccountsLoaded;
        this.cdr.detectChanges();
        if (!this.armAccountsLoaded) {
          this.liveAnnouncer.announce(this.resources.ArmAccountsLoading);
        }
      });

      this.coreStore.armAccountsError$.pipe(takeUntil(this.destroy$)).subscribe(error => {
        if (error) {
          this.liveAnnouncer.announce(this.resources.ArmAccountsErrorLoading);
          this.armAccountsError = true;
        }
      });
    }

    if (this.featureSwitchService.featureSwitch(FeatureSwitch.Edge)) {
      this.edgeExtensionsStore.selectEdgeExtensions$.pipe(takeUntil(this.destroy$)).subscribe(edgeExtensions => {
        this.edgeExtensions = edgeExtensions;
        this.cdr.detectChanges();
      });
    }

    this.translate.translateResources(this.resources).subscribe(() => {
      this.setProperties();
      this.ready = true;
      this.cdr.detectChanges();
    });

    this.accounts$ = this.coreStore.filteredUIAccounts$().pipe(takeUntil(this.destroy$));
    this.coreStore.selectAccountsCount$.pipe(takeUntil(this.destroy$)).subscribe(count => {
      this.selectAccountsCount = count;
    });
    this.coreStore.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe(account => {
      this.selectedAccount = account;
      this.cdr.detectChanges();
    });
    this.selectedAccountContract$ = this.coreStore.selectedAccountContract$.pipe(takeUntil(this.destroy$));
    this.userRule$ = this.coreStore.roleByUser$.pipe(takeUntil(this.destroy$));
    this.isZeroAccounts$ = this.coreStore.isZeroAccounts$.pipe(takeUntil(this.destroy$));
    this.userRule$.subscribe(userRole => {
      this.trackService.track('shell.user_role', { role: userRole });
    });

    // using skip 1 to avoid tracking the initial value of the accounts
    this.accounts$.pipe(skip(1), debounceTime(1000), takeUntil(this.destroy$)).subscribe(() => {
      this.trackService.track('shell.account.filtered_accounts');
    });

    this.currentPermission$ = this.coreStore.getAccountTokenPermission$.pipe(takeUntil(this.destroy$));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public filterAccounts() {
    this.coreStore.setAccountsFilterValue(this.accountFilterValue);
  }

  public getTranslationLocationTitle(account: IUIAccount): string {
    if (!account) {
      return;
    }
    const titleResource = { HeaderLocationTitle: '' };
    this.translate.translateResources(titleResource, {
      location: account.location
    });
    return titleResource.HeaderLocationTitle;
  }

  public isTrial(account: IUIAccount): boolean {
    return account && account.location === envTypes.TRIAL;
  }

  public openInvite() {
    this.coreStore.openShareAccount(true);

    this.trackService.track('shell.account.open_invite.click', {
      category: EventCategory.SHELL
    });
  }

  public openConnectToAzure() {
    this.navigationService.createAccountSubject.next(NavigationState.OPEN);

    this.trackService.track('shell.account.open_connect_to_azure.click', {
      category: EventCategory.SHELL
    });
  }

  public openAccountSettings() {
    this.trackService.track('shell.account.open_account_settings.click', {
      category: EventCategory.SHELL
    });
    this.coreStore.selectedAccountId$.pipe(take(1)).subscribe(id => {
      const route = VIRoutingMap.settings.path.replace(':accountId', id);
      this.coreStore.navigate([`/${route}`]);
      this.close.emit();
    });
  }

  public chooseAccount(account: IUIAccount) {
    this.trackService.track('shell.account.choose_account.click', {
      category: EventCategory.SHELL
    });
    this.coreStore.selectAccount(account.id);
    this.resetFilterAccounts();
    // Clear query params on change account
    this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`], null, null, true);
  }

  public copyAccountId() {
    // copy to clipboard
    this.clipboardService.copyFromContent(this.selectedAccount?.id);
    this.toastService.success(this.resources.SettingsCopyAccountIDSuccessNotification, false);
    // copyFromContent function change the focus.
    // So, set the focus back to copy button.
    setTimeout(() => {
      this.focusManager.focusByQuery('#copyBtnAccountList');
    }, 100);

    this.trackService.track('accounts_list.copy_account_id.clicked', {
      category: EventCategory.SIDE_BAR
    });
  }

  public getBadgeClass(account: IUIAccount) {
    if (account?.resourceType !== AccountResourceType.ARM) {
      return BadgeClasses.Classic;
    }

    return this.edgeExtensions?.filter(ext => ext.properties?.configurationSettings['videoIndexer.accountId'] === account.id)?.length > 0
      ? BadgeClasses.Hybrid
      : BadgeClasses.NoBadge;
  }

  public getBadgeText(account: IUIAccount) {
    if (account?.resourceType !== AccountResourceType.ARM) {
      return this.resources?.Classic;
    }

    return this.edgeExtensions?.filter(ext => ext.properties?.configurationSettings['videoIndexer.accountId'] === account.id)?.length > 0
      ? this.resources.Hybrid
      : '';
  }

  private setProperties() {
    connectToAzureAction.title = this.resources.VIHeaderCreatePro;
    this.createAccount = cloneDeep(connectToAzureAction);
    this.createAccount.id = this.createAccount.id + 'Sidebar';
  }

  private resetFilterAccounts() {
    this.accountFilterValue = '';
    this.filterAccounts();
  }
}
