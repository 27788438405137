<div class="brand-categories">
  <vi-ui-label [for]="dropdownId"
    [id]="'indexingBrandsCategories'"
    [value]="resources?.BrandsModel"
    [disabled]="isDisabled || isLoading">
  </vi-ui-label>
  <app-vi-check-box-action-menu [actionList]="brandsModelActions"
    [ariaLabelledBy]="dropdownId"
    [selectedAction]="defaultBrandCategory"
    [uid]="'indexing-brands'"
    [isDisabled]="isDisabled || isLoading"
    [placeholder]="menuPlaceholder"
    (selectedActionsChange)="onSelectBrandsModel($event)">
  </app-vi-check-box-action-menu>
  <app-vi-action-button [type]="ActionButtonType.ACTION_BUTTON"
    [action]="manageBrandsCategoriesAction"
    [width]="actionButtonWidth"
    [showAccessibilityTooltip]="true"
    [isDisabled]="isDisabled"
    (actionClick)="onManageBrandsCategories()">
  </app-vi-action-button>
</div>