import { createReducer, on } from '@ngrx/store';

import * as AmsMigrationActions from '../actions/ams-migration.actions';

export interface IState {
  accountMigration: Microsoft.VideoIndexer.Contracts.AmsAccountMigration;
  videoMigrations: Microsoft.VideoIndexer.Contracts.AmsMigrationsListResult;
  isLoading: boolean;
  loadingMore: boolean;
}

const initialState: IState = {
  accountMigration: {
    status: null,
    progress: 0,
    videosLeftToMigrate: 0,
    videosMigrated: 0,
    videosFailedToMigrate: 0,
    totalVideosToMigrate: 0,
    migrationCompletedDate: null,
    details: ''
  },
  videoMigrations: {
    results: [],
    nextPage: {
      pageSize: 25,
      skip: 0,
      done: null
    }
  },
  isLoading: true,
  loadingMore: false
};

const amsMigrationReducer = createReducer(
  initialState,
  on(AmsMigrationActions.getAmsMigrationStatusSuccess, (state, { migrationState }) => {
    return {
      ...state,
      accountMigration: { ...migrationState },
      // If video migrations are not loaded, we are still loading.
      ...(state.videoMigrations.nextPage.done !== null && { isLoading: false })
    };
  }),
  on(AmsMigrationActions.getVideoMigrationsSucceed, (state, { videoMigrations }) => {
    if (videoMigrations?.nextPage?.skip > 0) {
      videoMigrations = {
        ...videoMigrations,
        results: state.videoMigrations.results.concat(videoMigrations.results)
      };
    }

    return {
      ...state,
      videoMigrations: { ...videoMigrations },
      // If account migration status is null, we are still loading.
      ...(state.accountMigration.status !== null && { isLoading: false }),
      loadingMore: false
    };
  }),
  on(AmsMigrationActions.loadMoreVideoMigrations, state => {
    return {
      ...state,
      loadingMore: true
    };
  }),
  on(AmsMigrationActions.getVideoMigrationStarted, state => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(AmsMigrationActions.getMigrationStatusFailed, AmsMigrationActions.getVideoMigrationsFailed, state => {
    return {
      ...state,
      isLoading: false,
      loadingMore: false
    };
  }),
  on(AmsMigrationActions.resetAmsMigrationState, () => {
    return initialState;
  })
);

export function reducer(state, action) {
  return amsMigrationReducer(state, action);
}
