import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, withLatestFrom, EMPTY, catchError, tap, delay, takeUntil, of } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { guid } from '@common/modules/utils/string';
import { TimeInterval } from '@common/modules/utils/time';
import {
  INotification,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';

import { resources } from './resources';
import { IState } from '../../../core/reducers';
import * as fromCore from '../../../core/selectors';
import * as fromCustomizationData from '../../selectors';
import * as SpeechModelsActions from '../../actions/speech/speech-models.actions';
import { SpeechObjectState } from '../../interfaces';
import { SpeechErrorsService } from '../../services/speech/speech-errors.service';

import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;
import SpeechModelInput = Microsoft.VideoIndexer.Contracts.CustomSpeechModelInput;

@Injectable()
export class SpeechModelsEffects {
  public readonly GET_MODEL_STATUS_DELAY = TimeInterval.SECOND * 10;

  public loadSpeechModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.loadSpeechModels),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
      withLatestFrom(this.store.select(fromCustomizationData.isSpeechModelsLoaded)),
      switchMap(([[[, accountId], permission], isLoaded]) => {
        // Return empty in case the models already loaded in the store or account with restricted viewer permission
        if (isLoaded || !accountId || permission === AccountPermission.RESTRICTED_VIEWER) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.Speech.listModels(accountId).pipe(
          switchMap((models: SpeechModel[]) => {
            this.trackService.track('speech.load.models.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });
            return [SpeechModelsActions.loadSpeechModelsSucceeded({ models: models })];
          }),
          catchError(error => {
            this.trackService.track('speech.load.models.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [SpeechModelsActions.loadSpeechModelsFailed()];
          })
        );
      })
    )
  );

  public updateSpeechModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.updateSpeechModel),
      switchMap(({ modelId, properties }) =>
        of(modelId).pipe(withLatestFrom(this.store.select(fromCustomizationData.getSpeechModelId(modelId))), withLatestFrom(of(properties)))
      ),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[[modelId, modelBeforeUpdate], properties], accountId]) => {
        if (!modelId || !accountId) {
          return EMPTY;
        }

        this.store.dispatch(SpeechModelsActions.updateSpeechModelStarted());

        return this.apiService.Account.Customization.Speech.updateModel(accountId, modelId, properties).pipe(
          switchMap(model => {
            this.trackService.track('speech.update_model.succeeded', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                fields: {
                  name: modelBeforeUpdate.displayName !== properties.displayName,
                  description: modelBeforeUpdate.description !== properties.description,
                  nameLength: properties.displayName.length,
                  descriptionLength: properties.description?.length
                }
              }
            });
            return [SpeechModelsActions.updateSpeechModelSucceed({ model })];
          }),
          catchError(error => {
            this.trackService.track('speech.update_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            const title = this.resources.SpeechFailedUpdateModelTitle;
            const text = this.resources.SpeechFailedUpdateModelText;
            const errorNotification = this.getErrorNotification(modelId, title, text);
            this.toastService.notify(errorNotification);
            return [SpeechModelsActions.updateSpeechModelFailed()];
          })
        );
      })
    )
  );

  public createSpeechModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.createSpeechModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ properties }, accountId]) => {
        if (!properties || !accountId) {
          return EMPTY;
        }

        const creatingModel = this.getCreatingSpeechModel(properties) as SpeechModel;
        this.store.dispatch(SpeechModelsActions.createSpeechModelStarted({ model: creatingModel }));

        return this.apiService.Account.Customization.Speech.createModel(accountId, properties).pipe(
          switchMap(model => {
            this.trackService.track('speech.train.models.succeed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                locale: model.locale,
                nameLength: model.displayName.length,
                descriptionLength: model.description?.length
              }
            });
            return [
              SpeechModelsActions.removeSpeechModel({ modelId: creatingModel.id }),
              SpeechModelsActions.createSpeechModelSucceed({ model }),
              SpeechModelsActions.getModelStatus({ modelId: model.id })
            ];
          }),
          catchError(error => {
            this.trackService.track('speech.train.models.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            const title = this.translate.instant('SpeechFailedTrainModelTitle', { modelName: properties.displayName });
            const text = this.resources.SpeechFailedTrainModelText;
            const errorNotification = this.getErrorNotification(creatingModel.id, title, text);
            this.notificationService.notify(errorNotification);
            return [SpeechModelsActions.createSpeechModelFailed({ modelId: creatingModel.id })];
          })
        );
      })
    )
  );

  public deleteSpeechModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.deleteSpeechModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ model }, accountId]) => {
        if (!model.id || !accountId) {
          return EMPTY;
        }

        this.store.dispatch(SpeechModelsActions.deleteSpeechModelStarted());

        return this.apiService.Account.Customization.Speech.deleteModel(accountId, model.id).pipe(
          switchMap(() => {
            this.trackService.track('speech.delete_model.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });
            return [SpeechModelsActions.deleteSpeechModelSucceed({ modelId: model.id })];
          }),
          catchError(error => {
            this.trackService.track('speech.delete_model.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: { error: error }
            });
            return [SpeechModelsActions.deleteSpeechModelFailed({ error, modelId: model.id, modelName: model.name })];
          })
        );
      })
    )
  );

  public onFailedDeleteModel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SpeechModelsActions.deleteSpeechModelFailed),
        tap(({ error, modelId, modelName }) => {
          const errorTexts = this.speechErrorsService.getSpeechModelDeleteErrorTexts(error, modelId, modelName);
          const errorNotification = this.getErrorNotification(modelId, errorTexts.title, errorTexts.content);
          this.toastService.notify(errorNotification);
        })
      ),
    {
      dispatch: false
    }
  );

  public getModelsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.loadSpeechModelsSucceeded),
      switchMap(({ models }) => {
        const processingModels = models.filter(model => this.isModelProcessing(model));
        if (processingModels.length === 0) {
          return EMPTY;
        }
        const actionsList = [];
        processingModels.forEach(model => {
          actionsList.push(SpeechModelsActions.getModelStatus({ modelId: model.id }));
        });
        return actionsList;
      })
    )
  );

  public getModelStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechModelsActions.getModelStatus),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      delay(this.GET_MODEL_STATUS_DELAY),
      switchMap(([{ modelId }, accountId]) => {
        if (!modelId || !accountId) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.Speech.getModel(accountId, modelId).pipe(
          takeUntil(this.actions$.pipe(ofType(SpeechModelsActions.clearSpeechModels))),
          switchMap((model: SpeechModel) => {
            this.trackService.track('speech.get_model_status.succeeded', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                status: model.status
              }
            });
            const actionsList = [];
            if (this.isModelProcessing(model)) {
              actionsList.push(SpeechModelsActions.getModelStatus({ modelId }));
            }
            actionsList.push(SpeechModelsActions.getModelStatusSucceeded({ model }));
            return actionsList;
          }),
          catchError(error => {
            this.trackService.track('speech.get_model_status.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [SpeechModelsActions.getModelStatusFailed(error)];
          })
        );
      })
    )
  );

  private resources = resources;
  private newModelId = 0;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private trackService: TrackService,
    private translate: TranslateHelperService,
    private notificationService: NotificationsService,
    private toastService: ToastService,
    private speechErrorsService: SpeechErrorsService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.translate.translateResourcesInstant(this.resources);
  }

  private getCreatingSpeechModel(modelProperties: SpeechModelInput) {
    const creatingSpeechModel = {
      displayName: modelProperties.displayName,
      description: modelProperties.description,
      id: `new_model_${this.newModelId++}_${guid()}`,
      locale: modelProperties.locale,
      createdDateTime: new Date().toString(),
      lastActionDateTime: new Date().toString(),
      status: SpeechObjectState.WAITING,
      properties: null,
      datasets: [],
      customProperties: {}
    };
    return creatingSpeechModel;
  }

  private isModelProcessing(model) {
    return model.status === SpeechObjectState.WAITING || model.status === SpeechObjectState.PROCESSING;
  }

  private getErrorNotification(modelId: string, title: string, text: string): INotification {
    return {
      id: `failed_model_${modelId}_${guid()}`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Message,
      level: NotificationLevel.Error,
      icon: NotificationIcon.Error,
      title: title,
      text: text
    };
  }
}
