import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { cloneDeep, remove } from 'lodash-es';

import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { IAction } from '../../interfaces';
import { downloadAction, downloadOptionsWithClosedCaptionOption, downloadOptionsWithClosedCaptionList, downloadOptionsForEdgeVideo } from './actions';
import { DownloadService } from './download-service.service';
import { DownloadWidgetsName, IDownloadVideoData, UIDownloadActionType } from './interfaces';
import { resources } from './resources';
import { IDownloadCationsOptions } from './components/download-closed-captions/interfaces';

@Component({
  selector: 'app-vi-download-menu',
  templateUrl: './download-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./download-menu.component.scss']
})
export class DownloadMenuComponent implements OnInit {
  @Input() public videoData: IDownloadVideoData;
  @Input() public langKey: Microsoft.VideoIndexer.Contracts.LanguageV2 = 'en-US';
  @Input() public alignTo = 'left';
  @Input() public id = downloadAction.id;
  @Input() public widgetName: DownloadWidgetsName = DownloadWidgetsName.MEDIA;
  @Input() public renderState: Microsoft.VideoIndexer.Contracts.AsyncOperationState = 'Canceled';
  @Input() public internal = false;
  @Input() public hasSelectedEdgeExtension = false;

  // Actions
  public downloadAction = downloadAction;
  public downloadOptions: IAction[];

  public ready = false;
  public resources = resources;

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private downloadService: DownloadService,
    private trackService: TrackService
  ) {}

  public ngOnInit() {
    this.initDownloadOptions();
    this.setTranslation();
  }

  public async selectAction(action: IAction) {
    this.trackAction(action);
    await this.downloadService.onSelectAction(
      action,
      this.videoData.id,
      this.videoData.accountId,
      this.videoData.isBase,
      this.videoData.name,
      this.downloadedLang,
      this.captionsOptions,
      this.id
    );
  }

  private get downloadedLang() {
    if (!this.videoData.isBase) {
      // If it's a project and the current language is empty, it's a multi video
      return this.langKey === '' ? this.langKey : this.videoData.videos[0].language;
    } else {
      if (this.videoData.videos[0].language) {
        // If the video index has a language, return it
        return this.videoData.videos[0].language;
        // If the video index has multiple languages (not source languages) but no language is requested,
        // download it without language param, the downloaded CC/insights will be with the multi-lid languages data
      } else if (this.videoData.videos[0].languages?.length) {
        return '';
      } else {
        // If none of the above, return the current language key
        return this.langKey;
      }
    }
  }

  private trackAction(action: IAction) {
    this.trackService.track(`${this.widgetName}.download.${(action.value as string).toLowerCase()}`, {
      category: EventCategory.DOWNLOAD,
      data: {
        actionId: action.id,
        includeAudioEffects: false
      }
    });
  }

  private setTranslation() {
    this.translate.translateResources(this.resources).subscribe(() => {
      this.downloadOptions.forEach(c => {
        // In internal - do not translate closed captions actions
        if (c.type === UIDownloadActionType.CLOSED_CAPTIONS && this.internal) {
          return;
        }
        c.title = this.resources[c.key];
      });
      this.downloadAction.title = this.resources[this.downloadAction.key];
      this.ready = true;
      this.cdr.detectChanges();
    });
  }

  private initDownloadOptions() {
    if (this.hasSelectedEdgeExtension) {
      this.downloadOptions = cloneDeep(downloadOptionsForEdgeVideo);
      return;
    }

    this.downloadOptions = this.internal ? cloneDeep(downloadOptionsWithClosedCaptionOption) : cloneDeep(downloadOptionsWithClosedCaptionList);

    remove(this.downloadOptions, s => {
      const downloadOptionsToCheck = [UIDownloadActionType.VIDEO, UIDownloadActionType.ARTIFACTS_ZIP, UIDownloadActionType.PROJECT_RENDERED_VIDEO];
      // If the options doesn't include download video and download zip
      if (!downloadOptionsToCheck.includes(s.value)) {
        return false;
      }
      // If it is a playlist that has been rendered - show
      if (s.value === UIDownloadActionType.PROJECT_RENDERED_VIDEO) {
        if (!this.videoData.isBase && this.renderState === 'Succeeded') {
          return false;
        } else {
          return true;
        }
      }
      // If not base, disable download video and download zip
      if (!this.videoData.isBase) {
        return true;
      }
      // If it's a not an editable video (User doesn't have an accessToken), don't allow to download the video
      if (!(this.videoData.isBase && this.videoData.isEditable) && s.value === UIDownloadActionType.VIDEO) {
        return true;
      }
      return false;
    });
  }

  public get captionsOptions(): IDownloadCationsOptions {
    const showIncludeSpeakers = this.videoData.videos.reduce(
      (includeSpeakers, video) => includeSpeakers || (video?.insights?.transcript?.length > 0 && video?.insights?.speakers?.length > 0),
      false
    );

    const showIncludeAudioEffects = this.videoData.videos.reduce(
      (includeAudioEffects, video) => includeAudioEffects || video?.insights?.audioEffects?.length > 0,
      false
    );
    return {
      showIncludeSpeakers: showIncludeSpeakers,
      showIncludeAudioEffects: showIncludeAudioEffects
    };
  }
}
