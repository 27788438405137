import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VIUIModule } from '@vi-ui/core';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@common/modules/shared/shared.module';

import { ReIndexEffects } from './core/effects/re-index.effects';
import { IndexingFormEffects } from './core/effects/indexing-form.effects';
import { FileHelperService } from './services/file-helper.service';
import { CustomizationDataModule } from '../customization-data/customization-data.module';
import { UploadDialogComponent } from './components/upload/upload-dialog/upload-dialog.component';
import { UploadGuideComponent } from './components/upload/upload-guide/upload-guide.component';
import { UploadSourceComponent } from './components/upload/upload-source/upload-source.component';
import { IndexingPresetsComponent } from './components/shared/settings/indexing-presets/indexing-presets.component';
import { IndexingSettingsBrandCategoriesComponent } from './components/shared/settings/indexing-settings-brand-categories';
import { IndexingSettingsPeopleModelsComponent } from './components/shared/settings/people-models/indexing-settings-people-models.component';
// eslint-disable-next-line max-len
import { IndexingSettingsLogoGroupsComponent } from './components/shared/settings/indexing-settings-logo-groups/indexing-settings-logo-groups.component';
import { FileInformationComponent } from './components/shared/settings/file-information/file-information.component';
import { UploadEffects } from './core/effects/upload.effects';
import { reducers } from './core/reducers';
import { IndexingStoreService } from './services/indexing-store.service';
import { IndexingFormComponent } from './components/shared/indexing-form/indexing-form.component';
import { IndexingSummaryDialogComponent } from './components/shared/indexing-summary-dialog/indexing-summary-dialog.component';
import { IndexingSettingsGeneralComponent } from './components/shared/settings/indexing-settings-general/indexing-settings-general.component';
import { IndexingLanguageComponent } from './components/shared/settings/indexing-language/indexing-language.component';
import { FileNamesComponent } from './components/shared/settings/file-names/file-names.component';
import { FileNameInputComponent } from './components/shared/file-name-input/file-name-input.component';
import { ReIndexDialogComponent } from './components/re-index/re-index-dialog/re-index-dialog.component';
import { ConfirmExitIndexingComponent } from './components/shared/confirm-exit-indexing/confirm-exit-indexing.component';
import { UploadConsentComponent } from './components/shared/upload-consent/upload-consent.component';
import { UploadSummaryPageComponent } from './components/upload/summary-page/upload-summary-page.component';
import { IndexingFaceGateStripComponent } from './components/shared/indexing-face-gate-strip/indexing-face-gate-strip.component';
import { EdgeUploadEffects } from './core/effects/edge-upload.effects';
import { PresetHelperService } from './services/preset-helper.service';
import { IndexingSummaryComponent } from './components/shared/indexing-summary/indexing-summary.component';
import { ReIndexSummaryPageComponent } from './components/re-index/re-index-summary-page/re-index-summary-page.component';
import { ExcludeAIsHelperService } from './services/exclude-ais-helper.service';
import { IndexingReviewPageComponent } from './components/shared/indexing-review-page/indexing-review-page.component';

@NgModule({
  declarations: [
    UploadDialogComponent,
    UploadGuideComponent,
    UploadSourceComponent,
    IndexingPresetsComponent,
    IndexingFormComponent,
    IndexingSummaryDialogComponent,
    IndexingSettingsPeopleModelsComponent,
    FileInformationComponent,
    FileNamesComponent,
    IndexingSettingsGeneralComponent,
    IndexingLanguageComponent,
    FileNameInputComponent,
    IndexingSettingsBrandCategoriesComponent,
    ReIndexDialogComponent,
    UploadConsentComponent,
    ConfirmExitIndexingComponent,
    UploadSummaryPageComponent,
    IndexingSummaryComponent,
    ReIndexSummaryPageComponent,
    IndexingFaceGateStripComponent,
    IndexingSettingsLogoGroupsComponent,
    IndexingReviewPageComponent
  ],
  providers: [IndexingStoreService, FileHelperService, PresetHelperService, ExcludeAIsHelperService],
  imports: [
    VIUIModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CustomizationDataModule,
    StoreModule.forFeature('indexing', reducers),
    EffectsModule.forFeature([UploadEffects, EdgeUploadEffects, IndexingFormEffects, ReIndexEffects])
  ]
})
export class IndexingModule {}
