import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';
import { getUserState } from './user.selectors';

export const getDirectoriesState = createSelector(selectCoreState, state => state.directories);

export const selectDirectoriesLoaded = createSelector(getDirectoriesState, state => state.loaded);

export const selectDirectoriesError = createSelector(getDirectoriesState, state => state.error);

export const selectDirectories = createSelector(getDirectoriesState, state => state.entities);

export const currentTenantId = createSelector(getUserState, state => state.user?.tenantId);

export const selectedDirectory = createSelector(selectDirectories, currentTenantId, (directories, currentTenantId) => {
  const tenant = directories[currentTenantId];
  return {
    id: tenant?.id,
    tenantId: tenant?.tenantId,
    countryCode: tenant?.countryCode,
    displayName: tenant?.displayName,
    domains: tenant?.domains,
    tenantCategory: tenant?.tenantCategory,
    defaultDomain: tenant?.defaultDomain,
    tenantType: tenant?.tenantType
  };
});

export const selectUIDirectories = createSelector(selectDirectories, currentTenantId, (directories, currentTenantId) => {
  const allDirectories = Object.values(directories).map(directory => {
    return {
      id: directory.id,
      tenantId: directory.tenantId,
      countryCode: directory.countryCode,
      displayName: directory.displayName,
      domains: directory.domains,
      tenantCategory: directory.tenantCategory,
      defaultDomain: directory.defaultDomain,
      tenantType: directory.tenantType
    };
  });
  const selectedDirectory = allDirectories.filter(d => d.tenantId === currentTenantId);
  const otherDirectories = allDirectories.filter(d => d.tenantId !== currentTenantId);

  return [...selectedDirectory, ...otherDirectories];
});
