import { INotificationsResponse } from '@common/modules/core/services/toast/interfaces';

import { IHttpWrapper, INotificationsRequestParams } from '../interfaces';
import { getRequestSettings } from '../utils/request.function';

export class NotificationsApi {
  constructor(private ApiWrapper: IHttpWrapper) {}

  // [HttpGet]
  // [Route("{apiAuthBase}{apiAuthRoute}/notifications")]
  public getNotifications(reqParams: INotificationsRequestParams, reqSettings?: object) {
    const url = `${this.ApiWrapper.GetAuthApiBase()}/notifications`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<INotificationsResponse>(url, requestSettings);
  }
}
