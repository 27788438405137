import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';

import { ISubscriptions } from '@common/modules/insights/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { resources } from './resources';

@Component({
  selector: 'app-user-already-exists',
  templateUrl: './user-already-exists.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-already-exists.component.scss']
})
export class UserAlreadyExistsComponent implements OnInit, OnDestroy {
  public APIErrors = APIErrors;
  public resources = resources;
  public redirectUrl = '';
  public email = '';

  private subscriptions: ISubscriptions = {};
  private traceId = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateHelperService,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirectUrl'];
      this.traceId = params['traceId'];
      this.email = params['email'];

      this.setTranslationsSubscription();
    });
  }

  public ngOnDestroy() {
    // Unsubscribe all subscriptions.
    this.utilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  private setTranslationsSubscription() {
    this.translateService
      .translateResources(this.resources, { traceId: this.traceId, email: this.email })
      .pipe(take(1))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }
}
