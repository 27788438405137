export enum KeyCode {
  Tab = '9',
  Enter = '13',
  ShiftLeft = '16',
  ESCAPE = '27',
  ArrowUp = '38',
  ArrowDown = '40'
}

export function getKeyCode(event: KeyboardEvent): string {
  if (!event) {
    return '';
  }

  const keyName = event.code || event.key;
  if (keyName !== undefined) {
    if (KeyCode.hasOwnProperty(keyName)) {
      return KeyCode[keyName];
    } else {
      throw new Error(`Key ${keyName} not exist at KeyCode table. Please add it`);
    }
  }
  // eslint-disable-next-line deprecation/deprecation
  if (event.keyCode) {
    // eslint-disable-next-line deprecation/deprecation
    return event.keyCode.toString();
  }

  return '';
}
