import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KeyframeComponent } from '../insights/components/keyframe/keyframe.component';
import { InsightsContentListComponent } from '../insights/components/insights-content-list/insights-content-list.component';
import { CapsuleInputComponent } from '../insights/components/capsule-input/capsule-input.component';
import { InsightsCommonService } from './insights-common.service';
import { InsightsCommonDataService } from './insights-common-data.service';
import { InsightsCommonUtilsService } from './insights-common-utils.service';
import { AccessibilityModule } from '../accessibility/accessibility.module';
import { HighlightPipe } from '../timeline/pipes/highlight.pipe';
import { BoldPipe } from '../timeline/pipes/bold.pipe';
import { EmotionsMapComponent } from '../insights/components/emotions-map/emotions-map.component';
import { ToastService } from '../core/services/toast/toast.service';
import { SharedModule } from '../shared/shared.module';
import { CommunicationService } from './communication.service';

@NgModule({
  imports: [CommonModule, AccessibilityModule, SharedModule],
  declarations: [EmotionsMapComponent, KeyframeComponent, InsightsContentListComponent, CapsuleInputComponent, HighlightPipe, BoldPipe],
  providers: [InsightsCommonService, InsightsCommonDataService, InsightsCommonUtilsService, ToastService, CommunicationService],
  exports: [EmotionsMapComponent, KeyframeComponent, InsightsContentListComponent, CapsuleInputComponent, HighlightPipe, BoldPipe]
})
export class InsightsCommonModule {}
