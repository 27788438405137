import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

import { ProgressBarMode, ProgressStyle } from './interfaces';

@Component({
  selector: 'vi-ui-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation none for avoiding using ng-deep to style mat-progress-bar in VI style
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent {
  @Input() mode = ProgressBarMode.DETERMINATE;
  @Input() progressStyle = ProgressStyle.DEFAULT;
  @Input() value = 0;
  @Input() progressAriaLabel: string;
}
