import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { guid } from '../../utils/string';
import { ConfigService } from '../../core/services/config/config.service';
import { SessionHandlerService } from '../../shared/services/session-handler.service';
import { amILoggedInSuffix } from '../utils/api-utils';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private apimBase: string;
  private authorizationApiLocation: string;

  constructor(public configService: ConfigService, private sessionHandlerService: SessionHandlerService) {
    this.apimBase = this.configService.Config?.api?.apimBase;
    this.authorizationApiLocation = this.configService.Config?.api?.authorizationApiLocation;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.apimBase) || request.url.includes(this.authorizationApiLocation) || request.url.endsWith(amILoggedInSuffix)) {
      request = request.clone({
        setHeaders: {
          'x-ms-client-request-id': guid(),
          'x-ms-client-session-id': this.sessionHandlerService.sessionId
        }
      });
    }
    return next.handle(request);
  }
}
