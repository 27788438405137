import { Directive, Output, EventEmitter, Input, HostListener } from '@angular/core';

import { isMainButtonPressed } from '../utils/event';

@Directive({
  selector: '[appAccessibleClick]'
})
export class AccessibleClickDirective {
  @Input() public appClickDisabled = false;
  @Input() public appSpaceClickEnabled = false;
  @Output() public appAccessibleClick = new EventEmitter();

  @HostListener('mouseup', ['$event']) public onMouseUp(event: MouseEvent) {
    // Main button it's usually the left button or the un-initialized state
    if (!this.appClickDisabled && isMainButtonPressed(event)) {
      this.appAccessibleClick.emit(event);
    }
  }

  @HostListener('keyup.enter', ['$event']) public onEnterKeyUp(event: KeyboardEvent) {
    if (!this.appClickDisabled) {
      this.appAccessibleClick.emit(event);
    }
  }

  @HostListener('keyup.Space', ['$event']) public onSpaceKeyUp(event: KeyboardEvent) {
    if (this.appSpaceClickEnabled && !this.appClickDisabled) {
      this.appAccessibleClick.emit(event);
    }
  }
}
