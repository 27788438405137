export const resources = {
  ReindexModalHeader: '',
  Reindex: '',
  Cancel: '',
  Processing: '',
  ReIndexFileNotFoundErrorMsg: '',
  ReIndexGeneralLoadingErrorMsg: '',
  ReIndexVideoUrlUnreachableErrorMsg: '',
  ReIndexFileWasRemovedErrorMsg: '',
  ReIndexAmsUnreachableErrorMsg: '',
  ReIndexSourceVideoDeletedErrorMsg: '',
  ReIndexVideoIndexingInProgressErrorMsg: '',
  ReIndexFileInReviewErrorMsg: '',
  ReIndexFileWasReportedErrorMsg: '',
  ReIndexProjectRenderInProgressErrorMsg: '',
  ReIndexAmsAadAppNotInTenantErrorMsg: '',
  ReIndexGeneralErrorMessage: '',
  ReIndexStorageAccessDeniedErrorMsg: '',
  StorageAccessDeniedErrorMsgLinkText: '',
  ReIndexStorageUnreachableErrorMsg: '',
  ReIndexFileManagedIdentityMissingErrorMsg: '',
  Failed: '',
  Close: '',
  ReviewAndReIndex: '',
  IndexingBackToSettings: ''
};
