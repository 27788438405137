import { Injectable } from '@angular/core';

import { getCookie } from '@common/modules/utils/cookie';
import { ApiService } from '@common/modules/api/services/api.service';
import { AccountPermission } from '@common/modules/shared/interfaces';

import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { envTypes } from '../../../api/interfaces';
import { GuidPattern } from '../../../utils/regexes';
import { replaceJWTToken } from '../../../utils/string';

@Injectable({
  providedIn: 'root'
})
export class CommonTrackingDataService {
  public accountPermission: AccountPermission = null;
  private _accountType = '';
  private _isAmslessAccount: boolean;
  private _isAccountFaceGated: boolean;
  private readonly UNKNOWN = 'Unknown';
  private selectedAccountId = '';
  private selectedEdgeExtensionId = '';

  constructor(private localStorageService: LocalStorageService, private apiService: ApiService) {}

  public set accountId(id: string) {
    this.selectedAccountId = id;
  }

  public set edgeExtensionId(id: string) {
    this.selectedEdgeExtensionId = id;
  }

  public get edgeExtensionId(): string {
    return this.selectedEdgeExtensionId;
  }

  public get accountId(): string {
    try {
      if (this.selectedAccountId) {
        return this.selectedAccountId;
      }

      // Retrieve account id from url
      // Affect on media page and embed
      const path = window.location?.pathname;
      const regex = new RegExp(`${GuidPattern}`);
      const match = path?.match(regex);
      return match?.length ? match[0] : this.UNKNOWN;
    } catch (e) {
      // No access - can't retrieve account id
      return this.UNKNOWN;
    }
  }

  public get embedMode() {
    try {
      const path = window.location.pathname;
      const regex = new RegExp(/embed/i);
      const isEmbedRoute = regex.test(path);
      return isEmbedRoute && window.self !== window.top && !window.top.location.host.includes('.videoindexer.ai');
    } catch (e) {
      // No access to parent - meaning is embedded.
      return true;
    }
  }

  public get referrer(): string {
    try {
      const url = document.referrer || window.parent.location.href;
      return new URL(url).hostname;
    } catch (e) {
      // No access to parent nor to referrer.
      return this.UNKNOWN;
    }
  }

  public get location() {
    try {
      // First, try to get location from URL with location param
      // Affects on embed & media page
      const urlParams = new URLSearchParams(window.location.search);
      const location = urlParams.get('location');

      if (location) {
        return replaceJWTToken(location);
      }

      // Dev environment
      return replaceJWTToken(this.apiService.getApiLocation() || envTypes.DEV);
    } catch {
      return this.UNKNOWN;
    }
  }

  public get browserLang() {
    return window.navigator.language || window.navigator['userLanguage'];
  }

  public get locale(): string {
    try {
      // Try to get locale from local storage
      let locale = this.localStorageService.getItem('vi.locale');

      if (locale) {
        return replaceJWTToken(locale);
      }

      // Try to get from url
      const urlParams = new URLSearchParams(window.location.search);
      locale = urlParams.get('locale');

      if (locale) {
        // If there is a JWT token inside of locale, remove it
        return replaceJWTToken(locale);
      }

      return this.UNKNOWN;
    } catch (error) {
      return this.UNKNOWN;
    }
  }

  public get accountType(): string {
    // Return current account type
    // Affects while using the app after getAccount called
    if (this._accountType) {
      return this._accountType;
    }

    // Try to get from location
    if (this.location && this.location !== this.UNKNOWN) {
      return this.location.toLowerCase() === envTypes.TRIAL.toLowerCase() || this.location.toLowerCase() === envTypes.DEV.toLowerCase()
        ? this.location
        : envTypes.PAID.toLowerCase();
    }

    return this.UNKNOWN;
  }

  public set accountType(value: string) {
    this._accountType = value;
  }

  public get isAccountFaceGated(): boolean {
    return this._isAccountFaceGated;
  }

  public set isAccountFaceGated(value: boolean) {
    this._isAccountFaceGated = value;
  }

  public get isAmslessAccount(): boolean {
    return this._isAmslessAccount;
  }

  public set isAmslessAccount(value: boolean) {
    this._isAmslessAccount = value;
  }

  public get aiUserId(): string {
    const defaultUserId = 'AnonymousUser';

    // This cookie is set by 1ds script on our domain
    const aiCookie = getCookie('MSFPC');

    if (!aiCookie) {
      return defaultUserId;
    }

    const result = aiCookie.match(/GUID=(.*?)(&)/);
    return result?.length ? result[1] : defaultUserId;
  }

  // Effective type is an experimental feature, therefore it doesn't exist in the navigator types yet
  public get effectiveType(): string {
    return window.navigator['connection']?.['effectiveType'] || '';
  }
}
