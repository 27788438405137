import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Customization_Error_Types } from '../../customization/interfaces';
import { CustomizationUtilsService } from './customization-utils.service';

@Injectable()
export class BrandsService {
  constructor(private customizationUtilsService: CustomizationUtilsService) {}

  public handleBrandsError(error: HttpErrorResponse, brand: Microsoft.VideoIndexer.Contracts.BrandContractV2) {
    switch (error.error.ErrorType) {
      case Customization_Error_Types.ALREADY_EXISTS:
        this.customizationUtilsService.displayErrorToast(error, { BrandAlreadyExistsError: '' }, { name: brand?.name });
        break;
      case Customization_Error_Types.WIKI_ALREADY_EXISTS:
        this.customizationUtilsService.displayErrorToast(error, { BrandWikiAlreadyExistsError: '' }, { wikiUrl: brand?.referenceUrl });
        break;
      case Customization_Error_Types.INVALID_INPUT:
        this.customizationUtilsService.displayErrorToast(error, { BrandInvalidInputError: '' }, {});
        break;
      default:
        this.customizationUtilsService.displayErrorToast(error, { ErrorTypes_GENERAL: '' }, {});
    }
  }
}
