<div class="container">
  <div *ngIf="shouldShowIcon"
    class="icon-wrapper">
    <svg class="icon"
      [attr.height]="iconHeight"
      [attr.width]="iconWidth">
      <path [attr.d]="svgIcon" />
    </svg>
  </div>
  <div class="title">{{title}}</div>
  <div *ngIf="content"
    class="content"
    [innerHTML]="content"></div>
  <vi-ui-link *ngIf="linkText"
    [size]="'14-20'"
    [role]="'link'"
    [href]="linkPath"
    [text]="linkText"></vi-ui-link>
</div>
