import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../../../environments/environment';
import { setRuntimeConfigurations } from './runtime';

if (environment.production) {
  enableProdMode();
}

setRuntimeConfigurations();

const now = Date.now();
// Load environment configurations and bootstrap the application.
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.info('Angular bootstrapped in ' + (Date.now() - now) + 'ms');
    }
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
