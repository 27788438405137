import { createSelector } from '@ngrx/store';

import { isUserNotAuthenticatedOrUnauthorizedError } from '@common/modules/api/utils/request.function';
import { isEmptyGuid } from '@common/modules/utils/string';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { GalleryTab, GalleryView } from '../../interfaces';
import { getLibraryState } from './library.selectors';
import { getProjectsState } from './projects.selectors';
import { getSamplesState } from './samples.selectors';
import { getSearchState } from './search.selectors';
import { selectedGalleryTab, selectedGalleryView } from './gallery-view.selectors';
import { getUserDetails } from '../../../core/selectors/user.selectors';
import { selectErrorType as selectAccountErrorType } from '../../../core/selectors/accounts.selectors';
import { sortByUploaded } from '../../utils';

export const selectCurrentTabState = createSelector(
  selectedGalleryTab,
  getLibraryState,
  getProjectsState,
  getSamplesState,
  (tab, libraryState, projectsState, samplesState) => {
    switch (tab) {
      case GalleryTab.Library:
        return libraryState;
      case GalleryTab.Project:
        return projectsState;
      case GalleryTab.Sample:
        return samplesState;
    }
  }
);

export const selectCurrentTabNextPage = createSelector(selectCurrentTabState, currentTabState => currentTabState?.nextPage);

export const isNextPageDone = createSelector(selectCurrentTabNextPage, nextPage => nextPage?.done);

export const isNextPageLoading = createSelector(selectCurrentTabState, currentTabState => currentTabState?.loadingNext);

export const selectAllEntities = createSelector(selectCurrentTabState, state => state?.entities || []);

export const selectCurrentGalleryItemsState = createSelector(
  selectCurrentTabState,
  getSearchState,
  selectedGalleryView,
  (tabVideosState, searchState, view) => {
    if (view === GalleryView.Search) {
      return searchState?.entities || [];
    }
    return tabVideosState?.entities || [];
  }
);

export const isLoading = createSelector(selectCurrentTabState, state => !state?.loaded);

export const isError = createSelector(selectCurrentTabState, state => state?.error);

export const isUnauthorizedError = createSelector(selectCurrentTabState, selectAccountErrorType, (state, accountErrorType) =>
  isUserNotAuthenticatedOrUnauthorizedError(state?.errorType, accountErrorType)
);

export const isNetworkAccessNotAllowedError = createSelector(
  selectCurrentTabState,
  state => state?.errorType === APIErrors.NETWORK_ACCESS_NOT_ALLOWED
);

export const selectAllVideos = createSelector(selectAllEntities, entities => sortByUploaded(Object.values(entities)));

export const selectVideo = id => createSelector(selectCurrentGalleryItemsState, entities => entities[id]);

export const selectCurrentProcessingVideo = (videoId: string, isBase: boolean, accountId: string) =>
  createSelector(getLibraryState, getProjectsState, getSamplesState, (libraryState, projectsState, sampleState) => {
    if (!isBase) {
      return projectsState.entities[videoId];
    }

    if (isEmptyGuid(accountId)) {
      return sampleState.entities[videoId];
    }

    return libraryState.entities[videoId];
  });

export const selectProcessingVideos = createSelector(selectAllEntities, entities =>
  Object.values(entities).filter(e => e.state === 'Processing' || e.state === 'Uploaded')
);

export const isSelectedTabHidden = createSelector(selectedGalleryTab, getUserDetails, (tab, user) =>
  tab === GalleryTab.Sample ? !user.showSamplesTab : !user.showGalleryByDefault
);
