import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { AccountPermission } from '../../interfaces';
import { PermissionService } from '../../services/permission.service';
import { IViewControlChangedEvent, ViewActionType } from './interfaces';

@Component({
  selector: 'vi-role-based-view-control-wrapper',
  template: `
    <ng-container *ngIf="shouldRender">
      <ng-content></ng-content>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleBasedViewControlWrapperComponent implements OnChanges {
  /**
   * The role that is required to view the element.
   */
  @Input() public requiredPermission: AccountPermission = AccountPermission.CONTRIBUTOR;

  /**
   * The current permission
   * defaults to Contributor so current users won't get effected
   */
  @Input() public currentPermission: AccountPermission = AccountPermission.CONTRIBUTOR;

  /**
   * The action to take when the user is not authorized to view the element.
   */
  @Input() public viewAction: ViewActionType = ViewActionType.SHOW;

  /**
   * whether or not to match with the exact role of the user
   * otherwise it will match based on the access level
   */
  @Input() public exact = false;

  /**
   * Handler for role changed event.
   */
  @Output() public viewControlChanged = new EventEmitter<IViewControlChangedEvent>();

  public shouldRender = true;
  private fallbackPermission = AccountPermission.CONTRIBUTOR;

  constructor(private permissionService: PermissionService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPermission) {
      this.handleRoleChanged(changes.currentPermission.currentValue);
    }
  }

  private isViewable(permission: AccountPermission) {
    if (!this.permissionService.isValidPermission(permission)) {
      permission = this.fallbackPermission;
    }

    if (this.exact) {
      return permission === this.requiredPermission;
    }

    return this.permissionService.ensurePermission(permission, this.requiredPermission);
  }

  private handleRoleChanged = (role: AccountPermission) => {
    const isViewable = this.isViewable(role);
    const { viewAction = ViewActionType.SHOW } = this;

    if (viewAction === ViewActionType.SHOW) {
      this.shouldRender = isViewable;
    }

    this.viewControlChanged.emit({ viewAction, result: isViewable });
  };
}
