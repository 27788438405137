import { Params } from '@angular/router';

import { ConfigService } from '@common/modules/core/services/config/config.service';

import { environment, devEnvironmentMapping } from './../../../../../environments/environment';

export function getSelectedAccountId(
  accounts: Microsoft.VideoIndexer.Contracts.AccountContractSlim[],
  params: Params,
  selectAccountId: string,
  cachedAccountId: string
): string {
  if (selectAccountId) {
    return selectAccountId;
  }

  if (!accounts?.length) {
    return null;
  }

  let accountId;
  if (cachedAccountId && accounts.filter(a => a.id === cachedAccountId).length > 0) {
    accountId = cachedAccountId;
  } else {
    accountId = accounts[0]?.id;
  }

  if (params?.accountId) {
    const isAccountExist = accounts.find(account => account.id === params?.accountId);
    accountId = isAccountExist ? params?.accountId : accountId;
  }

  return accountId ? accountId : accounts[0]?.id;
}

export function getARMLocation(account: Microsoft.VideoIndexer.Contracts.AccountContractSlim, configService: ConfigService) {
  // For local environment we need to map the Arm Account location to the environment location
  if (environment.localhost) {
    const mappedEnv = devEnvironmentMapping?.find(env => configService.Config?.api?.internalApiLocation.includes(`-${env?.name}-`));
    if (mappedEnv) {
      return mappedEnv.location;
    }
  }

  // For dev environment we need to map the Arm Account location to the environment location
  if (configService.Config.mapArmLocation) {
    const mappedEnv = devEnvironmentMapping?.find(env => env.url === window.location.hostname);
    if (mappedEnv) {
      return mappedEnv.location;
    }
  }

  return account?.location;
}

export function isAccountDurationQuotaExceeded(quota: Microsoft.VideoIndexer.Contracts.AccountQuotaUsage) {
  return quota?.everUploadDurationInSeconds >= quota?.everUploadDurationLimitInSeconds;
}

export function isAccountDailyCountQuotaExceeded(quota: Microsoft.VideoIndexer.Contracts.AccountQuotaUsage) {
  return quota?.dailyUploadCount >= quota?.dailyUploadCountLimit;
}

export function isAccountDailyDurationQuotaExceeded(quota: Microsoft.VideoIndexer.Contracts.AccountQuotaUsage) {
  return quota?.dailyUploadDurationInSeconds >= quota?.dailyUploadDurationLimitInSeconds;
}
