import { Injectable, ElementRef, Inject, Renderer2 } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';

import { take } from 'rxjs/operators';

import { TranslateHelperService } from '../translation/services/translate-helper.service';

@Injectable()
export class AccessibilityUtilsService {
  @Inject(DOCUMENT) private document;

  constructor(private translate: TranslateHelperService, private liveAnnouncer: LiveAnnouncer) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getParents(node: any): any[] {
    let aNode = node.parentNode;

    const parents = [];
    while (aNode) {
      parents.push(aNode);
      aNode = aNode.parentNode;
    }

    return parents;
  }

  public createEnteringDiv(renderer: Renderer2, el: ElementRef, text: string) {
    const enteringContainer = renderer.createElement('div');
    renderer.addClass(enteringContainer, 'entering-container');
    // Create entering div
    const enteringDiv = document.getElementById('enteringDiv');
    if (!enteringDiv) {
      const enteringDivTemplate = `<div id='enteringDiv'><div aria-hidden='true' disabled tabindex='-1'>${text}</div></div>`;
      // eslint-disable-next-line @microsoft/sdl/no-inner-html
      enteringContainer.innerHTML += enteringDivTemplate;
    } else {
      renderer.appendChild(enteringContainer, enteringDiv);
    }
    return enteringContainer;
  }

  public announceDynamicMessage(resourceProperty: string, variables: object) {
    const resource = {};
    resource[resourceProperty] = '';
    this.translate
      .translateResources(resource, variables)
      .pipe(take(1))
      .subscribe(() => {
        this.liveAnnouncer.announce(resource[resourceProperty]);
      });
  }
}
