import { IAction } from '@common/modules/shared/interfaces';
import { UIActionIcon, UIActionType } from '@common/modules/insights/interfaces';

export const cancelAction: IAction = {
  title: 'Cancel',
  key: 'Cancel',
  selected: false,
  type: UIActionType.CANCEL,
  value: 'Cancel',
  selectable: true,
  hasDrop: false,
  isDisabled: false,
  id: 'cancel'
};

export const loadAction: IAction = {
  icon: '',
  title: '',
  key: 'GridLoadMoreButton',
  selected: false,
  type: UIActionType.SCROLL,
  value: '',
  selectable: true,
  hasDrop: true,
  id: 'Load'
};

export const moveToModelAction: IAction = {
  title: 'Move to model',
  key: 'UnknownPersonDialogMoveToModel',
  selected: false,
  type: UIActionType.UPDATE,
  value: 'Update',
  selectable: true,
  hasDrop: false,
  isDisabled: false,
  id: 'moveToModel'
};

export const barActions: IAction[] = [
  {
    icon: UIActionIcon.EDIT,
    title: 'Name person',
    key: 'UnknownPersonDialogNamePerson',
    selected: false,
    type: UIActionType.TOGGLE,
    value: 'Name person',
    selectable: true,
    hasDrop: false,
    id: 'namePerson'
  },
  {
    icon: UIActionIcon.TRASH,
    title: 'Delete',
    key: 'Delete',
    selected: false,
    type: UIActionType.DELETE_PERSON,
    value: 'Delete',
    selectable: true,
    hasDrop: false,
    id: 'deletePerson'
  }
  // @TODO: Enable when BE add support for PATCH API
  // {
  //   icon: UIActionIcon.ADD_BIG,
  //   title: 'Add Metadata',
  //   key: 'AddMetadata',
  //   selected: false,
  //   type: UIActionType.EDIT_METADATA,
  //   value: 'Add',
  //   selectable: true,
  //   hasDrop: false,
  //   id: 'addMetadata'
  // }
];

export const facesActions: IAction[] = [
  {
    icon: UIActionIcon.UPLOAD,
    title: 'Go to video',
    key: 'FacesCustomizationGoToVideoAction',
    selected: false,
    type: UIActionType.NAVIGATE,
    value: 'Navigate',
    selectable: true,
    hasDrop: false,
    id: 'goToVideo'
  },
  {
    icon: UIActionIcon.OPEN,
    title: 'Open image',
    key: 'FacesCustomizationOpenFaceAction',
    selected: false,
    type: UIActionType.OPEN,
    value: 'Open',
    selectable: true,
    hasDrop: false,
    id: 'OPEN_FACE'
  },
  {
    icon: UIActionIcon.TRASH,
    title: 'Delete',
    key: 'Delete',
    selected: false,
    type: UIActionType.DELETE_FACE,
    value: 'Delete',
    selectable: true,
    hasDrop: false,
    id: 'deleteFace'
  }
];
