import {
  INotification,
  INotificationLink,
  NotificationFilterType,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { IStripData, MessageType } from '@common/modules/shared/components/strip/interfaces';
import { TimeInterval } from '@common/modules/utils/time';

export const NOTIFICATION_IN_PROGRESS_INTERVAL = TimeInterval.SECOND * 30;
export const NOTIFICATION_INTERVAL = TimeInterval.MINUTE * 30;

export const MIGRATION_LINK_ICON = '<i class="i-vi-open"></i>';

export const generateBannerLink = (path: string, text: string, hasIcon: boolean = false): string => {
  const icon = hasIcon ? MIGRATION_LINK_ICON : '';
  return `<a target="_blank" role="link" tabindex="0" class="link" href="${path}" title="${text}">${text} ${icon}</a>`;
};

export const getMigrationNotification = (
  id: string,
  title: string,
  text: string,
  level: NotificationLevel,
  icon: NotificationIcon,
  toasted: boolean = false,
  link?: INotificationLink,
  type: NotificationType = NotificationType.Message
): INotification => {
  return {
    id,
    title,
    text,
    link,
    level,
    icon,
    toasted,
    startDate: new Date(),
    endDate: new Date(),
    type,
    messageType: NotificationMessageType.Custom,
    filterType: NotificationFilterType.Default
  };
};

export const getMigrationBanner = (
  text: string,
  iconClass: string,
  messageType: MessageType,
  trackingName: string,
  loadingProgress?: number
): IStripData => {
  return {
    text,
    show: true,
    hasCloseButton: true,
    iconClass,
    messageType,
    trackingName,
    loadingProgress
  };
};

// Notifications
export const getMigrationFailedNotification = (title: string, text: string, toasted: boolean, link: INotificationLink): INotification => {
  return getMigrationNotification('migration-failed-notification', title, text, NotificationLevel.Info, NotificationIcon.Error, toasted, link);
};

export const getMigrationPartlySuccessfulNotification = (title: string, text: string, toasted: boolean, link: INotificationLink): INotification => {
  return getMigrationNotification(
    'migration-partly-successful-notification',
    title,
    text,
    NotificationLevel.Info,
    NotificationIcon.Info,
    toasted,
    link
  );
};

export const getMigrationInProgressNotification = (title: string, text: string, toasted: boolean): INotification => {
  return getMigrationNotification('migration-in-progress-notification', title, text, NotificationLevel.Info, NotificationIcon.Info, toasted);
};

export const getMigrationErrorNotification = (title: string, text: string, toasted: boolean, link: INotificationLink): INotification => {
  return getMigrationNotification('migration-error-notification', title, text, NotificationLevel.Info, NotificationIcon.Error, toasted, link);
};

export const getMigrationCompleteNotification = (title: string, text: string, toasted: boolean): INotification => {
  return getMigrationNotification('migration-completed-notification', title, text, NotificationLevel.Info, NotificationIcon.Success, toasted);
};

// Banners
export const getMigrationFailedBanner = (text: string): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Error, MessageType.ERROR, 'ams_migration_failed');
};

export const getMigrationPartlySuccessfulBanner = (text: string): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Info, MessageType.INFO, 'ams_migration_partly_successful');
};

export const getMigrationPendingBanner = (text: string): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Info, MessageType.INFO, 'ams_migration_pending');
};

export const getMigrationInProgressBanner = (text: string, loadingProgress: number): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Info, MessageType.INFO, 'ams_migration_in_progress', loadingProgress);
};

export const getMigrationErrorBanner = (text: string): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Error, MessageType.ERROR, 'ams_migration_error');
};

export const getMigrationCompleteBanner = (text: string): IStripData => {
  return getMigrationBanner(text, NotificationIcon.Info, MessageType.INFO, 'ams_migration_completed');
};
export function getMigrationFallbackBanner(text: string) {
  return getMigrationBanner(text, NotificationIcon.Warning, MessageType.WARNING, 'ams_migration_fallback');
}
