import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';
import { ITabListTab } from './interfaces';

@Component({
  selector: 'app-vi-tablist',
  templateUrl: './tablist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tablist.component.scss']
})
export class TablistComponent<T> implements OnInit {
  @Input() public tabs: ITabListTab<T>[] = [];
  @Input() public isDisabled: boolean;
  @Output() public select = new EventEmitter<ITabListTab<T>>();

  public resources = resources;
  constructor(private translate: TranslateHelperService) {}

  public ngOnInit() {
    this.translate.translateResourcesInstant(resources);
  }

  public getId(tab: ITabListTab<T>) {
    return `tab${tab.id || tab.value}`;
  }

  public selectTab(tab: ITabListTab<T>) {
    this.select.emit(tab);
  }
}
