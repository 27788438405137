import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';

import { Subject, takeUntil, Observable, withLatestFrom, combineLatestWith } from 'rxjs';

import { TagType } from '@vi-ui/core/lib/components/tag/tag.component';
import { ITag } from '@vi-ui/core/libs/vi-ui/src/lib/components/tag-container/interfaces';

import { guid } from '@common/modules/utils/string';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { Direction, IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { AMS_RETIREMENT_LINK, UPLOAD_LIMITATION_LINK } from '@common/modules/shared/links';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { getDefaultFileName } from './../../../../utils/file';
import { FileHelperService } from './../../../../services/file-helper.service';
import { ErrorType, IFile } from '../../../../interfaces';
import { UploadMode, IndexingMode } from './../../../../interfaces';
import { IndexingStoreService } from './../../../../services/indexing-store.service';
import { CoreStoreService } from '../../../../../core/services/core-store.service';
import * as actions from './actions';
import { resources } from './resources';
import { getFileNameFromURL, isValidURL } from '../../../../utils/file';
import { generateSupportedMediaTypesPattern } from '../../../../utils/mediaFormats';

@Component({
  selector: 'vi-indexing-settings-general',
  templateUrl: './indexing-settings-general.component.html',
  styleUrls: ['./indexing-settings-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSettingsGeneralComponent implements OnInit, OnDestroy {
  @Input() public isMultiFileMode = false;
  @Input() public isDisabled = false;
  public showAdvancedMode$ = new Observable<boolean>();
  public uploadMode: UploadMode;

  @Output() public advancedSettingsClick = new EventEmitter<void>();
  @Output() public managedLanguageModelsClick = new EventEmitter<CustomizationPage>();
  @Output() public managedSpeechModelsClick = new EventEmitter<CustomizationPage>();
  @Output() public languageIdLinkClick = new EventEmitter<CustomizationPage>();
  @ViewChild('AddFilesButton', { read: ElementRef }) public addFilesButton: ElementRef;
  @ViewChild('AddURLButton', { read: ElementRef }) public addURLButton: ElementRef;
  @ViewChild('fileInput', { read: ElementRef }) public fileInput: ElementRef;

  // Constants
  public readonly ADD_URL_BUTTON_MIN_WIDTH = '67px';
  public readonly ADD_FILES_BUTTON_MIN_WIDTH = '120px';
  public readonly AMS_DEPRECATION_MESSAGE_TYPE = 'info';
  public readonly limitationsLink = UPLOAD_LIMITATION_LINK;
  public readonly amsDeprecationLink = AMS_RETIREMENT_LINK;
  public readonly filesContainerId: string = 'filesContainer_' + guid();
  public readonly indexingStreamingId: string = 'indexingStreaming_' + guid();
  public readonly indexingPrivacyId: string = 'indexingPrivacy_' + guid();
  public readonly indexingStreamingLabelId: string = 'indexingStreamingLabel_' + guid();
  public readonly indexingPrivacyLabelId: string = 'indexingPrivacyLabel_' + guid();

  // Indexing mode
  public indexingMode: IndexingMode;

  // Privacy mode
  public disablePrivacy: boolean = false;

  // Adaptive bitrate disclaimer
  public showAdaptiveBitrateDisclaimer: boolean = false;

  // URL
  public uploadURL: string;
  public isURLValid: boolean = false;
  public isUrlValidationLoading: boolean = false;
  public urlValidationErrorMessage: string = null;

  // Files
  public files: IFile[];
  public file: IFile; // Used in single file mode
  public fileTags: ITag[] = [];
  public filesType: string;
  public disableFileName: boolean = false;
  public maxFilesReached: boolean = false;
  public showAddFilesBtnTooltip: boolean = false;
  public showUploadFileError: boolean = false;
  public uploadFileErrorMessage: string;

  // Actions
  public addFilesAction: IAction = actions.addFilesAction;
  public privacyAction: IAction = actions.privacyAction;
  public privacyOptions: IAction[] = actions.privacyOptions;
  public streamAction: IAction = actions.streamAction;
  public streamOptions: IAction[] = actions.streamOptions;
  public selectedPrivacy: IAction;
  public selectedStreaming: IAction;
  public addURLAction: IAction = actions.addURLAction;

  // Interfaces
  public resources = resources;
  public ActionButtonType = ActionButtonType;
  public Direction = Direction;
  public UploadMode = UploadMode;
  public IndexingMode = IndexingMode;

  // Private
  private destroy$ = new Subject<void>();
  private readonly DEFAULT_FILENAME_PREFIX = 'Media file';
  private readonly LOADING_ICON = 'loading';
  private readonly OK_ICON = 'ok';
  private maxVideosLimit: number;

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private indexingStore: IndexingStoreService,
    private coreStoreService: CoreStoreService,
    private fileHelperService: FileHelperService,
    private trackService: TrackService
  ) {}

  public get showSingleFileName() {
    return this.indexingMode === IndexingMode.ReIndex || (this.isFileMode && !this.isMultiFileMode);
  }

  public get showAddFilesBtnSingleFileMode() {
    return this.isFileMode && !this.isMultiFileMode;
  }

  public get showAddFilesBtnMultiFileMode() {
    return this.isFileMode && this.isMultiFileMode;
  }

  public get showFilesContainer() {
    return this.isURLMode || this.isMultiFileMode;
  }

  public get isURLMode() {
    return this.indexingMode === IndexingMode.Upload && this.uploadMode === UploadMode.URL;
  }

  private get isFileMode() {
    return this.indexingMode === IndexingMode.Upload && this.uploadMode === UploadMode.File;
  }

  public get isFileNameDisabled() {
    return this.isDisabled || this.disableFileName;
  }

  public ngOnInit(): void {
    this.filesType = generateSupportedMediaTypesPattern();
    this.maxVideosLimit = this.fileHelperService.maxVideosLimit;
    this.initTranslations();
    this.showAdvancedMode$ = this.indexingStore.showAdvancedMode$.pipe(takeUntil(this.destroy$));
    this.indexingStore.uploadMode$.pipe(takeUntil(this.destroy$)).subscribe(uploadMode => {
      this.uploadMode = uploadMode;
    });

    //TODO: handle strip errors that might prevent uploading (AMS Unreachable, etc.)
    this.initUrlFileValidation();
    this.initIndexingMode();
    this.initFiles();
    this.initPrivacy();
    this.initStreaming();
    this.initInvalidFileValidation();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.uploadMode === UploadMode.URL) {
      this.indexingStore.clearUrlValidationError();
    }
  }

  public onSelectPrivacy(privacyAction: IAction) {
    this.indexingStore.updatePrivacy(privacyAction.value);
  }

  public onSelectStreaming(streamingAction: IAction) {
    this.indexingStore.updateStreamingPreset(streamingAction.value);
  }

  public onFileRemoved(fileId: string) {
    this.indexingStore.removeFile(fileId);
  }

  public onChangeFileName(file: IFile) {
    this.indexingStore.updateFile(file.id, { name: file.name });
    this.trackService.track('upload_dialog.filename.changed', {
      category: EventCategory.UPLOAD
    });
  }

  public onChangeURL(url: string) {
    this.uploadURL = url.trim();
    this.isURLValid = isValidURL(this.uploadURL);
    this.urlValidationErrorMessage = null;
  }

  public uploadChooseFiles(fileInput: HTMLInputElement) {
    fileInput.click();
  }

  public onFileBrowse($event) {
    const files: File[] = Array.from($event.target.files);
    if (files.length > 0) {
      const filesWithProps = files.map(file => this.fileHelperService.extractFileProperties(file));
      this.indexingStore.addFilesForUpload(filesWithProps);
      if (this.fileInput?.nativeElement?.value) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  public get urlIcon() {
    return this.isUrlValidationLoading ? { type: this.LOADING_ICON } : this.isURLValid ? { type: this.OK_ICON } : '';
  }
  public onAddURLClicked() {
    const file: IFile = {
      id: guid(),
      name: getFileNameFromURL(this.uploadURL) || getDefaultFileName(this.files, this.DEFAULT_FILENAME_PREFIX),
      url: this.uploadURL
    };
    this.urlValidationErrorMessage = null;
    this.indexingStore.updateUrlValidationLoading(true);
    this.indexingStore.addUrlFile(file);
  }

  public onManageLanguageClicked() {
    this.managedLanguageModelsClick.emit(CustomizationPage.Language);
  }

  public onSpeechModelsClicked() {
    this.managedSpeechModelsClick.emit(CustomizationPage.Speech);
  }

  public onAdvancedSettingsClicked(): void {
    this.advancedSettingsClick.emit();
  }

  public onLanguageIdLinkClicked(): void {
    this.languageIdLinkClick.emit(CustomizationPage.LanguageId);
  }

  public onAddFilesBtnHover(isHover: boolean) {
    this.showAddFilesBtnTooltip = this.maxFilesReached && isHover;
  }

  public get urlPlaceholder() {
    return this.files?.length ? resources?.UploadEnterAnotherUrlPlaceholder : resources.UploadEnterUrlPlaceholder;
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources, { limit: this.maxVideosLimit });

    for (const action in actions) {
      actions[action].title = resources[actions[action].key];
      actions[action].id = `${actions[action].id}_ ${guid()}`;
    }

    [this.privacyOptions, this.streamOptions].forEach(options => {
      options.forEach(opt => {
        opt.title = opt.key ? this.resources[opt.key] : '';
      });
    });
    this.cdr.detectChanges();
  }

  private initPrivacy() {
    this.indexingStore.privacy$.pipe(takeUntil(this.destroy$)).subscribe(privacy => {
      this.selectedPrivacy = this.privacyOptions.find(option => option.value === privacy);
      this.cdr.detectChanges();
    });
  }

  private initStreaming() {
    this.indexingStore.streamingPreset$.pipe(takeUntil(this.destroy$)).subscribe(streaming => {
      // If the selected streaming preset is not available, select the first available option
      this.selectedStreaming = this.streamOptions.find(option => option.value === streaming) || this.streamOptions[0];
      this.cdr.detectChanges();
    });
  }

  private initFiles() {
    if (this.indexingMode === IndexingMode.ReIndex) {
      this.initFileName();
      return;
    }
    this.indexingStore.allFiles$.pipe(takeUntil(this.destroy$)).subscribe(files => {
      this.files = files;
      if (this.files.length) {
        this.file = this.files[0];
      }
      this.maxFilesReached = this.files?.length >= this.maxVideosLimit;
      this.fileTags = this.files?.map(file => ({
        id: file.id,
        content: file.name,
        type: file.error?.errorType ? TagType.ERROR : TagType.REST,
        removeTagTitleAttribute: this.resources.IndexingRemoveFileButtonTooltip,
        removable: true
      }));
      this.cdr.detectChanges();
    });
  }

  private initFileName() {
    this.indexingStore.fileName$.pipe(takeUntil(this.destroy$)).subscribe(name => {
      this.file = { id: guid(), name };
    });
  }

  private initIndexingMode() {
    this.indexingStore.indexingMode$.pipe(takeUntil(this.destroy$)).subscribe(mode => {
      this.indexingMode = mode;
      this.disableFileName = mode === IndexingMode.ReIndex;
      this.disablePrivacy = mode === IndexingMode.ReIndex;
    });
  }

  private initUrlFileValidation() {
    this.urlValidationErrorMessage = null;
    this.indexingStore.isUrlValidationLoading$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.indexingStore.urlValidationError$))
      .subscribe(([isLoading, errorType]) => {
        this.isUrlValidationLoading = <boolean>isLoading;
        if (!isLoading) {
          if (!errorType) {
            this.uploadURL = '';
          } else {
            this.urlValidationErrorMessage = this.handleValidationErrorMessage(errorType);
          }
        }

        this.isURLValid = false;
        this.cdr.detectChanges();
      });
  }
  private handleValidationErrorMessage(errorType: APIErrors): string {
    let errorMessage = resources.UploadInvalidUrl;
    if (errorType === APIErrors.URL_UNREACHABLE) {
      errorMessage = resources.IndexingInvalidUrlValidationError;
    } else if (errorType === APIErrors.ALREADY_EXISTS) {
      errorMessage = resources.IndexingDuplicateFileValidationError;
    } else if (errorType === APIErrors.VIDEO_UPLOAD_LIMIT_EXCEEDED) {
      errorMessage = resources.UploadInvalidURLFileSizeMessage;
    }

    return errorMessage;
  }

  private initInvalidFileValidation() {
    this.indexingStore.showUploadFileError$
      .pipe(takeUntil(this.destroy$), combineLatestWith(this.indexingStore.isMultiFileMode$))
      .subscribe(([showUploadFileError]) => {
        this.showUploadFileError = showUploadFileError && !this.isMultiFileMode;
        this.uploadFileErrorMessage = showUploadFileError ? this.invalidFileErrorMessage : '';
        this.cdr.detectChanges();
      });
  }

  private get invalidFileErrorMessage() {
    switch (this.file?.error?.errorType) {
      case ErrorType.FileExtension:
        return this.resources.UploadInvalidFileExtensionMessage;
      case ErrorType.FileSize:
        return this.resources.UploadInvalidFileSizeMessage;
      default:
        return '';
    }
  }
}
