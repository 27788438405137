export enum UIHelpActionType {
  DOCUMENTATION,
  SUPPORT,
  GIVE_FEEDBACK,
  BLOG,
  API_REFERENCE,
  ABOUT_VI,
  PRICING,
  PRIVACY_STATEMENT,
  SERVICES_AGREEMENT,
  ACCESSIBILITY,
  SURVEY,
  FACE_RECOGNITION_FORM,
  INTERNAL_FACE_GATE_POPUP
}
