import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';

import { IUIEntityInsight } from '@common/modules/insights/interfaces';

import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { isMainButtonPressed } from '../../../utils/event';
import { UIImageShapeType } from './interfaces';

@Component({
  selector: 'vi-insights-images',
  templateUrl: './insights-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./insights-images.component.scss']
})
export class InsightsImagesComponent implements OnInit {
  // Inputs
  @Input() public entities: IUIEntityInsight[] = [];
  @Input() public expanded: boolean;
  @Input() public overflow: number;
  @Input() public activeContainer = false;
  @Input() public hasTooltip = false;
  @Input() public sizeClass = '';
  @Input() public stretchNotification = false;
  @Input() public imageShapeClass: UIImageShapeType = UIImageShapeType.ROUND;

  // Outputs
  @Output() public toggleExpand = new EventEmitter<void>();
  @Output() public select = new EventEmitter<IUIEntityInsight>();
  @Output() public hover = new EventEmitter<IUIEntityInsight>();

  public resources = resources;
  @ViewChildren('pictureObject') public listPictureItems: QueryList<ElementRef>;

  constructor(private focusManagerService: FocusManagerService, private cdr: ChangeDetectorRef, private translate: TranslateHelperService) {}

  public ngOnInit() {
    // Translate resources
    this.translate.translateResources(this.resources);
  }

  public toggleWidgetMouse($event: MouseEvent) {
    if (isMainButtonPressed($event)) {
      this.toggleWidget();
    }
  }

  public toggleWidget(bKeyboardEvent: boolean = false) {
    // Get current array length
    const currentLength: number = this.listPictureItems.toArray().length;
    this.toggleExpand.emit();

    if (bKeyboardEvent) {
      this.focusManagerService.focusVia(() => {
        // Update view
        this.cdr.detectChanges();
        const listItems = this.listPictureItems.toArray();
        return listItems[currentLength - 1];
      });
    }
  }

  public selectEntity(entity: IUIEntityInsight) {
    this.select.emit(entity);
  }

  public getShowingEntityProfileText(entity: IUIEntityInsight): string {
    const itemResources = { PeopleShowingPersonProfile: '' };
    this.translate.translateResources(itemResources, { name: entity.name });
    return itemResources.PeopleShowingPersonProfile;
  }

  public showEntityTooltip(entity: IUIEntityInsight, showTooltip: boolean) {
    entity.showTooltip = showTooltip;
    this.hover.emit(entity);
  }
}
