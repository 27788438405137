import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { IPersonaStyle } from './interfaces';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';

@Component({
  selector: 'app-vi-persona',
  templateUrl: './persona.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  @Input() public personaId: string;
  @Input() public label: string;
  @Input() public personaStyle: string;
  @Input() public personsUrl: string;

  public style: IPersonaStyle = {
    backgroundImage: '',
    backgroundPosition: ''
  };

  public fallbackUrl: string;

  constructor(public cdr: ChangeDetectorRef, private commonUtilsService: InsightsCommonUtilsService) {}

  public ngOnInit() {
    this.fallbackUrl = this.commonUtilsService.FaceFallbackUrl;
    this.getStyle();
  }

  private getStyle() {
    if (!this.personsUrl) {
      return;
    }
    this.style.backgroundImage = `url(${this.personsUrl})`;
    // this.style.backgroundPosition = `-${this.person.positionLarge}px 0`;
    this.cdr.markForCheck();
  }
}
