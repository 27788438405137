import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { combineLatestWith } from 'rxjs';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { SideMenuOption } from '@common/modules/core/services/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';
import { ISubMenuItem } from '../side-sub-menu/interfaces';
import { resources } from './resources';
import { CoreStoreService } from '../../../core/services/core-store.service';

@Component({
  selector: 'vi-edge-extensions-sub-menu',
  templateUrl: './edge-extensions-sub-menu.component.html',
  styleUrls: ['./edge-extensions-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EdgeExtensionsSubMenuComponent implements OnInit {
  public menuItems: ISubMenuItem<string>[];
  public resources = resources;
  public hasSelectedEdgeExtension: boolean;

  private readonly CLOUD_ITEM_CLASS = 'cloud';
  private readonly EXTENSION_CLASS_PREFIX = 'extension';
  private isMediaSideMenuOptionSelected: boolean;

  constructor(
    public edgeExtensionsStore: EdgeExtensionsStoreService,
    public coreStore: CoreStoreService,
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private focusManager: FocusManagerService,
    private trackService: TrackService
  ) {}

  public ngOnInit(): void {
    this.translate.translateResourcesInstant(resources);
    this.edgeExtensionsStore.accountEdgeExtensions$
      .pipe(combineLatestWith(this.edgeExtensionsStore.selectedEdgeExtensionId$, this.coreStore.selectSideMenuSelectedOption()))
      .subscribe(([accountExtensions, selectedEdgeExtensionId, sideMenuOption]) => {
        this.isMediaSideMenuOptionSelected = sideMenuOption === SideMenuOption.Media;
        this.initMenuItems(accountExtensions, selectedEdgeExtensionId);
        this.cdr.detectChanges();
        this.focusEdgeExtensionItem(selectedEdgeExtensionId);
      });
    this.edgeExtensionsStore.hasSelectedEdgeExtension$.subscribe(hasSelectedEdgeExtension => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
    });
    this.trackService.track('edge.extension_sub_menu.init', { category: EventCategory.EDGE });
  }

  public handleSelectItem(item: ISubMenuItem<string>) {
    if (!item.value) {
      if (this.hasSelectedEdgeExtension) {
        this.edgeExtensionsStore.clearSelectedEdgeExtension();
      }
      return;
    }
    this.selectEdgeExtension(item.value);
  }

  private initMenuItems(accountExtensions: Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension[], selectedEdgeExtensionId: string) {
    const cloudItem: ISubMenuItem<string> = {
      value: '',
      selected: this.isMediaSideMenuOptionSelected && selectedEdgeExtensionId === '',
      text: resources.MyCloud,
      class: this.CLOUD_ITEM_CLASS
    };

    this.menuItems = [cloudItem];

    for (const extension of accountExtensions) {
      if (!extension?.identity?.principalId) {
        this.trackService.track('edge.sub_menu_add_extension.skipped', {
          category: EventCategory.EDGE,
          data: {
            extensionName: extension?.name,
            extensionType: extension?.type,
            hasIdentity: !!extension?.identity
          }
        });
        continue;
      }
      this.menuItems.push({
        value: extension.id,
        selected: this.isMediaSideMenuOptionSelected && selectedEdgeExtensionId === extension.id,
        text: extension.name,
        class: `${this.EXTENSION_CLASS_PREFIX}_${extension.identity.principalId}`
      });
    }
  }

  private focusEdgeExtensionItem(selectedEdgeExtensionId: string) {
    const menuItem = this.menuItems.find(item => item.value === selectedEdgeExtensionId);
    if (menuItem) {
      this.focusManager.focusByQuery(`.${menuItem.class}`, this.cdr);
    }
  }

  private selectEdgeExtension(id: string) {
    this.edgeExtensionsStore.selectEdgeExtensionClicked(id);
  }
}
