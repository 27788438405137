import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import { immerOn } from 'ngrx-immer/store';

import { ISearchRequestParams } from '@common/modules/api/interfaces';

import { INextPage } from '../models/gallery';
import * as SearchActions from '../actions/search.actions';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export interface IState extends EntityState<SinglePlaylistSearchResultV2> {
  // additional entities state properties
  selectedVideoId: string;
  loading: boolean;
  searching: boolean;
  nextPage: INextPage;
  params: ISearchRequestParams;
  loadingNext: boolean;
  error: boolean;
}

function selectVideoId(a: SinglePlaylistSearchResultV2): string {
  return a.id;
}

const adapter: EntityAdapter<SinglePlaylistSearchResultV2> = createEntityAdapter<SinglePlaylistSearchResultV2>({
  selectId: selectVideoId
});

const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedVideoId: null,
  loading: false,
  searching: false,
  nextPage: {
    pageSize: null,
    skip: null,
    done: false
  },
  params: {},
  loadingNext: false,
  error: false
});

const searchReducer = createReducer(
  initialState,
  on(SearchActions.searchVideos, (state, { params }) => {
    return {
      ...state,
      loading: true,
      searching: true,
      params: params
    };
  }),
  on(SearchActions.upsertVideos, (state, { videos, nextPage }) => {
    return adapter.upsertMany(videos, {
      ...state,
      loading: false,
      nextPage: nextPage,
      loadingNext: false
    });
  }),
  on(SearchActions.clearSearch, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(SearchActions.clearSearch, (state, {}) => {
    return {
      ...state,
      loading: false,
      searching: false,
      nextPage: {
        pageSize: null,
        skip: null,
        done: false
      },
      params: {}
    };
  }),
  on(SearchActions.searchNextSearchResults, (state, {}) => {
    return {
      ...state,
      loadingNext: true
    };
  }),
  on(SearchActions.searchVideosError, (state, {}) => {
    return {
      ...state,
      error: true
    };
  }),
  on(SearchActions.deleteVideo, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  immerOn(SearchActions.updateVideo, (state: IState, { video }) => {
    if (state.entities[video.id]) {
      state.entities[video.id] = video;
    }

    return state;
  })
);

export function reducer(state: IState, action: Action) {
  return searchReducer(state, action);
}
