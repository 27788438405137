import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { IState } from '../reducers';
import * as fromSupportedLanguagesIds from '../selectors/supported-languages-ids.selectors';
import * as fromEdgeSupportedLanguages from '../selectors/edge-supported-languages.selectors';

@Injectable({
  providedIn: 'root'
})
export class SupportedLanguagesStoreService {
  constructor(private readonly store: Store<IState>) {}

  public get supportedLanguagesIds$(): Observable<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]> {
    return this.store.select(fromSupportedLanguagesIds.selectSupportedLanguagesIds);
  }

  public get supportedLanguagesIdsError$() {
    return this.store.select(fromSupportedLanguagesIds.selectSupportedLanguagesIdsError);
  }

  public get supportedLanguagesIdsSuccess$() {
    return this.store.select(fromSupportedLanguagesIds.selectSupportedLanguagesIdsSuccess);
  }

  // Edge
  public get edgeSupportedLanguages$(): Observable<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]> {
    return this.store.select(fromEdgeSupportedLanguages.selectEdgeSupportedLanguages);
  }
}
