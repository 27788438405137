import { NavBarItem } from '@vi-ui/core';

import { AdvancedSettings } from '../../../interfaces';

export const settings: NavBarItem[] = [
  { id: AdvancedSettings.General, hidden: false, key: 'IndexingGeneralSettings', text: '' },
  { id: AdvancedSettings.FileNames, hidden: false, key: 'IndexingFileNamesSettings', text: '' },
  { id: AdvancedSettings.IndexingPreset, hidden: false, key: 'IndexingIndexingPresetsSettings', text: '' },
  { id: AdvancedSettings.PeopleModels, hidden: false, key: 'IndexingPeopleModelsSettings', text: '' },
  { id: AdvancedSettings.BrandCategories, hidden: false, key: 'IndexingBrandCategoriesSettings', text: '' },
  { id: AdvancedSettings.LogoGroups, hidden: false, key: 'IndexingLogoGroupsSettings', text: '' },
  { id: AdvancedSettings.FileInfo, hidden: false, key: 'IndexingFileInfoSettings', text: '' }
];

export const edgeSettings: NavBarItem[] = [
  { id: AdvancedSettings.General, hidden: false, key: 'IndexingGeneralSettings', text: '' },
  { id: AdvancedSettings.FileNames, hidden: false, key: 'IndexingFileNamesSettings', text: '' },
  { id: AdvancedSettings.IndexingPreset, hidden: false, key: 'IndexingIndexingPresetsSettings', text: '' },
  { id: AdvancedSettings.FileInfo, hidden: false, key: 'IndexingFileInfoSettings', text: '' }
];
