import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';

import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { IAction, IArrToString } from '../../interfaces';
import {
  AZURE_LEGAL_TERMS,
  CUSTOMER_SOLUTION,
  lIMITED_ACCESS_SERVICES_TERMS,
  ONLINE_SERVICES_DPA,
  REPORT_SENSITIVE_USE,
  RESTRICTION_ON_US_POLICE,
  VI_LIMITED_ACCESS_FEATURES_DOC
} from '../../links';
import { UtilsService } from '../../services/utils.service';
import { ActionButtonType } from '../action-button/interfaces';
import { IDialogEvent } from '../dialog/interfaces';
import { acceptAction, dismissAction, useCaseMenuAction, useCaseOptions } from './actions';
import { IFaceGatePopupEvent, IFaceGatePopupInfo } from './interfaces';
import { resources } from './resources';

@Component({
  selector: 'vi-internal-face-gate-form',
  templateUrl: './internal-face-gate-form.component.html',
  styleUrls: ['./internal-face-gate-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalFaceGateFormComponent implements OnInit {
  @Input() public data: IFaceGatePopupInfo;
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public acceptAction = acceptAction;
  public dismissAction = dismissAction;
  public resources = resources;
  public isUserConfirmTerms: boolean;
  public signature = '';
  public ActionButtonType = ActionButtonType;
  public showEmailError = false;
  public useCaseMenuAction = useCaseMenuAction;
  public useCaseOptions = useCaseOptions;
  public selectedUseCases: string;

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private trackService: TrackService,
    private utilsService: UtilsService
  ) {}

  public ngOnInit(): void {
    this.initTranslations();
  }

  public toggleConfirmTerms($event) {
    this.isUserConfirmTerms = $event;
  }

  public onEmailInputValueChanged(email: string) {
    this.showEmailError = !this.data.userEmail.toLowerCase().startsWith(email.toLowerCase());
  }

  public onEmailInputFocusout(email: string) {
    this.showEmailError = email && this.data.userEmail.toLowerCase() !== email.toLowerCase();
  }

  public get acceptBtnDisabled() {
    return !this.isUserConfirmTerms || this.signature.toLowerCase() !== this.data.userEmail.toLowerCase() || !this.selectedUseCases;
  }

  public onClickAccept() {
    if (this.acceptBtnDisabled) {
      return;
    }
    const eventData = {
      isAccepted: true,
      signature: this.signature.toLowerCase(),
      useCase: this.selectedUseCases
    };
    this.trackService.track('internal_face_gate_form.accept_btn.clicked', {
      category: EventCategory.FACE_GATE
    });
    this.emitEvent(eventData);
  }

  public onClickDismiss() {
    const eventData = {
      isAccepted: false,
      isDismissed: true
    };
    this.emitEvent(eventData);
    this.trackService.track('internal_face_gate_form.dismiss_btn.clicked', {
      category: EventCategory.FACE_GATE
    });
  }

  public onSelectUseCases(actions: IAction[]) {
    if (actions?.length <= 0) {
      this.selectedUseCases = '';
      return;
    }

    const arrToString: IArrToString[] = actions.map(action => {
      return { name: action.value as string };
    });
    this.selectedUseCases = this.utilsService.parseArrayToString(arrToString);
  }

  private emitEvent(outputData: IFaceGatePopupEvent) {
    const dialogEvent: IDialogEvent = {
      isDialogClose: true,
      dialogEventData: outputData
    };

    this.componentEventEmitter.emit(dialogEvent);
  }

  private initTranslations() {
    this.translate.translateResources(this.resources, { userEmail: this.data.userEmail, ...this.faceGateLinksVariables }).subscribe(() => {
      this.acceptAction = {
        ...this.acceptAction,
        title: this.resources[this.acceptAction.key]
      };

      this.dismissAction = {
        ...this.dismissAction,
        title: this.resources[this.dismissAction.key]
      };

      this.useCaseMenuAction = {
        ...this.useCaseMenuAction,
        title: this.resources[this.useCaseMenuAction.key]
      };

      this.useCaseOptions = this.useCaseOptions.map(option => {
        return {
          ...option,
          title: this.resources[option.key]
        };
      });

      this.cdr.detectChanges();
    });
  }

  private get faceGateLinksVariables() {
    return {
      FaceGateTermsOfUseLink: AZURE_LEGAL_TERMS,
      FaceGateCustomerSolutionLink: CUSTOMER_SOLUTION,
      FaceGateLimitedAccessServicesTermsLink: lIMITED_ACCESS_SERVICES_TERMS,
      FaceGateRestrictionsOnUSPoliceUseLink: RESTRICTION_ON_US_POLICE,
      FaceGateOnlineServicesDPALink: ONLINE_SERVICES_DPA,
      FaceGateSensitiveUseCaseLink: REPORT_SENSITIVE_USE,
      FaceGateVILimitedAccessFeaturesDocLink: VI_LIMITED_ACCESS_FEATURES_DOC
    };
  }
}
