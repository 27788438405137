import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { firstValueFrom, Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';

import { IState } from '../reducers';
import * as fromCore from '../selectors';

export const UserMetadataResolver: ResolveFn<Promise<boolean>> = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(UserMetadataResolverService).resolve();
};

@Injectable({
  providedIn: 'root'
})
export class UserMetadataResolverService {
  constructor(private store: Store<IState>, private logger: LoggerService) {
    this.logger.log('[UserMetadataResolver] init');
  }

  public resolve(): Promise<boolean> {
    return firstValueFrom(
      this.waitForUserMetadataToLoad().pipe(
        map(loaded => {
          this.logger.log('[UserMetadataResolver] ' + loaded);
          return true;
        })
      )
    );
  }

  private waitForUserMetadataToLoad(): Observable<boolean> {
    return this.store.select(fromCore.isCoreMetadataLoaded).pipe(
      filter(loaded => loaded),
      take(1)
    );
  }
}
