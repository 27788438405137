import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of, switchMap } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { MediaCommonStoreService } from '@common/modules/media-data-common/services/media-common-store.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { MediaStoreService } from '../../media-data/services/media-store.service';
import { CoreStoreService } from '../services/core-store.service';
import { EdgeExtensionsStoreService } from '../services/edge-extensions-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export const EdgeExtensionsMediaMetadataResolver: ResolveFn<Observable<boolean>> = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(EdgeExtensionsMediaMetadataResolverService).resolve(next);
};

@Injectable({
  providedIn: 'root'
})
export class EdgeExtensionsMediaMetadataResolverService {
  constructor(
    private logger: LoggerService,
    private coreStore: CoreStoreService,
    private mediaStoreService: MediaStoreService,
    private edgeExtensionsStore: EdgeExtensionsStoreService,
    private featureSwitchService: FeatureSwitchService,
    private mediaCommonStoreService: MediaCommonStoreService
  ) {
    this.logger.log('[EdgeExtensionsMediaMetadataResolver] Init');
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.featureSwitchService.featureSwitch(FeatureSwitch.Edge)) {
      this.coreStore.navigate([`${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`], null, null, true);
      return of(false);
    }

    return this.edgeExtensionsStore.edgeExtensionsMetadataLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      withLatestFrom(this.coreStore.selectAccountIds$),
      switchMap(([loaded, accountIds]) => {
        this.logger.log('[EdgeExtensionsMediaMetadataResolver] ' + loaded);
        const videoId = route?.params?.videoId;
        const accountId = route?.params?.accountId;
        if (!accountIds.includes(accountId)) {
          this.coreStore.navigate([`/${VIRoutingMap.pageNotFoundError.path}`]);
          return of(false);
        }
        this.coreStore.selectAccount(accountId, false, true);
        this.mediaCommonStoreService.selectIndex(videoId);

        return this.edgeExtensionsStore.selectEdgeExtensionsLoaded$.pipe(
          filter(loaded => loaded),
          take(1),
          map(() => {
            const extensionId = route?.params?.extensionId;
            this.edgeExtensionsStore.selectEdgeExtensionByPrincipalId(extensionId);
            setTimeout(() => {
              this.mediaStoreService.loadVideoIndex(false);
            });
            return true;
          })
        );
      })
    );
  }
}
