import { ActionButtonType } from '../action-button/interfaces';

export type CheckBoxActionMenuType = ActionButtonType | CheckBoxActionMenuExtendedType;

export enum CheckBoxActionMenuExtendedType {
  Underline = 'underline'
}

export enum CheckBoxActionMenuSize {
  Small = 'small',
  Large = 'Large'
}
