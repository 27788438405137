import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const languageAction: IAction = {
  title: '',
  type: UIActionType.LANGUAGE,
  value: UIActionType.LANGUAGE,
  selected: false,
  selectable: false,
  id: 'language'
};
