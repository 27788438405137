import { createAction, props } from '@ngrx/store';
import { Update, EntityMap } from '@ngrx/entity';

import UserContractSlim = Microsoft.VideoIndexer.Contracts.UserContractSlim;

export const loadUserDetails = createAction('[User/API] Load User Details', props<{ forceLoad?: boolean; selectAccountId?: string }>());
export const loadUserDetailsSuccess = createAction('[User] Load User Details Success');
export const loadUserDetailsFailed = createAction('[User] Load User Details Failed');
export const loadUserDetailsInProgress = createAction('[User/API] Load User Details In Progress');
export const loadUserNotAuthenticated = createAction('[User] Load User Not Authenticated');
export const addUser = createAction('[User] Add User', props<{ user: UserContractSlim }>());
export const upsertUser = createAction('[User] Upsert User', props<{ user: UserContractSlim }>());
export const addUsers = createAction('[User] Add Users', props<{ users: UserContractSlim[] }>());
export const upsertUsers = createAction('[User/API] Upsert Users', props<{ users: UserContractSlim[] }>());
export const updateUser = createAction('[User] Update User', props<{ user: Partial<UserContractSlim> }>());
export const updateUserApi = createAction('[User/API] Update User Api', props<{ user: Partial<UserContractSlim> }>());
export const updateUsers = createAction('[User/API] Update Users', props<{ users: Array<Update<UserContractSlim>> }>());
export const mapUsers = createAction('[User/API] Map Users', props<{ entityMap: EntityMap<UserContractSlim> }>());
export const deleteUser = createAction('[User/API] Delete User', props<{ id: string }>());
export const deleteUsers = createAction('[User/API] Delete Users', props<{ ids: string[] }>());
export const clearUsers = createAction('[User] Clear Users');
