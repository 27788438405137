<div class="container"
  [ngClass]="{'is-disabled': isDisabled}">
  <div class="col main-filter">
    <vi-fluent-ui-select [id]="filterActionsId"
      [action]="filterItem.actionType"
      [size]="SelectComponentSize.Small"
      [type]="'underline'"
      [(selectedAction)]="selFilterAction"
      [actionList]="filterActions"
      [ariaLabel]="selFilterAction?.title"
      [isDisabled]="isDisabled"
      (selectedActionChange)="handleSelectFilterAction($event)">
    </vi-fluent-ui-select>
  </div>
  <div [ngSwitch]="filterType"
    class="col sub-filter">
    <app-vi-searchbar #searchBarForTextFilter
      class="with-focus"
      *ngSwitchCase="UIFilterType.TEXT"
      [placeholder]="searchbarPlaceholder"
      [isDisabled]="isDisabled"
      [id]="searchInputId"
      [initialSearchValue]="initValue"
      [delay]="100">
    </app-vi-searchbar>
    <vi-fluent-ui-select *ngSwitchCase="UIFilterType.DROP_DOWN"
      [id]="getSubActionID()"
      [action]="selectedAction"
      [actionList]="actionList"
      [placeholder]="placeholderAction.title"
      [size]="SelectComponentSize.Small"
      [type]="'underline'"
      [(selectedAction)]="selectedAction"
      [ariaLabel]="selectedAction?.title"
      [isDisabled]="isDisabled"
      (selectedActionChange)="handleFilterTextChange($event)">
    </vi-fluent-ui-select>
  </div>
  <div class="col op">
    <i class="i-vi-removefrom"
      appStopPropagation
      [attr.tabindex]="disableRemoveButton ? -1 : 0"
      role="button"
      [attr.aria-label]="resources?.EditorFilterItemRemoveTitle"
      [title]="resources?.EditorFilterItemRemoveTitle"
      (appAccessibleClick)="remove()"
      [appClickDisabled]="disableRemoveButton"
      appAccessibilityTooltip
      [showAccessibilityTooltip]="accessibilityMode"
      [ngClass]="{'disabled': disableRemoveButton}">
    </i>
    <i class="i-vi-addto"
      appStopPropagation
      [attr.tabindex]="disableAddButton ? -1 : 0"
      role="button"
      [attr.aria-label]="resources?.EditorFilterItemAddTitle"
      [title]="resources?.EditorFilterItemAddTitle"
      (appAccessibleClick)="add()"
      appAccessibilityTooltip
      [showAccessibilityTooltip]="accessibilityMode"
      [appClickDisabled]="disableAddButton"
      [ngClass]="{'disabled': disableAddButton}">
    </i>
  </div>
</div>
