import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, EventEmitter, Output, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';

import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { resources } from './resources';
import { cancelAction, deleteAccountAction } from './actions';
import { DeleteState, IDeleteAccount, IDeleteAccountOutput } from './interfaces';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-vi-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAccountComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() public data: IDeleteAccount;
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public account: Microsoft.VideoIndexer.Contracts.AccountContract;
  public isUserOwner: boolean;
  public resources = resources;
  public accountNameInput = '';
  public state: DeleteState;
  public DeleteState = DeleteState;
  public cancelAction = cancelAction;
  public deleteAccountAction = cloneDeep(deleteAccountAction);
  public readonly BUTTON_WIDTH = '120px';
  public ActionButtonType = ActionButtonType;
  private readonly delayTimeout = 3000;
  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService,
    private trackService: TrackService
  ) {
    super();
  }

  public ngOnInit() {
    this.trackService.track('settings.account.delete_account_dialog.init');
    this.setProperties();
    this.setTranslation();
    this.addDataListeners();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public deleteAccount() {
    // Check account name
    if (!this.validateAccountName()) {
      return;
    }

    // Set state to Process
    this.state = DeleteState.PROCESS;
    this.cancelAction.isDisabled = true;

    // Call API to delete.
    if (this.isUserOwner) {
      this.trackService.track('settings.account.delete_account_dialog.delete_account', {
        category: EventCategory.SETTINGS
      });
      this.settingsService.deleteAccount(this.account.id);
    } else {
      this.trackService.track('settings.account.leave_account.init', {
        category: EventCategory.SETTINGS
      });
      this.settingsService.leaveAccount(this.account.id);
    }
  }

  public validateAccountName(): boolean {
    if (this.accountNameInput !== this.account.name) {
      this.deleteAccountAction.isDisabled = true;
      return false;
    }
    this.deleteAccountAction.isDisabled = false;
    return true;
  }

  public closeDialog(closeParentComponent: boolean, isAccountDeleted: boolean = false): void {
    const data: IDeleteAccountOutput = { closeParentComponent: closeParentComponent, isAccountDeleted: isAccountDeleted };
    const dialogEvent: IDialogEvent = {
      isDialogClose: true,
      dialogEventData: data
    };
    this.componentEventEmitter.emit(dialogEvent);
  }

  private setProperties() {
    this.state = DeleteState.FORM;
    this.isUserOwner = this.data.isUserOwner;
    this.account = this.data.account;
  }

  private handleActionSuccess() {
    this.state = DeleteState.COMPLETED;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.closeDialog(!this.isUserOwner, true);
    }, this.delayTimeout);
  }

  private setTranslation() {
    this.translate.translateResources(this.resources, { name: this.account.name }).subscribe(() => {
      if (this.isUserOwner) {
        this.deleteAccountAction.title = this.resources.DeleteAccountTitle;
      } else {
        this.deleteAccountAction.title = this.resources.DeleteAccountDialogOptionsActionBtnText;
      }
      this.cdr.detectChanges();
    });
  }

  private addDataListeners() {
    // Listen to account saving - handle delete response
    this.settingsService.isAccountDeleteInProgress$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.settingsService.selectAccountError$))
      .subscribe(([saving, error]) => {
        if (!saving && this.state === DeleteState.PROCESS) {
          if (!error) {
            this.trackService.track('settings.account.delete_leave_account.success', {
              category: EventCategory.SETTINGS
            });
            this.handleActionSuccess();
          } else {
            this.trackService.track('settings.account.delete_leave_account.failed', {
              category: EventCategory.SETTINGS
            });
            this.closeDialog(false);
          }
        }
      });
  }
}
