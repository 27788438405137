import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { ISearchRequestParams } from '@common/modules/api/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';

import { showLanguagesError } from './../core/selectors/gallery-view.selectors';
import { IState } from '../../core/reducers';
import { GalleryTab, GalleryView } from '../interfaces';
import * as GalleryViewActions from '../core/actions/gallery-view.actions';
import * as GalleryActions from '../core/actions/gallery.actions';
import * as LibraryActions from '../core/actions/library.actions';
import * as ProjectsActions from '../core/actions/projects.actions';
import * as SamplesActions from '../core/actions/samples.actions';
import * as SearchActions from '../core/actions/search.actions';
import * as fromGallery from '../core/selectors';

@Injectable()
export class GalleryStoreService {
  constructor(private readonly store: Store<IState>, private localStorageService: LocalStorageService) {}

  // Gallery selectors

  public loadAllGallery(): void {
    this.loadLibrary();
    this.loadProjects();
    this.loadSamples();
  }

  public clearGallery(): void {
    this.store.dispatch(GalleryActions.clearGallery());
  }

  public loadNext(): void {
    this.store.dispatch(GalleryActions.loadNext());
  }

  public loadProcessingVideoOrProjectById(id: string) {
    this.store.dispatch(GalleryActions.loadProcessingVideoOrProjectById({ id }));
  }

  public get currentTabVideos$(): Observable<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2[]> {
    return this.store.select(fromGallery.selectAllVideos);
  }

  public get loading$(): Observable<boolean> {
    return this.store.select(fromGallery.isLoading);
  }

  public get isUploadEnabled$(): Observable<boolean> {
    return this.store.select(fromGallery.isUploadEnabled);
  }

  public get showUploadError$(): Observable<boolean> {
    return this.store.select(fromGallery.showUploadError);
  }

  public get showLanguagesError$(): Observable<boolean> {
    return this.store.select(showLanguagesError);
  }

  public get error$(): Observable<boolean> {
    return this.store.select(fromGallery.isError);
  }

  public get isUnauthorizedError$(): Observable<boolean> {
    return this.store.select(fromGallery.isUnauthorizedError);
  }

  public get isNetworkAccessNotAllowedError$(): Observable<boolean> {
    return this.store.select(fromGallery.isNetworkAccessNotAllowedError);
  }

  public get isNextPageDone$(): Observable<boolean> {
    return this.store.select(fromGallery.isNextPageDone);
  }

  public get isNextPageLoading$(): Observable<boolean> {
    return this.store.select(fromGallery.isNextPageLoading);
  }

  // Gallery View selectors

  public get selectedTab$(): Observable<GalleryTab> {
    return this.store.select(fromGallery.selectedGalleryTab);
  }

  public get selectedView$(): Observable<GalleryView> {
    return this.store.select(fromGallery.selectedGalleryView);
  }

  public setSelectedTab(galleryTab: GalleryTab): void {
    this.store.dispatch(GalleryViewActions.selectTab({ tab: galleryTab }));
  }

  public setSelectedView(galleryView: GalleryView): void {
    if (galleryView !== GalleryView.Search) {
      this.localStorageService.setItem('GalleryView', galleryView);
    }
    this.store.dispatch(GalleryViewActions.selectView({ view: galleryView }));
  }

  public get isSelectedTabHidden$(): Observable<boolean> {
    return this.store.select(fromGallery.isSelectedTabHidden);
  }

  // Search selectors

  public search(params: ISearchRequestParams): void {
    this.setSelectedView(GalleryView.Search);
    this.store.dispatch(SearchActions.searchVideos({ params }));
  }

  public clearSearch(): void {
    this.store.dispatch(SearchActions.clearSearch());
    const lastView = this.localStorageService.getItem('GalleryView');
    this.setSelectedView(lastView === 'List' ? GalleryView.List : GalleryView.Grid);
  }

  public get searchResults$(): Observable<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2[]> {
    return this.store.select(fromGallery.selectSearchResultsVideos);
  }

  public get searchParams$(): Observable<ISearchRequestParams> {
    return this.store.select(fromGallery.selectSearchParams);
  }

  public get isSearching$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearching);
  }

  public get isSearchLoading$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearchLoading);
  }

  public get isSearchStateNextPageDone$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearchStateNextPageDone);
  }

  public get isSearchNextPageDone$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearchNextPageDone);
  }

  public get isSearchNextPageLoading$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearchNextPageLoading);
  }

  public get searchError$(): Observable<boolean> {
    return this.store.select(fromGallery.isSearchError);
  }

  public searchNextSearchResults(): void {
    this.store.dispatch(SearchActions.searchNextSearchResults());
  }

  public selectVideo(id: string): Observable<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2> {
    return this.store.select(fromGallery.selectVideo(id));
  }

  // Library selectors

  public loadLibrary(): void {
    this.store.dispatch(LibraryActions.loadLibrary());
  }

  public deleteVideo(id: string, name: string) {
    this.store.dispatch(LibraryActions.deleteVideo({ id, name }));
  }

  // Projects selectors

  public loadProjects(): void {
    this.store.dispatch(ProjectsActions.loadProjects());
  }

  public deleteProject(id: string, name: string) {
    this.store.dispatch(ProjectsActions.deleteVideo({ id, name }));
  }

  public clearProjects(): void {
    this.store.dispatch(ProjectsActions.clearProjects());
  }

  // Samples selectors

  public loadSamples(): void {
    this.store.dispatch(SamplesActions.loadSamples());
  }
}
