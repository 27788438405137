import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IExpandedListItem } from '@common/modules/shared/components/expanded-item/interfaces';

import { ILanguage } from '../../core/interfaces';
import { ICreateListItem } from '../../customization/components/language/model-file/interfaces';
import * as SupportedLanguagesActions from '../actions/supported-languages.actions';

export interface ILanguageListContract extends ILanguage, IExpandedListItem, ICreateListItem {}

export interface IState extends EntityState<ILanguageListContract> {}

export function selectModelId(a: ILanguageListContract): string {
  return a?.key;
}

export const adapter: EntityAdapter<ILanguageListContract> = createEntityAdapter<ILanguageListContract>({
  selectId: selectModelId
});

export const initialState: IState = adapter.getInitialState({});

const supportedLanguagesReducer = createReducer(
  initialState,
  on(SupportedLanguagesActions.updateSupportedLanguage, (state, { supportedLanguage }) => {
    return adapter.updateOne(supportedLanguage, {
      ...state,
      selectedLanguageKey: supportedLanguage?.id
    });
  }),
  on(SupportedLanguagesActions.upsertSupportedLanguages, (state, { supportedLanguages }) => {
    const supportedLanguagesList = supportedLanguages.map(supportedLanguage => ({
      key: supportedLanguage.key,
      name: supportedLanguage.name,
      rtl: supportedLanguage.rtl,
      sourceLanguage: supportedLanguage.sourceLanguage,
      supportedUserLocale: supportedLanguage.supportedUserLocale,
      supportedCustomization: supportedLanguage.supportedCustomization,
      padding: supportedLanguage.padding,
      editMode: false,
      isExpanded: true,
      isCreating: false
    }));
    return adapter.upsertMany(supportedLanguagesList, {
      ...state
    });
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return supportedLanguagesReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectAccountIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllLanguages = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
