import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { UPLOAD_FORMAT_LINK, UPLOAD_LIMITATION_LINK } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';
import { backToUpload } from './actions';

@Component({
  selector: 'vi-upload-guide',
  templateUrl: './upload-guide.component.html',
  styleUrls: ['./upload-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadGuideComponent extends FocusableComponent {
  @Output() public backClicked = new EventEmitter<void>();
  public resources = resources;
  public ActionButtonType = ActionButtonType;
  public backAction = backToUpload;
  public formatsLink = UPLOAD_FORMAT_LINK;
  public readonly limitationsLink = UPLOAD_LIMITATION_LINK;

  constructor(private translate: TranslateHelperService) {
    super();

    this.translate.translateResourcesInstant(this.resources);
    this.backAction.title = this.resources.UploadGuideBackButton;
  }

  public backBtnClicked() {
    this.backClicked.emit();
  }

  public openLink(url) {
    window.open(url, '_blank');
  }
}
