import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { EMPTY, switchMap, withLatestFrom } from 'rxjs';

import { AccountResourceType } from '@common/modules/auth/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { VIRoutingMap } from '../../../app/routing/routes';
import * as AccountActions from '../../../core/actions/accounts.actions';
import * as RouterActions from '../../../core/actions/router.actions';
import { IState } from '../../../core/reducers';
import * as fromRouter from '../../../core/reducers/router';
import * as fromCore from '../../../core/selectors';
import * as fromCustomizationData from '../../selectors';
import { ofRoute } from '../../../core/services/operations';
import { SpeechTab } from '../../../customization/components/speech/speech-container/interfaces';
import * as SpeechDatasetsActions from '../../actions/speech/speech-datasets.actions';
import * as SpeechModelActions from '../../actions/speech/speech-models.actions';
import * as SpeechPageActions from '../../actions/speech/speech-page.actions';
import { SpeechPermission } from '../../interfaces';

@Injectable()
export class SpeechViewEffects {
  public selectSpeechTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechPageActions.selectSpeechTab),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ tab }, accountId]) => {
        if (!accountId) {
          return EMPTY;
        }
        const path = `${VIRoutingMap.customization.path.replace(':accountId', accountId)}/${
          VIRoutingMap.customizationSpeech.path
        }/${tab.toLowerCase()}`;

        return [
          RouterActions.Go({
            path: [path],
            removeParams: true
          })
        ];
      })
    )
  );

  public initSelectedSpeechTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute([
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechDatasets.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechModels.path}`
      ]),
      withLatestFrom(this.store.select(fromRouter.getRouterState)),
      switchMap(([, routerState]) => {
        const speechPath = routerState.state.filteredUrl.substring(routerState.state.filteredUrl.lastIndexOf('/') + 1).toLowerCase();
        switch (speechPath) {
          case VIRoutingMap.customizationSpeechDatasets.path:
            return [SpeechPageActions.initSelectedSpeechTab({ tab: SpeechTab.DATASETS })];
            break;
          case VIRoutingMap.customizationSpeechModels.path:
            return [SpeechPageActions.initSelectedSpeechTab({ tab: SpeechTab.MODELS })];
        }
      })
    )
  );

  public isSpeechPageEnabledARM$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadLastArmAccount),
      switchMap(() => {
        const isSpeechFeatureEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.Speech);

        return [
          SpeechPageActions.initSpeechPagePermission({ permission: isSpeechFeatureEnabled ? SpeechPermission.PERMITTED : SpeechPermission.REJECTED })
        ];
      })
    )
  );
  public isSpeechPageEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.selectAccountProcessing, AccountActions.selectArmAccountProcessing),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([, account]) => {
        const isSpeechFeatureEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.Speech);
        const isAccountEnabled =
          account?.resourceType === AccountResourceType.ARM || account?.accountType?.toLowerCase() === AccountResourceType.TRIAL.toLowerCase();
        const isSpeechPageEnabled = isSpeechFeatureEnabled && isAccountEnabled;
        return [
          SpeechPageActions.initSpeechPagePermission({ permission: isSpeechPageEnabled ? SpeechPermission.PERMITTED : SpeechPermission.REJECTED })
        ];
      })
    )
  );

  public loadSpeechData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.addSelectedAccount, AccountActions.addSelectedArmAccount),
      withLatestFrom(this.store.select(fromCustomizationData.getSpeechPermission)),
      switchMap(([, permission]) => {
        if (permission === SpeechPermission.PERMITTED) {
          return [SpeechModelActions.loadSpeechModels(), SpeechDatasetsActions.loadSpeechDatasets()];
        } else {
          return EMPTY;
        }
      })
    )
  );

  constructor(private actions$: Actions, private store: Store<IState>, private featureSwitchService: FeatureSwitchService) {}
}
