export enum UploadPages {
  UploadSource = 'UploadSource',
  UploadGuide = 'UploadGuide',
  IndexingForm = 'IndexingForm',
  UploadReview = 'UploadReview',
  UploadProgress = 'UploadProgress',
  UploadSummary = 'UploadSummary'
}

export enum UploadSourceActions {
  URL = 'URL',
  UploadGuide = 'UploadGuide'
}
