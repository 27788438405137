import { createAction, props } from '@ngrx/store';

import { ISearchRequestParams } from '@common/modules/api/interfaces';

import { INextPage } from '../models/gallery';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export const searchVideos = createAction('[Search/API] Search Videos', props<{ params: ISearchRequestParams }>());
export const searchProjects = createAction('[Search/API] Search Projects', props<{ params: ISearchRequestParams }>());
export const upsertVideos = createAction('[Search] Upsert Videos', props<{ videos: SinglePlaylistSearchResultV2[]; nextPage?: INextPage }>());
export const updateVideo = createAction('[Search] Update Video', props<{ video: SinglePlaylistSearchResultV2 }>());
export const clearSearch = createAction('[Search] Clear Search');
export const searchNextSearchResults = createAction('[Search/API] Search Next Search Results');
export const searchVideosError = createAction('[Search] Search Videos Error');
export const deleteVideo = createAction('[Search] Delete Video', props<{ id: string }>());
