import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, combineLatestWith, map, switchMap, withLatestFrom } from 'rxjs';

import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';

import { PrivacyMode } from '@common/modules/core/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { StreamingPreset, VideoModerationState, VideoReviewState } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { EdgeExtensionsStoreService } from '../../core/services/edge-extensions-store.service';
import { DEFAULT_LOCALE_LANGUAGE_KEY } from '../../core/supportedLocaleLanguages';
import { SpeechModelStoreService } from '../../customization-data/services/speech/speech-model-store.service';
import { SupportedLanguagesStoreService } from '../../customization-data/services/supported-languages-store.service';
import { IndexingPreset } from '../components/shared/settings';
import * as actions from '../core/actions';
import * as fromIndexing from '../core/selectors';
import { IFile, SupportedAIsMap } from '../interfaces';
import { sortLanguageCompare } from '../utils/languageSort';
import { CustomizationDataStoreService } from './../../customization-data/services/customization-data-store.service';
import { UploadPages } from '../components/upload/interfaces';
import { IState } from './../core/reducers';
import { AdvancedSettings, IIndexingLanguage, IndexingMode, UploadMode } from './../interfaces';
import { ReIndexPages, FailureCode } from '../components/re-index/interfaces';
import { isAccountDailyCountQuotaExceeded, isAccountDailyDurationQuotaExceeded, isAccountDurationQuotaExceeded } from '../../core/effects/utils';

@Injectable()
export class IndexingStoreService {
  constructor(
    private readonly store: Store<IState>,
    private customizationDataStoreService: CustomizationDataStoreService,
    private coreStoreService: CoreStoreService,
    private translate: TranslateHelperService,
    private speechModelStoreService: SpeechModelStoreService,
    private supportedLanguagesStore: SupportedLanguagesStoreService,
    private edgeExtensionsStore: EdgeExtensionsStoreService,
    private featureSwitchService: FeatureSwitchService
  ) {}

  // Files getters
  public get allValidFilesNames$(): Observable<string[]> {
    return this.store.select(fromIndexing.selectFilesNames);
  }

  public get allFiles$(): Observable<IFile[]> {
    return this.store.select(fromIndexing.getAllFiles);
  }

  public get allValidFiles$(): Observable<IFile[]> {
    return this.store.select(fromIndexing.getAllValidFiles);
  }

  public get totalUploadProgress$(): Observable<number> {
    return this.store.select(fromIndexing.getTotalUploadProgress);
  }

  public get isMultiFileMode$(): Observable<boolean> {
    return this.store.select(fromIndexing.isMultiFileMode);
  }

  // Indexing Settings getters
  public get indexingSettingSummary$() {
    return this.store.select(fromIndexing.getIndexingSettingSummary);
  }

  public get indexingPreset$(): Observable<IndexingPreset> {
    return this.store.select(fromIndexing.selectIndexingPreset);
  }

  public get excludeRAI$(): Observable<boolean> {
    return this.store.select(fromIndexing.selectExcludeRAI);
  }

  public get excludeAIs$(): Observable<ExcludableAIsModels[]> {
    return this.store.select(fromIndexing.selectExcludeAIs);
  }

  public get selectedSupportedAIs$(): Observable<SupportedAIsMap> {
    return this.store.select(fromIndexing.selectSupportedAIs);
  }

  public get isSupportedAIsLoaded$(): Observable<boolean> {
    return this.store.select(fromIndexing.isSupportedAIsLoaded);
  }

  public get selectedLanguage$(): Observable<string> {
    return this.store.select(fromIndexing.selectLanguageId);
  }

  public get streamingPreset$(): Observable<StreamingPreset> {
    return this.store.select(fromIndexing.selectStreamingPreset);
  }

  public get privacy$(): Observable<PrivacyMode> {
    return this.store.select(fromIndexing.selectPrivacy);
  }

  public get description$(): Observable<string> {
    return this.store.select(fromIndexing.selectDescription);
  }

  public get metadata$(): Observable<string> {
    return this.store.select(fromIndexing.selectMetadata);
  }

  public get selectedPeopleModel$(): Observable<Microsoft.VideoIndexer.Contracts.PersonModel> {
    return this.store.select(fromIndexing.selectedPeopleModel);
  }

  public get selectBrandCategories$(): Observable<string> {
    return this.store.select(fromIndexing.selectBrandCategories);
  }

  public selectedLogoGroup$(): Observable<Microsoft.VideoIndexer.Contracts.Customization.LogoGroupContract> {
    return this.store.select(fromIndexing.selectedLogoGroup);
  }

  public get selectLogoGroupId$(): Observable<string> {
    return this.store.select(fromIndexing.selectLogoGroupId);
  }

  public get indexingLanguages$(): Observable<IIndexingLanguage[]> {
    return this.store.select(fromIndexing.getLanguagesObject).pipe(
      withLatestFrom(this.supportedLanguagesStore.supportedLanguagesIds$),
      withLatestFrom(this.customizationDataStoreService.trainedLanguageModels$),
      withLatestFrom(this.speechModelStoreService.trainedSpeechModels$),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingMode)),
      withLatestFrom(this.supportedLanguagesStore.edgeSupportedLanguages$),
      withLatestFrom(this.edgeExtensionsStore.hasSelectedEdgeExtension$),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingPreset)),
      switchMap(
        ([
          [
            [[[[[{ additionalUploadLanguages, keepSource }, supportedLanguagesIds], languageModels], speechModels], mode], edgeSupportedLanguages],
            hasSelectedEdgeExtension
          ],
          indexingPreset
        ]) => {
          const additionalLanguages = additionalUploadLanguages.map(lang => ({
            id: lang.key,
            name: this.translate.getLocaleLanguage(lang.key),
            languageCode: lang.key,
            disabled: [
              IndexingPreset.AUDIO_BASIC,
              IndexingPreset.VIDEO_BASIC,
              IndexingPreset.VIDEO_STANDARD,
              IndexingPreset.VIDEO_ADVANCED,
              IndexingPreset.VIDEO_AUDIO_BASIC
            ].includes(indexingPreset)
          }));

          if (hasSelectedEdgeExtension) {
            const edgeLanguages = edgeSupportedLanguages
              ?.filter(lang => lang.isSourceLanguage)
              ?.map(lang => ({
                id: lang.languageCode,
                name: this.translate.getLocaleLanguage(lang.languageCode),
                languageCode: lang.languageCode,
                disabled: false
              }));
            let languages = [...edgeLanguages];

            // In case the edge supported API isn't working, we fallback to the default languages
            if (!languages.length) {
              languages.push({
                id: DEFAULT_LOCALE_LANGUAGE_KEY,
                name: this.translate.instant('English'),
                languageCode: DEFAULT_LOCALE_LANGUAGE_KEY,
                disabled: false
              });
            }
            if (this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeMultiLid)) {
              additionalLanguages.forEach(
                lang => (lang.disabled = [IndexingPreset.AUDIO_BASIC, IndexingPreset.VIDEO_BASIC, IndexingPreset.DEFAULT].includes(indexingPreset))
              );
              languages = [...additionalLanguages, ...languages];
            }
            languages.sort(sortLanguageCompare);
            return [languages];
          }

          const keepSourceLanguage = {
            id: keepSource.id,
            name: this.translate.instant('ReindexModalKeepSourceValue'),
            languageCode: keepSource.id,
            disabled: false
          };
          const supportedLanguages: IIndexingLanguage[] = supportedLanguagesIds
            ?.filter(lang => lang.isSourceLanguage)
            .map(lang => ({
              id: lang.languageCode,
              name: this.translate.getLocaleLanguage(lang.languageCode),
              languageCode: lang.languageCode,
              disabled: false
            }));

          const mappedLanguageModels: IIndexingLanguage[] = [];
          if (languageModels?.length > 0) {
            languageModels.forEach(model => {
              const lang = supportedLanguages.find(l => l.languageCode?.toLocaleLowerCase() === model.language?.toLocaleLowerCase());
              if (!lang) {
                return;
              }
              const langModel: IIndexingLanguage = {
                id: model.languageModelId,
                name: `${lang.name} (${model.name})`,
                languageCode: model.language,
                disabled: false
              };
              mappedLanguageModels.push(langModel);
            });
          }
          const mappedSpeechModels: IIndexingLanguage[] = [];
          if (speechModels?.length > 0) {
            speechModels.forEach(model => {
              const lang = supportedLanguages.find(l => l.languageCode?.toLocaleLowerCase() === model.locale?.toLocaleLowerCase());
              if (!lang) {
                return;
              }
              const speechModel: IIndexingLanguage = {
                id: model.id,
                name: `${lang.name} (${model.displayName})`,
                languageCode: model.locale,
                disabled: false
              };
              mappedSpeechModels.push(speechModel);
            });
          }

          const languages = [...additionalLanguages, ...supportedLanguages, ...mappedLanguageModels, ...mappedSpeechModels];
          languages.sort(sortLanguageCompare);
          if (mode === IndexingMode.ReIndex) {
            languages.unshift(keepSourceLanguage);
          }
          return [languages];
        }
      )
    );
  }

  public get isIndexingSettingLoaded$(): Observable<boolean> {
    return this.store.select(fromIndexing.isIndexingSettingLoaded);
  }

  public get isReIndexProcessing$(): Observable<boolean> {
    return this.store.select(fromIndexing.isReIndexProcessing);
  }

  public get fileName$(): Observable<string> {
    return this.store.select(fromIndexing.selectFileName);
  }

  // Indexing errors
  public get isLoadSupportedAIsError$() {
    return this.store.select(fromIndexing.isLoadSupportedAIsError);
  }

  // Upload settings getters
  public get displayedUploadPage$(): Observable<UploadPages> {
    return this.store.select(fromIndexing.selectUploadDisplayedPage);
  }

  public get isUrlValidationLoading$(): Observable<boolean> {
    return this.store.select(fromIndexing.isUrlValidationLoading);
  }

  public get urlValidationError$(): Observable<APIErrors> {
    return this.store.select(fromIndexing.urlValidationError);
  }

  public get isUploadInProgress$(): Observable<boolean> {
    return this.store.select(fromIndexing.isUploadInProgress);
  }

  public get isUploadingDone$(): Observable<boolean> {
    return this.store.select(fromIndexing.isUploadingDone);
  }

  public get showUploadFileError$(): Observable<boolean> {
    return this.store.select(fromIndexing.showUploadFileError);
  }

  public get uploadMode$(): Observable<UploadMode> {
    return this.store.select(fromIndexing.selectUploadMode);
  }

  // Indexing View getters
  public get showAdvancedMode$(): Observable<boolean> {
    return this.store.select(fromIndexing.selectAdvancedMode);
  }

  public get selectedAdvancedSettings$(): Observable<AdvancedSettings> {
    return this.store.select(fromIndexing.selectSelectedAdvancedSettings);
  }

  public get indexingMode$(): Observable<IndexingMode> {
    return this.store.select(fromIndexing.selectIndexingMode);
  }

  public get userConsent$(): Observable<boolean> {
    return this.store.select(fromIndexing.isUserConsent);
  }

  public get isIndexingProcessDirty$(): Observable<boolean> {
    return this.store.select(fromIndexing.selectIsIndexingProcessDirty);
  }

  // Upload Errors

  public get uploadErrorType$() {
    return this.store.select(fromIndexing.getUploadErrorType);
  }

  public get isEventGridError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.EVENT_GRID_NOT_REGISTERED));
  }

  public get isAmsAadAppNotInTenantError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT));
  }

  public get isAmsUnreachableError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.AMS_UNREACHABLE));
  }

  public get isStorageUnreachableError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.STORAGE_UNREACHABLE));
  }

  public get isStorageAccessDeniedError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.STORAGE_ACCESS_DENIED));
  }

  public get isManagedIdentityMissingError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.MANAGED_IDENTITY_MISSING));
  }

  public get isQuotaError$() {
    return this.uploadErrorType$.pipe(map(errorType => errorType === APIErrors.ACCOUNT_UPLOAD_QUOTA_EXCEEDED));
  }

  public get isBroadUploadError$() {
    return this.uploadErrorType$.pipe(
      map(
        errorType =>
          errorType === APIErrors.EVENT_GRID_NOT_REGISTERED ||
          errorType === APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT ||
          errorType === APIErrors.AMS_UNREACHABLE ||
          errorType === APIErrors.STORAGE_UNREACHABLE ||
          errorType === APIErrors.STORAGE_ACCESS_DENIED ||
          errorType === APIErrors.MANAGED_IDENTITY_MISSING
      )
    );
  }

  public get isAccountDurationQuota$() {
    return this.isQuotaError$.pipe(
      combineLatestWith(this.coreStoreService.getSelectedAccountQuota$),
      map(([isQuotaError, quota]) => isQuotaError && isAccountDurationQuotaExceeded(quota))
    );
  }

  public get isAccountDailyCountQuota$() {
    return this.isQuotaError$.pipe(
      combineLatestWith(this.coreStoreService.getSelectedAccountQuota$),
      map(([isQuotaError, quota]) => isQuotaError && isAccountDailyCountQuotaExceeded(quota))
    );
  }

  public get isAccountDailyDurationQuota$() {
    return this.isQuotaError$.pipe(
      combineLatestWith(this.coreStoreService.getSelectedAccountQuota$),
      map(([isQuotaError, quota]) => isQuotaError && isAccountDailyDurationQuotaExceeded(quota))
    );
  }

  public getFileUploadingError(fileId: string) {
    return this.store.select(fromIndexing.getFileUploadingErrorType(fileId));
  }

  public isVideoInProgressError(fileId: string) {
    return this.getFileUploadingError(fileId).pipe(map(errorType => errorType === APIErrors.VIDEO_ALREADY_IN_PROGRESS));
  }

  public isURLWasNotFileError$(fileId: string) {
    return this.getFileUploadingError(fileId).pipe(map(errorType => errorType === APIErrors.INVALID_INPUT));
  }

  // Re-Index Errors

  public get reIndexErrorType$() {
    return this.store.select(fromIndexing.selectReIndexErrorType);
  }

  public get isFileNotFoundError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.BREAKDOWN_NOT_FOUND));
  }

  public get isVideoUrlUnreachableError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === FailureCode.VIDEO_URL_UNREACHABLE));
  }

  public get isGeneralLoadingReIndexError$() {
    return this.reIndexErrorType$.pipe(
      combineLatestWith(this.store.select(fromIndexing.selectVideoId)),
      map(([errorType, videoId]) => !videoId && errorType === APIErrors.GENERAL)
    );
  }

  public get isGeneralReIndexError$() {
    return this.reIndexErrorType$.pipe(
      combineLatestWith(this.store.select(fromIndexing.selectVideoId)),
      map(([errorType, videoId]) => videoId && errorType === APIErrors.GENERAL)
    );
  }

  public get isFileWasRemovedError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.VIDEO_REMOVED));
  }

  public get isAmsUnreachableReIndexError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.AMS_UNREACHABLE));
  }

  public get isIndexingInProgressError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.VIDEO_INDEXING_IN_PROGRESS));
  }

  public get isInValidInput$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.INVALID_INPUT));
  }

  public get isFileInReviewError$() {
    return this.isInValidInput$.pipe(
      combineLatestWith(this.store.select(fromIndexing.selectModerationState)),
      combineLatestWith(this.store.select(fromIndexing.selectReviewState)),
      map(
        ([[isInvalidInputError, moderationState], reviewState]) =>
          isInvalidInputError && moderationState !== VideoModerationState.REMOVED && reviewState === VideoReviewState.IN_PROGRESS
      )
    );
  }

  public get isFileWasReportedError$() {
    return this.isInValidInput$.pipe(
      combineLatestWith(this.store.select(fromIndexing.selectModerationState)),
      map(([isInvalidInputError, moderationState]) => isInvalidInputError && moderationState === VideoModerationState.REMOVED)
    );
  }

  public get isAmsAadAppNotInTenantReIndexError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT));
  }

  public get isStorageAccessDeniedReIndexError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.STORAGE_ACCESS_DENIED));
  }

  public get isStorageUnreachableReIndexError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.STORAGE_UNREACHABLE));
  }

  public get isManageIdentityMissingReIndexError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.MANAGED_IDENTITY_MISSING));
  }

  public get isProjectRenderInProgressError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.PROJECT_RENDER_IN_PROGRESS));
  }

  public get isSourceVideoDeletedError$() {
    return this.reIndexErrorType$.pipe(map(errorType => errorType === APIErrors.SOURCE_VIDEO_DELETED));
  }

  // Dialogs
  public openUploadDialog() {
    this.store.dispatch(actions.openUploadDialog());
  }

  public openReIndexDialog(videoId: string) {
    this.store.dispatch(actions.openReIndexDialog({ videoId }));
  }

  // Indexing View Actions
  public toggleAdvancedMode(): void {
    this.store.dispatch(actions.toggleAdvancedMode());
  }

  public updateIndexingMode(mode: IndexingMode): void {
    this.store.dispatch(actions.updateIndexingMode({ mode }));
  }

  public updateSelectedAdvancedSettings(settings: AdvancedSettings): void {
    this.store.dispatch(actions.updateSelectedAdvancedSettings({ settings }));
  }

  // Upload Actions
  public uploadFiles(): void {
    this.store.dispatch(actions.uploadFiles());
  }

  public addUrlFile(file: IFile): void {
    this.store.dispatch(actions.addUrlFileForUpload({ file }));
  }

  public updateUploadPage(page: UploadPages): void {
    this.store.dispatch(actions.updateUploadPage({ page }));
  }

  public updateUploadMode(mode: UploadMode): void {
    this.store.dispatch(actions.updateUploadMode({ mode }));
  }

  public updateUseSavedIndexingSettings(use: boolean): void {
    this.store.dispatch(actions.updateUseSavedIndexingSettings({ use }));
  }

  public clearUrlValidationError(): void {
    this.store.dispatch(actions.updateUrlValidationError({ errorType: null }));
  }

  public updateUrlValidationLoading(loading: boolean): void {
    this.store.dispatch(actions.updateUrlValidationLoading({ loading }));
  }

  public updateUserConsent(consent: boolean) {
    this.store.dispatch(actions.updateUserConsent({ consent }));
  }

  // Re Index Actions
  public reIndex() {
    this.store.dispatch(actions.reIndexVideo());
  }

  public updateReIndexPage(page: ReIndexPages): void {
    this.store.dispatch(actions.updateReIndexPage({ page }));
  }

  // Re Index settings getters
  public get displayedReIndexPage$(): Observable<ReIndexPages> {
    return this.store.select(fromIndexing.selectReIndexDisplayedPage);
  }

  // Indexing Settings Actions
  public updateIndexingPreset(preset: IndexingPreset): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { indexingPreset: preset } }));
  }

  public updateExcludedRAI(exclude: boolean): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { excludeRAI: exclude } }));
  }

  public updateExcludeAIs(exclude: ExcludableAIsModels[]): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { excludeAIs: exclude } }));
  }

  public updateLanguageId(languageId: string) {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { languageId } }));
  }

  public updatePrivacy(privacy: PrivacyMode) {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { privacy } }));
  }

  public updateMetadata(metadata: string) {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { metadata } }));
  }

  public updateDescription(description: string) {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { description } }));
  }

  public updateStreamingPreset(streamingPreset: StreamingPreset) {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { streamingPreset } }));
  }

  public updatePeopleModel(model: string): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { peopleModelId: model } }));
  }

  public updateLogoGroup(groupId: string): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { logoGroupId: groupId } }));
  }

  public updateBrandCategory(brands: string): void {
    this.store.dispatch(actions.updateIndexingSettings({ settings: { brandsCategories: brands } }));
  }

  public loadSupportedAIs(): void {
    this.store.dispatch(actions.loadSupportedAIs());
  }

  public updateSupportedAIsState(supportedAIs): void {
    this.store.dispatch(actions.updateSupportedAIsState({ supportedAIs }));
  }

  // Files Actions
  public removeFile(fileId: string): void {
    this.store.dispatch(actions.removeFile({ fileId }));
  }

  public addFilesForUpload(files: IFile[]): void {
    this.store.dispatch(actions.addUploadFiles({ files }));
  }

  public updateFile(fileId: string, file: Partial<IFile>): void {
    this.store.dispatch(actions.updateFile({ fileId, file }));
  }
}
