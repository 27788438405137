import { Injectable, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';

import { ZoneWrapperService } from '@common/modules/core/services/zone-wrapper/zone-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FocusManagerService {
  constructor(public focusMonitor: FocusMonitor, private zone: ZoneWrapperService) {}

  public focusVia(preCallBack: () => ElementRef, cdr?: ChangeDetectorRef): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const focusElement: ElementRef = preCallBack();
        if (!focusElement || !focusElement.nativeElement) {
          return;
        }
        this.focusMonitor.focusVia(focusElement.nativeElement, 'keyboard');
        if (cdr) {
          cdr.detectChanges();
        }
      });
    });
  }

  public focusByQuery(query: string, cdr?: ChangeDetectorRef): void {
    // Get triggered element
    const triggeredElement = document.querySelector(query);

    // Focus triggered element
    this.focusVia(() => {
      return new ElementRef(triggeredElement);
    }, cdr);
  }
}
