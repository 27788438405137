import { Injectable } from '@angular/core';

import { AuthService } from '@common/modules/auth/services/auth.service';
import { DataService } from '@common/modules/shared/services/data.service';
import { humanizeFromNow } from '@common/modules/utils/time';

import { IPerson } from '../../../customization/components/people-table/interfaces';
import { CustomizationDataService } from '../customization-data.service';

@Injectable()
export class UnknownPersonService {
  constructor(private dataService: DataService, private authService: AuthService, private customizationDataService: CustomizationDataService) {}

  public getThumbnailUrl(accountId: string, videoId: string, thumbnailId: string): string {
    return this.dataService.getThumbnailUrl(accountId, videoId, thumbnailId);
  }

  public getThumbnailUrlWithToken(accountId: string, videoId: string, thumbnailId: string): string {
    const accessToken = this.authService.getAccessToken(null, accountId);
    const url = this.getThumbnailUrl(accountId, videoId, thumbnailId);

    return `${url}?accessToken=${accessToken}`;
  }

  public convertPersonContract(people: Microsoft.VideoIndexer.Contracts.UnknownPerson[], accountId: string, selectedPersonIds: string[]): IPerson[] {
    return people.map(person => {
      const duration = humanizeFromNow(person.lastUpdated);
      const dateValue = this.customizationDataService.getFromNowText(duration);
      return {
        id: person.id,
        name: person.name,
        score: person.score * 100 || 0,
        imageCount: person.imageCount,
        selected: selectedPersonIds.includes(person.id),
        lastUpdated: dateValue,
        url: this.getThumbnailUrlWithToken(accountId, person.bestFaceVideoId, person.bestFaceThumbnailId)
      };
    });
  }
}
