import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';
import { LanguageModelState } from '../../customization/interfaces';

export const getLanguageModelsState = createSelector(selectCoreState, state => state.languageModels);

export const getSupportedLanguagesState = createSelector(selectCoreState, state => state.supportedLanguages);

export const isLanguageModelsLoaded = createSelector(getLanguageModelsState, state => state.loaded);

export const isLanguageModelsLoading = createSelector(getLanguageModelsState, state => !state.loaded);

export const isLanguageModelsError = createSelector(getLanguageModelsState, state => state?.error);

export const getLanguageModels = createSelector(getLanguageModelsState, state => Object.values(state.entities));

export const getTrainedLanguageModels = createSelector(getLanguageModels, languageModels =>
  languageModels.filter(l => l.state === LanguageModelState.Complete)
);

export const getLanguageModelByLanguage = (language: string) =>
  createSelector(getLanguageModels, languages => languages.filter(l => l.language === language));

export const getLanguageModelIsExpanded = (modelId: string) =>
  createSelector(getLanguageModelsState, state => (state.entities[modelId] ? state.entities[modelId].isExpanded : true));

export const getLanguageModelIsSaving = (modelId: string) =>
  createSelector(getLanguageModelsState, state => (state.entities[modelId] ? state.entities[modelId].isCreating : false));

export const getLanguageModelIsTraining = (modelId: string) =>
  createSelector(getLanguageModelsState, state =>
    state.entities[modelId]
      ? state.entities[modelId].state === LanguageModelState.Waiting || state.entities[modelId].state === LanguageModelState.Processing
      : false
  );

export const getLanguageModelsIsTraining = (language: string) =>
  createSelector(
    getLanguageModelByLanguage(language),
    languages => languages.filter(l => l.state === LanguageModelState.Waiting || l.state === LanguageModelState.Processing).length
  );

export const getTrainedLanguageModelCount = (language: string) =>
  createSelector(getLanguageModelByLanguage(language), languages => languages.filter(l => l.state === LanguageModelState.Complete).length);

export const getSupportedLanguages = createSelector(getSupportedLanguagesState, state => Object.values(state.entities));

export const getSupportedLanguage = (key: string) => createSelector(getSupportedLanguagesState, state => state.entities[key]);

export const getSupportedLanguageIsSaving = (key: string) => createSelector(getSupportedLanguage(key), language => language?.isCreating);

export const getLanguageModel = (modelId: string) => createSelector(getLanguageModelsState, state => state.entities[modelId]);

export const getLanguageModelFiles = (modelId: string) => createSelector(getLanguageModel(modelId), language => language?.files || []);

export const getLanguageModelFile = (modelId: string, fileId: string) =>
  createSelector(getLanguageModelFiles(modelId), files => files.find(f => f.id === fileId));

export const getLanguageModelUploadingFiles = (modelId: string) =>
  createSelector(getLanguageModel(modelId), language => language?.uploadingFiles || []);

export const getLanguageModelIsTrained = (modelId: string) =>
  createSelector(getLanguageModel(modelId), language => language?.state === LanguageModelState.Complete);
