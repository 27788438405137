import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers } from './selectors/media.selectors';
import { MediaCommonEffects } from './effects/media-common.effects';
import { MediaCommonStoreService } from './services/media-common-store.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('media', reducers), EffectsModule.forFeature([MediaCommonEffects])],
  providers: [MediaCommonStoreService]
})
export class MediaDataCommonModule {}
