import { Injectable } from '@angular/core';

import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';

import { VIThemes } from '../interfaces';
import { EventCategory, TrackService } from '../track';
import { ZoneWrapperService } from '../zone-wrapper/zone-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {
  private _activeTheme = VIThemes.DEFAULT;

  constructor(private localStorageService: LocalStorageService, private zoneWrapperService: ZoneWrapperService, private trackService: TrackService) {}

  public get activeTheme() {
    return this._activeTheme;
  }

  public set activeTheme(theme: VIThemes) {
    if (theme === this._activeTheme) {
      return;
    }

    // remove currently active theme
    this.removeThemeClass(this._activeTheme);

    // add new theme
    this.addThemeClass(theme);

    // update local storage
    this.localStorageService.setItem('vi.theme', theme);
    this._activeTheme = theme;
    this.trackService.track('themes.update', {
      category: EventCategory.USER_SETTINGS,
      type: theme
    });
  }

  public initThemes(selectedTheme?: VIThemes) {
    let theme = VIThemes[this.localStorageService.getItem('vi.theme')?.toUpperCase()];
    theme = selectedTheme || theme || VIThemes.DEFAULT;

    this.addThemeClass(theme);

    // selectedTheme param is for widgets, should not effect the wep app.
    if (!selectedTheme) {
      // update local storage
      this.localStorageService.setItem('vi.theme', theme);
      this._activeTheme = theme;
    }

    this.trackService.track('themes.init', {
      category: EventCategory.USER_SETTINGS,
      type: this.activeTheme
    });
  }

  public addThemeClass(theme: VIThemes) {
    this.zoneWrapperService.runOutsideAngular(() => {
      window.document?.documentElement?.classList?.add(theme);
    });
  }

  public removeThemeClass(theme: VIThemes) {
    this.zoneWrapperService.runOutsideAngular(() => {
      window.document?.documentElement?.classList?.remove(theme);
    });
  }
}
