export const resources = {
  UploadDialogTitle: '',
  UploadRunInBackground: '',
  Cancel: '',
  Close: '',
  UploadAndIndexButton: '',
  UploadInvalidFilesMessage: '',
  ReviewAndUploadButton: '',
  IndexingBackToSettings: ''
};
