import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, EMPTY, catchError, mergeMap, tap, withLatestFrom, take } from 'rxjs';

import { guid } from '@common/modules/utils/string';
import { NotificationLevel } from '@common/modules/core/services/toast/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { IApiSasContract, IUploadRequestParams } from '@common/modules/api/interfaces';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import { INotification, NotificationType, NotificationIcon } from '@common/modules/core/services/toast/interfaces';
import { FeatureSwitch, NavigationState } from '@common/modules/core/services/interfaces';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { APIErrors, Errors } from '@common/modules/core/services/toast/errors';
import { UtilsService } from '@common/modules/shared/services/utils.service';

import { CoreStoreService } from './../../../core/services/core-store.service';
import { IFile, IndexingMode, UploadingState, UploadingStateError, UploadMode } from './../../interfaces';
import { IState } from './../reducers/index';
import { UploadDialogComponent } from '../../components/upload/upload-dialog/upload-dialog.component';
import * as actions from '../actions';
import * as fromIndexing from '../selectors';
import * as fromLanguageId from './../../../customization-data/selectors/language-id.selectors';
import * as fromCore from '../../../core/selectors';
import * as LibraryActions from '../../../gallery/core/actions/library.actions';
import { AzureBlobStorageService } from '../../services/azure-blob-storage.service';
import { UploadService } from '../../services/upload.service';
import { IFileBlob } from '../../interfaces';
import {
  getFSFileUploadBatchActions,
  getURLFileUploadBatchActions,
  getLanguageParam,
  getCustomLanguageModelParam,
  convertToUploadParams,
  UPLOAD_TO_BLOB_STORAGE_COMPLETED_PERCENTAGE,
  UPLOAD_PROCESS_FINISHED_PERCENTAGE,
  getExcludeSensitiveAIsParams,
  isUrlAlreadyAdded,
  MAX_FILE_SIZE_URL,
  getFileTraceData,
  getUploadSettingsTraceData,
  getCustomLanguagesParam,
  HTML_CONTENT_TYPE,
  getExcludeAIsParams
} from './utils/indexing-utils';
import {
  getUploadErrorType,
  getNotification,
  getFileErrorNotification,
  getQuotaExceededNotification,
  getUploadFilesFailedNotification,
  FILES_UPLOAD_FAILED_NOTIFICATION_TITLE,
  FILE_UPLOAD_FAILED_NOTIFICATION_TITLE
} from './utils/indexing-error-utils';
import { UIShellActionType } from '../../../shell/interfaces';
import { IndexingStoreService } from '../../services/indexing-store.service';
import { FileHelperService } from '../../services/file-helper.service';
import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';
import { getEdgeUploadBatchActions } from './utils/edge-indexing-utils';
@Injectable()
export class UploadEffects {
  public uploadDialogRef: MatDialogRef<DialogComponent>;
  public uploadProgressNotification: INotification;

  public openUploadDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.openUploadDialog),
      withLatestFrom(this.store.select(fromIndexing.isUploadInProgress)),
      switchMap(([, isUploadInProgress]) => {
        const consent = this.uploadService.hasUserConsent;

        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.NewUploadExperience)) {
          return EMPTY;
        }

        if (!isUploadInProgress) {
          this.store.dispatch(actions.initUploadDialogState({ indexingSettings: {} }));
          this.store.dispatch(actions.resetIndexingViewState());
          // Trigger init only when upload is not taken place in the background
          this.trackService.track('upload_dialog.init', { category: EventCategory.UPLOAD });
        }

        this.openUploadDialog();

        return [actions.updateUserConsent({ consent }), actions.updateIndexingMode({ mode: IndexingMode.Upload }), actions.loadSupportedAIs()];
      })
    )
  );

  public updateUserConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserConsent),
      switchMap(({ consent }) => {
        this.uploadService.updateUserConsent(consent);
        return [actions.updateUserConsentSuccess({ consent })];
      })
    )
  );

  public updateUseSavedIndexingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUseSavedIndexingSettings),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ use }, accountId]) => {
        const indexingSettings = use ? this.uploadService.getSavedIndexingSettings(accountId) : {};

        return [actions.initUploadDialogState({ indexingSettings }), actions.loadSupportedAIs()];
      })
    )
  );

  public uploadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadFiles),
      switchMap(() => {
        return [actions.initiateUploadFiles()];
      })
    )
  );

  public uploadFilesIterator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initiateUploadFiles, actions.uploadFileFinished),
      withLatestFrom(this.store.select(fromIndexing.selectUploadMode)),
      withLatestFrom(this.store.select(fromIndexing.getAllValidFiles)),
      switchMap(([[, mode], files]) => {
        let uploadBatchActions = [];
        if (files.length) {
          const idleFiles = files.filter(file => file.uploadingState === UploadingState.Idle);
          const inProgressFilesCount = files.filter(file => file.uploadingState === UploadingState.InProgress).length;
          if (idleFiles.length === files.length) {
            this.trackService.track('upload_dialog.upload_files.started', { category: EventCategory.UPLOAD, data: { filesCount: files.length } });
          }
          // if there are more files to upload, get the next batch of files to upload
          if (idleFiles.length) {
            if (this.hasSelectedEdgeExtension) {
              uploadBatchActions = getEdgeUploadBatchActions(idleFiles, inProgressFilesCount);
            } else if (mode === UploadMode.File) {
              uploadBatchActions = getFSFileUploadBatchActions(idleFiles, inProgressFilesCount);
            } else if (mode === UploadMode.URL) {
              // Upload Mode URL
              uploadBatchActions = getURLFileUploadBatchActions(idleFiles, inProgressFilesCount);
            }
          } else if (!inProgressFilesCount) {
            // All files have been uploaded / failed
            const failedUploads = files.filter(file => file.uploadingState === UploadingState.Failed).length;
            const successfulUploads = files.filter(file => file.uploadingState === UploadingState.Completed).length;
            this.trackService.track('upload_dialog.upload_files.finished', {
              category: EventCategory.UPLOAD,
              data: { filesCount: files.length, failedUploads, successfulUploads }
            });
            return [actions.uploadFilesFinished()];
          } else {
            // Max number of concurrent files are still being uploaded, do nothing
            return EMPTY;
          }
          return uploadBatchActions;
        }

        return EMPTY;
      })
    )
  );

  public getSasURL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getFileSas),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      mergeMap(([{ storeFile }, accountId]) => {
        if (!storeFile || !accountId) {
          return [actions.getFileSasFailed({ storeFile, uploadingStateError: UploadingStateError.GetSasInitFailed })];
        }
        this.trackService.track('upload_dialog.get_sas.started', {
          category: EventCategory.UPLOAD,
          data: getFileTraceData(storeFile)
        });
        const filename = storeFile.fileContent?.name || storeFile.name;
        return this.uploadService.getSas(accountId, filename, storeFile.size).pipe(
          mergeMap((sas: IApiSasContract) => {
            const file: IFileBlob = this.azureBlobService.generateFile(storeFile.fileContent);
            file.baseUrl = sas.baseUrl;
            file.sasToken = sas.sasToken;
            file.blobPath = sas.baseUrl + sas.sasToken;
            this.trackService.track('upload_dialog.get_sas.succeeded', {
              category: EventCategory.UPLOAD,
              data: getFileTraceData(storeFile)
            });
            return [actions.getFileSasSuccess({ blobFile: file, sas, storeFile })];
          }),
          catchError(error => {
            this.trackService.track('upload_dialog.get_sas.failed', {
              category: EventCategory.UPLOAD,
              data: { ...getFileTraceData(storeFile), error: error?.error }
            });
            return [actions.getFileSasFailed({ storeFile, uploadingStateError: UploadingStateError.GetSasFailed, error })];
          })
        );
      })
    )
  );

  public uploadToBlobStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getFileSasSuccess),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      mergeMap(([{ blobFile, sas, storeFile }, accountId]) => {
        if (!blobFile || !accountId || !sas || !storeFile) {
          return [actions.uploadFileToBlobStorageFailed({ storeFile, uploadingStateError: UploadingStateError.BlobStorageInitFailed })];
        }
        this.trackService.track('upload_dialog.upload_to_blob_storage.started', {
          category: EventCategory.UPLOAD,
          data: getFileTraceData(storeFile)
        });
        return this.azureBlobService.uploadToBlobStorage(blobFile, sas).pipe(
          mergeMap((event: HttpEvent<object>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                // Update progress up to 99% for the azure blob upload phase
                return [
                  actions.updateFile({
                    fileId: storeFile.id,
                    file: { progress: Math.floor((event.loaded / event.total) * UPLOAD_TO_BLOB_STORAGE_COMPLETED_PERCENTAGE) }
                  }),
                  actions.uploadingFilesInProgress()
                ];
              case HttpEventType.Response:
                this.trackService.track('upload_dialog.upload_to_blob_storage.succeeded', {
                  category: EventCategory.UPLOAD,
                  data: getFileTraceData(storeFile)
                });
                return [actions.uploadFileToBlobStorageSuccess({ storeFile, fileUrl: blobFile.blobPath })];
              default:
                return EMPTY;
            }
          }),
          catchError(error => {
            this.trackService.track('upload_dialog.upload_to_blob_storage.failed', {
              category: EventCategory.UPLOAD,
              data: { ...getFileTraceData(storeFile), error }
            });
            return [actions.uploadFileToBlobStorageFailed({ storeFile, uploadingStateError: UploadingStateError.BlobStorageFailed, error })];
          })
        );
      })
    )
  );

  public uploadFileToBlobStorageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadFileToBlobStorageSuccess),
      mergeMap(({ fileUrl, storeFile }) => {
        return [actions.uploadFileToServer({ storeFile, fileUrl })];
      })
    )
  );

  public uploadFilesToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadFileToServer),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingSettings)),
      withLatestFrom(this.store.select(fromIndexing.getLanguage)),
      withLatestFrom(this.store.select(fromLanguageId.selectSelectedLanguagesKeys)),
      mergeMap(([[[[{ storeFile, fileUrl, contentMD5 }, accountId], indexingSettings], language], selectSelectedLanguagesKeys]) => {
        if (!fileUrl || !accountId || !indexingSettings || !language || !storeFile) {
          return [actions.uploadFileToServerFailed({ storeFile, uploadingStateError: UploadingStateError.UploadToServerInitFailed })];
        }

        const isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);

        const languageSettings = getLanguageParam(language);
        const customLanguagesSettings = getCustomLanguagesParam(language, selectSelectedLanguagesKeys);
        const languageModelSettings = getCustomLanguageModelParam(language);
        const excludeAIParams = isExcludeAIsEnabled
          ? getExcludeAIsParams(indexingSettings.excludeAIs)
          : getExcludeSensitiveAIsParams(indexingSettings);
        const uploadSettings: IUploadRequestParams = convertToUploadParams(
          accountId,
          indexingSettings,
          languageSettings,
          languageModelSettings,
          storeFile,
          fileUrl,
          customLanguagesSettings
        );

        this.uploadService.setSavedIndexingSettings(accountId, {
          brandsCategories: indexingSettings.brandsCategories,
          indexingPreset: indexingSettings.indexingPreset,
          excludeAIs: indexingSettings.excludeAIs,
          languageId: indexingSettings.languageId,
          peopleModelId: indexingSettings.peopleModelId,
          privacy: indexingSettings.privacy,
          streamingPreset: indexingSettings.streamingPreset,
          excludeRAI: indexingSettings.excludeRAI,
          logoGroupId: indexingSettings.logoGroupId
        });

        this.trackService.track('upload_dialog.upload_file_to_server.started', {
          category: EventCategory.UPLOAD,
          data: { ...getFileTraceData(storeFile), ...getUploadSettingsTraceData(uploadSettings), excludeAIParams }
        });
        return this.uploadService.uploadToServer(accountId, uploadSettings, contentMD5, excludeAIParams).pipe(
          mergeMap((res: Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2) => {
            this.trackService.track('upload_dialog.upload_file_to_server.succeeded', {
              category: EventCategory.UPLOAD,
              data: getFileTraceData(storeFile)
            });
            const fileUpdates: Partial<IFile> = { uploadingState: UploadingState.Completed, progress: UPLOAD_PROCESS_FINISHED_PERCENTAGE };
            return [
              actions.updateFile({
                fileId: storeFile.id,
                file: fileUpdates
              }),
              actions.uploadFileFinished({ file: { ...storeFile, ...fileUpdates } }),
              LibraryActions.loadVideo({ id: res.id })
            ];
          }),
          catchError(error => {
            this.trackService.track('upload_dialog.upload_file_to_server.failed', {
              category: EventCategory.UPLOAD,
              data: { ...getFileTraceData(storeFile), error: error?.error }
            });
            return [actions.uploadFileToServerFailed({ storeFile, uploadingStateError: UploadingStateError.UploadToServerFailed, error })];
          })
        );
      })
    )
  );

  public onUploadFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getFileSasFailed, actions.uploadFileToBlobStorageFailed, actions.uploadFileToServerFailed, actions.uploadFileToEdgeFailed),
      mergeMap(({ storeFile, uploadingStateError, error }) => {
        const uploadingErrorType = getUploadErrorType(error);
        const notificationAction = [];
        // Exceed quota has it's own toast effect
        if (uploadingErrorType !== APIErrors.ACCOUNT_UPLOAD_QUOTA_EXCEEDED) {
          notificationAction.push(actions.showUploadFailedToast({ file: storeFile, errorType: uploadingErrorType }));
        }
        return [
          actions.updateFile({
            fileId: storeFile.id,
            file: {
              uploadingState: UploadingState.Failed,
              uploadingStateError,
              progress: UPLOAD_PROCESS_FINISHED_PERCENTAGE,
              uploadingErrorType
            }
          }),
          actions.uploadFileFinished({ file: storeFile }),
          ...notificationAction
        ];
      })
    )
  );

  public addFilesForUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addUploadFiles),
      withLatestFrom(this.store.select(fromIndexing.selectedFilesForUploadCount)),
      switchMap(([{ files: newFiles }, existingFilesCount]) => {
        // Take the top new files that are not exceeding the limit
        let topNewFiles = newFiles.slice(0, this.fileHelperService.maxVideosLimit - existingFilesCount);
        if (!topNewFiles.length) {
          return EMPTY;
        }
        topNewFiles = topNewFiles.map(file => ({ ...file, uploadingState: UploadingState.Idle }));

        return [actions.addUploadFilesSuccess({ files: topNewFiles })];
      })
    )
  );

  public addUrlFileForUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addUrlFileForUpload),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromIndexing.getAllValidFiles)),
      switchMap(([[{ file }, accountId], files]) => {
        this.trackService.track('upload_dialog.add_url.started', {
          category: EventCategory.UPLOAD
        });
        if (!accountId || !file.url) {
          return EMPTY;
        }

        if (isUrlAlreadyAdded(file.url, files)) {
          return [actions.urlValidationFailed({ errorType: APIErrors.ALREADY_EXISTS })];
        }

        return this.uploadService.getVideoInfo(accountId, file.url).pipe(
          switchMap(res => {
            // https://learn.microsoft.com/en-us/rest/api/storageservices/get-blob?tabs=azure-ad#request-headers
            // This header is returned only for blob storage URLs and if the blob has an MD5 hash.
            const contentMD5 = res && res.headers.has('Content-MD5') ? res.headers.get('Content-MD5') : undefined;
            const contentType = res?.body?.contentType;
            file = { ...file, size: res?.body?.length || 0 };
            // Check URL content size
            if (file.size > MAX_FILE_SIZE_URL) {
              this.trackService.track('upload_dialog.add_url.failed', {
                category: EventCategory.UPLOAD,
                data: { error: Errors.VIDEO_UPLOAD_LIMIT_EXCEEDED }
              });
              return [actions.urlValidationFailed({ errorType: APIErrors.VIDEO_UPLOAD_LIMIT_EXCEEDED })];
            }
            // Check URL content type doesn't contain HTML
            if (contentType === HTML_CONTENT_TYPE) {
              return [actions.urlValidationFailed({ errorType: APIErrors.URL_UNREACHABLE })];
            }

            this.trackService.track('upload_dialog.add_url.succeeded', {
              category: EventCategory.UPLOAD
            });
            return [actions.getVideoInfoSuccess({ file, contentMD5 })];
          }),
          catchError(error => {
            this.trackService.track('upload_dialog.add_url.failed', {
              category: EventCategory.UPLOAD,
              data: { error: error?.error }
            });
            return [actions.getVideoInfoFailed({ errorType: error?.error?.ErrorType })];
          })
        );
      })
    )
  );

  public getVideoInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getVideoInfoSuccess),
      switchMap(({ file, contentMD5 }) => {
        const updatedFile = { ...file, contentMD5 };
        return [actions.addUploadFiles({ files: [updatedFile] })];
      })
    )
  );

  public createUploadProgressNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadDialogClosed),
        withLatestFrom(this.store.select(fromIndexing.getInProgressFiles)),
        withLatestFrom(this.store.select(fromIndexing.getAllValidUploadFiles)),
        tap(([[, inProgressFiles], allValidFiles]) => {
          // no need notification in case there is no longer progressing files or the notification is already created
          if (!inProgressFiles?.length || !!this.uploadProgressNotification) {
            return EMPTY;
          }
          const notificationTitle =
            allValidFiles.length > 1
              ? this.translate.instant('UploadingNumberFiles', { number: allValidFiles.length })
              : this.translate.instant('UploadingFileName', { fileName: allValidFiles[0].name });
          const id = `uploadingProgress_${guid()}`;
          const link = {
            text: this.translate.instant('SeeDetails'),
            callback: () => {
              this.openUploadDialog();
            }
          };
          this.uploadProgressNotification = getNotification(
            id,
            notificationTitle,
            NotificationType.Message,
            NotificationLevel.Info,
            NotificationIcon.Upload,
            true,
            link
          );
        })
      ),
    {
      dispatch: false
    }
  );

  public showSingleFileUploadedToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadFileFinished),
        tap(({ file }) => {
          // Show uploaded toast only for completed file and when the upload dialog is closed
          if (!file || file.uploadingState !== UploadingState.Completed || this.uploadDialogRef.getState() !== MatDialogState.CLOSED) {
            return EMPTY;
          }
          const id = `${file.id}-uploaded`;
          const title = this.translate.instant('FileNameUploaded', { fileName: file.name });
          const toast = getNotification(id, title, NotificationType.Toast, NotificationLevel.Success, NotificationIcon.Success, false);
          this.notificationService.notify(toast);
        })
      ),
    {
      dispatch: false
    }
  );

  public showSingleFileUploadFailedToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.showUploadFailedToast),
        tap(({ file, errorType }) => {
          const title = this.translate.instant(FILE_UPLOAD_FAILED_NOTIFICATION_TITLE, { filename: file.name });
          const toasted = this.uploadDialogRef.getState() !== MatDialogState.CLOSED;
          const notification = getFileErrorNotification(errorType, title, toasted);
          if (notification) {
            notification.text = this.translate.instant(notification.text, { filename: file.name });
            this.notificationService.notify(notification);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public showSingleFileUploadQuotaExceededToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateAccountQuotaOnUploadFailure),
        withLatestFrom(this.coreStoreService.getSelectedAccountQuota$),
        tap(([{ file }, quota]) => {
          if (!quota) {
            return;
          }

          const title = this.translate.instant(FILE_UPLOAD_FAILED_NOTIFICATION_TITLE, { filename: file.name });
          const toasted = this.uploadDialogRef.getState() !== MatDialogState.CLOSED;
          const notification = getQuotaExceededNotification(quota, title, toasted);

          if (notification) {
            notification.text = this.translate.instant(notification.text);
            this.notificationService.notify(notification);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public showMultiFilesUploadedToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadFilesFinished),
        withLatestFrom(this.store.select(fromIndexing.isMultiFileMode)),
        withLatestFrom(this.store.select(fromIndexing.getAllCompletedFiles)),
        tap(([[, isMultiFileMode], completedFiles]) => {
          // Show all files uploaded toast only in multi mode and when the upload dialog is closed
          if (!isMultiFileMode || this.uploadDialogRef.getState() !== MatDialogState.CLOSED) {
            return EMPTY;
          }
          const id = `${guid()}-all-files-uploaded`;
          const title = this.translate.instant('NumberFilesUploaded', { number: completedFiles.length });
          const toast = getNotification(id, title, NotificationType.Toast, NotificationLevel.Success, NotificationIcon.Success, false);
          this.notificationService.notify(toast);
        })
      ),
    {
      dispatch: false
    }
  );
  public showUploadFilesErrorNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadFilesFinished),
        withLatestFrom(this.indexingStore.isBroadUploadError$),
        withLatestFrom(this.indexingStore.uploadErrorType$),
        withLatestFrom(this.coreStoreService.selectedAccountContract$),
        tap(([[[, isBroadUploadError], uploadErrorType], account]) => {
          // Show all files failed toast only when a broad error occurred
          if (!isBroadUploadError) {
            return;
          }
          const title = this.translate.instant(FILES_UPLOAD_FAILED_NOTIFICATION_TITLE);
          const toasted = this.uploadDialogRef.getState() !== MatDialogState.CLOSED;
          const notification = getUploadFilesFailedNotification(uploadErrorType, title, toasted);
          notification.text = this.translate.instant(notification.text);
          if (notification.link) {
            notification.link.text = this.translate.instant(notification.link.text);
            notification.link.src = this.utilsService.getViResourceOverviewPortalUrl(account?.tenantId, account?.resourceId);
          }
          this.notificationService.notify(notification);
        })
      ),
    {
      dispatch: false
    }
  );

  public updateUploadProgressNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadingFilesInProgress),
        withLatestFrom(this.store.select(fromIndexing.getTotalUploadProgress)),
        tap(([, progress]) => {
          if (!this.uploadProgressNotification) {
            return EMPTY;
          }
          this.notificationService.notify({ ...this.uploadProgressNotification, progress: progress });
        })
      ),
    {
      dispatch: false
    }
  );

  public clearUploadProgressNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.uploadFilesFinished),
        tap(() => {
          if (!this.uploadProgressNotification) {
            return EMPTY;
          }
          this.notificationService.clear([this.uploadProgressNotification.id]);
          this.uploadProgressNotification = null;
        })
      ),
    {
      dispatch: false
    }
  );

  public showIndexingStartedToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadFileFinished),
      withLatestFrom(this.store.select(fromIndexing.isMultiFileMode)),
      withLatestFrom(this.store.select(fromIndexing.getAllCompletedFiles)),
      switchMap(([[{ file }, isMultiFileMode], completedFiles]) => {
        // Show toast only when the upload dialog is closed
        if (this.uploadDialogRef.getState() !== MatDialogState.CLOSED) {
          return EMPTY;
        }
        // For multi file mode show start indexing only when the first file has been uploaded
        if (isMultiFileMode) {
          if (completedFiles.length > 1) {
            return EMPTY;
          } else {
            return [actions.showIndexingStartedForSingleToast({ file })];
          }
        }
        return [actions.showIndexingStartedForMultiToast({ numberFiles: completedFiles.length })];
      })
    )
  );

  public showIndexingStartedForSingleToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.showIndexingStartedForSingleToast),
        tap(({ file }) => {
          const id = `${file.id}-start-indexing`;
          const title = this.translate.instant('IndexingStartedForFile', { fileName: file.name });
          const toast = getNotification(id, title, NotificationType.Toast, NotificationLevel.Info, NotificationIcon.Upload, false);
          this.notificationService.notify(toast);
        })
      ),
    {
      dispatch: false
    }
  );

  public showIndexingStartedForMultiToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.showIndexingStartedForMultiToast),
        tap(({ numberFiles }) => {
          if (numberFiles === 0) {
            return;
          }
          const id = `${guid()}-start-indexing-files`;
          const title = this.translate.instant('IndexingStartedForNumberFiles', { number: numberFiles });
          const toast = getNotification(id, title, NotificationType.Toast, NotificationLevel.Info, NotificationIcon.Upload, false);
          this.notificationService.notify(toast);
        })
      ),
    {
      dispatch: false
    }
  );

  private hasSelectedEdgeExtension: boolean = false;
  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private dialogService: DialogService,
    private featureSwitchService: FeatureSwitchService,
    private translate: TranslateHelperService,
    private coreStoreService: CoreStoreService,
    private store: Store<IState>,
    private uploadService: UploadService,
    private azureBlobService: AzureBlobStorageService,
    private notificationService: NotificationsService,
    private trackService: TrackService,
    private navigationService: AppNavigationService,
    private indexingStore: IndexingStoreService,
    private utilsService: UtilsService,
    private fileHelperService: FileHelperService,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {
    this.init();
  }

  private init() {
    this.logger.log('[UploadEffects] On Init Effects');

    this.edgeExtensionsStore.hasSelectedEdgeExtension$.subscribe((hasSelectedEdgeExtension: boolean) => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
    });
  }

  private openUploadDialog() {
    const uploadDialogData: IDialogData = {
      class: 'upload-dialog',
      component: UploadDialogComponent,
      componentData: {},
      hideHeader: true
    };

    const dialogWidth = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs) ? '980px' : '880px';

    this.uploadDialogRef = this.dialogService.openDialog(uploadDialogData, dialogWidth, 'auto', 'upload-dialog-container', true, true, '740px', true);
    this.initDialogCloseEvent();

    this.uploadDialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(actions.uploadDialogClosed());
    });
  }

  private initDialogCloseEvent() {
    this.uploadDialogRef.componentInstance.actionChange.pipe(take(1)).subscribe(event => {
      this.trackService.track('upload_dialog.closed', {
        category: EventCategory.UPLOAD
      });
      if (event.dialogEventData?.customizationPageToNavigate) {
        this.coreStoreService.navigateToCustomization(event.dialogEventData.customizationPageToNavigate);
        this.trackService.track('upload_dialog.navigate', {
          category: EventCategory.UPLOAD,
          data: { page: event.dialogEventData.customizationPageToNavigate }
        });
      }

      if (event.dialogEventData?.navigateToCreateAccount) {
        this.navigationService.createAccountSubject.next(NavigationState.OPEN);
        this.trackService.track('upload_dialog.navigate', {
          category: EventCategory.UPLOAD,
          data: { page: UIShellActionType.CREATE_ACCOUNT }
        });
      }
    });
  }
}
