import { Injectable } from '@angular/core';

import { Observer, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenScope, IAmILoggedInResult, IArmAccountExtensionAccessTokenPayload } from '@common/modules/api/interfaces';
import { SamplesAccountId } from '@common/modules/core/interfaces';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { guid } from '@common/modules/utils/string';

import { ApiService } from '../../api/services/api.service';
import { UrlService } from '../../core/services/url/url.service';
import { CacheService } from './cache.service';
import { ConfigService } from '../../core/services/config/config.service';
import { FeatureSwitch, IConfiguration } from '../../core/services/interfaces';
import { LoggerService } from '../../core/services/logger/logger.service';
import { environment } from '../../../../environments/environment';
import { localhostClientID, appClientID, IAuthenticatedUser, AccountResourceType, UserType } from '../interfaces';
import { AuthenticationContext } from '../authenticationContext';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { EventCategory, TrackService } from '../../core/services/track';
import { allowEditToPermission, extractPermissionFromAccessToken, getTokenScope, isJwtTokenExpired } from '../utils/auth.utils';

@Injectable()
export class AuthService {
  public AuthenticationContext: AuthenticationContext;
  public accountAccessTokenPermission$ = new Subject<AccountPermission>();
  public videoAccessTokenPermission$ = new Subject<AccountPermission>();
  private lastAccountIdSavedPermission: string;
  private providers = {
    MicrosoftCorpAad: 'OpenIdConnect',
    Google: 'Google',
    Microsoft: 'Microsoft',
    LinkedIn: 'LinkedIn',
    Facebook: 'Facebook',
    OpenIdConnect: 'OpenIdConnect'
  };
  private config: IConfiguration;
  private accountIds: string[] = [];
  private readonly authenticationConfig = {
    clientId: environment?.localhost ? localhostClientID : appClientID,
    localLoginUrl: '/Account/SignIn',
    instance: environment?.localhost ? 'http://localhost:4100' : window.location.origin,
    loadFrameTimeout: 6000,
    endpoints: [{ 'https://localhost:44303/': 'https://localhost:44303/' }, { 'http://localhost:4100/': 'http://localhost:4100/' }],
    tenantId: 'common'
  };

  private isUserAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private authUser: IAuthenticatedUser = {
    upn: '',
    name: '',
    id: '',
    type: '',
    isAdmin: false,
    isSuspended: false
  };

  private userAccount: Microsoft.VideoIndexer.Contracts.AccountContractSlim;
  private edgeExtensionId: string;

  public set AccountIds(accountIds: string[]) {
    this.accountIds = accountIds;
  }

  public set UserAccount(account: Microsoft.VideoIndexer.Contracts.AccountContractSlim) {
    this.userAccount = account;
  }

  public set SelectedEdgeExtensionId(id: string) {
    this.edgeExtensionId = id;
  }

  public get SelectedEdgeExtensionId() {
    return this.edgeExtensionId;
  }

  public get enableEdgeExtensionTokens() {
    return this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeTokens);
  }

  public get defaultAccountId() {
    return this.accountIds?.length > 0 ? this.accountIds[0] : guid(0);
  }

  constructor(
    private logger: LoggerService,
    private apiService: ApiService,
    private cacheService: CacheService,
    private urlUtility: UrlService,
    private configService: ConfigService,
    private localStorageService: LocalStorageService,
    private trackService: TrackService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.init();
  }

  public get isSuspended(): boolean {
    return this.authenticatedUser?.isSuspended;
  }

  public get isAdmin(): boolean {
    return this.authenticatedUser?.isSuspended;
  }

  public get authenticatedUserId() {
    return this.authenticatedUser?.id;
  }

  public get authenticatedUpn(): string {
    return this.authenticatedUser?.upn;
  }

  public get authenticatedUserName() {
    return this.authenticatedUser?.name;
  }

  public get authenticatedUserType() {
    return this.authenticatedUser?.type;
  }

  public get authenticatedUser() {
    return this.authUser;
  }

  public get isArmAccount() {
    return this.userAccount?.resourceType === AccountResourceType.ARM;
  }

  public set authenticatedUser(user: IAuthenticatedUser) {
    this.authUser = user;
    this.initAuthenticationContext();
    this.saveLastProvider();
    this.login();
  }

  public login() {
    this.isUserAuthenticatedSubject.next(true);
  }

  public logout() {
    this.isUserAuthenticatedSubject.next(false);

    this.cacheService.removeAllAccessTokens();
  }

  public isLocal(): boolean {
    return this.config?.local;
  }

  public isAuthenticated(): boolean {
    return this.isUserAuthenticatedSubject.value;
  }

  public clearCache(): void {
    this.cacheService.removeAll();
  }

  public clearSession() {
    this.logout();
  }

  public getAccessTokenApi(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    reqSettings?: object,
    isExtensionRequest = false,
    extendExtensionToken = false,
    extensionTokenLifetimeInSeconds?: number
  ): Observable<string> {
    // If embed mode take url token (if url token is empty - may be public)
    if (this.isEmbedMode()) {
      return this.getAccessTokenFromUrlAsync(id, accountId, allowEdit);
    }

    const scope = getTokenScope(id, accountId, isProject);
    // User Token should remain vi token, also samples account should use classic tokens
    if (this.isArmAccount && scope !== TokenScope.USER && accountId !== SamplesAccountId) {
      return this.getArmAccessTokenApi(
        id,
        accountId,
        allowEdit,
        isProject,
        permission,
        reqSettings,
        isExtensionRequest,
        extendExtensionToken,
        extensionTokenLifetimeInSeconds
      );
    } else {
      return this.getClassicAccessTokenApi(id, accountId, allowEdit, isProject, permission, reqSettings);
    }
  }

  public getAccessTokenFromUrlAsync(videoId: string, accountId: string, allowEdit = false, isProject = false) {
    return new Observable((obs: Observer<string>) => {
      const urlToken = this.tryGetAccessTokenFromUrl();
      this.setAccessToken(urlToken, videoId, accountId, allowEdit, isProject);
      obs.next(urlToken);
    });
  }

  public isCorp(): boolean {
    return ['Microsoft', 'Google', 'Facebook'].indexOf(this.authenticatedUserType) === -1;
  }

  public isAad(): boolean {
    return this.authenticatedUserType === UserType.MicrosoftCorpAad;
  }

  public user(): object {
    return {
      Email: this.authenticatedUpn,
      Id: this.authenticatedUserId,
      FullName: this.authenticatedUserName,
      Type: this.authenticatedUserType
    };
  }

  public setAccessToken(
    newToken: string,
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    isExtensionRequest = false
  ): void {
    const cacheKey = this.getAccessTokenCacheKey(id, accountId, allowEdit, isProject, permission, isExtensionRequest);
    return this.cacheService.set(cacheKey, newToken);
  }

  public tryGetAccessTokenFromUrl(): string {
    return this.urlUtility.getQueryParam('accessToken') || '';
  }

  public getPermissionFromURLToken() {
    return AccountPermission.CONTRIBUTOR;
  }

  public getAccessToken(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    isExtensionRequest = false
  ): string {
    const urlToken = this.tryGetAccessTokenFromUrl();
    // If embed mode take url token (if url token is empty - may be public)
    if (this.isEmbedMode()) {
      return urlToken;
    } else {
      const allowEditParam = !allowEdit ? '' : `?allowEdit=true`;
      const cacheKey = this.getAccessTokenCacheKey(id, accountId, allowEdit, isProject, permission, isExtensionRequest);

      if (!this.isAuthenticated() && !this.isEmbedMode() && !(cacheKey as string).includes('/users/me/')) {
        return '';
      }

      const cacheValue = this.cacheService.get(cacheKey);

      if (typeof cacheValue === 'string' && cacheValue) {
        if (!isJwtTokenExpired(cacheValue)) {
          if (accountId && !id && accountId !== this.lastAccountIdSavedPermission && accountId !== SamplesAccountId) {
            this.setAccountAccessTokenPermission(cacheValue);
            this.lastAccountIdSavedPermission = accountId;
          } else if (accountId && id) {
            this.setVideoAccessTokenPermission(cacheValue);
          }
          return cacheValue;
        }
      }

      if (
        // If it is not a 'me' request
        (!(cacheKey as string).includes('/users/me') && !allowEdit && cacheValue === '') ||
        // if account isn't in user accounts list - for public videos
        (accountId &&
          accountId !== SamplesAccountId &&
          this.accountIds.length &&
          !this.accountIds.includes(accountId) &&
          this.userAccount?.id !== accountId)
      ) {
        return '';
      }

      return `refresh${allowEditParam}`;
    }
  }

  public removeAccessToken(id?: string, accountId?: string, allowEdit: boolean = false, isProject = false, permission?: AccountPermission) {
    const cacheKey = this.getAccessTokenCacheKey(id, accountId, allowEdit, isProject, permission);
    this.cacheService.removeItem(cacheKey);
  }

  public getAccessTokenCacheKey(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    isExtensionRequest = false
  ) {
    if (
      this.userAccount?.resourceType === AccountResourceType.ARM &&
      accountId &&
      accountId !== SamplesAccountId &&
      this.accountIds.includes(accountId)
    ) {
      return this.getArmAccessTokenCacheKey(
        id,
        this.userAccount.name,
        getTokenScope(id, accountId, isProject),
        permission ?? allowEditToPermission(allowEdit),
        isExtensionRequest
      );
    } else {
      return this.getClassicAccessTokenCacheKey(id, accountId, allowEdit, isProject, permission);
    }
  }

  public getClassicAccessTokenCacheKey(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission
  ) {
    const allowEditParam = !allowEdit ? '' : `?allowEdit=true`;

    switch (getTokenScope(id, accountId, isProject)) {
      case TokenScope.USER: {
        return `${this.apiService.getAuthApiBase(true)}/users/me/accessToken${allowEditParam}`;
      }
      case TokenScope.ACCOUNT: {
        const permissionValue = permission || allowEditToPermission(allowEdit);
        const permissionParam = permissionValue ? `?permission=${permissionValue}` : '';
        return `${this.apiService.getAuthApiBase(true)}/accounts/${accountId}/accessToken${permissionParam}`;
      }
      case TokenScope.VIDEO: {
        return `${this.apiService.getAuthApiBase(true)}/accounts/${accountId}/videos/${id}/accessToken${allowEditParam}`;
      }
      case TokenScope.PROJECT: {
        return `${this.apiService.getAuthApiBase(true)}/accounts/${accountId}/projects/${id}/accessToken${allowEditParam}`;
      }
      default: {
        break;
      }
    }
  }

  public getArmAccessTokenCacheKey(id: string, accountId: string, scope: TokenScope, permission: AccountPermission, isExtensionRequest = false) {
    switch (scope) {
      case TokenScope.ACCOUNT: {
        return `${this.getArmAccessTokenPrefix(accountId, isExtensionRequest)}/accessToken?permission=${permission}&scope=${scope}`;
      }
      case TokenScope.VIDEO: {
        return `${this.getArmAccessTokenPrefix(accountId, isExtensionRequest)}/videos/${id}/accessToken?permission=${permission}&scope=${scope}`;
      }
      case TokenScope.PROJECT: {
        return `${this.getArmAccessTokenPrefix(accountId, isExtensionRequest)}/projects/${id}/accessToken?permission=${permission}&scope=${scope}`;
      }
      default: {
        break;
      }
    }
    return `${this.getArmAccessTokenPrefix(accountId, isExtensionRequest)}/accessToken?permission=${permission}&scope=${scope}`;
  }

  public getUserAccessTokenOrEmptyString(videoId?: string, accountId?: string, allowEdit: boolean = false) {
    let accessToken = '';
    if (videoId && accountId) {
      accessToken = this.getAccessToken(videoId, accountId, allowEdit);
      if (accessToken.includes('refresh')) {
        accessToken = this.getAccessToken(videoId, accountId, !allowEdit);
      }
    } else if (accountId) {
      accessToken = this.getAccessToken(null, accountId, allowEdit);
      if (accessToken.includes('refresh')) {
        accessToken = this.getAccessToken(null, accountId, !allowEdit);
      }
    } else {
      accessToken = this.getAccessToken(null, null, allowEdit);
      if (accessToken.includes('refresh')) {
        accessToken = this.getAccessToken(null, null, !allowEdit);
      }
    }

    return !accessToken.includes('refresh') ? accessToken : '';
  }

  public getAccountsTokensAsync(allowEdit = true) {
    return this.apiService.Account.getAccounts({ refresh: true }, allowEdit);
  }

  public tryGetLastProvider(): string {
    const lastProvider = this.localStorageService.getItem('lastProvider');

    if (!this.isAuthenticated() || !lastProvider) {
      return '';
    }

    return this.providers[lastProvider];
  }

  public saveLastProvider(): void {
    if (this.isAuthenticated() && this.authenticatedUserType) {
      this.localStorageService.setItem('lastProvider', this.authenticatedUserType);
    }
  }

  public refreshSession(): Observable<string> {
    this.logger.log('[AuthService] try refresh session');
    return new Observable((obs: Observer<string>) => {
      const lastProvider = this.tryGetLastProvider() || this.providers[this.authenticatedUserType];
      this.logger.log('[AuthService] TryRefreshSession acquireTokenSilent');
      if (!lastProvider) {
        this.logger.log('[AuthService] TryRefreshSession no lastProvider');
        this.trackService.track('auth.session_refresh.no_provider');
        this.logout();
        obs.error('no provider');
      } else {
        this.AuthenticationContext.acquireToken(lastProvider, (msg, token) => {
          this.AuthenticationContext.removeAdalFrame('adalRenewFrame' + lastProvider);
          this.AuthenticationContext = new AuthenticationContext(this.authenticationConfig);
          this.trackService.track('auth.session_refresh.success', { provider: lastProvider });
          obs.next(lastProvider);
        });
      }
    });
  }

  public isEmbedMode() {
    const path = window.location.pathname;
    const regex = new RegExp(/embed/i);

    return regex.test(path);
  }

  public isRouteAuthenticated(): boolean {
    // TO-DO: Add user info check.
    // Can add permissions as part of the token
    // At the moment it will stay static. Editor and Customization will be defaultly disable
    return false;
  }

  public isUserAuthenticated(): Observable<boolean> {
    return this.isUserAuthenticatedSubject?.asObservable();
  }

  public checkIfUserAuthenticated(): Observable<boolean> {
    return new Observable(obs => {
      // eslint-disable-next-line deprecation/deprecation
      this.apiService.User.amILoggedIn().subscribe(
        (res: IAmILoggedInResult) => {
          this.trackService.track('auth.check_user_authenticated.am_i_logged_in.success', {
            isUserLoggedIn: !!res?.result
          });
          this.isUserAuthenticatedSubject.next(!!res?.result);
          obs.next(!!res?.result);
        },
        error => {
          this.trackService.track('auth.check_user_authenticated.am_i_logged_in.failed', {
            category: EventCategory.AUTH
          });
          obs.error(error);
        }
      );
    });
  }

  public get enableRestrictedViewer() {
    return this.featureSwitchService.featureSwitch(FeatureSwitch.RestrictedViewer);
  }

  private getArmAccessTokenPrefix(accountId: string, isExtensionRequest = false) {
    if (this.edgeExtensionId && isExtensionRequest) {
      return this.edgeExtensionId;
    } else {
      return `${this.apiService.getAuthApiBase(true)}/accounts/${accountId}`;
    }
  }

  private setAccountAccessTokenPermission(accessToken: string) {
    const permission = this.featureSwitchService.featureSwitch(FeatureSwitch.RestrictedViewer)
      ? extractPermissionFromAccessToken(accessToken)
      : AccountPermission.CONTRIBUTOR;
    this.accountAccessTokenPermission$.next(permission);
  }

  private setVideoAccessTokenPermission(accessToken: string) {
    const permission = this.featureSwitchService.featureSwitch(FeatureSwitch.RestrictedViewer)
      ? extractPermissionFromAccessToken(accessToken)
      : AccountPermission.CONTRIBUTOR;
    this.videoAccessTokenPermission$.next(permission);
  }

  private getArmAccessTokenApi(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    reqSettings?: object,
    isExtensionRequest = false,
    isExtendedExtension = false,
    extensionTokenLifetimeInSeconds?: number
  ): Observable<string> {
    if (this.enableEdgeExtensionTokens && isExtensionRequest && this.edgeExtensionId) {
      return this.getArmExtensionAccessTokenApi(
        id,
        accountId,
        allowEdit,
        isProject,
        permission,
        reqSettings,
        isExtendedExtension,
        extensionTokenLifetimeInSeconds
      );
    }

    const scope = getTokenScope(id, accountId, isProject);
    // User Token should remain vi token
    const permissions = permission ?? allowEditToPermission(allowEdit);
    const payload = { permissionType: permissions, scope: scope };
    switch (scope) {
      case TokenScope.ACCOUNT: {
        return this.apiService.ArmAccount.getArmAccountAccessToken(
          this.userAccount?.subscriptionId,
          this.userAccount?.resourceGroupName,
          this.userAccount?.name,
          payload,
          reqSettings
        ).pipe(map(res => res.accessToken));
      }
      case TokenScope.VIDEO: {
        return this.apiService.ArmAccount.getArmVideoAccessToken(
          this.userAccount?.subscriptionId,
          this.userAccount?.resourceGroupName,
          this.userAccount?.name,
          id,
          payload,
          reqSettings
        ).pipe(map(res => res.accessToken));
      }
      case TokenScope.PROJECT: {
        return this.apiService.ArmAccount.getArmProjectAccessToken(
          this.userAccount?.subscriptionId,
          this.userAccount?.resourceGroupName,
          this.userAccount?.name,
          id,
          payload,
          reqSettings
        ).pipe(map(res => res.accessToken));
      }
      default: {
        break;
      }
    }
  }

  private getArmExtensionAccessTokenApi(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    reqSettings?: object,
    isExtendedExtension = false,
    extensionTokenLifetimeInSeconds: number = 12 * 60 * 60 // 12 hours in seconds
  ): Observable<string> {
    const scope = getTokenScope(id, accountId, isProject);
    const expirationTime = isExtendedExtension ? extensionTokenLifetimeInSeconds : undefined;
    // User Token should remain vi token
    const permissions = permission ?? allowEditToPermission(allowEdit);
    const payload = this.addExtensionIdPayloadIfNeeded({ permissionType: permissions, scope: scope, tokenLifetimeInSeconds: expirationTime });
    switch (scope) {
      case TokenScope.ACCOUNT: {
        return this.apiService.ArmAccount.getArmExtensionAccountAccessToken(
          this.userAccount?.subscriptionId,
          this.userAccount?.resourceGroupName,
          this.userAccount?.name,
          payload,
          reqSettings
        ).pipe(map(res => res.accessToken));
      }
      case TokenScope.VIDEO: {
        return this.apiService.ArmAccount.getArmExtensionVideoAccessToken(
          this.userAccount?.subscriptionId,
          this.userAccount?.resourceGroupName,
          this.userAccount?.name,
          id,
          payload,
          reqSettings
        ).pipe(map(res => res.accessToken));
      }
      default: {
        break;
      }
    }
  }

  private addExtensionIdPayloadIfNeeded(payload: IArmAccountExtensionAccessTokenPayload) {
    if (this.edgeExtensionId) {
      payload.extensionId = this.edgeExtensionId;
    }

    return payload;
  }

  private getClassicAccessTokenApi(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    reqSettings?: object
  ): Observable<string> {
    const scope = getTokenScope(id, accountId, isProject);
    switch (scope) {
      case TokenScope.USER: {
        return this.apiService.User.getUserAccessToken(allowEdit);
      }
      case TokenScope.ACCOUNT: {
        return this.apiService.Account.getAccountAccessToken(accountId, allowEdit, permission);
      }
      case TokenScope.VIDEO: {
        return this.apiService.Account.Video.getVideoAccessToken(accountId, id, allowEdit, reqSettings);
      }
      case TokenScope.PROJECT: {
        return this.apiService.Account.Project.getProjectAccessToken(accountId, id, allowEdit);
      }
      default: {
        break;
      }
    }
  }

  private init() {
    this.logger.log('[AuthService] init');
    this.config = this.configService.Config;

    this.saveLastProvider();
    this.initAuthenticationContext();
  }

  private initAuthenticationContext() {
    try {
      if (this.authUser.tenantId) {
        this.authenticationConfig.tenantId = this.authUser.tenantId;
      }
      this.AuthenticationContext = new AuthenticationContext(this.authenticationConfig);

      this.logger.log('[AuthService] AuthenticationContext created');
      this.logger.log(this.AuthenticationContext);
    } catch (e) {
      this.logger.log('[AuthService] AuthenticationContext create error');
    }
  }
}
