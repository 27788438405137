import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { TrackService } from '@common/modules/core/services/track';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { cancelButtonAction, deleteButtonAction } from '../../core/models/actions';
import { AppListItem } from '../../data-source/app-list-data-source';
import { GalleryStoreService } from '../../services/gallery-store.service';
import { resources } from './resources';

@Component({
  selector: 'vi-delete-video',
  templateUrl: './delete-video.component.html',
  styleUrls: ['./delete-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteVideoComponent implements OnInit, OnDestroy {
  @Input() public data: AppListItem;
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public cancelButtonAction = cancelButtonAction;
  public deleteButtonAction = deleteButtonAction;
  public readonly BUTTONS_WIDTH = '84px';
  public resources = resources;
  public ActionButtonType = ActionButtonType;

  protected destroy$ = new Subject<void>();

  constructor(
    private logger: LoggerService,
    private galleryStore: GalleryStoreService,
    private translate: TranslateHelperService,
    private trackService: TrackService
  ) {}

  public ngOnInit(): void {
    this.trackService.track('gallery.delete_video.init');
    this.setTranslations();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public deleteIndex() {
    this.logger.log(`[DeleteVideoComponent] Delete ${this.data.name} id: ${this.data.id}`);
    if (this.data.isBase) {
      this.galleryStore.deleteVideo(this.data.id, this.data.name);
    } else {
      this.galleryStore.deleteProject(this.data.id, this.data.name);
    }
    this.closeDialog();
  }

  public cancel() {
    this.logger.log(`[DeleteVideoComponent] Cancel delete ${this.data.name}`);
    this.closeDialog();
  }

  private closeDialog() {
    const dialogEvent: IDialogEvent = {
      isDialogClose: true,
      action: cancelButtonAction
    };
    this.componentEventEmitter.emit(dialogEvent);
  }

  private setTranslations(): void {
    this.translate
      .translateResources(resources, { videoName: this.data.name })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cancelButtonAction.title = this.resources.Cancel;
        this.deleteButtonAction.title = this.resources.Delete;
      });
  }
}
