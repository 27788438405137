import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { EMPTY, combineLatest } from 'rxjs';
import { catchError, delay, filter, mergeMap, skipWhile, switchMap, take, withLatestFrom } from 'rxjs/operators';

import {
  INotification,
  NotificationFilterType,
  NotificationLevel,
  NotificationMessageType,
  INotificationsResponse,
  NotificationType,
  INotificationLink,
  INotificationButton,
  IBaseNotification
} from '@common/modules/core/services/toast/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { INotificationsRequestParams, NotificationExtendedAccountType } from '@common/modules/api/interfaces';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { TimeInterval } from '@common/modules/utils/time';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { notificationTemplates } from '@common/modules/core/services/toast/notifications';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { UpdateAccountAction } from '@common/modules/shared/actions';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { PermissionService } from '@common/modules/shared/services/permission.service';

import { IState } from '../reducers';
import { NotificationsHandlerService } from '../services/notifications-handler.service';
import { getNotificationIcon, getNotificationsLevel, getNotificationsType } from '../utils/notifications.utils';
import * as fromCore from '../../core/selectors';
import * as NotificationsActions from '../actions/notifications.actions';
import * as AccountsActions from '../actions/accounts.actions';

@Injectable()
export class NotificationsEffects {
  public NOTIFICATIONS_DELAY = TimeInterval.MINUTE * 20; // 20 min pulling delay time
  public lastPollingTime: Date;

  public initNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.initNotifications),
      withLatestFrom(this.store.select(fromCore.getSelectedAccountLocation)),
      withLatestFrom(this.store.select(fromCore.selectAccountResourceType)),
      withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
      withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
      withLatestFrom(this.store.select(fromCore.getUserId)),
      switchMap(([[[[[, location], accountResourceType], isArmAmsless], accountPermission], userId]) => {
        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
          return EMPTY;
        }
        this.notificationsInit = true;

        const accountTypeRequestParam = isArmAmsless ? NotificationExtendedAccountType.ARM_AMSLESS : accountResourceType;

        // loaded notifications data from cache
        this.notificationsHandlerService.loadNotificationsFromCache(userId, location?.toLowerCase(), accountTypeRequestParam?.toLowerCase());
        const params: INotificationsRequestParams = {
          region: location,
          accountType: accountTypeRequestParam
        };

        return this.apiService.Notifications.getNotifications(params).pipe(
          switchMap((response: INotificationsResponse) => {
            this.lastPollingTime = new Date();
            const preparedNotifications = this.prepareNotifications(response, accountPermission);
            this.notificationsHandlerService.ETag = response.eTag;
            this.saveNotificationsIds(preparedNotifications.notifications);

            if (preparedNotifications.notifications?.length) {
              const notificationsIds = preparedNotifications.notifications.map(n => n.id);
              this.trackService.track('notifications.init_notifications.success', {
                category: EventCategory.NOTIFICATIONS,
                data: {
                  ids: notificationsIds,
                  count: notificationsIds?.length,
                  eTag: preparedNotifications?.eTag,
                  accountRegion: location,
                  accountType: accountTypeRequestParam
                }
              });
            }

            return this.getNotificationsActions(preparedNotifications.notifications);
          }),
          catchError(error => {
            this.lastPollingTime = new Date();
            this.logger.error('[NotificationsEffects] initNotifications failed', error);
            this.trackService.track('notifications.init_notifications.failed', {
              category: EventCategory.NOTIFICATIONS,
              error: JSON.stringify(error)
            });
            return [NotificationsActions.loadNotifications()];
          })
        );
      })
    )
  );

  public pollNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.loadNotifications),
      delay(this.NOTIFICATIONS_DELAY),
      skipWhile(() => new Date().getTime() - this.lastPollingTime?.getTime() < this.NOTIFICATIONS_DELAY),
      withLatestFrom(this.store.select(fromCore.getSelectedAccountLocation)),
      withLatestFrom(this.store.select(fromCore.selectAccountResourceType)),
      withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
      withLatestFrom(this.store.select(fromCore.selectAccountTokenPermission)),
      mergeMap(([[[[, location], accountResourceType], isArmAmsless], accountPermission]) => {
        const accountTypeRequestParam = isArmAmsless ? NotificationExtendedAccountType.ARM_AMSLESS : accountResourceType;
        const params: INotificationsRequestParams = {
          region: location,
          accountType: accountTypeRequestParam
        };

        // if last pulling time is more then 6 min don't send eTag to fetch all notifications
        // needed for when user leave the portal tab opened and the computer sleeps
        if (new Date().getTime() - this.lastPollingTime?.getTime() < TimeInterval.MINUTE * 6) {
          const eTag = this.notificationsHandlerService.ETag;
          if (eTag) {
            params.eTag = encodeURIComponent(eTag);
          }
        }

        return this.apiService.Notifications.getNotifications(params).pipe(
          switchMap((response: INotificationsResponse) => {
            this.lastPollingTime = new Date();
            const preparedNotifications = this.prepareNotifications(response, accountPermission);
            this.notificationsHandlerService.ETag = response.eTag;
            this.saveNotificationsIds(preparedNotifications.notifications);

            if (preparedNotifications.notifications?.length) {
              const notificationsIds = preparedNotifications.notifications.map(n => n.id);
              this.trackService.track('notifications.poll_notifications.success', {
                category: EventCategory.NOTIFICATIONS,
                data: {
                  ids: notificationsIds,
                  count: notificationsIds?.length,
                  eTag: preparedNotifications?.eTag,
                  accountRegion: location,
                  accountType: accountTypeRequestParam
                }
              });
            }

            return this.getNotificationsActions(preparedNotifications.notifications);
          }),
          catchError(error => {
            this.logger.error('[NotificationsEffects] pullNotifications failed', error);
            this.trackService.track('notifications.poll_notifications.failed', {
              category: EventCategory.NOTIFICATIONS,
              error: JSON.stringify(error)
            });

            // In case of 401 error, we don't want to retry the request
            if (error?.status === 401) {
              return EMPTY;
            }

            return [NotificationsActions.loadNotifications()];
          })
        );
      })
    )
  );

  public reloadNotificationsOnAccountChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.selectAccount, AccountsActions.loadLastArmAccount, NotificationsActions.reloadNotifications),
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([[{}, account], selectCurrentAccount]) => {
        if (!this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
          return EMPTY;
        }

        if (!account && !selectCurrentAccount) {
          setTimeout(() => {
            this.store.dispatch(NotificationsActions.reloadNotifications());
          }, 200);
          return EMPTY;
        }

        if (this.notificationsInit && account?.id === selectCurrentAccount?.id) {
          this.logger.log(`[NotificationsEffects] get selected account in state`);
          return [NotificationsActions.loadNotifications()];
        }

        this.notificationsHandlerService.clearBannersNotifications();

        return [NotificationsActions.initNotifications()];
      })
    )
  );

  private notificationsInit = false;

  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private trackService: TrackService,
    private utilsService: UtilsService,
    private featureSwitchService: FeatureSwitchService,
    private translate: TranslateHelperService,
    private notificationsHandlerService: NotificationsHandlerService,
    private permissionService: PermissionService
  ) {
    if (this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
      this.notificationsHandlerService.initNotificationsService();
    }
  }

  private prepareNotifications(response: INotificationsResponse, accountPermission: AccountPermission): INotificationsResponse {
    const clearedNotificationsIds = this.notificationsHandlerService.clearedNotificationsIds;
    // ignore notifications with unfamiliar message types and show only those that match the permission
    const supportedNotifications = response?.notifications?.filter(notification => {
      const isSupportedMessageType = !!NotificationMessageType[notification.messageType];
      const hasRequiredPermission = !notification.permission || this.permissionService.ensurePermission(accountPermission, notification.permission);

      return isSupportedMessageType && hasRequiredPermission;
    });
    // removed the cleared notifications
    const newNotifications = supportedNotifications?.filter(
      n => !clearedNotificationsIds.includes(n.id) || getNotificationsLevel(n) === NotificationLevel.Critical
    );

    return {
      notifications: this.mapNotifications(newNotifications),
      eTag: response.eTag
    };
  }

  private mapNotifications(notifications: IBaseNotification[]): INotification[] {
    const clearedNotificationsIds = this.notificationsHandlerService.clearedNotificationsIds;
    // map the common notifications to fill in the data
    return notifications?.map(notification => {
      return {
        ...notification,
        title: this.getNotificationTitle(notification),
        text: this.getNotificationText(notification),
        level: getNotificationsLevel(notification),
        icon: getNotificationIcon(notification),
        type: getNotificationsType(notification),
        link: this.getNotificationLink(notification),
        button: this.getNotificationButton(notification),
        toasted: this.isNotificationsToasted(notification),
        cleared: clearedNotificationsIds.includes(notification.id),
        resolveAccountData: notification.messageType === NotificationMessageType.UpdateAccount
      };
    });
  }

  private saveNotificationsIds(notifications: INotification[]): void {
    if (!notifications?.length) {
      return;
    }

    // Filter all alert notifications, and spilt them into 3 filter types for saving the ids on the cache
    const alerts = notifications.filter(n => n.type?.toLowerCase() !== NotificationType.Message.toLowerCase());
    const alertsDefaultAndTenantNotificationsIds = alerts
      .filter(
        n =>
          n.filterType?.toLowerCase() === NotificationFilterType.Default.toLowerCase() ||
          n.filterType?.toLowerCase() === NotificationFilterType.Tenant.toLowerCase()
      )
      ?.map(n => n.id);
    const alertsRegionalNotificationsIds = alerts
      .filter(n => n.filterType?.toLowerCase() === NotificationFilterType.Region.toLowerCase())
      ?.map(n => n.id);
    const alertsAccountTypeNotificationsIds = alerts
      .filter(n => n.filterType?.toLowerCase() === NotificationFilterType.AccountType.toLowerCase())
      ?.map(n => n.id);

    // Filter all messages notifications, and spilt them into 3 filter types for saving the ids on the cache
    const messages = notifications.filter(n => n.type?.toLowerCase() === NotificationType.Message.toLowerCase());
    const messagesDefaultAndTenantNotificationsIds = messages
      .filter(
        n =>
          n.filterType?.toLowerCase() === NotificationFilterType.Default.toLowerCase() ||
          n.filterType?.toLowerCase() === NotificationFilterType.Tenant.toLowerCase()
      )
      ?.map(n => n.id);
    const messagesRegionalNotificationsIds = messages
      .filter(n => n.filterType?.toLowerCase() === NotificationFilterType.Region.toLowerCase())
      ?.map(n => n.id);
    const messagesAccountTypeNotificationsIds = messages
      .filter(n => n.filterType?.toLowerCase() === NotificationFilterType.AccountType.toLowerCase())
      ?.map(n => n.id);

    this.notificationsHandlerService.addNotificationsIds(
      alertsDefaultAndTenantNotificationsIds,
      alertsRegionalNotificationsIds,
      alertsAccountTypeNotificationsIds,
      messagesDefaultAndTenantNotificationsIds,
      messagesRegionalNotificationsIds,
      messagesAccountTypeNotificationsIds
    );
  }

  private getNotificationTitle(notification: IBaseNotification): string {
    if (notification.messageType === NotificationMessageType.Custom) {
      return notification.title;
    }

    return this.translate.instant(`${notification.messageType}Title`);
  }

  private getNotificationText(notification: IBaseNotification): string {
    switch (notification.messageType) {
      case NotificationMessageType.Custom:
        return notification.text;
      case NotificationMessageType.RegionalOutage:
        return this.translate.instant('RegionalOutageText', { region: notification.region });
      default:
        return this.translate.instant(`${notification.messageType}Text`);
    }
  }

  private getNotificationButton(notification: IBaseNotification): INotificationButton {
    switch (notification.messageType) {
      case NotificationMessageType.UpdateAccount:
        return {
          action: {
            ...UpdateAccountAction,
            title: this.translate.instant('UpdateAccountNotificationButtonText')
          },
          callback: () => {
            combineLatest([this.store.select(fromCore.selectedAccountContract), this.store.select(fromCore.accountError)])
              .pipe(
                filter(([account, error]) => !!account || error), // wait for account to be loaded or error to appear
                take(1)
              )
              .subscribe(([account, _error]) => {
                const url = this.utilsService.getUpdateAccountPortalUrl(account?.tenantId, account?.resourceId);
                // on error the url will be redirected to ibiza home page
                window.open(url, '_blank');
              });
          }
        };
      default:
        return notificationTemplates[notification.messageType]?.button
          ? {
              ...notificationTemplates[notification.messageType].button
            }
          : null;
    }
  }

  private getNotificationLink(notification: INotification): INotificationLink {
    if (notification.messageType === NotificationMessageType.Custom) {
      return notification.link?.src
        ? {
            ...notification.link
          }
        : null;
    }

    return notificationTemplates[notification.messageType]?.link
      ? {
          src: notificationTemplates[notification.messageType].link.src,
          text: this.translate.instant(`${notification.messageType}LinkText`)
        }
      : null;
  }

  private isNotificationsToasted(notification: INotification) {
    if (notification.toasted) {
      return true;
    }

    // On Critical level notifications we always want to show on refresh
    const level = getNotificationsLevel(notification);
    if (level === NotificationLevel.Critical) {
      return false;
    }

    // if notification is not Critical we check if it toasted from the cache
    const toastedNotificationsIds = this.notificationsHandlerService.toastedNotificationsIds;
    return toastedNotificationsIds.includes(notification.id);
  }

  private getNotificationsActions(notifications: INotification[]) {
    const bannersNotifications = notifications.filter(n => n.type?.toLowerCase() === NotificationType.Banner.toLowerCase());
    const alertsNotifications = notifications.filter(n => n.type?.toLowerCase() === NotificationType.Alert.toLowerCase());
    const messagesNotifications = notifications.filter(n => n.type?.toLowerCase() === NotificationType.Message.toLowerCase());
    const actions: Action[] = [];
    if (bannersNotifications?.length) {
      actions.push(NotificationsActions.addNotificationsBanners({ notifications: bannersNotifications }));
    }
    if (alertsNotifications?.length) {
      actions.push(NotificationsActions.addNotificationsAlerts({ notifications: alertsNotifications }));
    }
    if (messagesNotifications?.length) {
      actions.push(NotificationsActions.addNotificationMessages({ notifications: messagesNotifications }));
    }
    actions.push(NotificationsActions.loadNotifications());
    return actions;
  }
}
