import { ILanguage } from './interfaces';

export const SupportedLanguages: ILanguage[] = [
  {
    name: 'Arabic',
    key: 'ar-EG',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Arabic (Syrian Arab Republic)',
    key: 'ar-SY',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Bahrain), modern standard',
    key: 'ar-BH',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Iraq)',
    key: 'ar-IQ',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Jordan)',
    key: 'ar-JO',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Lebanon)',
    key: 'ar-LB',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Oman)',
    key: 'ar-OM',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Qatar)',
    key: 'ar-QA',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Saudi Arabia)',
    key: 'ar-SA',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Kuwait)',
    key: 'ar-KW',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Israel)',
    key: 'ar-IL',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (United Arab Emirates)',
    key: 'ar-AE',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Arabic (Palestinian Authority)',
    key: 'ar-PS',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'Chinese (Simplified)',
    key: 'zh-Hans',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'English',
    key: 'en-US',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'French',
    key: 'fr-FR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'French (Canada)',
    key: 'fr-CA',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'English (United Kingdom)',
    key: 'en-GB',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'English (Australia)',
    key: 'en-AU',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    padding: 1
  },
  {
    name: 'German',
    key: 'de-DE',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Italian',
    key: 'it-IT',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Japanese',
    key: 'ja-JP',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Portuguese',
    key: 'pt-BR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Portuguese (Portugal)',
    key: 'pt-PT',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Russian',
    key: 'ru-RU',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Spanish (Spain)',
    key: 'es-ES',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Spanish (Mexico)',
    key: 'es-MX',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Filipino',
    key: 'fil-PH',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Afrikaans',
    key: 'Af-ZA',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Bangla',
    key: 'Bn-BD',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Bosnian',
    key: 'Bs-Latn',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Bulgarian',
    key: 'bg-BG',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Catalan',
    key: 'ca-ES',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Croatian',
    key: 'hr-HR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Czech',
    key: 'Cs-CZ',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Danish',
    key: 'Da-DK',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Dutch',
    key: 'Nl-NL',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Fijian',
    key: 'En-FJ',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Samoan',
    key: 'En-WS',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Estonian',
    key: 'et-EE',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Finnish',
    key: 'Fi-FI',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Greek',
    key: 'el-GR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Haitian',
    key: 'Fr-HT',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Hebrew',
    key: 'he-IL',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Hindi',
    key: 'hi-IN',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1
  },
  {
    name: 'Hungarian',
    key: 'Hu-HU',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Indonesian',
    key: 'Id-ID',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Kiswahili',
    key: 'Sw-KE',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Korean',
    key: 'ko-KR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Latvian',
    key: 'lv-LV',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Lithuanian',
    key: 'Lt-LT',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Malagasy',
    key: 'Mg-MG',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Malay',
    key: 'Ms-MY',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Maltese',
    key: 'Mt-MT',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Norwegian',
    key: 'Nb-NO',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Persian',
    key: 'fa-IR',
    rtl: true,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Polish',
    key: 'Pl-PL',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Romanian',
    key: 'ro-RO',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Serbian (Cyrillic)',
    key: 'Sr-Cyrl-RS',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Serbian (Latin)',
    key: 'Sr-Latn-RS',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Slovak',
    key: 'sk-SK',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Slovenian',
    key: 'sl-SI',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Swedish',
    key: 'Sv-SE',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Tamil',
    key: 'Ta-IN',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Thai',
    key: 'Th-TH',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Tongan',
    key: 'To-TO',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Turkish',
    key: 'Tr-TR',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: true,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Ukrainian',
    key: 'Uk-UA',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Urdu',
    key: 'Ur-PK',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1.3
  },
  {
    name: 'Vietnamese',
    key: 'Vi-VN',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1.3
  },
  {
    name: 'Chinese (Traditional)',
    key: 'Zh-Hant',
    rtl: false,
    sourceLanguage: false,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1.3
  },
  {
    name: 'Chinese (Cantonese)',
    key: 'zh-HK',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Irish',
    key: 'ga-IE',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: true,
    padding: 1.3
  },
  {
    name: 'Multi',
    key: '',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  }
];

export const additionalUploadLanguages: ILanguage[] = [
  {
    name: 'Auto',
    key: 'auto',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    supportedCustomization: false,
    padding: 1
  },
  {
    name: 'Multi',
    key: 'multi',
    rtl: false,
    sourceLanguage: true,
    supportedUserLocale: false,
    padding: 1
  }
];

export const DefaultSupportedLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[] = [
  { name: 'German', languageCode: 'de-DE', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'English United States', languageCode: 'en-US', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Spanish', languageCode: 'es-ES', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'French', languageCode: 'fr-FR', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Italian', languageCode: 'it-IT', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Japanese', languageCode: 'ja-JP', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Portuguese', languageCode: 'pt-BR', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Russian', languageCode: 'ru-RU', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true },
  { name: 'Chinese (Simplified)', languageCode: 'zh-Hans', isRightToLeft: false, isSourceLanguage: true, isAutoDetect: true }
];

export const ExcludedSelectedDialectLanguages: Microsoft.VideoIndexer.Contracts.LanguageV2[] = ['zh-Hans', 'zh-Hant', 'zh-HK'];

export enum SpecialLanguageKey {
  AUTO = 'auto',
  MULTI = 'multi'
}

export const defaultLangKey = 'en-US';

export const keepSourceLanguageId = 'keepSource';
