import { combineReducers } from '@ngrx/store';

import { initialBannersState, BannersState } from './notifications-banners.reducer';
import { initialAlertsState, AlertsState } from './notifications-alerts.reducer';
import { initialMessagesState, MessagesState } from './notifications-messages.reducer';
import { initialNotificationsViewState, NotificationsViewState } from './notifications-view.reducer';
import * as fromNotificationsBanners from './notifications-banners.reducer';
import * as fromNotificationsAlerts from './notifications-alerts.reducer';
import * as fromNotificationsMessages from './notifications-messages.reducer';
import * as fromNotificationsState from './notifications-view.reducer';

export interface IState {
  banners: BannersState;
  alerts: AlertsState;
  messages: MessagesState;
  viewState: NotificationsViewState;
}

export const initialState: IState = {
  // additional entity state properties
  banners: initialBannersState,
  alerts: initialAlertsState,
  messages: initialMessagesState,
  viewState: initialNotificationsViewState
};

export const reducer = combineReducers({
  banners: fromNotificationsBanners.reducer,
  alerts: fromNotificationsAlerts.reducer,
  messages: fromNotificationsMessages.reducer,
  viewState: fromNotificationsState.reducer
});
