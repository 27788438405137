import { Action, createReducer, on } from '@ngrx/store';

import { SideMenuOption } from '@common/modules/core/services/interfaces';

import * as SideMenuActions from '../actions/side-menu.actions';

export interface IState {
  selectedOption: SideMenuOption;
  open?: boolean;
}

const initialState: IState = {
  selectedOption: SideMenuOption.Media,
  open: false
};

const sideMenuReducer = createReducer(
  initialState,
  on(SideMenuActions.sideMenuInitOption, (state, { option }) => {
    return {
      ...state,
      selectedOption: option,
      open: state.open
    };
  }),
  on(SideMenuActions.sideMenuToggled, (state, { open }) => {
    return {
      ...state,
      open: open
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return sideMenuReducer(state, action);
}
