import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { NavigationState } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppNavigationService {
  public createAccountSubject: BehaviorSubject<NavigationState>;
  constructor() {
    this.createAccountSubject = new BehaviorSubject<NavigationState>(null);
  }
}
