import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

export const backToSettingsAction: IAction = {
  title: '',
  key: 'IndexingBackToSettings',
  type: UIActionType.PREVIOUS,
  value: UIActionType.PREVIOUS,
  id: 'backToSettingsBtn'
};
