import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';

import { withLatestFrom } from 'rxjs/operators';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { IAction } from '@common/modules/shared/interfaces';
import { envTypes } from '@common/modules/api/interfaces';
import { TrackService } from '@common/modules/core/services/track';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { ConfigService } from '@common/modules/core/services/config/config.service';
import { AZURE_US_PORTAL_LINK } from '@common/modules/shared/links';

import { resources } from './resources';
import { helpActionsList, referencesActionList } from './actions';
import { UIHelpActionType } from './interfaces';
import { CoreStoreService } from '../../../core/services/core-store.service';
import { FaceGatePopupService } from '../../../core/services/face-gate-popup.service';

@Component({
  selector: 'app-vi-help',
  templateUrl: './help.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Output() public openAbout = new EventEmitter();

  public resources = resources;
  public helpList = helpActionsList;
  public referencesList = referencesActionList;
  public isTrial: boolean;
  public readonly MICROSOFT_COPYRIGHTS = '© Microsoft';
  public readonly LINK_ROLE = 'link';
  public showFaceGatePopupLink: boolean;
  private userEmail: string;
  private account: Microsoft.VideoIndexer.Contracts.AccountContract;

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private coreStoreService: CoreStoreService,
    private trackService: TrackService,
    private utilsService: UtilsService,
    private config: ConfigService,
    private faceGatePopupService: FaceGatePopupService
  ) {}

  public ngOnInit() {
    this.trackService.track('help.init');
    if (this.config.isFairFaxEnv()) {
      this.referencesList.find(r => r.id === 'apiReference').value = AZURE_US_PORTAL_LINK;
    }

    this.coreStoreService.selectAccountType$.pipe().subscribe(type => {
      this.isTrial = type?.toLowerCase() === envTypes.TRIAL.toLowerCase();
      this.cdr.markForCheck();
    });

    this.coreStoreService.selectedAccountContract$
      .pipe(withLatestFrom(this.coreStoreService.getSignedInUserEmail$), withLatestFrom(this.coreStoreService.getAccountTokenPermission$))
      .subscribe(([[account, userEmail], accountPermission]) => {
        if (!account) {
          return;
        }
        this.account = account;
        this.userEmail = userEmail;
        this.showFaceGatePopupLink = this.faceGatePopupService.isShowPopupEnabled(
          userEmail,
          account?.limitedAccessFeatures?.isFaceIdentificationEnabled,
          accountPermission,
          account.location
        );
        this.cdr.detectChanges();
      });

    this.setTranslations();
  }

  public setTranslations(): void {
    this.translate.translateResources(resources).subscribe(() => {
      this.helpList.forEach(c => {
        c.title = this.resources[c.key];
      });
      this.referencesList.forEach(c => {
        // Accesibility link should only appear for french loc.so the string is already localized
        if (c?.type !== UIHelpActionType.ACCESSIBILITY) {
          c.title = this.resources[c.key];
        }
      });
      this.cdr.detectChanges();
    });
  }

  public getLinkByValue(action: IAction): string {
    return this.utilsService.getLinkByValue(this.isTrial, action.value);
  }

  public showLink(action: IAction) {
    switch (action?.type) {
      case UIHelpActionType.INTERNAL_FACE_GATE_POPUP:
        return this.showFaceGatePopupLink;
      case UIHelpActionType.FACE_RECOGNITION_FORM:
        return !this.isTrial && !this.account?.limitedAccessFeatures?.isFaceIdentificationEnabled;
      case UIHelpActionType.PRICING:
        return this.isTrial;
      case UIHelpActionType.ACCESSIBILITY:
        // using the key property to indicate the locale in which the action is relavant to
        return action.key === this.translate.locale.toLowerCase();
      default:
        return true;
    }
  }

  public isPopup(action: IAction) {
    return action?.type === UIHelpActionType.ABOUT_VI || action?.type === UIHelpActionType.INTERNAL_FACE_GATE_POPUP;
  }

  public emitPopup(action: IAction) {
    switch (action?.type) {
      case UIHelpActionType.ABOUT_VI:
        this.openAbout.emit();
        break;
      case UIHelpActionType.INTERNAL_FACE_GATE_POPUP:
        this.showFaceGatePopup();
        break;
    }
  }

  private showFaceGatePopup() {
    this.faceGatePopupService.showPopup(this.account, this.userEmail);
  }
}
