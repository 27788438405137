<!-- Dialog header -->
<div class="dialog-title-container">
  <div class="main-title">
    <!--Header with title and close icon-->
    <h1>
      {{resources?.ReindexModalHeader}}
    </h1>
    <app-vi-action-button *ngIf="!showConfirmExit"
      class="close-dialog"
      [action]="closeIconAction"
      [showAccessibilityTooltip]="true"
      (actionClick)="confirmClose()">
    </app-vi-action-button>
  </div>
</div>
<ng-container *ngIf="!showConfirmExit; else confirmExitScreen">
  <!-- Loading -->
  <div class="spinner-container"
    *ngIf="!isIndexingSettingLoaded">
    <app-vi-spinner>
    </app-vi-spinner>
  </div>
  <ng-container [ngSwitch]="displayedReIndexPage">
    <!-- Indexing form -->
    <div *ngSwitchCase="ReIndexPages.IndexingForm"
      class="indexing-form-container">
      <!-- Error strip -->
      <vi-ui-alert-message *ngIf="showLoadingError || (showReIndexError && !isExcludeAIsEnabled)"
        [type]="AlertMessageType.ERROR"
        [content]="error?.content"
        [linkText]="error?.linkText"
        (buttonClicked)="error?.onClick()">
      </vi-ui-alert-message>
      <vi-indexing-form *ngIf="isIndexingSettingLoaded  && !showLoadingError"
        [isDisabled]="isIndexingFormDisabled"
        (navigateToCreateAccount)="onNavigateToCreateAccount()"
        (manageModelsClicked)="confirmClose($event)">
      </vi-indexing-form>
    </div>
    <div *ngSwitchCase="ReIndexPages.ReIndexReview">
      <vi-indexing-review-page></vi-indexing-review-page>
    </div>
    <vi-re-index-summary-page *ngSwitchCase="ReIndexPages.ReIndexSummary"
      (reIndexError)="onReIndexError()">
    </vi-re-index-summary-page>
  </ng-container>

  <!--Buttons-->
  <div class="re-index-buttons">
    <!-- reIndex status -->
    <div *ngIf="showReIndexStatus"
      class="status"
      [ngClass]="{'failed': showReIndexError }">
      <i [ngClass]="{'i-vi-loading': isReIndexProcessing, 'i-vi-error': showReIndexError}">
      </i>
      <span>{{reIndexStatusText}}</span>
    </div>

    <!-- Back to settings button -->
    <app-vi-action-button *ngIf="showBackToSettingsButton"
      class="back-to-settings-button"
      [action]="backToSettingsAction"
      [minWidth]="BACK_TO_SETTINGS_MIN_WIDTH"
      [type]="ActionButtonType.SECONDARY"
      (actionClick)="backToSettingsClicked()">
    </app-vi-action-button>

    <!-- reIndex button -->
    <app-vi-action-button *ngIf="showReIndexButton"
      class="re-index-button"
      [action]="reIndexAction"
      [isDisabled]="isReIndexButtonDisabled"
      [type]="ActionButtonType.PRIMARY"
      (actionClick)="reIndexClicked()">
    </app-vi-action-button>

    <!-- reIndex and review button -->
    <app-vi-action-button *ngIf="showReviewReIndexButton"
      class="review-re-index-button"
      [action]="reviewAndReIndexAction"
      [isDisabled]="!isSupportedAIsLoaded"
      [type]="ActionButtonType.PRIMARY"
      [minWidth]="REVIEW_REINDEX_BUTTON_MIN_WIDTH"
      (actionClick)="reviewReIndexClicked()">
    </app-vi-action-button>

    <!-- cancel button -->
    <app-vi-action-button *ngIf="showCancelButton"
      class="cancel-button"
      [action]="cancelAction"
      [type]="ActionButtonType.SECONDARY"
      (actionClick)="confirmClose()">
    </app-vi-action-button>

    <!-- close button -->
    <app-vi-action-button *ngIf="showCloseButton"
      class="clode-button"
      [action]="closeAction"
      [type]="ActionButtonType.SECONDARY"
      (actionClick)="close()">
    </app-vi-action-button>

  </div>
</ng-container>

<ng-template #confirmExitScreen>
  <vi-confirm-exit-indexing (backToIndexing)="onBackToIndexing()"
    (leaveIndexing)="leaveIndexing()">
  </vi-confirm-exit-indexing>
</ng-template>