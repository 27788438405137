import { createAction, props } from '@ngrx/store';

import InvitationContract = Microsoft.VideoIndexer.Contracts.InvitationContract;
import InviteContract = Microsoft.VideoIndexer.Contracts.InviteContract;

export const openShareAccount = createAction('[Invitations] Open Share Account', props<{ showInviteForm: boolean }>());
export const loadInvitations = createAction('[Invitations/API] Load Invitations');
export const loadInvitationsSuccess = createAction('[Invitations] Load Invitations Success', props<{ invitations: InvitationContract[] }>());
export const loadInvitationsFailed = createAction('[Invitations] Load Invitations Failed', props<{ isUserNotAllowedError: boolean }>());
export const deleteInvitation = createAction('[Invitations/API] Delete Invitation', props<{ invitationId: number }>());
export const deleteInvitationSuccess = createAction('[Invitations] Delete Invitation Success', props<{ invitationId: number }>());
export const invitationsError = createAction('[Invitations] Invitations error', props<{ isUserNotAllowedError: boolean }>());
export const inviteToAccount = createAction('[Invitations/API] Invite To Account', props<{ formDetails: InviteContract[] }>());
export const inviteToAccountInProgress = createAction('[Invitations] Invite To Account In Progress');
export const inviteToAccountSuccess = createAction('[Invitations] Invite To Account Success', props<{ invitations: InvitationContract[] }>());
export const copyInvitationLink = createAction('[Invitations] Copy invitation link', props<{ invitationId: number }>());
