<ng-container *ngFor="let item of elements; let i = index">
  <ng-container [ngSwitch]="elementType">
    <div *ngSwitchCase="ShimmerElementType.CHECKBOX"
      class="row">
      <div class="checkbox shimmer"></div>
      <div class="label shimmer"></div>
    </div>
    <div *ngSwitchCase="ShimmerElementType.ROW"
      class="row full-width">
      <div class="long-row shimmer"></div>
    </div>
    <div *ngSwitchCase="ShimmerElementType.SHORT_ROW"
      class="row full-width">
      <div class="short-row shimmer"></div>
    </div>
  </ng-container>
</ng-container>