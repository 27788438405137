import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { CoreStoreService } from '../services/core-store.service';

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AuthGuardService).canActivate();
};

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private logger: LoggerService,
    private auth: AuthService,
    private router: Router,
    private coreStore: CoreStoreService,
    private trackService: TrackService
  ) {}

  public canActivate(): Observable<boolean> {
    if (this.auth.isAuthenticated()) {
      return of(true);
    }

    return this.coreStore.userDetailsLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      map(() => {
        const isUserAuthenticated = this.auth.isAuthenticated();
        this.logger.log(`[AuthGuardService] canActivate: ${isUserAuthenticated}`);
        if (!isUserAuthenticated) {
          this.handleUserNotAuthenticated();
        }

        return isUserAuthenticated;
      })
    );
  }

  private handleUserNotAuthenticated() {
    this.logger.log('[AuthGuardService] user not authenticated navigate VIRoutes.login');
    this.trackService.track('auth.guard.user_not_authenticated', {
      category: EventCategory.AUTH
    });
    this.router.navigate(['/account/login']);
  }
}
