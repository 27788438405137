import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appStopPropagation]'
})
export class StopPropagationDirective {
  @Input() public stopPropagationActive = true;

  @HostListener('click', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('keyup', ['$event'])
  public onStopPropagation(event) {
    if (!this.stopPropagationActive) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
  }
}
