import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '@common/modules/shared/shared.module';

import { BrandModelEffects } from './effects/brands-model.effects';
import { CustomizationEffects } from './effects/customization.effects';
import { LanguageIdEffects } from './effects/language-id.effects';
import { LanguageModelEffects } from './effects/language-model.effects';
import { PersonModelEffects } from './effects/person-model.effects';
import { SpeechDatasetsEffects } from './effects/speech/speech-datasets.effects';
import { SpeechModelsEffects } from './effects/speech/speech-models.effects';
import { SpeechViewEffects } from './effects/speech/speech-page.effects';
import { SupportedLanguagesIdsEffects } from './effects/supported-languages-ids.effects';
import { SupportedLanguagesEffects } from './effects/supported-languages.effects';
import { reducers } from './reducers';
import { BrandsService } from './services/brands.service';
import { CustomizationDataStoreService } from './services/customization-data-store.service';
import { CustomizationDataService } from './services/customization-data.service';
import { CustomizationUtilsService } from './services/customization-utils.service';
import { CustomizationFilterService } from './services/fluent-list-filter-service';
import { LanguageCustomizationService } from './services/language-customization.service';
import { LanguageIdStoreService } from './services/language-id-store.service';
import { SpeechDatasetStoreService } from './services/speech/speech-dataset-store.service';
import { SpeechModelStoreService } from './services/speech/speech-model-store.service';
import { SpeechPageStoreService } from './services/speech/speech-page-store.service';
import { SpeechErrorsService } from './services/speech/speech-errors.service';
import { LogoGroupsEffects } from './effects/logo-groups.effects';
import { EdgeSupportedLanguagesEffects } from './effects/edge-supported-languages.effects';
import { UnknownPersonEffects } from './effects/people/unknown-person.effects';
import { UnknownPersonStoreService } from './services/people/unknown-person-store.service';
import { PeoplePageStoreService } from './services/people/people-page-store.service';
import { UnknownPersonService } from './services/people/unknown-person.service';
import { PeopleViewEffects } from './effects/people/people-page.effects';
import { UnknownPersonNotificationService } from '../customization/components/customization-people/services/unknown-person-notification.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('customizationData', reducers),
    EffectsModule.forFeature([
      LanguageModelEffects,
      PersonModelEffects,
      BrandModelEffects,
      CustomizationEffects,
      SupportedLanguagesEffects,
      SpeechDatasetsEffects,
      SpeechModelsEffects,
      SpeechViewEffects,
      PeopleViewEffects,
      LanguageIdEffects,
      SupportedLanguagesIdsEffects,
      LogoGroupsEffects,
      EdgeSupportedLanguagesEffects,
      UnknownPersonEffects
    ])
  ],
  providers: [
    CustomizationDataStoreService,
    CustomizationFilterService,
    CustomizationUtilsService,
    CustomizationDataService,
    LanguageCustomizationService,
    SpeechDatasetStoreService,
    SpeechModelStoreService,
    SpeechPageStoreService,
    PeoplePageStoreService,
    BrandsService,
    LanguageIdStoreService,
    DatePipe,
    SpeechErrorsService,
    UnknownPersonStoreService,
    UnknownPersonService,
    SpeechErrorsService,
    UnknownPersonNotificationService
  ]
})
export class CustomizationDataModule {}
