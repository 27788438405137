import { Injectable } from '@angular/core';

import { IStripData } from '@common/modules/shared/components/strip/interfaces';
import { TrackService } from '@common/modules/core/services/track';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { FeatureSwitch, NavigationState } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { CoreStoreService } from '../../core/services/core-store.service';

@Injectable({
  providedIn: 'root'
})
export class FaceGateStripService {
  private readonly FACE_GATE_TRACKING = 'face_gate_strip';
  private isFaceGateEnabled: boolean;
  private showFaceGateWarning: boolean;

  constructor(
    private trackService: TrackService,
    private stripService: StripService,
    private navigationService: AppNavigationService,
    private coreStore: CoreStoreService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.isFaceGateEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.FaceGate);
    this.coreStore.showFaceGateWarning$.subscribe(showFaceGateWarning => {
      this.showFaceGateWarning = showFaceGateWarning;
    });
  }

  public showFaceGateStrip(stripMessage: string, isTrial: boolean, pageName: string): number {
    if (!this.isFaceGateEnabled || !this.showFaceGateWarning) {
      return;
    }
    const stripData: IStripData = {
      text: stripMessage,
      trackingName: this.FACE_GATE_TRACKING,
      show: true,
      hasCloseButton: true,
      linkCallback: () => {
        if (isTrial) {
          this.navigationService.createAccountSubject.next(NavigationState.OPEN);
        }
        this.trackEventLink(isTrial ? 'OpenCreateAccountDialog' : 'OpenFaceRecognitionFom', pageName);
      },
      iconClass: 'i-vi-info'
    };
    return this.stripService.trigger(stripData);
  }

  public hideFaceGetStrip(faceGateStripId: number) {
    if (!this.isFaceGateEnabled || !this.showFaceGateWarning) {
      return;
    }
    this.stripService.hideStrip(faceGateStripId);
    this.trackService.track(`${this.FACE_GATE_TRACKING}.hide_strip`);
  }

  private trackEventLink(linkType: string, pageName: string) {
    this.trackService.track(`${this.FACE_GATE_TRACKING}.link_clicked`, {
      linkType: linkType,
      page: pageName
    });
  }
}
