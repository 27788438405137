import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IFile, UploadingStateError } from '../../interfaces';

export const uploadFileToEdge = createAction(
  '[Upload] Upload File To Edge',
  props<{
    storeFile: IFile;
  }>()
);
export const uploadFileToEdgeFailed = createAction(
  '[Upload] Upload File To Edge Failed',
  props<{ storeFile: IFile; uploadingStateError: UploadingStateError; error?: HttpErrorResponse }>()
);
