import { IUIFace } from '../insights/components/faces/interfaces';
import { IUIKeyframe } from '../insights/components/keyframe/interfaces';

export type IInsightJsonKeyType =
  | IUIInsightJsonKey.brands
  | IUIInsightJsonKey.emotions
  | IUIInsightJsonKey.faces
  | IUIInsightJsonKey.topics
  | IUIInsightJsonKey.keywords
  | IUIInsightJsonKey.labels
  | IUIInsightJsonKey.speakers
  | IUIInsightJsonKey.transcript
  | IUIInsightJsonKey.ocr
  | IUIInsightJsonKey.shots
  | IUIInsightJsonKey.sentiments
  | IUIInsightJsonKey.scenes
  | IUIInsightJsonKey.spokenLanguage
  | IUIInsightJsonKey.namedLocations
  | IUIInsightJsonKey.namedPeople
  | IUIInsightJsonKey.audioEffects
  | IUIInsightJsonKey.observedPeople
  | IUIInsightJsonKey.detectedObjects
  | IUIInsightJsonKey.clapperboards
  | IUIInsightJsonKey.logos
  | '';

export enum IUIInsightJsonKey {
  brands = 'brands',
  emotions = 'emotions',
  faces = 'faces',
  topics = 'topics',
  keywords = 'keywords',
  labels = 'labels',
  speakers = 'speakers',
  transcript = 'transcript',
  ocr = 'ocr',
  shots = 'shots',
  sentiments = 'sentiments',
  scenes = 'scenes',
  spokenLanguage = 'spokenLanguage',
  namedLocations = 'namedLocations',
  namedPeople = 'namedPeople',
  audioEffects = 'audioEffects',
  observedPeople = 'observedPeople',
  clapperboards = 'clapperboards',
  postProduction = 'postProduction',
  logos = 'logos',
  detectedObjects = 'detectedObjects'
}

export type IInsightGroupedKey = 'namedEntities' | 'postProduction';

export interface IInsightsPreparedPeopleData {
  key: string;
  faces?: IUIFace[];
  keframes?: IUIKeyframe[];
}

export type ThumbnailClass = 'set-thumb' | 'processing' | 'failed' | 'audio' | 'image' | 'block';

export enum SpriteType {
  Faces = 'Faces',
  KeyFrames = 'KeyFrames',
  ObservedPeople = 'ObservedPeople',
  Clapperboards = 'Clapperboards',
  ColorBars = 'ColorBars',
  Logos = 'Logos',
  LogosInstances = 'LogosInstances',
  DetectedObjects = 'DetectedObjects'
}

export enum IUIInsightNoneJsonKey {
  colorBars = 'colorBars'
}

export type IUInsightsKey = IUIInsightJsonKey | IUIInsightNoneJsonKey;

export enum BoundingBoxPropMsgName {
  PERSON_ID = 'person_id',
  FACE_ID = 'face_id',
  OBJECT_ID = 'detectedObject_id'
}
