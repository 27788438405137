import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Observable } from 'rxjs';

import { IAction } from '@common/modules/shared/interfaces';

import * as fromCustomizationDataState from '../reducers/index';
import * as fromCustomizationData from '../selectors';
import * as LanguageModelActions from '../actions/language-model.actions';
import * as SupportedLanguagesActions from '../actions/supported-languages.actions';
import * as PersonModelActions from '../actions/person-model.actions';
import * as LogoGroupsActions from '../actions/logo-groups.actions';
import * as BrandsModelActions from '../actions/brands-model.actions';
import * as LanguageIdActions from '../actions/language-id.actions';
import { ILanguageListContract } from '../reducers/supported-languages.reducer';
import { IFileBlob } from '../../indexing/interfaces';
import { IUploadTrainingDataFile } from '../../customization/components/language/model-file/interfaces';
import { IBrandsSettings } from '../../customization/components/brand/brand-container/interfaces';

import LanguageModel = Microsoft.VideoIndexer.Contracts.LanguageModel;
import AddLanguageModel = Microsoft.VideoIndexer.Contracts.AddLanguageModel;
import BrandContractV2 = Microsoft.VideoIndexer.Contracts.BrandContractV2;
import LogoGroupContract = Microsoft.VideoIndexer.Contracts.Customization.LogoGroupContract;
import TrainingDataFile = Microsoft.VideoIndexer.Contracts.TrainingDataFile;

@Injectable()
export class CustomizationDataStoreService {
  constructor(private readonly store: Store<fromCustomizationDataState.IState>) {}

  public loadAllCustomizations(): void {
    this.loadLanguageModels();
    this.loadPersonModels();
    this.loadBrandModels();
    this.loadLanguageId();
  }

  // People

  public loadPersonModels(force = false): void {
    this.store.dispatch(PersonModelActions.loadPersonModels({ force }));
  }

  public getPeopleModelById(id: string): Observable<Microsoft.VideoIndexer.Contracts.PersonModel> {
    return this.store.select(fromCustomizationData.getPersonModelById(id));
  }

  public getLogoGroupById(id: string): Observable<LogoGroupContract> {
    return this.store.select(fromCustomizationData.getLogoGroupById(id));
  }

  public get peopleModels(): Observable<Microsoft.VideoIndexer.Contracts.PersonModel[]> {
    return this.store.select(fromCustomizationData.getPeopleModels);
  }

  public get peopleModelsError(): Observable<boolean> {
    return this.store.select(fromCustomizationData.getPeopleError);
  }

  public isPeopleModelsLoaded(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isPeopleModelsLoaded);
  }

  public updatePeopleModel(model: Microsoft.VideoIndexer.Contracts.PersonModel) {
    this.store.dispatch(PersonModelActions.upsertPersonModel({ model: model }));
  }

  public deletePersonModel(model: Microsoft.VideoIndexer.Contracts.PersonModel) {
    this.store.dispatch(PersonModelActions.deletePersonModel({ model: model }));
  }

  public loadLanguageModels(): void {
    this.store.dispatch(LanguageModelActions.loadLanguageModels());
  }

  public loadLanguageModelFileContent(fileId: string, modelId: string): void {
    this.store.dispatch(LanguageModelActions.loadLanguageModelFileContent({ fileId, modelId }));
  }

  public loadLanguageModelCrisEditsContent(modelId: string): void {
    this.store.dispatch(LanguageModelActions.loadLanguageModelCrisEditsContent({ modelId }));
  }

  public getLanguageModelIsTraining(modelId: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.getLanguageModelIsTraining(modelId));
  }

  public getLanguageModelIsTrained(modelId: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.getLanguageModelIsTrained(modelId));
  }

  public getLanguageModelFile(modelId: string, fileId: string): Observable<TrainingDataFile> {
    return this.store.select(fromCustomizationData.getLanguageModelFile(modelId, fileId));
  }

  public trainModel(model: LanguageModel) {
    this.store.dispatch(LanguageModelActions.trainModel({ model }));
  }

  public addLanguageModel(newModel: AddLanguageModel) {
    this.store.dispatch(LanguageModelActions.addLanguageModel({ model: newModel }));
    this.store.dispatch(
      SupportedLanguagesActions.updateSupportedLanguage({
        supportedLanguage: {
          id: newModel.language,
          changes: { isCreating: true }
        }
      })
    );
  }

  public getSupportedLanguageIsSaving(key: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.getSupportedLanguageIsSaving(key));
  }

  public getLanguageModelIsSaving(modelId: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.getLanguageModelIsSaving(modelId));
  }

  public getLanguageModelIsExpanded(modelId: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.getLanguageModelIsExpanded(modelId));
  }

  public getLanguageModelFilesById(modelId: string): Observable<TrainingDataFile[]> {
    return this.store.select(fromCustomizationData.getLanguageModelFiles(modelId));
  }

  public toggleLanguageModelIsExpanded(modelId: string) {
    this.store.dispatch(LanguageModelActions.toggleLanguageModels({ modelId }));
  }

  public updateLanguageModel(newModel: AddLanguageModel, modelId: string, oldModel: LanguageModel) {
    this.store.dispatch(LanguageModelActions.updateLanguageModel({ model: newModel, modelId: modelId, oldModel: oldModel }));
  }

  public deleteLanguageModel(model: LanguageModel) {
    this.store.dispatch(LanguageModelActions.deleteLanguageModel({ model: model }));
  }

  public deleteLanguageModelFile(modelId: string, fileId: string) {
    this.store.dispatch(LanguageModelActions.deleteFile({ modelId, fileId }));
  }

  public downloadLanguageModelFile(modelId: string, fileId: string, fileName: string) {
    this.store.dispatch(LanguageModelActions.downloadFile({ modelId, fileId, fileName }));
  }

  public downloadFromEditLanguageModelFile(modelId: string, fileId: string, fileName: string) {
    this.store.dispatch(LanguageModelActions.downloadFromEditFile({ modelId, fileId, fileName }));
  }

  public uploadLanguageModelFile(file: IFileBlob, modelId: string) {
    this.store.dispatch(LanguageModelActions.uploadLanguageModelFile({ file: file, modelId: modelId }));
  }

  public updateSupportedLanguageModels(update: Update<ILanguageListContract>) {
    this.store.dispatch(SupportedLanguagesActions.updateSupportedLanguage({ supportedLanguage: update }));
  }

  public getLanguageModelUploadingFiles(modelId: string): Observable<IUploadTrainingDataFile[]> {
    return this.store.select(fromCustomizationData.getLanguageModelUploadingFiles(modelId));
  }

  public get languageModelListContract(): Observable<LanguageModel[]> {
    return this.store.select(fromCustomizationData.getLanguageModels);
  }

  public get trainedLanguageModels$(): Observable<LanguageModel[]> {
    return this.store.select(fromCustomizationData.getTrainedLanguageModels);
  }

  public getLanguageModelByLanguage(language: string): Observable<LanguageModel[]> {
    return this.store.select(fromCustomizationData.getLanguageModelByLanguage(language));
  }

  public getTrainedLanguageModelCount(language: string): Observable<number> {
    return this.store.select(fromCustomizationData.getTrainedLanguageModelCount(language));
  }

  public getLanguageModelsIsTraining(language: string): Observable<number> {
    return this.store.select(fromCustomizationData.getLanguageModelsIsTraining(language));
  }

  public isLanguageModelsLoading(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isLanguageModelsLoading);
  }

  // Brands

  public loadBrandModels(): void {
    this.store.dispatch(BrandsModelActions.loadBrandModels());
  }

  public loadBrandsSettings(): void {
    this.store.dispatch(BrandsModelActions.loadBrandsSettings());
  }

  public getBrandsIsSaving(enabled: boolean): Observable<boolean> {
    return this.store.select(fromCustomizationData.getBrandsIsSaving(enabled));
  }

  public addBrandModel(brand: BrandContractV2) {
    this.store.dispatch(BrandsModelActions.addBrandModel({ brand: brand }));
  }

  public updateBrandModel(brand: BrandContractV2) {
    this.store.dispatch(BrandsModelActions.updateBrandModel({ brand: brand }));
  }

  public deleteBrandModel(brand: BrandContractV2) {
    this.store.dispatch(BrandsModelActions.deleteBrandModel({ brand: brand }));
  }

  public updateBrandsSettings(settings: IBrandsSettings) {
    this.store.dispatch(BrandsModelActions.updateBrandsSettings({ settings: settings }));
  }

  public getBrandModelDuplication(brand: BrandContractV2): Observable<BrandContractV2[]> {
    return this.store.select(fromCustomizationData.getBrandModelDuplication(brand));
  }

  public getBrandModelUrlDuplication(brand: BrandContractV2): Observable<BrandContractV2[]> {
    return this.store.select(fromCustomizationData.getBrandModelUrlDuplication(brand));
  }

  public getBrandsByType(enabled: boolean): Observable<BrandContractV2[]> {
    return this.store.select(fromCustomizationData.getBrandsByType(enabled));
  }

  public isBrandModelsLoading(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isBrandModelsLoading);
  }

  public get brands(): Observable<BrandContractV2[]> {
    return this.store.select(fromCustomizationData.getBrands);
  }

  public get brandsSettings(): Observable<IBrandsSettings> {
    return this.store.select(fromCustomizationData.getBrandsSettings);
  }

  public isBrandModelsLoaded(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isBrandModelsLoaded);
  }

  public get brandsError(): Observable<boolean> {
    return this.store.select(fromCustomizationData.getBrandsError);
  }

  // LanguageId
  public loadLanguageId(): void {
    this.store.dispatch(LanguageIdActions.loadDefaultLanguages());
  }

  // Logos
  public loadLogoGroups(): void {
    this.store.dispatch(LogoGroupsActions.logoGroupsLoad());
  }

  public get logoGroups(): Observable<LogoGroupContract[]> {
    return this.store.select(fromCustomizationData.selectLogoGroups);
  }

  public get logoGroupsActions(): Observable<IAction[]> {
    return this.store.select(fromCustomizationData.getLogoGroupsActions);
  }

  public get logoGroupsError(): Observable<boolean> {
    return this.store.select(fromCustomizationData.selectLogoGroupError);
  }

  public isLogoGroupsLoaded(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isLogoGroupsLoaded);
  }
}
