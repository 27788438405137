import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FrontdoorInterceptor } from './interceptors/frontdoor.interceptor';
import { ApiService } from './services/api.service';
import { environment } from '../../../environments/environment';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ConfigService } from '../core/services/config/config.service';
import { LoggerService } from '../core/services/logger/logger.service';
import { GlobalHttpInterceptorService } from './interceptors/global.http.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    { provide: 'API_BASE', useValue: environment.apiBase },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FrontdoorInterceptor, multi: true },

    ApiService,
    ConfigService
  ],
  exports: [],
  declarations: []
})
export class ApiModule {
  constructor(private logger: LoggerService) {
    this.logger.log('[ApiModule] init');
  }
}
