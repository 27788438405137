export enum DownloadWidgetsName {
  INSIGHTS = 'insights',
  MEDIA = 'media'
}

export interface IDownloadVideoData {
  name: string;
  accountId: string;
  id: string;
  isBase: boolean;
  isEditable: boolean;
  videos: Microsoft.VideoIndexer.Contracts.VideoContract[];
}

export enum DownloadCaptionType {
  VTT = 'VTT',
  TTML = 'TTML',
  TXT = 'TXT',
  CSV = 'CSV',
  SRT = 'SRT'
}

export enum UIDownloadActionType {
  CLOSED_CAPTIONS = 'CLOSED_CAPTIONS',
  JSON = 'JSON',
  VIDEO = 'VIDEO',
  ARTIFACTS_ZIP = 'ARTIFACTS_ZIP',
  PROJECT_RENDERED_VIDEO = 'PROJECT_RENDERED_VIDEO'
}

export interface IDownloadCaption {
  type: DownloadCaptionType;
  includeAudioEffects: boolean;
  includeSpeakers: boolean;
  fileType?: string;
}
