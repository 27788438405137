import { createSelector } from '@ngrx/store';

import { IUserSettings } from '@common/modules/core/services/interfaces';

import { selectCoreState } from '../reducers';

export const getUserState = createSelector(selectCoreState, state => state.user);

export const getUserDetails = createSelector(getUserState, state => state.user);

export const isUserDetailsLoading = createSelector(getUserState, state => state.loading);

export const isUserDetailsLoaded = createSelector(getUserState, state => state.loaded);

export const loadUserDetailsError = createSelector(getUserState, state => state.error);

export const showSamplesTab = createSelector(getUserState, state => !!state?.user.showSamplesTab);

export const showGalleryByDefault = createSelector(getUserState, state => !!state?.user.showGalleryByDefault);

export const getUserSettings = createSelector(getUserState, state => getUserSettingsFromUserContract(state.user));

export const getUserEmail = createSelector(getUserState, state => state?.user?.email);

export const getSignedInUserEmail = createSelector(getUserState, state => state?.user?.signedInUserEmail);

export const getTenantId = createSelector(getUserState, state => state?.user?.tenantId);

export const getUserId = createSelector(getUserState, state => state?.user?.id);

export const autoCompleteEnabled = createSelector(getUserState, state => !!state?.user.autoCompleteSearch);

export function getUserSettingsFromUserContract(user: Microsoft.VideoIndexer.Contracts.UserContractSlim): IUserSettings {
  return {
    getNewsUpdates: user.getNewsUpdates,
    autoCompleteSearch: user.autoCompleteSearch,
    showSamplesTab: user.showSamplesTab,
    showGalleryByDefault: user.showGalleryByDefault,
    locale: user.locale,
    userType: user.userType
  };
}
