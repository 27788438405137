export const resources = {
  Cancel: '',
  Delete: '',
  FailDeletePerson: '',
  UnknownPeopleDeleteDialogTitle: '',
  UnknownPeopleDeleteDialogContent: '',
  UnknownPersonMoveToModelSuccessTitle: '',
  UnknownPersonMoveToModelSuccessText: '',
  UnknownPersonDeleteFaceFailedMessage: '',
  RegroupNotificationHeader: '',
  RegroupNotificationText: '',
  GroupFailedNotificationTitle: '',
  GroupFailedNotificationText: ''
};
