import { createAction, props } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import { INextPage } from '../models/gallery';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export const loadProjects = createAction('[Projects/API] Load Projects');
export const loadingProjects = createAction('[Projects] Loading Projects');
export const loadProjectsNextPage = createAction('[Projects/API] Load Next Page');
export const upsertVideos = createAction('[Projects/API] Upsert Videos', props<{ videos: SinglePlaylistSearchResultV2[]; nextPage?: INextPage }>());
export const updateVideo = createAction('[Projects] Update Video', props<{ video: SinglePlaylistSearchResultV2 }>());
export const updateVideoName = createAction('[Projects] Update Video Name', props<{ id: string; name: string }>());
export const updateThumbnailId = createAction('[Projects] Update Thumbnail Id', props<{ id: string; thumbnailId: string }>());
export const createProjectItem = createAction('[Projects] Add Create Project Item');
export const deleteVideo = createAction('[Projects/API] Delete Video', props<{ id: string; name: string }>());
export const deleteVideoSuccess = createAction('[Projects] Delete Video Success', props<{ id: string }>());
export const deleteVideoFailed = createAction('[Projects] Delete Video Failed', props<{ id: string }>());
export const deleteVideos = createAction('[Projects/API] Delete Videos', props<{ ids: string[] }>());
export const clearProjects = createAction('[Projects/API] Clear Projects');
export const loadProjectsError = createAction('[Projects] Load Projects Error', props<{ errorType?: APIErrors }>());
