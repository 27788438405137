import { UIActionTypeOptions } from '../../interfaces';

export enum UIFilterType {
  DROP_DOWN = 'DROP_DOWN',
  TEXT = 'TEXT',
  TIME = 'TIME'
}

export interface IUIFilterItem {
  id: string;
  index: number;
  actionType: UIActionTypeOptions;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
