import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';

import { take } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';

import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { IDialogEvent } from '../../../dialog/interfaces';
import { downloadCaptionsOptions } from '../../actions';
import { cancelAction, downloadAction, formatFilterAction } from './actions';
import { resources } from './resources';
import { DownloadCaptionType, IDownloadCaption } from '../../interfaces';
import { ActionButtonType } from '../../../action-button/interfaces';
import { IDownloadCationsData } from './interfaces';

@Component({
  selector: 'vi-download-closed-captions',
  templateUrl: './download-closed-captions.component.html',
  styleUrls: ['./download-closed-captions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadClosedCaptionsComponent extends FocusableComponent implements OnInit {
  @Input() public data: IDownloadCationsData;
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public resources = resources;
  public downloadCaptionsOptions = downloadCaptionsOptions;
  public formatFilterAction = formatFilterAction;
  public includeAudioEffects = false;
  public includeSpeakers = false;
  public selectedFormat: IAction;
  public downloadAction = downloadAction;
  public cancelAction = cancelAction;
  public ActionButtonType = ActionButtonType;
  public formatSelectId = 'format-filter';

  constructor(public translate: TranslateHelperService, public cdr: ChangeDetectorRef, private trackService: TrackService) {
    super();
  }

  public ngOnInit(): void {
    this.selectedFormat = this.downloadCaptionsOptions[0];
    this.setTranslation();
  }

  public onDownloadClicked() {
    const outputData: IDownloadCaption = {
      type: this.selectedFormat.value,
      includeAudioEffects: this.includeAudioEffects,
      includeSpeakers: this.includeSpeakers
    };

    if (this.selectedFormat.value === DownloadCaptionType.TTML) {
      outputData.fileType = 'application/ttml+xml';
    }

    const dialogEvent: IDialogEvent = {
      isDialogClose: false,
      dialogEventData: outputData
    };

    this.componentEventEmitter.emit(dialogEvent);
    this.trackAction();
  }

  public onCancelClicked() {
    const dialogEvent: IDialogEvent = {
      isDialogClose: true
    };
    this.componentEventEmitter.emit(dialogEvent);
  }

  private setTranslation() {
    this.translate
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {
        [this.cancelAction, this.downloadAction].forEach(action => {
          action.title = this.resources[action.key];
        });
        this.cancelAction = cloneDeep(this.cancelAction);
        this.downloadAction = cloneDeep(this.downloadAction);
        this.cdr.detectChanges();
      });
  }

  private trackAction() {
    const trackData = {
      category: EventCategory.MEDIA,
      data: {
        format: this.selectedFormat.id,
        includeAudioEffects: this.includeAudioEffects,
        includeSpeakers: this.includeSpeakers
      }
    };
    this.trackService.track(`closed_captions_popup.download.${(this.selectedFormat.type as string).toLowerCase()}`, trackData);
  }
}
