import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';

import { AccountResourceType } from '../auth/interfaces';
import { UrlService } from '../core/services/url/url.service';
import { AccountPermission } from '../shared/interfaces';

export declare interface IAPIRegion {
  domain: string;
  link: string;
  location: string;
  prefix: string;
  value: string;
  regionType: RegionType;
}

export enum UrlStreamingFormat {
  SMOOTH_STREAMING_FORMAT = 'SMOOTH_STREAMING_FORMAT',
  MPEG_DASH = 'MPEG_DASH',
  HLS_V4 = 'HLS_V4',
  HLS_V3 = 'HLS_V3'
}

export interface IAPIRequestParams {
  language?: Microsoft.VideoIndexer.Contracts.LanguageV2 | Microsoft.VideoIndexer.Contracts.LanguageV2[];
  includeUsage?: boolean;
  includeAmsInfo?: boolean;
}

export interface IGetIndexRequestParams extends IAPIRequestParams {
  includeStreamingUrls?: boolean;
  allowEdit?: boolean;
  includeSummarizedInsights?: boolean;
  includedInsights?: string;
}

export interface IGetStreamingUrlRequestParams {
  useProxy?: boolean;
  urlFormat?: string;
  tokenLifetimeInMinutes?: string;
  isBase?: boolean;
}

export interface IGetObservedPeoplePageTimeRequestParams {
  time?: number;
}

export interface IGetBoundingBoxPageTimeRequestParams {
  time?: number;
}

export interface IAzureSubscription {
  id: string;
  name: string;
  resources?: IAzureResource[];
  tenantId: string;
}

export interface IAzureResource {
  location: string;
  resourceGroup: string;
  name: string;
  id: string;
}

export interface IAzureLocation {
  name: string;
  key: string;
  type: environmentType;
  url: string;
}

export interface IRequestParam {
  key: string;
  value: string;
}

export declare interface IHttpWrapper {
  Cache: object;
  HttpClient: HttpClient;
  UrlUtility: UrlService;
  Injector: Injector;
  GetAuthApiBase(isAuthPath?: boolean): string;
  GetInternalApiBase(location?: string, defaultLocation?: boolean, forceCloudApi?: boolean): string;
  GetApiOperationsBase(removeApiLocation?: boolean, location?: string, forceCloudApi?: boolean): string;
  GetApiLocation(): string;
  GetApiMediaLocation(): string;
  GerProxyApiLocation(): string;
}

export enum environmentType {
  Develop = 'Develop',
  Trial = 'Trial',
  Paid = 'Paid'
}

export enum ApiType {
  Authorization = 'Authorization',
  Internals = 'Internals',
  Operations = 'Operations'
}

export enum ApiRegionProperties {
  PREFIX = 'prefix',
  LOCATION = 'location'
}

export enum envTypes {
  TRIAL = 'Trial',
  PAID = 'Paid',
  DEV = 'Dev'
}

export enum RegionType {
  TRIAL = 'trial',
  PAID = 'paid'
}

export interface IUploadRequestParams extends IAPIRequestParams {
  accountId?: string;
  name: string;
  privacy?: Microsoft.VideoIndexer.Contracts.PrivacyMode;
  description?: string;
  partition?: string;
  externalId?: string;
  callbackUrl?: string;
  metadata?: string;
  videoUrl?: string;
  fileName?: string;
  indexingPreset?: string;
  streamingPreset?: string;
  linguisticModelId?: string;
  animationModelId?: string;
  sendSuccessEmail: boolean;
  personModelId?: string;
  logoGroupId?: string;
  brandsCategories?: string;
  customLanguages?: string;
}

export type SupportedAIModels = ExcludableAIsModels | RequiredAIsModels;

export enum SensitiveAI {
  Faces = 'Faces',
  ObservedPeople = 'ObservedPeople',
  Emotions = 'Emotions',
  Labels = 'Labels'
}

export enum ExcludableAIsModels {
  OCR = 'OCR',
  TextualContentModeration = 'TextualContentModeration',
  Entities = 'Entities',
  Topics = 'Topics',
  Keywords = 'Keywords',
  Speakers = 'Speakers',
  Emotions = 'Emotions',
  VisualContentModeration = 'VisualContentModeration',
  Faces = 'Faces',
  Celebrities = 'Celebrities',
  KnownPeople = 'KnownPeople',
  Labels = 'Labels',
  ShotType = 'ShotType',
  RollingCredits = 'RollingCredits',
  DetectedObjects = 'DetectedObjects',
  Logos = 'Logos',
  ObservedPeople = 'ObservedPeople',
  MatchedPerson = 'MatchedPerson',
  PeopleDetectedClothing = 'PeopleDetectedClothing',
  FeaturedClothing = 'FeaturedClothing',
  Clapperboard = 'Clapperboard'
}

export enum RequiredAIsModels {
  AudioEffects = 'AudioEffects',
  ClosedCaptions = 'ClosedCaptions',
  Keyframes = 'Keyframes',
  Transcription = 'Transcription'
}

export interface IGetSasRequestParams {
  videoSize: string;
  fileName: string;
  allowEdit: boolean;
}

export interface IListRequestParams extends IAPIRequestParams {
  pageSize?: number;
  skip?: number;
  isBase?: string;
}

export interface ISearchRequestParams extends IAPIRequestParams {
  id?: string;
  partition?: string;
  externalId?: string;
  owner?: string | string[];
  face?: string | string[];
  query?: string;
  textScope?: string | string[];
  pageSize?: number;
  size?: number;
  hasSourceVideoFile?: boolean;
  scope?: string;
  isBase?: string;
}

export interface IGetPersonMaxConfidenceInVideosRequestParams extends IAPIRequestParams {
  personName: string;
  videoIds?: string[];
}

export interface ICaptionsRequestParams extends IAPIRequestParams {
  indexId?: string;
  format?: string;
  accessToken?: string;
  includeAudioEffects?: boolean;
  includeSpeakers?: boolean;
}

export interface IUpdateFaceRequestParams {
  newName?: string;
  name?: string;
}

export interface IReIndexRequestParams {
  indexingPreset?: string;
  streamingPreset?: string;
  sourceLanguage?: Microsoft.VideoIndexer.Contracts.LanguageV2;
  shortId?: string;
  linguisticModelId?: string;
  personModelId?: string;
  brandsCategories?: string;
  customLanguages?: string;
}

export interface IUpdateTranscriptRequestParams extends IAPIRequestParams {
  vttBody?: string;
  callbackUrl?: string;
  setAsSourceLanguage?: boolean;
  sendSuccessEmail: boolean;
}

export interface IGetIdByExternalRequestParams {
  externalId: string;
}

export interface IAddKeywordsRequestParams extends IAPIRequestParams {
  keyword: string;
}

export interface ICreateTextualVideoSummaryParamsCloud extends IAPIRequestParams {
  deploymentName: string;
  length?: string;
  style?: string;
  includedFrames?: Microsoft.VideoIndexer.Contracts.IncludedFrames;
}

export interface ICreateTextualVideoSummaryParamsEdge extends IAPIRequestParams {
  modelName: string;
  length?: string;
  style?: string;
  includedFrames?: Microsoft.VideoIndexer.Arc.Common.IncludedFrames;
}

export type ICreateTextualVideoSummaryParams = ICreateTextualVideoSummaryParamsCloud | ICreateTextualVideoSummaryParamsEdge;

export interface IRemoveBrandsRequestParams {
  brandId: number;
}

export interface IBrandsRequestParams {
  id: string;
}

export interface ISupportedAIsRequestParams {
  indexingPreset: string;
  includeFaces?: boolean;
}

export interface IAPISetThumbnailRequestParams {
  imageUrl: string;
}

export interface IAPISetThumbnailIdRequestParams {
  keyframeThumbnailId: string;
}

export interface IAddReaderParams {
  userId: string;
}

export interface IRemoveInviteParams {
  email: string;
}

export interface ITakeDownParams {
  abuseId: string;
}

export interface IInsightsWidgetParams {
  widgetTypes?: IWidgetType[];
  allowEdit?: boolean;
  title?: string;
}

export interface IPlayerWidgetParams {
  type?: IPlayerWidgetType[];
  autoplay?: boolean;
  showCaptions?: boolean;
  captions?: string;
}

export interface IUpdateRole {
  type: string;
}

export interface IAbuseParams {
  shortId: string;
  type: AbuseType;
  url: string;
  text: string;
}

export enum AbuseType {
  ThreatCyberHarassment,
  ChildEndangerment,
  OffensiveContent,
  ContentInfringement,
  Other
}

export interface IModelRequestParams {
  modelName?: string;
  language?: Microsoft.VideoIndexer.Contracts.LanguageV2;
  translationModelId?: string; // not in used at the moment
}

export interface IFileRequestParams {
  fileName: string;
  enable: boolean;
}

export interface IArtifactRequestParams {
  type: Microsoft.VideoIndexer.Contracts.ArtifactType;
}

export interface INotificationsRequestParams {
  region: string;
  accountType: NotificationAccountTypeRequestParam;
  eTag?: string;
}

export enum NotificationExtendedAccountType {
  ARM_AMSLESS = 'ARMAMSLESS'
}

export type NotificationAccountTypeRequestParam = AccountResourceType | NotificationExtendedAccountType;

export type IWidgetType = 'keywords' | 'transcript' | 'search' | 'brands' | 'annotations' | 'people' | 'sentiments' | 'logos';

export type IPlayerWidgetType = 'video' | 'audio';

export interface IApiSasContract {
  baseUrl: string;
  sasToken: string;
  relativePath: string;
}

export interface INetworkFileInfo {
  length?: number;
  contentType?: string;
  extension?: string;
}

export interface IAPIGetPeopleModel {
  personNamePrefix?: string;
}

export interface IAPIPostPeopleModel {
  name: string;
}

export interface IAPIPeopleCustomization {
  name: string;
  description?: string;
}

export interface IAPIPostFace {
  urls: string[];
}

export interface IAPIPostFaces {
  face: Microsoft.VideoIndexer.Contracts.Face;
}

export interface IAPIGetPeople {
  pageSize: number;
  skip: number;
  namePrefix?: string;
}

export interface IAPIGetFaces {
  pageSize: number;
  skip: number;
  sourceType?: Microsoft.VideoIndexer.Contracts.KnownPersonFaceSourceType;
}

export interface IVideoRenderRequestParams {
  sendCompletionEmail: boolean;
}

export interface IRenderFileDownloadUrl {
  downloadUrl: string;
}

export interface IAPIGetPeopleModel {
  personNamePrefix?: string;
}

export interface IAPIPostPeopleModel {
  name: string;
}

export interface IAPIPeopleCustomization {
  name: string;
}

export interface IAPIPostFaces {
  face: Microsoft.VideoIndexer.Contracts.Face;
}

export interface IAPIGetPeople {
  pageSize: number;
  skip: number;
  namePrefix?: string;
}

export interface IAPIGetFaces {
  pageSize: number;
  skip: number;
  sourceType?: Microsoft.VideoIndexer.Contracts.KnownPersonFaceSourceType;
}

export interface IVideoRenderRequestParams {
  sendCompletionEmail: boolean;
}

export interface IRenderFileDownloadUrl {
  downloadUrl: string;
}

export interface IAmILoggedInResult {
  result: boolean;
}

export enum TokenScope {
  ACCOUNT = 'Account',
  VIDEO = 'Video',
  PROJECT = 'Project',
  USER = 'User'
}

export interface IArmAccountAccessTokenPayload {
  permissionType: AccountPermission;
  scope: TokenScope;
}

export interface IArmAccountExtensionAccessTokenPayload extends IArmAccountAccessTokenPayload {
  extensionId?: string;
  tokenLifetimeInSeconds?: number;
}

export interface IArmVideoAccessTokenPayload extends IArmAccountAccessTokenPayload {
  videoId: string;
}

export interface IArmProjectAccessTokenPayload extends IArmAccountAccessTokenPayload {
  projectId: string;
}

export interface IArmAccessTokenResponse {
  accessToken: string;
}

export type SpeechDatasetLocale = {
  [key in string]: Microsoft.VideoIndexer.Contracts.DatasetKindEnum[];
};
