export enum ActionButtonType {
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
  PRIMARY_TEXT = 'primaryText',
  HEADER_BUTTON = 'headerButton',
  COMMAND = 'command',
  COMMAND_TRANSPARENT = 'commandTrasparent',
  ACTION_BUTTON = 'actionButton',
  MAIN_ACTION_MENU = 'mainActionMenu',
  SECONDARY_SLIM = 'secondarySlim'
}
