import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

export const browseForFileAction: IAction = {
  title: '',
  key: 'UploadChooseFilesButton',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'browseForFileAction'
};

export const enterFileUrlAction: IAction = {
  title: '',
  key: 'UploadEnterUrlButton',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'enterUrlAction'
};
