import { NavigationExtras } from '@angular/router';

import { createAction, props } from '@ngrx/store';

export const Go = createAction(
  '[Router] Go',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ path: any[]; queryParams?: object; extras?: NavigationExtras; removeParams?: boolean }>()
);
export const Back = createAction('[Router] Back');
export const Forward = createAction('[Router] Forward');
