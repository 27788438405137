import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/directories.actions';

import DirectoryContract = Microsoft.VideoIndexer.Contracts.DirectoryContract;

export interface IState extends EntityState<DirectoryContract> {
  loaded: boolean;
  error: boolean;
}

function selectTenantId(a: DirectoryContract): string {
  return a.tenantId;
}

export const adapter: EntityAdapter<DirectoryContract> = createEntityAdapter<DirectoryContract>({
  selectId: selectTenantId
});

const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false
});

const tenantsReducer = createReducer(
  initialState,
  on(actions.loadDirectoriesSuccess, (state, { directories: tenants }) => {
    const newState = {
      ...state,
      loaded: true
    };
    return adapter.setAll(tenants, newState);
  }),
  on(actions.loadDirectoriesFailed, state => {
    return {
      ...state,
      loaded: true,
      error: true
    };
  }),
  on(actions.reloadDirectories, state => {
    return {
      ...state,
      loaded: false,
      error: false
    };
  })
);

export function reducer(state: IState, action: Action) {
  return tenantsReducer(state, action);
}
