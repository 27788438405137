import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { Observable, take } from 'rxjs';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';
import { IndexingStoreService } from '../../../../services/indexing-store.service';
@Component({
  selector: 'vi-indexing-file-information',
  templateUrl: './file-information.component.html',
  styleUrls: ['./file-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInformationComponent implements OnInit {
  @Input() public isDisabled = false;

  public metadata: string;
  public description: string;
  public resources = resources;
  public isMultiFileMode$ = new Observable<boolean>();

  constructor(private translate: TranslateHelperService, private indexingStore: IndexingStoreService) {}

  public ngOnInit(): void {
    this.isMultiFileMode$ = this.indexingStore.isMultiFileMode$;
    this.indexingStore.metadata$.pipe(take(1)).subscribe(metadata => {
      this.metadata = metadata;
    });
    this.indexingStore.description$.pipe(take(1)).subscribe(description => {
      this.description = description;
    });

    this.translate.translateResourcesInstant(this.resources);
  }

  public onMetadataChanged(value: string) {
    if (this.metadata === value) {
      return;
    }
    this.metadata = value;
    this.indexingStore.updateMetadata(value);
  }

  public onDescriptionChanged(value: string) {
    if (this.description === value) {
      return;
    }
    this.description = value;
    this.indexingStore.updateDescription(value);
  }
}
