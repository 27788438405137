import {
  Component,
  OnInit,
  Inject,
  Optional,
  Output,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IDialogData, IDialogEvent } from './interfaces';
import { IAction } from '../../interfaces';
import { closeAction } from './actions';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { resources } from './resources';
import { ISubscriptions } from '../../../insights/interfaces';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { IEventCell } from '../../../../../apps/web/src/grid/components/grid-cell/interfaces';
import { ActionButtonType } from '../action-button/interfaces';

@Component({
  selector: 'app-vi-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  // Set close button action
  public closeAction: IAction = closeAction;
  // Show dialog content only when ready
  public ready = false;
  // Set component property for updating injected component data
  public updateInjectedComponentDataFunc = this.updateInjectedComponentData;
  // Update event data in component
  public updateInjectedEventDataFunc = this.updateEventData;

  public resources = resources;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentRef: ComponentRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public headerComponentRef: ComponentRef<any>;
  public ActionButtonType = ActionButtonType;

  @Output() public actionChange = new EventEmitter();
  // Reference to the injected component. (View component reference)
  @ViewChild('hostComponent', { read: ViewContainerRef, static: true }) private vcRef: ViewContainerRef;
  @ViewChild('hostHeaderComponent', { read: ViewContainerRef, static: true }) private vcHeaderRef: ViewContainerRef;

  private subscriptions: ISubscriptions = {};

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private translateService: TranslateHelperService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  public ngOnInit() {
    // Check if there is a component to inject
    if (this.data.component) {
      this.loadComponent();
    }

    // Check if there is a header component to inject
    if (this.data.headerComponent) {
      this.loadHeaderComponent();
    }

    this.subscriptions.translate = this.translateService.translateResources(this.resources).subscribe(() => {
      this.closeAction.titleAttribute = this.resources[this.closeAction.key];
    });
  }

  public loadComponent() {
    // Create reference to component
    this.componentRef = this.vcRef.createComponent(this.data.component);
    // Inject data to injected component
    this.componentRef.instance.data = this.data.componentData;
    // Listen to injected component event and fire the event up to the opener dialog component
    if (this.componentRef.instance.componentEventEmitter) {
      this.componentRef.instance.componentEventEmitter.subscribe((data: IDialogEvent) => {
        this.handleClick(data);
      });
    }
  }

  public loadHeaderComponent() {
    // Create reference to header component
    this.headerComponentRef = this.vcHeaderRef.createComponent(this.data.headerComponent);
    // Inject data to injected header component
    this.headerComponentRef.instance.data = this.data.headerComponentData;
  }

  public closeDialog(action: IAction): void {
    if (!this.data.disableAutoClose) {
      // Close the dialog and eit the event up
      this.dialogRef.close({ event: this.data.event, action: action, componentData: this.data.componentData });
    }

    // Notify close action
    this.actionChange.emit({ action: action });
  }

  public updatePrimaryButtonState(isDisabled: boolean) {
    this.data.primaryButton.isDisabled = isDisabled;
    this.cdr.detectChanges();
  }

  public updateSecondaryButtonState(isDisabled: boolean) {
    this.data.secondaryButton.isDisabled = isDisabled;
    this.cdr.detectChanges();
  }

  public handleClick(data: IDialogEvent) {
    if (data.isUpdateAction) {
      this.data.primaryButton.action = data.action;
      // Update primary button isDisable
      this.data.primaryButton.isDisabled = data.action.isDisabled;
      this.cdr.markForCheck();
      return;
    }

    if (data.isUpdatePrimaryButtonState) {
      this.updatePrimaryButtonState(data.action.isDisabled);
      return;
    }

    if (data.isUpdateSecondaryButtonState) {
      this.updateSecondaryButtonState(data.action.isDisabled);
      return;
    }

    this.actionChange.emit({
      event: this.data.event,
      action: data.action,
      dialogEventData: data.dialogEventData,
      componentData: this.data.componentData
    });
    if (data.isDialogClose) {
      this.closeDialog(data.action);
    }
  }

  public updateInjectedComponentData(data) {
    // Update injected component
    this.componentRef.instance.updateData(data);
  }

  public updateEventData(event: IEventCell) {
    if (this.data.event) {
      this.data.event = event;
    }
  }

  public ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.insightsCommonUtilsService.unsubscribeSubscriptions(this.subscriptions);
  }
}
