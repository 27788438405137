import { HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { SamplesAccountId } from '@common/modules/core/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { getSeconds } from '@common/modules/utils/time';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { RegionType } from '../interfaces';
import { CustomUrlEncodingCodec } from './custom-url-encoding-codec';

export const amILoggedInSuffix = '/auth/amILoggedIn';

export function trialLocationForSampleAccount(accountId: string) {
  return accountId === SamplesAccountId ? RegionType.TRIAL : '';
}

export function getCustomEncodingParams(params: Object): HttpParams {
  if (!params) {
    return null;
  }

  // Create http with custom encoding
  let customHttpParam = new HttpParams({ encoder: new CustomUrlEncodingCodec() });

  // Move all request params to customHttpParam object
  for (const key of Object.keys(params)) {
    customHttpParam = customHttpParam.append(key, params[key]);
  }

  return customHttpParam;
}

export function prepareVideoSummarizedInsights(
  videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
  injector: Injector
): Microsoft.VideoIndexer.Contracts.PlaylistContractV2 {
  const featureSwitchService = injector?.get(FeatureSwitchService);
  if (!featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights)) {
    return videoIndex;
  }

  const faces = getFaces(videoIndex.videos, videoIndex.duration ? getSeconds(videoIndex.duration) : videoIndex.durationInSeconds, true, injector);

  videoIndex.summarizedInsights = {
    name: videoIndex.name,
    id: videoIndex.id,
    privacyMode: videoIndex.privacyMode,
    duration: {
      seconds: videoIndex.duration ? getSeconds(videoIndex.duration) : videoIndex.durationInSeconds,
      time: videoIndex.duration
    },
    thumbnailVideoId: '',
    thumbnailId: '',
    faces,
    keywords: [],
    sentiments: [],
    emotions: [],
    audioEffects: [],
    labels: [],
    brands: [],
    namedPeople: [],
    namedLocations: [],
    statistics: null,
    topics: [],
    framePatterns: []
  };

  return videoIndex;
}

export function prepareProjectSummarizedInsights(
  videoIndex: Microsoft.VideoIndexer.Contracts.ProjectPlaylistContractV2,
  injector: Injector
): Microsoft.VideoIndexer.Contracts.ProjectPlaylistContractV2 {
  const featureSwitchService = injector?.get(FeatureSwitchService);
  if (!featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights)) {
    return videoIndex;
  }

  const faces = getFaces(videoIndex.videos, videoIndex.duration ? getSeconds(videoIndex.duration) : videoIndex.durationInSeconds, false, injector);
  videoIndex.summarizedInsights = {
    name: videoIndex.name,
    id: videoIndex.id,
    privacyMode: videoIndex.privacyMode,
    duration: {
      seconds: videoIndex.duration ? getSeconds(videoIndex.duration) : videoIndex.durationInSeconds,
      time: videoIndex.duration
    },
    thumbnailVideoId: '',
    thumbnailId: '',
    faces,
    keywords: [],
    sentiments: [],
    emotions: [],
    audioEffects: [],
    labels: [],
    brands: [],
    namedPeople: [],
    namedLocations: [],
    statistics: null,
    topics: [],
    framePatterns: []
  };

  return videoIndex;
}

// TODO - move this function into selector once adding NGRX store to our widgets
function getFaces(videos: Microsoft.VideoIndexer.Contracts.VideoContract[], duration: number, isBase: boolean, injector: Injector) {
  const trackService = injector?.get(TrackService);
  const startTime = performance.now();
  const facesDictionary = [];

  for (const video of videos) {
    if (video.insights?.faces) {
      for (const face of video.insights.faces) {
        let seenDuration = 0;
        for (const instance of face.instances) {
          seenDuration += getSeconds(instance.adjustedEnd) - getSeconds(instance.adjustedStart);
        }

        facesDictionary[face.id] = {
          confidence: face.confidence,
          description: face.description,
          id: face.id,
          name: face.name,
          seenDuration: facesDictionary[face.id] ? facesDictionary[face.id].seenDuration + seenDuration : seenDuration,
          thumbnailId: face.thumbnailId,
          title: face.title,
          videoId: video.id
        };

        facesDictionary[face.id].seenDurationRatio = facesDictionary[face.id].seenDuration / duration;
      }
    }
  }

  const sortedFaces = Object.values(facesDictionary).sort((f1, f2) => f2.seenDuration - f1.seenDuration);
  const endTime = performance.now();
  trackService?.trackPerformance('prepare_summarized_insights', EventCategory.INSIGHTS, endTime - startTime);

  return sortedFaces;
}
