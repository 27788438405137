import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import * as BrandActions from '../actions/brands-model.actions';
import { IBrandsSettings } from '../../customization/components/brand/brand-container/interfaces';

import BrandContractV2 = Microsoft.VideoIndexer.Contracts.BrandContractV2;

export interface IState extends EntityState<BrandContractV2> {
  // additional entities state properties
  selectedAccountId: string;
  loaded: boolean;
  savingIncluded: boolean;
  savingExcluded: boolean;
  error: boolean;
  settings: IBrandsSettings;
}

export function selectAccountId(a: BrandContractV2): number {
  return a.id;
}

export const adapter: EntityAdapter<BrandContractV2> = createEntityAdapter<BrandContractV2>({
  selectId: selectAccountId
});

export const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedAccountId: null,
  loaded: false,
  savingIncluded: false,
  savingExcluded: false,
  error: false,
  settings: Object.create(null)
});

const brandModelReducer = createReducer(
  initialState,
  on(BrandActions.upsertBrandModel, (state, { brand }) => {
    return adapter.upsertOne(brand, {
      ...state,
      savingIncluded: false,
      savingExcluded: false
    });
  }),
  on(BrandActions.upsertBrandModels, (state, { brands }) => {
    return adapter.upsertMany(brands, {
      ...state,
      loaded: true
    });
  }),
  on(BrandActions.upsertBrandsSettings, (state, { settings }) => {
    return {
      ...state,
      settings: settings
    };
  }),
  on(BrandActions.clearBrandModels, (state, {}) => {
    return adapter.removeAll({
      ...state,
      selectedAccountId: null,
      loaded: false,
      savingIncluded: false,
      savingExcluded: false,
      error: false,
      settings: Object.create(null)
    });
  }),
  on(BrandActions.addBrandModel, (state, { brand }) => {
    return {
      ...state,
      savingIncluded: brand.enabled,
      savingExcluded: !brand.enabled
    };
  }),
  on(BrandActions.discardBrandSaving, (state, { brand }) => {
    return {
      ...state,
      savingIncluded: brand.enabled ? false : state.savingIncluded,
      savingExcluded: !brand.enabled ? false : state.savingExcluded
    };
  }),
  on(BrandActions.deleteBrandModel, (state, { brand }) => {
    return adapter.removeOne(brand.id, state);
  }),
  on(BrandActions.failLoadBrandModels, state => {
    return {
      ...state,
      error: true
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return brandModelReducer(state, action);
}

export const getSelectedAccountId = (state: IState) => state.selectedAccountId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectAccountIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllAccounts = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
