import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { IUIEmotion } from '../emotions/interfaces';

@Component({
  selector: 'app-vi-insights-emotions-map',
  templateUrl: './emotions-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./emotions-map.component.scss']
})
export class EmotionsMapComponent implements OnInit {
  @Input() public emotions: IUIEmotion[];
  @Input() public showConfidence: boolean;

  constructor() {}

  public ngOnInit() {}
}
