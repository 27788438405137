import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';

@Directive({
  selector: '[viShowIfAmsless]'
})
export class ShowIfAmslessDirective implements OnInit {
  @Input('viShowIfAmsless') show: boolean = true;
  private isAmslessAccount: boolean;

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef, private coreStoreService: CoreStoreService) {
    this.coreStoreService.isAmslessAccount$.subscribe(isAmslessAccount => {
      this.isAmslessAccount = isAmslessAccount;
    });
  }

  ngOnInit() {
    if (this.show) {
      this.showIfAmsless();
    } else {
      this.hideIfAmsless();
    }
  }

  private showIfAmsless() {
    if (!this.isAmslessAccount) {
      this.hideElement();
    } else if (this.isAmslessAccount) {
      this.showElement();
    }
  }

  private hideIfAmsless() {
    if (!this.isAmslessAccount) {
      this.showElement();
    } else if (this.isAmslessAccount) {
      this.hideElement();
    }
  }

  private showElement() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hideElement() {
    this.viewContainer.clear();
  }
}
