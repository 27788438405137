import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@common/modules/auth/services/auth.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { TrackService } from '@common/modules/core/services/track';

@Pipe({ name: 'imageSrc' })
export class ImageSrcPipe implements PipeTransform {
  constructor(private httpClient: HttpClient, private authService: AuthService, private logger: LoggerService, private track: TrackService) {}

  public transform(url: string, accountId: string): Observable<string> {
    return this.loadImage(url, accountId);
  }

  private loadImage(url: string, accountId: string): Observable<string> {
    if (!url || url.includes('/thumbnails/null')) {
      return null;
    }
    const accessToken = this.authService.getAccessToken(null, accountId);
    return this.httpClient
      .get(url, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .pipe(
        switchMap(imageObj => {
          return this.getImageUrl(imageObj);
        }),
        catchError(err => {
          this.logger.error('[Image pipe] throw an error', err);
          this.track.trackError(err);
          // eslint-disable-next-line deprecation/deprecation
          return throwError(err);
        })
      );
  }

  private getImageUrl(imageObj: Blob): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(imageObj);
      reader.onloadend = () => {
        observer.next(reader.result as string);
      };
      reader.onerror = err => {
        observer.error(err);
      };
    });
  }
}
