import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VIUIModule } from '@vi-ui/core';

import { SharedModule } from '@common/modules/shared/shared.module';

import { SidebarContainerComponent } from './pages/sidebar-container/sidebar-container.component';
import { HelpComponent } from './components/help/help.component';
import { AccountsListComponent } from './components/accounts-list/accounts-list.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { ThemeOptionComponent } from './components/user-settings/theme-option/theme-option.component';
import { NotificationsPanelComponent } from './components/notifications-panel/notifications-panel.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';

@NgModule({
  declarations: [
    SidebarContainerComponent,
    HelpComponent,
    AccountsListComponent,
    UserSettingsComponent,
    ThemeOptionComponent,
    NotificationsPanelComponent,
    NotificationCardComponent
  ],
  imports: [CommonModule, SharedModule, VIUIModule],
  exports: [SidebarContainerComponent]
})
export class SidebarModule {}
