import { Injectable, OnDestroy } from '@angular/core';

import { find, remove, upperFirst, cloneDeep } from 'lodash-es';

import { ISubscriptions } from '@common/modules/insights/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { InsightsCommonUtilsService } from '@common/modules/insights-common/insights-common-utils.service';
import { IAction } from '@common/modules/shared/interfaces';
import { UIFaceType } from '@common/modules/insights/components/faces/interfaces';
import { IDurationDetails } from '@common/modules/utils/time';

import { IUIRow } from '../../grid/components/grid-body/interfaces';
import { IUIGridCell, UIGridCellTypes } from '../../grid/components/grid-cell/interfaces';
import { IUICellNameType } from '../../customization/components/customization-people/interfaces';
import { resources } from '../../customization/components/customization/services/resources';

@Injectable()
export class CustomizationDataService implements OnDestroy {
  public resources = resources;

  private subscriptions: ISubscriptions = {};

  constructor(private insightsCommonUtilsService: InsightsCommonUtilsService, private translateService: TranslateHelperService) {
    this.subscriptions.translateResources = this.translateService.translateResources(this.resources).subscribe(() => {});
  }

  public ngOnDestroy() {
    // Unsubscribe all subscriptions.
    this.insightsCommonUtilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  // TO-DO: Animation - Add types - T = PEOPLE || ANIMATED
  public addModelData<T>(model: T, models: T[]) {
    models.unshift(model);
  }

  public addModelToData(
    model: Microsoft.VideoIndexer.Contracts.PersonModel,
    cells: IUIGridCell[],
    gridData: IUIRow[],
    modelGridData?: IUIRow,
    isUnshiftToList?: boolean
  ) {
    if (isUnshiftToList) {
      gridData.unshift({ cells: cells, edit: !model.id, expand: false }, { cells: null, rowDetails: [] });
      return;
    }
    // In case it is search request, set expand as previous value
    gridData.push(
      {
        cells: cells,
        edit: !model.id,
        expand: modelGridData && modelGridData.expand
      },
      { cells: null, rowDetails: [] }
    );
  }

  public createUniqueAction(currentAction: IAction, actionTitle: string, id: string): IAction {
    const currentRowAction = cloneDeep(currentAction);
    // Add title and remove whitespace
    currentRowAction.titleAttribute = actionTitle;
    currentRowAction.id += id;
    currentRowAction.id = currentRowAction.id.replace(/ /g, '');

    return currentRowAction;
  }

  public deleteModel(modelsGridData: IUIRow[], index: number): void {
    if (!modelsGridData || (modelsGridData && !modelsGridData.length)) {
      return;
    }
    // Delete row data for model details
    modelsGridData.splice(index, 2);
  }

  public deleteModelByIdFromGrid(modelsGridData: IUIRow[], id: string, nameCellIndex: number): void {
    const index = this.getModelDataIndexById(modelsGridData, id, nameCellIndex);
    // Delete row data + sub row for model details
    modelsGridData.splice(index, 2);
  }

  public deleteModelData<T>(id: string, models: T[]): void {
    remove(models, p => {
      return p.id === id;
    });
  }

  public getModelDataIndexById(modelsGridData: IUIRow[], modelId: string, nameCellIndex: number): number {
    let modelIndex = null;
    modelsGridData.forEach((model, index) => {
      if (model.cells && model.cells[nameCellIndex].id === modelId) {
        modelIndex = index;
        return;
      }
    });
    return modelIndex;
  }

  public getCounterModelCell(model: Microsoft.VideoIndexer.Contracts.PersonModel, modelType?: UIFaceType): IUIGridCell {
    const modelName = upperFirst(modelType);
    const itemResources = {};
    itemResources[`${modelName}Customization${modelName}CounterSingular`] = '';
    itemResources[`${modelName}Customization${modelName}CounterPlural`] = '';

    // Casting for typescript
    const peopleModel = <Microsoft.VideoIndexer.Contracts.PersonModel>model;
    // Verify that there's a counter
    if (peopleModel.personsCount !== undefined) {
      this.translateService.translateResources(itemResources, { number: peopleModel.personsCount });
      const value = itemResources[`${modelName}Customization${modelName}Counter${peopleModel.personsCount === 1 ? 'Singular' : 'Plural'}`];
      // Model counter
      return {
        id: model.id,
        name: IUICellNameType.FacesModelName,
        title: this.resources[IUICellNameType.FacesModelName],
        placeholder: this.resources.FacesCustomizationEnterModelName,
        value: value,
        type: UIGridCellTypes.TEXT,
        loading: false,
        edit: false,
        isDisabled: !peopleModel.personsCount || peopleModel.personsCount < 1
      };
    }

    return null;
  }

  public updateModel(
    gridData: IUIRow[],
    model: Microsoft.VideoIndexer.Contracts.PersonModel,
    faceType: UIFaceType,
    nameCellIndex: number,
    counterCellIndex: number,
    actionsCellIndex: number,
    index?: number,
    infoModelAction?: IAction,
    ...indexToSkip: number[]
  ): void {
    // If Index, search by it. (Less calculation time O(1))
    if (index !== undefined) {
      this.updateModelByIndex(gridData, model, index, faceType, nameCellIndex, counterCellIndex, actionsCellIndex, infoModelAction, ...indexToSkip);
      return;
    }
    // Otherwise, search by ID
    this.updateModelById(gridData, model, faceType, nameCellIndex, counterCellIndex, actionsCellIndex, infoModelAction, ...indexToSkip);
  }

  public updateModelActions(model: IUIRow, id: string, faceType: UIFaceType, infoModelAction: IAction, actionsCellIndex: number) {
    // Add model info
    const modelInfo = cloneDeep(infoModelAction);
    modelInfo.value = id;
    const faceName = upperFirst(faceType);
    // Translate action
    const itemResources = {};
    itemResources[`${faceName}Customization${faceName}ModelIdTitle`] = '';

    this.translateService.translateResources(itemResources, { modelId: id });
    modelInfo.title = itemResources[`${faceName}Customization${faceName}ModelIdTitle`];
    model.cells[actionsCellIndex].actionsList.push(modelInfo);
  }

  public disableRowActions(row: IUIRow, isDisabled: boolean) {
    if (row && row.cells) {
      row.cells.forEach(c => {
        if (c) {
          // Update new pointer for cell
          c.isDisabled = isDisabled;
          c = cloneDeep(c);
        }
      });
    }
  }

  public disableRowDetailsActions(row: IUIRow, isDisabled: boolean) {
    if (row) {
      row.rowDetails.forEach(p => {
        this.disableRowActions(p, isDisabled);
      });
    }
  }

  public getFromNowText(duration: IDurationDetails): string {
    let dateValue = this.resources.UtilsServiceJustNow;
    if (duration) {
      const itemResources = {};
      itemResources[`UtilsService${duration.units}`] = '';
      this.translateService.translateResources(itemResources, { number: duration.number });
      dateValue = itemResources[`UtilsService${duration.units}`];
    }
    return dateValue;
  }

  private updateModelByIndex(
    gridData: IUIRow[],
    model: Microsoft.VideoIndexer.Contracts.PersonModel,
    index: number,
    faceType: UIFaceType,
    nameCellIndex: number,
    counterCellIndex: number,
    actionsCellIndex: number,
    infoModelAction?: IAction,
    ...indexToSkip: number[]
  ) {
    const modelGrid = gridData[index];
    this.updateModelCells(modelGrid, model, nameCellIndex, counterCellIndex, actionsCellIndex, faceType, infoModelAction, ...indexToSkip);
  }

  private updateModelById(
    gridData: IUIRow[],
    model: Microsoft.VideoIndexer.Contracts.PersonModel,
    faceType: UIFaceType,
    nameCellIndex: number,
    counterCellIndex: number,
    actionsCellIndex: number,
    infoModelAction?: IAction,
    ...indexToSkip: number[]
  ) {
    const modelGrid = find(gridData, p => {
      return !p.rowDetails && p.cells[nameCellIndex].id === model.id;
    });
    this.updateModelCells(modelGrid, model, nameCellIndex, counterCellIndex, actionsCellIndex, faceType, infoModelAction, ...indexToSkip);
  }

  private updateModelCells(
    modelGrid: IUIRow,
    model: Microsoft.VideoIndexer.Contracts.PersonModel,
    nameCellIndex: number,
    counterCellIndex: number,
    actionsCellIndex: number,
    faceType: UIFaceType,
    infoModelAction?: IAction,
    ...indexToSkip: number[]
  ) {
    if (!modelGrid || (modelGrid && !modelGrid.cells)) {
      return;
    }
    // Check if new model
    const isNewModel = modelGrid.cells[nameCellIndex].id ? false : true;
    modelGrid.edit = false;

    if (!modelGrid.cells) {
      return;
    }
    modelGrid.cells.forEach((c: IUIGridCell, index: number) => {
      if (!c) {
        return;
      }

      const isIndex = indexToSkip && indexToSkip.find(i => index === i);
      if (isIndex !== undefined) {
        return;
      }
      // Update model info
      c.id = model.id;
      c.value = model.name;
      // Update model actions
      if (c.actionsList) {
        c.actionsList.forEach(a => {
          a.isDisabled = !model.id;
        });
      }
    });

    if (counterCellIndex !== null) {
      modelGrid.cells[counterCellIndex] = this.getCounterModelCell(model, faceType);
    }

    // Check if new model and add Model info ID (Use in faces customization)
    if (isNewModel && infoModelAction) {
      this.updateModelActions(modelGrid, model.id, faceType, infoModelAction, actionsCellIndex);
    }
  }
}
