import { RouterStateSnapshot } from '@angular/router';

import { RouterStateSerializer } from '@ngrx/router-store';

import { IRouterStateUrl } from '../../interfaces';

export class VIRouterSerializer implements RouterStateSerializer<IRouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
    let route = routerState.root;

    while (route?.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;
    const filteredUrl = url.split('?')[0];

    // Get route name
    const data = route.data;
    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, filteredUrl, data };
  }
}
