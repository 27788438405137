const getCookies = () =>
  // eslint-disable-next-line @microsoft/sdl/no-cookies
  document?.cookie?.split(';').reduce((acc, current) => {
    const [name, ...value] = current.trim().split('=');
    acc[name] = value.join('=');

    return acc;
  }, {});

export const getCookie = (name: string) => getCookies()[name];
