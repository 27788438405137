import { UIActionType } from '../../../insights/interfaces';
import { IAction } from '../../../shared/interfaces';
import { DownloadCaptionType, UIDownloadActionType } from './interfaces';

export const downloadAction: IAction = {
  icon: 'i-vi-download',
  title: 'Download',
  key: 'Download',
  type: UIActionType.DOWNLOAD,
  value: UIActionType.DOWNLOAD,
  selected: false,
  selectable: true,
  hasDrop: true,
  id: 'download'
};

/** Download captions options are different in internal and embed
In internal - should NOT be translated, therefore there is fixed title (for example - DownloadCaptionType.SRT)
In embed - should be translated accordingly the key property **/
export const downloadCaptionsOptions: IAction[] = [
  {
    icon: '',
    key: 'DownloadOptionsSRT',
    title: DownloadCaptionType.SRT,
    selected: false,
    value: DownloadCaptionType.SRT,
    type: UIDownloadActionType.CLOSED_CAPTIONS,
    selectable: true,
    id: 'SRT'
  },
  {
    icon: '',
    key: 'DownloadOptionsVTT',
    title: DownloadCaptionType.VTT,
    selected: false,
    value: DownloadCaptionType.VTT,
    type: UIDownloadActionType.CLOSED_CAPTIONS,
    selectable: true,
    id: 'VTT'
  },
  {
    icon: '',
    key: 'DownloadOptionsTTML',
    title: DownloadCaptionType.TTML,
    selected: false,
    value: DownloadCaptionType.TTML,
    type: UIDownloadActionType.CLOSED_CAPTIONS,
    selectable: true,
    id: 'TTML'
  },
  {
    icon: '',
    key: 'DownloadOptionsTXT',
    title: DownloadCaptionType.TXT,
    selected: false,
    value: DownloadCaptionType.TXT,
    type: UIDownloadActionType.CLOSED_CAPTIONS,
    selectable: true,
    id: 'TXT'
  },
  {
    icon: '',
    key: 'DownloadOptionsCSV',
    title: DownloadCaptionType.CSV,
    selected: false,
    value: DownloadCaptionType.CSV,
    type: UIDownloadActionType.CLOSED_CAPTIONS,
    selectable: true,
    id: 'CSV'
  }
];

const downloadJsonAction: IAction = {
  icon: '',
  key: 'DownloadOptionsJSON',
  title: '',
  selected: false,
  value: UIDownloadActionType.JSON,
  selectable: true,
  id: 'JSON'
};

const downloadArtifactAction: IAction = {
  icon: '',
  key: 'DownloadOptionsDownloadArtifacts',
  title: '',
  selected: false,
  value: UIDownloadActionType.ARTIFACTS_ZIP,
  selectable: true,
  id: 'ARTIFACTS'
};

const downloadVideoAction: IAction = {
  icon: '',
  key: 'DownloadOptionsVideo',
  title: '',
  selected: false,
  value: UIDownloadActionType.VIDEO,
  selectable: true,
  id: 'VIDEO'
};

const downloadRenderedProjectAction: IAction = {
  icon: '',
  key: 'DownloadOptionsRenderedProject',
  title: '',
  selected: false,
  value: UIDownloadActionType.PROJECT_RENDERED_VIDEO,
  selectable: true,
  id: 'RENDERED_VIDEO'
};

const closedCaptionAction: IAction = {
  icon: '',
  key: 'ClosedCaptions',
  title: '',
  selected: false,
  value: UIDownloadActionType.CLOSED_CAPTIONS,
  selectable: true,
  id: 'closedCaptions',
  separated: true
};

export const downloadOptionsWithClosedCaptionOption = [
  downloadJsonAction,
  downloadArtifactAction,
  downloadVideoAction,
  downloadRenderedProjectAction,
  closedCaptionAction
];

export const downloadOptionsWithClosedCaptionList = [
  downloadJsonAction,
  ...downloadCaptionsOptions,
  downloadArtifactAction,
  downloadVideoAction,
  downloadRenderedProjectAction
];

export const downloadOptionsForEdgeVideo = [downloadJsonAction, downloadArtifactAction, closedCaptionAction];
