<div (keyup.esc)="toggleSideMenu()"
  [appAccessibilitySubMenuHandler]="isOpened && isResponsive"
  [toggleFuncOnNullRelatedTarget]="true"
  (toggleFunc)="toggleSideMenu()">
  <div [appCdkTrapFocus]="isOpened && isResponsive"
    role="navigation"
    class="side-menu-container"
    [ngClass]="{'sub-menu-opened': subMenuOpened}">
    <div class="main-side-menu"
      [ngClass]="{'menu-opened': isOpened,
              'accessible': accessibilityMode,
              'mobile': isMobile}">
      <div class="side-menu-row"
        id="toggleBtn"
        [attr.title]="isOpened ? resources?.CloseSideMenu : resources?.OpenSideMenu"
        [attr.aria-label]="isOpened ? resources?.CloseSideMenu : resources?.OpenSideMenu"
        tabindex="0"
        role="button"
        (appAccessibleClick)="toggleSideMenu()">
        <div class="row-wrapper hamburger"
          [ngClass]="{'responsive': isResponsive}">
          <i [ngClass]="isMobile ? 'i-vi-close-small' : isResponsive? 'i-vi-hamburger' : isOpened ? 'i-vi-chev-left' : 'i-vi-chev-right'"></i>
        </div>
      </div>
      <div class="navigation-items"
        role="tablist">
        <div class="side-menu-row"
          [ngClass]="{'selected-state': option === sideMenuOption.Media, 'expandable': showExtensionsSubMenu}"
          [attr.title]="resources?.MediaFiles"
          [attr.aria-label]="resources?.MediaFiles"
          tabindex="0"
          role="tab"
          [attr.aria-selected]="option === sideMenuOption.Media"
          (appAccessibleClick)="navigate(sideMenuOption.Media)">
          <div class="row-wrapper">
            <i class="i-vi-editor"></i>
            <span *ngIf="isOpened">{{resources?.MediaFiles}}</span>
          </div>
        </div>
        <ng-container *ngIf="isResponsive">
          <ng-container *ngTemplateOutlet="edgeExtensionsSubMenu">
          </ng-container>
        </ng-container>
        <vi-role-based-view-control-wrapper [requiredPermission]="AccountPermission.CONTRIBUTOR"
          [currentPermission]="currentPermission$ | async"
          [viewAction]="ViewActionType.DISABLE"
          (viewControlChanged)="onMenuItemsControlChanged($event)">
          <div class="side-menu-row"
            [ngClass]="{'selected-state': option === sideMenuOption.Settings}"
            [attr.title]="resources?.AccountSettings"
            [attr.aria-label]="resources?.AccountSettings"
            [attr.disabled]="isMenuItemDisabled ? isMenuItemDisabled : null"
            tabindex="0"
            role="tab"
            [attr.aria-selected]="option === sideMenuOption.Settings"
            (appAccessibleClick)="navigate(sideMenuOption.Settings)">
            <div class="row-wrapper">
              <i class="i-vi-account-prefs"></i>
              <span *ngIf="isOpened">{{resources?.AccountSettings}}</span>
            </div>
          </div>
          <div class="side-menu-row expandable"
            [ngClass]="{'selected-state': option === sideMenuOption.Customizations}"
            [attr.title]="resources?.ModelCustomizations"
            [attr.aria-label]="resources?.ModelCustomizations"
            [attr.disabled]="isMenuItemDisabled ? isMenuItemDisabled : null"
            tabindex="0"
            role="tab"
            [attr.aria-selected]="option === sideMenuOption.Customizations"
            (appAccessibleClick)="navigate(sideMenuOption.Customizations)">
            <div class="row-wrapper">
              <i class="i-vi-customization"></i>
              <span *ngIf="isOpened">{{resources?.ModelCustomizations}}</span>
            </div>
          </div>
        </vi-role-based-view-control-wrapper>
      </div>
    </div>
    <div class="sub-menu"
      *ngIf="showCustomizationSubMenu">
      <vi-customization-sub-menu>
      </vi-customization-sub-menu>
    </div>
    <ng-container *ngIf="!isResponsive">
      <ng-container *ngTemplateOutlet="edgeExtensionsSubMenu">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #edgeExtensionsSubMenu>
  <div class="sub-menu"
    *ngIf="showExtensionsSubMenu">
    <vi-edge-extensions-sub-menu>
    </vi-edge-extensions-sub-menu>
  </div>
</ng-template>