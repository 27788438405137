import { Injectable } from '@angular/core';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { IDialogData, IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { InternalFaceGateFormComponent } from '@common/modules/shared/components/internal-face-gate-form/internal-face-gate-form.component';
import { IFaceGatePopupEvent, IFaceGatePopupInfo } from '@common/modules/shared/components/internal-face-gate-form/interfaces';
import { AccountPermission, StorageCacheKey } from '@common/modules/shared/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { isMsCorpEmailAddress } from '@common/modules/utils/string';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { PermissionService } from '@common/modules/shared/services/permission.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { resources } from '../resources';
import { CoreStoreService } from './core-store.service';
import { highRiskRegions } from '../high-risk-regions';

@Injectable({
  providedIn: 'root'
})
export class FaceGatePopupService {
  private readonly MAX_COUNT_SHOW_NEW_SESSION = 3;

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private localStorageService: LocalStorageService,
    private dialogService: DialogService,
    private coreStoreService: CoreStoreService,
    private toastService: ToastService,
    private permissionService: PermissionService,
    private trackService: TrackService
  ) {}

  public showPopup(account: Microsoft.VideoIndexer.Contracts.AccountContract, userEmail: string) {
    this.openFaceGateDialog(account.id, account.name, userEmail);
    this.trackService.track('internal_face_gate_form.open', {
      category: EventCategory.FACE_GATE,
      data: {
        origin: 'help'
      }
    });
  }

  public autoShowPopup(account: Microsoft.VideoIndexer.Contracts.AccountContract, userEmail: string) {
    this.openFaceGateDialog(account.id, account.name, userEmail);

    const showCounterLocalStorageKey = this.getLocalStorageKey(StorageCacheKey.ShowFaceGatePopupCounter, account.id);
    // showCounter - existing plus this time
    const showCounterLocalStorageValue = this.localStorageService.getItem(showCounterLocalStorageKey) ?? '0';
    const showCounter = parseInt(showCounterLocalStorageValue, 10) + 1;
    // increase show counter value
    if (showCounter < this.MAX_COUNT_SHOW_NEW_SESSION) {
      this.localStorageService.setItem(showCounterLocalStorageKey, showCounter.toString());
    } else {
      //do not show again after reach maximum
      const doNotShowLocalStorageKey = this.getLocalStorageKey(StorageCacheKey.DoNotShowFaceGatePopup, account.id);
      this.localStorageService.setItem(doNotShowLocalStorageKey, true.toString());
    }

    this.trackService.track('internal_face_gate_form.open', {
      data: {
        origin: 'auto',
        count: showCounter
      },
      category: EventCategory.FACE_GATE
    });
  }

  public onUpdateFaceFeaturesSuccess() {
    this.toastService.success(resources.FaceGateInternalApproveSuccess, false);
    this.trackService.track('internal_face_gate_form.submit.success', {
      category: EventCategory.FACE_GATE
    });
  }

  public onUpdateFaceFeaturesFailed(error) {
    this.toastService.error(error, resources.FaceGateInternalApproveFailed, false);
    this.trackService.track('internal_face_gate_form.submit.failed', {
      category: EventCategory.FACE_GATE,
      data: {
        error
      }
    });
  }

  public isShowPopupEnabled(email: string, isAccountEnabledWithFaces: boolean, accountPermission: AccountPermission, accountLocation: string) {
    // Check feature switch
    const showFaceGatePopupEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.InternalFaceGatePopup);
    if (!showFaceGatePopupEnabled) {
      return false;
    }

    // Check account has at least contributor permission
    if (!this.permissionService.ensurePermission(accountPermission, AccountPermission.CONTRIBUTOR)) {
      return false;
    }

    // Check account is not in high risk location
    if (highRiskRegions.includes(accountLocation)) {
      return false;
    }

    // Check if account limited with faces
    if (isAccountEnabledWithFaces) {
      return false;
    }

    // Check if user is MS corp
    if (!isMsCorpEmailAddress(email)) {
      return false;
    }

    return true;
  }

  public shouldAutoShow(account: Microsoft.VideoIndexer.Contracts.AccountContract, accountPermission: AccountPermission, userEmail: string) {
    if (!this.isShowPopupEnabled(userEmail, account.limitedAccessFeatures.isFaceIdentificationEnabled, accountPermission, account.location)) {
      return false;
    }

    // Check DoNotShowFaceGatePopup cache param
    const doNotShowLocalStorageKey = this.getLocalStorageKey(StorageCacheKey.DoNotShowFaceGatePopup, account.id);
    if (this.localStorageService.getItem(doNotShowLocalStorageKey)) {
      return false;
    }

    return true;
  }

  private openFaceGateDialog(accountId: string, accountName: string, userEmail: string) {
    const popupInfo: IFaceGatePopupInfo = {
      accountName: accountName,
      userEmail: userEmail
    };

    const dialogData: IDialogData = {
      class: 'face-gate-dialog',
      title: resources.FaceGatePopupTitle,
      component: InternalFaceGateFormComponent,
      componentData: popupInfo
    };

    const dialogRef = this.dialogService.openDialog(dialogData, '600px');

    dialogRef.componentInstance.actionChange.subscribe((res: IDialogEvent) => {
      const data = <IFaceGatePopupEvent>res?.dialogEventData;
      if (data?.isAccepted) {
        this.coreStoreService.acceptUseOfFacialIdentification(data?.signature, data?.useCase);
        // if user click dismiss do not show again auto popup (in new session)
      } else if (data?.isDismissed) {
        const localStorageKey = this.getLocalStorageKey(StorageCacheKey.DoNotShowFaceGatePopup, accountId);
        this.localStorageService.setItem(localStorageKey, true.toString());
      }
    });
  }

  private getLocalStorageKey(key: string, accountId: string) {
    return `${key}_${accountId}`;
  }
}
