export const resources = {
  Download: '',
  DownloadOptionsJSON: '',
  DownloadOptionsSRT: '',
  DownloadOptionsVTT: '',
  DownloadOptionsTTML: '',
  DownloadOptionsTXT: '',
  DownloadOptionsCSV: '',
  DownloadOptionsDownloadArtifacts: '',
  DownloadOptionsVideo: '',
  DownloadOptionsRenderedProject: '',
  DownloadClosedCaptions: '',
  ClosedCaptions: ''
};
