import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import { IExpandedListItem } from '@common/modules/shared/components/expanded-item/interfaces';
import { guid } from '@common/modules/utils/string';

import * as LanguageActions from '../actions/language-model.actions';
import { IUploadTrainingDataFile, ICreateListItem, IUILanguageModel } from '../../customization/components/language/model-file/interfaces';

import TrainingDataFile = Microsoft.VideoIndexer.Contracts.TrainingDataFile;
export interface ILanguageModelContract extends IUILanguageModel, IExpandedListItem, ICreateListItem {}

export interface IState extends EntityState<ILanguageModelContract> {
  // additional entities state properties
  selectedAccountId: string;
  loaded: boolean;
  error: boolean;
}

export function selectModelId(a: ILanguageModelContract): string {
  return a.id;
}

export const adapter: EntityAdapter<ILanguageModelContract> = createEntityAdapter<ILanguageModelContract>({
  selectId: selectModelId
});

export const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedAccountId: null,
  loaded: false,
  error: false
});

const languageModelReducer = createReducer(
  initialState,
  on(LanguageActions.upsertLanguageModel, (state, { model }) => {
    return adapter.upsertOne(
      {
        ...model,
        isCreating: false,
        isExpanded: state.entities[model.id] ? state.entities[model.id].isExpanded : true,
        editMode: false,
        uploadingFiles: state.entities[model.id] ? state.entities[model.id].uploadingFiles : []
      },
      {
        ...state
      }
    );
  }),
  on(LanguageActions.upsertLanguageModels, (state, { models }) => {
    return adapter.upsertMany(
      models.map(model => ({
        ...model,
        isCreating: false,
        isExpanded: state.entities[model.id] ? state.entities[model.id].isExpanded : true,
        editMode: false,
        uploadingFiles: state.entities[model.id] ? state.entities[model.id].uploadingFiles : []
      })),
      {
        ...state,
        loaded: true,
        error: false
      }
    );
  }),
  on(LanguageActions.addLanguageModel, (state, { model }) => {
    return {
      ...state
    };
  }),
  on(LanguageActions.clearLanguageModels, (state, {}) => {
    return adapter.removeAll({
      ...state,
      selectedAccountId: null,
      loaded: false,
      error: false
    });
  }),
  on(LanguageActions.deleteLanguageModel, (state, { model }) => {
    return adapter.removeOne(model.id, state);
  }),
  on(LanguageActions.uploadLanguageModelFile, (state, { modelId }) => {
    return adapter.updateOne({ id: modelId, changes: { isCreating: true } }, state);
  }),
  on(LanguageActions.cancelLanguageModelFile, (state, { modelId }) => {
    return adapter.updateOne({ id: modelId, changes: { isCreating: false } }, state);
  }),
  on(LanguageActions.updateLanguageModelFileContent, (state, { file, modelId }) => {
    let files = state.entities[modelId].files;
    const index = files.findIndex(f => f.id === file.id);
    files = files.filter(f => f.id !== file.id);
    files.splice(index, 0, file);
    return adapter.updateOne({ id: modelId, changes: { files: files } }, state);
  }),
  on(LanguageActions.updateLanguageModelCrisEditsContent, (state, { crisEdits, modelId }) => {
    let files = state.entities[modelId].files;
    files = files.filter(f => f.id !== `cris-edits-id-${modelId}`);
    const file: TrainingDataFile = {
      id: `cris-edits-id-${modelId}`,
      name: 'From transcript edits',
      fileName: 'From transcript edits',
      hasCrisEdits: true,
      modelId: modelId,
      crisEdits: crisEdits,
      enable: false,
      creator: '',
      creationTime: '',
      modelType: null,
      description: '',
      lastUpdateTime: '',
      active: false,
      fileContent: '',
      groupId: null
    };
    files.splice(0, 0, file);
    return adapter.updateOne({ id: modelId, changes: { files: files } }, state);
  }),
  on(LanguageActions.updateLanguageModel, (state, { model, modelId, oldModel }) => {
    return adapter.updateOne({ id: modelId, changes: { isCreating: true, name: model.modelName } }, state);
  }),
  on(LanguageActions.trainModel, (state, { model }) => {
    return adapter.updateOne({ id: model.id, changes: { state: 'Waiting' } }, state);
  }),
  on(LanguageActions.toggleLanguageModels, (state, { modelId }) => {
    return adapter.updateOne({ id: modelId, changes: { isExpanded: !state.entities[modelId]?.isExpanded } }, state);
  }),
  on(LanguageActions.deleteLanguageModelFile, (state, { fileId, modelId }) => {
    const lm = state.entities[modelId];
    const newFiles = lm?.files?.filter(file => file.id !== fileId);
    // after deleting a file we reset the languageModelId as it isn't trained anymore
    return adapter.updateOne({ id: modelId, changes: { files: newFiles, languageModelId: guid(0), state: 'None' } }, state);
  }),
  on(LanguageActions.failLoadLanguageModels, state => {
    return {
      ...state,
      error: true
    };
  }),
  on(LanguageActions.updateUploadProgress, (state, { file: uploadFile, modelId }) => {
    const lm = state.entities[modelId];
    const fileIndex = lm?.uploadingFiles?.findIndex(file => file.id === uploadFile.id);
    let uploadingFiles: IUploadTrainingDataFile[] = [];
    if (fileIndex === -1) {
      uploadingFiles.push(uploadFile);
    } else {
      uploadingFiles = [...lm.uploadingFiles];
      uploadingFiles[fileIndex] = { ...uploadFile };
    }
    return adapter.updateOne({ id: modelId, changes: { uploadingFiles: uploadingFiles } }, state);
  }),
  on(LanguageActions.removeUpdatingFile, (state, { modelId, id }) => {
    const lm = state.entities[modelId];
    const newUploadingFiles = lm?.uploadingFiles?.filter(file => file.id !== id);
    return adapter.updateOne({ id: modelId, changes: { uploadingFiles: newUploadingFiles } }, state);
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return languageModelReducer(state, action);
}

export const getSelectedAccountId = (state: IState) => state.selectedAccountId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectLanguageIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllLanguages = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
