import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { AccessTokenInterceptor } from './interceptors/access-token.interceptor';
import { AuthSessionInterceptor } from './interceptors/auth-session.interceptor';
import { AccessTokenService } from './services/access-token.service';
import { AuthSessionService } from './services/auth-session.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthSessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    AuthService,
    AccessTokenService,
    AuthSessionService
  ]
})
export class AuthModule {}
