import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  ViewChildren,
  QueryList
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { cloneDeep } from 'lodash-es';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { Direction, IAction } from '../../../shared/interfaces';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { UIActionType } from '../../../insights/interfaces';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { isMainButtonPressed } from '../../../utils/event';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent implements OnInit, OnChanges, OnDestroy {
  // Public
  public mainActionIcon: string;
  public showTooltip = false;
  public containerWidth = 0;
  public width = 'auto';
  public buttonWidth = 'auto';
  public subMenuWidth = 'auto';
  public subMenuPadding = 24;
  public expanded = false;
  public defaultCSS = {
    padding: 8,
    fontSize: 14
  };
  public openRightClass = false;
  public UIActionType = UIActionType;
  public menuId: string;
  public menuItemRefs: ElementRef[];
  public Direction = Direction;

  // Input
  @Input() public action?: IAction;
  @Input() public type: ActionButtonType;
  @Input() public actionList: IAction[] = [];
  @Input() public hasOpenerIcon = false;
  @Input() public changeTextOnSelect = false;
  @Input() public defaultValue = 'en-US';
  @Input() public tooltipStatusType = '';
  @Input() public hasTooltip = false;
  @Input() public sizeClass = '';
  @Input() public calcWidth = true;
  @Input() public minDropDownWidth: string;
  @Input() public isDisabled = false;
  @Input() public activeContainer = false;
  @Input() public showAccessibilityTooltip = false;
  @Input() public updateDropdownLocation = false;
  @Input() public openSubMenuDown = false;
  // Fallback url for image
  @Input() public fallbackUrl?: string;
  @Input() public id?: string;

  // Output
  @Output() public actionChange = new EventEmitter<IAction>(true);
  @Output() public mouseOverActionButton = new EventEmitter();
  @Output() public mouseLeaveActionButton = new EventEmitter();
  @Output() public focusInActionButton = new EventEmitter();
  @Output() public isMenuOpened = new EventEmitter<boolean>();

  @ViewChild('dropdownMenu', { static: false }) public dropdownMenu: ElementRef;
  @ViewChild('dropdownContainer', { static: false }) public dropdownContainer: ElementRef;
  @ViewChildren('menuItems') public menuItems: QueryList<ElementRef>;
  @ViewChild('MainButton', { read: ElementRef }) public mainButton: ElementRef;

  // Privates
  private documentClickHandlerRef;
  private isActionClick = false;
  private documentClickBound = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    private commonUtilsService: InsightsCommonUtilsService,
    private translate: TranslateHelperService,
    private focusManager: FocusManagerService,
    public utils: UtilsService,
    public el: ElementRef,
    private renderer: Renderer2
  ) {}

  public get whiteFocus(): boolean {
    return this.type === ActionButtonType.HEADER_BUTTON;
  }

  public ngOnInit() {
    this.mainActionIcon = this.action ? this.action.icon : '';
    if (this.sizeClass === 'small') {
      this.defaultCSS.fontSize = 13;
      this.defaultCSS.padding = 9;
    }
    this.actionList.forEach(action => {
      action.selected = false;
    });
    if (this.calcWidth) {
      this.calcDropDownWidth();
    } else {
      this.subMenuWidth = `${this.calcMaxTextWidth() + this.subMenuPadding}px`;
    }
    if (!this.id && this.action) {
      this.id = this.action.id;
    }
    this.menuId = `submenu-${this.id}`;
  }

  public applyDirection(): void {
    if (!this.dropdownMenu) {
      return;
    }
    this.utils.getContainerRectAsync(this.el.nativeElement).then(elementRect => {
      this.utils.getBodyRectAsync().then(bodyRect => {
        // Get dropdown height
        this.utils.getContainerRectAsync(this.dropdownMenu.nativeElement).then(dropdownRect => {
          // Calculate height with padding and dropdown height
          const totalDropdownHeight = elementRect.bottom + dropdownRect.height + 20;
          const bottomOffset = Math.abs(bodyRect.bottom - totalDropdownHeight);
          // Check if drop-down is overlapping with screen border
          if (dropdownRect.width > bodyRect.right - dropdownRect.right) {
            this.renderer.removeClass(this.dropdownContainer.nativeElement, 'left');
          }

          if (totalDropdownHeight >= bodyRect.bottom && bottomOffset <= totalDropdownHeight && !this.openSubMenuDown) {
            this.renderer.setStyle(this.dropdownMenu.nativeElement, 'top', `-${dropdownRect.height}px`);
          }
          this.renderer.setStyle(this.dropdownContainer.nativeElement, 'opacity', `1`);
        });
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.defaultValue && !changes.defaultValue.firstChange) {
      // Set back action to default value
      if (this.changeTextOnSelect) {
        const type = this.action.type;
        let action: IAction;
        this.actionList.forEach(actionListItem => {
          actionListItem.selected = false;
          if (actionListItem.value === this.defaultValue) {
            action = actionListItem;
          }
        });
        this.action = cloneDeep(action);
        this.action.type = type;
      }
    }
    if (changes.actionList) {
      if (this.calcWidth) {
        this.calcDropDownWidth();
      } else {
        this.subMenuWidth = `${this.calcMaxTextWidth() + this.subMenuPadding}px`;
      }
    }
  }

  public handleArrowNav(index: number) {
    this.focusManager.focusVia(() => this.menuItems.find((_, i) => i === index), this.cdr);
  }

  // modulo function has undefined behaviour for negative numbers so we implement our own
  public calcIndex(index: number) {
    return index > -1 && index < this.actionList.length ? index : this.actionList.length - Math.abs(index);
  }

  public onEscHandler(event: KeyboardEvent) {
    // toggle sub window
    if (this.expanded) {
      this.selectAction(this.action, null);

      // Focus triggered button
      if (this.id) {
        this.focusManager.focusByQuery(`#${this.id}`);
      }
    }
    this.isMenuOpened.emit(this.expanded);
  }

  // User clicked on action button
  public selectAction(action: IAction, e: MouseEvent | KeyboardEvent) {
    this.expanded = !this.expanded;
    this.cdr.detectChanges();
    this.actionChange.emit(action);
    this.isActionClick = true;
    this.bindToDocumentClick();
    if (this.updateDropdownLocation) {
      setTimeout(() => {
        this.applyDirection();
      });
    } else {
      if (this.expanded) {
        this.renderer.setStyle(this.dropdownContainer.nativeElement, 'opacity', `1`);
      }
    }
    this.isMenuOpened.emit(this.expanded);
  }

  // User choose an action from dropdown menu
  public selectSubAction(action: IAction) {
    if (action.isDisabled) {
      return;
    }
    // Apply action
    if (this.changeTextOnSelect) {
      const type = this.action.type;
      this.action.isButtonAction = false;
      this.actionChange.emit(action);
      action.isButtonAction = true;
      this.action = cloneDeep(action);
      this.action.type = type;
    } else {
      this.actionChange.emit(action);
    }

    // Close drop
    this.expanded = false;

    // Focus triggered button
    if (this.id) {
      this.focusManager.focusByQuery(`#${this.id}`);
    }

    this.isMenuOpened.emit(this.expanded);
    // Calc new width
    if (this.calcWidth) {
      this.calcDropDownWidth();
    }
  }

  public onMouseOver(state: boolean) {
    this.showTooltip = state;
    this.cdr.detectChanges();

    if (state) {
      this.mouseOverActionButton.emit();
    } else {
      this.mouseLeaveActionButton.emit();
    }
  }

  public handleFocusInActionButton() {
    this.focusInActionButton.emit();
  }

  public generateTooltipText(): string {
    // Get current value
    let actionTitle = '';
    for (const action of this.actionList) {
      if (action.value === this.defaultValue) {
        actionTitle = action.title;
        break;
      }
    }
    const itemResources = { TooltipStatus: '' };
    this.translate.translateResources(itemResources, {
      statusType: this.tooltipStatusType,
      currentStatus: `<strong>${actionTitle}</strong>`
    });

    return itemResources.TooltipStatus;
  }

  public ngOnDestroy(): void {
    this.unbindToDocumentClick();
  }

  private bindToDocumentClick() {
    if (!this.documentClickBound) {
      this.documentClickHandlerRef = this.documentClickHandler.bind(this);
      this.document.addEventListener('mouseup', this.documentClickHandlerRef);
      this.documentClickBound = true;
    }
  }

  private unbindToDocumentClick() {
    if (this.documentClickBound) {
      this.document.removeEventListener('mouseup', this.documentClickHandlerRef);
      this.documentClickBound = false;
    }
  }

  private documentClickHandler(event: MouseEvent) {
    if (!isMainButtonPressed(event)) {
      return;
    }
    if (!this.isActionClick) {
      this.expanded = false;
      this.isMenuOpened.emit(this.expanded);
      this.cdr.detectChanges();
      this.unbindToDocumentClick();
      this.actionList.forEach(action => {
        action.selected = false;
      });
    }
    this.isActionClick = false;
  }

  private calcMaxTextWidth() {
    // Calc texts max width
    let max = 0;
    this.actionList.forEach(action => {
      const text = action.title;
      let textWidth = this.commonUtilsService.measureText(text, this.defaultCSS.fontSize);
      if (action.icon) {
        textWidth += 20;
      }
      if (max < textWidth) {
        max = textWidth;
      }
    });

    return max;
  }

  private calcDropDownWidth() {
    const dropdownTextsWidth = this.calcMaxTextWidth();
    const padding = this.defaultCSS.padding;

    // Calc button  width
    const mainActionWidth = this.commonUtilsService.measureText(this.action ? this.action.title : ':', this.defaultCSS.fontSize);
    let totalWidth = Math.max(dropdownTextsWidth, mainActionWidth);

    // Add button padding
    totalWidth += 2 * padding;

    // Add icons padding
    if (this.mainActionIcon) {
      totalWidth += 20;
    }
    if (this.hasOpenerIcon) {
      totalWidth += 20;
    }

    // Add main text padding
    totalWidth += 8;

    if (totalWidth) {
      this.width = `${totalWidth}px`;
    }

    if (this.action && this.action.title) {
      this.buttonWidth = this.width;
    }
  }
}
