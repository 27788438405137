export const resources = {
  IndexingMultiFilesLabel: '',
  IndexingSingleFileLabel: '',
  UploadingNumberFiles: '',
  NumberFilesUploaded: '',
  NumberFilesNotUploaded: '',
  NumberNotUploaded: '',
  SingleFileUploaded: '',
  SingleFileNotUploaded: '',
  Uploading: '',
  Failed: '',
  Progress: '',
  EventGridErrorMsg: '',
  AccountDurationQuotaErrorMsg: '',
  AccountDailyCountQuotaErrorMsg: '',
  AccountDailyDurationQuotaErrorMsg: '',
  CreateAccountWithAzureSubscription: '',
  CreateUnlimitedAccountWithAzure: '',
  AmsAadAppNotInTenantErrorMsg: '',
  AmsUnreachableErrorMsg: '',
  StorageUnreachableErrorMsg: '',
  UploadFileManagedIdentityMissingErrorMsg: '',
  UploadFileStorageAccessDeniedErrorMsg: '',
  StorageAccessDeniedErrorMsgLinkText: ''
};
