import { createFeatureSelector, createSelector, ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import * as fromRouter from '@ngrx/router-store';

import { IRouterStateUrl } from '../../interfaces';
import { IVIRoute } from '../../../app/routing/interfaces';
import { VIRoutingMap } from '../../../app/routing/routes';

export interface IState {
  router: fromRouter.RouterReducerState<IRouterStateUrl>;
}

export const routerReducers: ActionReducerMap<IState> = {
  router: routerReducer
};

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<IRouterStateUrl>>('router');

export const getQueryParams = createSelector(getRouterState, state => state?.state.queryParams);

export const getRouteParams = createSelector(getRouterState, state => state?.state.params);

export const getRouteData = createSelector(getRouterState, state => state?.state?.data as IVIRoute);

export const isGalleryRoute = createSelector(
  getRouteData,
  state =>
    state?.path === VIRoutingMap.galleryLibrary.path ||
    state?.path === VIRoutingMap.galleryProjects.path ||
    state?.path === VIRoutingMap.gallerySamples.path
);

export const isLibraryRoute = createSelector(getRouteData, state => state?.path === VIRoutingMap.galleryLibrary.path);
