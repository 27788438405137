export const resources = {
  UploadGuidelinesSubTitle1: '',
  UploadGuidelinesSection1List1: '',
  UploadGuidelinesSection1List2: '',
  UploadGuidelinesLink1: '',
  UploadGuidelinesSubTitle2: '',
  UploadGuidelinesSection2List1: '',
  UploadGuidelinesSection2List2: '',
  UploadGuidelinesLink2: '',
  UploadGuideBackButton: '',
  UploadGuidelinesMaxFilesLimit: '',
  UploadGuidelinesTitle: ''
};
