import { Injectable, Inject } from '@angular/core';

import { AuthService } from '@common/modules/auth/services/auth.service';
import { UrlService } from '@common/modules/core/services/url/url.service';
import { WINDOW } from '@common/modules/core/services/window.token';
import { SessionHandlerService } from '@common/modules/shared/services/session-handler.service';

import { ISignInURLs, LoginProvider } from '../pages/login/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private urls: ISignInURLs;

  constructor(
    private authService: AuthService,
    private urlService: UrlService,
    private sessionHandlerService: SessionHandlerService,
    @Inject(WINDOW) private window
  ) {
    this.initProperties();
  }

  public get email() {
    return this.authService.authenticatedUpn;
  }

  public isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  public signIn(provider: LoginProvider) {
    let returnUrl = this.urlService.getQueryParam('returnUrl') || '';
    const accountId = this.urlService.getQueryParam('accountId');
    if (returnUrl) {
      returnUrl = this.urls.returnUrl + encodeURIComponent(returnUrl);
      if (accountId) {
        returnUrl += encodeURIComponent(`&accountId=${accountId}`);
      }
    } else if (accountId) {
      returnUrl = this.urls.returnUrl + encodeURIComponent(`/?accountId=${accountId}`);
    }

    const sessionId = this.sessionHandlerService.sessionId;
    let sessionParam = '';
    if (sessionId) {
      sessionParam = `&aisid=${sessionId}`;
    }

    let sourceParam = this.urlService.getQueryParam('source') || '';
    if (sourceParam) {
      sourceParam = `&source=${sourceParam}`;
    }

    const env = this.urlService.getQueryParam('env') || '';
    let envParam = '';
    if (env) {
      envParam = `&env=${env}`;
    }

    const url = this.urls.base + provider + sessionParam + returnUrl + sourceParam + envParam;
    this.window.location.replace(url);
  }

  private initProperties() {
    this.urls = {
      base: '/Account/SignIn?provider=',
      returnUrl: '&returnUrl=',
      consent: 'Api/Buy/RedirectToGraphConsent/'
    };
  }
}
