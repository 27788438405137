import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { upperFirst } from 'lodash-es';

export enum TagType {
  REST = 'rest',
  ERROR = 'error'
}

@Component({
  selector: 'vi-ui-tag',
  templateUrl: './tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() public type: TagType = TagType.REST;
  @Input() public content: string;
  @Input() public enableRemove: boolean = true;
  @Input() public term?: string = '';
  @Input() public removeTagTitleAttribute?: string;

  @Output() public closeClicked = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  public onRemoveTag() {
    this.closeClicked.emit();
    this.cdr.detectChanges();
  }

  private get formattedTerm() {
    return upperFirst(this.term.toLowerCase());
  }
}
