import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { ConfigService } from '../../../core/services/config/config.service';

@Component({
  selector: 'app-vi-embed-footer',
  templateUrl: './embed-footer.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./embed-footer.component.scss']
})
export class EmbedFooterComponent implements OnInit {
  // Public
  public resources = resources;

  // Ctor
  constructor(private translateHelper: TranslateHelperService, private config: ConfigService) {}

  public ngOnInit() {
    this.translateHelper.translateResources(this.resources, { year: 2018 });
    this.setPoweredByText();
  }

  private setPoweredByText() {
    const poweredByResources = { InsightsWidgetPoweredBy: '', InsightsWidgetPoweredByFF: '' };
    this.translateHelper.translateResources(poweredByResources).subscribe(() => {
      this.resources.InsightsWidgetPoweredBy = this.config.isFairFaxEnv()
        ? poweredByResources.InsightsWidgetPoweredByFF
        : poweredByResources.InsightsWidgetPoweredBy;
    });
  }
}
