import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { IStripData } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class StripService {
  public triggerStripData: BehaviorSubject<IStripData>;
  public triggerNotificationsStripData: BehaviorSubject<IStripData>;

  private id = 0;
  constructor() {
    this.triggerStripData = new BehaviorSubject<IStripData>(null);
    this.triggerNotificationsStripData = new BehaviorSubject<IStripData>(null);
  }

  public trigger(data: IStripData): number {
    const dataWithId = { ...data, id: ++this.id };
    this.triggerStripData.next(dataWithId);
    return dataWithId.id;
  }

  public hideStrip(id: number) {
    this.triggerStripData.next({ id: id, show: false, trackingName: 'strip' });
  }

  public triggerNotification(data: IStripData): number {
    const dataWithId = { ...data, id: ++this.id };
    this.triggerNotificationsStripData.next(dataWithId);
    return dataWithId.id;
  }

  public hideNotificationStrip(id: number) {
    this.triggerNotificationsStripData.next({ id: id, show: false, trackingName: 'strip.notification' });
  }
}
