import { createReducer, Action, on } from '@ngrx/store';

import * as UserActions from '../actions/user.actions';

import UserContractSlim = Microsoft.VideoIndexer.Contracts.UserContractSlim;

export interface IState {
  // additional entities state properties
  user: UserContractSlim;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

const initialState: IState = {
  // additional entity state properties
  loaded: false,
  loading: false,
  user: {
    id: '',
    name: '',
    isAuthenticated: false,
    email: '',
    signedInUserEmail: '',
    userType: null,
    joinTime: '',
    getNewsUpdates: false,
    getVideoNotifications: false,
    showSamplesTab: false,
    autoCompleteSearch: false,
    locale: '',
    isSuspended: false,
    isAdmin: false,
    showGalleryByDefault: false,
    image: '',
    tenantId: ''
  },
  error: false
};

const userReducer = createReducer(
  initialState,
  on(UserActions.addUser, (state, { user }) => {
    return {
      ...state,
      user: {
        ...user
      },
      loaded: true,
      loading: false
    };
  }),
  on(UserActions.updateUser, (state, { user }) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...user
      }
    };
  }),
  on(UserActions.loadUserDetailsInProgress, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(UserActions.loadUserDetailsFailed, state => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }),
  on(UserActions.loadUserNotAuthenticated, state => {
    return {
      ...state,
      loading: false,
      loaded: true
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return userReducer(state, action);
}
