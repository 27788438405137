import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { UpdateVideoIndexAction } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MediaCommonService {
  public videoIndexUpdate$: Subject<Microsoft.VideoIndexer.Contracts.PlaylistContractV2> = new Subject();
  public videoIndexUpdateError$: Subject<string> = new Subject();
  public videoIndexUpdateSuccess$: Subject<string> = new Subject();
  public videoIndexTranscriptLineUpdateError$: Subject<string>[] = [];
  public videoIndexTranscriptLineUpdateSuccess$: Subject<string>[] = [];

  constructor() {}

  public videoIndexUpdated(index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    this.videoIndexUpdate$.next(index);
  }

  public handelSuccess(action: UpdateVideoIndexAction) {
    this.videoIndexUpdateSuccess$.next(action.successMessage);
    this.videoIndexTranscriptLineUpdateSuccess$[action.transcriptLineId].next(action.errorMessage);
  }

  public handelError(action: UpdateVideoIndexAction) {
    this.videoIndexUpdateError$.next(action.errorMessage);
    this.videoIndexTranscriptLineUpdateError$[action.transcriptLineId].next(action.errorMessage);
  }
}
