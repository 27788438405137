import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  Injector,
  AfterViewInit
} from '@angular/core';

import { AccessibilityModeHandlerService } from './accessibility-mode-handler.service';

@Directive({
  selector: '[appAccessibilityTooltip]'
})
export class AccessibilityTooltipDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() public tooltipAddEvents = 'focus';
  @Input() public tooltipRemoveEvents = 'blur';
  @Input() public showAccessibilityTooltip = true;
  @Input() public observeTitleChange = false;

  private mutationObserver: MutationObserver;
  private tooltipClasses = {
    tooltip: 'vi-tooltip',
    tooltipLeft: 'left-side',
    tooltipRight: 'right-side'
  };
  private accessibilityModeHandler: AccessibilityModeHandlerService;

  constructor(private el: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    // Get Accessibility mode service
    const injector = Injector.create({ providers: [{ provide: AccessibilityModeHandlerService, deps: [] }] });
    this.accessibilityModeHandler = injector.get(AccessibilityModeHandlerService);
  }

  public ngAfterViewInit(): void {
    if (this.observeTitleChange) {
      this.mutationObserver = new MutationObserver(mutationList => {
        if (mutationList.find(mutation => mutation.attributeName === 'title')) {
          if (document?.activeElement === this.el.nativeElement) {
            this.createTip();
          } else {
            this.deleteTip();
          }
        }
      });
      this.mutationObserver?.observe(this.el.nativeElement, { attributes: true });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.showAccessibilityTooltip) {
      if (changes.showAccessibilityTooltip.currentValue === true) {
        this.el.nativeElement.addEventListener(this.tooltipAddEvents, this.createTip.bind(this));
        this.el.nativeElement.addEventListener(this.tooltipRemoveEvents, this.deleteTip.bind(this));
        this.el.nativeElement.addEventListener('mouseup mousedown', this.deleteTip.bind(this));

        // If during focus mode
        if (document?.activeElement === this.el.nativeElement) {
          // create tip
          this.createTip();
        }
      } else {
        this.removeEventListeners();
      }
    }
  }

  public ngOnDestroy(): void {
    this.removeEventListeners();
  }

  private removeEventListeners() {
    this.mutationObserver?.disconnect();
    this.el.nativeElement.removeEventListener(this.tooltipAddEvents, this.createTip);
    this.el.nativeElement.removeEventListener(this.tooltipRemoveEvents, this.deleteTip);
  }

  private createTip() {
    // Hide tooltip if not accessibility mode
    if (!this.accessibilityModeHandler.AccessibilityMode) {
      return;
    }
    if (!this.showAccessibilityTooltip) {
      return;
    }
    let tooltip = this.el.nativeElement.getElementsByClassName('.vi-tooltip');
    if (tooltip.length) {
      this.deleteTip();
    }

    const title = this.el.nativeElement.getAttribute('title');
    if (!title) {
      return;
    }
    this.renderer.removeAttribute(this.el.nativeElement, 'title');
    this.renderer.setAttribute(this.el.nativeElement, this.tooltipClasses.tooltip, title);

    tooltip = this.renderer.createElement('div');
    this.renderer.addClass(tooltip, this.tooltipClasses.tooltip);

    const textNode = this.renderer.createText(title);

    this.renderer.appendChild(tooltip, textNode);
    this.renderer.appendChild(this.el.nativeElement, tooltip);

    this.cdr.detectChanges();
  }

  private deleteTip() {
    if (!this.showAccessibilityTooltip) {
      return;
    }

    const tooltip = this.el.nativeElement.getElementsByClassName(this.tooltipClasses.tooltip);

    if (!(tooltip && tooltip.length)) {
      return;
    }
    const tooltipText = tooltip[0].textContent;

    if (tooltipText) {
      this.renderer.setAttribute(this.el.nativeElement, 'title', tooltipText);
      this.renderer.removeAttribute(this.el.nativeElement, this.tooltipClasses.tooltip);
    }

    if (tooltip.length) {
      this.renderer.removeChild(this.el.nativeElement, tooltip[0]);
    }
  }
}
