import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { sortBy, find, remove, cloneDeep, uniqBy, filter, difference } from 'lodash-es';

import { IUIFace, IVideoIdToIUIFace, UIFaceType } from '../insights/components/faces/interfaces';
import { IUIAppearance, IUITab } from '../insights/interfaces';
import { getSeconds } from '../utils/time';
import { ApiService } from '../api/services/api.service';
import { AuthService } from '../auth/services/auth.service';
import { InsightsCommonUtilsService } from './insights-common-utils.service';
import { resources } from './resources';
import { InsightsImagesService } from '../shared/components/insights-images/insights-images.service';
import { InsightsService } from '../insights/services/insights.service';
import { IUIInsightJsonKey, IInsightJsonKeyType, SpriteType, IUIInsightNoneJsonKey } from './interfaces';
import { TranslateHelperService } from '../translation/services/translate-helper.service';
import { ITabListTab } from '../shared/components/tablist/interfaces';
import { DeepPartial } from '../shared/interfaces';

@Injectable()
export class InsightsCommonDataService {
  public personDataUpdate: Subject<string>;
  public facesDataChange: Subject<IUIFace | void>;

  // Private
  private tabs: ITabListTab<IUITab>[] = [];
  private resources = resources;
  private videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2;
  private preparedVideosData: IVideoIdToIUIFace[] = [];
  private summarizedInsights: Microsoft.VideoIndexer.Contracts.SummarizedInsightsV2;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private utilsService: InsightsCommonUtilsService,
    private translate: TranslateHelperService,
    private insightsImagesService: InsightsImagesService,
    private insightsService: InsightsService
  ) {
    this.personDataUpdate = new Subject<string>();
    this.facesDataChange = new Subject<IUIFace>();
  }

  public setVideoIndex(videoIndex: DeepPartial<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>) {
    this.videoIndex = cloneDeep(videoIndex);
  }

  public clearSummarizeInsights() {
    this.summarizedInsights = null;
  }

  public setSummarizeInsights(summarizedInsights: Microsoft.VideoIndexer.Contracts.SummarizedInsightsV2) {
    /* Get all id's that exist in summarized insights
       Summarized insights contain special data -
        * faces appearances.
        * Unique faces (Faces that were combined will appear once).
    */
    // Summarize insights only appear on timeline.
    // If faces has been set without summarize insights, set missing data
    if (this.summarizedInsights) {
      return;
    }

    this.summarizedInsights = summarizedInsights;

    // If there is an existing faces data, merge seen duration data
    const videoID = this.videoIndex.id;
    const peopleFaces =
      this.preparedVideosData[videoID] && this.preparedVideosData[videoID][UIFaceType.people]
        ? this.preparedVideosData[videoID][UIFaceType.people]
        : [];

    for (const face of peopleFaces) {
      face.seenDurationRatio = this.getSeenDurationRatio(this.summarizedInsights, face.id, IUIInsightJsonKey.faces);
    }
  }

  public getVideoIndex() {
    return this.videoIndex;
  }

  public getFacesByType(videoId: string, nameFilter?: string, facesType?: UIFaceType, forcePrepareData: boolean = false) {
    const faces = this.preparedVideosData[videoId] && this.preparedVideosData[videoId][facesType];
    if (!faces || forcePrepareData) {
      if (!this.preparedVideosData[videoId]) {
        this.preparedVideosData[videoId] = {};
      }
      this.preparedVideosData[videoId][facesType] = cloneDeep(this.prepareFaces(nameFilter, facesType));
      return cloneDeep(this.preparedVideosData[videoId][facesType]);
    } else {
      return this.filterByName(cloneDeep(faces), nameFilter);
    }
  }

  public getJsonKeyType(facesType: UIFaceType) {
    return facesType === UIFaceType.people ? IUIInsightJsonKey.faces : '';
  }

  public updateFaceLoading(videoId: string, loading: boolean, id: number, facesType: UIFaceType) {
    const faces = this.preparedVideosData[videoId] && this.preparedVideosData[videoId][facesType];
    if (!faces) {
      return;
    }
    const face = find(faces, f => {
      return f.id === id;
    });
    if (face) {
      face.loading = loading;
    }
  }

  public getSeenDurationRatio(
    summarizedInsights: Microsoft.VideoIndexer.Contracts.SummarizedInsightsV2,
    faceId,
    type: IInsightJsonKeyType = IUIInsightJsonKey.faces
  ) {
    const face = this.getFaceFromSummarize(summarizedInsights, faceId, type);
    return face ? Number((face.seenDurationRatio * 100).toFixed(2)) : 0;
  }

  public getFaceFromSummarize(
    summarizedInsights: Microsoft.VideoIndexer.Contracts.SummarizedInsightsV2,
    id: number,
    type: IInsightJsonKeyType = IUIInsightJsonKey.faces
  ) {
    if (!summarizedInsights || (summarizedInsights && !summarizedInsights[type])) {
      return;
    }
    return find(summarizedInsights[type], f => {
      return f.id === id;
    });
  }

  public getSpriteUrl(
    accountId: string,
    videoId: string,
    index: number,
    spriteSize: number,
    spriteType: IUIInsightJsonKey | IUIInsightNoneJsonKey | UIFaceType | SpriteType
  ) {
    const page = Math.floor(index / spriteSize);

    if (this.insightsService.customImagesLocation) {
      return this.apiService.Account.Video.getThumbnailSpriteCustomUrl(this.insightsService.customImagesLocation, page);
    } else if (accountId && videoId) {
      let accessToken = this.authService.getUserAccessTokenOrEmptyString(videoId, accountId);

      // Fallback - if access token is empty try to get from account token
      if (!accessToken) {
        accessToken = this.authService.getUserAccessTokenOrEmptyString(null, accountId);
      }
      return this.apiService.Account.Video.getSpriteUrl(accountId, videoId, accessToken, page, this.getSpriteType(spriteType));
    } else {
      return this.apiService.Account.Video.getTestThumbnailSpriteUrl(page);
    }
  }

  public updateFaceName(videoId: string, id: number, name: string, facesType: UIFaceType) {
    const faces: IUIFace[] = this.preparedVideosData[videoId] && this.preparedVideosData[videoId][facesType];
    if (!faces) {
      return;
    }

    const face = find(faces, f => {
      return f.id === id;
    });

    // Check if this name is already exist
    // Check if name wasn't changed to a celebrity (referenceId refers to a celebrity)
    const samePersonName = find(faces, person => {
      return person.name === name && !person.referenceId;
    });

    this.updatePersonNameData(face, name, facesType);

    this.personDataUpdate.next(videoId);

    if (!samePersonName) {
      return;
    }

    this.updateDuplicateName(samePersonName, face, faces, videoId, facesType);
  }

  public updatePersonNameData(face: IUIFace | Microsoft.VideoIndexer.Contracts.Face, name: string, facesType: UIFaceType) {
    if (face) {
      face.name = name;

      // If a celebrity name has been change, remove it's description - not a celebrity
      if (face.referenceId) {
        face.referenceId = '';
        face.description = '';
        if (facesType === UIFaceType.people) {
          face[`referenceType`] = '';
          face[`title`] = '';
        }
      }
    }
  }

  public removeFace(videoId: string, id: number, faceType: UIFaceType) {
    const faces: IUIFace[] = this.preparedVideosData[videoId] && this.preparedVideosData[videoId][faceType];
    remove(faces, (face: IUIFace) => {
      return face.id === id;
    });

    for (const video of this.videoIndex.videos) {
      remove(video.insights.faces, (face: Microsoft.VideoIndexer.Contracts.Face) => {
        return face.id === id;
      });
    }
  }

  public getTabs() {
    if (!this.tabs.length) {
      this.initTabs();
    }
    return this.tabs;
  }

  private filterByName(faces: IUIFace[], nameFilter: string): IUIFace[] {
    if (nameFilter) {
      return filter(faces, face => {
        return face.name.toLowerCase().includes(nameFilter.toLowerCase());
      });
    } else {
      return faces;
    }
  }

  private prepareFaces(customFilter?: string, facesType: UIFaceType = UIFaceType.people) {
    let faces: Microsoft.VideoIndexer.Contracts.Face[] = [];
    let UIFaces: IUIFace[] = [];
    const jsonKeyType: IInsightJsonKeyType = this.getJsonKeyType(facesType);
    if (!this.utilsService.hasData(jsonKeyType, this.videoIndex)) {
      return UIFaces;
    }

    // Get all faces
    this.videoIndex.videos.forEach(video => {
      let videoInsights: Microsoft.VideoIndexer.Contracts.Face[];
      switch (jsonKeyType) {
        case IUIInsightJsonKey.faces:
          videoInsights = video.insights.faces;
          break;
      }
      // If current video has faces data
      if (videoInsights) {
        videoInsights.forEach(face => {
          face['shortId'] = video.id;
          face['faceType'] = jsonKeyType;
          faces.push(face);
        });
      }
    });

    // Sort faces by id for matching the sprite
    faces = sortBy(faces, ['shortId', 'id']);
    // Create UI view model.
    faces.forEach((f, i) => {
      if (customFilter && !f.name.toLowerCase().includes(customFilter.toLowerCase())) {
        return UIFaces;
      }
      const personAppearances: IUIAppearance[] = [];

      f.instances.forEach(appearance => {
        personAppearances.push({
          adjustedStart: appearance.adjustedStart,
          endSeconds: getSeconds(appearance.adjustedEnd),
          startSeconds: getSeconds(appearance.adjustedStart)
        });
      });

      const face: IUIFace = {
        spriteIndex: i,
        description: f.description,
        appearances: personAppearances,
        id: f.id,
        selected: false,
        title: f.title,
        name: f.name,
        referenceId: f.referenceId,
        referenceType: f.referenceType,
        seenDurationRatio: this.summarizedInsights ? this.getSeenDurationRatio(this.summarizedInsights, f.id, jsonKeyType) : null,
        positionTiny: this.insightsImagesService.getSpritePosition(i, 32, 100),
        positionSmall: this.insightsImagesService.getSpritePosition(i, 40, 100),
        positionLarge: this.insightsImagesService.getSpritePosition(i, 72, 100),
        spriteUrl: this.getSpriteUrl(this.videoIndex.accountId, this.videoIndex.id, i, 100, facesType),
        style: null,
        highQuality: f.highQuality,
        selectedStyle: null,
        timelineStyle: null,
        confidence: Number((f.confidence * 100).toFixed(2)),
        knownPersonId: f.knownPersonId ? f.knownPersonId : null,
        videoId: f['shortId']
      };

      const itemResources = { PersonComponentAppearsDuration: '' };
      this.translate.translateResources(itemResources, { personSeenDuration: face.seenDurationRatio });
      face.appearTitle = itemResources.PersonComponentAppearsDuration;
      face.style = this.insightsImagesService.getThumbStyle(face, face.positionSmall);
      face.selectedStyle = this.insightsImagesService.getThumbStyle(face, face.positionLarge);
      face.timelineStyle = this.insightsImagesService.getThumbStyle(face, face.positionTiny);
      UIFaces.push(face);
    });

    UIFaces = this.removeDuplicateId(UIFaces);
    UIFaces = this.removeDuplicateName(UIFaces, jsonKeyType);
    UIFaces = this.sortByKnownAndSeenDuration(UIFaces);
    return UIFaces;
  }

  private getSpriteType(type: IUIInsightJsonKey | IUIInsightNoneJsonKey | UIFaceType | SpriteType): SpriteType {
    switch (type) {
      case IUIInsightJsonKey.faces:
        return SpriteType.Faces;
      case IUIInsightJsonKey.observedPeople:
        return SpriteType.ObservedPeople;
      case IUIInsightJsonKey.clapperboards:
        return SpriteType.Clapperboards;
      case IUIInsightNoneJsonKey.colorBars:
        return SpriteType.ColorBars;
      default:
        return <SpriteType>type;
    }
  }

  private sortByKnownAndSeenDuration(people: IUIFace[]): IUIFace[] {
    people = sortBy(people, [
      // Sort by known first
      (p: IUIFace) => {
        return p.name.indexOf('Unknown #') !== -1;
      },
      // Sort by seen duration
      (p: IUIFace) => {
        return -p.seenDurationRatio;
      }
    ]);
    return people;
  }

  private updateDuplicateName(samePersonName: IUIFace, mergedFace: IUIFace, faces: IUIFace[], videoId: string, facesType: UIFaceType) {
    // Remove the merged face from faces array
    const duplicateNamePeopleRemoved = remove(faces, person => {
      return person.id !== mergedFace.id;
    });

    // Get person from the new uniq people array
    const personFromUniqArray = samePersonName;

    personFromUniqArray.appearances = personFromUniqArray.appearances.concat(mergedFace.appearances);
    personFromUniqArray.confidence = personFromUniqArray.confidence + mergedFace.confidence;
    personFromUniqArray.seenDurationRatio += mergedFace.seenDurationRatio;
    personFromUniqArray.seenDurationRatio = Number(personFromUniqArray.seenDurationRatio.toFixed(2));

    const itemResources = { PersonComponentAppearsDuration: '' };
    this.translate.translateResources(itemResources, { personSeenDuration: personFromUniqArray.seenDurationRatio });
    personFromUniqArray.appearTitle = itemResources.PersonComponentAppearsDuration;
    if (this.preparedVideosData[videoId] && this.preparedVideosData[videoId][facesType]) {
      this.preparedVideosData[videoId][facesType] = duplicateNamePeopleRemoved;
    }
  }

  private removeDuplicateName(people: IUIFace[], type: IInsightJsonKeyType = IUIInsightJsonKey.faces): IUIFace[] {
    if (!this.videoIndex.summarizedInsights || (this.videoIndex.summarizedInsights && !this.videoIndex.summarizedInsights[type])) {
      return null;
    }

    // Get all id's that exist in summarized insights
    const uniqPersonId = this.videoIndex.summarizedInsights[type].map(face => {
      return face.id;
    });

    // Get people that was removed
    let removedPeople = filter(people, person => {
      // Get duplicated on people array from other videos
      const faceFromArray = find(people, currentPerson => {
        return person.id === currentPerson.id && currentPerson.videoId !== person.videoId;
      });

      if (faceFromArray) {
        return true;
      }

      // Else if it is not exist on  summarize insights array, it has been removed
      return !uniqPersonId.includes(person.id);
    });

    // Get uniqBy array
    removedPeople = uniqBy(removedPeople, person => {
      return person.id;
    });

    // Get the rest
    const duplicateNamePeopleRemoved = difference(people, removedPeople);

    // Get objects from uniq array
    for (const iterator of removedPeople) {
      // Get person from summarize insights (The appearances are combined there)
      const personFromSummarize = find(this.videoIndex.summarizedInsights[type], face => {
        return (
          (iterator.knownPersonId && face.knownPersonId && iterator.knownPersonId === face.knownPersonId) ||
          (iterator.referenceId && iterator.referenceId === face.referenceId && iterator.referenceType === face.referenceType)
        );
      });

      if (!personFromSummarize) {
        break;
      }

      // Get person from the new uniq people array
      const personFromUniqArray = find(duplicateNamePeopleRemoved, face => {
        return face.name === personFromSummarize.name;
      });

      if (!personFromUniqArray) {
        break;
      }
      // Create new person appearances
      const personAppearances: IUIAppearance[] = [];

      personFromSummarize.appearances.forEach(appearance => {
        personAppearances.push({
          adjustedStart: appearance.startTime,
          endSeconds: appearance.endSeconds,
          startSeconds: appearance.startSeconds
        });
      });

      personFromUniqArray.appearances = personAppearances;

      personFromUniqArray.confidence = personFromSummarize.confidence;
      personFromUniqArray.seenDurationRatio = Number((personFromSummarize.seenDurationRatio * 100).toFixed(2));

      const itemResources = { PersonComponentAppearsDuration: '' };
      this.translate.translateResources(itemResources, { personSeenDuration: personFromUniqArray.seenDurationRatio });
      personFromUniqArray.appearTitle = itemResources.PersonComponentAppearsDuration;
    }
    return duplicateNamePeopleRemoved;
  }

  private removeDuplicateId(faces: IUIFace[]): IUIFace[] {
    /**
     *@todo remove after duplicate id bug is fixed
     */
    // Remove duplicate id:
    // Filter null & non-null values
    const nullReferencePeople: IUIFace[] = filter(faces, person => {
      return !person.referenceId;
    });

    let nonNullReferencePeople: IUIFace[] = filter(faces, person => {
      return !!person.referenceId;
    });

    // Get uniq values
    nonNullReferencePeople = uniqBy(nonNullReferencePeople, person => {
      return person.referenceId;
    });

    faces = nonNullReferencePeople.concat(nullReferencePeople);
    return faces;
  }

  private initTabs() {
    this.translate.translateResources(this.resources);
    this.tabs = [
      {
        value: IUITab.INSIGHTS,
        active: true,
        selected: false,
        text: this.resources.CognitiveInsightsInsightsTabTitle
      },
      {
        value: IUITab.TIMELINE,
        active: true,
        selected: false,
        text: this.resources.Timeline
      }
    ];
  }
}
