import { UIActionType } from '@common/modules/insights/interfaces';

import { IAction } from '../../interfaces';

export const acceptAction: IAction = {
  title: '',
  key: 'Accept',
  type: UIActionType.CLICK,
  value: UIActionType.CLICK,
  id: 'accept'
};

export const dismissAction: IAction = {
  title: '',
  key: 'Dismiss',
  type: UIActionType.CANCEL,
  value: UIActionType.CANCEL,
  id: 'dismiss'
};

export const useCaseMenuAction: IAction = {
  title: '',
  key: 'SelectAUseCase',
  type: UIActionType.CLICK,
  value: UIActionType.DROPDOWN,
  id: 'useCaseMenu'
};

export const useCaseOptions: IAction[] = [
  {
    title: '',
    key: 'IdentityVerification',
    type: UIActionType.CLICK,
    value: 'Identity verification',
    id: 'identityVerification'
  },
  {
    title: '',
    key: 'TouchlessAccessControl',
    type: UIActionType.CLICK,
    value: 'Touchless access control',
    id: 'touchLessAccessControl'
  },
  {
    title: '',
    key: 'Personalization',
    type: UIActionType.CLICK,
    value: 'Personalization',
    id: 'personalization'
  },
  {
    title: '',
    key: 'DetectDuplicateOrBlockedUsers',
    type: UIActionType.CLICK,
    value: 'Detect duplicate or blocked users',
    id: 'detectDuplicateOrBlockedUsers'
  },
  {
    title: '',
    key: 'FaceInMediaOrEntertainmentVideo',
    type: UIActionType.CLICK,
    value: 'A face in a media or entertainment video archive',
    id: 'faceInMediaOrEntertainmentVideo'
  },
  {
    title: '',
    key: 'CelebrityRecognition',
    type: UIActionType.CLICK,
    value: 'Celebrity recognition',
    id: 'celebrityRecognition'
  },
  {
    title: '',
    key: 'DemoUse',
    type: UIActionType.CLICK,
    value: 'Demo use',
    id: 'DemoUse'
  }
];
