import { Action } from '@ngrx/store';

import { IUploadRequestParams, SensitiveAI, IReIndexRequestParams, IRequestParam, ExcludableAIsModels } from '@common/modules/api/interfaces';

import * as actions from '../../actions';
import { IFile, IIndexingSettings, IndexingLanguageType, UploadMode, UploadingState } from '../../../interfaces';
import { IndexingPreset } from '../../../components/shared/settings';

export const CONCURRENT_FS_UPLOADS = 3;
export const CONCURRENT_URL_UPLOADS = 10;
export const UPLOAD_PROCESS_FINISHED_PERCENTAGE = 100;
export const UPLOAD_TO_BLOB_STORAGE_COMPLETED_PERCENTAGE = 99;
// Max size 30 GB from URL
export const MAX_FILE_SIZE_URL = Math.pow(1024, 3) * 30;
export const EXCLUDE_AI_PARAM = 'excludedAI';
export const HTML_CONTENT_TYPE = 'text/html';

export const getExcludeSensitiveAIsParams = (indexingSettings: IIndexingSettings): IRequestParam[] => {
  return indexingSettings.excludeRAI
    ? [SensitiveAI.Emotions, SensitiveAI.Faces, SensitiveAI.Labels, SensitiveAI.ObservedPeople].map(ai => ({ key: EXCLUDE_AI_PARAM, value: ai }))
    : null;
};

export const getExcludeAIsParams = (excludeAIs: ExcludableAIsModels[]): IRequestParam[] => {
  const params = excludeAIs.map(excludeAI => ({ key: EXCLUDE_AI_PARAM, value: excludeAI }));
  return params;
};

export const getFSFileUploadBatchActions = (idleFiles: IFile[], inProgressFilesCount: number) => {
  const uploadBatchActions = [];
  idleFiles
    .slice(0, CONCURRENT_FS_UPLOADS - inProgressFilesCount)
    .forEach(file =>
      uploadBatchActions.push(
        actions.updateFile({ fileId: file.id, file: { uploadingState: UploadingState.InProgress } }),
        actions.getFileSas({ storeFile: file })
      )
    );
  return uploadBatchActions;
};

export const getURLFileUploadBatchActions = (idleFiles: IFile[], inProgressFilesCount: number): Action[] => {
  const uploadBatchActions = [];
  idleFiles
    .slice(0, CONCURRENT_URL_UPLOADS - inProgressFilesCount)
    .forEach(file =>
      uploadBatchActions.push(
        actions.updateFile({ fileId: file.id, file: { uploadingState: UploadingState.InProgress } }),
        actions.uploadFileToServer({ storeFile: file, fileUrl: file.url })
      )
    );
  return uploadBatchActions;
};

export const getLanguageParam: (language) => Microsoft.VideoIndexer.Contracts.LanguageV2 = language => {
  switch (language.type) {
    case IndexingLanguageType.Language:
      return language.languageCode;
    case IndexingLanguageType.Auto:
      return language.key;
    case IndexingLanguageType.SpeechModel:
      return language.locale;
    case IndexingLanguageType.LanguageModel:
      return language.language;
    default:
      return null;
  }
};

export const getCustomLanguagesParam = (language, languageIdKeys) => {
  if (language.type === IndexingLanguageType.Auto) {
    return languageIdKeys.join(',');
  }
  return null;
};

export const getCustomLanguageModelParam = language => {
  return language.type === IndexingLanguageType.LanguageModel
    ? language.languageModelId
    : language.type === IndexingLanguageType.SpeechModel
    ? language.id
    : null;
};

export const getBrandsCategoriesParam = (brands: string) => {
  return brands
    ? brands
        .split(',')
        .filter(brand => brand !== 'Default')
        .join(',')
    : null;
};

export const convertToUploadParams = (
  accountId: string,
  indexingSettings: IIndexingSettings,
  languageSettings: Microsoft.VideoIndexer.Contracts.LanguageV2,
  languageModelSettings: string,
  storeFile: IFile,
  fileUrl: string,
  customLanguages
): IUploadRequestParams => {
  const brandsCategories = getBrandsCategoriesParam(indexingSettings.brandsCategories);

  return {
    accountId: accountId,
    indexingPreset: indexingSettings.indexingPreset,
    language: languageSettings,
    linguisticModelId: languageModelSettings,
    name: storeFile.name,
    sendSuccessEmail: true,
    videoUrl: fileUrl,
    description: indexingSettings.description,
    metadata: indexingSettings.metadata,
    ...(customLanguages && { customLanguages }),
    streamingPreset: indexingSettings.streamingPreset,
    privacy: indexingSettings.privacy,
    ...(brandsCategories && { brandsCategories }),
    ...(indexingSettings.peopleModelId && { personModelId: indexingSettings.peopleModelId }),
    ...(isAdvancedIndexingPresetSelected(indexingSettings.indexingPreset) &&
      indexingSettings.logoGroupId && { logoGroupId: indexingSettings.logoGroupId })
  };
};
export const convertToReIndexParams = (
  indexingSettings: IIndexingSettings,
  languageSettings: Microsoft.VideoIndexer.Contracts.LanguageV2,
  languageModelSettings: string,
  customLanguages: string
): IReIndexRequestParams => {
  const brandsCategories = getBrandsCategoriesParam(indexingSettings.brandsCategories);

  return {
    indexingPreset: indexingSettings.indexingPreset,
    sourceLanguage: languageSettings,
    linguisticModelId: languageModelSettings,
    ...(customLanguages && { customLanguages: customLanguages }),
    streamingPreset: indexingSettings.streamingPreset,
    ...(brandsCategories && { brandsCategories }),
    ...(indexingSettings.peopleModelId && { personModelId: indexingSettings.peopleModelId }),
    ...(isAdvancedIndexingPresetSelected(indexingSettings.indexingPreset) &&
      indexingSettings.logoGroupId && { logoGroupId: indexingSettings.logoGroupId })
  };
};

export const isUrlAlreadyAdded = (url: string, files: IFile[]) => {
  return !!files.find(f => f.url === url);
};

export const isAdvancedIndexingPresetSelected = (indexingPreset: IndexingPreset) => {
  return indexingPreset === IndexingPreset.VIDEO_AUDIO_ADVANCED || indexingPreset === IndexingPreset.VIDEO_ADVANCED;
};

export const getFileTraceData = (storeFile: IFile): object => {
  return { fileSize: storeFile.size, mode: storeFile.url ? UploadMode.URL : UploadMode.File };
};

export const getUploadSettingsTraceData = (uploadParams: Partial<IUploadRequestParams>): Omit<Partial<IUploadRequestParams>, 'name' | 'videoUrl'> => {
  const updatedParams = { ...uploadParams };
  delete updatedParams.videoUrl;
  // name is UGC and as such can contain sensitive information (names, email addresses, etc.)
  delete updatedParams.name;
  return updatedParams;
};
