import { createEntityAdapter, Dictionary, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { map, cloneDeep } from 'lodash-es';

import { SpeechDatasetKind, SpeechObjectState } from '../../interfaces';
import { DatasetDisabledReason, ISpeechDatasetDetails, ISpeechDatasetReport, SelectedDatasets } from './interfaces';
import * as SpeechDatasetsActions from '../../actions/speech/speech-datasets.actions';

export interface SpeechDatasetState extends EntityState<ISpeechDatasetDetails> {
  // additional entities state properties
  loaded: boolean;
  updating: boolean;
  error: boolean;
  selectedDatasets: SelectedDatasets;
}

function selectDatasetId(a: ISpeechDatasetDetails): string {
  return a.id;
}

const adapter = createEntityAdapter<ISpeechDatasetDetails>({
  selectId: selectDatasetId
});

export const datasetInitialState: SpeechDatasetState = adapter.getInitialState({
  loaded: false,
  updating: false,
  error: false,
  selectedDatasets: {
    pronunciationId: '',
    languageIds: [],
    locale: ''
  }
});

const speechDatasetReducer = createReducer(
  datasetInitialState,
  on(SpeechDatasetsActions.loadSpeechDatasetsSucceeded, (state, { datasets }) => {
    const mappedDatasets: ISpeechDatasetDetails[] = map(datasets, dataset => {
      if (!isDatasetDone(dataset)) {
        return {
          ...dataset,
          disabled: true
        };
      }
      return dataset;
    });
    return adapter.upsertMany(mappedDatasets, { ...state, loaded: true });
  }),
  on(SpeechDatasetsActions.loadSpeechDatasetFilesSucceeded, (state, { datasetId, files }) => {
    return adapter.updateOne(
      {
        id: datasetId,
        changes: {
          languageData: files.find(file => file.name.includes('languagedata'))?.content as string,
          report: files.find(file => file.name.includes('report'))?.content as ISpeechDatasetReport,
          normalizedText: files.find(file => file.name.includes('normalized'))?.content as string,
          filesLoaded: true
        }
      },
      state
    );
  }),
  on(SpeechDatasetsActions.loadSpeechDatasetsFailed, state => {
    return {
      ...state,
      error: true,
      loaded: true
    };
  }),
  on(SpeechDatasetsActions.loadSpeechDatasetFilesFailed, (state, { datasetId }) => {
    return adapter.updateOne(
      {
        id: datasetId,
        changes: {
          filesLoaded: true,
          filesError: true
        }
      },
      state
    );
  }),
  on(SpeechDatasetsActions.clearSpeechDatasets, state => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      error: false
    });
  }),
  on(SpeechDatasetsActions.updateSpeechDatasetStarted, state => {
    return {
      ...state,
      updating: true
    };
  }),
  on(SpeechDatasetsActions.updateSpeechDatasetSucceed, (state, { dataset }) => {
    return adapter.updateOne({ id: dataset.id, changes: dataset }, { ...state, updating: false });
  }),
  on(SpeechDatasetsActions.getDatasetStatusSucceeded, (state, { dataset }) => {
    let datasetDetails = { ...dataset } as ISpeechDatasetDetails;
    if (isDatasetDone(datasetDetails)) {
      datasetDetails.disabled = false;
      // when there are selected dataset, its properties can be updated according to the selected datasets
      if (state.selectedDatasets.locale) {
        datasetDetails = getDatasetOnSelect(datasetDetails, state.selectedDatasets);
      }
    }
    return adapter.updateOne({ id: dataset.id, changes: datasetDetails }, state);
  }),
  on(SpeechDatasetsActions.updateSpeechDatasetFailed, state => {
    return {
      ...state,
      updating: false
    };
  }),
  on(SpeechDatasetsActions.deleteSpeechDatasetStarted, state => {
    return {
      ...state,
      updating: true
    };
  }),
  on(SpeechDatasetsActions.deleteSpeechDatasetSucceed, (state, { datasetId }) => {
    if (!state.entities[datasetId]?.selected) {
      return state;
    }
    return getStateOnToggleDataset(state, datasetId);
  }),
  on(SpeechDatasetsActions.deleteSpeechDatasetSucceed, (state, { datasetId }) => {
    return adapter.removeOne(datasetId, { ...state, updating: false });
  }),
  on(SpeechDatasetsActions.deleteSpeechDatasetFailed, state => {
    return {
      ...state,
      updating: false
    };
  }),
  on(SpeechDatasetsActions.addUploadingDataset, (state, { dataset }) => {
    return adapter.addOne(dataset, state);
  }),
  on(SpeechDatasetsActions.uploadDatasetToBlobFailed, (state, { datasetId }) => {
    return adapter.removeOne(datasetId, state);
  }),
  on(SpeechDatasetsActions.getDatasetSasURLFailed, (state, { datasetId }) => {
    return adapter.removeOne(datasetId, state);
  }),
  on(SpeechDatasetsActions.removeSpeechDataset, (state, { datasetId }) => {
    return adapter.removeOne(datasetId, state);
  }),
  on(SpeechDatasetsActions.createDatasetSucceeded, (state, { datasetDetails }) => {
    let dataset = datasetDetails as ISpeechDatasetDetails;
    if (!isDatasetDone(dataset)) {
      dataset = {
        ...dataset,
        disabled: true
      };
    }
    return adapter.addOne(dataset, state);
  }),
  on(SpeechDatasetsActions.createDatasetFailed, (state, { datasetId }) => {
    return adapter.removeOne(datasetId, state);
  }),
  on(SpeechDatasetsActions.toggleSpeechDatasetSelection, (state, { datasetId }) => {
    return getStateOnToggleDataset(state, datasetId);
  }),
  on(SpeechDatasetsActions.clearSpeechDatasetSelection, state => {
    const clearSelectionsDatasets: ISpeechDatasetDetails[] = map(state.entities, dataset => {
      if (isDatasetDone(dataset)) {
        return {
          ...dataset,
          disabled: false,
          disabledReason: '',
          selected: false
        };
      }
      return dataset;
    });
    return adapter.upsertMany(clearSelectionsDatasets, {
      ...state,
      selectedDatasets: {
        pronunciationId: '',
        languageIds: [],
        locale: ''
      }
    });
  })
);

export function reducer(state: SpeechDatasetState | undefined, action: Action) {
  return speechDatasetReducer(state, action);
}

function getStateOnToggleDataset(state: SpeechDatasetState, datasetId: string) {
  const clickedDataset: ISpeechDatasetDetails = { ...state.entities[datasetId] };
  clickedDataset.selected = !clickedDataset.selected;
  if (clickedDataset.selected && clickedDataset.disabled) {
    return state;
  }

  const selectedDatasets = getSelectedDatasetsOnSelect(clickedDataset, state.selectedDatasets);
  const updatedDatasets = changeDatasetsPropertiesOnSelect(state.entities, selectedDatasets, clickedDataset);

  return adapter.upsertMany(updatedDatasets, {
    ...state,
    selectedDatasets: selectedDatasets
  });
}

function getSelectedDatasetsOnSelect(clickedDataset: ISpeechDatasetDetails, selectedDatasets: SelectedDatasets): SelectedDatasets {
  let selectedPronunciationId = selectedDatasets.pronunciationId;
  let selectedLanguageIds = cloneDeep(selectedDatasets.languageIds);
  let selectedLocale = selectedDatasets.locale;

  // if dataset is selected add it in selectedDatasets state.
  if (clickedDataset.selected) {
    if (clickedDataset.kind === SpeechDatasetKind.LANGUAGE) {
      selectedLanguageIds.push(clickedDataset.id);
    } else if (clickedDataset.kind === SpeechDatasetKind.PRONUNCIATION) {
      selectedPronunciationId = clickedDataset.id;
    }
    selectedLocale = clickedDataset.locale;
    // if dataset is selected remove it in selectedDatasets state.
  } else if (!clickedDataset.selected) {
    if (clickedDataset.kind === SpeechDatasetKind.LANGUAGE) {
      selectedLanguageIds = selectedLanguageIds.filter(id => id !== clickedDataset.id);
    } else if (clickedDataset.kind === SpeechDatasetKind.PRONUNCIATION) {
      selectedPronunciationId = '';
    }
    selectedLocale = selectedLanguageIds.length > 0 || !!selectedPronunciationId ? selectedLocale : '';
  }

  return {
    pronunciationId: selectedPronunciationId,
    languageIds: selectedLanguageIds,
    locale: selectedLocale
  };
}

function changeDatasetsPropertiesOnSelect(
  datasetEntities: Dictionary<ISpeechDatasetDetails>,
  selectedDatasets: SelectedDatasets,
  clickedDataset?: ISpeechDatasetDetails
) {
  const datasets: ISpeechDatasetDetails[] = map(datasetEntities, entity => ({ ...entity }));

  for (let dataset of datasets) {
    if (clickedDataset && dataset.id === clickedDataset.id) {
      dataset.selected = clickedDataset.selected;
    } else {
      dataset = getDatasetOnSelect(dataset, selectedDatasets);
    }
  }

  return datasets;
}

function getDatasetOnSelect(dataset: ISpeechDatasetDetails, selectedDatasets: SelectedDatasets) {
  const hasSelectedDataset = selectedDatasets.languageIds.length > 0 || !!selectedDatasets.pronunciationId;
  // all failed datasets should be disabled when there is selected dataset
  if (dataset.status === SpeechObjectState.FAILED) {
    dataset.disabled = hasSelectedDataset;
    dataset.disabledReason = undefined;
  } else {
    // disable other datasets in different locales
    const isLanguageDisabled = selectedDatasets.locale && dataset.locale !== selectedDatasets.locale;
    // disable other pronunciation when there is already selected one.
    const isPronunciationDisabled =
      !!selectedDatasets.pronunciationId && selectedDatasets.pronunciationId !== dataset.id && dataset.kind === SpeechDatasetKind.PRONUNCIATION;
    const isDatasetInProcess = !isDatasetDone(dataset);
    dataset.disabled = isLanguageDisabled || isPronunciationDisabled || isDatasetInProcess;
    dataset.disabledReason = isLanguageDisabled
      ? DatasetDisabledReason.NotInSelectedLanguage
      : isPronunciationDisabled
      ? DatasetDisabledReason.PronunciationAlreadySelected
      : undefined;
  }
  return dataset;
}

function isDatasetDone(dataset: ISpeechDatasetDetails) {
  return dataset.status === SpeechObjectState.COMPLETE || dataset.status === SpeechObjectState.FAILED;
}
