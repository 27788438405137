import { createAction, props } from '@ngrx/store';

import UserRole = Microsoft.VideoIndexer.Contracts.UserRole;
import AccountRoleAssignment = Microsoft.VideoIndexer.Contracts.AccountRoleAssignment;

export const loadRoleAssignments = createAction('[Role Assignment/API] Load Role Assignments');
export const loadRoleAssignmentsInProgress = createAction('[Role Assignment] Load Role Assignments In Progress');
export const loadRoleAssignmentsSuccess = createAction(
  '[Role Assignment] Load Role Assignments Success',
  props<{ roleAssignments: AccountRoleAssignment[] }>()
);
export const loadRoleAssignmentsFailed = createAction('[Role Assignment] Load Role Assignments Failed', props<{ isUserNotAllowedError: boolean }>());
export const updateRoleAssignments = createAction(
  '[Role Assignment/API] Update Role Assignments',
  props<{ roleAssignmentId: number; role: UserRole }>()
);
export const updateRoleAssignmentsSuccess = createAction(
  '[Role Assignment] Update Role Assignments Success',
  props<{ roleAssignmentId: number; role: UserRole }>()
);
export const deleteRoleAssignment = createAction('[Role Assignment/API] Delete Role Assignments', props<{ roleAssignmentId: number }>());
export const deleteRoleAssignmentSuccess = createAction('[Role Assignment] Delete Role Assignments Success', props<{ roleAssignmentId: number }>());
export const roleAssignmentsError = createAction('[Role Assignment] Invitations error', props<{ isUserNotAllowedError: boolean }>());
