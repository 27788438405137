import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ISubscriptions } from '@common/modules/insights/interfaces';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { TrackService } from '@common/modules/core/services/track';

import { resources } from './resources';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  public resources = resources;
  private subscriptions: ISubscriptions = {};

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateHelperService,
    private utilsService: UtilsService,
    private trackService: TrackService,
    private router: Router
  ) {}

  public ngOnInit() {
    this.setTranslationsSubscription();

    // Get router url
    const urlTree = this.router.parseUrl(this.router?.url);

    // If there is access token - remove data
    if (urlTree?.queryParams.accessToken) {
      urlTree.queryParams.accessToken = '***';
    }

    this.trackService.track('page_not_found.init', {
      router_url: urlTree.toString()
    });
  }

  public ngOnDestroy() {
    // Unsubscribe all subscriptions.
    this.utilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  private setTranslationsSubscription() {
    this.translateService
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }
}
