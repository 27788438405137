import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { guid } from '../../utils';

@Component({
  selector: 'vi-ui-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() for: string = guid();
  @Input() id: string = guid();

  constructor() {}
}
