import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { UrlService } from '@common/modules/core/services/url/url.service';
import { IGetIndexRequestParams } from '@common/modules/api/interfaces';

import { MediaCommonService } from '../services/media-common.service';
import { UpdateVideoIndexAction } from '../interfaces';
import * as fromMedia from '../selectors/media.selectors';
import * as MediaActions from '../actions/media.actions';

@Injectable()
export class MediaCommonEffects {
  public updateVideoIndex$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.updateVideoIndex),
      withLatestFrom(this.store.select(fromMedia.selectIndexLanguage)),
      switchMap(([{ action, index }, language]) => {
        const videoId = this.urlUtility.getParam('videoId');
        const accountId = this.urlUtility.getParam('accountId');
        const params: IGetIndexRequestParams = {
          language
        };
        this.logger.log('Update index in store');
        return this.apiService.Account.Video.updateVideoIndex(accountId, videoId, action.data, params).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) => {
            this.logger.log('Update index success');

            // TODO: remove this once completely migrating the insights to ngrx
            if (action.refreshData) {
              setTimeout(() => {
                this.mediaCommonService.videoIndexUpdated(result);
              });
            }

            this.trackService.track('insights.update_index.success', {
              category: EventCategory.MEDIA,
              data: { path: action.data[0].path }
            });
            return [
              MediaActions.updateIndexSaving({ saving: false }),
              MediaActions.upsertMediaIndex({ mediaIndex: result }),
              MediaActions.updateVideoIndexSucceed({ action })
            ];
          }),
          catchError(error => {
            this.logger.log('Update index failed', error);
            this.trackService.track('insights.update_index.failed', {
              category: EventCategory.MEDIA,
              data: { path: action.data[0].path, error }
            });
            this.mediaCommonService.handelError(action);
            const actionsList = [];
            if (index) {
              actionsList.push(MediaActions.upsertMediaIndex({ mediaIndex: index }));
            }
            actionsList.push(MediaActions.updateIndexSaving({ saving: false }));
            return actionsList;
          })
        );
      })
    )
  );

  public assignNewSpeaker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.assignNewSpeaker),
      withLatestFrom(this.store.select(fromMedia.selectIndexLanguage)),
      switchMap(([{ addSpeakerAction, updateTranscriptLineAction }, language]) => {
        const videoId = this.urlUtility.getParam('videoId');
        const accountId = this.urlUtility.getParam('accountId');
        const params: IGetIndexRequestParams = {
          language
        };
        return this.apiService.Account.Video.updateVideoIndex(accountId, videoId, addSpeakerAction.data, params).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) => {
            this.logger.log('Assign new speaker success');
            this.trackService.track('insights.assign_new_speaker.success', {
              category: EventCategory.MEDIA,
              data: { path: addSpeakerAction.data[0].path }
            });

            // assign to the transcript line action the new speaker id
            const newSpeakerName = addSpeakerAction.data[0].value['name'];
            const id = result.videos[0].insights.speakers.find(s => s.name === newSpeakerName)?.id;
            const newAction: UpdateVideoIndexAction = {
              ...updateTranscriptLineAction,
              data: [
                {
                  ...updateTranscriptLineAction.data[0],
                  value: id
                }
              ]
            };
            return [MediaActions.updateVideoIndex({ action: newAction, index: result })];
          }),
          catchError(error => {
            this.logger.log('Update index failed', error);
            this.trackService.track('insights.assign_new_speaker.failed', {
              category: EventCategory.MEDIA,
              data: { path: addSpeakerAction.data[0].path, error }
            });
            this.mediaCommonService.handelError(addSpeakerAction);
            return [MediaActions.updateIndexSaving({ saving: false })];
          })
        );
      })
    )
  );

  public updateVideoIndexSucceed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MediaActions.updateVideoIndexSucceed),
        tap(({ action }) => {
          this.mediaCommonService.handelSuccess(action);
        })
      ),
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<fromMedia.IState>,
    private logger: LoggerService,
    private trackService: TrackService,
    private urlUtility: UrlService,
    private mediaCommonService: MediaCommonService
  ) {}
}
