import { RequiredAIsModels } from '@common/modules/api/interfaces';

export enum IndexingPreset {
  AUDIO_ONLY = 'AudioOnly',
  BASIC = 'Basic',
  DEFAULT = 'Default',
  ADVANCED = 'Advanced',

  VIDEO_BASIC = 'BasicVideo',
  VIDEO_STANDARD = 'VideoOnly',
  VIDEO_ADVANCED = 'AdvancedVideo',

  AUDIO_BASIC = 'BasicAudio',
  AUDIO_STANDARD = 'AudioOnly',
  AUDIO_ADVANCED = 'AdvancedAudio',

  VIDEO_AUDIO_BASIC = 'Basic',
  VIDEO_AUDIO_STANDARD = 'Default',
  VIDEO_AUDIO_ADVANCED = 'Advanced'
}

export const presetToRequiredAIsMap = {
  [IndexingPreset.AUDIO_BASIC]: [RequiredAIsModels.ClosedCaptions, RequiredAIsModels.Transcription],
  [IndexingPreset.BASIC]: [RequiredAIsModels.ClosedCaptions, RequiredAIsModels.Keyframes, RequiredAIsModels.Transcription],
  [IndexingPreset.VIDEO_BASIC]: [RequiredAIsModels.Keyframes],
  [IndexingPreset.VIDEO_STANDARD]: [RequiredAIsModels.Keyframes],
  [IndexingPreset.VIDEO_ADVANCED]: [RequiredAIsModels.Keyframes],
  [IndexingPreset.AUDIO_STANDARD]: [RequiredAIsModels.AudioEffects, RequiredAIsModels.ClosedCaptions, RequiredAIsModels.Transcription],
  [IndexingPreset.AUDIO_ADVANCED]: [RequiredAIsModels.AudioEffects, RequiredAIsModels.ClosedCaptions, RequiredAIsModels.Transcription],
  [IndexingPreset.DEFAULT]: [
    RequiredAIsModels.AudioEffects,
    RequiredAIsModels.ClosedCaptions,
    RequiredAIsModels.Keyframes,
    RequiredAIsModels.Transcription
  ],
  [IndexingPreset.ADVANCED]: [
    RequiredAIsModels.AudioEffects,
    RequiredAIsModels.ClosedCaptions,
    RequiredAIsModels.Keyframes,
    RequiredAIsModels.Transcription
  ]
};
