export const localhostClientID = '032dea9b-5591-4512-816f-3a9125100501'; // 'eda45e57-a35d-41ca-94ac-c676cc3aa127';
export const appClientID = '9873b408-1903-416c-acd6-79e6a95ce6f3';

export interface IAuthenticatedUser {
  upn: string;
  name: string;
  id: string;
  type: string;
  isAdmin: boolean;
  isSuspended: boolean;
  tenantId?: string;
}

export enum AccountResourceType {
  TRIAL = 'TRIAL',
  ARM = 'ARM',
  CLASSIC = 'CLASSIC'
}

export enum UserType {
  MicrosoftCorpAad = 'MicrosoftCorpAad',
  Microsoft = 'Microsoft',
  Google = 'Google'
}
