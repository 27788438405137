export const resources = {
  UploadChooseFilesButton: '',
  UploadOrValue: '',
  UploadEnterUrlButton: '',
  UploadDropMediaFile: '',
  UploadUploadingGuidelines: '',
  UploadErrorFileNotSupported: '',
  UploadErrorFileSize: '',
  UploadDropMediaFiles: '',
  UploadUsingSavedIndexingSettingsLabel: ''
};
