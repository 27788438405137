import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

import { getKeyCode, KeyCode } from '../utils/event';

@Injectable()
export class AccessibilityModeHandlerService {
  public static accessibilityModeUpdate: Subject<boolean>;
  private static accessibilityModeOn = false;
  private static hasEventListener = false;
  @Inject(DOCUMENT) private document;
  constructor() {
    if (!AccessibilityModeHandlerService.hasEventListener) {
      AccessibilityModeHandlerService.accessibilityModeUpdate = new Subject();
      document.body.addEventListener('keydown', AccessibilityModeHandlerService.enableAccessibilityMode);
      AccessibilityModeHandlerService.hasEventListener = true;
    }
  }

  private static enableAccessibilityMode(event: KeyboardEvent): void {
    // Tab, enter and shift+tab
    try {
      const keyCode = getKeyCode(event);
      if (!keyCode) {
        return;
      }

      // If event is enter and came from input - ignore
      const target = event?.target as Element;
      if (keyCode === KeyCode.Enter && target?.tagName === 'INPUT') {
        return;
      }

      if (keyCode === KeyCode.Tab || keyCode === KeyCode.Enter || (event.shiftKey && keyCode === KeyCode.Tab)) {
        AccessibilityModeHandlerService.accessibilityModeOn = true;
        document.body.removeEventListener('keydown', AccessibilityModeHandlerService.enableAccessibilityMode);
        AccessibilityModeHandlerService.accessibilityModeUpdate.next(true);
      }
    } catch (error) {
      // Catch each key that is not on KeyCode list. No need to trace.
    }
  }

  public get AccessibilityMode(): boolean {
    return AccessibilityModeHandlerService.accessibilityModeOn;
  }

  public get AccessibilityModeSubject(): Subject<boolean> {
    return AccessibilityModeHandlerService.accessibilityModeUpdate;
  }
}
