export enum UIInviteActionType {
  OWNER = 'OWNER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  REMOVE = 'REMOVE',
  INVITATION = 'INVITATION',
  ROLE_ASSIGNMENT = 'ROLE_ASSIGNMENT'
}

export interface IUIAccountInvitation extends Microsoft.VideoIndexer.Contracts.InvitationContract {
  removing?: boolean;
}
