import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const closeAction: IAction = {
  icon: 'i-vi-close-small',
  title: '',
  key: 'SideBarCloseLabel',
  selected: false,
  type: UIActionType.CLOSE,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'sideMenuClose',
  titleAttribute: 'Close'
};
