import { Injectable } from '@angular/core';

import { take } from 'rxjs';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { IDialogButton, IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { VI_SURVEY } from '@common/modules/shared/links';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { isOverTimeOffset } from '@common/modules/utils/time';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { TrackService } from '@common/modules/core/services/track';

import { resources } from '../resources';

@Injectable()
export class SurveyService {
  private readonly SURVEY_MIN_DAYS_USER_IN_VI = 1000 * 60 * 60 * 24 * 7; // 7 days
  private readonly SURVEY_REPEAT_TIME_OFFSET = 1000 * 60 * 60 * 24 * 14; // 14 days

  constructor(
    private dialogService: DialogService,
    private localStorageService: LocalStorageService,
    private featureSwitchService: FeatureSwitchService,
    private trackService: TrackService
  ) {}

  public showSurveyPopup(userJoinTime: string) {
    if (this.isShowSurvey(userJoinTime)) {
      this.openSurveyDialog();
    }
  }

  private isShowSurvey(userJoinTime: string) {
    // Check feature switch
    const showSurveyPopupEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.SurveyPopup);
    if (!showSurveyPopupEnabled) {
      return false;
    }

    // Check "DoNotShowSurvey" cache param.
    // This will be true when user have already directed to the survey form
    const doNotShowCacheValue = this.localStorageService.getItem(StorageCacheKey.DoNotShowSurvey);
    if (doNotShowCacheValue) {
      return false;
    }

    // Check "LastTimeShowSurvey" cache param.
    // This will be true when 14 days have passed since user saw the popup and did not want to fill survey,
    // In this case we want to show the popup again
    const lastTimeShowSurvey = parseInt(this.localStorageService.getItem(StorageCacheKey.LastTimeShowSurvey), 10);
    if (lastTimeShowSurvey) {
      return isOverTimeOffset(lastTimeShowSurvey, this.SURVEY_REPEAT_TIME_OFFSET);
    }

    // Show only for user who join to VI portal for at least 7 days ago
    const isUserJoinAfterMin = isOverTimeOffset(userJoinTime, this.SURVEY_MIN_DAYS_USER_IN_VI);
    return isUserJoinAfterMin;
  }

  private openSurveyDialog() {
    const okButton: IDialogButton = {
      type: ActionButtonType.PRIMARY,
      action: {
        title: resources.SurveyDialogOkButton,
        value: null,
        id: 'openSurveyBtn'
      }
    };
    const cancelButton: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: resources.SurveyDialogCancelButton,
        value: null,
        id: 'cancelSurveyBtn'
      }
    };

    const surveyDialogData: IDialogData = {
      class: 'survey-dialog',
      title: resources.SurveyDialogTitle,
      content: resources.SurveyDialogMessage,
      primaryButton: okButton,
      secondaryButton: cancelButton
    };

    this.trackService.track('survey.popup.opened');
    const dialog = this.dialogService.openDialog(surveyDialogData, '440px');

    dialog.componentInstance.actionChange.pipe(take(1)).subscribe(event => {
      if (event.action === okButton.action) {
        window.open(VI_SURVEY, '_blank');
        this.trackService.track('survey.popup.cta.clicked');
        this.localStorageService.setItem(StorageCacheKey.DoNotShowSurvey, 'true');
      }

      if (event.action === cancelButton.action) {
        this.trackService.track('survey.popup.dismissed');
        this.localStorageService.setItem(StorageCacheKey.LastTimeShowSurvey, Date.now().toString());
      }
    });
  }
}
