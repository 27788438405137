import { Params } from '@angular/router';

import { map } from 'rxjs';

import { guid } from '@common/modules/utils/string';

import { environment } from '../../../../environments/environment';
import { concatParamsListToURL, getRequestSettings } from '../utils/request.function';
import { IDeleteFaceAPI } from '../../insights/services/interfaces';
import { IError } from '../../core/services/toast/interfaces';
import { prepareProjectSummarizedInsights, prepareVideoSummarizedInsights, trialLocationForSampleAccount } from '../utils/api-utils';
import {
  IAbuseParams,
  IAddKeywordsRequestParams,
  IAPIRequestParams,
  IAPISetThumbnailIdRequestParams,
  IAPISetThumbnailRequestParams,
  IArtifactRequestParams,
  ICaptionsRequestParams,
  ICreateTextualVideoSummaryParams,
  IGetBoundingBoxPageTimeRequestParams,
  IGetIndexRequestParams,
  IGetObservedPeoplePageTimeRequestParams,
  IGetStreamingUrlRequestParams,
  IHttpWrapper,
  IInsightsWidgetParams,
  IListRequestParams,
  IPlayerWidgetParams,
  IReIndexRequestParams,
  IRemoveBrandsRequestParams,
  IRenderFileDownloadUrl,
  IRequestParam,
  ITakeDownParams,
  IUpdateFaceRequestParams,
  IUpdateTranscriptRequestParams,
  IVideoRenderRequestParams
} from '../interfaces';

export class VideoApi {
  private timeToken;
  private apiBaseRoute = '/accounts';

  constructor(private ApiWrapper: IHttpWrapper) {
    this.timeToken = Date.now();
  }

  private get apiAuthBase(): string {
    return this.ApiWrapper.GetAuthApiBase(true) + this.apiBaseRoute;
  }

  private get apiLocation(): string {
    return this.ApiWrapper.GetApiLocation();
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos")]
  public list(accountId: string, requestParams?: IListRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithLocation(accountId)}/${accountId}/videos/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, requestParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}")]
  public get(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithLocation(accountId)}/${accountId}/videos/${videoId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}")]
  public deleteVideo(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [APIM]
  // [HttpDelete]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/SourceFile")]
  public deleteVideoSourceFile(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/sourceFile/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/Index")]
  public getVideoIndex(accountId: string, videoId: string, reqParams?: IGetIndexRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>(url, requestSettings).pipe(
      map(index => prepareVideoSummarizedInsights(index, this.ApiWrapper.Injector))
    );
  }

  // Get video index from custom url
  public getCustomVideoIndex(url: string) {
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>(url);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Projects/{projectId}/Index")]
  public getProjectIndex(accountId: string, projectId: string, reqParams?: IAPIRequestParams | Params, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/Projects/${projectId}/index`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.ProjectPlaylistContractV2>(url, requestSettings).pipe(
      map(index => prepareProjectSummarizedInsights(index, this.ApiWrapper.Injector))
    );
  }

  public getVideoTestIndex() {
    let base = '';
    if (environment.production && window.viewContext) {
      base = window.viewContext.codeLocation + 'apps/web/';
    }
    const url = `${base}/assets/testdata.json`;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>(url);
  }

  public getVideoIndexUrl(
    accountId: string,
    videoId: string,
    accessToken: string,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2,
    query: { [key: string]: string } = {}
  ): string {
    const params = {
      language: language,
      accessToken: accessToken,
      ...query
    };

    const serializedParams = this.ApiWrapper.UrlUtility.paramsToUrl(params);
    return `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/Index/${
      serializedParams ? '?' + serializedParams : ''
    }`;
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Index")]
  public saveVideoIndex(
    accountId: string,
    videoId: string,
    index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
    reqParams?: IAPIRequestParams,
    reqSettings?: object
  ) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>(url, index, requestSettings);
  }

  // [HttpPatch]
  // [Route("{location}/Accounts/{accountId}/Videos/{videoId}/Index[?language]
  public updateVideoIndex(
    accountId: string,
    videoId: string,
    updateData: Microsoft.VideoIndexer.Contracts.IJsonPatchDocument<Microsoft.VideoIndexer.Contracts.JsonPatchValue>[],
    reqParams?: IAPIRequestParams,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams);
    return this.ApiWrapper.HttpClient.patch<Microsoft.VideoIndexer.Contracts.PlaylistContractV2>(url, updateData, requestSettings).pipe(
      map(index => prepareVideoSummarizedInsights(index, this.ApiWrapper.Injector))
    );
  }

  // [APIM]
  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/SourceFile/DownloadUrl")]
  public getSourceFileDownloadUrl(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/sourceFile/downloadUrl`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/Captions")]
  public getCaptions(accountId: string, videoId: string, isBase: boolean, reqParams?: ICaptionsRequestParams, reqSettings?: object) {
    const type = isBase ? 'videos' : 'projects';
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/${type}/${videoId}/captions/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    requestSettings['responseType'] = 'text';
    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  public getCaptionsUrl(accountId: string, videoId: string, reqParams: ICaptionsRequestParams): string {
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, null, reqParams) as object;
    const serializedParams = this.ApiWrapper.UrlUtility.paramsToUrl(requestSettings['params']);
    return `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/captions/?${serializedParams}`;
  }

  // [APIM]
  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Thumbnails/{thumbnailId}")]
  public getThumbnail(accountId: string, videoId: string, thumbnailId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/thumbnails/${thumbnailId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    requestSettings['responseType'] = 'blob';
    return this.ApiWrapper.HttpClient.get(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Thumbnails")]
  public setThumbnail(accountId: string, videoId: string, thumbnailId: string, reqParams: IAPISetThumbnailRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/thumbnails/${thumbnailId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/ThumbnailId")]
  public setThumbnailId(accountId: string, videoId: string, reqParams: IAPISetThumbnailIdRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/ThumbnailId/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.put(url, null, requestSettings);
  }

  public getThumbnailUrl(accountId: string, videoId: string, thumbnailId: string): string {
    // BE return thumbnailId=Guid(0) when there isn't thumbnail
    if (thumbnailId === guid(0)) {
      return '';
    }
    return `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/thumbnails/${thumbnailId}`;
  }

  public getThumbnailSpriteCustomUrl(customURL: string, page: number = 0): string {
    return `${this.getCustomUrl(customURL, 'FacesLegendSprite')}/${page}`;
  }

  public getKeyframesSpriteCustomUrl(customURL: string, page: number = 0): string {
    return `${this.getCustomUrl(customURL, 'KeyframesSprite')}/${page}`;
  }

  /***
   * Get sprite url - generic for all kind of sprite types.
   * [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Sprite")]
   * Instead of:
   * [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Thumbnails/FacesLegendSprite/{pageIndex}")]
   * [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Thumbnails/KeyframesSprite/{pageIndex}")]
   ***/
  public getSpriteUrl(
    accountId: string,
    videoId: string,
    accessToken: string,
    pageIndex: number,
    spriteType: Microsoft.VideoIndexer.Contracts.SpriteType
  ): string {
    const tokenParam = accessToken && !accessToken.includes('refresh') ? `&accessToken=${accessToken}` : '';
    return `${this.apiInternalsBaseWithMediaApiLocation(
      accountId
    )}/${accountId}/videos/${videoId}/sprite/?type=${spriteType}&pageIndex=${pageIndex}${tokenParam}`;
  }

  public getTestThumbnailSpriteUrl(page: number = 0): string {
    let base = '';
    if (environment.production && window.viewContext) {
      base = window.viewContext.codeLocation;
    }
    return `${base}/assets/sprite${page}.jpg`;
  }

  public getTestKeyframesSpriteUrl(page: number = 0): string {
    let base = '';
    if (environment.production && window.viewContext) {
      base = window.viewContext.codeLocation;
    }
    return `${base}/assets/kf_sprite${page <= 5 ? page : 0}.jpg`;
  }

  // [APIM]
  // [HttpPut]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/ReIndex")]
  public reIndex(accountId: string, videoId: string, reqParams?: IReIndexRequestParams, reqSettings?: object, paramsList?: IRequestParam[]) {
    let url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/reIndex/`;
    url = concatParamsListToURL(url, paramsList);
    const requestSettings = (<object>getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams)) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [APIM]
  // [HttpPut]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/Index/Faces/{faceId}")]
  public updateFace(accountId: string, videoId: string, faceId: number, reqParams?: IUpdateFaceRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index/faces/${faceId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/Index/Transcript")]
  public updateTranscript(accountId: string, videoId: string, reqParams?: IUpdateTranscriptRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index/transcript/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, reqParams, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/labels/{labelId}")]
  public removeLabels(accountId: string, videoId: string, labelId: number, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/labels/${labelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Keywords/{keywordId}")]
  public removeKeywords(accountId: string, videoId: string, keywordId: number, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/Keywords/${keywordId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}}/Keywords")]
  public addKeywords(accountId: string, videoId: string, reqParams: IAddKeywordsRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/keywords`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<number>(url, null, requestSettings);
  }

  // [HttpDelete]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/Index/Faces/{faceId}")]
  public removeFaces({ accountId, videoId, faceId, reqSettings }: IDeleteFaceAPI) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/index/faces/${faceId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/RemoveBrands")]
  public removeBrands(accountId: string, videoId: string, reqParams?: IRemoveBrandsRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/removeBrands/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/auth/Accounts/{accountId}/Videos/{videoId}/AccessToken")]
  public getVideoAccessToken(accountId: string, videoId: string, allowEdit?: boolean, reqSettings?: object) {
    const url = `${this.apiAuthBase}/${accountId}/videos/${videoId}/accessToken/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;
    requestSettings['params'].allowEdit = allowEdit;
    requestSettings['params'].location = requestSettings['params'].location ?? this.apiLocation;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Takedown")]
  public takedown(accountId: string, videoId: string, takedownParams: ITakeDownParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/takedown/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, takedownParams) as object;

    return this.ApiWrapper.HttpClient.post<void>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/TakedownByAdmin")]
  public takedownByAdmin(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/takedownByAdmin/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/Unquarantine")]
  public unquarantine(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/unquarantine/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<void>(url, null, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/InsightsWidget")]
  public getInsightsWidget(accountId: string, videoId: string, insightsParams: IInsightsWidgetParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/insightsWidget/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, insightsParams) as object;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/PlayerWidget")]
  public getPlayerWidget(accountId: string, videoId: string, playerParams: IPlayerWidgetParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/insightsWidget/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, playerParams) as object;

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpGet]
  public getArtifactByUrl(url: string, reqSettings?: object) {
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/ArtifactUrl")]
  public getArtifactUrl(accountId: string, videoId: string, reqParams?: IArtifactRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/ArtifactUrl/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/ArtifactsZip")]
  public getArtifactZip(accountId: string, videoId: string, reqParams?: IArtifactRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/ArtifactsZip/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    requestSettings['responseType'] = 'blob';
    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/ReportAbuse")]
  public reportAbuse(accountId: string, videoId: string, abuseData: IAbuseParams) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/reportAbuse/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache) as object;

    return this.ApiWrapper.HttpClient.post(url, abuseData, requestSettings);
  }

  // [HttpPost]
  // [Route("/{location}/Accounts/{accountId}/Projects/{projectId}/render")]
  public renderProject(accountId: string, projectId: string, reqParams?: IVideoRenderRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/projects/${projectId}/render`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.post(url, null, requestSettings);
  }

  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Projects/{projectId}/renderoperation")]
  public checkRenderProjectStatus(accountId: string, projectId: string, reqParams?: IAPIRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/projects/${projectId}/renderoperation`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<
      Microsoft.VideoIndexer.Contracts.AsyncOperationContract<Microsoft.VideoIndexer.Contracts.ProjectRenderOperationResultContract, IError>
    >(url, requestSettings);
  }

  // [HttpGet]
  // [Route("{ProjectId}/renderedfile/downloadurl/")]
  public getProjectRenderedFileDownloadUrl(accountId: string, projectId: string, reqParams?: IAPIRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/Projects/${projectId}/renderedfile/downloadurl/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<IRenderFileDownloadUrl>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("{ProjectId}/renderoperation/Cancel")]
  public cancelRenderProject(accountId: string, projectId: string, reqParams?: IAPIRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/Projects/${projectId}/renderoperation/Cancel`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.put(url, null, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}/ReportAbuse")]
  public requestReview(accountId: string, videoId: string) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/requestReview/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache) as object;

    return this.ApiWrapper.HttpClient.post(url, null, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/streaming-url")]
  public getVideoStreamingURL(accountId: string, videoId: string, reqParams?: IGetStreamingUrlRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/streaming-url`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.StreamingUrlContract>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/ObservedPeople/Pages/{pageId}")]
  public getObservedPeoplePageByID(accountId: string, videoId: string, pageId: number, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/ObservedPeople/Pages/${pageId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IObservedPeoplePage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/ObservedPeople/pages?time={time}")]
  public getObservedPeoplePageByTime(accountId: string, videoId: string, reqParams?: IGetObservedPeoplePageTimeRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/ObservedPeople/Pages`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IObservedPeoplePage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/FacesBoundaryBoxes/Pages/{pageId}")]
  public getFacesPageByID(accountId: string, videoId: string, pageId: number, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/FacesBoundaryBoxes/Pages/${pageId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IBoundingBoxesPage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/FacesBoundaryBoxes/Pages?time={time}")]
  public getFacesPageByTime(accountId: string, videoId: string, reqParams?: IGetBoundingBoxPageTimeRequestParams, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/FacesBoundaryBoxes/Pages`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IBoundingBoxesPage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/DetectedObjects/Pages/{pageId}")]
  public getDetectedObjectsPageByID(accountId: string, videoId: string, pageId: number, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithLocation(accountId)}/${accountId}/videos/${videoId}/DetectedObjects/Pages/${pageId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IDetectedObjectsPage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/DetectedObjects/
  //          Pages?time={time}}&IncludedObjectTypes={type1}&IncludedObjectTypes={type2}")]
  public getDetectedObjectsPageByTime(
    accountId: string,
    videoId: string,
    reqParams?: IGetBoundingBoxPageTimeRequestParams,
    reqSettings?: object,
    paramsList?: IRequestParam[]
  ) {
    let url = `${this.apiInternalsBaseWithLocation(accountId)}/${accountId}/videos/${videoId}/DetectedObjects/Pages`;
    url = concatParamsListToURL(url, paramsList);
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.IDetectedObjectsPage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/summaries/textual]
  public listTextualVideoSummaries(accountId: string, videoId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithLocation(accountId)}/${accountId}/videos/${videoId}/summaries/textual`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.TextualSummarizationPage>(url, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/aoaideployments]
  public listAOAIDeployments(accountId: string, reqSettings?: object) {
    const url = `${this.apiInternalsBaseWithLocation(accountId)}/${accountId}/aoaideployments`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AOAIDeployments>(url, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Videos/{videoId}}/Summaries/Textual")]
  public createTextualVideoSummary(accountId: string, videoId: string, reqParams: ICreateTextualVideoSummaryParams, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithMediaApiLocation(accountId)}/${accountId}/videos/${videoId}/Summaries/Textual`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.TextualSummarizationJobWithSummaryContent>(url, null, requestSettings);
  }

  // [APIM]
  // [HttpGet]
  // [Route("/{location}/Accounts/{accountId}/Videos/{videoId}/summaries/textual/{summaryId}]
  public getTextualVideoSummary(accountId: string, videoId: string, summaryId: string, reqSettings?: object) {
    const url = `${this.apiOperationBaseWithLocation(accountId)}/${accountId}/videos/${videoId}/summaries/textual/${summaryId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.TextualSummarizationJobWithSummaryContent>(url, requestSettings);
  }

  private apiOperationBaseWithLocation(accountId: string): string {
    const location = trialLocationForSampleAccount(accountId);
    return `${this.ApiWrapper.GetApiOperationsBase(false, location)}${this.apiBaseRoute}`;
  }

  private apiOperationBaseWithMediaApiLocation(accountId: string): string {
    const location = trialLocationForSampleAccount(accountId) || this.ApiWrapper.GetApiMediaLocation();
    return `${this.ApiWrapper.GetApiOperationsBase(false, location)}${this.apiBaseRoute}`;
  }

  private apiInternalsBaseWithLocation(accountId: string): string {
    const location = trialLocationForSampleAccount(accountId);
    return this.ApiWrapper.GetInternalApiBase(location) + this.apiBaseRoute;
  }

  private apiInternalsBaseWithMediaApiLocation(accountId: string): string {
    const location = trialLocationForSampleAccount(accountId) || this.ApiWrapper.GetApiMediaLocation();
    return this.ApiWrapper.GetInternalApiBase(location) + this.apiBaseRoute;
  }

  private getCustomUrl(customURL: string, spriteType: string): string {
    let url = '';
    if (customURL) {
      // Check if url ends with '/'
      url = `${customURL.lastIndexOf('/') === customURL.length - 1 ? customURL : customURL + '/'}${spriteType}`;
    }
    return url;
  }
}
