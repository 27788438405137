// Angular imports
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Modules
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import { SharedModule } from '@common/modules/shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { LoginErrorComponent } from '../components/login-error/login-error.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { UserAlreadyExistsComponent } from '../components/user-already-exists/user-already-exists.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, LoginErrorComponent, UserAlreadyExistsComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot({ positionClass: 'inline', maxOpened: 6 }),
    ToastContainerModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
