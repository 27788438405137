export const resources = {
  IndexingSingleFileLabel: '',
  ReIndexSummaryTitle: '',
  ReIndexAmsUnreachableErrorMsg: '',
  ReIndexSourceVideoDeletedErrorMsg: '',
  ReIndexVideoIndexingInProgressErrorMsg: '',
  ReIndexFileInReviewErrorMsg: '',
  ReIndexFileWasReportedErrorMsg: '',
  ReIndexProjectRenderInProgressErrorMsg: '',
  ReIndexAmsAadAppNotInTenantErrorMsg: '',
  ReIndexGeneralErrorMessage: '',
  ReIndexStorageAccessDeniedErrorMsg: '',
  StorageAccessDeniedErrorMsgLinkText: '',
  ReIndexStorageUnreachableErrorMsg: '',
  ReIndexFileManagedIdentityMissingErrorMsg: ''
};
