import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IUISpeechModelTableDetail, TrainModelDialogSource } from '../../interfaces';

import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;
import SpeechModelInput = Microsoft.VideoIndexer.Contracts.CustomSpeechModelInput;

export const loadSpeechModels = createAction('[SpeechModel/API] Load Speech Models');
export const loadSpeechModelsSucceeded = createAction('[SpeechModel] Load Speech models succeeded', props<{ models: SpeechModel[] }>());
export const loadSpeechModelsFailed = createAction('[SpeechModel]  Load Speech models failed');
export const clearSpeechModels = createAction('[SpeechModel]  Clear Speech models');
export const loadSpeechModelsSupportedLocalesSucceeded = createAction(
  '[SpeechModel] Load Speech models supported locales succeeded',
  props<{ locales: string[] }>()
);
export const loadSpeechModelsSupportedLocalesFailed = createAction(
  '[SpeechModel/API] Load Speech models supported locales failed',
  props<{ error: Error }>()
);

export const openEditSpeechModelDialog = createAction('[SpeechModel] Open edit Speech model dialog', props<{ model: IUISpeechModelTableDetail }>());

export const updateSpeechModel = createAction(
  '[SpeechModel/API] Update Speech model',
  props<{ modelId: string; properties: Pick<SpeechModel, 'displayName' | 'description' | 'customProperties'> }>()
);
export const updateSpeechModelStarted = createAction('[SpeechModel] Update Speech model started');
export const updateSpeechModelSucceed = createAction('[SpeechModel] Update Speech model Succeed', props<{ model: SpeechModel }>());
export const updateSpeechModelFailed = createAction('[SpeechModel] Update Speech model Failed');
export const removeSpeechModel = createAction('[SpeechModel] Remove Speech model', props<{ modelId: string }>());
export const createSpeechModel = createAction('[SpeechModel] Create Speech model', props<{ properties: SpeechModelInput }>());
export const createSpeechModelStarted = createAction('[SpeechModel] Create Speech model started', props<{ model: SpeechModel }>());
export const createSpeechModelSucceed = createAction('[SpeechModel] Create Speech model Succeed', props<{ model: SpeechModel }>());
export const createSpeechModelFailed = createAction('[SpeechModel] Create Speech model Failed', props<{ modelId: string }>());
export const openTrainModelDialog = createAction('[SpeechModel] Open Train Speech Model Dialog', props<{ source: TrainModelDialogSource }>());
export const trainModelDialogClosed = createAction('[SpeechModel] Train Speech Model Dialog Closed');
export const openViewModelDialog = createAction('[SpeechModel] Open View Speech Model Dialog', props<{ model: IUISpeechModelTableDetail }>());
export const openSpeechDeleteModelDialog = createAction(
  '[SpeechModel] Open Delete Speech Model Dialog',
  props<{ model: IUISpeechModelTableDetail }>()
);
export const deleteSpeechModel = createAction('[SpeechModel/API] Delete Speech Model', props<{ model: IUISpeechModelTableDetail }>());
export const deleteSpeechModelStarted = createAction('[SpeechModel] Delete Speech Model Started');
export const deleteSpeechModelSucceed = createAction('[SpeechModel] Delete Speech Model Succeed', props<{ modelId: string }>());
export const deleteSpeechModelFailed = createAction(
  '[SpeechModel] Delete Speech Model Failed',
  props<{ error: HttpErrorResponse; modelId: string; modelName: string }>()
);
export const getModelStatus = createAction('[SpeechModel/API] Get Speech Model status', props<{ modelId: string }>());
export const getModelStatusSucceeded = createAction('[SpeechModel] Get Speech Model status Succeeded ', props<{ model: SpeechModel }>());
export const getModelStatusFailed = createAction('[SpeechModel] [SpeechModel] Get Speech Model status Failed', props<{ error: Error }>());
