import { Injectable } from '@angular/core';

import { PageTiming } from '..';
import { ConfigService } from '../../config/config.service';
import { TrackService } from '../../track';

@Injectable()
export class PageTimingService {
  constructor(private track: TrackService, private config: ConfigService) {}

  public init() {
    const pageTimings = new PageTiming({
      onTimingsCollected: timings => {
        this.track.trackPageTimings({ timings });
      },
      navigationTimingOptions: {
        precision: 0
      },
      paintTimingOptions: {
        captureFirstPaint: true
      },
      resourceTimingOptions: {
        domains: [this.config.cdnDomain]
      }
    });

    pageTimings.collect();
  }
}
