import { Directive, OnInit, ElementRef, Renderer2, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

interface ReadMoreLinkStyle {
  fontSize: string;
  fontWeight: string;
  lineHeight: string;
  color: string;
}

@Directive({
  selector: '[viTextReadMore]'
})
export class TextReadMoreDirective implements OnInit, OnDestroy {
  // Full text to represent
  @Input() public text: string;
  // When text has more characters than maxChars, cut the text and add '... read more'
  @Input() public maxChars: number;

  // read more link styling
  @Input() public linkStyling?: ReadMoreLinkStyle = {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: 'var(--primary)'
  };

  @Output() readMoreClicked = new EventEmitter<void>();

  private readMoreText?: string;
  private readMoreLink: HTMLLinkElement;

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateHelperService) {
    this.readMoreText = this.translate.instant('ReadMore');
  }

  public ngOnInit(): void {
    // add all text if it has not characters more than maxChars
    if (this.text.length <= this.maxChars) {
      this.el.nativeElement.innerText = this.text;
      return;
    }

    // Cut the text according to maxChars and for adding '... read more'
    const ellipsis = '... ';
    const truncatedLength = this.maxChars - ellipsis.length - this.readMoreText.length;
    const truncate = this.text.substring(0, truncatedLength);

    // create read more link
    this.setReadMoreLink();

    // create the element with the cut text and '... read more'
    const truncateElement = this.renderer.createElement('span');
    truncateElement.innerText = truncate + ellipsis;
    this.renderer.appendChild(truncateElement, this.readMoreLink);

    // add the element to the DOM
    this.renderer.appendChild(this.el.nativeElement, truncateElement);
  }

  public ngOnDestroy(): void {
    if (!this.readMoreLink) {
      return;
    }
    this.readMoreLink.removeEventListener('mouseup', this.showFullText);
    this.readMoreLink.removeEventListener('keyup.enter', this.showFullText);
    this.readMoreLink.removeEventListener('keyup.Space', this.showFullText);
  }

  private setReadMoreLink() {
    // create read more link element
    this.readMoreLink = this.renderer.createElement('a');
    this.readMoreLink.setAttribute('href', 'javascript:void(0);');
    this.readMoreLink.innerText = this.readMoreText;

    // add event listeners (all listeners necessary for accessibility)
    this.readMoreLink.addEventListener('mouseup', this.showFullText.bind(this));
    this.readMoreLink.addEventListener('keyup.enter', this.showFullText.bind(this));
    this.readMoreLink.addEventListener('keyup.Space', this.showFullText.bind(this));

    // add styling to the link
    const css = `font-weight: ${this.linkStyling.fontWeight};
    font-size: ${this.linkStyling.fontSize};
    line-height: ${this.linkStyling.lineHeight};
    color: ${this.linkStyling.color}`;
    this.readMoreLink.setAttribute('style', css);
  }

  private showFullText(): void {
    this.el.nativeElement.innerText = this.text;
    this.readMoreClicked.emit();
  }
}
