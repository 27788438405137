<ul role="listbox"
  [attr.aria-label]="listLabel"
  [title]="listLabel">
  <!-- CAPSULE LIST -->
  <li #itemObject
    *ngFor="let item of items; index as i; trackBy: trackByIndex"
    [id]="getCapsuleId(item)"
    [title]="item.name"
    class="item capsule"
    [attr.tabindex]="activeContainer ? -1 : 0"
    [ngClass]="{selected: item.selected, editable: (edit && item.id > 0), timeline: timeline, custom: item?.customClass}"
    (appAccessibleClick)="selectItem(item)"
    appAccessibilityAnnouncer
    listenedEvent="enter"
    [announceText]="getShowingInsightTimelineText(item)"
    role="option"
    [attr.aria-label]="item.name"
    [attr.aria-selected]="item.selected">
    <i [attr.dir]="dir"
      class="i-vi-delete"
      *ngIf="(edit && item.id > 0)"
      role="button"
      (appAccessibleClick)="removeItem(item, $event)"
      appAccessibilityTooltip
      [showAccessibilityTooltip]="!activeContainer && accessibilityMode"
      [attr.title]="resources?.KeywordRemoveTitle"
      [attr.tabindex]="activeContainer ? -1 : 0"
      [attr.aria-hidden]="activeContainer? 'true':'false'"
      [attr.aria-label]="resources?.KeywordRemoveTitle"></i>
    <span [attr.title]="item.name"
      class="item-text"
      [innerHTML]="item.name"></span>
    <ng-container class="injected-input"
      *ngTemplateOutlet="injectedInputTemplate, context: { $implicit: item }"> </ng-container>
  </li>
  <div class="add-capsule-container"
    *ngIf="edit && enableAddNewItem"
    role="none">
    <app-vi-insights-capsule-input [text]="placeholderText"
      [EnterText]="placeholderEnterText"
      [items]="items"
      (addCapsule)="handleAddCapsule($event)"
      [activeContainer]="activeContainer">
    </app-vi-insights-capsule-input>
  </div>
  <!-- PLACEHOLDER -->
  <li class="item overflow"
    role="button"
    [attr.tabindex]="activeContainer ? -1 : 0"
    (mouseup)="toggleExpandMouse($event)"
    (keyup.enter)="toggleExpand(true)"
    [attr.title]="resources?.ShowMore"
    *ngIf="conf?.overflowValue && !conf?.expanded"
    [ngStyle]="{'width.px': conf.overflowWidth}">

    <div class="circle">
      {{ conf.overflowSentence ? conf.overflowSentence : ('+' + conf.overflowValue) }}
    </div>
  </li>
</ul>
