import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { InsightsImagesService } from '@common/modules/shared/components/insights-images/insights-images.service';
import { InsightsCommonDataService } from '@common/modules/insights-common/insights-common-data.service';
import { InsightsCommonUtilsService } from '@common/modules/insights-common/insights-common-utils.service';
import { TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { DetectedObjectsStore } from './../classes/detected-objects-store';
import * as fromMedia from '../selectors/media.selectors';
import { UpdateVideoIndexAction } from '../interfaces';
import * as MediaActions from '../actions/media.actions';

@Injectable()
export class MediaCommonStoreService {
  // INSIGHTS CLASSES
  public DetectedObjects: DetectedObjectsStore;

  constructor(
    private readonly store: Store<fromMedia.IState>,
    private insightsImagesService: InsightsImagesService,
    private dataService: InsightsCommonDataService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private trackService: TrackService,
    private translate: TranslateHelperService
  ) {
    this.init();
  }

  public updateVideoIndex(action: UpdateVideoIndexAction) {
    this.store.dispatch(MediaActions.updateVideoIndex({ action }));
  }

  public assignNewSpeaker(addSpeakerAction: UpdateVideoIndexAction, updateTranscriptLineAction: UpdateVideoIndexAction) {
    this.store.dispatch(MediaActions.assignNewSpeaker({ addSpeakerAction, updateTranscriptLineAction }));
  }

  public selectedIndexSpeakers(): Observable<Microsoft.VideoIndexer.Contracts.Speaker[]> {
    return this.store.select(fromMedia.selectedMediaIndexSpeakers);
  }

  public selectedLogos(): Observable<Microsoft.VideoIndexer.Contracts.Logo[]> {
    return this.store.select(fromMedia.selectedMediaInsightsLogos);
  }

  public selectMediaIndexDuration() {
    return this.store.select(fromMedia.selectMediaIndexDuration);
  }

  public selectInsightsFilterValue() {
    return this.store.select(fromMedia.selectInsightsFilterValue);
  }

  public upsertIndex(index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    this.store.dispatch(MediaActions.upsertMediaIndex({ mediaIndex: index }));
  }

  public selectAllIndexes(): Observable<Microsoft.VideoIndexer.Contracts.PlaylistContractV2[]> {
    return this.store.select(fromMedia.selectAllIndexes);
  }

  public selectedMediaIndex() {
    return this.store.select(fromMedia.selectedMediaIndex);
  }

  public selectIndex(id: string) {
    this.store.dispatch(MediaActions.selectMediaIndex({ id }));
  }

  public setInsightsFilterValue(value: string) {
    this.store.dispatch(MediaActions.setInsightsFilterValue({ value }));
  }

  public selectFilteredLogos$(): Observable<Microsoft.VideoIndexer.Contracts.Logo[]> {
    return this.store.select(fromMedia.selectFilteredLogos);
  }

  private init() {
    this.DetectedObjects = new DetectedObjectsStore(
      this.store,
      this.insightsImagesService,
      this.dataService,
      this.insightsCommonUtilsService,
      this.trackService,
      this.translate
    );
  }
}
