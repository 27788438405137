import jwt_decode, { JwtPayload } from 'jwt-decode';

import { TokenScope } from '@common/modules/api/interfaces';
import { AccountAccessTokenClaims, AccountPermission } from '@common/modules/shared/interfaces';

export function isJwtTokenExpired(token: string): boolean {
  const decoded = jwt_decode(token) as JwtPayload;
  if (decoded.exp === undefined) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);

  return new Date().valueOf() >= date?.valueOf() || false;
}

export function allowEditToPermission(allowEdit: boolean) {
  return allowEdit ? AccountPermission.CONTRIBUTOR : AccountPermission.READER;
}

export function getTokenScope(id?: string, accountId?: string, isProject = false): TokenScope {
  if (id && accountId) {
    if (isProject) {
      return TokenScope.PROJECT;
    }
    return TokenScope.VIDEO;
  } else if (accountId) {
    return TokenScope.ACCOUNT;
  } else {
    return TokenScope.USER;
  }
}

/**
 *
 * @param accessToken string
 * @returns object
 *
 * Extracts claims from access token by:
 * 1. splitting access token by '.' to get the the second part of the JWT which is the claims
 * 2. decoding the base64 string to get the stringified JSON object
 * 3. parsing the stringified to it's JSON format
 *
 */
function extractClaimsFromAccessToken(accessToken: string): Partial<AccountAccessTokenClaims> {
  const claims = accessToken.split('.')[1];

  return JSON.parse(atob(claims));
}

export function extractPermissionFromAccessToken(accessToken: string) {
  const claims = extractClaimsFromAccessToken(accessToken);

  return claims?.Permission;
}
