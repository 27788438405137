import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';

import { Subject, take, takeUntil } from 'rxjs';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { IUIAccount, NavigationState } from '@common/modules/core/services/interfaces';
import { envTypes } from '@common/modules/api/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { resources } from './resources';
import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';
import { BadgeClasses } from '../../../shell/interfaces';

@Component({
  selector: 'vi-accounts-list-button',
  templateUrl: './accounts-list-button.component.html',
  styleUrls: ['./accounts-list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountsListButtonComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() selected = false;

  @Output() toggle = new EventEmitter();

  public selectedAccount: IUIAccount;
  public hasEdgeExtensions: boolean;
  public isSideBarOpened = false;

  public resources = resources;

  private destroy$ = new Subject<void>();

  constructor(
    private coreStore: CoreStoreService,
    private edgeExtensionsStore: EdgeExtensionsStoreService,
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.translate.translateResourcesInstant(this.resources);
    this.coreStore.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe(account => {
      this.selectedAccount = account;
      this.cdr.detectChanges();
    });
    this.edgeExtensionsStore.hasEdgeExtensions$.pipe(takeUntil(this.destroy$)).subscribe(hasEdgeExtensions => {
      this.hasEdgeExtensions = hasEdgeExtensions;
      this.cdr.detectChanges();
    });
    this.coreStore.sidebarStateStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.isSideBarOpened = false;
      if (status === NavigationState.OPEN) {
        this.isSideBarOpened = true;
      }
      this.cdr.detectChanges();
    });
    this.accessibilityModeSubject.pipe(takeUntil(this.destroy$), take(1)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isTrial(): boolean {
    return this.selectedAccount?.location === envTypes.TRIAL;
  }

  public getBadgeClass(): string {
    if (this.isTrial()) {
      return `${BadgeClasses.Trial} header`;
    }

    return this.hasEdgeExtensions ? BadgeClasses.Hybrid : BadgeClasses.NoBadge;
  }

  public getBadgeText(): string {
    if (this.isTrial()) {
      return this.resources.HeaderTrial;
    }

    return this.hasEdgeExtensions ? this.resources.Hybrid : '';
  }

  public openAccountsList() {
    this.toggle.emit();
  }
}
