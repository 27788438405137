import { createSelector } from '@ngrx/store';

import { AccountResourceType } from '@common/modules/auth/interfaces';

import {
  accountError,
  selectedAccountLoaded,
  selectAccountResourceType,
  hasSelectedEdgeExtension,
  selectedEdgeExtensionHandshakeDone
} from '../../../core/selectors';
import { selectGalleryState } from '../reducers';
import { isLibraryLoaded, libraryError } from './library.selectors';
import { isProjectsLoaded, projectsError } from './projects.selectors';
import {
  isSpeechModelsLoaded,
  selectSupportedLanguagesIdsSuccess,
  isLanguageModelsLoaded,
  selectSupportedLanguagesIdsError,
  isSpeechModelError,
  isLanguageModelsError
} from '../../../customization-data/selectors';

export const getGalleryViewState = createSelector(selectGalleryState, state => state.galleryView);

export const selectedGalleryTab = createSelector(getGalleryViewState, state => state.selectedTab);

export const selectedGalleryView = createSelector(getGalleryViewState, state => state.view);

export const isLanguagesLoaded = createSelector(
  selectSupportedLanguagesIdsSuccess,
  isLanguageModelsLoaded,
  isSpeechModelsLoaded,
  selectAccountResourceType,
  hasSelectedEdgeExtension,
  selectedEdgeExtensionHandshakeDone,
  (langLoaded, langModelsLoaded, speechModelsLoaded, accountType, hasSelectedEdgeExtension, handshakeDone) => {
    if (hasSelectedEdgeExtension) {
      return handshakeDone;
    }
    return langLoaded && langModelsLoaded && (speechModelsLoaded || accountType === AccountResourceType.CLASSIC);
  }
);

export const showLanguagesError = createSelector(
  selectSupportedLanguagesIdsError,
  isLanguageModelsError,
  isSpeechModelError,
  (langError, langModelsError, speechError) => {
    return langError || langModelsError || speechError;
  }
);

export const isUploadEnabled = createSelector(
  isLibraryLoaded,
  isProjectsLoaded,
  selectedAccountLoaded,
  isLanguagesLoaded,
  (libLoaded, projLoaded, accountLoaded, languagesLoaded) => (libLoaded || projLoaded || accountLoaded) && languagesLoaded
);

export const showUploadError = createSelector(
  libraryError,
  projectsError,
  accountError,
  (libError, projError, accountError) => libError || projError || accountError
);
