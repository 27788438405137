<a type="link"
  class="action-link {{sizeClass}}"
  id="{{action.id}}"
  role="link"
  target="_blank"
  [ngClass]="{responsive: responsive}"
  [style.width]="width"
  [attr.aria-pressed]="action?.hasPressedMode ? action?.selected && action?.selectable : null"
  [title]="action?.titleAttribute || action?.title"
  [attr.aria-label]="action?.titleAttribute || action?.title"
  [attr.aria-disabled]="isDisabled"
  [attr.disabled]="isDisabled ? '' : null"
  [href]="action?.href"
  appAccessibilityTooltip
  [showAccessibilityTooltip]="(showAccessibilityTooltip && accessibilityMode && (!action?.title))"
  [attr.tabindex]="activeContainer ? -1 : 0">
  <i class="{{action?.icon}}"
    *ngIf="action?.icon"></i>
  <span *ngIf="action?.title">{{action?.title}}</span>
</a>
