import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter, Input, OnChanges } from '@angular/core';

import { combineLatestWith } from 'rxjs/operators';
import { takeUntil, Observable, Subject } from 'rxjs';

import { NavBarItem, AccordionMode } from '@vi-ui/core';

import { ComponentChanges, IAction } from '@common/modules/shared/interfaces';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { CustomizationPage, FeatureSwitch } from '@common/modules/core/services/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { DataService } from '@common/modules/shared/services/data.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { EdgeExtensionsStoreService } from '../../../../core/services/edge-extensions-store.service';
import { CustomizationDataStoreService } from '../../../../customization-data/services/customization-data-store.service';
import { AdvancedSettings, IndexingMode, UploadMode } from './../../../interfaces';
import { resources } from './resources';
import { IndexingStoreService } from './../../../services/indexing-store.service';
import { settings, edgeSettings } from './settings';
import * as actions from './actions';
import { IndexingPreset } from '../settings';
import { isAdvancedIndexingPresetSelected } from '../../../core/effects/utils/indexing-utils';

@Component({
  selector: 'vi-indexing-form',
  templateUrl: './indexing-form.component.html',
  styleUrls: ['./indexing-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isDisabled = false;
  @Output() public manageModelsClicked = new EventEmitter<CustomizationPage>();
  @Output() public navigateToCreateAccount = new EventEmitter<void>();

  public showAdvancedMode: boolean = false;
  public selectedAdvancedSettings$ = new Observable<AdvancedSettings>();
  public indexingMode: IndexingMode;
  public indexingPreset$ = new Observable<IndexingPreset>();
  public excludeRAI$ = new Observable<boolean>();
  public settingsItems: NavBarItem[] = settings;
  public edgeSettingsItems: NavBarItem[] = edgeSettings;
  public isUserConfirmTerms: boolean = false;
  public backToBasicAction: IAction = actions.backToBasic;
  public advancedSettingsAction: IAction = actions.advancedSettings;
  public AdvancedSettings = AdvancedSettings;
  public ActionButtonType = ActionButtonType;
  public IndexingMode = IndexingMode;
  public AccordionMode = AccordionMode;
  // Indicates if the user switched to multi file mode at least once
  public isMultiFileMode: boolean = false;
  public isAdvancedIndexingPresetSelected: boolean;
  public resources = resources;

  // Mobile
  public isGeneralSettingsMobileOpened = true;
  public readonly GENERAL_SETTINGS_MOBILE_ID = 'generalSettingMobileItem';
  public isMobileView = false;
  public fileInfoTitle: string;
  public enableBasicEdgePreset = false;
  public hasSelectedEdgeExtension = false;

  private destroy$ = new Subject<void>();
  constructor(
    private indexingStore: IndexingStoreService,
    private customizationDataStoreService: CustomizationDataStoreService,
    private translate: TranslateHelperService,
    private trackService: TrackService,
    private dataService: DataService,
    private featureSwitchService: FeatureSwitchService,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {}

  public get fileInfoDisabled(): boolean {
    return this.indexingMode === IndexingMode.ReIndex;
  }

  public get showFileNamesSection() {
    return this.indexingMode === IndexingMode.Upload && this.isMultiFileMode;
  }

  public get settings() {
    return this.hasSelectedEdgeExtension ? this.edgeSettingsItems : this.settingsItems;
  }

  public set settings(settings: NavBarItem[]) {
    if (this.hasSelectedEdgeExtension) {
      this.edgeSettingsItems = settings;
    } else {
      this.settingsItems = settings;
    }
  }

  public ngOnInit(): void {
    this.enableBasicEdgePreset = this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeBasicPreset);

    this.initTranslations();
    this.edgeExtensionsStore.hasSelectedEdgeExtension$.pipe(takeUntil(this.destroy$)).subscribe(hasSelectedEdgeExtension => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
    });
    this.indexingStore.indexingMode$.pipe(takeUntil(this.destroy$)).subscribe(indexingMode => {
      this.indexingMode = indexingMode;
      this.handleReIndexNavItems();
    });
    this.indexingStore.showAdvancedMode$.pipe(takeUntil(this.destroy$)).subscribe(showAdvancedMode => {
      this.showAdvancedMode = showAdvancedMode;
    });
    this.initUploadModeEvents();
    this.selectedAdvancedSettings$ = this.indexingStore.selectedAdvancedSettings$.pipe(takeUntil(this.destroy$));
    this.indexingPreset$ = this.indexingStore.indexingPreset$.pipe(takeUntil(this.destroy$));
    this.indexingPreset$.subscribe(preset => {
      this.isAdvancedIndexingPresetSelected = isAdvancedIndexingPresetSelected(preset);
    });
    this.excludeRAI$ = this.indexingStore.excludeRAI$;
    this.dataService.isResponsiveView$.subscribe(isResponsiveView => {
      this.isMobileView = isResponsiveView;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(changes: ComponentChanges<IndexingFormComponent>) {
    if (changes.isDisabled) {
      this.handleDisabledNavItems(changes.isDisabled.currentValue);
    }
  }

  public toggleAdvancedSettings(): void {
    this.indexingStore.toggleAdvancedMode();
  }

  public onAdvancedSettingsClicked(): void {
    this.trackEvent('advanced_settings.toggled', { advanced: true });
    this.toggleAdvancedSettings();
    this.customizationDataStoreService.loadPersonModels();
    this.customizationDataStoreService.loadBrandModels();
    this.customizationDataStoreService.loadLogoGroups();
  }

  public onBackToBasicSettings(): void {
    this.trackEvent('advanced_settings.toggled', { advanced: false });
    this.indexingStore.updateSelectedAdvancedSettings(AdvancedSettings.General);
    this.toggleAdvancedSettings();
  }

  public onSelectedSettings(selectedSettings: AdvancedSettings) {
    if (this.isDisabled) {
      return;
    }
    this.indexingStore.updateSelectedAdvancedSettings(selectedSettings);
    this.trackEvent('indexing_section.init', { selectedSettings });
  }

  public onIndexingPresetChanged(preset: IndexingPreset) {
    this.indexingStore.updateIndexingPreset(preset);
  }

  public onExcludedRAIChange(excluded: boolean) {
    this.indexingStore.updateExcludedRAI(excluded);
  }

  public onExcludeAIsChanged(excluded: ExcludableAIsModels[]) {
    this.indexingStore.updateExcludeAIs(excluded);
  }

  public onManageModelsClicked(customizationPageToNavigate: CustomizationPage) {
    this.manageModelsClicked.emit(customizationPageToNavigate);
  }

  public onNavigateToCreateAccount() {
    this.navigateToCreateAccount.emit();
  }

  public onAddFilesClicked() {
    if (!this.isMobileView) {
      this.indexingStore.updateSelectedAdvancedSettings(AdvancedSettings.General);
      return;
    }

    // scroll to general settings section and make sure it opened
    this.isGeneralSettingsMobileOpened = true;
    document.getElementById(this.GENERAL_SETTINGS_MOBILE_ID).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources);
    for (const item in this.settingsItems) {
      this.settingsItems[item].text = resources[this.settingsItems[item].key];
    }

    for (const item in this.edgeSettingsItems) {
      this.edgeSettingsItems[item].text = resources[this.edgeSettingsItems[item].key];
    }

    for (const action in actions) {
      actions[action].title = resources[actions[action].key];
    }
  }

  private initUploadModeEvents() {
    this.indexingStore.isMultiFileMode$
      .pipe(takeUntil(this.destroy$), combineLatestWith(this.indexingStore.uploadMode$))
      .subscribe(([isMultiFileMode, uploadMode]) => {
        // Indicates if the user switched to multi file mode at least once, switching back to single mode will not change this value by design
        if (isMultiFileMode) {
          this.isMultiFileMode = true;
        }
        this.handleUploadNavItems(isMultiFileMode, uploadMode);
      });
  }

  private handleUploadNavItems(isMultiFileMode: boolean, uploadMode: UploadMode) {
    if (this.indexingMode === IndexingMode.ReIndex) {
      return;
    }

    this.settings = this.settings.map(item => {
      const updatedItem = { ...item };
      // Hide the file names settings item if the current file mode is single file
      if (updatedItem.id === AdvancedSettings.FileNames && uploadMode === UploadMode.File) {
        updatedItem.hidden = !this.isMultiFileMode;
      }
      // Change the text of the file info settings item according to the current file mode
      if (updatedItem.id === AdvancedSettings.FileInfo) {
        // save in property for mobile view
        this.fileInfoTitle = isMultiFileMode ? resources.IndexingBatchInfoSettings : resources.IndexingFileInfoSettings;
        updatedItem.text = this.fileInfoTitle;
      }
      return updatedItem;
    });
  }

  private handleReIndexNavItems() {
    if (this.indexingMode === IndexingMode.Upload) {
      return;
    }
    // for re index mode remove file items
    this.settings = this.settings.filter(item => item.id !== AdvancedSettings.FileNames);
    this.fileInfoTitle = resources.IndexingFileInfoSettings;
  }

  private handleDisabledNavItems(isDisabled: boolean) {
    this.settings = this.settings.map(item => {
      const updatedItem = { ...item };
      updatedItem.disable = isDisabled;
      return updatedItem;
    });
  }

  private trackEvent(eventName: string, properties: object) {
    if (this.indexingMode === IndexingMode.Upload) {
      this.trackService.track(`upload_dialog.${eventName}`, { data: properties, category: EventCategory.UPLOAD });
    } else {
      this.trackService.track(`re_index_dialog.${eventName}`, { data: properties, category: EventCategory.RE_INDEX });
    }
  }
}
