import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';

import { ISubMenuItem } from './interfaces';

@Component({
  selector: 'vi-side-sub-menu',
  templateUrl: './side-sub-menu.component.html',
  styleUrls: ['./side-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideSubMenuComponent<T> extends FocusableComponent {
  @Input() public items: ISubMenuItem<T>[];

  @Output() public itemSelected = new EventEmitter<ISubMenuItem<T>>();

  constructor() {
    super();
  }

  public handleSelectItem(item: ISubMenuItem<T>) {
    this.itemSelected.emit(item);
  }
}
