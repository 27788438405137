import { Injectable } from '@angular/core';

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { TrackService } from '@common/modules/core/services/track';

import { SupportedLocaleLanguages } from './../../../../apps/web/src/core/supportedLocaleLanguages';

@Injectable({
  providedIn: 'root'
})
export class CustomMissingTranslationHandler extends MissingTranslationHandler {
  private isHandled = false;

  constructor(private trackService: TrackService) {
    super();
  }

  // TO-Do: add a test for the function
  public handle(params: MissingTranslationHandlerParams) {
    // Check id current language is already loaded
    // (Added due to a ngx-translate's bug when loading the requested language it calls the handle missing translation)
    const isCurrentLangLoaded =
      params.translateService.getLangs() && params.translateService.getLangs().includes(params.translateService.currentLang);
    if (!this.isHandled && !isCurrentLangLoaded) {
      // In case there was an error loading the language json file, the default language is en and set in the translate-helper
      params.translateService.use(SupportedLocaleLanguages.en);

      // TO-DO: add a notification.
    }

    this.isHandled = true;

    if (isCurrentLangLoaded) {
      // @TODO: figure out why translate method is being called with 'en' as key
      if (params.key !== 'en') {
        this.trackService.trackError(new Error(`Missing translation for key: ${params.key}`), {
          data: { key: params.key, lang: params.translateService.currentLang }
        });
      }
    }
  }
}
