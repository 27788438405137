import { IHttpWrapper } from '../../interfaces';
import { getRequestSettings } from '../../utils/request.function';

import AddLanguageModel = Microsoft.VideoIndexer.Contracts.AddLanguageModel;

export class CustomizationLanguageModelsApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Language')]
  public getModels(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.LanguageModel[]>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Language')]
  public getModel(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.LanguageModel>(url, requestSettings);
  }

  // [HttpPost]
  // [Route('v2/Accounts/{accountId}/Customization/Language')]
  public addModel(accountId: string, reqParams: AddLanguageModel, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.LanguageModel>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}')]
  public updateModel(accountId: string, reqParams: AddLanguageModel, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.LanguageModel>(url, null, requestSettings);
  }

  // [HttpDelete]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}')]
  public deleteModel(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpPut]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}')]
  public uploadModelFile(accountId: string, modelId: string, reqBody: FormData, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.LanguageModel>(url, reqBody, requestSettings);
  }

  // [HttpDelete]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}')]
  public deleteModelFile(accountId: string, modelId: string, fileId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}/files/${fileId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}')]
  public downloadModelFile(accountId: string, modelId: string, fileId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}/files/${fileId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.TrainingDataFileContent>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Language//{modelId}')]
  public getModelFile(accountId: string, modelId: string, fileId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}/files/${fileId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.TrainingDataFile>(url, requestSettings);
  }

  // [HttpGet]
  // [Route('v2/Accounts/{accountId}/Customization/Language//{modelId}')]
  public getModelCrisEdits(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}/edits`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.TrainingDataFromEdits[]>(url, requestSettings);
  }

  // [HttpPut]
  // [Route('v2/Accounts/{accountId}/Customization/Language/{modelId}/train')]
  public trainModel(accountId: string, modelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/language/${modelId}/train`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.LanguageModel>(url, null, requestSettings);
  }
}
