/* eslint-disable max-len, no-control-regex */
export const GuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
export const VideoIdPattern = '(?<=\\/videos\\/|\\/projects\\/)[0-9a-zA-Z]{10,12}';
export const msCorpFullNameEmailPattern =
  /[[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+@microsoft.com/i;
export const msCropEmailPattern = /.+@microsoft.com/i;
export const JWTTokenPattern = 'ey([a-zA-Z0-9_=]{13,})\\.([a-zA-Z0-9_=]{15,})\\.([a-zA-Z0-9_\\-\\+\\/=]{15,})';
export const editableTimestampPattern = /([0-9]{2}):([0-9]{2}):([0-9]{2}),([0-9]{2})/g;
export const emailPattern =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/gi;
export const sasTokenPattern = /([?&]|%26)(sig=)([^&\\\'\"\s\)\]\}]*)/gi;
