export const resources = {
  LoginErrorPageMessageHeader: '',
  LoginErrorPageMessage: '',
  LoginErrorPageMessageLine2: '',
  VILoginErrorPageMessageLine3: '',
  VILoginErrorPageMessageLine3Label: '',
  LoginErrorPageMessageLine4: '',
  LoginErrorPageMessageLine5: '',
  VILoginError_USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER: '',
  VILoginError_USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER_Label: ''
};
