import { IPaintTimingResult, IPaintTimingOptions } from './interfaces';
import { toFixed } from '../../utils';
import { BaseTiming } from '../base';

export { IPaintTimingOptions, IPaintTimingResult };
export class PaintTiming extends BaseTiming {
  constructor(private options?: IPaintTimingOptions) {
    super();
  }

  public getTimings(): Partial<IPaintTimingResult> {
    const timings = this.performance.getEntriesByName('first-paint')[0];

    if (!timings) {
      return {};
    }

    return {
      firstPaintTime: toFixed(timings?.startTime)
    };
  }
}
