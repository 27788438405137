import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { VIDEO_INDEXER_PRICING_LINK, GET_STARTED_WITH_AZURE_FREE } from '@common/modules/shared/links';

import { LoginService } from '../../../../login/services/login.service';
import { LoginProvider } from '../../../../login/pages/login/interfaces';
import { LogoutService } from '../../../../logout/services/logout.service';

@Component({
  selector: 'app-vi-create-new-account',
  templateUrl: './create-new-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./create-new-account.component.scss']
})
export class CreateNewAccountComponent implements OnInit {
  public VIDEO_INDEXER_PRICING_LINK = VIDEO_INDEXER_PRICING_LINK;
  public GET_STARTED_WITH_AZURE_FREE = GET_STARTED_WITH_AZURE_FREE;

  @Input() public resources;
  constructor(private loginService: LoginService, private logoutService: LogoutService) {}

  public ngOnInit(): void {}

  public signInWithAAD() {
    this.logoutService.exitAccount();
    this.loginService.signIn(LoginProvider.OpenIdConnect);
  }
}
