import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType, UIActionIcon } from '@common/modules/insights/interfaces';

import { UILanguageModelActionType } from '../../interfaces';

export const mainAction: IAction = {
  icon: 'i-vi-more',
  titleAttribute: 'Brand model menu',
  key: 'BrandCustomizationModelMenu',
  title: '',
  selected: false,
  type: UIActionType.EDIT,
  value: UIActionType.EDIT,
  selectable: true,
  hasDrop: true,
  id: 'brandCustomizationModelMenu'
};

export const EditBrandModelAction: IAction = {
  icon: UIActionIcon.EDIT,
  title: 'Edit',
  key: 'Edit',
  selected: false,
  type: UIActionType.UPDATE,
  value: UILanguageModelActionType.RENAME_MODEL,
  selectable: true,
  hasDrop: false,
  id: 'EDIT_BRAND_MODEL'
};

export const DeleteBrandModelAction: IAction = {
  icon: UIActionIcon.TRASH,
  title: 'Delete',
  key: 'Delete',
  selected: false,
  type: UIActionType.DELETE,
  value: UILanguageModelActionType.DELETE_MODEL,
  selectable: true,
  hasDrop: false,
  id: 'DELETE_BRAND_MODEL'
};

export const brandsActions: IAction[] = [EditBrandModelAction, DeleteBrandModelAction];

export const brandAddAction: IAction = {
  icon: UIActionIcon.ADD,
  title: 'Create new brand',
  key: 'brandAddAction',
  selected: false,
  type: UIActionType.ADD,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'brandAddBtn',
  style: 'add-button'
};

export const brandUpdateAction: IAction = {
  title: 'Update',
  key: 'brandUpdateAction',
  selected: false,
  type: UIActionType.UPDATE,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'brandUpdateBtn',
  style: 'update-button'
};

export const brandSaveAction: IAction = {
  title: 'Save',
  key: 'brandSaveAction',
  selected: false,
  type: UIActionType.SAVE,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'brandSaveBtn',
  style: 'save-button'
};

export const brandCancelAction: IAction = {
  title: 'Cancel',
  key: 'brandCancelAction',
  selected: false,
  type: UIActionType.CANCEL,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'brandCancelBtn',
  style: 'cancel-button'
};
