import { IVIRoutesMap } from './interfaces';

export const VIRoutingMap: IVIRoutesMap = {
  // Sign in
  login: {
    name: 'Login',
    traceName: 'login',
    path: 'account/login'
  },
  logout: {
    name: 'Logout',
    traceName: 'logout',
    path: 'account/logout'
  },
  signout: {
    name: 'Sign out',
    traceName: 'signout',
    path: 'account/signout'
  },
  signin: {
    name: 'Signin',
    traceName: 'signin',
    path: 'account/signin'
  },
  // Error
  pageNotFoundError: {
    name: 'Page not found',
    traceName: '404',
    path: 'app/error/404'
  },
  loginError: {
    name: 'Login Error',
    traceName: 'loginError',
    path: 'app/error/api/loginError'
  },
  userAlreadyExistsError: {
    name: 'User already exists',
    traceName: 'userAlreadyExists',
    path: 'app/error/api/userAlreadyExists'
  },
  // Gallery
  mediaGallery: {
    name: 'Media gallery',
    traceName: 'gallery',
    path: 'media'
  },
  galleryLibrary: {
    name: 'Gallery Library',
    traceName: 'gallery.library',
    path: 'library'
  },
  galleryProjects: {
    name: 'Gallery projects',
    traceName: 'gallery.projects',
    path: 'projects'
  },
  gallerySamples: {
    name: 'Gallery samples',
    traceName: 'gallery.samples',
    path: 'samples'
  },
  galleryHomeFullRoute: {
    name: 'Gallery home full route',
    traceName: 'gallery.library',
    path: 'media/library'
  },
  // Media
  mediaVideo: {
    name: 'Media video',
    traceName: 'media.video',
    path: 'accounts/:accountId/videos/:videoId'
  },
  mediaProject: {
    name: 'Media project',
    traceName: 'media.project',
    path: 'accounts/:accountId/projects/:videoId'
  },
  edgeExtensionVideo: {
    name: 'Edge Extension video',
    traceName: 'edge_extension.video',
    path: 'accounts/:accountId/extensions/:extensionId/videos/:videoId'
  },
  // Settings
  settings: {
    name: 'Account settings',
    traceName: 'account.settings',
    path: 'account/:accountId/settings'
  },
  connections: {
    name: 'Connections',
    traceName: 'connections',
    path: 'connections'
  },
  // Editor
  editorVideo: {
    name: 'Editor video',
    traceName: 'editor.video',
    path: 'editor/:accountId/videos/:videoId'
  },
  editorProject: {
    name: 'Editor project',
    traceName: 'editor.project',
    path: 'editor/:accountId/projects/:videoId'
  },
  editorNewProject: {
    name: 'Editor new project',
    traceName: 'editor.newProject',
    path: 'editor/:accountId'
  },
  // Customization
  customization: {
    name: 'Customization',
    traceName: 'customization',
    path: 'customization/:accountId'
  },
  customizationPeople: {
    name: 'Customization people',
    traceName: 'customization.people',
    path: 'people'
  },
  customizationPeopleUnknown: {
    name: 'Customization people unknown',
    traceName: 'customization.unknown_people',
    path: 'unknown'
  },
  customizationLanguage: {
    name: 'Customization language',
    traceName: 'customization.language',
    path: 'language'
  },
  customizationBrands: {
    name: 'Customization brands',
    traceName: 'customization.brands',
    path: 'brands'
  },
  customizationSpeech: {
    name: 'Customization speech',
    traceName: 'customization.speech',
    path: 'speech'
  },
  customizationSpeechDatasets: {
    name: 'Customization speech datasets',
    traceName: 'customization.speech.datasets',
    path: 'datasets'
  },
  customizationSpeechModels: {
    name: 'Customization speech models',
    traceName: 'customization.speech.models',
    path: 'models'
  },
  customizationLanguageId: {
    name: 'Customization language id',
    traceName: 'customization.languageId',
    path: 'languageId'
  },
  // Migration
  amsMigrationReport: {
    name: 'Migration report',
    traceName: 'migration_report',
    path: 'migration-report/:accountId'
  }
};
