export const resources = {
  AccountsIDLabel: '',
  VIHeaderCreatePro: '',
  AccountSettingsTitle: '',
  SidebarShareAccount: '',
  AccountListSelectedAccountInfoLabel: '',
  AccountListLabel: '',
  Classic: '',
  ArmAccountsLoading: '',
  ArmAccountsErrorLoading: '',
  AccessTitle: '',
  ViewOnly: '',
  NoResultsFound: '',
  FindAccount: '',
  ID: '',
  SelectedAccount: '',
  Region: '',
  SettingsAccountIDCopyLabel: '',
  SettingsCopyAccountIDSuccessNotification: '',
  Hybrid: '',
  HeaderTrial: ''
};
