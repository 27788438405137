import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { FeatureSwitch, IFeatureSwitches } from '../interfaces';
import { UrlService } from '../../../core/services/url/url.service';
import { ConfigService } from '../config/config.service';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureSwitchService {
  private featureSwitches: IFeatureSwitches;
  private readonly featureToggleQueryParamPrefix = 'feature';

  constructor(private urlUtility: UrlService, private configService: ConfigService, private logger: LoggerService) {
    this.loadFeatureSwitches();
  }

  public featureSwitch(name: string) {
    return !!this.featureSwitches[name];
  }

  // if we want to enable query param switch need to call this function in the ctor
  public checkFeatureSwitchesQueryParams() {
    if (!this.featureSwitches) {
      this.featureSwitches = {};
    }
    const featureSwitchesKeys = Object.values(FeatureSwitch);
    for (const key of featureSwitchesKeys) {
      const featureSwitchParam = this.urlUtility.getQueryParam(`${this.featureToggleQueryParamPrefix}.${key}`);
      if (featureSwitchParam) {
        this.featureSwitches[key] = featureSwitchParam === 'true';
      }
    }
  }

  private loadFeatureSwitches(): void {
    this.featureSwitches = cloneDeep(this.configService.featureSwitches);
    // Get url params and override feature switches
    this.checkFeatureSwitchesQueryParams();
    this.logger.log('[FeatureSwitchService] FeatureSwitches', this.featureSwitches);
  }
}
