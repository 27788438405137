import { createSelector } from '@ngrx/store';

import { SpeechObjectState } from '../../interfaces';
import { selectCoreState } from '../../reducers';

export const getSpeechState = createSelector(selectCoreState, state => state.speech);
export const getSpeechModelsState = createSelector(getSpeechState, state => state.models);
export const isSpeechModelError = createSelector(getSpeechModelsState, state => state.error);
export const getSpeechModels = createSelector(getSpeechModelsState, state => Object.values(state.entities));
export const getTrainedSpeechModels = createSelector(getSpeechModels, speechModels =>
  speechModels.filter(s => s.status === SpeechObjectState.COMPLETE)
);
export const getSpeechModelId = (id: string) => createSelector(getSpeechModelsState, state => state.entities[id]);
export const isSpeechModelsLoaded = createSelector(getSpeechModelsState, state => state.loaded);
export const isSpeechModelUpdating = createSelector(getSpeechModelsState, state => state.updating);
export const isSpeechModelTrainDialogOpened = createSelector(getSpeechModelsState, state => state.isTrainDialogOpened);
export const getSelectedSpeechModel = createSelector(getSpeechModelsState, state => state.entities[state.selectedModelId]);
