import { Injectable } from '@angular/core';
import { NavigationExtras, Params } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable, combineLatest, filter, map, take } from 'rxjs';

import { UtilsService } from '@common/modules/shared/services/utils.service';
import {
  CustomizationPage,
  FeatureSwitch,
  IUIAccount,
  IUserSettings,
  NavigationState,
  SideMenuOption
} from '@common/modules/core/services/interfaces';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { ISidebarNavigationOptions, SidebarPages } from '../../sidebar/interfaces';
import { SettingsTab } from '../../settings/pages/settings/interfaces';
import { IState } from '../reducers';
import * as actions from '../actions';
import * as fromRouter from '../reducers/router';
import * as fromCore from '../selectors';
import { AMSAssetsMigrationStatus } from '../interfaces';

@Injectable()
export class CoreStoreService {
  constructor(
    private readonly store: Store<IState>,
    private readonly routerStore: Store<fromRouter.IState>,
    private readonly utilsService: UtilsService,
    private readonly featureSwitchService: FeatureSwitchService
  ) {}

  // Core selectors

  public get roleByUser$(): Observable<Microsoft.VideoIndexer.Contracts.UserRole> {
    return this.store.select(fromCore.getRoleByUser);
  }

  public get coreMetadataLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.isCoreMetadataLoaded);
  }

  public get isFullMetadataLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.isFullMetadataLoaded);
  }

  public get userDetailsLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.isUserDetailsLoaded);
  }

  public get isZeroAccounts$(): Observable<boolean> {
    return this.store.select(fromCore.isZeroAccounts);
  }

  // User selectors

  public get userDetails$(): Observable<Microsoft.VideoIndexer.Contracts.UserContractSlim> {
    return this.store.select(fromCore.getUserDetails);
  }

  public get allUserDetails$(): Observable<Microsoft.VideoIndexer.Contracts.AllUserDetails> {
    return this.store.select(fromCore.getAllUserDetails);
  }

  public get userSettings$(): Observable<IUserSettings> {
    return this.store.select(fromCore.getUserSettings);
  }

  public get userDetailsError$(): Observable<boolean> {
    return this.store.select(fromCore.loadUserDetailsError);
  }

  public get showSamplesTab$(): Observable<boolean> {
    return this.store.select(fromCore.showSamplesTab);
  }

  public get showGalleryByDefault$(): Observable<boolean> {
    return this.store.select(fromCore.showGalleryByDefault);
  }

  public get getUserEmail$(): Observable<string> {
    return this.store.select(fromCore.getUserEmail);
  }

  public get getSignedInUserEmail$(): Observable<string> {
    return this.store.select(fromCore.getSignedInUserEmail);
  }

  public get autoCompleteEnabled$(): Observable<boolean> {
    return this.store.select(fromCore.autoCompleteEnabled);
  }

  public get getAccountTokenPermission$(): Observable<AccountPermission> {
    return this.store.select(fromCore.selectAccountTokenPermission);
  }

  public saveUserSettings(userSettings: IUserSettings) {
    this.store.dispatch(actions.updateUserApi({ user: userSettings }));
  }

  public sessionExpired() {
    this.store.dispatch(actions.sessionExpired());
  }

  public loadChunksError() {
    this.store.dispatch(actions.loadChunksError());
  }

  // Account selectors

  public get accounts$(): Observable<Microsoft.VideoIndexer.Contracts.AccountContractSlim> {
    return this.store.select(fromCore.selectCurrentAccount);
  }

  public get selectAccountsCount$(): Observable<number> {
    return this.store.select(fromCore.selectAccountsCount);
  }

  public get UIAccounts$(): Observable<IUIAccount[]> {
    return this.store.select(fromCore.selectUIAccounts);
  }

  public get selectedAccountId$(): Observable<string> {
    return this.store.select(fromCore.selectCurrentAccountId);
  }

  public get selectedAccount$(): Observable<Microsoft.VideoIndexer.Contracts.AccountContractSlim> {
    return this.store.select(fromCore.selectCurrentAccount);
  }

  public get selectAccountIds$(): Observable<string[]> {
    return this.store.select(fromCore.selectAccountIds);
  }

  public get selectedUIAccount$(): Observable<IUIAccount> {
    return this.store.select(fromCore.selectedUIAccount);
  }

  public get armAccounts$(): Observable<IUIAccount[]> {
    return this.store.select(fromCore.selectArmAccounts);
  }

  public get selectedAccountContract$(): Observable<Microsoft.VideoIndexer.Contracts.AccountContract> {
    return this.store.select(fromCore.selectedAccountContract);
  }

  public get selectedAccountLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.selectedAccountLoaded);
  }

  public get selectedAccountStatistics$(): Observable<Microsoft.VideoIndexer.Contracts.AccountStatistics> {
    return this.store.select(fromCore.getSelectedAccountStatistics);
  }

  public get getSelectedAccountQuota$(): Observable<Microsoft.VideoIndexer.Contracts.AccountQuotaUsage> {
    return this.store.select(fromCore.getSelectedAccountQuota);
  }

  public get selectIncludeCelebSettings$(): Observable<boolean> {
    return this.store.select(fromCore.getIncludeCelebSettings);
  }

  public get selectedAccountLocation$(): Observable<string> {
    return this.store.select(fromCore.getSelectedAccountLocation);
  }

  public get selectAccountType$(): Observable<string> {
    return this.store.select(fromCore.selectAccountType);
  }

  public get selectAccountError$(): Observable<boolean> {
    return this.store.select(fromCore.accountError);
  }

  public get isNetworkAccessNotAllowedError$(): Observable<boolean> {
    return this.store.select(fromCore.isNetworkAccessNotAllowedError);
  }

  public get isAccountLimitedWithFaces$(): Observable<boolean> {
    return this.store.select(fromCore.isAccountLimitedWithFaces);
  }

  public get showFaceGateWarning$(): Observable<boolean> {
    return this.store.select(fromCore.showFaceGateWarning);
  }

  public get isAccountInHighRiskRegion$(): Observable<boolean> {
    return this.store.select(fromCore.isAccountInHighRiskRegion);
  }

  public get isAmslessAccount$(): Observable<boolean> {
    return combineLatest([this.isArmAmslessAccount$, this.isTrialAccount$]).pipe(
      map(
        ([isArmAmslessAccount, isTrialAccount]) =>
          isArmAmslessAccount || (isTrialAccount && this.featureSwitchService.featureSwitch(FeatureSwitch.Amsless))
      )
    );
  }

  public get isArmAmslessAccount$(): Observable<boolean> {
    return this.store.select(fromCore.isArmAmslessAccount);
  }

  public get isAccountConnectedToOpenAI$(): Observable<boolean> {
    return this.store.select(fromCore.isConnectedToOpenAI);
  }

  public get isProjectsForAmslessEnabled$() {
    return combineLatest([this.isArmAmslessMigrated$, this.isTrialAccount$]).pipe(
      map(([isArmAmslessMigrated, isTrialAccount]) => {
        return (isArmAmslessMigrated || isTrialAccount) && this.featureSwitchService.featureSwitch(FeatureSwitch.ProjectsForAMSless);
      })
    );
  }

  public get isArmAmslessMigrated$() {
    return combineLatest([this.isArmAmslessAccount$, this.store.select(fromCore.getAmsMigrationStatus)]).pipe(
      map(([isArmAmslessAccount, amsMigrationStatus]) => {
        const noNeedMigration =
          amsMigrationStatus?.status === AMSAssetsMigrationStatus.FAILED ||
          amsMigrationStatus?.status === AMSAssetsMigrationStatus.COMPLETED ||
          amsMigrationStatus?.status === AMSAssetsMigrationStatus.NOT_APPLICABLE;
        return isArmAmslessAccount && noNeedMigration;
      })
    );
  }

  public get isUSAccount$(): Observable<boolean> {
    return this.store.select(fromCore.isUSAAccount);
  }

  public get isTrialAccount$(): Observable<boolean> {
    return this.store.select(fromCore.isTrialAccount);
  }

  public selectAccount(id: string, forceLoad = false, doNotClearSelectedExtension = false): void {
    this.store.dispatch(actions.selectAccount({ id, forceLoad, doNotClearSelectedExtension }));
  }

  public addAccount(newAccount: Microsoft.VideoIndexer.Contracts.AccountContract): void {
    this.store.dispatch(actions.addAccount({ account: newAccount }));
  }

  public armAccountsFromLocation(location: string): Observable<IUIAccount[]> {
    return this.store.select(fromCore.selectArmAccountsFromLocation(location));
  }

  public filteredUIAccounts$(): Observable<IUIAccount[]> {
    return this.store.select(fromCore.selectFilteredUIAccounts());
  }

  public setAccountsFilterValue(value: string): void {
    this.store.dispatch(actions.setAccountsFilterValue({ value }));
  }

  public acceptUseOfFacialIdentification(signature: string, useCase: string): void {
    this.store.dispatch(actions.acceptUseOfFacialIdentification({ signature, useCase }));
  }

  // ARM Accounts Selectors

  public get armAccountsLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.armAccountsLoaded);
  }

  public get armAccountsError$(): Observable<boolean> {
    return this.store.select(fromCore.armAccountsError);
  }

  // Directories Selectors
  public get UIDirectories$(): Observable<Microsoft.VideoIndexer.Contracts.DirectoryContract[]> {
    return this.store.select(fromCore.selectUIDirectories);
  }

  public get selectedDirectory$(): Observable<Microsoft.VideoIndexer.Contracts.DirectoryContract> {
    return this.store.select(fromCore.selectedDirectory);
  }

  public get currentTenantId$(): Observable<string> {
    return this.store.select(fromCore.currentTenantId);
  }

  public get directoriesLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.selectDirectoriesLoaded);
  }

  public get directoriesError$(): Observable<boolean> {
    return this.store.select(fromCore.selectDirectoriesError);
  }

  public loadDirectories(): void {
    this.store.dispatch(actions.loadDirectories());
  }

  public selectDirectory(tenantId: string): void {
    this.store.dispatch(actions.selectDirectory({ tenantId }));
  }

  // Invite Data

  public get selectInvitationsListLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.selectInvitationsListLoaded);
  }

  public get selectAllInvitationsEmails$(): Observable<string[]> {
    return this.store.select(fromCore.selectAllInvitationsEmails);
  }

  public get selectUIInvitations$() {
    return this.store.select(fromCore.selectUIInvitations);
  }

  public get selectUIRolesWithoutUser$() {
    return this.store.select(fromCore.selectUIRolesWithoutUser);
  }

  public get selectInviteError$() {
    return this.store.select(fromCore.selectInviteError);
  }

  public get selectInviteIsNotAllowedError$() {
    return this.store.select(fromCore.selectInviteIsNotAllowedError);
  }

  public get selectInviteSaving$() {
    return this.store.select(fromCore.selectInviteSaving);
  }

  public inviteToAccount(formDetails: Microsoft.VideoIndexer.Contracts.InviteContract[]): void {
    this.store.dispatch(actions.inviteToAccount({ formDetails }));
  }

  public deleteInvitation(invitationId: number): void {
    this.store.dispatch(actions.deleteInvitation({ invitationId }));
  }

  public copyInvitationLink(invitationId: number): void {
    this.store.dispatch(actions.copyInvitationLink({ invitationId }));
  }

  public deleteRoleAssignment(roleAssignmentId: number): void {
    this.store.dispatch(actions.deleteRoleAssignment({ roleAssignmentId }));
  }

  public updateRoleAssignments(roleAssignmentId: number, role: Microsoft.VideoIndexer.Contracts.UserRole): void {
    this.store.dispatch(actions.updateRoleAssignments({ roleAssignmentId, role }));
  }

  public updateAccountCelebSettings(includeCelebrityRecognition: boolean) {
    this.store.dispatch(actions.updateAccountCelebSettings({ includeCelebrityRecognition }));
  }

  // Sidebar selectors

  public get sidebarStateStatus$(): Observable<NavigationState> {
    return this.store.select(fromCore.getSidebarStateStatus);
  }

  public get sidebarPage$(): Observable<SidebarPages> {
    return this.store.select(fromCore.getSidebarPage);
  }

  public get sidebarCallbackAfterClose$(): Observable<() => void> {
    return this.store.select(fromCore.getSidebarCallbackAfterClose);
  }

  public setSidebar(sidebarNavigationOptions: ISidebarNavigationOptions): void {
    this.store.dispatch(actions.selectSidebarOptions({ options: sidebarNavigationOptions }));
  }

  public clearSidebar(): void {
    this.store.dispatch(actions.clearSidebar());
    this.store.dispatch(actions.clearAccountsFilterValue());
  }

  public clearAccountsFilterValue(): void {
    this.store.dispatch(actions.clearAccountsFilterValue());
  }

  // Router selectors

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public navigate(path: any[], queryParams?: object, extras?: NavigationExtras, removeParams?: boolean) {
    this.store.dispatch(
      actions.Go({
        path,
        queryParams,
        extras,
        removeParams
      })
    );
  }

  public get routerParams$(): Observable<Params> {
    return this.routerStore.select(fromRouter.getRouteParams);
  }

  public get routerQueryParams$(): Observable<Params> {
    return this.routerStore.select(fromRouter.getQueryParams);
  }

  public get isGalleryRoute$(): Observable<boolean> {
    return this.routerStore.select(fromRouter.isGalleryRoute);
  }

  // Navigation
  public navigateToSettings(tab?: SettingsTab) {
    this.store.dispatch(actions.navigateToSettings({ tab }));
  }

  public navigateToCustomization(page = CustomizationPage.Language) {
    this.store.dispatch(actions.navigateToCustomization({ page }));
  }

  public navigateToCamera(cameraId: string) {
    this.store.dispatch(actions.navigateToCamera({ cameraId }));
  }

  public navigateToWorkflow(workflowId: string) {
    this.store.dispatch(actions.navigateToWorkflow({ workflowId }));
  }

  public openShareAccount(showInviteForm: boolean) {
    this.store.dispatch(actions.openShareAccount({ showInviteForm }));
  }

  public navigateToViResourceOverviewPortalUrl() {
    this.selectedAccountContract$
      .pipe(
        filter(account => !!account),
        take(1)
      )
      .subscribe(account => {
        const url = this.utilsService.getViResourceOverviewPortalUrl(account.tenantId, account.resourceId);
        window.open(url, '_blank');
      });
  }

  // Side menu

  public navigateFromSideMenu(option?: SideMenuOption) {
    this.store.dispatch(actions.sideMenuOptionSelected({ option }));
  }

  public sideMenuToggled(open: boolean) {
    this.store.dispatch(actions.sideMenuToggled({ open }));
  }

  public selectSideMenuOpen() {
    return this.store.select(fromCore.getSideMenuOpen);
  }

  public selectSideMenuSelectedOption() {
    return this.store.select(fromCore.getSideMenuSelectedOption);
  }

  public get isExtensionsSubMenuOpened$() {
    return this.store.select(fromCore.isExtensionsSubMenuOpened);
  }

  // customization view

  public getSelectedCustomizationPage(): Observable<CustomizationPage> {
    return this.store.select(fromCore.selectedCustomizationPage);
  }
}
