import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { AccessibilityUtilsService } from './accessibility-utils.service';

@Directive({
  selector: '[appAccessibilityContentOnHoverHandler]'
})
export class ContentOnHoverHandlerDirective {
  @Input() public appAccessibilityContentOnHoverHandler;
  @Output() public contentOnHover: EventEmitter<boolean> = new EventEmitter();

  constructor(private elRef: ElementRef, private accessibilityUtils: AccessibilityUtilsService) {}

  @HostListener('mouseover', ['$event']) public onMouseOver(event: MouseEvent) {
    this.contentOnHover.next(true);
  }

  @HostListener('mouseout', ['$event']) public onMouseOut(event: MouseEvent) {
    const relatedTarget = event.relatedTarget;

    // Check if target is child of element
    if (relatedTarget != null) {
      const parents = this.accessibilityUtils.getParents(relatedTarget);

      // if hover out-
      if (parents.indexOf(this.elRef.nativeElement) === -1) {
        // toggle sub window
        this.contentOnHover.next(false);
      }
    }
  }
}
