import { IResourceTimingOptions, IResourceTimingResult } from './interfaces';
import { toFixed, normalizeEntryName } from '../../utils';
import { BaseTiming } from '../base';

export { IResourceTimingOptions, IResourceTimingResult };
export class ResourceTiming extends BaseTiming {
  constructor(private options: IResourceTimingOptions = {}) {
    super();
  }

  public getTimings(): IResourceTimingResult {
    const { domains } = this.options;
    const entries = this.performance.getEntriesByType('resource');

    let regex = '(js|css)$';
    let compiledRegex = /(js|css)$/i;

    if (domains) {
      regex = `(${domains.join('|')}).*${regex}`;
      compiledRegex = new RegExp(regex, 'i');
    }

    return entries
      .filter(entry => compiledRegex.test(entry.name))
      .reduce(
        (acc, entry) => ({
          ...acc,
          [normalizeEntryName(entry.name)]: toFixed(entry.duration)
        }),
        {}
      );
  }
}
