import { NgModule, Optional, SkipSelf, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { ConfigService } from './services/config/config.service';
import { FeatureSwitchService } from './services/feature-switch/feature-switch.service';
import { LoggerService } from './services/logger/logger.service';
import { MonitoringErrorHandlerService } from './services/monitoring-error-handler/monitoring-error-handler.service';
import { PageTimingService } from './services/page-timing/service/page-timing.service';
import { ThemesService } from './services/themes/themes.service';
import { ToastService } from './services/toast/toast.service';
import { TrackService } from './services/track';
import { VIMainSidebarService } from './services/vi-main-sidebar/vi-sidebar.service';
import { WINDOW } from './services/window.token';
import { ZoneWrapperService } from './services/zone-wrapper/zone-wrapper.service';
import { environment } from '../../../environments/environment';
import { AccessibilityModule } from '../accessibility/accessibility.module';
import { ApiModule } from '../api/api.module';
import { TranslateHelperService } from '../translation/services/translate-helper.service';
import { TranslationModule } from '../translation/translation.module';
import { CacheStorageService } from './services/cache-storage/cache-storage.service';
import { SanitizerService } from './services/sanitizer/sanitizer.service';

// Init translate before loading the app
export function appInitializerFactory(translate: TranslateHelperService) {
  return () => translate.init();
}

@NgModule({
  imports: [ToastrModule.forRoot(), BrowserAnimationsModule],
  exports: [TranslationModule, ApiModule, AccessibilityModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateHelperService],
      multi: true
    },
    {
      provide: WINDOW,
      useValue: window
    },
    LoggerService,
    SanitizerService,
    TrackService,
    ToastService,
    { provide: 'ENABLE_LOGGER', useValue: environment.logger },
    ConfigService,
    {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandlerService
    },
    FeatureSwitchService,
    VIMainSidebarService,
    ThemesService,
    ZoneWrapperService,
    PageTimingService,
    CacheStorageService
  ],
  declarations: []
})
export class CommonCoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CommonCoreModule, private logger: LoggerService) {
    super(parentModule);
    this.logger.log('[CommonCoreModule] init');
  }
}
