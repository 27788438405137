export const resources = {
  Metadata: '',
  Save: '',
  Cancel: '',
  Delete: '',
  SettingsSave: '',
  AddMetadata: '',
  GridLoadMoreButton: '',
  FacesCustomizationGoToVideoAction: '',
  UnknownPersonDialogMoveToModel: '',
  UnknownPersonDialogSpinnerTitle: '',
  UnknownPersonDialogFacesTitle: '',
  UnknownPersonDialogPersonNameTitle: '',
  FacesCustomizationOpenFaceAction: '',
  UnknownPersonDialogNamePerson: '',
  UnknownPersonDialogNamePersonDescription: ''
};
