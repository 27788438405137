import { createReducer, Action, on } from '@ngrx/store';

import { GalleryTab, GalleryView } from '../../interfaces';
import * as ViewActions from '../actions/gallery-view.actions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State {
  selectedTab: GalleryTab;
  view: GalleryView;
}

const initialState: State = {
  selectedTab: GalleryTab.Library,
  view: getGalleryView()
};

function getGalleryView() {
  let viewStorage: string;
  try {
    viewStorage = window.localStorage?.getItem('GalleryView');
  } catch (error) {
    viewStorage = '';
  }
  return viewStorage === 'List' ? GalleryView.List : GalleryView.Grid;
}

const viewReducer = createReducer(
  initialState,
  on(ViewActions.selectTab, (state, { tab }) => {
    return {
      ...state,
      selectedTab: tab
    };
  }),
  on(ViewActions.selectView, (state, { view }) => {
    return {
      ...state,
      view: view
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return viewReducer(state, action);
}
