import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, withLatestFrom, catchError, EMPTY } from 'rxjs';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ApiService } from '@common/modules/api/services/api.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { ExcludeAIsHelperService } from './../../services/exclude-ais-helper.service';
import { IndexingMode } from '../../interfaces';
import { IState } from '../reducers/index';
import * as fromCore from '../../../core/selectors';
import * as actions from '../actions';
import * as fromIndexing from '../selectors';
import * as fromCustomizationData from '../../../customization-data/selectors';

@Injectable()
export class IndexingFormEffects {
  public loadSupportedAIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadSupportedAIs),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingPreset)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.isAccountLimitedWithFaces)),
      withLatestFrom(this.store.select(fromIndexing.selectExcludeAIs)),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingMode)),
      withLatestFrom(this.store.select(fromIndexing.isUploadInProgress)),
      switchMap(([[[[[[, preset], accountId], isAccountFaceGated], excludeAIs], indexingMode], isUploadInProgress]) => {
        if (!this.isExcludeAIsEnabled || (indexingMode === IndexingMode.Upload && isUploadInProgress)) {
          return EMPTY;
        }

        return this.apiService.Account.getAccountSupportedAIs(accountId, { indexingPreset: preset }).pipe(
          switchMap((supportedAIs: Microsoft.VideoIndexer.Contracts.SupportedAIsContract) => {
            this.trackService.track('indexing_form_effect.load_supported_ais.success', { category: EventCategory.INDEXING_FORM });

            const supportedAIsMap = this.excludeAIsHelperService.getSupportedAIsMap(supportedAIs.aIs, preset, isAccountFaceGated, excludeAIs);

            // reset the exclude AIs list if the preset is changed.
            // add only the face gated AIs (if the account is not face gated, the face gated AIs list will be empty)
            this.store.dispatch(
              actions.updateIndexingSettings({
                settings: { excludeAIs: [...excludeAIs, ...this.excludeAIsHelperService.faceGatedAIs] },
                isDirty: false
              })
            );

            return [actions.updateSupportedAIs({ supportedAIs: supportedAIsMap })];
          }),
          catchError(error => {
            this.trackService.track('indexing_form_effect.load_supported_ais.failed', { category: EventCategory.INDEXING_FORM, data: error });
            return [actions.loadSupportedAIsFailed()];
          })
        );
      })
    )
  );

  public updateSupportedAIsForIndexing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateSupportedAIs),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingMode)),
      switchMap(([{ supportedAIs }, mode]) => {
        if (mode === IndexingMode.Upload) {
          return [actions.updateUploadSupportedAIs({ supportedAIs })];
        } else if (mode === IndexingMode.ReIndex) {
          return [actions.updateReIndexSupportedAIs({ supportedAIs })];
        }
      })
    )
  );

  public updateSupportedAIsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateSupportedAIsState),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingMode)),
      switchMap(([{ supportedAIs }, mode]) => {
        if (mode === IndexingMode.Upload) {
          return [actions.updateUploadSupportedAIsState({ supportedAIs })];
        } else if (mode === IndexingMode.ReIndex) {
          return [actions.updateReIndexSupportedAIsState({ supportedAIs })];
        }
      })
    )
  );

  public updateIndexingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateIndexingSettings),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingMode)),
      withLatestFrom(this.store.select(fromIndexing.selectLogoGroupId)),
      withLatestFrom(this.store.select(fromCustomizationData.selectLogoGroups)),
      withLatestFrom(this.store.select(fromCustomizationData.isLogoGroupsLoaded)),
      switchMap(([[[[{ settings }, mode], selectLogoGroupId], logoGroups], isLogoGroupsLoaded]) => {
        const actionsList = [];
        if (mode === IndexingMode.Upload) {
          this.trackService.track('upload_dialog.indexing_form_effect.set_indexing_data', { category: EventCategory.UPLOAD, data: settings });
          actionsList.push(actions.updateUploadIndexingSettings({ settings }));
        } else if (mode === IndexingMode.ReIndex) {
          this.trackService.track('re_index_dialog.indexing_form_effect.set_indexing_data', { category: EventCategory.RE_INDEX, data: settings });
          actionsList.push(actions.updateReIndexIndexingSettings({ settings }));
        }

        if (isLogoGroupsLoaded && logoGroups?.length && !selectLogoGroupId) {
          actionsList.push(actions.updateIndexingSettings({ settings: { logoGroupId: logoGroups[0]?.id } }));
        }

        return actionsList;
      })
    )
  );

  private isExcludeAIsEnabled: boolean = false;
  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private store: Store<IState>,
    private trackService: TrackService,
    private apiService: ApiService,
    private featureSwitchService: FeatureSwitchService,
    private excludeAIsHelperService: ExcludeAIsHelperService
  ) {
    this.logger.log('[IndexingFormEffects] On Init Effects');
    this.isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);
  }
}
