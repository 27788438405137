import { combineReducers } from '@ngrx/store';

import { datasetInitialState, SpeechDatasetState } from './speech-dataset.reducer';
import { modelsInitialState, SpeechModelsState } from './speech-model.reducer';
import { speechPageInitialState } from './speech-page.reducer';
import * as fromSpeechModels from './speech-model.reducer';
import * as fromSpeechDataset from './speech-dataset.reducer';
import * as fromSpeechPage from './speech-page.reducer';

export interface IState {
  page: fromSpeechPage.IState;
  datasets: SpeechDatasetState;
  models: SpeechModelsState;
}

export const initialState: IState = {
  // additional entity state properties
  page: speechPageInitialState,
  datasets: datasetInitialState,
  models: modelsInitialState
};

export const reducer = combineReducers({
  page: fromSpeechPage.reducer,
  models: fromSpeechModels.reducer,
  datasets: fromSpeechDataset.reducer
});
