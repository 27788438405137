import { HttpErrorResponse } from '@angular/common/http';

import {
  isAccountDailyCountQuotaExceeded,
  isAccountDailyDurationQuotaExceeded,
  isAccountDurationQuotaExceeded
} from 'src/apps/web/src/core/effects/utils';

import { APIErrors } from '@common/modules/core/services/toast/errors';
import {
  INotification,
  INotificationLink,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { guid } from '@common/modules/utils/string';

export const UPLOAD_HANDLING_ERRORS = [
  APIErrors.ACCOUNT_UPLOAD_QUOTA_EXCEEDED,
  APIErrors.VIDEO_ALREADY_IN_PROGRESS,
  APIErrors.EVENT_GRID_NOT_REGISTERED,
  APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT,
  APIErrors.AMS_UNREACHABLE,
  // In case of video info failed and the video is link to none video file
  APIErrors.INVALID_INPUT,
  APIErrors.STORAGE_UNREACHABLE,
  APIErrors.MANAGED_IDENTITY_MISSING,
  APIErrors.STORAGE_ACCESS_DENIED
];

export const RE_INDEX_HANDLING_ERRORS = [
  APIErrors.BREAKDOWN_NOT_FOUND,
  APIErrors.GENERAL,
  APIErrors.VIDEO_REMOVED,
  APIErrors.AMS_UNREACHABLE,
  APIErrors.VIDEO_INDEXING_IN_PROGRESS,
  APIErrors.INVALID_INPUT,
  APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT,
  APIErrors.PROJECT_RENDER_IN_PROGRESS,
  APIErrors.SOURCE_VIDEO_DELETED,
  APIErrors.STORAGE_UNREACHABLE,
  APIErrors.MANAGED_IDENTITY_MISSING,
  APIErrors.STORAGE_ACCESS_DENIED
];

export const FILE_UPLOAD_FAILED_NOTIFICATION_TITLE = 'UploadFileFailedNotificationMessage';
export const FILES_UPLOAD_FAILED_NOTIFICATION_TITLE = 'UploadFilesFailedNotificationMessage';

export const getUploadErrorType = (err: HttpErrorResponse) => {
  if (UPLOAD_HANDLING_ERRORS.includes(err?.error?.ErrorType)) {
    return <APIErrors>err.error.ErrorType;
  }
  return APIErrors.GENERAL;
};

export const getNotification = (
  id: string,
  title: string,
  type: NotificationType,
  level: NotificationLevel,
  icon: NotificationIcon,
  toasted: boolean,
  link?: INotificationLink,
  text?: string
): INotification => {
  return {
    id,
    messageType: NotificationMessageType.Custom,
    startDate: new Date(),
    endDate: new Date(),
    type,
    level,
    icon,
    title,
    toasted,
    link,
    text
  };
};

export const getQuotaExceededNotification = (quota: Microsoft.VideoIndexer.Contracts.AccountQuotaUsage, title: string, toasted: boolean) => {
  let text = '';
  if (isAccountDurationQuotaExceeded(quota)) {
    text = 'UploadFileAccountDurationQuotaError';
  } else if (isAccountDailyCountQuotaExceeded(quota)) {
    text = 'UploadFileAccountDailyCountQuotaError';
  } else if (isAccountDailyDurationQuotaExceeded(quota)) {
    text = 'UploadFileAccountDailyDurationQuotaError';
  }

  if (text) {
    const id = `uploadExceedQuota_${guid()}`;
    return getErrorNotification(id, title, toasted, text);
  }
};
export const getFileErrorNotification = (uploadingErrorType: APIErrors, title: string, toasted: boolean) => {
  let text;
  switch (uploadingErrorType) {
    case APIErrors.VIDEO_ALREADY_IN_PROGRESS:
      text = 'UploadFileURLAlreadyExistsError';
      break;
    case APIErrors.INVALID_INPUT:
      text = 'UploadFileInvalidURLError';
      break;
    case APIErrors.GENERAL:
      text = 'UploadFileGeneralError';
  }

  if (text) {
    const id = `uploadFileFailed_${guid()}`;
    return getErrorNotification(id, title, toasted, text);
  }
};

export const getUploadFilesFailedNotification = (uploadErrorType, title, toasted) => {
  let text;
  let link;
  switch (uploadErrorType) {
    case APIErrors.EVENT_GRID_NOT_REGISTERED:
      text = 'UploadFileEventGridError';
      break;
    case APIErrors.AMS_UNREACHABLE:
      text = 'UploadFileAmsUnreachableError';
      break;
    case APIErrors.AMS_AAD_APPLICATION_NOT_IN_TENANT:
      text = 'AmsAadAppNotInTenantErrorMsg';
      break;
    case APIErrors.STORAGE_UNREACHABLE:
      text = 'StorageUnreachableErrorMsg';
      break;
    case APIErrors.STORAGE_ACCESS_DENIED:
      text = 'UploadFileStorageAccessDeniedErrorMsg';
      link = {
        text: 'StorageAccessDeniedErrorMsgLinkText',
        src: ''
      };
      break;
    case APIErrors.MANAGED_IDENTITY_MISSING:
      text = 'UploadFileManagedIdentityMissingErrorMsg';
      break;
  }

  if (text) {
    const id = `uploadFilesFailed_${guid()}`;
    return getErrorNotification(id, title, toasted, text, link);
  }
};

export const getErrorNotification = (id, title: string, toasted: boolean, text: string, link?: INotificationLink) =>
  getNotification(id, title, NotificationType.Message, NotificationLevel.Error, NotificationIcon.Error, toasted, link, text);

export const getReIndexErrorType = (err: HttpErrorResponse) => {
  if (RE_INDEX_HANDLING_ERRORS.includes(err?.error?.ErrorType)) {
    return <APIErrors>err.error.ErrorType;
  }
  return APIErrors.GENERAL;
};
