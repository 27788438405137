import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';

export const sidebarContent = trigger('contentInOut', [
  state(
    'out',
    style({
      opacity: '0',
      transform: 'translate(40px, 0)'
    })
  ),
  state(
    'in',
    style({
      opacity: '1',
      transform: 'translate(0, 0)'
    })
  ),
  transition('out => in', [animate('0.28s 40ms cubic-bezier(0.8, 0, 0.2, 1)')])
]);

export const sidebarPage = trigger('pageInOut', [
  state(
    'out',
    style({
      opacity: '0',
      // display: 'none',
      transform: 'translate(40px, 0)'
    })
  ),
  state(
    'in',
    style({
      opacity: '1',
      display: 'block',
      transform: 'translate(0, 0)'
    })
  ),
  transition('out => in', [animate('0.16s 0s cubic-bezier(0.8, 0, 0.2, 1)'), query('@contentInOut', animateChild(), { optional: true })]),
  transition('in => out', [animate('0.16s 0s cubic-bezier(0.8, 0, 0.2, 1)')])
]);
