import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'vi-dynamic-tooltip',
  templateUrl: './dynamic-tooltip.component.html',
  styleUrls: ['./dynamic-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTooltipComponent {
  @Input() public tooltipTransform: string;
  @Input() public message: string;
  @Input() public showTooltipDown = false;

  // Do not delete cdr - used in DynamicTooltipDirective
  constructor(public cdr: ChangeDetectorRef) {}
}
