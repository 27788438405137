import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { switchMap, catchError, filter, take, mergeMap, finalize } from 'rxjs/operators';

import {
  getRequestVideoId,
  getRequestAccountId,
  hasAllowEdit,
  isProjectRequest,
  addAuthenticationToken,
  isThumbnailsRequest,
  getPermissionParam,
  tryGetParam,
  isReIndexRequest
} from '@common/modules/api/utils/request.function';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { AuthService } from '../../auth/services/auth.service';
import { ConfigService } from '../../core/services/config/config.service';
import { allowEditToPermission } from '../utils/auth.utils';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  // refresh tokens subjects
  private refreshUserTokenInProgressMap = [];
  private refreshUserTokenSubjectMap: BehaviorSubject<string>[] = [];

  private refreshAccountTokenInProgressMap = [];
  private refreshAccountTokenSubjectMap: BehaviorSubject<string>[] = [];

  private refreshVideoTokenInProgressMap = [];
  private refreshVideoTokenSubjectMap: BehaviorSubject<string>[] = [];

  private get enableEdgeExtensionTokens() {
    return this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeTokens);
  }

  constructor(
    public logger: LoggerService,
    public auth: AuthService,
    public configService: ConfigService,
    private trackService: TrackService,
    private apiService: ApiService,
    private featureSwitchService: FeatureSwitchService
  ) {
    this.logger.log('[AccessTokenService] init');
  }

  public async getAccessTokenAsync(
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    isExtensionRequest = false,
    permission?: AccountPermission,
    force = false,
    extendExtensionToken = false
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const token = this.auth.getAccessToken(id, accountId, allowEdit, isProject, null, isExtensionRequest);
      if (token.includes('refresh') || force) {
        this.logger.log('[AccessTokenService] GetAccessTokenAsync refresh token');
        this.trackService.track('auth.get_access_token.start', {
          category: EventCategory.AUTH,
          data: {
            id,
            accountId,
            allowEdit,
            isProject
          }
        });
        this.auth
          .getAccessTokenApi(id, accountId, allowEdit, isProject, permission, null, isExtensionRequest, extendExtensionToken)
          .pipe(take(1))
          // eslint-disable-next-line deprecation/deprecation
          .subscribe(
            accessToken => {
              this.handleGetAccessTokenSuccess(accessToken, id, accountId, allowEdit, isProject);
              resolve(accessToken);
            },
            error => {
              this.trackService.track('auth.get_access_token.failed', {
                category: EventCategory.AUTH,
                data: {
                  id,
                  accountId,
                  allowEdit,
                  isProject
                }
              });
              reject(error);
            }
          );
      } else {
        resolve(token);
      }
    });
  }

  public getAccessTokenFromCache(request: HttpRequest<object>): string {
    // Get params
    const id = this.getVideoId(request);
    const accountId = getRequestAccountId(request);
    const allowEdit = hasAllowEdit(request);
    const isProject = isProjectRequest(request);
    const permission = getPermissionParam(request);
    const isExtensionRequest = this.isExtensionRequest(request);
    return this.auth.getAccessToken(id, accountId, allowEdit, isProject, permission, isExtensionRequest);
  }

  public addAuthenticationToken(request: HttpRequest<object>): HttpRequest<object> {
    // Get params
    const id = this.getVideoId(request);
    const accountId = getRequestAccountId(request);
    const allowEdit = hasAllowEdit(request);
    const isProject = isProjectRequest(request);
    const permission = getPermissionParam(request);
    const isExtensionRequest = this.isExtensionRequest(request);
    /**
     * reindex request in arc extension uses account level token so in this case we can't
     * send the videoId otherwise the token will be scoped to the video
     */
    const useAccountScope = isExtensionRequest && isReIndexRequest(request);
    const accessToken = this.auth.getAccessToken(useAccountScope ? null : id, accountId, allowEdit, isProject, permission, isExtensionRequest);

    return addAuthenticationToken(request, accessToken);
  }

  // AccessTokenAuthorizationHandler - handle all tokens refresh requests (User/Account/Video)
  // depends on the the request params
  // refresh token mechanism for each token (User/Account/Video)
  public accessTokenAuthorizationHandler(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // Get params
    const id = this.getVideoId(request);
    const accountId = getRequestAccountId(request);
    const allowEdit = hasAllowEdit(request);
    const isProject = isProjectRequest(request);
    const permission = getPermissionParam(request);
    const isExtensionsRequest = this.isExtensionRequest(request);
    this.trackService.track('auth.access_token.refresh_token.init', {
      category: EventCategory.AUTH,
      data: {
        id,
        accountId,
        allowEdit,
        isProject
      }
    });

    // reindex request in arc extension uses account level token
    if (id && isExtensionsRequest && isReIndexRequest(request)) {
      return this.handleAccountAccessTokenAuthorizationError(request, next, accountId, allowEdit, permission, isExtensionsRequest);
    }

    // if in extension mode, don't send video access token request
    if (id && !isThumbnailsRequest(request)) {
      return this.handleVideoAccessTokenAuthorizationError(request, next, id, accountId, allowEdit, isProject, isExtensionsRequest);
    } else if (accountId) {
      return this.handleAccountAccessTokenAuthorizationError(request, next, accountId, allowEdit, permission, isExtensionsRequest);
    } else {
      return this.handleUserAccessTokenAuthorizationError(request, next, allowEdit);
    }
  }

  private handleUserAccessTokenAuthorizationError(
    request: HttpRequest<object>,
    next: HttpHandler,
    allowEdit: boolean = false
  ): Observable<HttpEvent<object>> {
    if (!this.refreshUserTokenInProgressMap[`${allowEdit}`]) {
      this.refreshUserTokenInProgressMap[`${allowEdit}`] = true;
      this.refreshUserTokenSubjectMap[`${allowEdit}`] = new BehaviorSubject<string>(null);

      return this.auth.getAccessTokenApi(null, null, allowEdit).pipe(
        switchMap((userToken: string) => {
          this.handleGetAccessTokenSuccess(userToken, null, null, allowEdit);
          this.refreshUserTokenSubjectMap[`${allowEdit}`].next(userToken);
          this.refreshUserTokenInProgressMap[`${allowEdit}`] = false;
          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(err => {
          this.handleGetAccessTokenError(err, null, null, allowEdit);
          this.refreshUserTokenSubjectMap[`${allowEdit}`].next(err);
          this.refreshUserTokenInProgressMap[`${allowEdit}`] = false;
          // eslint-disable-next-line deprecation/deprecation
          return throwError(err);
        })
      );
    } else {
      return this.refreshUserTokenSubjectMap[`${allowEdit}`].pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
  }

  private handleAccountAccessTokenAuthorizationError(
    request: HttpRequest<object>,
    next: HttpHandler,
    accountId: string,
    allowEdit: boolean = false,
    permission?: AccountPermission,
    isExtensionRequest = false
  ): Observable<HttpEvent<object>> {
    const permissionValue = permission || allowEditToPermission(allowEdit);
    const index = `${accountId}_${permissionValue}${isExtensionRequest ? this.auth.SelectedEdgeExtensionId : ''}`;
    if (!this.refreshAccountTokenInProgressMap[index]) {
      this.refreshAccountTokenInProgressMap[index] = true;
      this.refreshAccountTokenSubjectMap[index] = new BehaviorSubject<string>(null);
      const reqSettings = { params: {} };
      if (this.auth.enableRestrictedViewer) {
        reqSettings.params['enableRestrictedViewer'] = true;
      }
      return this.auth.getAccessTokenApi(null, accountId, allowEdit, null, permission, reqSettings, isExtensionRequest).pipe(
        mergeMap((accountToken: string) => {
          this.handleGetAccessTokenSuccess(accountToken, null, accountId, allowEdit, null, permission, isExtensionRequest);
          this.refreshAccountTokenSubjectMap[index].next(accountToken);
          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(err => {
          this.handleGetAccessTokenError(err, null, accountId, allowEdit, null, permission);
          this.refreshAccountTokenSubjectMap[index].next(err);
          // eslint-disable-next-line deprecation/deprecation
          return throwError(err);
        }),
        finalize(() => {
          this.refreshAccountTokenInProgressMap[index] = false;
        })
      );
    } else {
      return this.refreshAccountTokenSubjectMap[index].pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
  }

  private handleVideoAccessTokenAuthorizationError(
    request: HttpRequest<object>,
    next: HttpHandler,
    id: string,
    accountId: string,
    allowEdit: boolean = false,
    isProject = false,
    isExtensionRequest = false
  ): Observable<HttpEvent<object>> {
    const index = `${id}_${allowEdit}${isExtensionRequest ? this.auth.SelectedEdgeExtensionId : ''}`;
    if (!this.refreshVideoTokenInProgressMap[index]) {
      this.refreshVideoTokenInProgressMap[index] = true;
      this.refreshVideoTokenSubjectMap[index] = new BehaviorSubject<string>(null);

      const location = tryGetParam(request, 'location');
      const reqSettings = { params: {} };
      if (location) {
        reqSettings.params['location'] = location;
      }
      if (this.auth.enableRestrictedViewer) {
        reqSettings.params['enableRestrictedViewer'] = true;
      }
      return this.auth.getAccessTokenApi(id, accountId, allowEdit, isProject, null, reqSettings, isExtensionRequest).pipe(
        switchMap((videoToken: string) => {
          this.handleGetAccessTokenSuccess(videoToken, id, accountId, allowEdit, isProject, null, isExtensionRequest);
          this.refreshVideoTokenSubjectMap[index].next(videoToken);
          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(err => {
          this.handleGetAccessTokenError(err, id, accountId, allowEdit, isProject);
          this.refreshVideoTokenSubjectMap[index].next(err);
          // eslint-disable-next-line deprecation/deprecation
          return throwError(err);
        }),
        finalize(() => {
          this.refreshVideoTokenInProgressMap[index] = false;
        })
      );
    } else {
      return this.refreshVideoTokenSubjectMap[`${index}`].pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthenticationToken(request));
        })
      );
    }
  }

  private handleGetAccessTokenSuccess(
    token: string,
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission,
    isExtensionRequest = false
  ) {
    this.trackService.track('auth.access_token.refresh_token.success', {
      category: EventCategory.AUTH,
      data: {
        id,
        accountId,
        allowEdit,
        isProject,
        permission
      }
    });
    this.auth.setAccessToken(token, id, accountId, allowEdit, isProject, permission, isExtensionRequest);
  }

  private handleGetAccessTokenError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    id?: string,
    accountId?: string,
    allowEdit: boolean = false,
    isProject = false,
    permission?: AccountPermission
  ) {
    this.trackService.track('auth.access_token.refresh_token.failed', {
      category: EventCategory.AUTH,
      data: {
        error: {
          name: error?.name,
          status: error?.status,
          error: error?.error
        },
        id,
        accountId,
        allowEdit,
        isProject,
        permission
      }
    });
  }

  private getVideoId(request: HttpRequest<object>): string {
    if (!this.enableEdgeExtensionTokens) {
      return this.isExtensionRequest(request) ? '' : getRequestVideoId(request);
    }

    return getRequestVideoId(request);
  }

  private isExtensionRequest(request: HttpRequest<object>): boolean {
    if (!this.apiService.edgeExtensionApiUrl?.length) {
      return false;
    }

    return request.url.includes(this.apiService.edgeExtensionApiUrl);
  }
}
