import { TagType } from '../tag';

export interface ITag {
  content: string;
  id: string;
  type?: TagType;
  term?: string;
  enableRemove?: boolean;
  removeTagTitleAttribute?: string;
}
