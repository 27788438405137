export const resources = {
  HelpReferences: '',
  HeaderDocumentation: '',
  HeaderHelpLinkSupport: '',
  HeaderHelpLinkGiveFeedback: '',
  HeaderBlog: '',
  HeaderAPIReference: '',
  HeaderAboutVideoIndexer: '',
  HeaderPricing: '',
  FooterPrivacyStatement: '',
  FooterServicesAgreement: '',
  HelpSurvey: '',
  FaceRecognitionFormHelpLink: '',
  FaceGatePopupTitle: ''
};
