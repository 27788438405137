import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';

export const getSidebarState = createSelector(selectCoreState, state => state.sidebar);

export const getSidebarStateStatus = createSelector(getSidebarState, state => state.state);

export const getSidebarPage = createSelector(getSidebarState, state => state.page);

export const getSidebarCallbackAfterClose = createSelector(getSidebarState, state => state.callbackAfterClose);
