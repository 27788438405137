import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostBinding, Input, Output, EventEmitter } from '@angular/core';

import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FACE_RECOGNITION_FORM } from '@common/modules/shared/links';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';

import { resources } from './resources';
import { CoreStoreService } from '../../../../core/services/core-store.service';
import { FaceGateStripMode } from './interfaces';

@Component({
  selector: 'vi-indexing-face-gate-strip',
  templateUrl: './indexing-face-gate-strip.component.html',
  styleUrls: ['./indexing-face-gate-strip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingFaceGateStripComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() public mode: FaceGateStripMode;
  @Output() public navigateToCreateAccount = new EventEmitter<void>();

  public AlertMessageType = AlertMessageType;
  public isTrial = false;
  public isAccountLimitedWithFaces = false;
  public showFaceGateWarning = false;
  public resources = resources;

  @HostBinding('style.display') public get isVisible() {
    return this.showFaceGateWarning ? 'block' : 'none';
  }

  private isAccountInHighRiskRegion = false;

  private destroy$ = new Subject<void>();

  constructor(private coreStore: CoreStoreService, private translate: TranslateHelperService) {
    super();
  }

  public ngOnInit(): void {
    this.initTranslations();
    this.coreStore.isTrialAccount$.pipe(takeUntil(this.destroy$)).subscribe(isTrial => {
      this.isTrial = isTrial;
    });

    this.coreStore.isAccountLimitedWithFaces$
      .pipe(withLatestFrom(this.coreStore.isAccountInHighRiskRegion$), takeUntil(this.destroy$))
      .pipe(withLatestFrom(this.coreStore.showFaceGateWarning$), takeUntil(this.destroy$))
      .subscribe(([[isAccountLimitedWithFaces, isAccountInHighRiskRegion], showFaceGateWarning]) => {
        if (isAccountLimitedWithFaces === undefined || isAccountLimitedWithFaces === null) {
          return;
        }
        this.isAccountLimitedWithFaces = isAccountLimitedWithFaces;
        this.isAccountInHighRiskRegion = isAccountInHighRiskRegion;
        this.showFaceGateWarning = showFaceGateWarning;
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get faceGateStripMessage() {
    // When account is gated - can not access to faces
    if (this.mode === FaceGateStripMode.Indexing) {
      return this.faceGateIndexingStripMessage;
    } else {
      return this.faceGatePeopleModelStripMessage;
    }
  }

  public get faceGateStripLinkText() {
    // When account is gated - can not access to faces
    if (this.mode === FaceGateStripMode.Indexing) {
      return this.faceGateIndexingStripLinkText;
    } else {
      return this.faceGatePeopleModelStripLinkText;
    }
  }

  public handleLinkNavigation() {
    if (this.isTrial) {
      this.navigateToCreateAccount.emit();
    }
  }

  public get faceGateStripLinkPath() {
    return this.isTrial ? null : FACE_RECOGNITION_FORM;
  }

  private get faceGatePeopleModelStripLinkText() {
    return this.isTrial ? this.resources?.IndexingPeopleModelsTrialFaceGateLink : this.resources?.IndexingPaidFaceGateLink;
  }

  private get faceGatePeopleModelStripMessage() {
    return this.isTrial
      ? this.resources?.IndexingPeopleModelsTrialFaceGateMessage
      : this.isAccountInHighRiskRegion
      ? resources.IndexingPeopleModelsPaidHighRiskFaceGateMessage
      : this.resources?.IndexingPeopleModelsPaidFaceGateMessage;
  }

  private get faceGateIndexingStripMessage() {
    if (this.isAccountLimitedWithFaces) {
      // return different message to - trial, paid or paid in high risk region
      return this.isTrial
        ? this.resources?.IndexingTrialFaceGateMessage
        : this.isAccountInHighRiskRegion
        ? this.resources?.IndexingPaidHighRiskFaceGateMessage
        : this.resources?.IndexingPaidFaceGateMessage;
    } else {
      // When account is not gated - warning him
      // return different message to - trial, paid or paid in high risk region
      return this.isTrial
        ? this.resources?.IndexingOldTrialFaceGateMessage
        : this.isAccountInHighRiskRegion
        ? resources.IndexingOldPaidHighRiskFaceGateMessage
        : this.resources?.IndexingOldPaidFaceGateMessage;
    }
  }

  private get faceGateIndexingStripLinkText() {
    return this.isTrial ? this.resources?.CreateAccountWithAzure : this.resources?.IndexingPaidFaceGateLink;
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources);
  }
}
