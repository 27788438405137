import { createAction, props } from '@ngrx/store';

export const loadSupportedLanguages = createAction('[SupportedLanguagesIds/API] Load Supported Languages');

export const upsertSupportedLanguages = createAction(
  '[SupportedLanguagesIds/API] Upsert Supported Languages',
  props<{ supportedLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[] }>()
);

export const loadSupportedLanguageFailed = createAction('[SupportedLanguagesIds] Failed load Supported Languages');
