import { Injectable, OnDestroy } from '@angular/core';

import { Update } from '@ngrx/entity';

import { Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import {
  INotification,
  INotificationButton,
  NotificationFilterType,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { IStripData, MessageType } from '@common/modules/shared/components/strip/interfaces';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { NotificationsStoreService } from './notifications-store.service';
import { CoreStoreService } from './core-store.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsHandlerService implements OnDestroy {
  protected destroy$ = new Subject<void>();
  // Alerts
  private alertsDefaultAndTenantNotificationsIdsList: string[] = [];
  private alertsDefaultAndTenantNotificationsToastedIdsList: string[] = [];
  private alertsRegionalNotificationsIdsList: string[] = [];
  private alertsRegionalNotificationsToastedIdsList: string[] = [];
  private alertsAccountTypeNotificationsIdsList: string[] = [];
  private alertsAccountTypeNotificationsToastedIdsList: string[] = [];

  // Messages
  private messagesDefaultAndTenantNotificationsIdsList: string[] = [];
  private messagesDefaultAndTenantNotificationsToastedIdsList: string[] = [];
  private messagesRegionalNotificationsIdsList: string[] = [];
  private messagesRegionalNotificationsToastedIdsList: string[] = [];
  private messagesAccountTypeNotificationsIdsList: string[] = [];
  private messagesAccountTypeNotificationsToastedIdsList: string[] = [];

  // Session
  private sessionNotificationsIdsList: string[] = [];

  // Common
  private clearedNotificationsIdsList: string[] = [];
  private bannersStripsIds: { [index: string]: number } = {};
  private userId = '';
  private region = '';
  private accountType = '';
  private eTag = '';
  private alertsSuffix = 'alerts';
  private messagesSuffix = 'messages';

  constructor(
    private toastService: ToastService,
    private stripService: StripService,
    private notificationsStore: NotificationsStoreService,
    private localStorageService: LocalStorageService,
    private featureSwitchService: FeatureSwitchService,
    private utilsService: UtilsService,
    private translateService: TranslateHelperService,
    private coreStoreService: CoreStoreService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get clearedNotificationsIds(): string[] {
    return this.clearedNotificationsIdsList;
  }

  public get toastedNotificationsIds(): string[] {
    return [
      ...this.alertsDefaultAndTenantNotificationsToastedIdsList,
      ...this.alertsRegionalNotificationsToastedIdsList,
      ...this.alertsAccountTypeNotificationsToastedIdsList,
      ...this.messagesDefaultAndTenantNotificationsToastedIdsList,
      ...this.messagesRegionalNotificationsToastedIdsList,
      ...this.messagesAccountTypeNotificationsToastedIdsList,
      ...this.sessionNotificationsIdsList
    ];
  }

  public get ETag(): string {
    return this.eTag;
  }

  public set ETag(eTag: string) {
    this.eTag = eTag;
  }

  public addNotificationsIds(
    alertsDefaultAndTenantNotificationsIds: string[],
    alertsRegionalNotificationsIds: string[],
    alertsAccountTypeNotificationsIdsList: string[],
    messagesDefaultAndTenantNotificationsIds: string[],
    messagesRegionalNotificationsIds: string[],
    messagesAccountTypeNotificationsIdsList: string[]
  ) {
    this.addAlertsDefaultAndTenantNotificationsIds(alertsDefaultAndTenantNotificationsIds);
    this.addAlertsRegionalNotificationsIds(alertsRegionalNotificationsIds);
    this.addAlertsAccountTypeNotificationsIds(alertsAccountTypeNotificationsIdsList);
    this.addMessagesDefaultAndTenantNotificationsIds(messagesDefaultAndTenantNotificationsIds);
    this.addMessagesRegionalNotificationsIds(messagesRegionalNotificationsIds);
    this.addMessagesAccountTypeNotificationsIds(messagesAccountTypeNotificationsIdsList);
  }

  public initNotificationsService() {
    if (!this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
      return;
    }

    combineLatest([this.notificationsStore.notToastedBannersNotifications$, this.coreStoreService.selectedAccountLoaded$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([notifications, isSelectedAccountLoaded]) => {
        const updatedNotifications: Update<INotification>[] = [];
        for (const notification of notifications) {
          // publish banner notification
          const stripData: IStripData = {
            text: notification.text,
            iconClass: notification.icon,
            show: true,
            messageType: this.getNotificationStripMessageType(notification.level),
            hasCloseButton: true,
            notificationLevel: notification.level,
            trackingName: 'notifications.banner',
            ...(notification.button && { action: notification.button.action, actionCallback: notification.button.callback })
          };

          if (!this.bannersStripsIds[notification.id.toString()] && (!notification.resolveAccountData || isSelectedAccountLoaded)) {
            this.bannersStripsIds[notification.id.toString()] = this.stripService.triggerNotification(stripData);
            const updateNotification = {
              ...notification,
              toasted: true
            };
            updatedNotifications.push({ id: updateNotification.id, changes: updateNotification });
          }
        }

        if (updatedNotifications.length) {
          // update that all banners toasted
          this.notificationsStore.updateBannersNotifications(updatedNotifications);
        }
      });

    this.notificationsStore.notToastedAlertsNotifications$.pipe(takeUntil(this.destroy$)).subscribe(notifications => {
      const updatedNotifications: Update<INotification>[] = [];
      for (const notification of notifications) {
        // publish toast notification
        if (!this.toastedNotificationsIds.includes(notification.id?.toString())) {
          this.toastService.notify(notification);
        }
        const updateNotification = {
          ...notification,
          toasted: true
        };
        updatedNotifications.push({ id: updateNotification.id, changes: updateNotification });
        this.saveAlertsToastNotificationToList(notification);
      }

      if (updatedNotifications.length) {
        // update that all alerts toasted
        this.notificationsStore.updateAlertsNotifications(updatedNotifications);
        this.saveToastNotificationsCache();
      }
    });

    this.notificationsStore.notToastedMessagesNotifications$.pipe(takeUntil(this.destroy$)).subscribe(notifications => {
      const updatedNotifications: Update<INotification>[] = [];
      for (const notification of notifications) {
        // publish toast notification
        if (!this.toastedNotificationsIds.includes(notification.id?.toString())) {
          this.toastService.notify(notification);
        }
        const updateNotification = {
          ...notification,
          toasted: true
        };
        updatedNotifications.push({ id: updateNotification.id, changes: updateNotification });
        this.saveMessagesToastNotificationToList(notification);
      }

      if (updatedNotifications.length) {
        // update that all alerts toasted
        this.notificationsStore.updateMessagesNotifications(updatedNotifications);
        this.saveToastNotificationsCache();
      }
    });
  }

  public clearAllToasts() {
    this.toastService.clearAllToasts();
  }

  public loadNotificationsFromCache(userId: string, region: string, accountType: string) {
    this.clearBannersNotifications();
    this.clearSessionNotifications();
    if (this.region && this.region !== region) {
      this.clearRegionNotifications();
    }

    if (this.accountType && this.accountType !== accountType) {
      this.clearAccountTypeNotifications();
    }

    this.userId = userId;
    this.region = region;
    this.accountType = accountType;
    // setting the eTag empty to always get all notifications on init and change account
    this.eTag = '';
    this.loadDefaultAndTenantNotificationsIdsCache();
    this.loadDefaultAndTenantNotificationsToastedIdsCache();
    this.loadRegionalNotificationsIdsCache();
    this.loadRegionalNotificationsToastedIdsCache();
    this.loadAccountTypeNotificationsIdsCache();
    this.loadAccountTypeNotificationsToastedIdsCache();
    this.setClearedNotifications();
  }

  public clearAlertsAndBannersNotifications() {
    this.saveAlertsDefaultAndTenantNotificationsIdsCache();
    this.saveAlertsRegionalNotificationsIdsCache();
    this.saveAlertsAccountTypeNotificationsIdsCache();
    this.setClearedNotifications();

    this.notificationsStore.clearAlertsAndBannersNotifications();
  }

  public clearMessagesNotifications() {
    this.saveMessagesDefaultAndTenantNotificationsIdsCache();
    this.saveMessagesRegionalNotificationsIdsCache();
    this.saveMessagesAccountTypeNotificationsIdsCache();
    this.setClearedNotifications();

    this.notificationsStore.clearMessagesNotifications();
  }

  public markedAlertsAndBannersNotificationsBeenSeen() {
    const defaultChanges = this.alertsDefaultAndTenantNotificationsIdsList.map(id => {
      return { id, changes: { seen: true } };
    });
    const regionalChanges = this.alertsRegionalNotificationsIdsList.map(id => {
      return { id, changes: { seen: true } };
    });
    const accountTypeChanges = this.alertsAccountTypeNotificationsIdsList.map(id => {
      return { id, changes: { seen: true } };
    });
    const updatedNotifications: Update<INotification>[] = [...defaultChanges, ...regionalChanges, ...accountTypeChanges];
    this.notificationsStore.updateAlertsNotifications(updatedNotifications);
    this.notificationsStore.updateBannersNotifications(updatedNotifications);
  }

  public clearBannersNotifications() {
    for (const id of Object.keys(this.bannersStripsIds)) {
      // remove banner notification
      if (this.bannersStripsIds[id]) {
        this.stripService.hideNotificationStrip(this.bannersStripsIds[id]);
        delete this.bannersStripsIds[id];
      }
    }
    this.notificationsStore.clearBannersNotifications();
  }

  public clearSessionNotifications() {
    this.notificationsStore.removeNotifications(this.sessionNotificationsIdsList);
    this.sessionNotificationsIdsList = [];
  }

  public getRequestMigrationNotification(): INotification {
    return {
      id: `request-migration-notification`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Banner,
      level: NotificationLevel.Warning,
      icon: NotificationIcon.Warning,
      title: this.translateService.instant('RequestMigrationNotificationTitle'),
      toasted: false,
      text: this.translateService.instant('RequestMigrationNotificationText'),
      button: this.getRequestMigrationNotificationButton()
    };
  }

  private getRequestMigrationNotificationButton(): INotificationButton {
    return {
      action: {
        title: this.translateService.instant('RequestMigrationNotificationButtonText'),
        id: 'request-migration',
        value: 'request-migration'
      },
      callback: () => {
        combineLatest([this.coreStoreService.selectedAccountContract$, this.coreStoreService.selectAccountError$])
          .pipe(
            filter(([account, error]) => !!account || error), // wait for account to be loaded or error to appear
            take(1)
          )
          .subscribe(([account, _error]) => {
            const url = this.utilsService.getRequestMigrationInAzurePortalUrl(account?.tenantId, account?.resourceId);
            // on error the url will be redirected to ibiza home page
            window.open(url, '_blank');
          });
      }
    };
  }

  private addAlertsDefaultAndTenantNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.alertsDefaultAndTenantNotificationsIdsList.includes(id)) || [];
    this.alertsDefaultAndTenantNotificationsIdsList.push(...newIds);
  }

  private addAlertsRegionalNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.alertsRegionalNotificationsIdsList.includes(id)) || [];
    this.alertsRegionalNotificationsIdsList.push(...newIds);
  }

  private addAlertsAccountTypeNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.alertsAccountTypeNotificationsIdsList.includes(id)) || [];
    this.alertsAccountTypeNotificationsIdsList.push(...newIds);
  }

  private addMessagesDefaultAndTenantNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.messagesDefaultAndTenantNotificationsIdsList.includes(id)) || [];
    this.messagesDefaultAndTenantNotificationsIdsList.push(...newIds);
  }

  private addMessagesRegionalNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.messagesRegionalNotificationsIdsList.includes(id)) || [];
    this.messagesRegionalNotificationsIdsList.push(...newIds);
  }

  private addMessagesAccountTypeNotificationsIds(ids: string[]) {
    const newIds = ids?.filter(id => !this.messagesAccountTypeNotificationsIdsList.includes(id)) || [];
    this.messagesAccountTypeNotificationsIdsList.push(...newIds);
  }

  private clearRegionNotifications() {
    this.notificationsStore.removeNotifications(this.alertsRegionalNotificationsIdsList);
  }

  private clearAccountTypeNotifications() {
    this.notificationsStore.removeNotifications(this.alertsAccountTypeNotificationsIdsList);
  }

  private getNotificationStripMessageType(level: NotificationLevel): MessageType {
    switch (level) {
      case NotificationLevel.Critical:
        return MessageType.ERROR;
      case NotificationLevel.Error:
        return MessageType.ERROR;
      case NotificationLevel.Warning:
        return MessageType.WARNING;
      default:
        return MessageType.INFO;
    }
  }

  private setClearedNotifications() {
    this.clearedNotificationsIdsList = [
      ...this.alertsDefaultAndTenantNotificationsIdsList,
      ...this.alertsRegionalNotificationsIdsList,
      ...this.alertsAccountTypeNotificationsIdsList,
      ...this.messagesDefaultAndTenantNotificationsIdsList,
      ...this.messagesRegionalNotificationsIdsList,
      ...this.messagesAccountTypeNotificationsIdsList
    ];
  }

  private saveToastNotificationsCache() {
    this.saveDefaultAndTenantNotificationsToastedIdsCache();
    this.saveRegionalNotificationsToastedIdsCache();
    this.saveAccountTypeNotificationsToastedIdsCache();
  }

  private saveAlertsToastNotificationToList(notification: INotification) {
    switch (notification.filterType) {
      case NotificationFilterType.Default:
        this.alertsDefaultAndTenantNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.Tenant:
        this.alertsDefaultAndTenantNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.Region:
        this.alertsRegionalNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.AccountType:
        this.alertsAccountTypeNotificationsToastedIdsList.push(notification.id);
        break;
      default:
        this.sessionNotificationsIdsList.push(notification.id);
        break;
    }
  }

  private saveMessagesToastNotificationToList(notification: INotification) {
    switch (notification.filterType) {
      case NotificationFilterType.Default:
        this.messagesDefaultAndTenantNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.Tenant:
        this.messagesDefaultAndTenantNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.Region:
        this.messagesRegionalNotificationsToastedIdsList.push(notification.id);
        break;
      case NotificationFilterType.AccountType:
        this.messagesAccountTypeNotificationsToastedIdsList.push(notification.id);
        break;
      default:
        this.sessionNotificationsIdsList.push(notification.id);
        break;
    }
  }

  private saveAlertsDefaultAndTenantNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsIds}_${this.userId}_${this.alertsSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsIds}_${this.userId}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsDefaultAndTenantNotificationsIdsList)
    );
  }

  private saveMessagesDefaultAndTenantNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsIds}_${this.userId}_${this.messagesSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsIds}_${this.userId}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesDefaultAndTenantNotificationsIdsList)
    );
  }

  private loadDefaultAndTenantNotificationsIdsCache() {
    const alertsIds = this.localStorageService.getItem(`${StorageCacheKey.NotificationsIds}_${this.userId}_${this.alertsSuffix}`);
    const messagesIds = this.localStorageService.getItem(`${StorageCacheKey.NotificationsIds}_${this.userId}_${this.messagesSuffix}`);
    this.alertsDefaultAndTenantNotificationsIdsList = [];
    if (alertsIds?.length) {
      this.alertsDefaultAndTenantNotificationsIdsList = JSON.parse(alertsIds);
    }
    this.messagesDefaultAndTenantNotificationsIdsList = [];
    if (messagesIds?.length) {
      this.messagesDefaultAndTenantNotificationsIdsList = JSON.parse(messagesIds);
    }
  }

  private saveDefaultAndTenantNotificationsToastedIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.alertsSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsDefaultAndTenantNotificationsToastedIdsList)
    );
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.messagesSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesDefaultAndTenantNotificationsToastedIdsList)
    );
  }

  private loadDefaultAndTenantNotificationsToastedIdsCache() {
    const alertsIds = this.localStorageService.getItem(`${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.alertsSuffix}`);
    const messagesIds = this.localStorageService.getItem(`${StorageCacheKey.NotificationsToastedIds}_${this.userId}_${this.messagesSuffix}`);
    this.alertsDefaultAndTenantNotificationsToastedIdsList = [];
    if (alertsIds?.length) {
      this.alertsDefaultAndTenantNotificationsToastedIdsList = JSON.parse(alertsIds);
    }
    this.messagesDefaultAndTenantNotificationsToastedIdsList = [];
    if (messagesIds?.length) {
      this.messagesDefaultAndTenantNotificationsToastedIdsList = JSON.parse(messagesIds);
    }
  }

  private saveAlertsRegionalNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.alertsSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsRegionalNotificationsIdsList)
    );
  }

  private saveMessagesRegionalNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.messagesSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesRegionalNotificationsIdsList)
    );
  }

  private loadRegionalNotificationsIdsCache() {
    const alertsIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.alertsSuffix}`
    );
    const messagesIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsRegionalIds}_${this.userId}_${this.region}_${this.messagesSuffix}`
    );
    this.alertsRegionalNotificationsIdsList = [];
    if (alertsIds?.length) {
      this.alertsRegionalNotificationsIdsList = JSON.parse(alertsIds);
    }
    this.messagesRegionalNotificationsIdsList = [];
    if (messagesIds?.length) {
      this.messagesRegionalNotificationsIdsList = JSON.parse(messagesIds);
    }
  }

  private saveRegionalNotificationsToastedIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.alertsSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsRegionalNotificationsToastedIdsList)
    );
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.messagesSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesRegionalNotificationsToastedIdsList)
    );
  }

  private loadRegionalNotificationsToastedIdsCache() {
    const alertsIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.alertsSuffix}`
    );
    const messagesIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsRegionalToastedIds}_${this.userId}_${this.region}_${this.messagesSuffix}`
    );
    this.alertsRegionalNotificationsToastedIdsList = [];
    if (alertsIds?.length) {
      this.alertsRegionalNotificationsToastedIdsList = JSON.parse(alertsIds);
    }
    this.messagesRegionalNotificationsToastedIdsList = [];
    if (messagesIds?.length) {
      this.messagesRegionalNotificationsToastedIdsList = JSON.parse(messagesIds);
    }
  }

  private saveAlertsAccountTypeNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsAccountTypeNotificationsIdsList)
    );
  }

  private saveMessagesAccountTypeNotificationsIdsCache() {
    this.localStorageService.removeItem(`${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`);
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesAccountTypeNotificationsIdsList)
    );
  }

  private loadAccountTypeNotificationsIdsCache() {
    const alertsIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`
    );
    const messagesIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsAccountTypeIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`
    );
    this.alertsAccountTypeNotificationsIdsList = [];
    if (alertsIds?.length) {
      this.alertsAccountTypeNotificationsIdsList = JSON.parse(alertsIds);
    }
    this.messagesAccountTypeNotificationsIdsList = [];
    if (messagesIds?.length) {
      this.messagesAccountTypeNotificationsIdsList = JSON.parse(messagesIds);
    }
  }

  private saveAccountTypeNotificationsToastedIdsCache() {
    this.localStorageService.removeItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`
    );
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`,
      JSON.stringify(this.alertsAccountTypeNotificationsToastedIdsList)
    );
    this.localStorageService.removeItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`
    );
    this.localStorageService.setItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`,
      JSON.stringify(this.messagesAccountTypeNotificationsToastedIdsList)
    );
  }

  private loadAccountTypeNotificationsToastedIdsCache() {
    const alertsIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.alertsSuffix}`
    );
    const messagesIds = this.localStorageService.getItem(
      `${StorageCacheKey.NotificationsAccountTypeToastedIds}_${this.userId}_${this.accountType}_${this.messagesSuffix}`
    );
    this.alertsAccountTypeNotificationsToastedIdsList = [];
    if (alertsIds?.length) {
      this.alertsAccountTypeNotificationsToastedIdsList = JSON.parse(alertsIds);
    }
    this.messagesAccountTypeNotificationsToastedIdsList = [];
    if (messagesIds?.length) {
      this.messagesAccountTypeNotificationsToastedIdsList = JSON.parse(messagesIds);
    }
  }
}
