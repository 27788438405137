import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '../../auth/services/auth.service';
import { ConfigService } from '../../core/services/config/config.service';

@Injectable()
export class FrontdoorInterceptor implements HttpInterceptor {
  private authorizationApiLocation: string;

  constructor(public auth: AuthService, public configService: ConfigService) {
    this.authorizationApiLocation = this.configService.Config?.api?.authorizationApiLocation;
  }

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // If its not api route
    if (!request.url.includes(this.authorizationApiLocation)) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({
        setHeaders: {
          // @see https://learn.microsoft.com/en-us/azure/frontdoor/front-door-http-headers-protocol#from-client-to-the-front-door
          'X-Azure-DebugInfo': '1'
        }
      })
    );
  }
}
