import { createReducer, Action, on } from '@ngrx/store';

import * as NotificationsActions from '../../actions/notifications.actions';
import * as AccountsActions from '../../actions/accounts.actions';

export interface NotificationsViewState {
  // additional entities state properties
  loading: boolean;
}

export const initialNotificationsViewState: NotificationsViewState = {
  // additional entity state properties
  loading: true
};

const notificationsViewStateReducer = createReducer(
  initialNotificationsViewState,
  on(AccountsActions.selectAccount, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(AccountsActions.selectArmAccount, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(NotificationsActions.loadNotifications, state => {
    return {
      ...state,
      loading: false
    };
  })
);

export function reducer(state: NotificationsViewState | undefined, action: Action) {
  return notificationsViewStateReducer(state, action);
}
