import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { IAction } from '@common/modules/shared/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { INotification } from '@common/modules/core/services/toast/interfaces';
import { NOTIFICATIONS_EMPTY_STATE_SVG_PATH1, NOTIFICATIONS_EMPTY_STATE_SVG_PATH2 } from '@common/modules/shared/svg-shapes';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';

import { NotificationsHandlerService } from '../../../core/services/notifications-handler.service';
import { NotificationsStoreService } from '../../../core/services/notifications-store.service';
import { UINotificationsActionType } from '../../interfaces';
import { resources } from './resources';

@Component({
  selector: 'vi-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  public resources = resources;
  public alerts: INotification[];
  public messages: INotification[];
  public NOTIFICATIONS_EMPTY_STATE_SVG_PATH1 = NOTIFICATIONS_EMPTY_STATE_SVG_PATH1;
  public NOTIFICATIONS_EMPTY_STATE_SVG_PATH2 = NOTIFICATIONS_EMPTY_STATE_SVG_PATH2;
  public ActionButtonType = ActionButtonType;
  public notificationsLoading: boolean;
  public clearAlertsNotifications: IAction = {
    title: '',
    icon: '',
    key: 'SidebarUserClear',
    value: UINotificationsActionType.CLEAR,
    selected: false,
    selectable: false,
    id: 'clearAlertsNotificationsButton'
  };
  public clearMessagesNotifications: IAction = {
    title: '',
    icon: '',
    key: 'SidebarUserClear',
    value: UINotificationsActionType.CLEAR,
    selected: false,
    selectable: false,
    id: 'clearMessagesNotificationsButton'
  };
  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private notificationsStore: NotificationsStoreService,
    private trackService: TrackService,
    private notificationsHandlerService: NotificationsHandlerService
  ) {}

  public ngOnInit() {
    this.trackService.track('notifications.panel.init', {
      category: EventCategory.NOTIFICATIONS
    });

    this.setTranslations();
    this.notificationsStore.getNotificationPanelAlerts$.pipe(takeUntil(this.destroy$)).subscribe(notifications => {
      this.alerts = notifications;
      this.cdr.detectChanges();
    });

    this.notificationsStore.getNotificationPanelMessages$.pipe(takeUntil(this.destroy$)).subscribe(notifications => {
      this.messages = notifications;
      this.cdr.detectChanges();
    });

    this.notificationsStore.getNotificationsLoadingState$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      this.notificationsLoading = loading;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public clearAlertsAndBannersNotificationsClicked() {
    this.trackService.track('notifications.panel.clear_alerts', {
      category: EventCategory.NOTIFICATIONS
    });
    this.notificationsHandlerService.clearAlertsAndBannersNotifications();
  }

  public clearMessagesNotificationsClicked() {
    this.trackService.track('notifications.panel.clear_messages', {
      category: EventCategory.NOTIFICATIONS
    });

    this.notificationsHandlerService.clearMessagesNotifications();
  }

  public setTranslations(): void {
    this.translate.translateResourcesInstant(this.resources);
    this.clearAlertsNotifications.title = this.resources.SidebarUserClear;
    this.clearMessagesNotifications.title = this.resources.SidebarUserClear;
    this.cdr.detectChanges();
  }
}
