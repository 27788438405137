import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';

import { SharedModule } from '@common/modules/shared/shared.module';

import { CreateNewAccountComponent } from './connect-to-azure/components/create-new-account/create-new-account.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MainSidebarComponent } from './components/main-sidebar/main-sidebar.component';
import { FaceGateStripService } from './services/face-gate-strip.service';
import { CustomizationSubMenuComponent } from './components/customization-sub-menu/customization-sub-menu.component';
import { SideSubMenuComponent } from './components/side-sub-menu/side-sub-menu.component';
import { EdgeExtensionsSubMenuComponent } from './components/edge-extensions-sub-menu/edge-extensions-sub-menu.component';
import { AccountUnavailableDialogComponent } from './components/account-unavailable-dialog/account-unavailable-dialog.component';

@NgModule({
  declarations: [
    CreateNewAccountComponent,
    SideMenuComponent,
    MainSidebarComponent,
    CustomizationSubMenuComponent,
    SideSubMenuComponent,
    EdgeExtensionsSubMenuComponent,
    AccountUnavailableDialogComponent
  ],
  providers: [FaceGateStripService],
  imports: [CommonModule, SharedModule, FormsModule, MatSortModule],
  exports: [SideMenuComponent, MainSidebarComponent, CreateNewAccountComponent]
})
export class ShellModule {}
