<input #acInput
  (keydown.enter)="handleEnterKeyDown($event)"
  (keyup.enter)="handleEnter(acInput.value)"
  (keyup.esc)="handleEsc()"
  (input)="valueChange()"
  (blur)="onBlur($event)"
  [attr.disabled]="disabled ? '' : null"
  [attr.placeholder]="placeholder"
  [attr.contenteditable]="!disabled"
  value="{{ val }}"
  [attr.name]="title || placeholder"
  [attr.aria-label]="title || placeholder"
  [style.width]="width"
  [style.minWidth]="minWidth"
  [style.font-size.px]="fontSize"
  [ngClass]="{editable: !disabled, spaced: calcTextSpacing, underline: alwaysUnderlineOnEdit}"
  [ngStyle]="{ 'pointer-events': ignoreClickWhenDisabled && disabled ? 'none' : 'auto' }"
  [attr.type]="inputType"
  autocomplete="off"
  class="c-text-field {{class}} {{responsiveState}}"
  [attr.tabindex]="activeContainer ? -1 : 0"
  [attr.vi-inspect]="acInput.value || val" />
<i class="save-person i-vi-ok"
  *ngIf="addApproveAndLoading && !disabled && valueChanged"
  [attr.disabled]="disabled ? '' : null"
  (appAccessibleClick)="handleEnter(acInput.value)"
  (focus)="handleFocus(true)"
  (focusout)="handleFocus(false)"
  [attr.tabindex]="activeContainer ? -1 : 0"
  [attr.aria-label]="resources.ActionableInputSaveChanges"
  [attr.title]="resources.ActionableInputSaveChanges"
  role="button">
</i>
<app-vi-spinner class="input-spinner"
  *ngIf="addApproveAndLoading && loading"
  [size]="'tiny'">
</app-vi-spinner>
