import { IUIEntityInsight } from '../../interfaces';

export interface IUIFace extends IUIEntityInsight {
  thumb?: string;
  description: string;
  referenceId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timelineStyle: any;
  sourceType?: Microsoft.VideoIndexer.Contracts.KnownPersonFaceSourceType;
  sourceId?: string;
  knownPersonId?: string;
  referenceType?: string;
  highQuality?: boolean;
}

export interface IVideoIdToIUIFace {
  [videoId: string]: IVideoIdToIUIFace;
}

export interface IVideoIUIFace {
  [faceType: string]: IUIFace[];
}

export enum UIFaceType {
  people = 'faces'
}
