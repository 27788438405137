import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { defaultBrandCategory } from '../../../../interfaces';

export const brandDefaultAction: IAction = {
  title: '',
  key: 'UploadServiceDefaultName',
  selected: true,
  value: defaultBrandCategory,
  id: 'defaultBrandModel'
};

export const manageBrandCategoriesAction: IAction = {
  title: '',
  key: 'ManageBrandCategories',
  type: UIActionType.NAVIGATE,
  value: CustomizationPage.Brands,
  id: 'manageBrandCategoriesButton',
  icon: 'i-vi-customization'
};
