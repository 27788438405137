import { createAction, props } from '@ngrx/store';

import PersonModel = Microsoft.VideoIndexer.Contracts.PersonModel;

export const loadPersonModels = createAction('[PersonModel/API] Load Person Models', props<{ force?: boolean }>());
export const loadPersonModelsInProgress = createAction('[PersonModel/API] Load Person Models In Progress');
export const failLoadPersonModels = createAction('[PersonModel] Fail load Person Models');
export const upsertPersonModel = createAction('[PersonModel] Upsert Person Model', props<{ model: PersonModel }>());
export const upsertPersonModels = createAction('[PersonModel] Upsert Person Models', props<{ models: PersonModel[] }>());
export const deletePersonModel = createAction('[PersonModel/API] Delete Person Model', props<{ model: PersonModel }>());
export const addPersonModel = createAction('[PersonModel/API] Add Person Model', props<{ model: PersonModel }>());
export const personModelsLoaded = createAction('[PersonModel] Person Models Loaded');
export const clearPersonModels = createAction('[PersonModel] Clear Person Models');
