import { Params } from '@angular/router';

import { IArmAccessTokenResponse, IArmAccountAccessTokenPayload, IArmAccountExtensionAccessTokenPayload, IHttpWrapper } from '../interfaces';
import { getRequestSettings } from '../utils/request.function';

export class ArmAccountApi {
  private apiBaseRoute = '/arm';
  public constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiAuthBase(): string {
    return this.ApiWrapper.GetAuthApiBase() + this.apiBaseRoute;
  }

  private get apiProxy(): string {
    return this.ApiWrapper.GerProxyApiLocation() + this.apiBaseRoute;
  }

  // [HttpGet]
  public getArmAccounts(reqParams?: Params, reqSettings?: object) {
    const url = `${this.apiAuthBase}/accounts`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountContractSlim[]>(url, requestSettings);
  }

  // [HttpGet]
  public getArmAccount(subscriptionId: string, resourceGroupName: string, accountName: string, reqParams?: Params, reqSettings?: object) {
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;
    requestSettings['params']['forceToken'] = true;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.AccountContract>(url, requestSettings);
  }

  // [HttpGet]
  public getArmAccountAccessToken(
    subscriptionId: string,
    resourceGroupName: string,
    accountName: string,
    armAccountAccessTokenPayload: IArmAccountAccessTokenPayload,
    reqSettings?: object
  ) {
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}/accessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, armAccountAccessTokenPayload) as object;

    return this.ApiWrapper.HttpClient.get<IArmAccessTokenResponse>(url, requestSettings);
  }

  // [HttpGet]
  public getArmVideoAccessToken(
    subscriptionId: string,
    resourceGroupName: string,
    accountName: string,
    videoId: string,
    armAccountAccessTokenPayload: IArmAccountAccessTokenPayload,
    reqSettings?: object
  ) {
    // eslint-disable-next-line max-len
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}/videos/${videoId}/accessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, armAccountAccessTokenPayload) as object;

    return this.ApiWrapper.HttpClient.get<IArmAccessTokenResponse>(url, requestSettings);
  }

  // [HttpGet]
  public getArmProjectAccessToken(
    subscriptionId: string,
    resourceGroupName: string,
    accountName: string,
    projectId: string,
    armAccountAccessTokenPayload: IArmAccountAccessTokenPayload,
    reqSettings?: object
  ) {
    // eslint-disable-next-line max-len
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}/projects/${projectId}/accessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, armAccountAccessTokenPayload) as object;

    return this.ApiWrapper.HttpClient.get<IArmAccessTokenResponse>(url, requestSettings);
  }

  // [HttpGet]
  public getArmExtensionAccountAccessToken(
    subscriptionId: string,
    resourceGroupName: string,
    accountName: string,
    armAccountExtensionAccessTokenPayload: IArmAccountExtensionAccessTokenPayload,
    reqSettings?: object
  ) {
    // eslint-disable-next-line max-len
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}/extensionAccessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, armAccountExtensionAccessTokenPayload) as object;

    return this.ApiWrapper.HttpClient.get<IArmAccessTokenResponse>(url, requestSettings);
  }

  // [HttpGet]
  public getArmExtensionVideoAccessToken(
    subscriptionId: string,
    resourceGroupName: string,
    accountName: string,
    videoId: string,
    armAccountExtensionAccessTokenPayload: IArmAccountExtensionAccessTokenPayload,
    reqSettings?: object
  ) {
    // eslint-disable-next-line max-len
    const url = `${this.apiAuthBase}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/accounts/${accountName}/videos/${videoId}/extensionAccessToken`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, armAccountExtensionAccessTokenPayload) as object;

    return this.ApiWrapper.HttpClient.get<IArmAccessTokenResponse>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("{apiInternalBase}/Users/me")]
  public getArmDirectories(reqSettings?: object) {
    const url = `${this.apiAuthBase}/tenants`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.DirectoryContract[]>(url, requestSettings);
  }
}
