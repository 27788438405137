<div class="vi-tag-container">
  <vi-ui-label *ngIf="label"
    [for]="containerId"
    [value]="label">
  </vi-ui-label>
  <div id="containerId"
    class="container">
    <vi-ui-tag *ngFor="let tag of tags; trackBy: trackById"
      [type]="tag.type || 'rest'"
      [content]="tag.content"
      [term]="tag.term"
      [removeTagTitleAttribute]="tag?.removeTagTitleAttribute"
      (closeClicked)="removeTag(tag.id)">
    </vi-ui-tag>
  </div>
</div>