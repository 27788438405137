import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

import { take } from 'rxjs/operators';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { AccessibilityUtilsService } from '../../../accessibility/accessibility-utils.service';
import { resources } from './resources';

@Component({
  selector: 'app-vi-actionable-input',
  templateUrl: './actionable-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./actionable-input.component.scss']
})
export class ActionableInputComponent implements OnInit, OnChanges {
  @Input() public disabled: boolean;
  @Input() public alwaysUnderlineOnEdit: boolean = false;
  @Input() public ignoreClickWhenDisabled: boolean = false;
  @Input() public val;
  @Input() public class = '';
  @Input() public activeContainer = false;
  @Input() public addApproveAndLoading = false;
  @Input() public autoSaveOn = false;
  @Input() public discardOnEsc = false;
  @Input() public loading = false;
  @Input() public focusOnEnable = false;
  @Input() public title = '';
  @Input() public fontSize = 13;
  @Input() public isBold = false;
  @Input() public calcFontFamilyWidth = false;
  @Input() public isHandleBlur: boolean;
  @Input() public placeholder?: string;
  @Input() public minWidth?: number = 40;
  @Input() public maxWidth = 0;
  // take letter spacing into account (accessibility)
  @Input() public calcTextSpacing = false;
  // In case using fixedWidth it ignores minWidth maxWidth inputs
  @Input() public fixedWidth: number;
  @Input() public responsiveState = '';
  @Input() public inputType = 'text';

  @Output() public enter = new EventEmitter<string>();
  @Output() public esc = new EventEmitter<string>();
  @Output() public focus = new EventEmitter<boolean>();
  @Output() public emptyInput = new EventEmitter<string>();
  @Output() public inputChanged = new EventEmitter<boolean>();
  @Output() public inputChangedData = new EventEmitter<string>();

  @ViewChild('acInput', { static: true }) public inputBox: ElementRef;

  // Public
  public valueChanged = false;
  public width = '0px';
  public isSaved = false;
  public resources = resources;
  // Private
  private readonly BORDER_SPACING = 2;
  constructor(
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private focusManagerService: FocusManagerService,
    private accessibilityUtils: AccessibilityUtilsService,
    private elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    private translate: TranslateHelperService
  ) {}

  public ngOnInit() {
    this.translate
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {});
    if (this.fixedWidth) {
      this.width = `${this.fixedWidth}px`;
      this.minWidth = this.fixedWidth;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // Reset value changed
    if (this.disabled || changes.val) {
      this.valueChanged = false;
      this.inputChanged.emit(false);
    }

    // If changed to focus on enable, apply focus on text
    if (this.focusOnEnable && changes.disabled && !changes.disabled.currentValue) {
      this.focusInput();
    }

    if (!this.fixedWidth) {
      this.updateWidth();
    }
  }

  public handleEnterKeyDown(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

  public handleEnter(value: string) {
    this.inputBox.nativeElement.focus();
    // Discard if not valid input
    if (!this.validateInput(value)) {
      this.discard();
      return;
    }

    this.enter.emit(value);
    this.isSaved = true;
  }

  public handleEsc() {
    if (this.discardOnEsc) {
      this.discard();
    }
  }

  public handleFocus(value: boolean) {
    this.focus.emit(value);
  }

  public validateInput(value: string): boolean {
    // Input box contains empty value
    return value && value.trim() !== '';
  }

  public onBlur($event) {
    // when on blur is handled, also handleEnter is also being called.
    const relatedTarget = $event.relatedTarget;

    // If focus moved to related
    if (relatedTarget) {
      const parents = this.accessibilityUtils.getParents(relatedTarget);

      // If blurred to a child - dont trigger blur
      if (parents.indexOf(this.elementRef.nativeElement) !== -1) {
        return;
      }
    }

    // If auto save is on, check that enter haven't called / not during loading
    if (this.autoSaveOn && (!this.isSaved || !this.loading)) {
      // Check if value has been changed, if so - save new value
      const currentInputValue = this.inputBox.nativeElement.value;

      if (this.val !== currentInputValue && this.validateInput(currentInputValue)) {
        // Update new val
        this.val = currentInputValue;

        // Trigger enter
        this.handleEnter(this.val);
        return;
      }
    }

    // In order to prevent 2 event emit, Check if isSaved is true
    if (!this.isHandleBlur || this.isSaved) {
      this.isSaved = false;
      return;
    }

    this.handleEsc();
  }

  public valueChange() {
    const currentInputValue = this.inputBox.nativeElement.value;
    if (!this.fixedWidth) {
      let fontFamily;
      if (this.calcFontFamilyWidth) {
        fontFamily = this.insightsCommonUtilsService.getCalculatedFontFamily(this.inputBox.nativeElement);
      }
      const textWidth =
        this.insightsCommonUtilsService.measureText(
          currentInputValue || this.inputBox.nativeElement.placeholder,
          this.fontSize + (this.isBold ? 1 : 0),
          fontFamily
        ) + this.BORDER_SPACING;
      this.width = this.maxWidth ? `${Math.max(Math.min(this.maxWidth, textWidth), this.minWidth)}px` : `${Math.max(textWidth, this.minWidth)}px`;
    }

    if (currentInputValue === '') {
      this.emptyInput.emit(currentInputValue);
    }
    // If the value has been changed, and not blank
    if (currentInputValue && this.val !== currentInputValue) {
      this.valueChanged = true;
      this.inputChanged.emit(true);
      this.inputChangedData.emit(currentInputValue);
    } else {
      this.valueChanged = false;
      this.inputChanged.emit(false);
    }

    if (!this.fixedWidth) {
      this.updateWidth();
    }
  }

  private focusInput() {
    // Wait until edit enabled and then focus
    setTimeout(() => {
      this.focusManagerService.focusVia(() => {
        return this.inputBox;
      });
    }, 200);
  }

  private discard() {
    if (!this.isSaved || !this.loading) {
      // Check if value has been changed, if so - return to old value
      const currentInputValue = this.inputBox.nativeElement.value;
      if (this.val !== currentInputValue) {
        // Return to old value and blur
        this.inputBox.nativeElement.value = this.val;
        this.valueChanged = false;
        // Update width
        if (!this.fixedWidth) {
          this.updateWidth();
        }
      }

      this.inputBox.nativeElement.blur();
      this.esc.emit();
    }
  }

  private updateWidth() {
    // Calc the value by order of values
    const currentValue = this.val || this.inputBox.nativeElement.value || this.placeholder;
    let fontFamily;
    if (this.calcFontFamilyWidth) {
      fontFamily = this.insightsCommonUtilsService.getCalculatedFontFamily(this.inputBox.nativeElement);
    }
    const textSpacing = this.calcTextSpacing ? this.insightsCommonUtilsService.measureTextSpacing(currentValue, this.fontSize) : 0;
    const textWidth =
      this.insightsCommonUtilsService.measureText(currentValue, this.fontSize + (this.isBold ? 1 : 0), fontFamily) +
      this.BORDER_SPACING +
      textSpacing;
    const totalWidth = this.maxWidth ? Math.min(this.maxWidth, textWidth) : textWidth;
    this.width = `${Math.max(totalWidth, this.minWidth)}px`;
  }
}
