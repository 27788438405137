import { createAction, props } from '@ngrx/store';

import accountMigration = Microsoft.VideoIndexer.Contracts.AmsAccountMigration;
import videoMigrations = Microsoft.VideoIndexer.Contracts.AmsMigrationsListResult;

export const getAmsMigrationStatusStarted = createAction('[Migration/API] Get AMS Migration Status started');

export const getAmsMigrationStatusSuccess = createAction(
  '[Migration/API] Get AMS Migration Status Success',
  props<{ migrationState: accountMigration }>()
);

export const startMigrationStatusInterval = createAction('[Migration/API] Start Migration Status Interval');

export const getMigrationStatusFailed = createAction('[Migration/API] Get Migration Status Failed');

export const loadMoreVideoMigrations = createAction('[Migration/API] Load More Video Migrations');

export const getVideoMigrationStarted = createAction('[Migration/API] Get Video Migrations Started');

export const getVideoMigrationsSucceed = createAction('[Migration/API] Get Video Migrations Succeed', props<{ videoMigrations: videoMigrations }>());

export const getVideoMigrationsFailed = createAction('[Migration/API] Get Video Migrations Failed');

export const resetAmsMigrationState = createAction('[Migration] Reset AMS Migration State');
