import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@common/modules/shared/shared.module';

import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderStripsComponent } from './components/header-strips/header-strips.component';
import { AccountsListButtonComponent } from './components/accounts-list-button/accounts-list-button.component';

@NgModule({
  declarations: [HeaderComponent, UserDetailsComponent, UserMenuComponent, HeaderStripsComponent, AccountsListButtonComponent],
  exports: [HeaderComponent, UserDetailsComponent, UserMenuComponent],
  imports: [CommonModule, SharedModule, RouterModule]
})
export class HeaderModule {}
