import { Injectable } from '@angular/core';

import { AuthService } from '@common/modules/auth/services/auth.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  constructor(private logger: LoggerService, private authService: AuthService, private localStorageService: LocalStorageService) {
    this.logger.log('[LogoutService] init');
  }

  public logout() {
    setTimeout(() => {
      this.exitAccount();
      window.location.replace('/Account/SignOut');
    }, 3000);
  }

  public exitAccount(): void {
    this.authService.logout();
    this.removeLastProvider();
  }

  private removeLastProvider(): void {
    this.localStorageService.removeItem('lastProvider');
    this.localStorageService.removeItem('accountId');
  }
}
