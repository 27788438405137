import { IHttpWrapper } from '../../interfaces';
import { getRequestSettings } from '../../utils/request.function';

import UnknownPersonsJob = Microsoft.VideoIndexer.Contracts.UnknownPersonsJob;
import UnknownPerson = Microsoft.VideoIndexer.Contracts.UnknownPerson;
import UnknownPersonDetails = Microsoft.VideoIndexer.Contracts.UnknownPersonDetails;
import PaginatedResult = Microsoft.VideoIndexer.Contracts.PaginatedResult;

export class CustomizationUnknownPersonsApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiInternalBase(): string {
    return this.ApiWrapper.GetInternalApiBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpPost]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/Job")]
  public submitJob(accountId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/Group`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.post<UnknownPersonsJob>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/Jobs")]
  public getJob(accountId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/Jobs/${accountId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<UnknownPersonsJob | PaginatedResult<UnknownPerson>>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons")]
  public list(accountId: string, skip: number, filterValue: string, reqSettings?: object) {
    const params = { nameForFilter: filterValue };
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons?skip=${skip}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, params) as object;

    return this.ApiWrapper.HttpClient.get<PaginatedResult<UnknownPerson>>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/{unknownPersonId}")]
  public get(accountId: string, unknownPersonId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/${unknownPersonId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.get<UnknownPersonDetails>(url, requestSettings);
  }

  // [HttpPatch]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/{unknownPersonId}")]
  public patch(accountId: string, unknownPersonId: string, params: Partial<UnknownPerson>, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/${unknownPersonId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, params) as object;

    return this.ApiWrapper.HttpClient.patch<UnknownPerson>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/{unknownPersonId}/MoveToKnownPersonModels")]
  public moveToKnownPersonModels(accountId: string, unknownPersonId: string, personName: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/${unknownPersonId}/MoveToKnownPersonModels?personName=${personName}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.put<UnknownPerson>(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/{unknownPersonId}")]
  public delete(accountId: string, unknownPersonId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/${unknownPersonId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete(url, requestSettings);
  }

  // [HttpDelete]
  // [Route("api/v2/Accounts/{accountId}/UnknownPersons/{unknownPersonId}")]
  public deleteFace(accountId: string, unknownPersonId: string, faceId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${accountId}/UnknownPersons/${unknownPersonId}/Faces/${faceId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete(url, requestSettings);
  }
}
