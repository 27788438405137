import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params, ResolveFn } from '@angular/router';

import { filter, take } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { AuthService } from '@common/modules/auth/services/auth.service';

import { CoreStoreService } from '../services/core-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export const AuthenticatedResolver: ResolveFn<Promise<boolean>> = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthenticatedResolverService).resolve();
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedResolverService {
  constructor(private logger: LoggerService, private auth: AuthService, private coreStore: CoreStoreService) {
    this.logger.log('[AuthenticatedResolver] init');
  }

  public resolve(): Promise<boolean> {
    return new Promise(resolve => {
      this.coreStore.routerQueryParams$.pipe(take(1)).subscribe(queryParams => {
        if (this.auth.isAuthenticated()) {
          this.logger.log(`[AuthenticatedResolver] user already authenticated`);
          this.handleUserAlreadyAuthenticated(queryParams);
          return resolve(false);
        }

        return this.coreStore.userDetailsLoaded$
          .pipe(
            filter(loaded => loaded),
            take(1)
          )
          .subscribe(() => {
            if (this.auth.isAuthenticated()) {
              this.logger.log(`[AuthenticatedResolver] userDetailsLoaded user already authenticated`);
              this.handleUserAlreadyAuthenticated(queryParams);
              return resolve(false);
            } else {
              this.logger.log('[AuthenticatedResolver] user not authenticated');
              return resolve(true);
            }
          });
      });
    });
  }

  private handleUserAlreadyAuthenticated(queryParams: Params) {
    this.logger.log('[AuthenticatedResolver] user authenticated');
    const returnUrlParamValue = queryParams?.returnUrl;
    if (returnUrlParamValue) {
      // Check if video link and navigate to player page
      if (returnUrlParamValue.includes(window.location.host)) {
        // Create a new URL object to parse and validate the returnUrl
        let url;
        try {
          url = new URL(returnUrlParamValue);
        } catch (_) {
          // If the URL constructor throws, the URL is invalid
          return;
        }

        // Ensure the URL's origin matches the current location's origin
        if (url.origin !== window.location.origin) {
          return;
        }

        // If the URL is valid and the origins match, it's safe to use
        window.location.replace(returnUrlParamValue);
      }

      // Check if Invite link
      // Remove /? chars from returnUrl value
      // eslint-disable-next-line max-len
      const params = JSON.parse(
        '{"' +
          decodeURI(returnUrlParamValue).replace(/\\/g, '\\\\').replace(/\?|\//g, '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}'
      );
      this.logger.log(`[AuthenticatedResolver] returnUrlParam: ${params}`);
      this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`], params);
    } else {
      this.logger.log(`[AuthenticatedResolver] params: ${queryParams}`);
      this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`, queryParams]);
    }
  }
}
