<div class="summary">
  <div class="strips-container">
    <!-- Error strip -->
    <vi-ui-alert-message *ngIf="showReIndexError"
      [type]="AlertMessageType.ERROR"
      [content]="error?.content"
      [linkText]="error?.linkText"
      (buttonClicked)="error?.onClick()">
    </vi-ui-alert-message>
  </div>
  <div class="title">{{resources?.ReIndexSummaryTitle + ':'}}</div>
</div>
<div class="details">
  <div class="row">
    <vi-ui-label [value]="resources?.IndexingSingleFileLabel + ':'"></vi-ui-label>
    <div>{{file}}</div>
  </div>
  <vi-indexing-summary></vi-indexing-summary>
</div>