import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import { catchError, throwError } from 'rxjs';

import { EventCategory } from '@common/modules/core/services/track';

import { TrackService } from './../../core/services/track/track.service';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(public router: Router, private trackService: TrackService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        const urlObject = new URL(error?.url);
        const url = `${urlObject.protocol}//${urlObject.host}${urlObject.pathname}`;

        this.trackService.track('http.request.error', {
          category: EventCategory.HTTP,
          data: {
            status: error?.status,
            name: error?.name,
            method: req?.method,
            url,
            error: error?.error
          }
        });

        return throwError(() => error);
      })
    );
  }
}
