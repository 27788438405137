import { NotificationLevel } from '@common/modules/core/services/toast/interfaces';

import { IAction } from '../../interfaces';

export enum MessageType {
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export interface IStripData {
  id?: number;
  text?: string;
  show: boolean;
  action?: IAction;
  hasSpinner?: boolean;
  hasCloseButton?: boolean;
  iconClass?: string;
  type?: string;
  actionCallback?: ($event) => void;
  closeCallback?: ($event) => void;
  linkCallback?: ($event) => void;
  messageType?: MessageType;
  notificationLevel?: NotificationLevel;
  trackingName?: string;
  loadingProgress?: number;
}
