<div class="tag"
  [ngClass]="type"
  tabindex="-1">
  <span class="content"
    role="text"
    [attr.aria-label]="content">
    <span class="term"
      *ngIf="term"
      role="text"
      [attr.aria-label]="term">
      {{formattedTerm}}:
    </span>
    {{content}}
  </span>
  <button class="icon"
    *ngIf="enableRemove"
    [attr.aria-label]="removeTagTitleAttribute"
    [attr.title]="removeTagTitleAttribute"
    (viUiAccessibleClick)="onRemoveTag()">
    <i class="i-vi-close-big">
    </i>
  </button>
</div>