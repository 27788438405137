export const resources = {
  SettingsSendNewsletter: '',
  SettingsAutoCompleteTitle: '',
  SettingsSamplesTabLabel: '',
  SettingsShowGalleryByDefaultTitle: '',
  SettingsLanguageSettingsTitle: '',
  SettingsAutoPlayTitle: '',
  Themes: '',
  SettingsShowArcExtensions: ''
};
