import { UISpeechObjectState } from '../../interfaces';

import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;
import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;

export interface ISpeechDatasetDetails extends Omit<SpeechDataset, 'status'> {
  report?: ISpeechDatasetReport;
  normalizedText?: string;
  languageData?: string;
  filesLoaded?: boolean;
  filesError?: boolean;
  status: UISpeechObjectState;
  disabled?: boolean;
  disabledReason?: DatasetDisabledReason;
  selected?: boolean;
}

export interface ISpeechDatasetReport {
  acceptedLineCount: number;
  rejectedLineCount: number;
  details: ISpeechDatasetReportDetails[];
}

export interface ISpeechDatasetReportDetails {
  filename: string;
  inputText: string;
  message: string;
  status: string;
}

export enum DatasetDisabledReason {
  PronunciationAlreadySelected = 'PronunciationAlreadySelected',
  NotInSelectedLanguage = 'NotInSelectedLanguage'
}

export interface ISpeechModel extends SpeechModel {}

export interface SelectedDatasets {
  pronunciationId: string;
  languageIds: string[];
  locale: string;
}

export interface SpeechErrorTexts {
  title: string;
  content: string;
}
