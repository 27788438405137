import { createSelector } from '@ngrx/store';

import { selectGalleryState } from '../reducers';

export const getLibraryState = createSelector(selectGalleryState, state => state.library);

export const isLibraryLoaded = createSelector(getLibraryState, state => state.loaded);

export const isLibraryLoading = createSelector(getLibraryState, state => state.loading);

export const libraryError = createSelector(getLibraryState, state => state.error);

export const libraryErrorType = createSelector(getLibraryState, state => state.errorType);
