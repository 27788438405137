<section class="signup error-page" id="loginError">
  <div class="container" data-grid="container">
      <div class="logging-out">
          <div class="e404">
              <h1 role="text" [attr.aria-label]="resources?.LoginErrorPageMessageHeader"
                  [attr.title]="resources?.LoginErrorPageMessageHeader">{{resources?.LoginErrorPageMessageHeader}}
              </h1>
          </div>
          <div class="error-content">

              <p *ngIf="!errorType">
                  <span role="text" [attr.aria-label]="resources?.LoginErrorPageMessage"
                      [attr.title]="resources?.LoginErrorPageMessage">{{resources?.LoginErrorPageMessage}}</span>
                  <br>
                  <span role="text" [attr.aria-label]="resources?.LoginErrorPageMessageLine2"
                      [attr.title]="resources?.LoginErrorPageMessageLine2">{{resources?.LoginErrorPageMessageLine2}}</span>
              </p>

              <p *ngIf="errorType" [ngSwitch]="errorType">
                  <ng-container
                      *ngSwitchCase="APIErrors?.USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER">
                      <span role="text"
                          [innerHTML]="resources?.VILoginError_USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER"
                          [attr.aria-label]="resources?.VILoginError_USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER_Label"
                          [attr.title]="resources?.VILoginError_USER_EMAIL_ALREADY_REGISTERED_FROM_ANOTHER_IDENTITY_PROVIDER_Label">
                      </span>
                  </ng-container>
              </p>

              <div class="small-text">
                <br>
                  <p>
                      <span role="text" *ngIf="traceId" [innerHTML]="resources?.VILoginErrorPageMessageLine3"
                          [attr.aria-label]="resources?.VILoginErrorPageMessageLine3Label"
                          [attr.title]="resources?.VILoginErrorPageMessageLine3Label"></span>
                      <br><br>
                      <span role="text" [attr.aria-label]="resources?.LoginErrorPageMessageLine4" *ngIf="traceId"
                          [attr.title]="resources?.LoginErrorPageMessageLine4">{{resources.LoginErrorPageMessageLine4}}</span>
                  </p>
              </div>
          </div>
          <div class="retry-link">
              <a *ngIf="redirectUrl" href="{{redirectUrl}}" role="link"
                  [attr.aria-label]="resources?.LoginErrorPageMessageLine5"
                  [attr.title]="resources?.LoginErrorPageMessageLine5"
                  target="_blank">{{resources?.LoginErrorPageMessageLine5}}</a>
          </div>
      </div>
  </div>
</section>
