import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AccountPermission } from '@common/modules/shared/interfaces';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import * as AccountsActions from '../actions/accounts.actions';
import { AccountStateOptions } from '../interfaces';

import AccountContractSlim = Microsoft.VideoIndexer.Contracts.AccountContractSlim;
import AccountContract = Microsoft.VideoIndexer.Contracts.AccountContract;
import AccountSettings = Microsoft.VideoIndexer.Contracts.AccountSettings;

export interface IState extends EntityState<AccountContractSlim> {
  // additional entities state properties
  selectedAccountId: string;
  accountsFilterValue: string;
  loaded: boolean;
  selectedAccountContract: AccountContract;
  selectedAccountLoaded: boolean;
  saving: boolean;
  deleteInProgress: boolean;
  error: boolean;
  errorType: APIErrors;
  armAccountsLoaded: boolean;
  isArmAccountNeededForInit: boolean;
  armAccountsError: boolean;
  selectedAccountSettings: AccountSettings;
  selectedAccountAccessTokenPermission: AccountPermission;
}

function selectAccountId(a: AccountContractSlim): string {
  return a.id;
}

export const adapter: EntityAdapter<AccountContractSlim> = createEntityAdapter<AccountContractSlim>({
  selectId: selectAccountId
});

const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedAccountId: null,
  accountsFilterValue: '',
  loaded: false,
  selectedAccountContract: null,
  saving: false,
  deleteInProgress: false,
  error: false,
  errorType: null,
  selectedAccountLoaded: false,
  armAccountsLoaded: false,
  armAccountsError: false,
  isArmAccountNeededForInit: true,
  selectedAccountSettings: null,
  selectedAccountAccessTokenPermission: null
});

const accountsReducer = createReducer(
  initialState,
  on(AccountsActions.addAccount, (state, { account }) => {
    return adapter.addOne(account, state);
  }),
  on(AccountsActions.upsertAccounts, (state, { accounts }) => {
    return adapter.upsertMany(accounts, state);
  }),
  on(AccountsActions.addAccounts, (state, { accounts }) => {
    return adapter.addMany(accounts, {
      ...state,
      loaded: true
    });
  }),
  on(AccountsActions.addSelectedAccount, (state, { account }) => {
    return {
      ...state,
      selectedAccountContract: account,
      selectedAccountLoaded: true,
      error: false,
      errorType: null
    };
  }),
  on(AccountsActions.updateAccount, (state, { account }) => {
    return adapter.updateOne(account, state);
  }),
  on(AccountsActions.updateSelectedAccount, (state, { account }) => {
    return {
      ...state,
      selectedAccountContract: account,
      saving: false
    };
  }),
  on(AccountsActions.updateAccountState, (state, { saving, error }) => {
    return {
      ...state,
      saving: saving,
      error: error
    };
  }),
  on(AccountsActions.updateAccounts, (state, { accounts }) => {
    return adapter.updateMany(accounts, state);
  }),
  on(AccountsActions.selectAccount, (state, { id }) => {
    return {
      ...state,
      selectedAccountLoaded: id === state.selectedAccountId,
      selectedAccountId: id ? id : (state.ids[0] as string)
    };
  }),
  on(AccountsActions.loadLastArmAccount, (state, { accountId }) => {
    return {
      ...state,
      selectedAccountId: accountId
    };
  }),
  on(AccountsActions.leaveAccount, state => {
    return {
      ...state,
      deleteInProgress: true,
      error: false
    };
  }),
  on(AccountsActions.deleteOwnAccount, state => {
    return {
      ...state,
      deleteInProgress: true,
      error: false
    };
  }),
  on(AccountsActions.recoverOwnAccount, state => {
    return {
      ...state,
      deleteInProgress: true,
      error: false
    };
  }),
  on(AccountsActions.updateAccountContract, (state, { account, newAccount }) => {
    const update = { id: account.id, changes: newAccount };
    return adapter.updateOne(update, {
      ...state,
      saving: true
    });
  }),
  on(AccountsActions.updateMediaServicesAccount, state => {
    return {
      ...state,
      saving: true,
      error: false
    };
  }),
  on(AccountsActions.addSelectedAccountFailed, AccountsActions.addSelectedArmAccountFailed, (state, { errorType }) => {
    return {
      ...state,
      error: true,
      selectedAccountContract: null,
      selectedAccountLoaded: true,
      errorType
    };
  }),
  on(AccountsActions.deleteAccount, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(AccountsActions.deleteAccounts, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(AccountsActions.deleteOwnAccountSuccess, state => {
    return {
      ...state,
      selectedAccountContract: {
        ...state.selectedAccountContract,
        state: AccountStateOptions.PENDING
      },
      deleteInProgress: false
    };
  }),
  on(AccountsActions.recoverOwnAccountSuccess, state => {
    return {
      ...state,
      selectedAccountContract: {
        ...state.selectedAccountContract,
        state: AccountStateOptions.ACTIVE
      },
      deleteInProgress: false
    };
  }),
  on(AccountsActions.loadAccounts, (state, { accounts }) => {
    return adapter.setAll(accounts, state);
  }),
  on(AccountsActions.clearAccounts, state => {
    return adapter.removeAll({ ...state, selectedAccountId: null });
  }),
  on(AccountsActions.zeroAccountsLoaded, state => {
    return {
      ...state,
      loaded: true
    };
  }),
  on(AccountsActions.loadAccountsFailed, state => {
    return {
      ...state,
      loaded: true,
      armAccountsLoaded: true
    };
  }),
  on(AccountsActions.loadArmAccountsSuccess, (state, { accounts }) => {
    return adapter.upsertMany(accounts, {
      ...state,
      armAccountsLoaded: true
    });
  }),
  on(AccountsActions.loadArmAccountsNotNeeded, state => {
    return {
      ...state,
      armAccountsLoaded: true
    };
  }),
  on(AccountsActions.loadArmAccountsFailed, state => {
    return {
      ...state,
      armAccountsError: true
    };
  }),
  on(AccountsActions.addSelectedArmAccount, (state, { account }) => {
    return adapter.addOne(account, {
      ...state,
      selectedAccountContract: account,
      selectedAccountId: account.id,
      selectedAccountLoaded: true,
      isArmAccountNeededForInit: false,
      errorType: null
    });
  }),
  on(AccountsActions.loadArmAccountNotNeededForInit, state => {
    return {
      ...state,
      isArmAccountNeededForInit: false
    };
  }),
  on(AccountsActions.addAccountSettings, (state, { settings }) => {
    return {
      ...state,
      selectedAccountSettings: settings
    };
  }),
  on(AccountsActions.loadAccountAccessTokenPermission, (state, { permission }) => {
    return {
      ...state,
      selectedAccountAccessTokenPermission: permission
    };
  }),
  on(AccountsActions.setAccountsFilterValue, (state, { value }) => {
    return {
      ...state,
      accountsFilterValue: value
    };
  }),
  on(AccountsActions.clearAccountsFilterValue, state => {
    return {
      ...state,
      accountsFilterValue: ''
    };
  }),
  on(AccountsActions.updateFaceGateFeaturesSuccess, (state, { limitedAccessFeatures }) => {
    return {
      ...state,
      selectedAccountContract: {
        ...state.selectedAccountContract,
        limitedAccessFeatures: limitedAccessFeatures
      }
    };
  }),
  on(AccountsActions.updateAccountQuota, (state, { accountQuota }) => {
    return {
      ...state,
      selectedAccountContract: {
        ...state.selectedAccountContract,
        quotaUsage: accountQuota
      }
    };
  })
);

export function reducer(state: IState, action: Action) {
  return accountsReducer(state, action);
}
