import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ShimmerElementType } from './interfaces';

@Component({
  selector: 'vi-ui-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShimmerComponent implements OnInit {
  @Input() elementType: ShimmerElementType;
  @Input() numOfElements: number;

  public elements: number[];

  public ShimmerElementType = ShimmerElementType;

  public ngOnInit() {
    this.elements = new Array(this.numOfElements);
  }
}
