import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';

import { VIUIModule } from '@vi-ui/core';

import { ActionButtonComponent } from './components/action-button/action-button.component';
import { ActionableInputComponent } from './components/actionable-input/actionable-input.component';
import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { CheckBoxActionMenuComponent } from './components/check-box-action-menu/check-box-action-menu.component';
import { DownloadClosedCaptionsComponent } from './components/download-menu/components/download-closed-captions/download-closed-captions.component';
import { RoleBasedViewControlWrapperComponent } from './components/role-based-view-control-wrapper/role-based-view-control-wrapper.component';
import { NumericPrefixTitleCasePipe } from './pipes/numeric-prefix-title.pipe';
import { ElementResizeDirective } from './directives/element-resize.directive';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { PermissionService } from './services/permission.service';
import { JsonPrettifyPipe } from './pipes/json-prettify/json-prettify.pipe';
import { CheckboxWithLabelComponent } from './components/checkbox-with-label/checkbox-with-label.component';
import { DescriptionComponent } from './components/description/description.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogService } from './components/dialog/dialog.service';
import { DownloadMenuComponent } from './components/download-menu/download-menu.component';
import { InternalFaceGateFormComponent } from './components/internal-face-gate-form/internal-face-gate-form.component';
import { DynamicTooltipComponent } from './components/dynamic-tooltip/dynamic-tooltip.component';
import { ExpandedItemComponent } from './components/expanded-item/expanded-item.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { EmbedFooterComponent } from './components/footer/embed-footer.component';
import { InsightsImagesComponent } from './components/insights-images/insights-images.component';
import { InsightsImagesService } from './components/insights-images/insights-images.service';
import { NotificationTooltipComponent } from './components/notification-tooltip/notification-tooltip.component';
import { PersonaComponent } from './components/persona/persona.component';
import { PresetsComponent } from './components/presets/presets.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { SearchbarService } from './components/searchbar/services/searchbar.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StripComponent } from './components/strip/strip.component';
import { TablistComponent } from './components/tablist/tablist.component';
import { DebounceDirective } from './directives/debounce.directive';
import { DynamicTooltipDirective } from './directives/dynamic-tooltip.directive';
import { HighlightDirective } from './directives/highlight.directive';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { ImageIconFallbackDirective } from './directives/image-icon-fallback.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { ImageSrcPipe } from './pipes/image.pipe';
import { TitleCasePipe } from './pipes/title.pipe';
import { DataService } from './services/data.service';
import { LocalStorageService } from './services/local-storage.service';
import { UtilsService } from './services/utils.service';
import { AccessibilityModule } from '../accessibility/accessibility.module';
import { ActionLinkComponent } from '../shared/components/action-link/action-link.component';
import { TextReadMoreDirective } from './directives/text-read-more.directive';
import { ShowIfEdgeDirective } from './directives/show-if-edge.directive';
import { ShowIfHasEdgeExtensionsDirective } from './directives/show-if-has-edge-extensions.directive';
import { SortMenuComponent } from './components/sort-menu';
import { FluentUiSelectComponent } from './components/fluent-ui-select/fluent-ui-select.component';
import { ShowIfAmslessDirective } from './directives/show-if-amsless.directive';

@NgModule({
  imports: [VIUIModule, CommonModule, FormsModule, AccessibilityModule, MatDialogModule, MatSliderModule, MatProgressBarModule],
  declarations: [
    SearchbarComponent,
    PresetsComponent,
    ActionsMenuComponent,
    ActionButtonComponent,
    SortMenuComponent,
    NotificationTooltipComponent,
    ActionableInputComponent,
    DescriptionComponent,
    DialogComponent,
    SpinnerComponent,
    PersonaComponent,
    InsightsImagesComponent,
    ImageFallbackDirective,
    FluentUiSelectComponent,
    ImageIconFallbackDirective,
    DebounceDirective,
    CheckBoxActionMenuComponent,
    ActionLinkComponent,
    StripComponent,
    AlertMessageComponent,
    DownloadMenuComponent,
    EmbedFooterComponent,
    HighlightDirective,
    StopPropagationDirective,
    FilterItemComponent,
    FilterButtonComponent,
    TablistComponent,
    TitleCasePipe,
    NumericPrefixTitleCasePipe,
    ExpandedItemComponent,
    ImageSrcPipe,
    JsonPrettifyPipe,
    DownloadClosedCaptionsComponent,
    ElementResizeDirective,
    RoleBasedViewControlWrapperComponent,
    EmptyStateComponent,
    CheckboxWithLabelComponent,
    InternalFaceGateFormComponent,
    DynamicTooltipComponent,
    DynamicTooltipDirective,
    ShowIfEdgeDirective,
    ShowIfAmslessDirective,
    TextReadMoreDirective,
    ShowIfHasEdgeExtensionsDirective
  ],
  providers: [DataService, DialogService, InsightsImagesService, SearchbarService, UtilsService, LocalStorageService, PermissionService],
  exports: [
    VIUIModule,
    FormsModule,
    SearchbarComponent,
    PresetsComponent,
    ActionsMenuComponent,
    ActionButtonComponent,
    SortMenuComponent,
    NotificationTooltipComponent,
    ActionableInputComponent,
    DescriptionComponent,
    DialogComponent,
    SpinnerComponent,
    PersonaComponent,
    InsightsImagesComponent,
    CheckBoxActionMenuComponent,
    ImageFallbackDirective,
    ImageIconFallbackDirective,
    DebounceDirective,
    FluentUiSelectComponent,
    AccessibilityModule,
    MatDialogModule,
    MatProgressBarModule,
    ActionLinkComponent,
    StripComponent,
    AlertMessageComponent,
    DownloadMenuComponent,
    EmbedFooterComponent,
    HighlightDirective,
    StopPropagationDirective,
    FilterItemComponent,
    FilterButtonComponent,
    TablistComponent,
    TitleCasePipe,
    NumericPrefixTitleCasePipe,
    ExpandedItemComponent,
    ImageSrcPipe,
    JsonPrettifyPipe,
    ElementResizeDirective,
    RoleBasedViewControlWrapperComponent,
    EmptyStateComponent,
    CheckboxWithLabelComponent,
    DynamicTooltipDirective,
    TextReadMoreDirective,
    ShowIfEdgeDirective,
    ShowIfAmslessDirective,
    ShowIfHasEdgeExtensionsDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
