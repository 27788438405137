import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { combineLatestWith } from 'rxjs/operators';
import { Observable, skip, take, withLatestFrom } from 'rxjs';

import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { SpeechPageStoreService } from '../../../customization-data/services/speech/speech-page-store.service';
import { resources } from './resources';
import { ISubMenuItem } from '../side-sub-menu/interfaces';

@Component({
  selector: 'vi-customization-sub-menu',
  templateUrl: './customization-sub-menu.component.html',
  styleUrls: ['./customization-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizationSubMenuComponent extends FocusableComponent implements OnInit {
  public menuItems: ISubMenuItem<CustomizationPage>[];
  public selectedPage$: Observable<CustomizationPage>;

  private resources = resources;
  private isSpeechEnabled$: Observable<boolean>;

  constructor(
    private translateHelper: TranslateHelperService,
    private coreStoreService: CoreStoreService,
    private cdr: ChangeDetectorRef,
    private focusManager: FocusManagerService,
    private speechPageStoreService: SpeechPageStoreService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.selectedPage$ = this.coreStoreService.getSelectedCustomizationPage();
    this.isSpeechEnabled$ = this.speechPageStoreService.isSpeechEnabled();

    this.translateHelper
      .translateResources(this.resources)
      .pipe(take(1), withLatestFrom(this.selectedPage$), combineLatestWith(this.isSpeechEnabled$))
      .subscribe(([[, selectedPage], isSpeechEnabled]) => {
        this.initMenuOptions(selectedPage, isSpeechEnabled);
        this.cdr.detectChanges();
        this.focusManager.focusByQuery(`.${selectedPage?.toLocaleLowerCase()}`, this.cdr);
      });

    this.selectedPage$.pipe(skip(1), combineLatestWith(this.isSpeechEnabled$)).subscribe(([newPage, isSpeechEnabled]) => {
      this.initMenuOptions(newPage, isSpeechEnabled);
      this.cdr.detectChanges();
    });
  }

  public handleSelectPage(item: ISubMenuItem<CustomizationPage>) {
    this.coreStoreService.navigateToCustomization(item.value);
  }

  private initMenuOptions(selectedPage: CustomizationPage, isSpeechEnabled: boolean) {
    this.menuItems = [
      {
        value: CustomizationPage.Language,
        selected: selectedPage === CustomizationPage.Language,
        text: isSpeechEnabled ? this.resources.LanguageClassic : this.resources.TabsLanguage,
        class: CustomizationPage.Language?.toLocaleLowerCase()
      },
      {
        value: CustomizationPage.LanguageId,
        selected: selectedPage === CustomizationPage.LanguageId,
        text: this.resources.TabsLanguageID,
        class: CustomizationPage.LanguageId?.toLocaleLowerCase()
      },
      {
        value: CustomizationPage.People,
        selected: selectedPage === CustomizationPage.People,
        text: this.resources.TabsFaces,
        class: CustomizationPage.People?.toLocaleLowerCase()
      },
      {
        value: CustomizationPage.Brands,
        selected: selectedPage === CustomizationPage.Brands,
        text: this.resources.TabsBrands,
        class: CustomizationPage.Brands?.toLocaleLowerCase()
      }
    ];

    if (isSpeechEnabled) {
      const speechOption: ISubMenuItem<CustomizationPage> = {
        value: CustomizationPage.Speech,
        selected: selectedPage === CustomizationPage.Speech,
        text: this.resources.SpeechNew,
        class: CustomizationPage.Speech?.toLocaleLowerCase()
      };
      this.menuItems.unshift(speechOption);
    }
  }
}
