export const resources = {
  Language_From_Edits_Header: '',
  LabelUnknown: '',
  LabelCrisFromEditsEditFrom: '',
  UtilsServiceJustNow: '',
  FacesCustomizationEnterModelName: '',
  FaceImageTitle: '',
  FacesName: '',
  FacesModelName: '',
  FacesCustomizationExpandMenuTitle: ''
};
