<!-- Loading -->
<div class="spinner-container"
  *ngIf="isLoading">
  <app-vi-spinner [label]="resources.IndexingSummaryLoadingPlaceholder"
    [labelSize]="'small'">
  </app-vi-spinner>
</div>
<div class="error-container"
  *ngIf="showError">
  <i class="i-vi-illustration-error error"></i>
  <vi-empty-state class="block-message"
    [title]="resources?.IndexingSummaryFailedTitle"
    [content]="resources?.InsightsTranslationErrorGetIndexDescription"
    [shouldShowIcon]="false">
  </vi-empty-state>
</div>
<!-- Indexing form -->
<div class="content-container"
  *ngIf="!isLoading && !showError">
  <!-- Main View -->
  <main class="main-view">
    <div class="indexing-thumbnail-wrapper">
      <figure class="thumbnail"
        tabindex="-1">
        <img [src]="(dataset.videoThumbnailUrl | imageSrc: dataset.accountId | async)"
          [alt]="resources.GalleryListItemMediaThumbnail"
          [appViImageIconFallback]="thumbnailClass"
          [appViImageIconFallbackRadius]="'0'"
          tabindex="-1">
        <div class="figcaption-wrapper">
          <vi-item-figcaption [item]="data"
            [show]="true"
            [activeContainer]="true">
          </vi-item-figcaption>
        </div>
      </figure>
      <div class="text-content">
        <span class="video-title">{{dataset?.videoTitle}}</span>
        <div class="description">
          <div class="setting"
            *ngFor="let generalSetting of dataset?.videoDescription">
            <ng-container *ngIf="generalSetting.value">
              <span class="title">{{generalSetting.text}}:</span>
              <span class="value">{{generalSetting.value}}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="indexing-settings">
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: dataset?.indexingSettingsDetails }"></ng-container>
    </div>

    <ng-template #recursiveList
      let-list>
      <ng-container *ngFor="let setting of list">
        <div class="setting"
          *ngIf="setting.value">
          <span class="title">{{setting.text}}:</span>
          <span class="value">{{setting.value}}</span>

          <div *ngIf="setting.value && setting.children"
            [ngClass]="setting.childrenClassName">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: setting.children }"></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </main>
</div>