import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as UnknownPersonActions from '../../actions/people/unknown-person.actions';
import * as fromUnknownPersons from '../../selectors/people/unknown-person.selectors';
import { IState } from '../../../core/reducers';

@Injectable()
export class UnknownPersonStoreService {
  constructor(private readonly store: Store<IState>) {}

  // Selectors
  public getState() {
    return this.store.select(fromUnknownPersons.getState);
  }

  public getPaginationState() {
    return this.store.select(fromUnknownPersons.getPaginationState);
  }

  public getJob() {
    return this.store.select(fromUnknownPersons.getJob);
  }

  public getList() {
    return this.store.select(fromUnknownPersons.list);
  }

  public getSelected$() {
    return this.store.select(fromUnknownPersons.getSelected);
  }

  public getIsUpdating$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.getIsUpdating);
  }

  public getIsLoaded$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.getIsLoaded);
  }

  public getIsJobLoaded$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.getIsJobLoaded);
  }

  public getIsTableLoaded$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.getIsTableLoaded);
  }

  public getIsLoadMoreError$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.isLoadMoreError);
  }

  public getIsSearchError$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.isSearchError);
  }

  public getIsGetListError$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.isGetListError);
  }

  public getIsSearching$(): Observable<boolean> {
    return this.store.select(fromUnknownPersons.getIsSearching);
  }

  // Actions
  public selectUnknownPersonsTab() {
    this.store.dispatch(UnknownPersonActions.selectUnknownPersonsTab());
  }

  public getUnknownPersonsJob() {
    this.store.dispatch(UnknownPersonActions.getUnknownPersonsJob());
  }

  public deleteUnknownPerson(id: string) {
    this.store.dispatch(UnknownPersonActions.deleteUnknownPerson({ id }));
  }

  public listUnknownPersons(searchValue?: string) {
    const payload = searchValue ? { searchValue } : {};
    this.store.dispatch(UnknownPersonActions.listUnknownPersons(payload));
  }

  public submitUnknownPersonsJob() {
    this.store.dispatch(UnknownPersonActions.submitUnknownPersonsJob());
  }

  public openUnknownPersonDetailsDialog(id: string) {
    this.store.dispatch(UnknownPersonActions.openUnknownPersonDetailsDialog({ id }));
  }

  public openUnknownPersonDeleteDialog(personIds: string[]) {
    this.store.dispatch(UnknownPersonActions.openDeleteUnknownPeopleDialog({ personIds }));
  }

  public searchUnknownPersons(searchValue: string) {
    this.store.dispatch(UnknownPersonActions.searchUnknownPersons({ searchValue }));
  }

  public clearTableListError() {
    this.store.dispatch(UnknownPersonActions.clearTableListError());
  }
}
