import { notificationTemplates } from '@common/modules/core/services/toast/notifications';
import {
  NotificationLevel,
  NotificationMessageType,
  NotificationType,
  NotificationIcon,
  IBaseNotification
} from '@common/modules/core/services/toast/interfaces';

export function getNotificationsLevel(notification: IBaseNotification): NotificationLevel {
  if (notification.messageType === NotificationMessageType.Custom) {
    return notification.level;
  }

  return notificationTemplates[notification.messageType].level;
}

export function getNotificationsType(notification: IBaseNotification): NotificationType {
  if (notification.messageType === NotificationMessageType.Custom) {
    return notification.type;
  }

  return notificationTemplates[notification.messageType].type;
}

export function getNotificationIcon(notification: IBaseNotification): NotificationIcon {
  if (notification.messageType !== NotificationMessageType.Custom) {
    return notificationTemplates[notification.messageType].icon;
  }

  switch (notification.level) {
    case NotificationLevel.Critical:
      return NotificationIcon.Error;
    case NotificationLevel.Error:
      return NotificationIcon.Error;
    case NotificationLevel.Success:
      return NotificationIcon.Success;
    case NotificationLevel.Warning:
      return NotificationIcon.Warning;
    case NotificationLevel.Info:
      return NotificationIcon.Info;
    default:
      return NotificationIcon.None;
  }
}
