import { IAction } from '@common/modules/shared/interfaces';
import { FACE_RECOGNITION_FORM, VI_SURVEY } from '@common/modules/shared/links';

import { UIHelpActionType } from './interfaces';

export const helpActionsList: IAction[] = [
  {
    title: 'Documentation',
    key: 'HeaderDocumentation',
    type: UIHelpActionType.DOCUMENTATION,
    value: 'https://aka.ms/vi-docs',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'documentation',
    icon: 'i-vi-open'
  },
  {
    title: 'Support',
    key: 'HeaderHelpLinkSupport',
    type: UIHelpActionType.SUPPORT,
    value: 'Support',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'support',
    icon: 'i-vi-open'
  },
  {
    title: 'Survey',
    key: 'HelpSurvey',
    type: UIHelpActionType.SURVEY,
    value: VI_SURVEY,
    selected: false,
    selectable: false,
    isLink: true,
    id: 'survey',
    icon: 'i-vi-open'
  },
  {
    title: '',
    key: 'FaceRecognitionFormHelpLink',
    type: UIHelpActionType.FACE_RECOGNITION_FORM,
    value: FACE_RECOGNITION_FORM,
    selected: false,
    selectable: false,
    isLink: true,
    id: 'faceRecognitionForm',
    icon: 'i-vi-open'
  },
  {
    title: '',
    key: 'FaceGatePopupTitle',
    type: UIHelpActionType.INTERNAL_FACE_GATE_POPUP,
    value: '#',
    selected: false,
    selectable: false,
    id: 'faceGatePopupLink'
  },
  {
    title: 'Give Feedback',
    key: 'HeaderHelpLinkGiveFeedback',
    type: UIHelpActionType.GIVE_FEEDBACK,
    value: 'https://aka.ms/vi-feedback',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'giveFeedback',
    icon: 'i-vi-open'
  }
];

export const referencesActionList: IAction[] = [
  {
    title: 'Blog',
    key: 'HeaderBlog',
    type: UIHelpActionType.BLOG,
    value: 'https://aka.ms/vi-blog',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'blog',
    icon: 'i-vi-open'
  },
  {
    title: 'API Reference',
    key: 'HeaderAPIReference',
    type: UIHelpActionType.API_REFERENCE,
    value: 'https://aka.ms/viapi',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'apiReference',
    icon: 'i-vi-open'
  },
  {
    title: 'About Video Indexer',
    key: 'HeaderAboutVideoIndexer',
    type: UIHelpActionType.ABOUT_VI,
    value: '#',
    selected: false,
    selectable: false,
    id: 'aboutVi'
  },
  {
    title: 'Pricing',
    key: 'HeaderPricing',
    type: UIHelpActionType.PRICING,
    value: 'https://aka.ms/vi-pricing',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'pricing',
    icon: 'i-vi-open'
  },
  {
    title: 'Privacy Statement',
    key: 'FooterPrivacyStatement',
    type: UIHelpActionType.PRIVACY_STATEMENT,
    value: 'PrivacyStatement',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'privacyStatement',
    icon: 'i-vi-open'
  },
  {
    title: 'Service Agreement',
    key: 'FooterServicesAgreement',
    type: UIHelpActionType.SERVICES_AGREEMENT,
    value: 'ServiceAgreement',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'serviceAgreement',
    icon: 'i-vi-open'
  },
  {
    title: 'Accessibilité : partiellement conforme',
    key: 'fr-fr',
    type: UIHelpActionType.ACCESSIBILITY,
    value: 'https://aka.ms/accessibility-statement-france',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'a11yConformance-france',
    icon: 'i-vi-open'
  },
  {
    title: 'Dichiarazione di accessibilità',
    key: 'it-it',
    type: UIHelpActionType.ACCESSIBILITY,
    value: 'https://aka.ms/accessibility-statement-italy',
    selected: false,
    selectable: false,
    isLink: true,
    id: 'a11yConformance-italy',
    icon: 'i-vi-open'
  }
];
