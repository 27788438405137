import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { EMPTY, Observable, first, from, fromEvent, mergeWith, throwError, timer, tap } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, timeout } from 'rxjs/operators';

import { cloneDeep, find, orderBy, sortBy } from 'lodash-es';

import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { IUIInsightJsonKey } from '@common/modules/insights-common/interfaces';
import { UtilsService } from '@common/modules/shared/services/utils.service';

import { ILanguage } from '../../../../apps/web/src/core/interfaces';
import {
  ICaptionsRequestParams,
  IGetBoundingBoxPageTimeRequestParams,
  IGetIndexRequestParams,
  IGetObservedPeoplePageTimeRequestParams,
  IGetStreamingUrlRequestParams,
  IRenderFileDownloadUrl,
  IRequestParam,
  UrlStreamingFormat
} from '../../api/interfaces';
import { ApiService } from '../../api/services/api.service';
import { AuthService } from '../../auth/services/auth.service';
import { IError } from '../../core/services/toast/interfaces';
import { InsightsCommonDataService } from '../../insights-common/insights-common-data.service';
import { InsightsCommonService } from '../../insights-common/insights-common.service';
import { IUIKeyframe } from '../../insights/components/keyframe/interfaces';
import { UIActionType } from '../../insights/interfaces';
import { InsightsService } from '../../insights/services/insights.service';
import { formatToStandardTime, getSeconds } from '../../utils/time';
import { IAction, IThumbStyle, TranslationJobState } from '../interfaces';
import { videoSearchOptions } from './videoSearchActions';

import PlaylistContractV2 = Microsoft.VideoIndexer.Contracts.PlaylistContractV2;
import LanguageV2 = Microsoft.VideoIndexer.Contracts.LanguageV2;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public customImagesLocation: string;
  private keyframesByVideoId: Map<string, IUIKeyframe[]> = new Map<string, IUIKeyframe[]>();
  private readonly RESPONSIVE_WINDOW_WIDTH = 768;
  private readonly MIN_SUPPORT_RESPONSIVE_WIDTH = 320;
  private readonly TRANSLATION_JOB_POLLING_INTERVAL = 15000; // 15sec
  private readonly TRANSLATION_JOB_POLLING_TIMEOUT = 600000; // 10min
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private insightsCommonDataService: InsightsCommonDataService,
    private commonInsights: InsightsCommonService,
    private insightsService: InsightsService,
    private featureSwitchService: FeatureSwitchService,
    private utilsService: UtilsService,
    private trackService: TrackService
  ) {}

  public getVideoTestIndex(accountId: string, videoId: string) {
    return this.apiService.Account.Video.getVideoTestIndex();
  }

  public getVideoIndex(
    accountId: string,
    videoId: string,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2 = 'en-US',
    customIndexLocation?: string,
    includeStreamingUrls?: boolean,
    allowEdit?: boolean,
    includedInsights?: IUIInsightJsonKey[],
    hasSelectedEdgeExtension: boolean = false
  ): Observable<Microsoft.VideoIndexer.Contracts.PlaylistContractV2> {
    if (customIndexLocation) {
      return this.apiService.Account.Video.getCustomVideoIndex(customIndexLocation);
    }

    if (accountId && videoId) {
      let params: IGetIndexRequestParams = {};
      if (language) {
        params = { language: language };
      }
      if (allowEdit) {
        params.allowEdit = true;
      }
      if (includedInsights) {
        params.includedInsights = includedInsights.toString();
      }
      params.includeStreamingUrls = includeStreamingUrls ? includeStreamingUrls : false;
      params.includeSummarizedInsights = !this.featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights);
      if (hasSelectedEdgeExtension) {
        return this.getVideoIndexEdgeExtension(accountId, videoId, params);
      }
      return this.apiService.Account.Video.getVideoIndex(accountId, videoId, params);
    }

    return this.apiService.Account.Video.getVideoTestIndex();
  }

  public getVideoIndexEdgeExtension(
    accountId: string,
    videoId: string,
    params: IGetIndexRequestParams
  ): Observable<Microsoft.VideoIndexer.Contracts.PlaylistContractV2> {
    return this.apiService.Account.Video.getVideoIndex(accountId, videoId, params).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.status === HttpStatusCode.NotFound) {
          const language: LanguageV2 = params.language as LanguageV2;
          switch (error?.error?.ErrorType) {
            case TranslationJobState.TRANSLATION_NOT_FOUND:
              this.trackService.track('edge.translate_insights_job.not_found', { category: EventCategory.INSIGHTS, data: { language } });
              return this.apiService.EdgeExtensions.translate(accountId, videoId, language).pipe(
                switchMap(() => {
                  this.trackService.track('edge.translate_insights_job.started', { category: EventCategory.INSIGHTS, data: { language } });
                  return this.pollUntilTranslationComplete(accountId, videoId, params);
                }),
                catchError(translateError => {
                  this.trackService.track('edge.translate_insights_job.failed', {
                    category: EventCategory.INSIGHTS,
                    data: { language, error: error?.error }
                  });
                  return throwError(() => translateError);
                })
              );
            case TranslationJobState.VIDEO_PROCESSING:
              this.trackService.track('edge.translate_insights_job.processing', { category: EventCategory.INSIGHTS, data: { language } });
              return this.pollUntilTranslationComplete(accountId, videoId, params);
            case TranslationJobState.VIDEO_TRANSLATION_FAILED:
              return throwError(() => error);
          }
        }
        // Failed to get video index for other reasons
        return throwError(() => error);
      })
    );
  }

  public getVideoStreamingUrl(
    accountId: string,
    videoId: string,
    useProxy?: boolean,
    urlFormat?: UrlStreamingFormat,
    tokenLifetimeInMinutes?: string,
    isBase = true
  ) {
    const params: IGetStreamingUrlRequestParams = {
      isBase
    };
    if (useProxy) {
      params.useProxy = useProxy;
    }
    if (urlFormat) {
      params.urlFormat = urlFormat;
    }
    if (tokenLifetimeInMinutes) {
      params.tokenLifetimeInMinutes = tokenLifetimeInMinutes;
    }

    return this.apiService.Account.Video.getVideoStreamingURL(accountId, videoId, params);
  }

  public getObservedPeoplePageByTime(
    accountId: string,
    videoId: string,
    time: number
  ): Observable<Microsoft.VideoIndexer.Contracts.IObservedPeoplePage> {
    const params: IGetObservedPeoplePageTimeRequestParams = {
      time: time
    };

    return this.apiService.Account.Video.getObservedPeoplePageByTime(accountId, videoId, params);
  }

  public getObservedPeoplePageByID(
    accountId: string,
    videoId: string,
    pageID: number
  ): Observable<Microsoft.VideoIndexer.Contracts.IObservedPeoplePage> {
    return this.apiService.Account.Video.getObservedPeoplePageByID(accountId, videoId, pageID);
  }

  public getFaceBoundingBoxPageByTime(
    accountId: string,
    videoId: string,
    time: number
  ): Observable<Microsoft.VideoIndexer.Contracts.IBoundingBoxesPage> {
    const params: IGetBoundingBoxPageTimeRequestParams = {
      time: time
    };

    return this.apiService.Account.Video.getFacesPageByTime(accountId, videoId, params);
  }

  public getFaceBoundingBoxPageByID(
    accountId: string,
    videoId: string,
    pageID: number
  ): Observable<Microsoft.VideoIndexer.Contracts.IBoundingBoxesPage> {
    return this.apiService.Account.Video.getFacesPageByID(accountId, videoId, pageID);
  }

  public getDetectedObjectsBoundingBoxPageByTime(
    accountId: string,
    videoId: string,
    time: number,
    includedObjectTypes: IRequestParam[]
  ): Observable<Microsoft.VideoIndexer.Contracts.IDetectedObjectsPage> {
    const params: IGetBoundingBoxPageTimeRequestParams = {
      time: time
    };

    return this.apiService.Account.Video.getDetectedObjectsPageByTime(accountId, videoId, params, null, includedObjectTypes);
  }

  public getDetectedObjectsBoundingBoxPageByID(
    accountId: string,
    videoId: string,
    pageID: number
  ): Observable<Microsoft.VideoIndexer.Contracts.IDetectedObjectsPage> {
    return this.apiService.Account.Video.getDetectedObjectsPageByID(accountId, videoId, pageID);
  }

  public getProjectIndex(
    accountId: string,
    videoId: string,
    language: Microsoft.VideoIndexer.Contracts.LanguageV2 = 'en-US'
  ): Observable<Microsoft.VideoIndexer.Contracts.ProjectPlaylistContractV2> {
    const params = {
      allowEdit: true,
      includeSummarizedInsights: !this.featureSwitchService.featureSwitch(FeatureSwitch.DisableSummarizedInsights)
    };
    if (language) {
      params['language'] = language;
    }
    return this.apiService.Account.Video.getProjectIndex(accountId, videoId, params);
  }

  public getLanguages(isMultiLanguage = false, propertyFilter: string = ''): IAction[] {
    let languages: IAction[] = [];
    const supportedLangs = propertyFilter ? this.getSupportedLanguagesFilterBy(propertyFilter) : this.commonInsights.getSupportedLanguages();
    supportedLangs.forEach(lang => {
      // Create lang
      const uiLanguage: IAction = {
        title: lang.name,
        selected: false,
        type: UIActionType.CHANGE_LANGUAGE,
        value: lang.key,
        icon: '',
        selectable: true,
        id: lang.name
      };
      // Add multi language only if it is a multi language video, and push it first
      if (lang.name === 'Multi') {
        if (isMultiLanguage) {
          languages.unshift(uiLanguage);
        }
      } else {
        languages.push(uiLanguage);
      }
    });
    // Sort aZ
    languages = orderBy(languages, [
      item => {
        return !(item.id === 'Multi');
      },
      'title'
    ]);
    return languages;
  }

  public getSupportedLanguagesFilterBy(propertyFilter: string = 'sourceLanguage') {
    return this.commonInsights.getSupportedLanguages().filter((lang: ILanguage) => {
      return lang[propertyFilter];
    });
  }

  public getLanguageByKey(languageKey) {
    const languages = this.commonInsights.getSupportedLanguages();
    let languageObject = find(languages, language => {
      return languageKey === null ? language.key === '' : language.key.toLowerCase() === languageKey.toLowerCase();
    });

    if (!languageObject) {
      // if language is not supported get English
      languageObject = find(languages, language => {
        return 'en-us' === language.key.toLowerCase();
      });
    }

    return languageObject;
  }

  public getThumbnailUrl(accountId: string, videoId: string, thumbnailId: string): string {
    return this.apiService.Account.Video.getThumbnailUrl(accountId, videoId, thumbnailId);
  }

  public getThumbnail(video: Microsoft.VideoIndexer.Contracts.PlaylistContractV2, thumbnailId: string) {
    return this.apiService.Account.Video.getThumbnail(video.accountId, video.id, thumbnailId);
  }

  public getAccessToken(accountId: string, videoId: string): string {
    return this.authService.getAccessToken(videoId, accountId);
  }

  public setPrivacyMode(privacyMode: Microsoft.VideoIndexer.Contracts.PrivacyMode) {
    this.insightsCommonDataService.getVideoIndex().privacyMode = privacyMode;
  }

  public getSubtitlesUrl(accountId: string, videoId: string, params: ICaptionsRequestParams, isBase: boolean): string {
    let accessToken = this.authService.getUserAccessTokenOrEmptyString(videoId, accountId);

    // Fallback - if access token is empty try to get account token
    if (!accessToken) {
      accessToken = this.authService.getUserAccessTokenOrEmptyString(null, accountId);
    }

    params.accessToken = accessToken;

    if (isBase) {
      return this.apiService.Account.Video.getCaptionsUrl(accountId, videoId, params);
    }

    return this.apiService.Account.Project.getCaptionsUrl(accountId, videoId, params);
  }

  public getProjectRenderedFileDownloadUrl(accountId: string, projectId: string): Observable<IRenderFileDownloadUrl> {
    return this.apiService.Account.Video.getProjectRenderedFileDownloadUrl(accountId, projectId);
  }

  public checkRenderProjectStatus(
    accountId: string,
    projectId: string
  ): Observable<
    Microsoft.VideoIndexer.Contracts.AsyncOperationContract<Microsoft.VideoIndexer.Contracts.ProjectRenderOperationResultContract, IError>
  > {
    return this.apiService.Account.Video.checkRenderProjectStatus(accountId, projectId, null, { allowEdit: true });
  }

  public getVideoSearchOptions(): IAction[] {
    return cloneDeep(videoSearchOptions);
  }

  public onResizeEventStream(minimum = 320, debounce = 600) {
    if (!window) {
      return;
    }
    const source = fromEvent(window, 'resize');
    return source
      .pipe(map(val => val.target['innerWidth']))
      .pipe(filter(val => val >= minimum))
      .pipe(debounceTime(debounce))
      .pipe(distinctUntilChanged());
  }

  public getKeyframeArray(
    index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
    spriteSize: number,
    smallSize: number,
    keyframeTitle = ''
  ): IUIKeyframe[] {
    let shots: Microsoft.VideoIndexer.Contracts.Shot[];
    let keyframes: IUIKeyframe[] = [];

    // Sort keyframes by id for matching the sprite
    let currentIndex = 0;

    const videoID = index.id;
    // If keyframes array haven't initialize and it is the same video - take the old keyframes array
    if (!this.keyframesByVideoId.get(videoID)) {
      index.videos.forEach(video => {
        if (video.insights && video.insights.shots) {
          shots = video.insights.shots;

          // If current video has shots data
          if (shots) {
            shots.forEach(shot => {
              shot.keyFrames.forEach((kf, kfIndex) => {
                if (kf.instances) {
                  kf.instances.forEach((kfi, kfiIndex) => {
                    const keyframe = this.createUIKeyframe(
                      kf,
                      kfi,
                      keyframeTitle,
                      currentIndex,
                      video.id,
                      index.videos.length > 1 && index.summarizedInsights?.thumbnailId
                        ? kfi.thumbnailId === index.summarizedInsights?.thumbnailId
                        : kfi.thumbnailId === index.videos[0].thumbnailId
                    );

                    if (keyframe) {
                      currentIndex++;
                      keyframe.isFirstShotKeyframe = kfIndex === 0 && kfiIndex === 0;
                      keyframes.push(keyframe);
                    }
                  });
                }
              });
            });
          }
        }
      });
    } else {
      keyframes = cloneDeep(this.keyframesByVideoId.get(videoID));
    }

    // Create thumb style
    keyframes = this.createThumbnailsWithStyle(index, spriteSize, smallSize, keyframes, keyframeTitle);
    this.keyframesByVideoId.set(videoID, cloneDeep(keyframes));
    return keyframes;
  }

  public getDefaultLanguage(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2): Microsoft.VideoIndexer.Contracts.LanguageV2 {
    if (videoIndex && videoIndex.videos && videoIndex.videos[0]) {
      // First, check if video is multi language
      if (videoIndex.videos[0].sourceLanguages && videoIndex.videos[0].sourceLanguages.length > 1) {
        return '';
      } else if (videoIndex.videos.length > 1) {
        // Video is a project - check if multi language project
        const firstLanguage = videoIndex.videos[0].sourceLanguage;
        for (let index = 1; index < videoIndex.videos.length; index++) {
          const video = videoIndex.videos[index];
          if (firstLanguage !== video.sourceLanguage) {
            return '';
          }
        }

        return firstLanguage;
      } else {
        // If video has null source object -> it's a multi language video
        if (videoIndex.videos[0].sourceLanguage) {
          return videoIndex.videos[0].sourceLanguage;
        }
        return '';
      }
    }

    return '';
  }

  public setVideoThumbnail(newThumbnail: string, indexID: string): void {
    const keyframes = this.keyframesByVideoId.get(indexID);
    if (keyframes) {
      keyframes.forEach(keyframe => {
        keyframe.isVideoThumbnail = keyframe.thumbnailId === newThumbnail ? true : false;
      });
    }
  }
  public get isResponsiveView$() {
    const isResponsiveWidth = width => width <= this.RESPONSIVE_WINDOW_WIDTH;
    const initViewResponsive$ = from(this.utilsService.getBodyRectAsync()).pipe(map(bodyRect => isResponsiveWidth(bodyRect.width)));
    const onResizeResponsive$ = this.onResizeEventStream(this.MIN_SUPPORT_RESPONSIVE_WIDTH).pipe(map(isResponsiveWidth));
    return initViewResponsive$.pipe(mergeWith(onResizeResponsive$));
  }

  private createThumbnailsWithStyle(
    index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2,
    spriteSize: number,
    smallSize: number,
    keyframes: IUIKeyframe[],
    keyframeTitle = ''
  ): IUIKeyframe[] {
    // If it's a project, sort by video id + id
    const tempKeyframe = (index.videos.length > 1 ? sortBy(keyframes, ['videoId', 'id']) : keyframes).map((keyframe, keyframeIndex) => {
      keyframe.index = keyframeIndex;
      keyframe.title = `${keyframeTitle} : ${keyframe.startText} - ${keyframe.endText}`;
      const thumbStyle = this.getThumbStyleData(keyframe, smallSize, spriteSize, index);
      keyframe.style = thumbStyle.style;
      keyframe.url = thumbStyle.url;
      keyframe.xPosition = thumbStyle.position;
      keyframe.yPosition = 0;
      return keyframe;
    });

    keyframes = index.videos.length > 1 ? sortBy(tempKeyframe, ['originIndex']) : tempKeyframe;

    return keyframes;
  }

  private createUIKeyframe(
    kf: Microsoft.VideoIndexer.Contracts.KeyFrame,
    kfi: Microsoft.VideoIndexer.Contracts.KeyFrameElementInstance,
    keyframeTitle: string,
    currentIndex: number,
    videoId: string,
    isVideoThumbnail: boolean
  ): IUIKeyframe {
    // If there's no thumbnail id, probably an old video, return.
    if (!kfi.thumbnailId) {
      return null;
    }
    const formattedStart = formatToStandardTime(kfi.adjustedStart);
    const formattedEnd = formatToStandardTime(kfi.adjustedEnd);
    return {
      id: kf.id,
      title: `${keyframeTitle} : ${formattedStart} - ${formattedEnd}`,
      name: `${keyframeTitle} : ${formattedStart} - ${formattedEnd}`,
      thumbnailId: kfi.thumbnailId,
      index: currentIndex,
      originIndex: currentIndex,
      start: kfi.adjustedStart,
      startText: formattedStart,
      end: kfi.adjustedEnd,
      endText: formattedEnd,
      videoId: videoId,
      isVideoThumbnail: isVideoThumbnail,
      style: null,
      appearances: [
        {
          endSeconds: getSeconds(kfi.adjustedEnd),
          startSeconds: getSeconds(kfi.adjustedStart)
        }
      ]
    };
  }

  private getSpriteUrl(currentIndex: number, spriteSize: number, index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    const page = Math.floor(currentIndex / spriteSize);
    return this.insightsService.getKeyframesSpriteUrl(index.accountId, index.id, page);
  }

  private getSpritePosition(index: number, smallSize: number, spriteSize: number) {
    const page = Math.floor(index / spriteSize);
    return (index % spriteSize) * smallSize;
  }

  private getThumbStyleData(
    kf: IUIKeyframe,
    smallSize: number,
    spriteSize: number,
    index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2
  ): IThumbStyle {
    const position = this.getSpritePosition(kf.index, smallSize, spriteSize);
    // Return the x position of the image based on the sprite image index
    const spriteURL = this.getSpriteUrl(kf.index, spriteSize, index);
    return {
      style: {
        backgroundImage: `url(${spriteURL})`,
        backgroundPosition: `-${position}px 0`
      },
      url: spriteURL,
      position: position
    };
  }

  private pollUntilTranslationComplete(accountId: string, videoId: string, params: IGetIndexRequestParams): Observable<PlaylistContractV2> {
    return timer(0, this.TRANSLATION_JOB_POLLING_INTERVAL).pipe(
      switchMap(() =>
        this.apiService.Account.Video.getVideoIndex(accountId, videoId, params).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error?.ErrorType === TranslationJobState.VIDEO_PROCESSING) {
              // Retry until success or timeout
              return EMPTY;
            }
            this.trackService.track('edge.translate_insights_job.failed', {
              category: EventCategory.INSIGHTS,
              data: { language: params.language, error: error?.error }
            });
            return throwError(() => error);
          })
        )
      ),
      filter(response => response && !(response instanceof HttpErrorResponse)),
      tap(() => {
        this.trackService.track('edge.translate_insights_job.completed', { category: EventCategory.INSIGHTS, data: { language: params.language } });
      }),
      first(),
      timeout(this.TRANSLATION_JOB_POLLING_TIMEOUT)
    );
  }
}
