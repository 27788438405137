import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { guid } from '../../utils';

@Component({
  selector: 'vi-ui-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaComponent {
  @Input() value: string;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() errorMessage: string;
  @Input() description?: string;
  @Input() maxLength?: number;
  @Input() showCounter: boolean = true;
  @Input() height: string;
  @Output() inputChanged = new EventEmitter<string>();
  @Output() focusedOut = new EventEmitter<string>();

  public DEFAULT_HEIGHT = '72px';
  public name: string = guid();

  public get counter() {
    return this.showCounter && this.maxLength ? `${this.value?.length || 0}/${this.maxLength}` : '';
  }

  public get error() {
    return !!this.errorMessage;
  }

  public onFocusedOut(value: string) {
    this.focusedOut.emit(value);
  }
}
