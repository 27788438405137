import { createSelector } from '@ngrx/store';

import { findIndex } from 'lodash-es';

import { getSpeechState } from './speech-models.selectors';
import { SpeechDatasetKind, SpeechObjectState } from '../../interfaces';

export const getSpeechDatasetsState = createSelector(getSpeechState, state => state.datasets);
export const getSpeechDatasets = createSelector(getSpeechDatasetsState, state => Object.values(state.entities));
export const getSpeechDatasetById = (id: string) => createSelector(getSpeechDatasetsState, state => state.entities[id]);
export const isSpeechDatasetsLoaded = createSelector(getSpeechDatasetsState, state => state.loaded);
export const isDatasetUpdating = createSelector(getSpeechDatasetsState, state => state.updating);
export const getSpeechDatasetError = createSelector(getSpeechDatasetsState, state => state.error);
export const isSpeechDatasetFilesError = (id: string) => createSelector(getSpeechDatasetsState, state => state.entities[id]?.filesError);
export const getSelectedDatasets = createSelector(getSpeechDatasetsState, state => state.selectedDatasets);
export const getSelectedDatasetsLocale = createSelector(getSpeechDatasetsState, state => state.selectedDatasets.locale);
export const getSelectedDatasetsIds = createSelector(getSpeechDatasetsState, state => {
  const selectedDatasetsIds = [...state.selectedDatasets.languageIds];
  if (state.selectedDatasets.pronunciationId) {
    selectedDatasetsIds.push(state.selectedDatasets.pronunciationId);
  }
  return selectedDatasetsIds;
});
export const selectedDatasetsExists = createSelector(getSelectedDatasetsIds, selectedIds => {
  return selectedIds?.length > 0;
});

export const getSpeechCompleteDatasetsByLocale = (locale: string) =>
  createSelector(getSpeechDatasets, datasets =>
    datasets.filter(dataset => dataset.status === SpeechObjectState.COMPLETE && dataset.locale === locale)
  );
export const isSelectedDatasetsForTrainModel = createSelector(
  getSpeechDatasets,
  datasets => findIndex(datasets, dataset => dataset.kind === SpeechDatasetKind.LANGUAGE && dataset.selected) > -1
);
export const getSpeechDatasetsByIds = (ids: string[]) =>
  createSelector(getSpeechDatasets, datasets => datasets.filter(dataset => ids.includes(dataset.id)));
