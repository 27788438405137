import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import * as PersonActions from '../actions/person-model.actions';

import PersonModel = Microsoft.VideoIndexer.Contracts.PersonModel;

export interface IState extends EntityState<PersonModel> {
  // additional entities state properties
  selectedAccountId: string;
  loaded: boolean;
  loading: boolean;
  creatingModel: boolean;
  error: boolean;
}

export function selectAccountId(a: PersonModel): string {
  return a.id;
}

export const adapter: EntityAdapter<PersonModel> = createEntityAdapter<PersonModel>({
  selectId: selectAccountId
});

export const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedAccountId: null,
  loaded: false,
  loading: false,
  creatingModel: false,
  error: false
});

const personModelReducer = createReducer(
  initialState,
  on(PersonActions.upsertPersonModel, (state, { model }) => {
    return adapter.upsertOne(model, state);
  }),
  on(PersonActions.upsertPersonModels, (state, { models }) => {
    return adapter.upsertMany(models, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(PersonActions.clearPersonModels, (state, {}) => {
    return adapter.removeAll({
      ...state,
      selectedAccountId: null,
      loaded: false,
      loading: false,
      creatingModel: false,
      error: false
    });
  }),
  on(PersonActions.failLoadPersonModels, state => {
    return {
      ...state,
      error: true,
      loading: false,
      loaded: true
    };
  }),
  on(PersonActions.loadPersonModelsInProgress, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(PersonActions.deletePersonModel, (state, { model }) => {
    return adapter.removeOne(model.id, state);
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return personModelReducer(state, action);
}

export const getSelectedAccountId = (state: IState) => state.selectedAccountId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectAccountIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllAccounts = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
