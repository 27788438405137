import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { IAPIError } from '../toast/interfaces';
import { APIErrors, Errors } from '../toast/errors';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public resources = {
    APIServiceServerError: ''
  };

  constructor(private toastService: ToastService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handler(err: HttpErrorResponse | any, errMessage?: string) {
    if (!err) {
      return;
    }

    // eslint-disable-next-line one-var
    let statusCode = 0,
      errorType = '',
      message = '';

    if (typeof err === 'number') {
      message = this.resources.APIServiceServerError;
      statusCode = err;
    } else {
      // Get server message
      if (err.error) {
        if (typeof err.error === 'string') {
          message = err.error;
        } else if (err.error.Message) {
          // Res.data is an object that has Message member
          message = err.error.Message;
        }
      }
      message = message ? message : typeof err === 'string' ? err : this.resources.APIServiceServerError;
      // eslint-disable-next-line deprecation/deprecation
      statusCode = status || err.status;
      errorType = err.error ? err.error.ErrorType : err.ErrorType;
    }

    const errorMsg = errMessage ? errMessage : Errors[errorType] && Errors[errorType].msg !== '' ? Errors[errorType].msg : message;
    const serverError: IAPIError = err.error;
    switch (statusCode) {
      case 401:
        switch (serverError.ErrorType) {
          case APIErrors.ACCESS_TOKEN_VALIDATION_FAILED:
            this.toastService.error(err);
            break;
          default:
        }
        break;
      case 400:
        if (errorType && Errors[errorType]) {
          this.toastService.error(err, errorMsg, false, false, false, null, 'ErrorTypes');
        }
        break;
      case 403:
        break;
      case 500:
        break;
      case 503:
        break;
      case 504:
        break;
      case 404:
        break;
      case -1:
      default:
        this.toastService.error(err, message, false);
    }
  }

  static extractTraceIdFromMessage(message: string): string {
    if (!message) {
      return null;
    }

    const traceIdPattern = /Trace id: '([a-f0-9\-]+)'/;
    const match = message.match(traceIdPattern);
    let traceId = null;
    if (match && match[1]) {
      traceId = match[1];
    }

    return traceId;
  }
}
