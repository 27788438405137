import { Component, OnInit, Injector } from '@angular/core';

import { AccessibilityModeHandlerService } from '../accessibility-mode-handler.service';

@Component({
  selector: 'app-focusable',
  templateUrl: './focusable.component.html',
  styleUrls: ['./focusable.component.scss']
})
export class FocusableComponent implements OnInit {
  private accessibilityModeHandler: AccessibilityModeHandlerService;

  constructor() {
    // Get Accessibility mode service
    const injector = Injector.create({ providers: [{ provide: AccessibilityModeHandlerService, deps: [] }] });
    this.accessibilityModeHandler = injector.get(AccessibilityModeHandlerService);
  }

  public ngOnInit() {}

  public get accessibilityMode() {
    return this.accessibilityModeHandler.AccessibilityMode;
  }

  public get accessibilityModeSubject() {
    return this.accessibilityModeHandler.AccessibilityModeSubject;
  }
}
