import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeType } from './interface';

@Component({
  selector: 'vi-ui-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() public type: BadgeType = BadgeType.Primary;
}
