import { UIActionIcon, UIActionType } from '@common/modules/insights/interfaces';

import { IAction } from '../../interfaces';
import { ISortState } from './interfaces';

export const directionActions = [
  {
    title: '',
    key: 'SortOptionAscending',
    selected: false,
    type: UIActionType.SORT,
    value: ISortState.Ascending,
    selectable: true,
    hasDrop: false,
    id: 'ASCENDING',
    hasPressedMode: true,
    separated: true
  },
  {
    title: '',
    key: 'SortOptionDescending',
    selected: false,
    type: UIActionType.SORT,
    value: ISortState.Descending,
    selectable: true,
    hasDrop: false,
    id: 'DESCENDING',
    hasPressedMode: true,
    separated: false
  }
];
export const sortAction: IAction = {
  icon: UIActionIcon.SORT,
  title: '',
  key: 'SortButton',
  selected: false,
  type: UIActionType.DROPDOWN,
  value: '',
  selectable: true,
  hasDrop: true,
  id: 'SORT_ACTION_BUTTON'
};
