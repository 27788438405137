import { Params, Data } from '@angular/router';

export interface ILanguage {
  key: Microsoft.VideoIndexer.Contracts.LanguageV2;
  name: LanguageName;
  rtl?: boolean;
  sourceLanguage?: boolean;
  supportedUserLocale?: boolean;
  supportedCustomization?: boolean;
  padding?: number;
}

export type LanguageName =
  | 'Auto'
  | 'Arabic'
  | 'Arabic (Syrian Arab Republic)'
  | 'Arabic (Bahrain), modern standard'
  | 'Arabic (Egypt)'
  | 'Arabic (Iraq)'
  | 'Arabic (Jordan)'
  | 'Arabic (Lebanon)'
  | 'Arabic (Oman)'
  | 'Arabic (Qatar)'
  | 'Arabic (Saudi Arabia)'
  | 'Arabic (Kuwait)'
  | 'Arabic (Israel)'
  | 'Arabic (United Arab Emirates)'
  | 'Arabic (Palestinian Authority)'
  | 'Chinese (Simplified)'
  | 'English'
  | 'English (Custom)'
  | 'English (Australia)'
  | 'English (United Kingdom)'
  | 'French'
  | 'French (Canada)'
  | 'German'
  | 'German (Custom)'
  | 'Italian'
  | 'Japanese'
  | 'Portuguese'
  | 'Portuguese (Portugal)'
  | 'Russian'
  | 'Spanish (Spain)'
  | 'Spanish (Custom)'
  | 'Spanish (Mexico)'
  | 'Filipino'
  | 'Afrikaans'
  | 'Bangla'
  | 'Bosnian'
  | 'Bulgarian'
  | 'Catalan'
  | 'Croatian'
  | 'Czech'
  | 'Danish'
  | 'Dutch'
  | 'Fijian'
  | 'Samoan'
  | 'Estonian'
  | 'Finnish'
  | 'Greek'
  | 'Haitian'
  | 'Hebrew'
  | 'Hindi'
  | 'Hungarian'
  | 'Indonesian'
  | 'Kiswahili'
  | 'Korean'
  | 'Latvian'
  | 'Lithuanian'
  | 'Malagasy'
  | 'Malay'
  | 'Maltese'
  | 'Norwegian'
  | 'Persian'
  | 'Polish'
  | 'Romanian'
  | 'Serbian (Cyrillic)'
  | 'Serbian (Latin)'
  | 'Slovak'
  | 'Slovenian'
  | 'Swedish'
  | 'Tamil'
  | 'Thai'
  | 'Tongan'
  | 'Turkish'
  | 'Ukrainian'
  | 'Urdu'
  | 'Vietnamese'
  | 'Chinese (Traditional)'
  | 'Chinese (Cantonese)'
  | 'Irish'
  | 'Keep Source'
  | 'Multi'
  | 'All';

export interface IRouterStateUrl {
  url: string;
  filteredUrl: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export enum AccountStateOptions {
  ACTIVE = 'Active',
  PENDING = 'PendingDeletion'
}

export enum AMSAssetsMigrationStatus {
  NOT_STARTED = 'NotStarted',
  PENDING = 'Pending',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Success',
  FAILED = 'Failed',
  NOT_APPLICABLE = 'NotApplicable',
  PENDING_USER_ACTION = 'PendingUserAction'
}

export enum AMSMigrationErrorType {
  MIGRATION_NOT_STARTED = 'MIGRATION_NOT_STARTED',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export const ArabicLanguages: Microsoft.VideoIndexer.Contracts.LanguageV2[] = [
  'ar-SY',
  'ar-BH',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-IL',
  'ar-AE',
  'ar-PS'
];

export interface IArmAccountCacheData {
  subscriptionId: string;
  resourceGroupName: string;
  name: string;
  location: string;
  accountId: string;
}
