import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { AccessibilityUtilsService } from '@common/modules/accessibility/accessibility-utils.service';
import { AccountPermission } from '@common/modules/shared/interfaces';

import * as RoleAssignmentsActions from '../actions/roleAssignments.actions';
import { IState } from '../reducers';
import * as fromCore from '../../core/selectors';

@Injectable()
export class RoleAssignmentsEffects {
  public loadRoleAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAssignmentsActions.loadRoleAssignments),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([, accountId]) => {
        this.store.dispatch(RoleAssignmentsActions.loadRoleAssignmentsInProgress());
        return this.apiService.Account.getRoleAssignments(accountId, { permission: AccountPermission.OWNER }).pipe(
          switchMap(roleAssignments => {
            this.trackService.track('roleAssignments.load.success', {
              category: EventCategory.INVITE
            });
            return [RoleAssignmentsActions.loadRoleAssignmentsSuccess({ roleAssignments })];
          }),
          catchError(err => {
            this.trackService.track('roleAssignments.load.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [RoleAssignmentsActions.loadRoleAssignmentsFailed({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  public deleteRoleAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAssignmentsActions.deleteRoleAssignment),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCore.selectRoleAssignments)),
      switchMap(([[{ roleAssignmentId }, accountId], roleAssignments]) => {
        return this.apiService.Account.deleteRoleAssignment(accountId, roleAssignmentId, { permission: AccountPermission.OWNER }).pipe(
          switchMap(() => {
            this.trackService.track('roleAssignments.delete.success', {
              category: EventCategory.INVITE
            });
            this.accessibilityUtils.announceDynamicMessage('RoleAssignRemovingSuccessNarrator', {
              email: roleAssignments[roleAssignmentId]?.user?.email
            });
            return [RoleAssignmentsActions.deleteRoleAssignmentSuccess({ roleAssignmentId })];
          }),
          catchError(err => {
            this.trackService.track('roleAssignments.delete.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [RoleAssignmentsActions.roleAssignmentsError({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  public updateRoleAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleAssignmentsActions.updateRoleAssignments),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ roleAssignmentId, role }, accountId]) => {
        return this.apiService.Account.updateRoleAssignment(
          accountId,
          roleAssignmentId,
          { type: role },
          { permission: AccountPermission.OWNER }
        ).pipe(
          switchMap(() => {
            this.trackService.track('roleAssignments.update.success', {
              category: EventCategory.INVITE
            });
            return [RoleAssignmentsActions.updateRoleAssignmentsSuccess({ roleAssignmentId, role })];
          }),
          catchError(err => {
            this.trackService.track('roleAssignments.update.failed', {
              category: EventCategory.INVITE
            });
            const isUserNotAllowedError = err?.error?.ErrorType === APIErrors.USER_NOT_ALLOWED;
            return [RoleAssignmentsActions.roleAssignmentsError({ isUserNotAllowedError })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private trackService: TrackService,
    private store: Store<IState>,
    private accessibilityUtils: AccessibilityUtilsService
  ) {}
}
