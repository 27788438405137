import { IOneDSClientConfiguration } from './interfaces';

const noop = () => null;

export const defaultParams: IOneDSClientConfiguration = {
  appId: '',
  onEventSent: noop,
  onEventDiscarded: noop,
  telemetryInitializers: [],
  webAnalyticsConfiguration: {
    autoCapture: {
      pageView: false,
      click: false,
      scroll: false,
      onUnload: false,
      resize: false,
      onLoad: true,
      jsError: false,
      lineage: false
    }
  },
  propertyConfiguration: {
    populateOperatingSystemInfo: true,
    populateBrowserInfo: true,
    enableApplicationInsightsUser: true,
    enableApplicationInsightsTrace: true,
    dropIdentifiers: true
  }
};
