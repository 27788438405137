import { UpdateAccountAction } from '@common/modules/shared/actions';

import { INotificationMap, NotificationIcon, NotificationLevel, NotificationMessageType, NotificationType } from './interfaces';

export const notificationTemplates: { [key: string]: INotificationMap } = {
  [NotificationMessageType.ServiceOutage]: {
    type: NotificationType.Banner,
    level: NotificationLevel.Critical,
    icon: NotificationIcon.Error
  },
  [NotificationMessageType.PartialOutage]: {
    type: NotificationType.Banner,
    level: NotificationLevel.Critical,
    icon: NotificationIcon.Error
  },
  [NotificationMessageType.RegionalOutage]: {
    type: NotificationType.Banner,
    level: NotificationLevel.Critical,
    icon: NotificationIcon.Error
  },
  [NotificationMessageType.ServiceRestored]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Info,
    icon: NotificationIcon.Info
  },
  [NotificationMessageType.DependencyOutageAzureMediaServices]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning
  },
  [NotificationMessageType.DependencyOutageAzureKubernetesService]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning
  },
  [NotificationMessageType.DependencyOutageAzureNetworking]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning
  },
  [NotificationMessageType.DependencyOutageAzureStorage]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning
  },
  [NotificationMessageType.DependencyOutageAzureCompute]: {
    type: NotificationType.Alert,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning
  },
  [NotificationMessageType.ReleaseNotes]: {
    type: NotificationType.Message,
    level: NotificationLevel.Info,
    icon: NotificationIcon.Info,
    link: {
      src: 'https://aka.ms/vi-release-notes',
      text: ''
    }
  },
  [NotificationMessageType.WelcomeMessage]: {
    type: NotificationType.Message,
    level: NotificationLevel.None,
    icon: NotificationIcon.None
  },
  [NotificationMessageType.FacialRecognition]: {
    type: NotificationType.Message,
    level: NotificationLevel.Warning,
    icon: NotificationIcon.Warning,
    link: {
      src: 'https://aka.ms/facerecognition',
      text: ''
    }
  },
  [NotificationMessageType.UpdateAccount]: {
    type: NotificationType.Banner,
    level: NotificationLevel.Error,
    icon: NotificationIcon.Error,
    button: {
      action: UpdateAccountAction
    }
  }
};
