import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject, firstValueFrom, take } from 'rxjs';

import { snakeCase, startCase } from 'lodash-es';
import { AppListItem } from 'src/apps/web/src/gallery/data-source/app-list-data-source';
import { GalleryService } from 'src/apps/web/src/gallery/services/gallery.service';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { StreamingPreset } from '@common/modules/shared/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ApiService } from '@common/modules/api/services/api.service';

import { IndexingSummaryService } from '../../../../gallery/services/indexing-summary-service';
import { indexingSummaryData, IndexingSettingKeys } from './interfaces';
import { resources } from '../indexing-summary/resources';
import { dataset } from './settings';
import { IndexingPreset } from '../settings/indexing-presets/interfaces';
import { presets, presetsWithBasicVideo } from '../settings/indexing-presets/actions';

@Component({
  selector: 'vi-indexing-summary-dialog',
  templateUrl: './indexing-summary-dialog.component.html',
  styleUrls: ['./indexing-summary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSummaryDialogComponent implements OnInit, OnDestroy {
  @Input() isDisabled = false;
  @Input() public data: AppListItem;
  readonly displayNameString = 'DisplayName';

  public isLoading: boolean = true;
  public showError: boolean = false;
  public videoTitle$ = new Observable<string>();
  public dataset: indexingSummaryData = dataset;
  public resources = resources;
  public thumbnailClass: string = '';
  public logoGroupName: string = '';
  public peopleModelName: string = '';
  public includedAIsNames: string[] = [];
  public excludedAIsNames: string[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private indexingSummaryService: IndexingSummaryService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private trackService: TrackService,
    private apiService: ApiService,
    private galleryService: GalleryService
  ) {}

  public getThumbnailClass(item: AppListItem): string {
    return `i-vi-${this.galleryService.getThumbnailClass(item)}`;
  }

  ngOnInit(): void {
    this.trackService.track('indexing_settings_dialog.init', { category: EventCategory.INDEXING_SETTINGS });
    this.translate.translateResourcesInstant(resources);
    this.loadVideoIndexData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async loadVideoIndexData(): Promise<void> {
    try {
      const videoIndexResponse = await firstValueFrom(
        this.apiService.Account.Video.getVideoIndex(this.data.accountId, this.data.id, {
          includeStreamingUrls: false,
          includeSummarizedInsights: false
        }).pipe(take(1))
      );

      this.thumbnailClass = this.getThumbnailClass(this.data);

      const [logoGroupName, peopleModelName, supportedAIs] = await Promise.all([
        this.indexingSummaryService.getLogoGroupNameById(videoIndexResponse.videos[0].logoGroupId),
        this.indexingSummaryService.getPeopleModelNameById(videoIndexResponse.videos[0].personModelId),
        this.indexingSummaryService.getSupportedAINamesByPreset(this.data.accountId, this.data.indexingPreset as IndexingPreset)
      ]);

      this.excludedAIsNames = this.indexingSummaryService.getExcludedAIsNames(videoIndexResponse.videos[0].excludedAIs || [], supportedAIs);
      this.includedAIsNames = this.indexingSummaryService.getIncludedAisNames(
        this.data.indexingPreset as IndexingPreset,
        supportedAIs,
        this.excludedAIsNames
      );

      this.logoGroupName = logoGroupName;
      this.peopleModelName = peopleModelName;

      this.initTranslations();
      this.initDatasetValues();
      this.isLoading = false;

      this.cdr.detectChanges();
      this.trackService.track('indexing_summary_dialog.load_video_index_data.success', { category: EventCategory.INDEXING_SETTINGS });
    } catch (err) {
      this.trackService.track('indexing_summary_dialog.load_video_index_data.failed', { category: EventCategory.INDEXING_SETTINGS, data: err });
      this.isLoading = false;
      this.showError = true;
      this.cdr.detectChanges();
    }
  }

  private initTranslations() {
    for (const item of this.dataset.indexingSettingsDetails) {
      item.text = resources[item.key];

      // translate sub settings titles
      if (item.children) {
        for (const child of item.children) {
          child.text = resources[child.key];
        }
      }
    }
    for (const item of this.dataset.videoDescription) {
      item.text = resources[item.key];
    }
  }

  private initDatasetValues() {
    const allSummaryKeys = [...this.dataset.videoDescription, ...this.dataset.indexingSettingsDetails];

    for (const item of allSummaryKeys) {
      switch (item.key) {
        case IndexingSettingKeys.IndexedBy:
          item.value = this.data.userName;
          break;
        case IndexingSettingKeys.GalleryItemLastModified:
          item.value = this.data.lastModifiedTimeFormatted;
          break;
        case IndexingSettingKeys.UploadPeopleModel:
          item.value = this.peopleModelName;
          break;
        case IndexingSettingKeys.UploadLogoGroupOption:
          item.value = this.logoGroupName;
          break;
        case IndexingSettingKeys.IndexingPresetLabel:
          item.children.find(child => child.key === IndexingSettingKeys.IncludedAIs).value = this.translatedAIs(this.includedAIsNames).join(', ');
          item.children.find(child => child.key === IndexingSettingKeys.ExcludedAIs).value = this.translatedAIs(this.excludedAIsNames).join(', ');
          item.value = this.translatedPreset;
          break;
        case IndexingSettingKeys.ReindexModalVideoSourceLanguage:
          item.value = this.languageName;
          break;
        case IndexingSettingKeys.UploadPrivacyLabel:
          item.value = this.translatedPrivacyMode;
          break;
        case IndexingSettingKeys.UploadStreaming:
          item.value = this.selectedStreaming;
          break;
      }
    }

    this.dataset.videoThumbnailUrl = this.data.thumbnail;
    this.dataset.videoTitle = this.data.name;
  }

  private translatedAIs(AIsNames: string[]): string[] {
    return AIsNames.map(ai => {
      let modelDisplayName = this.translate.instant(`${ai}${this.displayNameString}`);
      // if the displayName is not translated, use the startCase function as a fallback.
      if (modelDisplayName === `${ai}${this.displayNameString}`) {
        modelDisplayName = startCase(ai);
      }
      return modelDisplayName;
    });
  }

  private get selectedStreaming(): string {
    switch (this.data.streamingPreset) {
      case StreamingPreset.SingleBitrate:
        return this.resources.UploadServiceSingleBiterate;
      case StreamingPreset.NoStreaming:
        return this.resources.UploadServiceNoStreaming;
    }
  }

  private get translatedPrivacyMode(): string {
    return this.translate.instant(`${this.data.privacyMode}`);
  }

  private get languageName(): string | undefined {
    const languageNameTranslated = this.translate.instant(`Language_${snakeCase(this.data.sourceLanguage)}`);
    return languageNameTranslated;
  }

  private get translatedPreset(): string | undefined {
    const value = this.data.indexingPreset;
    const preset = [...presets, ...presetsWithBasicVideo].find(p => p.value === value);
    return preset ? this.resources[preset.key] : undefined;
  }
}
