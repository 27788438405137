import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const connectToAzureAction: IAction = {
  title: '',
  icon: 'i-vi-add',
  key: 'SettingsCreateAccount',
  value: UIActionType.CLICK,
  selected: false,
  selectable: false,
  id: 'connectToAzureButtonSettings'
};
