import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  public transform(value: string, textFilter: string): string {
    // LD means any letter or digit.
    textFilter = textFilter.replace(/\\p{LD}+/gi, '');
    if (textFilter.trim().length > 0) {
      const regex = new RegExp(textFilter, 'ig');
      if (value.match(regex) && value.match(regex).length > 0) {
        const origStr = value.match(regex)[0];
        value = value.replace(origStr, `<span class="highlight">${origStr}</span>`);
      }
    }
    return value;
  }
}
