import { Injectable, ErrorHandler } from '@angular/core';

import { TrackService } from '../track';

@Injectable()
export class MonitoringErrorHandlerService extends ErrorHandler {
  constructor(public trackService: TrackService) {
    super();
  }

  public handleError(error: Error): void {
    this.trackService.trackError(error);
    super.handleError(error);
  }
}
