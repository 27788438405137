import { Injectable } from '@angular/core';

import { getCookie } from '@common/modules/utils/cookie';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {
  private _sessionId = '';
  private _sessionFromCookie = false;

  private readonly SESSION_ID_KEY = 'ai_session';
  constructor() {
    // Default value is session id according to 1ds constraints
    this._sessionId = this.generateSessionId();
  }

  public get sessionId() {
    // Get session id from 1Ds cookie
    const sessionCookie = getCookie(this.SESSION_ID_KEY);
    if (sessionCookie) {
      this._sessionId = this.parseSessionId(sessionCookie);
      this._sessionFromCookie = true;
    }

    return this._sessionId;
  }

  public get sessionFromCookie() {
    return this._sessionFromCookie;
  }

  /**
   * Parse session id according to 1DS & Application insights template.
   */
  private parseSessionId(fullSessionId: string) {
    return fullSessionId.substring(0, fullSessionId.indexOf('|'));
  }

  /**
   * Generate a new session id compatible with 1DS constraints (either 5 or 22 characters long)
   */
  private generateSessionId() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16);
  }
}
