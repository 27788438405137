import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { CommonTrackingDataService } from '@common/modules/core/services/track/common-tracking-data.service';

import { UrlService } from '../../core/services/url/url.service';
import { IAction } from '../../shared/interfaces';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { DEFAULT_LOCALE_LANGUAGE_KEY, getMappedLanguage, SupportedLocaleLanguages } from '../../../../apps/web/src/core/supportedLocaleLanguages';
import { TrackService } from '../../core/services/track';
import enJson from '../../../../assets/i18n/en.json';

@Injectable({
  providedIn: 'root'
})
export class TranslateHelperService {
  private mappedLang;

  constructor(
    private translate: TranslateService,
    private urlUtility: UrlService,
    private localStorageService: LocalStorageService,
    private trackingService: TrackService,
    private commonTrackingService: CommonTrackingDataService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public get locale() {
    const storedLang = this.localStorageService.getItem('vi.locale');
    const queryLang = this.urlUtility.getQueryParam('locale');
    const lang = this.commonTrackingService.embedMode ? queryLang || storedLang : storedLang || queryLang;
    return lang || this.translate.getBrowserCultureLang() || DEFAULT_LOCALE_LANGUAGE_KEY;
  }

  public init(): Promise<boolean> {
    // Get the locale
    this.mappedLang = getMappedLanguage(this.locale && this.locale.toLowerCase());

    // In case the mappedLang is english, skip the call for the json file and load from import json
    this.translate.setTranslation(SupportedLocaleLanguages.en, enJson, false);
    this.translate.setDefaultLang(SupportedLocaleLanguages.en);

    // Update html lang attribute
    try {
      this.document.documentElement.lang = this.mappedLang;
    } catch (error) {
      this.trackingService.trackError(error);
    }

    // The lang to use, if the lang isn't available, it will use the current loader to get them
    return new Promise((resolve, reject) => {
      this.translate.use(this.mappedLang).subscribe({
        next: () => {
          resolve(true);
        },
        error: () => {
          resolve(true);
        }
      });
    });
  }

  public checkLanguageUpdate(newLanguage: string) {
    if (!newLanguage) {
      return;
    }
    const mappedNewLanguage = getMappedLanguage(newLanguage);

    if (mappedNewLanguage && mappedNewLanguage !== this.mappedLang) {
      window.location.reload();
    }
  }

  public translateResources(resources: object, values?: object) {
    if (!resources) {
      return;
    }

    const translateSubject = new Subject<void>();

    for (const key of Object.keys(resources)) {
      const value = this.translate.instant(key, values);
      resources[key] = value;
    }

    setTimeout(() => {
      translateSubject.next();
    });

    return translateSubject;
  }

  public translateResourcesInstant(resources: object, values?: object) {
    if (!resources) {
      return;
    }

    for (const key of Object.keys(resources)) {
      const value = this.translate.instant(key, values);
      resources[key] = value;
    }
  }

  //  Replace the regex inside the given string and return its value.
  public getStringReplaced(fullString: string, regex: RegExp[], strToReplace: string[]): string {
    let convertedString = fullString;
    for (let i = 0; i < regex.length; i++) {
      convertedString = convertedString.replace(regex[i], strToReplace[i]);
    }

    return convertedString;
  }

  // Localize action titles
  public translateLanguages(actionList: IAction[], resourcePrefix?: string) {
    const prefix = `${resourcePrefix}_` || '';
    actionList.forEach(action => {
      action.title = action && action.value ? this.translate.instant(`${prefix}${action.value.replace(/-/g, '_').toLocaleLowerCase()}`) : '';
    });
  }

  public instant(key: string, values = {}) {
    if (!key) {
      return;
    }

    return this.translate.instant(key, values);
  }

  public getLanguage() {
    return this.mappedLang;
  }

  public getLocaleLanguage(localeKey: string) {
    return this.translate.instant('Language_' + localeKey.replace(/-/g, '_').toLowerCase());
  }
}
