import { IHttpWrapper } from '../../interfaces';
import { CustomizationPeopleModelsApi } from './people-models-api.class';
import { CustomizationPeopleApi } from './people-api.class';
import { CustomizationFacesApi } from './faces-api.class';
import { CustomizationBrandsModelsApi } from './brands-models-api.class';
import { CustomizationLanguageModelsApi } from './language-models-api';
import { CustomizationSpeechApi } from './speech-api.class';
import { CustomizationLogoGroupsApi } from './logo-groups-api.class';
import { CustomizationUnknownPersonsApi } from './unknown-person-api.class';

export class CustomizationApi {
  public PeopleModels: CustomizationPeopleModelsApi;
  public People: CustomizationPeopleApi;
  public UnknownPersons: CustomizationUnknownPersonsApi;
  public Faces: CustomizationFacesApi;
  public BrandsModels: CustomizationBrandsModelsApi;
  public LanguagesModels: CustomizationLanguageModelsApi;
  public Speech: CustomizationSpeechApi;
  public LogoGroups: CustomizationLogoGroupsApi;

  constructor(private ApiWrapper: IHttpWrapper) {
    this.PeopleModels = new CustomizationPeopleModelsApi(this.ApiWrapper);
    this.UnknownPersons = new CustomizationUnknownPersonsApi(this.ApiWrapper);
    this.People = new CustomizationPeopleApi(this.ApiWrapper);
    this.Faces = new CustomizationFacesApi(this.ApiWrapper);
    this.BrandsModels = new CustomizationBrandsModelsApi(this.ApiWrapper);
    this.LanguagesModels = new CustomizationLanguageModelsApi(this.ApiWrapper);
    this.Speech = new CustomizationSpeechApi(this.ApiWrapper);
    this.LogoGroups = new CustomizationLogoGroupsApi(this.ApiWrapper);
  }
}
