import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import { GalleryProcessingProgress, INextPage } from '../models/gallery';
import * as LibraryActions from '../actions/library.actions';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

interface IVideo extends SinglePlaylistSearchResultV2 {
  prevProcessingProgress?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<IVideo> {
  // additional entities state properties
  selectedVideoId: string;
  loaded: boolean;
  loading: boolean;
  nextPage?: INextPage;
  loadingNext: boolean;
  error: boolean;
  errorType?: APIErrors;
}

function selectVideoId(a: IVideo): string {
  return a.id;
}

const adapter: EntityAdapter<IVideo> = createEntityAdapter<IVideo>({
  selectId: selectVideoId
});

const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedVideoId: null,
  loaded: false,
  loading: false,
  nextPage: {
    pageSize: null,
    skip: null,
    done: false
  },
  loadingNext: false,
  error: false,
  errorType: null
});

const libraryReducer = createReducer(
  initialState,
  on(LibraryActions.updateVideo, (state, { video }) => {
    return adapter.upsertOne(video, state);
  }),
  on(LibraryActions.upsertVideos, (state, { videos, nextPage }) => {
    return adapter.upsertMany(videos, {
      ...state,
      nextPage: nextPage,
      loaded: true,
      loading: false,
      loadingNext: false
    });
  }),
  on(LibraryActions.deleteVideo, (state, { id }) => {
    // save the current processing progress so we can restore it if the delete fails
    const prevProcessingProgress = state.entities[id].processingProgress;

    return adapter.updateOne({ id: id, changes: { prevProcessingProgress, processingProgress: GalleryProcessingProgress.DELETING } }, state);
  }),
  on(LibraryActions.deleteVideoSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(LibraryActions.deleteVideoFailed, (state, { id }) => {
    // restore the processing progress from before the delete operation
    const processingProgress = state.entities[id].prevProcessingProgress;

    return adapter.updateOne({ id: id, changes: { processingProgress, prevProcessingProgress: null } }, state);
  }),
  on(LibraryActions.deleteVideos, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(LibraryActions.clearLibrary, (state, {}) => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      loading: false,
      nextPage: {
        pageSize: null,
        skip: null,
        done: false
      },
      loadingNext: false,
      error: false,
      selectedVideoId: null,
      errorType: null
    });
  }),
  on(LibraryActions.loadLibraryNextPage, (state, {}) => {
    return {
      ...state,
      loadingNext: true
    };
  }),
  on(LibraryActions.loadLibraryError, (state, { errorType }) => {
    return {
      ...state,
      error: true,
      errorType
    };
  }),
  on(LibraryActions.loadingLibrary, (state, {}) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(LibraryActions.updateVideoName, (state, { id, name }) => {
    return adapter.updateOne({ id: id, changes: { name: name } }, state);
  }),
  on(LibraryActions.updateThumbnailId, (state, { id, thumbnailId }) => {
    return adapter.updateOne({ id: id, changes: { thumbnailId: thumbnailId } }, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return libraryReducer(state, action);
}
