import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromBrandModels from './brands-model.reducer';
import * as fromLanguageId from './language-id.reducer';
import * as fromLanguageModels from './language-model.reducer';
import * as fromPersonModels from './person-model.reducer';
import * as fromUnknownPersons from './people/unknown-persons.reducer';
import * as fromPeoplePage from './people/people-page.reducer';
import * as fromSpeech from './speech/speech.reducer';
import * as fromSupportedLanguagesIds from './supported-languages-ids.reducer';
import * as fromSupportedLanguages from './supported-languages.reducer';
import * as fromEdgeSupportedLanguages from './edge-supported-languages.reducer';
import * as fromLogoGroups from './logo-groups.reducer';

export interface IState {
  languageModels: fromLanguageModels.IState;
  supportedLanguages: fromSupportedLanguages.IState;
  personModels: fromPersonModels.IState;
  brandModels: fromBrandModels.IState;
  logoGroups: fromLogoGroups.IState;
  speech: fromSpeech.IState;
  languageId: fromLanguageId.IState;
  supportedLanguagesIds: fromSupportedLanguagesIds.IState;
  edgeSupportedLanguages: fromEdgeSupportedLanguages.IState;
  unknownPersons: fromUnknownPersons.IState;
  peoplePage: fromPeoplePage.IState;
}

export const reducers: ActionReducerMap<IState> = {
  languageModels: fromLanguageModels.reducer,
  supportedLanguages: fromSupportedLanguages.reducer,
  personModels: fromPersonModels.reducer,
  unknownPersons: fromUnknownPersons.reducer,
  peoplePage: fromPeoplePage.reducer,
  brandModels: fromBrandModels.reducer,
  logoGroups: fromLogoGroups.reducer,
  speech: fromSpeech.reducer,
  languageId: fromLanguageId.reducer,
  supportedLanguagesIds: fromSupportedLanguagesIds.reducer,
  edgeSupportedLanguages: fromEdgeSupportedLanguages.reducer
};

export const selectCoreState = createFeatureSelector<IState>('customizationData');
