import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { RegionType } from '@common/modules/api/interfaces';

import { VIRoutingMap } from '../../app/routing/routes';
import { MediaStoreService } from '../../media-data/services/media-store.service';
import { CoreStoreService } from '../services/core-store.service';

export const MediaMetadataResolver: ResolveFn<Observable<boolean>> = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(MediaMetadataResolverService).resolve(next);
};

@Injectable({
  providedIn: 'root'
})
export class MediaMetadataResolverService {
  constructor(
    private logger: LoggerService,
    private coreStore: CoreStoreService,
    private mediaStoreService: MediaStoreService,
    private apiService: ApiService
  ) {
    this.logger.log('[MediaMetadataResolver] Init');
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.coreStore.isFullMetadataLoaded$.pipe(
      filter(loaded => loaded),
      withLatestFrom(this.coreStore.selectAccountIds$),
      take(1),
      map(([loaded, accountIds]) => {
        this.logger.log('[MediaMetadataResolver] ' + loaded);
        if (route.routeConfig.path === VIRoutingMap.mediaVideo.path) {
          this.setMediaApiLocation(route, accountIds);
          this.mediaStoreService.loadVideoIndex();
        } else {
          this.mediaStoreService.loadVideoProject();
        }
        return true;
      })
    );
  }

  private setMediaApiLocation(route: ActivatedRouteSnapshot, accountIds: string[]) {
    const accountId = route?.params?.accountId;
    let locationParam: string;
    // If video belongs to the user, it should use the selected account location
    if (accountIds?.includes(accountId)) {
      return;
      // If video does not belong to user should set location according to URL parameter
    } else {
      locationParam = route.queryParams.location || RegionType.TRIAL;
    }
    this.apiService.setApiMediaLocation(locationParam);
  }
}
