import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, EMPTY, catchError, of, take, delay, withLatestFrom } from 'rxjs';

import { FeatureSwitch, NavigationState } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { ApiService } from '@common/modules/api/services/api.service';
import { IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import * as MediaActions from '@common/modules/media-data-common/actions/media.actions';
import { guid } from '@common/modules/utils/string';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';

import { UIShellActionType } from '../../../shell/interfaces';
import { defaultLangKey } from './../../../core/languages';
import * as PersonModelActions from '../../../customization-data/actions/person-model.actions';
import * as LogoGroupsActions from '../../../customization-data/actions/logo-groups.actions';
import * as IndexingActions from '../actions';
import * as fromIndexing from '../selectors';
import * as fromLanguageId from './../../../customization-data/selectors/language-id.selectors';
import * as fromCore from '../../../core/selectors';
import * as fromCustomization from '../../../customization-data/selectors';
import * as GalleryActions from '../../../gallery/core/actions/gallery.actions';
import * as fromGallery from '../../../gallery/core/selectors';
import { IIndexingSettings, IndexingMode } from '../../interfaces';
import { resources } from '../../resources';
import { IState } from '../reducers/index';
import { ReIndexDialogComponent } from '../../components/re-index/re-index-dialog/re-index-dialog.component';
import {
  convertToReIndexParams,
  getCustomLanguageModelParam,
  getExcludeSensitiveAIsParams,
  getLanguageParam,
  getCustomLanguagesParam,
  getExcludeAIsParams
} from './utils/indexing-utils';
import { CoreStoreService } from '../../../core/services/core-store.service';
import { FailureCode } from '../../components/re-index/interfaces';
import { getReIndexErrorType } from './utils/indexing-error-utils';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

@Injectable()
export class ReIndexEffects {
  public reIndexDialogRef: MatDialogRef<DialogComponent>;
  public readonly HANDLE_RE_INDEX_SUCCESS = 750;

  public openReIndexDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndexingActions.openReIndexDialog),
      switchMap(({ videoId }) => of(videoId).pipe(withLatestFrom(this.store.select(fromGallery.selectVideo(videoId))))),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCustomization.isPeopleModelsLoaded)),
      withLatestFrom(this.store.select(fromCustomization.isLogoGroupsLoaded)),
      switchMap(([[[[videoId, video], accountId], isPeopleModelsLoaded], isLogoGroupsLoaded]) => {
        if (this.featureSwitchService.featureSwitch(FeatureSwitch.NewUploadExperience)) {
          if (!isPeopleModelsLoaded) {
            this.store.dispatch(PersonModelActions.loadPersonModels({}));
          }
          if (!isLogoGroupsLoaded) {
            this.store.dispatch(LogoGroupsActions.logoGroupsLoad());
          }
          this.store.dispatch(IndexingActions.resetIndexingViewState());
          this.store.dispatch(IndexingActions.resetReIndexState());
          this.store.dispatch(IndexingActions.loadVideoIndex());
          this.openReIndexDialog();
          this.store.dispatch(IndexingActions.updateIndexingMode({ mode: IndexingMode.ReIndex }));
          return this.apiService.Account.Video.getVideoIndex(accountId, videoId).pipe(
            switchMap(videoIndex => {
              if (videoIndex?.videos[0] && videoIndex.videos[0].failureCode === FailureCode.VIDEO_URL_UNREACHABLE) {
                this.trackService.track('re_index_dialog.get_video_index.succeeded.video_url_unreachable', {
                  category: EventCategory.RE_INDEX
                });
                return [IndexingActions.getVideoIndexFailed({ errorType: FailureCode.VIDEO_URL_UNREACHABLE })];
              }

              this.trackService.track('re_index_dialog.get_video_index.succeeded', { category: EventCategory.RE_INDEX });
              const streamingPreset = (video as SinglePlaylistSearchResultV2).streamingPreset;
              const reviewState = videoIndex?.videos[0]?.reviewState;
              const moderationState = videoIndex?.videos[0]?.moderationState;

              return [
                IndexingActions.initReIndexIndexingSettings({ videoIndex, streamingPreset, reviewState, moderationState }),
                IndexingActions.initVideoId({ videoId }),
                IndexingActions.loadSupportedAIs(),
                IndexingActions.validateReIndexSettingReady()
              ];
            }),
            catchError(error => {
              this.trackService.track('re_index_dialog.get_video_index.failed', { category: EventCategory.RE_INDEX, data: { error: error?.error } });
              return [IndexingActions.getVideoIndexFailed({ errorType: getReIndexErrorType(error) })];
            })
          );
        }

        return EMPTY;
      })
    )
  );

  public checkReIndexAdvancedSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndexingActions.validateReIndexSettingReady),
      withLatestFrom(this.store.select(fromCustomization.isPeopleModelsLoaded)),
      withLatestFrom(this.store.select(fromCustomization.isPeopleModelsLoading)),
      withLatestFrom(this.store.select(fromCustomization.isLogoGroupsLoaded)),
      withLatestFrom(this.store.select(fromCustomization.isLogoGroupsLoading)),
      withLatestFrom(this.store.select(fromIndexing.isSupportedAIsLoaded)),
      switchMap(([[[[[, isPeopleModelsLoaded], isPeopleModelsLoading], isLogoGroupsLoaded], isLogoGroupsLoading], isSupportedAIsLoaded]) => {
        const isSupportedAIsStatusVerified = !this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs) || isSupportedAIsLoaded;
        if (isPeopleModelsLoaded && isLogoGroupsLoaded && isSupportedAIsStatusVerified) {
          return [IndexingActions.updateReIndexIndexingAdvancedSettings()];
        }
        if (!isPeopleModelsLoading && !isPeopleModelsLoaded) {
          this.store.dispatch(PersonModelActions.loadPersonModels({}));
        }
        if (!isLogoGroupsLoading && !isLogoGroupsLoaded) {
          this.store.dispatch(LogoGroupsActions.logoGroupsLoad());
        }

        // wait for people models, logo groups and supported AIs to be loaded. Delay of 1 second and call this effect again
        setTimeout(() => {
          this.store.dispatch(IndexingActions.validateReIndexSettingReady());
        }, 1000);

        return EMPTY;
      })
    )
  );

  public checkReIndexSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndexingActions.updateReIndexIndexingAdvancedSettings),
      withLatestFrom(this.store.select(fromIndexing.selectVideoIndex)),
      withLatestFrom(this.store.select(fromCustomization.getPeopleModels)),
      withLatestFrom(this.store.select(fromCustomization.selectLogoGroups)),
      withLatestFrom(this.store.select(fromCustomization.getTrainedLanguageModels)),
      withLatestFrom(this.store.select(fromCustomization.getTrainedSpeechModels)),
      switchMap(([[[[[, videoIndex], peopleModels], logoGroups], languagesModels], speechModels]) => {
        let actions = [];
        const updatedIndexingSettings: Partial<IIndexingSettings> = {};
        // set default person model when the model has not found
        updatedIndexingSettings.peopleModelId = peopleModels.find(model => model.id === videoIndex?.videos[0].personModelId)
          ? videoIndex?.videos[0].personModelId
          : '';

        updatedIndexingSettings.logoGroupId = logoGroups.find(group => group.id === videoIndex?.videos[0].logoGroupId)
          ? videoIndex?.videos[0].logoGroupId
          : '';
        const videoLangModelId = videoIndex?.videos[0].linguisticModelId;
        // set default language id model when the file was indexed with model and the model has not found
        if (videoLangModelId !== guid(0)) {
          const languageModel = languagesModels.find(model => model.languageModelId === videoLangModelId);
          const speechModel = speechModels.find(model => model.id === videoLangModelId);
          if (!languageModel && !speechModel) {
            updatedIndexingSettings.languageId = defaultLangKey;
            actions.push(IndexingActions.updateOriginalLanguageId({ languageId: defaultLangKey }));
          }
        }
        // set default texts for metadata and description when they are empty
        updatedIndexingSettings.metadata = videoIndex?.videos[0].metadata ?? resources?.NoMetadata;
        updatedIndexingSettings.description = videoIndex?.description ?? resources?.NoDescription;

        actions = actions.concat([
          IndexingActions.updateReIndexIndexingSettings({
            settings: updatedIndexingSettings
          }),
          IndexingActions.indexingSettingsLoaded()
        ]);
        return actions;
      })
    )
  );

  public reIndexVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndexingActions.reIndexVideo),
      withLatestFrom(this.store.select(fromIndexing.selectVideoId)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromIndexing.selectIndexingSettings)),
      withLatestFrom(this.store.select(fromIndexing.getLanguage)),
      withLatestFrom(this.store.select(fromLanguageId.selectSelectedLanguagesKeys)),
      switchMap(([[[[[, videoId], accountId], indexingSettings], language], selectSelectedLanguagesKeys]) => {
        this.trackService.track('re_index_dialog.re_index_button.clicked', { category: EventCategory.RE_INDEX });
        const isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);

        const languageSettings = getLanguageParam(language);
        const languageModelSettings = getCustomLanguageModelParam(language);
        const customLanguagesSettings = getCustomLanguagesParam(language, selectSelectedLanguagesKeys);
        const reIndexParams = convertToReIndexParams(indexingSettings, languageSettings, languageModelSettings, customLanguagesSettings);
        const excludedAIs = isExcludeAIsEnabled ? getExcludeAIsParams(indexingSettings.excludeAIs) : getExcludeSensitiveAIsParams(indexingSettings);
        this.trackService.track('re_index_dialog.re_index.started', { category: EventCategory.RE_INDEX, data: { ...reIndexParams, excludedAIs } });
        return this.apiService.Account.Video.reIndex(accountId, videoId, reIndexParams, undefined, excludedAIs).pipe(
          // Handle re-index response with delay so get processing will be with updated result
          delay(this.HANDLE_RE_INDEX_SUCCESS),
          switchMap(() => {
            if (!isExcludeAIsEnabled) {
              this.reIndexDialogRef.close();
            }
            this.trackService.track('re_index_dialog.re_index.succeeded', { category: EventCategory.RE_INDEX });
            return [
              IndexingActions.reIndexVideoSucceeded(),
              GalleryActions.loadProcessingVideoOrProjectById({ id: videoId }),
              MediaActions.removeMediaIndex({ id: videoId })
            ];
          }),
          catchError(error => {
            this.trackService.track('re_index_dialog.re_index.failed', { category: EventCategory.RE_INDEX, data: { error: error?.error } });
            return [IndexingActions.reIndexVideoFailed({ errorType: getReIndexErrorType(error) })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<IState>,
    private featureSwitchService: FeatureSwitchService,
    private dialogService: DialogService,
    private coreStoreService: CoreStoreService,
    private apiService: ApiService,
    private trackService: TrackService,
    private translate: TranslateHelperService,
    private navigationService: AppNavigationService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.translate.translateResourcesInstant(resources);
  }

  private openReIndexDialog() {
    const reIndexDialogData: IDialogData = {
      class: 're-index-dialog',
      component: ReIndexDialogComponent,
      hideHeader: true
    };

    const dialogWidth = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs) ? '980px' : '840px';

    this.reIndexDialogRef = this.dialogService.openDialog(
      reIndexDialogData,
      dialogWidth,
      '800px',
      're-index-dialog-container',
      true,
      true,
      'auto',
      true
    );
    this.initDialogCloseEvent();
    this.trackService.track('re_index_dialog.init', { category: EventCategory.RE_INDEX });
  }

  private initDialogCloseEvent() {
    this.reIndexDialogRef.componentInstance.actionChange.pipe(take(1)).subscribe(event => {
      this.trackService.track('re_index_dialog.closed', { category: EventCategory.RE_INDEX });
      if (event.dialogEventData?.customizationPageToNavigate) {
        this.coreStoreService.navigateToCustomization(event.dialogEventData.customizationPageToNavigate);
        this.trackService.track('re_index_dialog.navigate', {
          category: EventCategory.RE_INDEX,
          data: { page: event.dialogEventData.customizationPageToNavigate }
        });
      }

      if (event.dialogEventData?.navigateToCreateAccount) {
        this.navigationService.createAccountSubject.next(NavigationState.OPEN);
        this.trackService.track('re_index_dialog.navigate', {
          category: EventCategory.RE_INDEX,
          data: { page: UIShellActionType.CREATE_ACCOUNT }
        });
      }
    });
  }
}
