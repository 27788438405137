import { createReducer, Action, on } from '@ngrx/store';

import { NavigationState } from '@common/modules/core/services/interfaces';

import { SidebarPages } from '../../sidebar/interfaces';
import * as SidebarActions from '../actions/sidebar.actions';

export interface IState {
  // additional entities state properties
  state: NavigationState;
  page?: SidebarPages;
  callbackAfterClose?: () => void;
}

const initialState: IState = {
  state: NavigationState.CLOSE,
  page: SidebarPages.ANY,
  callbackAfterClose: null
};

const sidebarReducer = createReducer(
  initialState,
  on(SidebarActions.selectSidebarOptions, (state, { options }) => {
    return {
      ...state,
      state: getSidebarNavigationState(state, options),
      page: options.page,
      callbackAfterClose: options.callbackAfterClose
    };
  }),
  on(SidebarActions.clearSidebar, (state, {}) => {
    return {
      ...state,
      state: NavigationState.CLOSE,
      page: SidebarPages.ANY,
      callbackAfterClose: null
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return sidebarReducer(state, action);
}

function getSidebarNavigationState(currentState: IState, optionsState: IState): NavigationState {
  if (optionsState.state === NavigationState.CLOSE || optionsState.state === NavigationState.OPEN) {
    return optionsState.state;
  } else if (optionsState.state === NavigationState.TOGGLE) {
    if (currentState.page === optionsState.page) {
      return currentState.state === NavigationState.CLOSE ? NavigationState.OPEN : NavigationState.CLOSE;
    } else if (currentState.page !== optionsState.page && currentState.state === NavigationState.CLOSE) {
      return NavigationState.OPEN;
    } else {
      return currentState.state;
    }
  }
}
