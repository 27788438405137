<div class="c-progress f-indeterminate-local f-progress-{{size}}"
  [ngClass]="{invert: invert}"
  role="alert"
  tabindex="0"
  aria-busy="true"
  [attr.aria-label]="resources?.Loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
<div *ngIf="label"
  class="label-{{labelSize}}">
  {{label}}
</div>
