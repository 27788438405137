<header role="banner">
  <div class="header-skip-main-content"
    *ngIf="showSkipMainContent"
    role="button"
    tabindex="0"
    [attr.aria-label]="resources.HeaderComponentSkipMainContent"
    [attr.title]="resources.HeaderComponentSkipMainContent"
    (blur)="onBlurSkipContent($event)"
    (keyup.enter)="onEnterSkipContent($event)">
    <span role="none">{{resources.HeaderComponentSkipMainContent}}</span>
  </div>
  <!-- Empty div for Skip to main content focus -->
  <div [attr.tabindex]="mainContentTriggerTabIndex"
    (focus)="onHeaderFocus($event)"></div>
  <div class="header-main">
    <div class="top-left"
      [ngClass]="{'mobile': isMobile}">
      <app-vi-action-button *ngIf="(coreMetadataLoaded$ | async) && showSideMenuToggle"
        id="headerSideMenuToggle"
        class="header-button side-menu"
        [type]="ActionButtonType.HEADER_BUTTON"
        [action]="sidemenuToggle"
        [whiteFocus]="true"
        [showAccessibilityTooltip]="true"
        (actionClick)="handleToggleSideMenu(accounts)">
      </app-vi-action-button>
      <div class="app-logo"
        role="button"
        tabindex="0"
        [attr.aria-label]="logoTextTitle"
        [title]="logoTextTitle"
        (appAccessibleClick)="handleLogoClick()">
        {{logoText}}
      </div>
      <div class="app-logo short"
        tabindex="0"
        role="button"
        [attr.aria-label]="logoTextShortTitle"
        [title]="logoTextShortTitle"
        (appAccessibleClick)="handleLogoClick()">
        {{logoTextShort}}
      </div>
    </div>
    <div class="topRight"
      *ngIf="(coreMetadataLoaded$ | async) && isUserAuthenticated && preset === HeaderPreset.FULL">
      <!--Connect-To-Azure-button-->
      <div class="containerConnectToAzure"
        *ngIf="showConnectToAzure()">
        <app-vi-action-button id="connectToAzure"
          [type]="ActionButtonType.SECONDARY"
          [whiteFocus]="true"
          [action]="connectToAzureAction"
          (actionClick)="connectToAzure()">
        </app-vi-action-button>
      </div>

      <!--Accounts-list-button-->
      <vi-accounts-list-button *ngIf="!isZeroAccounts"
        id="headerAccountsBtn"
        [selected]="accounts.selected"
        (toggle)="handleButtonClick(accounts)"></vi-accounts-list-button>

      <!--Notifications-button-->
      <app-vi-action-button *ngIf="isNotificationCenterEnabled"
        id="headerNotificationsBtn"
        class="header-button"
        [type]="ActionButtonType.HEADER_BUTTON"
        [whiteFocus]="true"
        [action]="notifications"
        [showAccessibilityTooltip]="true"
        [selected]="notifications.selected"
        (actionClick)="handleButtonClick(notifications)">
        <div *ngIf="(notClearedNotificationsCount$ | async) > 0 && (notificationsLoading$ | async) === false"
          [ngClass]="{'selected': (selectedAction?.id === notifications?.id) && selectedAction.selected }"
          class="notification-badge">{{notClearedNotificationsCount$ | async}}</div>
      </app-vi-action-button>

      <!--Settings-button-->
      <app-vi-action-button id="headerSettingsBtn"
        class="header-button"
        [type]="ActionButtonType.HEADER_BUTTON"
        [whiteFocus]="true"
        [action]="settings"
        [showAccessibilityTooltip]="true"
        [selected]="settings.selected"
        (actionClick)="handleButtonClick(settings)">
      </app-vi-action-button>

      <!--Help-button-->
      <app-vi-action-button id="headerHelpBtn"
        class="header-button"
        [type]="ActionButtonType.HEADER_BUTTON"
        [whiteFocus]="true"
        [action]="help"
        [showAccessibilityTooltip]="true"
        [selected]="help.selected"
        (actionClick)="handleButtonClick(help)">
      </app-vi-action-button>

      <!--Mobile-menu-button-->
      <app-actions-menu class="mobile-menu"
        [id]="'mobileMenu'"
        [calcWidth]="false"
        [action]="menu"
        [actionList]="menuList"
        [type]="menuType"
        (actionChange)="handleButtonClick($event)"
        (isMenuOpened)="ToggleMobileMenu($event)">
      </app-actions-menu>

      <!--User-button-->
      <div class="user-menu-container"
        role="presentation"
        tabindex="-1"
        [appAccessibilitySubMenuHandler]="userMenuExpanded"
        [toggleFuncOnNullRelatedTarget]="true"
        (toggleFunc)="userMenuExpanded = false">
        <button tabindex="0"
          id="userButton"
          [title]="userDetails.name"
          [attr.aria-label]="userDetails.name"
          [attr.aria-expanded]="userMenuExpanded"
          (appAccessibleClick)="handleToggleUserMenu($event)"
          appAccessibilityTooltip
          [showAccessibilityTooltip]="accessibilityMode && userDetails?.name !== ''"
          role="button">
          <img [src]="userDetails?.imageUrl || ''"
            tabindex="-1"
            [alt]="resources.HeaderUserImageAlt"
            [appViImageIconFallback]="'i-vi-user'" />
        </button>
        <app-vi-user-menu class="user-menu"
          [show]="userMenuExpanded"
          [userDetails]="userDetails"
          (close)="userMenuExpanded = false">
        </app-vi-user-menu>
      </div>
    </div>
  </div>
  <vi-header-strips>
  </vi-header-strips>
</header>