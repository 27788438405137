import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';

import { combineLatest, Subject, takeUntil } from 'rxjs';

import { IndexingStoreService } from 'src/apps/web/src/indexing/services/indexing-store.service';
import { CustomizationDataStoreService } from 'src/apps/web/src/customization-data/services/customization-data-store.service';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { guid } from '@common/modules/utils/string';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { CustomizationPage } from '@common/modules/core/services/interfaces';

import { resources } from './resources';
import { manageBrandCategoriesAction, brandDefaultAction } from './actions';

export interface IBrandsModel {
  name: string;
  value: string;
}

@Component({
  selector: 'vi-indexing-settings-brand-categories',
  templateUrl: './indexing-settings-brand-categories.component.html',
  styleUrls: ['./indexing-settings-brand-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSettingsBrandCategoriesComponent implements OnInit, OnDestroy {
  @Input() public isDisabled = false;
  @Output() public manageBrandsClicked = new EventEmitter<CustomizationPage>();

  public ActionButtonType = ActionButtonType;
  public isLoading: boolean;
  public resources = resources;
  public dropdownId: string = 'brandsCategoriesDropdown_' + guid();
  public manageBrandsCategoriesAction: IAction = manageBrandCategoriesAction;
  public brandsModelActions: IAction[] = [];
  public defaultBrandCategory: IAction = brandDefaultAction;
  public menuPlaceholder: string;
  public actionButtonWidth: string = 'max-content';

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private customizationDataStoreService: CustomizationDataStoreService,
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.translate.translateResourcesInstant(this.resources);
    this.manageBrandsCategoriesAction.title = this.resources.ManageBrandCategories;
    this.menuPlaceholder = this.resources.SettingsLoading;
    this.defaultBrandCategory.title = this.resources.UploadServiceDefaultName;
    this.defaultBrandCategory.group = this.resources.UploadSelectBrandsAllBrands;

    this.loadBrands();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onManageBrandsCategories() {
    this.manageBrandsClicked.emit(CustomizationPage.Brands);
  }

  public onSelectBrandsModel(brandModels: IAction[]) {
    // Create from Actions to BrandsModel
    const brandList: IBrandsModel[] = [];
    brandModels.forEach(element => {
      brandList.push({ name: element.value, value: element.value });
    });

    const brandsCategories = brandList.length ? this.utilsService.parseArrayToString(brandList) : '';
    this.indexingStore.updateBrandCategory(brandsCategories);
  }

  private loadBrands() {
    combineLatest([
      this.customizationDataStoreService.brands,
      this.customizationDataStoreService.isBrandModelsLoaded(),
      this.indexingStore.selectBrandCategories$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([brands, isLoaded, selectedBrandCategories]) => {
        if (isLoaded && !this.brandsModelActions.length) {
          this.setBrandsActions(brands, selectedBrandCategories);
          this.menuPlaceholder = this.resources?.CheckboxSelectLabel;
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private setBrandsActions(brands: Microsoft.VideoIndexer.Contracts.BrandContractV2[], selectedBrandCategories: string) {
    const selectedCategories = selectedBrandCategories?.split(',');
    this.brandsModelActions = [];

    brands.forEach(brand => {
      brand.tags.forEach(category => {
        // Add brand category to the list only if it isn't already exist.
        if (category && this.brandsModelActions.find(action => action.value === category) === undefined) {
          const isSelected = selectedCategories ? selectedCategories.includes(category) : false;
          const brandsModelAction: IAction = {
            title: category,
            value: category,
            id: brand.id.toString(),
            selected: isSelected,
            group: this.resources.UploadSelectBrandsAllBrands
          };
          this.brandsModelActions.push(brandsModelAction);
        }
      });
    });

    // Add brand default action to the list
    this.defaultBrandCategory.selected = selectedBrandCategories == null || selectedCategories.includes('Default');
    this.brandsModelActions.unshift(this.defaultBrandCategory);
  }
}
