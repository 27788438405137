export interface ISummaryRow {
  label: string;
  value: string;
  show: boolean;
  subRows?: ISummaryRow[];
}

export enum RowValueColor {
  Secondary = 'secondary',
  Primary = 'primary'
}
