<fluent-checkbox [checked]="checked"
  class="{{size}} size-{{labelSize}}"
  [ngClass]="{accessible: accessibilityMode, 'no-label': !label}"
  [disabled]="disabled || null"
  [ariaLabel]="ariaLabel"
  tabindex="0"
  [attr.aria-required]="true"
  [attr.aria-checked]="checked"
  [attr.aria-label]="ariaLabel ?? label"
  [attr.title]="ariaLabel ?? label"
  [id]="id"
  (change)="onCheckboxChange($event)">
  <label part="label">{{label}}</label>
</fluent-checkbox>
