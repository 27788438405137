export interface NavBarItem {
  id: string;
  text: string;
  key?: string;
  hidden?: boolean;
  disable?: boolean;
  children?: NavBarItem[];
  expanded?: boolean;
  expandTitleAttribute?: string;
  collapseTitleAttribute?: string;
}
