import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as fromCustomizationDataState from '../../reducers/index';
import * as fromCustomizationData from '../../selectors';
import * as SpeechDatasetsActions from '../../actions/speech/speech-datasets.actions';
import * as SpeechDatasetActions from '../../actions/speech/speech-datasets.actions';
import { ISpeechDatasetDetails } from '../../reducers/speech/interfaces';
import { IUISpeechDatasetTableDetail } from '../../interfaces';

import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;

@Injectable()
export class SpeechDatasetStoreService {
  constructor(private readonly store: Store<fromCustomizationDataState.IState>) {}

  public loadSpeechData() {
    this.store.dispatch(SpeechDatasetsActions.loadSpeechDatasets());
  }

  public uploadSpeechDatasetClicked() {
    this.store.dispatch(SpeechDatasetActions.uploadButtonClicked());
  }

  public getSpeechDatasetById(id: string): Observable<ISpeechDatasetDetails> {
    return this.store.select(fromCustomizationData.getSpeechDatasetById(id));
  }

  public isLoadDatasetDetailsError(): Observable<boolean> {
    return this.store.select(fromCustomizationData.getSpeechDatasetError);
  }

  public isSpeechDatasetFilesError(id: string): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechDatasetFilesError(id));
  }

  public getSpeechDatasets(): Observable<ISpeechDatasetDetails[]> {
    return this.store.select(fromCustomizationData.getSpeechDatasets);
  }

  public getSpeechCompleteDatasetsByLocale(locale: string): Observable<ISpeechDatasetDetails[]> {
    return this.store.select(fromCustomizationData.getSpeechCompleteDatasetsByLocale(locale));
  }

  public getSpeechDatasetsByIds(datasetIds: string[]): Observable<ISpeechDatasetDetails[]> {
    return this.store.select(fromCustomizationData.getSpeechDatasetsByIds(datasetIds));
  }

  public isSpeechDatasetsLoaded(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechDatasetsLoaded);
  }

  public updateDataset(datasetId: string, properties: Pick<SpeechDataset, 'displayName' | 'description' | 'customProperties'>) {
    this.store.dispatch(SpeechDatasetActions.updateSpeechDataset({ datasetId, properties }));
  }

  public openEditSpeechDatasetDialog(dataset: IUISpeechDatasetTableDetail) {
    this.store.dispatch(SpeechDatasetActions.openEditSpeechDatasetDialog({ dataset }));
  }

  public isDatasetUpdating(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isDatasetUpdating);
  }

  public openDeleteDatasetDialog(dataset: IUISpeechDatasetTableDetail) {
    this.store.dispatch(SpeechDatasetActions.openDeleteSpeechDatasetDialog({ dataset }));
  }

  public downloadDataset(datasetId: string) {
    this.store.dispatch(SpeechDatasetActions.downloadSpeechDataset({ datasetId }));
  }

  public openSpeechDatasetDialog(datasetId: string) {
    this.store.dispatch(SpeechDatasetActions.openViewSpeechDatasetDialog({ datasetId }));
  }

  public isSelectedDatasetsForTrainModel(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSelectedDatasetsForTrainModel);
  }

  public getSelectedDatasetsIds(): Observable<string[]> {
    return this.store.select(fromCustomizationData.getSelectedDatasetsIds);
  }

  public selectedDatasetsExists(): Observable<boolean> {
    return this.store.select(fromCustomizationData.selectedDatasetsExists);
  }

  public toggleSpeechDatasetSelection(datasetId: string) {
    this.store.dispatch(SpeechDatasetActions.toggleSpeechDatasetSelection({ datasetId }));
  }

  public clearSpeechDatasetSelection() {
    this.store.dispatch(SpeechDatasetActions.clearSpeechDatasetSelection());
  }
}
