import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { combineLatestWith, take, takeUntil } from 'rxjs/operators';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { IViewControlChangedEvent, ViewActionType } from '@common/modules/shared/components/role-based-view-control-wrapper/interfaces';
import { DataService } from '@common/modules/shared/services/data.service';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { AccountPermission } from '@common/modules/shared/interfaces';
import { SideMenuOption } from '@common/modules/core/services/interfaces';

import { resources } from './resources';
import { CoreStoreService } from '../../../core/services/core-store.service';
import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';

@Component({
  selector: 'vi-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() public isMobile = false;
  public isOpened = false;
  public isResponsive = false;
  public option = SideMenuOption.Media;
  public sideMenuOption = SideMenuOption;
  public resources = resources;
  public currentPermission$: Observable<AccountPermission>;
  public AccountPermission = AccountPermission;
  public ViewActionType = ViewActionType;
  public isMenuItemDisabled = false;

  private destroy$ = new Subject<void>();
  private readonly RESPONSIVE_WINDOW_WIDTH = 768;
  private readonly AUTO_CLOSED_WINDOW_WIDTH = 1024;
  private isAutoSideMenuClosed = false;
  private hasEdgeExtensions: boolean;
  private isGalleryRoute: boolean;

  constructor(
    private coreStore: CoreStoreService,
    private translate: TranslateHelperService,
    private cdr: ChangeDetectorRef,
    private trackService: TrackService,
    private focusManager: FocusManagerService,
    private utils: UtilsService,
    private dataService: DataService,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {
    super();
  }

  public get showCustomizationSubMenu(): boolean {
    return this.isResponsive || this.option === SideMenuOption.Customizations;
  }

  public get showExtensionsSubMenu(): boolean {
    return this.hasEdgeExtensions && (this.isResponsive || (this.option === SideMenuOption.Media && this.isGalleryRoute));
  }

  public get subMenuOpened(): boolean {
    return this.showCustomizationSubMenu || this.showExtensionsSubMenu;
  }

  public ngOnInit(): void {
    this.trackService.track('side_menu.init');
    this.translate.translateResources(this.resources).pipe(take(1));

    this.coreStore.selectSideMenuOpen().subscribe(open => {
      if (!this.isMobile && this.isResponsive) {
        if (open) {
          this.focusWithTimeOut('#toggleBtn');
        } else if (!open) {
          this.focusWithTimeOut('#headerToggleSidemenu');
        }
      }
      this.isOpened = open;
      this.cdr.detectChanges();
    });

    this.coreStore.isNetworkAccessNotAllowedError$.pipe(takeUntil(this.destroy$)).subscribe(isNetworkAccessNotAllowed => {
      this.isMenuItemDisabled = isNetworkAccessNotAllowed;
      this.cdr.detectChanges();
    });

    this.edgeExtensionsStore.hasEdgeExtensions$
      .pipe(combineLatestWith(this.coreStore.isGalleryRoute$))
      .subscribe(([hasEdgeExtensions, isGalleryRoute]) => {
        this.hasEdgeExtensions = hasEdgeExtensions;
        this.isGalleryRoute = isGalleryRoute;
        this.cdr.detectChanges();
      });

    this.coreStore.selectSideMenuSelectedOption().subscribe(option => {
      this.option = option;
      this.isOpened == this.isAutoSideMenuClosed && this.subMenuOpened ? false : this.isOpened;
      this.cdr.detectChanges();
    });

    this.accessibilityModeSubject.pipe(takeUntil(this.destroy$), take(1)).subscribe(() => {
      this.cdr.detectChanges();
    });

    if (!this.isMobile) {
      this.checkResponsive();
    }
    // Toggle the side menu if the window is resized
    this.dataService.onResizeEventStream(320, 0).subscribe(() => {
      if (!this.isMobile) {
        this.checkResponsive();
      }
    });

    this.currentPermission$ = this.coreStore.getAccountTokenPermission$.pipe(takeUntil(this.destroy$));
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleSideMenu() {
    this.isOpened = !this.isOpened;
    this.coreStore.sideMenuToggled(this.isOpened);
    this.trackService.track('side_menu.toggle_side_menu.click', {
      data: {
        value: this.isOpened
      }
    });
  }

  public navigate(option: SideMenuOption) {
    if (this.isMenuItemDisabled && (option === SideMenuOption.Customizations || option === SideMenuOption.Settings)) {
      return;
    }
    if (option !== this.option) {
      this.coreStore.navigateFromSideMenu(option);
    }

    if (this.isResponsive) {
      this.toggleSideMenu();
      if (!this.isMobile) {
        this.focusWithTimeOut('.main-content-action', 400);
      }
    }
  }

  public onMenuItemsControlChanged(viewChangedEvent: IViewControlChangedEvent) {
    if (viewChangedEvent.viewAction !== ViewActionType.DISABLE) {
      return;
    }

    this.isMenuItemDisabled = viewChangedEvent.result === false;
  }

  private checkResponsive() {
    this.utils.getBodyRectAsync().then(bodyRect => {
      const currResponsive = bodyRect.width <= this.RESPONSIVE_WINDOW_WIDTH;
      if (this.isOpened && !this.isResponsive && currResponsive) {
        this.focusWithTimeOut('#toggleBtn');
      }
      this.isResponsive = currResponsive;
      this.isAutoSideMenuClosed = bodyRect.width <= this.AUTO_CLOSED_WINDOW_WIDTH;
      this.cdr.detectChanges();
    });
  }

  private focusWithTimeOut(query: string, timeout = 0) {
    // Set timeout to 500ms to make sure the page has loaded
    setTimeout(() => {
      // Focus expanded button
      this.focusManager.focusByQuery(query, this.cdr);
    }, timeout);
  }
}
