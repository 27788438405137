import { createAction, props } from '@ngrx/store';
import { Update, EntityMap } from '@ngrx/entity';

import { AccountPermission } from '@common/modules/shared/interfaces';
import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { SettingsTab } from '../../settings/pages/settings/interfaces';

import AccountContractSlim = Microsoft.VideoIndexer.Contracts.AccountContractSlim;
import AccountContract = Microsoft.VideoIndexer.Contracts.AccountContract;
import UpdateAmsConnectionContract = Microsoft.VideoIndexer.Contracts.UpdateAmsConnectionContract;
import AccountSettings = Microsoft.VideoIndexer.Contracts.AccountSettings;
import LimitedAccessFeaturesContract = Microsoft.VideoIndexer.Contracts.LimitedAccessFeaturesContract;
import AccountQuotaUsage = Microsoft.VideoIndexer.Contracts.AccountQuotaUsage;
import IConnectedClusterExtension = Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension;

export const loadAccounts = createAction('[Account/API] Load Accounts', props<{ accounts: AccountContractSlim[] }>());
export const loadAccountsFailed = createAction('[Account] Load Accounts Failed');
export const getAccounts = createAction('[Account/API] Get Accounts');
export const addAccount = createAction('[Account/API] Add Account', props<{ account: AccountContractSlim }>());
export const upsertAccount = createAction('[Account/API] Upsert Account', props<{ account: AccountContractSlim }>());
export const addAccounts = createAction('[Account/API] Add Accounts', props<{ accounts: AccountContractSlim[] }>());
export const upsertAccounts = createAction('[Account/API] Upsert Accounts', props<{ accounts: AccountContractSlim[] }>());
export const updateMediaServicesAccount = createAction(
  '[Account/API] Update Media Services Account',
  props<{ amsUpdateConfig: UpdateAmsConnectionContract }>()
);
export const updateAccountContract = createAction(
  '[Account/API] Update Account Contract',
  props<{ account: AccountContract; newAccount: AccountContract }>()
);
export const updateAccount = createAction('[Account] Update Account', props<{ account: Update<AccountContractSlim> }>());
export const updateAccountQuota = createAction('[Account] Update Account Quota', props<{ accountQuota: AccountQuotaUsage }>());
export const updateAccountQuotaFailed = createAction('[Account] Update Account Quota Failed');
export const updateSelectedAccount = createAction('[Account] Update Selected Account', props<{ account: AccountContract }>());
export const updateAccounts = createAction('[Account/API] Update Accounts', props<{ accounts: Update<AccountContractSlim>[] }>());
export const updateAccountState = createAction('[Account] Update Account State', props<{ error?: boolean; saving?: boolean }>());
export const mapAccounts = createAction('[Account/API] Map Accounts', props<{ entityMap: EntityMap<AccountContractSlim> }>());
export const selectAccount = createAction(
  '[Account/API] Select Account',
  props<{ id?: string; forceLoad?: boolean; doNotClearSelectedExtension?: boolean }>()
);
export const selectAccountProcessing = createAction('[Account] Select Account Processing');
export const addSelectedAccount = createAction('[Account] Add Selected Account', props<{ account: AccountContract }>());
export const addSelectedAccountFailed = createAction('[Account] Add Selected Account Failed', props<{ errorType: APIErrors }>());
export const deleteOwnAccount = createAction('[Account/API] Delete Account', props<{ id: string }>());
export const deleteOwnAccountSuccess = createAction('[Account] Delete Account Success');
export const recoverOwnAccount = createAction('[Account/API] Recover Account', props<{ id: string }>());
export const recoverOwnAccountSuccess = createAction('[Account] Recover Account Success');
export const leaveAccount = createAction('[Account/API] Leave Account', props<{ id: string }>());
export const deleteAccount = createAction('[Account] Delete Account', props<{ id: string }>());
export const deleteAccounts = createAction('[Account/API] Delete Accounts', props<{ ids: string[] }>());
export const joinAccount = createAction('[Account/API] Join Account', props<{ accountId: string; inviteCode: string; location?: string }>());
export const clearAccounts = createAction('[Account/API] Clear Accounts');
export const accountsLoaded = createAction('[Account] Accounts Loaded');
export const navigateToSettings = createAction('[Account] Navigate To Settings', props<{ tab?: SettingsTab }>());
export const navigateToCustomization = createAction('[Account] Navigate To Customization', props<{ page: CustomizationPage }>());
export const navigateToCamera = createAction('[Account] Navigate To Camera', props<{ cameraId: string }>());
export const navigateToWorkflow = createAction('[Account] Navigate To Workflow', props<{ workflowId: string }>());
export const zeroAccountsLoaded = createAction('[Account] Zero Accounts Loaded');
export const loadAccountSettings = createAction('[Account/API] Load Account Settings', props<{ id: string }>());
export const updateAccountCelebSettings = createAction(
  '[Account/API] Update Account Celeb Settings',
  props<{ includeCelebrityRecognition: boolean }>()
);
export const addAccountSettings = createAction('[Account] Add Account Settings', props<{ settings: AccountSettings }>());
export const loadAccountAccessTokenPermission = createAction(
  '[ArmAccount] Load Account Access Token Permission',
  props<{ permission: AccountPermission }>()
);
export const accountHasRestrictedAccess = createAction('[Account] has restricted access');
export const checkGalleryProjectTabAccess = createAction('[Account] check gallery project tab access');
export const setAccountsFilterValue = createAction('[Account] Set accounts filter value', props<{ value: string }>());
export const clearAccountsFilterValue = createAction('[Account] Clear accounts filter value');
export const acceptUseOfFacialIdentification = createAction(
  '[Account/API] Accept Use Of Facial Identification button clicked',
  props<{ signature: string; useCase: string }>()
);
export const updateFaceGateFeaturesSuccess = createAction(
  '[Account] Update FaceGate Features Success',
  props<{ limitedAccessFeatures: LimitedAccessFeaturesContract }>()
);
export const updateFaceGateFeaturesFailed = createAction('[Account] Update FaceGate Features Failed', props<{ error: Error }>());

// ARM Accounts
export const loadArmAccounts = createAction('[ArmAccount/API] ArmLoad Accounts');
export const loadArmAccountsFailed = createAction('[ArmAccount] Arm Load Accounts Failed');
export const loadArmAccountsSuccess = createAction(
  '[ArmAccount] Arm Load Accounts Succuss',
  props<{ accounts: Microsoft.VideoIndexer.Contracts.AccountContractSlim[] }>()
);
export const loadArmAccountsNotNeeded = createAction('[ArmAccount] Arm Load Accounts Not Needed');
export const loadLastArmAccount = createAction('[ArmAccount] Arm Load Last Account', props<{ accountId: string }>());
export const addSelectedArmAccountFailed = createAction('[ArmAccount] Add Selected Account Failed', props<{ errorType: APIErrors }>());
export const loadArmAccountNotNeededForInit = createAction('[ArmAccount] Arm load Account Not Needed For Init');
export const selectArmAccount = createAction('[ArmAccount/API] Select Account', props<{ id?: string }>());
export const selectArmAccountProcessing = createAction('[ArmAccount] Select Arm Account Processing');
export const selectArmAccountSuccess = createAction('[ArmAccount] Select Arm Account Success');
export const addSelectedArmAccount = createAction('[ArmAccount] Add Selected Arm Account', props<{ account: AccountContract }>());

export const refreshCachedArmAccounts = createAction('[ArmAccount] Refresh Cached Arm Accounts');

export const loadEdgeExtensions = createAction('[EdgeExtension/API] Load Edge Extensions');
export const loadEdgeExtensionsSuccess = createAction(
  '[EdgeExtensions] Load Edge Extensions Success',
  props<{ extensions: IConnectedClusterExtension[] }>()
);
export const loadEdgeExtensionsFailed = createAction('[EdgeExtensions] Load Edge Extensions Failed');
