export const resources = {
  IndexingPresetBasicVideoAudioLabel: '',
  IndexingPresetBasicVideoAudioDetails: '',
  IndexingPresetStandardVideoAudioLabel: '',
  IndexingPresetStandardVideoAudioDetails: '',
  IndexingPresetAdvancedVideoAudioLabel: '',
  IndexingPresetAdvancedVideoAudioDetails: '',
  IndexingPresetBasicVideoLabel: '',
  IndexingPresetBasicVideoDetails: '',
  IndexingPresetStandardVideoLabel: '',
  IndexingPresetStandardVideoDetails: '',
  IndexingPresetAdvancedVideoLabel: '',
  IndexingPresetAdvancedVideoDetails: '',
  IndexingPresetBasicAudioLabel: '',
  IndexingPresetBasicAudioDetails: '',
  IndexingPresetStandardAudioLabel: '',
  IndexingPresetStandardAudioDetails: '',
  IndexingPresetAdvancedAudioLabel: '',
  IndexingPresetAdvancedAudioDetails: '',
  LabelIndexingPresetVideoAudio: '',
  LabelIndexingPresetVideoOnly: '',
  LabelIndexingPresetAudioOnly: '',
  IndexingPresetLabel: '',
  IndexingPresetTooltipText: '',
  IndexingPresetDetailsLabel: '',
  IndexingPresetLearnMoreLinkText: '',
  IndexingPresetExcludeRAICheckboxLabel: '',
  IndexingPresetExcludeRAITooltipText: '',
  IndexingPresetExcludeRAIVideoAdvancedTooltipText: '',
  IndexingPresetExcludeRAIDescription: '',
  IndexingPresetRAILinkText: '',
  IndexingPresetBasicAudioEdgeDetails: '',
  IndexingPresetBasicVideoEdgeDetails: '',
  IndexingPresetBasicVideoAudioEdgeDetails: '',
  IndexingPresetIncludedModelsLabel: '',
  IndexingPresetIncludedModelsDescription: '',
  IndexingPresetLearnMoreModelsLinkText: '',
  IndexingPresetExcludeRAITitle: '',
  IndexingPresetExcludeRAIToggleLabel: '',
  IndexingPresetExcludeSensitiveModelsDescription: '',
  IndexingPresetExcludeSensitiveModelsTooltip: '',
  SupportedAIsFailedTitle: '',
  SupportedAIsFailedContent: ''
};
