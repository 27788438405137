import { IAction } from '@common/modules/shared/interfaces';

export const formatFilterAction: IAction = {
  value: '',
  id: 'formatFilter'
};

export const downloadAction: IAction = {
  key: 'Download',
  title: '',
  value: '',
  id: 'download'
};

export const cancelAction: IAction = {
  key: 'Cancel',
  title: '',
  value: '',
  id: 'cancel'
};
