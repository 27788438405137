import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { fluentCheckbox, provideFluentDesignSystem } from '@fluentui/web-components';

import { FocusableComponent } from '../focusable/focusable.component';
import { CheckboxSize, LabelSize } from './interfaces';
import { guid } from '../../utils/string/utils.string';

@Component({
  selector: 'vi-ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends FocusableComponent implements OnInit {
  @Input() public checked = false;
  @Input() public label?: string;
  @Input() public disabled = false;
  @Input() public size?: CheckboxSize = CheckboxSize.Large;
  @Input() public labelSize?: LabelSize = LabelSize.Large;
  @Input() public ariaLabel?: string;
  @Output() public checkedChange = new EventEmitter<boolean>(false);

  public id = `checkboxWithLabel${guid()}`;

  constructor() {
    super();
  }

  public ngOnInit() {
    provideFluentDesignSystem().register(fluentCheckbox());
  }

  public onCheckboxChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.checked = target.checked;
    this.checkedChange.emit(this.checked);
  }
}
