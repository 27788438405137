import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { INotification, NotificationType } from '@common/modules/core/services/toast/interfaces';
import { ToastService } from '@common/modules/core/services/toast/toast.service';

import * as fromNotificationState from './../../../../apps/web/src/core/reducers/notifications/notifications.reducer';
import * as NotificationsActions from './../../../../apps/web/src/core/actions/notifications.actions';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private store: Store<fromNotificationState.IState>, private toastService: ToastService) {}

  public notify(notification: INotification) {
    switch (notification.type) {
      case NotificationType.Message:
        this.store.dispatch(NotificationsActions.addNotificationMessages({ notifications: [notification] }));
        break;
      case NotificationType.Alert:
        this.store.dispatch(NotificationsActions.addNotificationsAlerts({ notifications: [notification] }));
        break;
      case NotificationType.Banner:
        this.store.dispatch(NotificationsActions.addNotificationsBanners({ notifications: [notification] }));
        break;
      default:
        this.toastService.notify(notification);
        break;
    }
  }

  public clear(ids: string[]) {
    this.store.dispatch(NotificationsActions.removeNotifications({ ids }));
  }
}
