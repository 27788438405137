import { Action, createReducer, on } from '@ngrx/store';

import { SpeechTab } from '../../../customization/components/speech/speech-container/interfaces';
import * as SpeechPageActions from '../../actions/speech/speech-page.actions';
import { SpeechPermission } from '../../interfaces';

export interface IState {
  selectedTab: SpeechTab;
  permission: SpeechPermission;
}

export const speechPageInitialState: IState = {
  selectedTab: SpeechTab.DATASETS,
  permission: SpeechPermission.UNDEFINED
};

const speechPageReducer = createReducer(
  speechPageInitialState,
  on(SpeechPageActions.initSelectedSpeechTab, (state, { tab }) => {
    return {
      ...state,
      selectedTab: tab
    };
  }),
  on(SpeechPageActions.selectSpeechTab, (state, { tab }) => {
    return {
      ...state,
      selectedTab: tab
    };
  }),
  on(SpeechPageActions.initSpeechPagePermission, (state, { permission }) => {
    return {
      ...state,
      permission: permission
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return speechPageReducer(state, action);
}
