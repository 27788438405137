import { Injectable, ViewContainerRef, NgModuleFactory, Compiler, Injector } from '@angular/core';

import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {
  // eslint-disable-next-line deprecation/deprecation
  constructor(private logger: LoggerService, private compiler: Compiler, private injector: Injector) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async loadComponent(LazyModule: any, LazyComponent: any, ref: ViewContainerRef, data?) {
    const moduleFactory = await this.loadModuleFactory(LazyModule);
    const moduleRef = moduleFactory.create(this.injector);
    // eslint-disable-next-line deprecation/deprecation
    const factory = moduleRef.componentFactoryResolver.resolveComponentFactory(LazyComponent);
    // eslint-disable-next-line deprecation/deprecation
    const componentRef = ref.createComponent(factory);

    if (data) {
      Object.assign(
        componentRef.instance,
        // for backward compatibility
        { data },
        // spread the date object on component instance
        data
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async loadModuleFactory(t: any) {
    // eslint-disable-next-line deprecation/deprecation
    if (t instanceof NgModuleFactory) {
      return t;
    } else {
      return await this.compiler.compileModuleAsync(t);
    }
  }
}
