import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { MULTI_LANGUAGE_LINK, UPLOAD_LIMITATION_LINK } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { UIActionType } from '@common/modules/insights/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { getKeyCode, KeyCode } from '@common/modules/utils/event';
import { guid } from '@common/modules/utils/string';

import { resources } from './resources';
import { LanguageIdStoreService } from './../../../../../customization-data/services/language-id-store.service';
import { IndexingStoreService } from './../../../../services/indexing-store.service';
import * as actions from './actions';
import { SpecialLanguageKey } from './../../../../../core/languages';

@Component({
  selector: 'vi-indexing-language',
  templateUrl: './indexing-language.component.html',
  styleUrls: ['./indexing-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingLanguageComponent extends FocusableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public isDisabled = false;

  @Output() public languageIdLinkClick = new EventEmitter<void>();
  @Output() public managedLanguageModelsClick = new EventEmitter<void>();
  @Output() public managedSpeechModelsClick = new EventEmitter<void>();

  //Constants
  public readonly multiLanguageLink = MULTI_LANGUAGE_LINK;
  public readonly limitationsLink = UPLOAD_LIMITATION_LINK;
  public readonly LANG_SCROLL_HEIGHT = '152px';
  public readonly indexingLanguageId = 'indexingLanguage_' + guid();
  public readonly indexingLanguageLabelId = 'indexingLanguageLabel_' + guid();

  // Actions
  public languageAction: IAction = actions.languageAction;
  public selectedLanguageAction: IAction = null;
  public languageOptions: IAction[] = [];

  // Public
  public linguisticModelId: string;
  public isAutoDetectLanguageEnabled: boolean;
  public isLanguageLoading: boolean = false;
  public showAutoMultiLanguageLink = false;
  public showCustomizationLink = false;
  public safeTextAutoMultiLanguageSelectedLanguagesIds: SafeHtml;
  public resources = resources;

  // Private
  private selectedLanguage: string;
  private languageIdLinkEl: Element;
  private languageIdLinkClickHandlerRef;
  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private featureSwitchService: FeatureSwitchService,
    private indexingStore: IndexingStoreService,
    private languageIdStoreService: LanguageIdStoreService,
    private sanitizer: DomSanitizer,
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isAutoDetectLanguageEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
    this.initTranslations();
    this.initLanguages();
    // TODO: add support for custom languages
  }

  public ngOnDestroy(): void {
    this.removeLinkListeners();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    if (this.selectedLanguageAction) {
      this.handlingLanguageLinks(this.selectedLanguageAction);
    }
  }

  public onSelectLang(langAction: IAction) {
    this.indexingStore.updateLanguageId(langAction.id);
    this.handlingLanguageLinks(langAction);
  }

  public onManageLanguageClicked() {
    this.managedLanguageModelsClick.emit();
  }

  public onSpeechModelsClicked() {
    this.managedSpeechModelsClick.emit();
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(this.resources);

    for (const action in actions) {
      actions[action].title = this.resources[actions[action].key];
    }

    this.cdr.detectChanges();
  }

  private initLanguages() {
    this.isLanguageLoading = true;
    this.indexingStore.indexingLanguages$.pipe(takeUntil(this.destroy$)).subscribe(languages => {
      this.languageOptions = languages.map(lang => {
        const langAction: IAction = {
          isDisabled: lang.disabled,
          value: lang.id,
          id: lang.id,
          key: lang.languageCode,
          selected: false,
          selectable: true,
          title: lang.name,
          type: UIActionType.LANGUAGE
        };
        return langAction;
      });
      this.isLanguageLoading = false;
      this.initSelectedLanguageAction();
      this.cdr.detectChanges();
    });

    this.indexingStore.selectedLanguage$.pipe(takeUntil(this.destroy$)).subscribe(selectedLanguage => {
      this.selectedLanguage = selectedLanguage;
      this.initSelectedLanguageAction();
      this.cdr.detectChanges();
    });

    // Set Selected Languages for Multi Languages
    if (this.isAutoDetectLanguageEnabled) {
      this.setSelectedLanguagesIdsForMultiLanguages();
    }
  }

  private initSelectedLanguageAction(): void {
    if (!this.selectedLanguage || !this.languageOptions?.length) {
      return;
    }
    this.selectedLanguageAction = this.languageOptions?.find(opt => opt.id === this.selectedLanguage);
    this.handlingLanguageLinks(this.selectedLanguageAction);
  }

  private setSelectedLanguagesIdsForMultiLanguages() {
    // Check what are the selected languages
    // In case empty, display in the text the default languages.
    this.languageIdStoreService.selectedLanguagesIdNames$
      .pipe(withLatestFrom(this.languageIdStoreService.defaultLanguagesNames$), takeUntil(this.destroy$))
      .subscribe(([selectedLanguages, defaultLanguages]) => {
        if (!selectedLanguages?.length) {
          this.setMultiLanguageDescriptionText(defaultLanguages.join(', '));
        } else {
          this.setMultiLanguageDescriptionText(selectedLanguages.join(', '));
        }
      });
  }

  private setMultiLanguageDescriptionText(selectedLanguages: string) {
    const multiLanguagesTextObject = { UploadMultiLanguageSelectedLanguagesIds: '' };
    this.translate.translateResourcesInstant(multiLanguagesTextObject, {
      languagesList: selectedLanguages,
      editListTitle: this.resources.UploadEditLanguagesIdsList,
      learnMoreMulti: this.resources.UploadMultiLanguagePreviewFeature,
      multiLanguageLink: this.multiLanguageLink
    });
    this.resources.UploadMultiLanguageSelectedLanguagesIds = multiLanguagesTextObject.UploadMultiLanguageSelectedLanguagesIds;
    // eslint-disable-next-line @microsoft/sdl/no-angular-bypass-sanitizer
    this.safeTextAutoMultiLanguageSelectedLanguagesIds = this.sanitizer.bypassSecurityTrustHtml(
      this.resources?.UploadMultiLanguageSelectedLanguagesIds
    );
    this.cdr.markForCheck();
  }

  private handlingLanguageLinks(langAction: IAction) {
    this.showLanguageLinks(langAction);
    this.cdr.detectChanges();
    this.addClickLanguageIdLinkListener(langAction);
  }

  private showLanguageLinks(langAction: IAction) {
    this.showAutoMultiLanguageLink = langAction.value === SpecialLanguageKey.MULTI || langAction.value === SpecialLanguageKey.AUTO;
  }

  private removeLinkListeners() {
    this.languageIdLinkEl?.removeEventListener('click', this.languageIdLinkClickHandlerRef);
    this.languageIdLinkEl?.removeEventListener('keyup', this.languageIdLinkClickHandlerRef);
  }

  private addClickLanguageIdLinkListener(action: IAction) {
    // Initial event listener for navigation link to languageId customization page
    // The link is a part of the translation therefor require a listener

    // First check if there are already listeners to old HTML
    if (this.languageIdLinkEl) {
      this.removeLinkListeners();
    }

    if ((action.value === SpecialLanguageKey.MULTI || action.value === SpecialLanguageKey.AUTO) && this.isAutoDetectLanguageEnabled) {
      this.languageIdLinkEl = this.el.nativeElement.querySelector('.navigate-to-languageId');
      // Add listener to click 'switch to manual;
      this.languageIdLinkClickHandlerRef = this.navigateToLanguageId.bind(this);
      this.languageIdLinkEl?.addEventListener('click', this.languageIdLinkClickHandlerRef);
      this.languageIdLinkEl?.addEventListener('keyup', this.languageIdLinkClickHandlerRef);
    }
  }

  private navigateToLanguageId(event) {
    try {
      const keyCode = getKeyCode(event);

      if (keyCode === KeyCode.Enter || event?.type == 'click') {
        this.languageIdLinkClick.emit();
      }
    } catch (error) {
      this.logger.log('Please add key to dictionary.', error);
    }
  }
}
