import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { guid } from '../../utils';
import { ITag } from './interfaces';

@Component({
  selector: 'vi-ui-tag-container',
  templateUrl: './tag-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tag-container.component.scss']
})
export class TagContainerComponent {
  @Input() public tags: ITag[];
  @Input() label: string;

  @Output() public tagRemoved = new EventEmitter<string>();

  public containerId: string = 'tag-container_' + guid();

  constructor(private cdr: ChangeDetectorRef) {}

  public removeTag(tagId: string): void {
    this.tags = this.tags.filter((tag: ITag) => tag.id !== tagId);
    this.tagRemoved.emit(tagId);
    this.cdr.detectChanges();
  }

  public trackById(index: number, tag: ITag): string {
    return tag.id;
  }
}
