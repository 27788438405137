import { IUploadDialogDatasetState } from './components/speech/speech-upload-dataset-dialog/interfaces';

import SpeechDatasetInput = Microsoft.VideoIndexer.Contracts.SpeechDatasetInput;

export enum UILanguageModelActionType {
  DELETE_MODEL = 'DELETE_MODEL',
  RENAME_MODEL = 'RENAME_MODEL',
  ADD_MODEL = 'ADD_MODEL'
}

export enum UILanguageIDActionType {
  DELETE_LANGUAGE_ID = 'DELETE_LANGUAGE_ID'
}

export enum UILanguageModelFileActionType {
  PREVIEW_FILE = 'PREVIEW_FILE',
  DELETE_FILE = 'DELETE_FILE',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE'
}

export enum Customization_Error_Types {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  WIKI_ALREADY_EXISTS = 'WIKI_ALREADY_EXISTS',
  INVALID_INPUT = 'INVALID_INPUT',
  NOT_FOUND = 'NOT_FOUND'
}

export enum LanguageModelState {
  Waiting = 'Waiting',
  Processing = 'Processing',
  Complete = 'Complete'
}

export enum UIActionIcon {
  REMOVE_FROM = 'i-vi-removefrom'
}

export interface UploadDataDetails extends IUploadDialogDatasetState {
  id: string;
}

export interface IUISpeechDatasetInput extends SpeechDatasetInput {
  id: string;
}
