import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { filter, mergeWith, switchMap, tap, withLatestFrom, catchError, delay, takeUntil } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { UtilsService } from '@common/modules/shared/services/utils.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ApiService } from '@common/modules/api/services/api.service';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { IStripData } from '@common/modules/shared/components/strip/interfaces';
import { INotification, INotificationLink } from '@common/modules/core/services/toast/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { TimeInterval, hasDatePassed, isOverTimeOffset } from '@common/modules/utils/time';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { IState } from '../reducers';
import * as fromCore from '../selectors';
import * as AccountsActions from '../actions/accounts.actions';
import * as MigrationActions from '../actions/ams-migration.actions';
import * as fromRouter from '../reducers/router';
import { AMSAssetsMigrationStatus, AMSMigrationErrorType } from '../interfaces';
import { NotificationsHandlerService } from '../services/notifications-handler.service';
import { CoreStoreService } from '../services/core-store.service';
import { resources } from '../resources';
import * as NotificationsUtils from '../utils/ams-migration-notifications.utils';
import { VIRoutingMap } from '../../app/routing/routes';

import AmsAccountMigration = Microsoft.VideoIndexer.Contracts.AmsAccountMigration;

@Injectable()
export class AmsMigrationEffects {
  public updateMigrationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.addSelectedAccount),
      mergeWith(this.actions$.pipe(ofType(AccountsActions.addSelectedArmAccount))),
      withLatestFrom(this.store.select(fromCore.isArmAmslessAccount)),
      switchMap(([{ account }, isArmAmslessAccount]) => {
        if (!isArmAmslessAccount) {
          return EMPTY;
        }

        // Once the feature switch is ON, the account is considered as a "pure" Amsless account
        if (this.featureSwitchService.featureSwitch(FeatureSwitch.RemoveAmsMigrationStatus)) {
          return [
            MigrationActions.getAmsMigrationStatusSuccess({
              migrationState: {
                status: AMSAssetsMigrationStatus.NOT_APPLICABLE
              }
            })
          ];
        }

        return this.apiService.AmsMigrationApi.getStatus(account.id).pipe(
          takeUntil(this.actions$.pipe(ofType(AccountsActions.selectAccount))),
          switchMap((migrationState: AmsAccountMigration) => {
            this.trackService.track('ams_migration.get_status.success', {
              category: EventCategory.AMS_MIGRATION,
              data: { status: migrationState.status }
            });

            return [
              MigrationActions.getAmsMigrationStatusSuccess({
                migrationState: migrationState
              })
            ];
          }),
          catchError(error => {
            this.trackService.track('ams_migration.get_status.failed', {
              category: EventCategory.AMS_MIGRATION,
              data: { error }
            });

            // migration was not requested yet
            if (error?.error?.ErrorType === AMSMigrationErrorType.MIGRATION_NOT_STARTED) {
              return [
                MigrationActions.getAmsMigrationStatusSuccess({
                  migrationState: {
                    status: AMSAssetsMigrationStatus.NOT_STARTED
                  }
                })
              ];
            }

            // account is AMSless, no migration needed
            if (error?.error?.ErrorType === AMSMigrationErrorType.NOT_APPLICABLE) {
              return [
                MigrationActions.getAmsMigrationStatusSuccess({
                  migrationState: {
                    status: AMSAssetsMigrationStatus.NOT_APPLICABLE
                  }
                })
              ];
            }

            return [MigrationActions.getMigrationStatusFailed()];
          })
        );
      })
    )
  );

  public publishRequestMigrationNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(({ migrationState }) => migrationState?.status === AMSAssetsMigrationStatus.NOT_STARTED),
        tap(() => {
          const requestMigrationDeprecationDate = new Date('2024-07-16');
          if (!hasDatePassed(new Date(), requestMigrationDeprecationDate)) {
            const notification = this.notificationsHandlerService.getRequestMigrationNotification();
            this.notificationService.notify(notification);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public publishPendingMigrationBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(({ migrationState }) => migrationState?.status === AMSAssetsMigrationStatus.PENDING),
        tap(() => {
          const stripData: IStripData = NotificationsUtils.getMigrationPendingBanner(resources.StripAmsMigrationPending);
          this.removeCurrentStrip();
          this.migrationStripId = this.stripService.trigger(stripData);
        })
      ),
    {
      dispatch: false
    }
  );

  public publishInProgressMigrationBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(({ migrationState }) => migrationState?.status === AMSAssetsMigrationStatus.IN_PROGRESS),
        tap(({ migrationState }) => {
          const notification: INotification = NotificationsUtils.getMigrationInProgressNotification(
            resources.NotificationAmsMigrationInProgressTitle,
            resources.NotificationAmsMigrationInProgressMessage,
            !this.migrationStripId || !!this.migrationNotificationId
          );

          const stripData: IStripData = NotificationsUtils.getMigrationInProgressBanner(
            resources.StripAmsMigrationInProgress,
            migrationState.progress
          );

          this.removeCurrentStrip();
          this.migrationStripId = this.stripService.trigger(stripData);
          this.migrationNotificationId = notification.id;
          this.notificationService.notify(notification);
        }),
        delay(NotificationsUtils.NOTIFICATION_IN_PROGRESS_INTERVAL),
        tap(() => {
          this.store.dispatch(MigrationActions.getAmsMigrationStatusStarted());
        })
      ),
    {
      dispatch: false
    }
  );

  public publishMigrationErrorNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(({ migrationState }) => migrationState?.status === AMSAssetsMigrationStatus.PENDING_USER_ACTION),
        withLatestFrom(this.coreService.selectedAccountContract$),
        tap(([{ migrationState }, account]) => {
          const details = migrationState.details;
          const path = this.utilService.getViResourceOverviewPortalUrl(account?.tenantId, account?.resourceId);
          // when details returns, don't translate the message
          const link: INotificationLink = {
            text: details ? resources.AzurePortalLinkText : resources.NotificationAmsMigrationErrorLinkText,
            src: path
          };
          const notification = NotificationsUtils.getMigrationErrorNotification(
            details ? resources.NotificationAmsMigrationErrorTitleUntranslated : resources.NotificationAmsMigrationErrorTitle,
            details ? details : resources.NotificationAmsMigrationErrorMessage,
            !this.migrationStripId,
            link
          );

          const stripText = details
            ? `${details} ${NotificationsUtils.generateBannerLink(path, resources.AzurePortalLinkText)}`
            : this.translate.instant('StripAmsMigrationError', { path });
          const stripData = NotificationsUtils.getMigrationErrorBanner(stripText);

          this.removeCurrentStrip();
          this.migrationStripId = this.stripService.trigger(stripData);

          this.migrationNotificationId = notification.id;
          this.notificationService.notify(notification);
        })
      ),
    {
      dispatch: false
    }
  );

  public publishPartlySuccessfulMigrationNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(
          ({ migrationState }) =>
            migrationState?.status === AMSAssetsMigrationStatus.FAILED &&
            migrationState?.videosMigrated > 0 &&
            this.showMigrationTool(migrationState.migrationCompletedDate)
        ),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        withLatestFrom(this.store.select(fromCore.selectedUIAccount)),
        tap(([[{ migrationState }, routerState], account]) => {
          const details = migrationState.details;
          const path = `/${VIRoutingMap.amsMigrationReport.path}?location=${this.apiService.getApiLocation()}`.replace(':accountId', account.id);
          const link: INotificationLink = {
            text: details ? resources.AmsMigrationReportLinkText : resources.NotificationAmsMigrationPartlySuccessfulLinkText,
            src: `${window.location.origin}${path}`
          };

          const notificationText = this.translate.instant('NotificationAmsMigrationPartlySuccessfulMessage', {
            accountName: account.name,
            videosMigrated: migrationState.videosMigrated,
            totalVideos: migrationState.totalVideosToMigrate
          });

          const notification = NotificationsUtils.getMigrationPartlySuccessfulNotification(
            resources.NotificationAmsMigrationPartlySuccessfulTitle,
            notificationText,
            // don't show toast when first loading
            !this.migrationStripId,
            link
          );

          this.removeCurrentStrip();

          // don't show strip when navigating to the report page
          if (routerState?.state?.data?.name !== VIRoutingMap.amsMigrationReport.name) {
            const stripText = details
              ? `${resources.StripAmsMigrationPartlySuccessfulUntranslated} ${details} ${NotificationsUtils.generateBannerLink(
                  path,
                  resources.AmsMigrationReportLinkText,
                  true
                )}`
              : this.translate.instant('StripAmsMigrationPartlySuccessful', { path });
            const stripData = NotificationsUtils.getMigrationPartlySuccessfulBanner(stripText);
            this.migrationStripId = this.stripService.trigger(stripData);
          }

          this.removeCurrentNotification();
          this.migrationNotificationId = notification.id;
          this.notificationService.notify(notification);
        })
      ),
    {
      dispatch: false
    }
  );

  public publishFailedMigrationNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(
          ({ migrationState }) =>
            migrationState?.status === AMSAssetsMigrationStatus.FAILED &&
            migrationState.videosMigrated === 0 &&
            this.showMigrationTool(migrationState.migrationCompletedDate)
        ),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        withLatestFrom(this.store.select(fromCore.selectedUIAccount)),
        tap(([[{ migrationState }, routerState], account]) => {
          const details = migrationState.details;
          const path = `/${VIRoutingMap.amsMigrationReport.path}?location=${this.apiService.getApiLocation()}`.replace(':accountId', account.id);
          const link: INotificationLink = {
            text: details ? resources.AmsMigrationReportLinkText : resources.NotificationAmsMigrationFailedLinkText,
            src: `${window.location.origin}${path}`
          };

          const notification: INotification = NotificationsUtils.getMigrationFailedNotification(
            details ? resources.NotificationAmsMigrationFailedTitleUntranslated : resources.NotificationAmsMigrationFailedTitle,
            details ? details : resources.NotificationAmsMigrationFailedMessage,
            !this.migrationStripId,
            link
          );

          this.removeCurrentStrip();

          if (routerState?.state?.data?.name !== VIRoutingMap.amsMigrationReport.name) {
            const stripText = details
              ? `${details} ${NotificationsUtils.generateBannerLink(path, resources.AmsMigrationReportLinkText, true)}`
              : this.translate.instant('StripAmsMigrationFailed', { path });
            const stripData: IStripData = NotificationsUtils.getMigrationFailedBanner(stripText);
            this.migrationStripId = this.stripService.trigger(stripData);
          }

          this.removeCurrentNotification();
          this.migrationNotificationId = notification.id;
          this.notificationService.notify(notification);
        })
      ),
    {
      dispatch: false
    }
  );

  public publishCompletedMigrationNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(
          ({ migrationState }) =>
            migrationState?.status === AMSAssetsMigrationStatus.COMPLETED && this.showMigrationTool(migrationState.migrationCompletedDate)
        ),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        tap(([, routerState]) => {
          const notification = NotificationsUtils.getMigrationCompleteNotification(
            resources.NotificationAmsMigrationCompleteTitle,
            resources.NotificationAmsMigrationCompleteMessage,
            !this.migrationStripId
          );

          this.removeCurrentStrip();

          if (routerState?.state?.data?.name !== VIRoutingMap.amsMigrationReport.name) {
            const stripData: IStripData = NotificationsUtils.getMigrationCompleteBanner(resources.StripAmsMigrationComplete);
            this.migrationStripId = this.stripService.trigger(stripData);
          }

          this.removeCurrentNotification();
          this.migrationNotificationId = notification.id;
          this.notificationService.notify(notification);
        })
      ),
    {
      dispatch: false
    }
  );

  public publishMigrationsFallbackBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getVideoMigrationsFailed, MigrationActions.getMigrationStatusFailed),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        filter(([, routerState]) => routerState?.state?.data?.name === VIRoutingMap.amsMigrationReport.name),
        tap(() => {
          const stripData: IStripData = NotificationsUtils.getMigrationFallbackBanner(resources.StripMigrationFallbackMessage);
          this.removeCurrentStrip();
          this.migrationStripId = this.stripService.trigger(stripData);
        })
      ),
    {
      dispatch: false
    }
  );

  public hideMigrationBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountsActions.selectAccount),
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      filter(([action, account]) => account?.id !== action?.id),
      switchMap(() => {
        this.removeCurrentStrip();
        this.removeCurrentNotification();
        return [MigrationActions.resetAmsMigrationState()];
      })
    )
  );

  public updateVideoMigrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.getVideoMigrationStarted),
      withLatestFrom(this.store.select(fromCore.getAmsMigrationResultsNextPage)),
      withLatestFrom(this.coreService.selectedAccountId$),
      switchMap(([[, nextPage], accountId]) => this.getVideoMigrationsHandler(accountId, nextPage?.pageSize, nextPage?.skip))
    )
  );

  public loadMoreVideoMigrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.loadMoreVideoMigrations),
      withLatestFrom(this.store.select(fromCore.getAmsMigrationResultsNextPage)),
      withLatestFrom(this.coreService.selectedAccountId$),
      switchMap(([[, nextPage], accountId]) => this.getVideoMigrationsHandler(accountId, nextPage?.pageSize, nextPage?.skip))
    )
  );

  public setMigrationInterval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountsActions.selectAccount),
        withLatestFrom(this.coreService.isArmAmslessAccount$),
        filter(([, isAmsless]) => isAmsless),
        tap(() => {
          // if account is interval is not already active, start migration status interval
          if (!this.migrationStatusIntervalActive) {
            this.migrationStatusIntervalActive = true;
            this.store.dispatch(MigrationActions.startMigrationStatusInterval());
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public updateMigrationStatusInterval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.startMigrationStatusInterval),
        delay(NotificationsUtils.NOTIFICATION_INTERVAL),
        withLatestFrom(this.coreService.isArmAmslessAccount$),
        withLatestFrom(this.store.select(fromCore.getAmsMigrationStatus)),
        tap(([[, isAmsless], migrationState]) => {
          // if migration completed or failed, stop polling for status
          if (
            migrationState?.status === AMSAssetsMigrationStatus.COMPLETED ||
            migrationState?.status === AMSAssetsMigrationStatus.FAILED ||
            !isAmsless
          ) {
            this.migrationStatusIntervalActive = false;
          } else {
            this.store.dispatch(MigrationActions.getAmsMigrationStatusStarted());
            this.store.dispatch(MigrationActions.startMigrationStatusInterval());
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public UpdateMigrationStatusProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MigrationActions.getAmsMigrationStatusStarted),
      switchMap(() => this.coreService.selectedAccountId$),
      switchMap(accountId =>
        this.apiService.AmsMigrationApi.getStatus(accountId).pipe(
          switchMap((migrationState: AmsAccountMigration) => {
            this.trackService.track('ams_migration.get_status.success', {
              category: EventCategory.AMS_MIGRATION,
              data: { status: migrationState.status }
            });

            return [
              MigrationActions.getAmsMigrationStatusSuccess({
                migrationState
              })
            ];
          }),
          catchError(error => {
            this.trackService.track('ams_migration.get_status.failed', {
              category: EventCategory.AMS_MIGRATION,
              data: { error }
            });

            return [MigrationActions.getMigrationStatusFailed()];
          })
        )
      )
    )
  );

  public navigateMigrationNotStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MigrationActions.getAmsMigrationStatusSuccess),
        filter(
          ({ migrationState }) =>
            migrationState?.status === AMSAssetsMigrationStatus.NOT_STARTED || migrationState?.status === AMSAssetsMigrationStatus.NOT_APPLICABLE
        ),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        withLatestFrom(this.coreService.selectedAccount$),
        tap(([[, routerState], account]) => {
          if (routerState?.state?.data?.name === VIRoutingMap.amsMigrationReport.name && account?.id === routerState?.state?.params?.accountId) {
            this.coreService.navigate([`${VIRoutingMap.pageNotFoundError}`]);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  private migrationStripId: number;
  private migrationNotificationId: string;
  private migrationStatusIntervalActive = false;

  constructor(
    private actions$: Actions,
    private store: Store<IState>,
    private apiService: ApiService,
    private notificationService: NotificationsService,
    private notificationsHandlerService: NotificationsHandlerService,
    private trackService: TrackService,
    private stripService: StripService,
    private coreService: CoreStoreService,
    private translate: TranslateHelperService,
    private utilService: UtilsService,
    private featureSwitchService: FeatureSwitchService
  ) {}

  private showMigrationTool(completed: string) {
    return !isOverTimeOffset(completed, TimeInterval.DAY * 14);
  }

  private removeCurrentStrip() {
    if (this.migrationStripId) {
      this.stripService.hideStrip(this.migrationStripId);
      this.migrationStripId = null;
    }
  }

  private removeCurrentNotification() {
    if (this.migrationNotificationId) {
      this.notificationService.clear([this.migrationNotificationId]);
      this.migrationNotificationId = null;
    }
  }

  private getVideoMigrationsHandler = (accountId: string, pageSize: number, skip: number) => {
    return this.apiService.AmsMigrationApi.getVideoMigrations(accountId, {
      states: [AMSAssetsMigrationStatus.FAILED],
      pageSize: pageSize,
      skip: skip
    }).pipe(
      switchMap(videoMigrations => {
        this.trackService.track('ams_migration.get_video_migrations.success', {
          category: EventCategory.AMS_MIGRATION
        });

        return [
          MigrationActions.getVideoMigrationsSucceed({
            videoMigrations
          })
        ];
      }),
      catchError(error => {
        this.trackService.track('ams_migration.get_video_migrations.failed', {
          category: EventCategory.AMS_MIGRATION,
          data: { error }
        });
        return [MigrationActions.getVideoMigrationsFailed()];
      })
    );
  };
}
