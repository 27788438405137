import { Component, ChangeDetectionStrategy, Input, EventEmitter, ChangeDetectorRef, Output } from '@angular/core';

import { ExpandIconPosition } from './interfaces';

@Component({
  selector: 'vi-ui-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionItemComponent {
  @Input() title: string;
  @Input() expandIconPosition = ExpandIconPosition.END;
  @Input() iconOpened = 'i-vi-arrow-up';
  @Input() iconClosed = 'i-vi-arrow-down';
  @Input() set opened(isOpen) {
    this._opened = isOpen;
    this.cdr.detectChanges();
  }

  @Output() toggle = new EventEmitter<boolean>();

  public ExpandIconPosition = ExpandIconPosition;

  public get opened() {
    return this._opened;
  }

  private _opened = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public get startPosition() {
    return this.expandIconPosition === ExpandIconPosition.START;
  }

  public get endPosition() {
    return this.expandIconPosition === ExpandIconPosition.END;
  }

  public get iconClass() {
    return this._opened ? this.iconOpened : this.iconClosed;
  }

  public onToggle() {
    this.toggle.emit(!this._opened);
  }
}
