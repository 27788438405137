import { StorageCacheKey } from './interfaces';

export const VIResourceMarketplaceId = 'Microsoft.VideoIndexer';
export const PortalCreateVIDevAccountFlag = 'createDevEnv=true';
export const MigrationTimeOffset = 60 * 24 * 30; // 30 days

export function getCreateAccountPortalUrl(portalPrefix: string, createDevEnv = false) {
  if (createDevEnv) {
    return `${portalPrefix}/?${PortalCreateVIDevAccountFlag}#create/${VIResourceMarketplaceId}`;
  } else {
    return `${portalPrefix}/?#create/${VIResourceMarketplaceId}`;
  }
}

export function getLastArmStorageKey(tenantId: string) {
  return `${StorageCacheKey.LastArmAccount}_${tenantId}`;
}
