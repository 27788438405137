export enum DeleteState {
  FORM,
  PROCESS,
  COMPLETED
}

export interface IDeleteAccount {
  id: string;
  account: Microsoft.VideoIndexer.Contracts.AccountContract;
  isUserOwner: boolean;
}

export interface IDeleteAccountOutput {
  closeParentComponent: boolean;
  isAccountDeleted: boolean;
}
