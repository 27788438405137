import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, take, combineLatestWith } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { TrackService } from '@common/modules/core/services/track';
import { PermissionService } from '@common/modules/shared/services/permission.service';
import { AccountPermission } from '@common/modules/shared/interfaces';

import { CoreStoreService } from '../services/core-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export function PermissionGuard(requiredPermission: AccountPermission): CanActivateFn {
  return (ars: ActivatedRouteSnapshot, rss: RouterStateSnapshot) => {
    return inject(PermissionGuardService).canActivate(requiredPermission);
  };
}

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService {
  constructor(
    private logger: LoggerService,
    private coreStore: CoreStoreService,
    private trackService: TrackService,
    private permissionService: PermissionService
  ) {}

  public canActivate(requiredPermission: AccountPermission): Observable<boolean> {
    return this.coreStore.selectedAccountLoaded$.pipe(
      filter(loaded => loaded),
      combineLatestWith(this.coreStore.getAccountTokenPermission$),
      filter(([, permission]) => permission !== null),
      take(1),
      map(([, permission]) => {
        if (!this.permissionService.ensurePermission(permission, requiredPermission)) {
          this.handleHasNoPermission(permission, requiredPermission);
          return false;
        }

        return true;
      })
    );
  }

  private handleHasNoPermission(permission: AccountPermission, requiredPermission: AccountPermission) {
    this.logger.log('[PermissionGuardService] not permitted, navigate gallery library');
    this.trackService.track('permission_guard.can_activate.blocked', {
      requiredPermission: requiredPermission,
      permission: permission
    });
    this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`]);
  }
}
