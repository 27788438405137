<div class="face-gate-form-details">

  <div class="description"
    [innerHTML]="resources?.FaceGatePopupDescription">
  </div>

  <div class="account-name-container">
    <label class="label">{{resources?.AccountName}}</label>
    <div class="account-name">{{data?.accountName}}</div>
  </div>

  <div class="use-case-container">
    <label class="label">{{resources?.SelectAUseCase}}</label>
    <div class="use-case-description"
      [innerHtml]="resources?.FaceGatePopupSensitiveUseCase"></div>
    <app-vi-check-box-action-menu *ngIf="useCaseMenuAction.title"
      class="use-case-menu"
      [actionList]="useCaseOptions"
      [hasOpenerIcon]="true"
      [selectedAction]="selectedUseCaseAction"
      [placeholder]="useCaseMenuAction.title"
      (selectedActionsChange)="onSelectUseCases($event)">
    </app-vi-check-box-action-menu>
  </div>

  <div class="terms-of-use-container">
    <label class="label">{{resources?.TermsOfUse}}</label>
    <div class="terms-content"
      [innerHTML]="resources?.FaceGatePopupTermsOfUse"></div>
    <vi-checkbox-with-label [checked]="isUserConfirmTerms"
      [label]="resources?.FaceGatePopupAgreement"
      [ariaLabel]="resources?.FaceGatePopupAgreementTitle"
      (checkedChange)="toggleConfirmTerms($event)">
    </vi-checkbox-with-label>
  </div>

  <div class="sign-container">
    <label class="label">{{resources?.TypeYourEmailAddressFoAddedSecurity}}</label>
    <input name="signInput"
      type="text"
      autocomplete="off"
      [ngClass]="{'error': showEmailError}"
      [placeholder]="resources?.TypeYourEmailAddress"
      [(ngModel)]="signature"
      (focusout)="onEmailInputFocusout($event.target.value)"
      (input)="onEmailInputValueChanged($event.target.value)" />
    <span class="error-message"
      [ngClass]="{'shown' : showEmailError}">{{resources?.FaceGatePopupEmailDoesNotMatch}}</span>
  </div>
</div>
<div class="actions">
  <app-vi-action-button [action]="acceptAction"
    [type]="ActionButtonType.PRIMARY"
    [isDisabled]="acceptBtnDisabled"
    (actionClick)="onClickAccept()">
  </app-vi-action-button>
  <app-vi-action-button [action]="dismissAction"
    [type]="ActionButtonType.SECONDARY"
    (actionClick)="onClickDismiss()">
  </app-vi-action-button>
</div>
