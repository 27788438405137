import { createSelector } from '@ngrx/store';

import { UploadingState } from '../../interfaces';
import { selectIndexingState } from '../reducers';

export const selectUploadState = createSelector(selectIndexingState, state => state.upload);
export const selectUploadFiles = createSelector(selectUploadState, state => Object.values(state.entities));
export const selectUploadFileById = (id: string) => createSelector(selectUploadState, state => state.entities[id]);
export const selectUploadDisplayedPage = createSelector(selectUploadState, state => state.displayedUploadPage);
export const selectUploadingQuotaLimitReached = createSelector(selectUploadState, state => state.uploadingQuotaLimitReached);
export const selectUploadMode = createSelector(selectUploadState, state => state.uploadMode);
export const getAllValidUploadFiles = createSelector(selectUploadFiles, files => {
  return files?.filter(file => !file.error);
});
export const showUploadFileError = createSelector(selectUploadFiles, files => {
  return !!files?.find(file => file.error);
});
export const getTotalUploadProgress = createSelector(getAllValidUploadFiles, files =>
  files.length ? Math.floor(files.reduce((acc, f) => acc + (f.progress || 0), 0) / files.length) : 0
);
export const getInProgressFiles = createSelector(selectUploadFiles, files => files?.filter(f => f.uploadingState === UploadingState.InProgress));
export const isUploadInProgress = createSelector(getInProgressFiles, files => !!files?.length);
export const isUploadingDone = createSelector(
  getAllValidUploadFiles,
  files => files.length && !files?.find(f => f.uploadingState === UploadingState.Idle || f.uploadingState === UploadingState.InProgress)
);
export const isUserConsent = createSelector(selectUploadState, state => state.userConsent);
export const selectedFilesForUploadCount = createSelector(getAllValidUploadFiles, files => files.length);
export const isUrlValidationLoading = createSelector(selectUploadState, state => state.urlValidationLoading);
export const urlValidationError = createSelector(selectUploadState, state => state.urlValidationError);

export const getUploadErrorType = createSelector(selectUploadFiles, files => {
  return files?.reduce((errorType, file) => file?.uploadingErrorType || errorType, null);
});

export const getFileUploadingErrorType = (id: string) => createSelector(selectUploadState, state => state.entities[id]?.uploadingErrorType);
