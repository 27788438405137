import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { envTypes } from '@common/modules/api/interfaces';
import { ApiService } from '@common/modules/api/services/api.service';
import { isUserNotAuthenticatedError } from '@common/modules/api/utils/request.function';
import { AccountResourceType, IAuthenticatedUser, UserType } from '@common/modules/auth/interfaces';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { ConfigService } from '@common/modules/core/services/config/config.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { MigrationTimeOffset, getLastArmStorageKey } from '@common/modules/shared/arm';
import { IStripData, MessageType } from '@common/modules/shared/components/strip/interfaces';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { VIRoutingMap } from '../../app/routing/routes';
import * as SupportedLanguageIDActions from '../../customization-data/actions/supported-languages-ids.actions';
import * as fromRouter from '../../core/reducers/router';
import * as fromCore from '../../core/selectors';
import * as actions from '../actions/index';
import { IArmAccountCacheData } from '../interfaces';
import * as fromUser from '../reducers/user.reducer';
import { SurveyService } from '../services/survery.service';
import { UserService } from '../services/user.service';
import { getSelectedAccountId } from './utils';

import UserContract = Microsoft.VideoIndexer.Contracts.UserContract;

@Injectable()
export class UserEffects {
  // loadUserMetadata effect - get user data
  // called on UserEffects init
  // eslint-disable-next-line
  public loadUserMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUserDetails),
      withLatestFrom(this.store.select(fromCore.isUserDetailsLoaded)),
      withLatestFrom(this.store.select(fromCore.isUserDetailsLoading)),
      withLatestFrom(this.routerStore.select(fromRouter.getRouterState)),
      switchMap(([[[{ forceLoad, selectAccountId }, loaded], loading], routerState]) => {
        if (!routerState?.state?.url) {
          setTimeout(() => {
            this.store.dispatch(actions.loadUserDetails({}));
          }, 200);
          return EMPTY;
        }
        if (
          ((loaded || loading) && !forceLoad) ||
          routerState?.state?.url?.includes(VIRoutingMap.login.path) ||
          routerState?.state?.url?.includes(VIRoutingMap.logout.path)
        ) {
          this.logger.log(`[UserEffects] not loadUserMetadata loaded: ${loaded}, loading: ${loading}`);
          return EMPTY;
        }
        this.store.dispatch(actions.loadUserDetailsInProgress());
        this.logger.log('[UserEffects] Load User Details');
        return this.apiService.User.getUser().pipe(
          switchMap((result: UserContract) => {
            let accounts = result.accounts;

            if (accounts?.length && this.featureSwitchService.featureSwitch(FeatureSwitch.HideClassicAccounts)) {
              accounts = accounts.filter(account => account.accountType === envTypes.TRIAL);
            }

            // copy all user properties except accounts
            const user = this.getUserObj(result);
            const authenticatedUser = this.getAuthenticatedUser(result);
            this.authService.authenticatedUser = authenticatedUser;
            const accountIds = accounts?.map(a => a.id);
            this.authService.AccountIds = accountIds;
            const actionsList = [];
            actionsList.push(actions.addUser({ user }), actions.loadUserDetailsSuccess(), actions.addAccounts({ accounts }));
            const locale = user.locale || this.translationService.locale;
            // Set user locale & Login Hint
            const lastUserEmail = this.localStorageService.getItem(StorageCacheKey.LoginHint);
            this.localStorageService.setItem(StorageCacheKey.Locale, locale);
            this.localStorageService.setItem(StorageCacheKey.LoginHint, user.email);
            this.trackService.track('user_effect.load_user_metadata.success', {
              category: EventCategory.USERS,
              locale
            });
            // Update translation to the selected user language
            this.translationService.checkLanguageUpdate(locale);
            if (user.userType === UserType.MicrosoftCorpAad) {
              actionsList.push(actions.loadArmAccounts());
            } else {
              actionsList.push(actions.loadArmAccountsNotNeeded());
            }
            const lastAccountType = this.localStorageService.getItem(StorageCacheKey.LastAccountType);
            const armAccountCache: IArmAccountCacheData = JSON.parse(this.localStorageService.getItem(getLastArmStorageKey(user.tenantId)));

            // last selected account is ARM account
            // location and accountId are new parameters that have been added for getting ARM account from FE-Service.
            // We are not loaded ARM account from cache in case users do not have theses new parameters.
            // The behavior will be same as there is no last selected account at all.
            if (
              lastAccountType === AccountResourceType.ARM &&
              user.userType === UserType.MicrosoftCorpAad &&
              lastUserEmail === user.email &&
              armAccountCache?.location &&
              armAccountCache?.accountId
            ) {
              this.authService.UserAccount = {
                id: armAccountCache.accountId,
                name: armAccountCache.name,
                location: armAccountCache.location,
                resourceType: AccountResourceType.ARM,
                subscriptionId: armAccountCache.subscriptionId,
                resourceGroupName: armAccountCache.resourceGroupName
              };
              this.authService.AccountIds = accountIds.concat([armAccountCache.accountId]);
              this.apiService.setApiBaseLocation(armAccountCache.location, envTypes.PAID);
              actionsList.push(actions.loadLastArmAccount({ accountId: armAccountCache.accountId }));

              // there is no last ARM selected account
            } else {
              // select last selected trial account or first trial account as default account
              if (accounts?.length > 0) {
                let selectedAccountId = getSelectedAccountId(
                  accounts,
                  routerState?.state.params,
                  selectAccountId,
                  this.localStorageService.getItem(StorageCacheKey.LastAccountId)
                );
                let selectedAccount = accounts?.find(account => account.id === selectedAccountId);
                // validating that selectedAccount is not under migration
                if (
                  new Date(selectedAccount.moveToArmStartedDate).getTime() >
                  new Date(Math.floor(new Date().getTime() / 1000) - MigrationTimeOffset).getTime()
                ) {
                  selectedAccount = accounts[0];
                  selectedAccountId = selectedAccount.id;
                }
                if (selectedAccount) {
                  this.apiService.setApiBaseLocation(selectedAccount.location, selectedAccount.accountType);
                }
                actionsList.push(actions.selectAccount({ id: selectedAccountId }));
                if (routerState?.state?.data?.path !== VIRoutingMap.mediaVideo.path) {
                  actionsList.push(actions.loadArmAccountNotNeededForInit());
                }

                // there are no trial accounts
              } else if (!accounts?.length) {
                this.apiService.setApiBaseLocation(this.configService.Config.api.defaultRegion, envTypes.PAID);
                if (user.userType !== UserType.MicrosoftCorpAad) {
                  actionsList.push(actions.zeroAccountsLoaded());
                }
                // For 'MicrosoftCorpAad' we need to wait for the arm account to load before selecting default account.
              }
            }

            if (this.featureSwitchService.featureSwitch('ArmGAStrip') && !this.stripGAId) {
              const resource = { ArmAccountGeneralAvailabilityStrip: '' };
              this.translationService.translateResources(resource);

              const stripData: IStripData = {
                text: resource.ArmAccountGeneralAvailabilityStrip,
                show: true,
                hasCloseButton: true,
                messageType: MessageType.INFO,
                linkCallback: event => {
                  this.trackService.track('strip.link.clicked', {
                    category: EventCategory.NOTIFICATIONS,
                    data: {
                      href: event.target?.href
                    }
                  });
                }
              };

              this.stripGAId = this.stripService.trigger(stripData);
            }

            this.surveyService.showSurveyPopup(user.joinTime);
            actionsList.push(SupportedLanguageIDActions.loadSupportedLanguages());
            return actionsList;
          }),
          catchError(error => {
            this.logger.error('[UserEffects] Load User details failed', error);
            this.trackService.track('user_effect.load_user_metadata.failed', {
              category: EventCategory.USERS,
              data: {
                error: error
              }
            });
            const actionsList = [];
            actionsList.push(actions.loadUserDetailsFailed(), actions.loadAccountsFailed());
            if (isUserNotAuthenticatedError(error)) {
              actionsList.push(actions.loadUserNotAuthenticated());
            }
            return actionsList;
          })
        );
      })
    )
  );

  public updateUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserApi),
      withLatestFrom(this.store.select(fromCore.getUserDetails)),
      withLatestFrom(this.store.select(fromCore.selectAllAccountsSlim)),
      switchMap(([[newUserSettings, currentUser], accounts]) => {
        this.store.dispatch(actions.updateUser({ user: newUserSettings.user }));
        // create user with new user settings
        const userLocale = newUserSettings.user.locale || this.translationService.locale;
        const newUser: UserContract = {
          ...currentUser,
          getNewsUpdates: newUserSettings.user.getNewsUpdates,
          autoCompleteSearch: newUserSettings.user.autoCompleteSearch,
          showSamplesTab: newUserSettings.user.showSamplesTab,
          showGalleryByDefault: newUserSettings.user.showGalleryByDefault,
          locale: userLocale,
          accounts
        };

        this.localStorageService.setItem('vi.locale', userLocale);

        return this.apiService.User.saveUser(newUser).pipe(
          switchMap(() => {
            if (newUser.locale !== currentUser.locale) {
              window.location.reload();
            }
            const userService = this.injector.get(UserService);
            userService.toastUserSettingsSaveSuccessfully();
            return EMPTY;
          }),
          catchError(() => {
            // return to previous user properties
            return [actions.updateUser({ user: currentUser })];
          })
        );
      })
    )
  );

  private stripGAId: number;

  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private authService: AuthService,
    private apiService: ApiService,
    private store: Store<fromUser.IState>,
    private readonly routerStore: Store<fromRouter.IState>,
    private localStorageService: LocalStorageService,
    private trackService: TrackService,
    private translationService: TranslateHelperService,
    private configService: ConfigService,
    private injector: Injector,
    private featureSwitchService: FeatureSwitchService,
    private surveyService: SurveyService,
    private stripService: StripService
  ) {
    this.init();
  }

  public init() {
    this.logger.log('[UserEffects] On Init Effects');
    setTimeout(() => {
      this.store.dispatch(actions.loadUserDetails({}));
    }, 300);
  }

  private getUserObj(result: UserContract): Microsoft.VideoIndexer.Contracts.UserContractSlim {
    return {
      id: result.id,
      name: result.name,
      isAuthenticated: result.isAuthenticated,
      email: result.email,
      userType: result.userType,
      joinTime: result.joinTime,
      getNewsUpdates: result.getNewsUpdates,
      getVideoNotifications: result.getVideoNotifications,
      showSamplesTab: result.showSamplesTab,
      autoCompleteSearch: result.autoCompleteSearch,
      locale: this.localStorageService.getItem('vi.locale') || result.locale,
      isSuspended: result.isSuspended,
      isAdmin: result.isAdmin,
      showGalleryByDefault: result.showGalleryByDefault,
      image: result.image,
      tenantId: result.tenantId,
      signedInUserEmail: result.signedInUserEmail
    };
  }

  private getAuthenticatedUser(result: UserContract): IAuthenticatedUser {
    return {
      upn: result.email,
      name: result.name,
      id: result.id,
      type: result.userType,
      isAdmin: result.isAdmin,
      isSuspended: result.isSuspended,
      tenantId: result.tenantId
    };
  }
}
