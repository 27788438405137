import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Subject, combineLatestWith, takeUntil } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { CustomizationPage, FeatureSwitch } from '@common/modules/core/services/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { FeatureSwitchService } from './../../../../../../../common/modules/core/services/feature-switch/feature-switch.service';
import { ReIndexPages } from './../interfaces';
import { CoreStoreService } from '../../../../core/services/core-store.service';
import { TranslateHelperService } from './../../../../../../../common/modules/translation/services/translate-helper.service';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { reIndexAction, cancelAction, closeIconAction, reviewAndReIndexAction, closeAction } from './actions';
import { resources } from './resources';
import { IUploadErrorStrip } from '../../upload/summary-page/interfaces';
import { backToSettingsAction } from '../../shared/actions';

@Component({
  selector: 'vi-re-index-dialog',
  templateUrl: './re-index-dialog.component.html',
  styleUrls: ['./re-index-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReIndexDialogComponent implements OnInit, OnDestroy {
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public isExcludeAIsEnabled: boolean;
  public isIndexingSettingLoaded: boolean;
  public isReIndexProcessing: boolean;
  public indexingFormChanged: boolean = false;
  public showConfirmExit: boolean = false;
  public navigateToCreateAccount: boolean = false;
  public customizationPageToNavigate: CustomizationPage = null;
  public reIndexAction = reIndexAction;
  public cancelAction = cancelAction;
  public closeIconAction = closeIconAction;
  public closeAction = closeAction;
  public backToSettingsAction = backToSettingsAction;
  public reviewAndReIndexAction = reviewAndReIndexAction;
  public ActionButtonType = ActionButtonType;
  public resources = resources;
  public AlertMessageType = AlertMessageType;
  public showLoadingError = false;
  public showReIndexError = false;
  public error: Partial<IUploadErrorStrip>;
  public statusText: string;
  public displayedReIndexPage: ReIndexPages;
  public ReIndexPages = ReIndexPages;
  public isSupportedAIsLoaded: boolean = false;

  public readonly REVIEW_REINDEX_BUTTON_MIN_WIDTH = '155px';
  public readonly BACK_TO_SETTINGS_MIN_WIDTH = '141px';

  private destroy$ = new Subject<void>();

  constructor(
    private indexingStore: IndexingStoreService,
    private translate: TranslateHelperService,
    private trackService: TrackService,
    private coreStoreService: CoreStoreService,
    private cdr: ChangeDetectorRef,
    private featureSwitchService: FeatureSwitchService
  ) {}

  public ngOnInit(): void {
    this.isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);
    this.initTranslations();
    this.handleLoadingErrors();
    this.handleReIndexErrors();
    this.statusText = `${this.resources?.Processing}...`;
    this.indexingStore.isIndexingProcessDirty$.pipe(takeUntil(this.destroy$)).subscribe(isDirty => {
      this.indexingFormChanged = isDirty;
    });
    this.indexingStore.isIndexingSettingLoaded$.pipe(takeUntil(this.destroy$)).subscribe(isIndexingSettingLoaded => {
      this.isIndexingSettingLoaded = isIndexingSettingLoaded;
      this.cdr.detectChanges();
    });
    this.indexingStore.displayedReIndexPage$.pipe(takeUntil(this.destroy$)).subscribe(page => {
      this.displayedReIndexPage = page;
      this.cdr.detectChanges();
    });
    this.indexingStore.isReIndexProcessing$.pipe(takeUntil(this.destroy$)).subscribe(isReIndexProcessing => {
      this.isReIndexProcessing = isReIndexProcessing;
      this.cdr.detectChanges();
    });
    this.indexingStore.isSupportedAIsLoaded$.pipe(takeUntil(this.destroy$)).subscribe(isSupportedAIsLoaded => {
      this.isSupportedAIsLoaded = isSupportedAIsLoaded;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get showReIndexButton(): boolean {
    if (this.isExcludeAIsEnabled) {
      return this.displayedReIndexPage === ReIndexPages.ReIndexReview;
    }

    return this.isIndexingSettingLoaded;
  }

  public get showReviewReIndexButton(): boolean {
    return (
      this.isExcludeAIsEnabled && this.isIndexingSettingLoaded && !this.showLoadingError && this.displayedReIndexPage === ReIndexPages.IndexingForm
    );
  }

  public get showBackToSettingsButton(): boolean {
    return this.displayedReIndexPage === ReIndexPages.ReIndexReview;
  }

  public get showCloseButton(): boolean {
    return this.displayedReIndexPage === ReIndexPages.ReIndexSummary;
  }

  public get showCancelButton(): boolean {
    return this.displayedReIndexPage !== ReIndexPages.ReIndexSummary;
  }

  public get showReIndexStatus(): boolean {
    if (this.isExcludeAIsEnabled) {
      return this.displayedReIndexPage === ReIndexPages.ReIndexSummary;
    }
    return this.isIndexingFormDisabled;
  }

  public get reIndexStatusText(): string {
    if (!this.isReIndexProcessing && !this.showReIndexError) {
      this.statusText = '';
    }
    return this.statusText;
  }

  public get isReIndexButtonDisabled(): boolean {
    return this.isReIndexProcessing || this.showLoadingError || this.showReIndexError;
  }

  public get isIndexingFormDisabled(): boolean {
    return this.isReIndexProcessing || this.showReIndexError;
  }

  public reIndexClicked() {
    this.customizationPageToNavigate = null;
    this.indexingStore.reIndex();

    if (this.isExcludeAIsEnabled) {
      this.indexingStore.updateReIndexPage(ReIndexPages.ReIndexSummary);
    }
  }

  public reviewReIndexClicked() {
    this.indexingStore.updateReIndexPage(ReIndexPages.ReIndexReview);
  }

  public backToSettingsClicked() {
    this.indexingStore.updateReIndexPage(ReIndexPages.IndexingForm);
  }

  public confirmClose(pageToNavigate: CustomizationPage = null, navigateToCreateAccount: boolean = false) {
    this.customizationPageToNavigate = pageToNavigate;
    this.navigateToCreateAccount = navigateToCreateAccount;
    this.showConfirmExit = this.indexingFormChanged;

    if (!this.showConfirmExit) {
      this.close();
    } else {
      // This trace was emitted here and not inside the confirm exit component so we can differentiate upload_dialog event from re_index_dialog trace
      this.trackService.track('re_index_dialog.confirm_exit.init', { category: EventCategory.RE_INDEX });
    }
  }

  public close() {
    this.componentEventEmitter.emit({
      isDialogClose: true,
      dialogEventData: { customizationPageToNavigate: this.customizationPageToNavigate, navigateToCreateAccount: this.navigateToCreateAccount }
    });
  }

  public leaveIndexing() {
    this.trackService.track('re_index_dialog.confirm_exit.leave.clicked', { category: EventCategory.RE_INDEX });
    this.close();
  }

  public onBackToIndexing() {
    this.trackService.track('re_index_dialog.confirm_exit.cancel.clicked', { category: EventCategory.RE_INDEX });
    this.showConfirmExit = !this.showConfirmExit;
  }

  public onNavigateToCreateAccount() {
    this.confirmClose(null, true); // Nav to create account dialog
  }

  public onReIndexError() {
    this.showReIndexError = true;
    this.statusText = this.resources.Failed;
    this.cdr.detectChanges();
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources);
    const actions = [reIndexAction, cancelAction, reviewAndReIndexAction, closeAction, backToSettingsAction];
    for (const action of actions) {
      action.title = this.resources[action.key];
    }
  }

  private handleLoadingErrors() {
    this.indexingStore.isFileNotFoundError$
      .pipe(
        takeUntil(this.destroy$),
        combineLatestWith(
          this.indexingStore.isGeneralLoadingReIndexError$,
          this.indexingStore.isVideoUrlUnreachableError$,
          this.indexingStore.isFileWasRemovedError$
        )
      )
      .subscribe(([isFileNotFoundError, isGeneralLoadingReIndexError, isVideoUrlUnreachableError, isFileWasRemovedError]) => {
        this.showLoadingError = isFileNotFoundError || isGeneralLoadingReIndexError || isVideoUrlUnreachableError || isFileWasRemovedError;

        if (isFileNotFoundError) {
          this.error = { content: this.resources.ReIndexFileNotFoundErrorMsg };
        }
        if (isGeneralLoadingReIndexError) {
          this.error = { content: this.resources.ReIndexGeneralLoadingErrorMsg };
        }
        if (isVideoUrlUnreachableError) {
          this.error = { content: this.resources.ReIndexVideoUrlUnreachableErrorMsg };
        }
        if (isFileWasRemovedError) {
          this.error = { content: this.resources.ReIndexFileWasRemovedErrorMsg };
        }

        if (this.showLoadingError) {
          this.cdr.detectChanges();
        }
      });
  }

  // TODO: delete when exclude AIs is enabled
  private handleReIndexErrors() {
    this.indexingStore.isAmsUnreachableReIndexError$
      .pipe(
        takeUntil(this.destroy$),
        combineLatestWith(
          this.indexingStore.isSourceVideoDeletedError$,
          this.indexingStore.isIndexingInProgressError$,
          this.indexingStore.isFileInReviewError$,
          this.indexingStore.isFileWasReportedError$,
          this.indexingStore.isProjectRenderInProgressError$,
          this.indexingStore.isAmsAadAppNotInTenantReIndexError$,
          this.indexingStore.isGeneralReIndexError$,
          this.indexingStore.isStorageAccessDeniedReIndexError$,
          this.indexingStore.isStorageUnreachableReIndexError$,
          this.indexingStore.isManageIdentityMissingReIndexError$
        )
      )
      .subscribe(
        ([
          isAmsUnreachableReIndexError,
          isSourceVideoDeletedError,
          isIndexingInProgressError,
          isFileInReviewError,
          isFileWasReportedError,
          isProjectRenderInProgressError,
          isAmsAadAppNotInTenantReIndexError,
          isGeneralReIndexError,
          isStorageAccessDeniedReIndexError,
          isStorageUnreachableReIndexError,
          isManageIdentityMissingReIndexError
        ]) => {
          const errorMessages = [
            { showError: isAmsUnreachableReIndexError, content: this.resources.ReIndexAmsUnreachableErrorMsg, linkText: null, onClick: null },
            { showError: isSourceVideoDeletedError, content: this.resources.ReIndexSourceVideoDeletedErrorMsg, linkText: null, onClick: null },
            { showError: isIndexingInProgressError, content: this.resources.ReIndexVideoIndexingInProgressErrorMsg, linkText: null, onClick: null },
            { showError: isFileInReviewError, content: this.resources.ReIndexFileInReviewErrorMsg, linkText: null, onClick: null },
            { showError: isFileWasReportedError, content: this.resources.ReIndexFileWasReportedErrorMsg, linkText: null, onClick: null },
            {
              showError: isProjectRenderInProgressError,
              content: this.resources.ReIndexProjectRenderInProgressErrorMsg,
              linkText: null,
              onClick: null
            },
            {
              showError: isAmsAadAppNotInTenantReIndexError,
              content: this.resources.ReIndexAmsAadAppNotInTenantErrorMsg,
              linkText: null,
              onClick: null
            },
            { showError: isGeneralReIndexError, content: this.resources.ReIndexGeneralErrorMessage, linkText: null, onClick: null },
            {
              showError: isStorageAccessDeniedReIndexError,
              content: this.resources.ReIndexStorageAccessDeniedErrorMsg,
              linkText: this.resources.StorageAccessDeniedErrorMsgLinkText,
              onClick: () => this.coreStoreService.navigateToViResourceOverviewPortalUrl()
            },
            { showError: isStorageUnreachableReIndexError, content: this.resources.ReIndexStorageUnreachableErrorMsg, linkText: null, onClick: null },
            {
              showError: isManageIdentityMissingReIndexError,
              content: this.resources.ReIndexFileManagedIdentityMissingErrorMsg,
              linkText: null,
              onClick: null
            }
          ];

          const error = errorMessages.find(error => error.showError);

          if (error) {
            this.showReIndexError = true;
            this.error = error;
            this.statusText = this.resources.Failed;
            this.cdr.detectChanges();
          }
        }
      );
  }
}
