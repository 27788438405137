import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { EMPTY, catchError, concatMap, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { UIActionType } from '@common/modules/insights/interfaces';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { IDialogButton, IDialogData, IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { NotificationIcon, NotificationLevel } from '@common/modules/core/services/toast/interfaces';

import { IState } from '../../../core/reducers';
import * as fromCore from '../../../core/selectors';
// eslint-disable-next-line max-len
import { UnknownPersonDetailsDialogComponent } from '../../../customization/components/customization-people/unknown-person/unknown-person-details-dialog/unknown-person-details-dialog.component';
// eslint-disable-next-line max-len
import { UnknownPersonNotificationService } from '../../../customization/components/customization-people/services/unknown-person-notification.service';
import { JobType } from '../../../customization/components/customization-unknown-people/interfaces';
import { GroupingState } from '../../../customization/components/customization-people/unknown-people-empty-state/interfaces';
import * as actions from '../../actions/people/unknown-person.actions';
import * as fromUnknownPersons from '../../selectors/people/unknown-person.selectors';
import { UnknownPersonStoreService } from '../../services/people/unknown-person-store.service';
import { resources } from './resources';
import { CustomizationDataStoreService } from '../../services/customization-data-store.service';

import UnknownPerson = Microsoft.VideoIndexer.Contracts.UnknownPerson;
import PaginatedResult = Microsoft.VideoIndexer.Contracts.PaginatedResult;

@Injectable()
export class UnknownPersonEffects {
  public initUnknownPersonTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.selectUnknownPersonsTab),
      switchMap(() => {
        return [actions.getUnknownPersonsJob(), actions.listUnknownPersons({})];
      })
    )
  );

  public getUnknownPersonsJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUnknownPersonsJob),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([, account]) => {
        if (!account.id) {
          return EMPTY;
        }

        this.trackService.track('unknown_person_effect.get_job.started', {
          category: EventCategory.CUSTOMIZATION
        });
        return this.apiService.Account.Customization.UnknownPersons.getJob(account.id).pipe(
          switchMap(job => {
            this.trackService.track('unknown_person_effect.get_job.success', {
              category: EventCategory.CUSTOMIZATION
            });

            // Update job status to processed
            const updatedJob = (job as PaginatedResult<UnknownPerson>).results
              ? {
                  ...job,
                  jobType: JobType.UnknownPersonsGrouping,
                  state: GroupingState.Processed
                }
              : job;

            return [actions.getUnknownPersonsJobSucceeded({ job: updatedJob as Microsoft.VideoIndexer.Contracts.UnknownPersonsJob })];
          }),
          catchError(error => {
            this.trackService.track('unknown_person_effect.get_job.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [actions.getUnknownPersonsJobFailed({ error })];
          })
        );
      })
    )
  );

  public submitUnknownPersonsJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.submitUnknownPersonsJob),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([, account]) => {
        if (!account.id) {
          return EMPTY;
        }

        this.trackService.track('unknown_person_effect.submit_job.started', {
          category: EventCategory.CUSTOMIZATION
        });
        return this.apiService.Account.Customization.UnknownPersons.submitJob(account.id).pipe(
          switchMap(job => {
            // Notify
            this.unknownPersonNotificationService.notify(
              this.resources.RegroupNotificationHeader,
              this.resources.RegroupNotificationText,
              false,
              NotificationLevel.Info,
              NotificationIcon.Info
            );

            // Update job status to processing
            const updatedJob: Microsoft.VideoIndexer.Contracts.UnknownPersonsJob = job || {
              ...Object.create(null),
              jobType: JobType.UnknownPersonsGrouping,
              state: GroupingState.Processing
            };

            this.trackService.track('unknown_person_effect.submit_job.success', {
              category: EventCategory.CUSTOMIZATION
            });

            return [actions.submitUnknownPersonsJobSucceeded({ job: updatedJob })];
          }),
          catchError(error => {
            // Notify
            this.unknownPersonNotificationService.notify(
              this.resources.GroupFailedNotificationTitle,
              this.resources.GroupFailedNotificationText,
              false,
              NotificationLevel.Error,
              NotificationIcon.Error
            );

            // Update job status to failed
            const updatedJob: Microsoft.VideoIndexer.Contracts.UnknownPersonsJob = {
              ...Object.create(null),
              jobType: JobType.UnknownPersonsGrouping,
              state: GroupingState.Failed
            };

            this.trackService.track('unknown_person_effect.submit_job.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [actions.submitUnknownPersonsJobFailed({ error, job: updatedJob })];
          })
        );
      })
    )
  );

  public getUnknownPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getUnknownPerson),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ id }, account]) => {
        if (!account.id) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.UnknownPersons.get(account.id, id).pipe(
          switchMap(unknownPerson => {
            return [actions.getUnknownPersonSucceeded({ unknownPerson })];
          }),
          catchError(error => {
            return [actions.getUnknownPersonFailed({ error })];
          })
        );
      })
    )
  );

  public listUnknownPersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.listUnknownPersons),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      withLatestFrom(this.store.select(fromUnknownPersons.getPaginationState)),
      switchMap(([[{ searchValue }, account], { page, pageSize }]) => {
        if (!account.id) {
          return EMPTY;
        }
        this.trackService.track('unknown_person_effect.get_list.started', {
          category: EventCategory.CUSTOMIZATION,
          data: {
            page,
            pageSize,
            search: !!searchValue
          }
        });
        const skip = page * pageSize;
        return this.apiService.Account.Customization.UnknownPersons.list(account.id, skip, searchValue).pipe(
          switchMap(unknownPersons => {
            this.trackService.track('unknown_person_effect.get_list.success', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                page,
                resultSize: unknownPersons?.results?.length,
                search: !!searchValue
              }
            });
            return [actions.listUnknownPersonsSucceeded({ unknownPersons })];
          }),
          catchError(error => {
            this.trackService.track('unknown_person_effect.get_list.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                page,
                error,
                search: !!searchValue
              }
            });
            return [actions.listUnknownPersonsFailed({ error })];
          })
        );
      })
    )
  );

  public searchUnknownPersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.searchUnknownPersons),
      switchMap(({ searchValue }) => {
        return [actions.listUnknownPersons({ searchValue })];
      })
    )
  );

  public openUnknownPersonDetailsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.openUnknownPersonDetailsDialog),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ id }, account]) => {
        if (!id || !account?.id) {
          return EMPTY;
        }

        // select unknown person from the store
        this.store.pipe(select(fromUnknownPersons.getById(id)), take(1)).subscribe(unknownPerson => {
          this.trackService.track('unknown_person_effect.details_dialog.opened', {
            category: EventCategory.CUSTOMIZATION
          });
          this.openUnknownPersonDetailsDialog(unknownPerson, account.id);
        });

        return this.apiService.Account.Customization.UnknownPersons.get(account.id, id).pipe(
          switchMap(unknownPerson => {
            return [actions.selectUnknownPerson({ unknownPerson })];
          }),
          catchError(error => {
            return [actions.getUnknownPersonFailed({ error })];
          })
        );
      })
    )
  );

  public updateUnknownPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUnknownPerson),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ unknownPerson }, account]) => {
        if (!unknownPerson || !account.id) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.UnknownPersons.patch(account.id, unknownPerson.id, unknownPerson).pipe(
          switchMap(unknownPerson => {
            return [actions.updateUnknownPersonSucceeded({ unknownPerson })];
          }),
          catchError(error => {
            return [actions.updateUnknownPersonFailed({ error })];
          })
        );
      })
    )
  );

  public deleteUnknownPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteUnknownPerson),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      concatMap(([{ id }, account]) => {
        if (!id || !account.id) {
          return EMPTY;
        }

        this.trackService.track('unknown_person_effect.delete_person.started', {
          category: EventCategory.CUSTOMIZATION
        });
        return this.apiService.Account.Customization.UnknownPersons.delete(account.id, id).pipe(
          switchMap(() => {
            this.trackService.track('unknown_person_effect.delete_person.success', {
              category: EventCategory.CUSTOMIZATION
            });
            return [actions.deleteUnknownPersonSucceeded({ id })];
          }),
          catchError(error => {
            this.toastService.error(null, resources?.FailDeletePerson, false);
            this.trackService.track('unknown_person_effect.delete_person.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [actions.deleteUnknownPersonFailed({ error })];
          })
        );
      })
    )
  );

  public deleteUnknownPersonFace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteUnknownPersonFace),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ unknownPersonId, faceId }, account]) => {
        if (!unknownPersonId || !account.id || !faceId) {
          return EMPTY;
        }

        this.trackService.track('unknown_person_effect.delete_face.started', {
          category: EventCategory.CUSTOMIZATION
        });
        return this.apiService.Account.Customization.UnknownPersons.deleteFace(account.id, unknownPersonId, faceId).pipe(
          switchMap(() => {
            this.trackService.track('unknown_person_effect.delete_face.success', {
              category: EventCategory.CUSTOMIZATION
            });
            return [actions.deleteUnknownPersonFaceSucceeded({ unknownPersonId, faceId })];
          }),
          catchError(error => {
            this.toastService.error(null, resources?.UnknownPersonDeleteFaceFailedMessage, false);
            this.trackService.track('unknown_person_effect.delete_face.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [actions.deleteUnknownPersonFaceFailed({ error })];
          })
        );
      })
    )
  );

  public deleteUnknownPersonFaceSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteUnknownPersonFaceSucceeded),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ unknownPersonId, faceId }, account]) => {
        if (!unknownPersonId || !account.id || !faceId) {
          return EMPTY;
        }
        return this.apiService.Account.Customization.UnknownPersons.get(account.id, unknownPersonId).pipe(
          switchMap(unknownPerson => {
            // Need to find the next best face if the deleted face was the best face
            const bestFace = unknownPerson.faces.sort((b, a) => b.qualityRank - a.qualityRank)[0];
            return [
              actions.selectUnknownPerson({ unknownPerson }),
              actions.updateUnknownPersonSucceeded({
                unknownPerson: {
                  id: unknownPerson.id,
                  name: unknownPerson.name,
                  score: unknownPerson.score,
                  imageCount: unknownPerson.imageCount,
                  bestFaceThumbnailId: bestFace?.thumbnailId,
                  bestFaceVideoId: bestFace?.videoId,
                  lastUpdated: unknownPerson.lastUpdated
                }
              })
            ];
          })
        );
      })
    )
  );

  public openDeleteUnknownPersonDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.openDeleteUnknownPeopleDialog),
        tap(({ personIds }) => {
          if (!personIds?.length) {
            return EMPTY;
          }
          this.trackService.track('unknown_person_effect.unknown_person_delete_dialog.opened', {
            category: EventCategory.CUSTOMIZATION,
            data: {
              count: personIds.length
            }
          });
          this.openDeleteModelDialog(personIds);
        })
      ),
    {
      dispatch: false
    }
  );

  public moveUnknownPersonToModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.moveUnknownPersonToModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccount)),
      switchMap(([{ unknownPerson, personName }, account]) => {
        if (!unknownPerson || !personName || !account.id) {
          return EMPTY;
        }

        this.trackService.track('unknown_person_effect.person_move.started', {
          category: EventCategory.CUSTOMIZATION
        });
        return this.apiService.Account.Customization.UnknownPersons.moveToKnownPersonModels(account.id, unknownPerson.id, personName).pipe(
          switchMap(_ => {
            this.trackService.track('unknown_person_effect.person_move.success', {
              category: EventCategory.CUSTOMIZATION
            });
            return [actions.moveUnknownPersonToModelSucceeded({ unknownPerson, personName })];
          }),
          catchError(error => {
            this.trackService.track('unknown_person_effect.person_move.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [actions.moveUnknownPersonToModelFailed({ error })];
          })
        );
      })
    )
  );

  public moveUnknownPersonToModelSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.moveUnknownPersonToModelSucceeded),
      switchMap(({ unknownPerson, personName }) => {
        const force = true;
        this.customizationDataStoreService.loadPersonModels(force);
        this.dialogRef.close();
        this.unknownPersonNotificationService.notify(
          this.resources.UnknownPersonMoveToModelSuccessTitle,
          this.translationService.instant('UnknownPersonMoveToModelSuccessText', { personName }),
          false,
          NotificationLevel.Success,
          NotificationIcon.Success
        );
        this.trackService.track('unknown_person_effect.person_move.success', {
          category: EventCategory.CUSTOMIZATION
        });
        return [actions.deleteUnknownPersonSucceeded({ id: unknownPerson.id })];
      })
    )
  );

  private dialogRef;
  private resources = resources;
  private translationService: TranslateHelperService;

  constructor(
    private actions$: Actions,
    private store: Store<IState>,
    private apiService: ApiService,
    private injector: Injector,
    private trackService: TrackService,
    private unknownPersonStoreService: UnknownPersonStoreService,
    private customizationDataStoreService: CustomizationDataStoreService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private unknownPersonNotificationService: UnknownPersonNotificationService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.translationService = this.injector.get(TranslateHelperService);
    this.translationService.translateResourcesInstant(this.resources);
  }

  private openUnknownPersonDetailsDialog(unknownPerson: UnknownPerson, accountId: string) {
    const dialog: IDialogData = {
      class: 'unknown-person-details-dialog',
      title: unknownPerson.name,
      component: UnknownPersonDetailsDialogComponent,
      componentData: {
        updating$: this.unknownPersonStoreService.getIsUpdating$(),
        accountId,
        unknownPerson$: this.unknownPersonStoreService.getSelected$()
      }
    };

    this.dialogRef = this.dialogService.openDialog(dialog, '808px', '632px');

    // reset selected unknown person on dialog close
    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(actions.selectUnknownPerson({ unknownPerson: null }));
    });

    this.dialogRef.componentInstance.actionChange.pipe().subscribe((event: IDialogEvent) => {
      if (event.action.type === UIActionType.CANCEL) {
        this.trackService.track('unknown_person_effect.details_dialog_cancel.clicked', {
          category: EventCategory.CUSTOMIZATION
        });
      }

      if (event.action.type === UIActionType.UPDATE) {
        const { unknownPerson, personName } = event.action.value;
        this.trackService.track('unknown_person_effect.details_dialog_move_to_model.clicked', {
          category: EventCategory.CUSTOMIZATION
        });
        this.store.dispatch(actions.moveUnknownPersonToModel({ personName, unknownPerson }));
      }

      if (event.action.type == UIActionType.DELETE_PERSON) {
        this.dialogRef.close();
        this.trackService.track('unknown_person_effect.details_dialog_delete_person.clicked', {
          category: EventCategory.CUSTOMIZATION
        });
        this.store.dispatch(actions.openDeleteUnknownPeopleDialog({ personIds: [unknownPerson.id] }));
      }

      if (event.action.type == UIActionType.DELETE_FACE) {
        if (event.action?.value) {
          this.trackService.track('unknown_person_effect.details_dialog_delete_face.clicked', {
            category: EventCategory.CUSTOMIZATION
          });
          const { unknownPersonId, faceId } = event.action.value;
          this.store.dispatch(actions.deleteUnknownPersonFace({ unknownPersonId, faceId }));
        }
      }
    });
  }

  private openDeleteModelDialog(peopleIds: string[]) {
    const cancelBtn: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: resources.Cancel,
        value: null,
        id: 'delete-unknown-people-cancel-btn'
      }
    };

    const deleteBtn: IDialogButton = {
      type: ActionButtonType.PRIMARY,
      action: {
        title: resources.Delete,
        value: null,
        id: 'delete-unknown-people-delete-btn'
      }
    };

    const dialog: IDialogData = {
      class: 'delete-unknown-people-dialog',
      title: resources.UnknownPeopleDeleteDialogTitle,
      content: resources.UnknownPeopleDeleteDialogContent,
      secondaryButton: cancelBtn,
      primaryButton: deleteBtn
    };

    const deleteModelDialogRef = this.dialogService.openDialog(dialog, '440px');
    deleteModelDialogRef.componentInstance.actionChange.pipe(take(1)).subscribe((event: IDialogEvent) => {
      if (event.action.id === deleteBtn.action.id) {
        this.trackService.track('unknown_person_effect.delete_dialog_delete_button.clicked', {
          category: EventCategory.CUSTOMIZATION,
          data: {
            count: peopleIds.length
          }
        });
        peopleIds.forEach(id => this.store.dispatch(actions.deleteUnknownPerson({ id })));
      }
    });
  }
}
