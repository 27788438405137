import { createSelector } from '@ngrx/store';

import { selectIndexingState } from '../reducers';

export const selectIndexingViewState = createSelector(selectIndexingState, state => state.viewState);

export const selectAdvancedMode = createSelector(selectIndexingViewState, state => state?.showAdvanced);

export const selectIndexingMode = createSelector(selectIndexingViewState, state => state?.indexingMode);

export const selectIsIndexingProcessDirty = createSelector(selectIndexingViewState, state => state.isIndexingProcessDirty);

export const selectSelectedAdvancedSettings = createSelector(selectIndexingViewState, state => state?.selectedAdvancedSettings);
