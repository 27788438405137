import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { CustomizationPage, SideMenuOption } from '@common/modules/core/services/interfaces';

import { VIRoutingMap } from '../../app/routing/routes';
import * as AccountsActions from '../actions/accounts.actions';
import * as CustomizationViewActions from '../actions/customization-view.actions';
import * as RouterActions from '../actions/router.actions';
import * as SideMenuActions from '../actions/side-menu.actions';
import { IState } from '../reducers';
import { ofRoute } from '../services/operations';
import { SpeechPageStoreService } from '../../customization-data/services/speech/speech-page-store.service';

@Injectable()
export class SideMenuEffects {
  public navigateFromSideMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SideMenuActions.sideMenuOptionSelected),
      switchMap(({ option }) => {
        switch (option) {
          case SideMenuOption.Customizations:
            this.handleCustomizationsOptionSelected();
            return EMPTY;
          // please leave init page null because in responsive view customization sub menu is always shown
          case SideMenuOption.Settings:
            return [AccountsActions.navigateToSettings({}), CustomizationViewActions.initPage({ page: null })];
          case SideMenuOption.Media:
            return [RouterActions.Go({ path: [`/${VIRoutingMap.mediaGallery.path}`] }), CustomizationViewActions.initPage({ page: null })];
        }
      })
    )
  );

  public sideMenuCustomizationNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute(this.customizationRoutes),
      switchMap(() => {
        return [SideMenuActions.sideMenuInitOption({ option: SideMenuOption.Customizations })];
      })
    )
  );

  public sideMenuSpeechNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        ofRoute([
          `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechDatasets.path}`,
          `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationSpeech.path}/${VIRoutingMap.customizationSpeechModels.path}`
        ]),
        tap(() => {
          this.speechPageStoreService.isSpeechEnabled().subscribe(isSpeechEnabled => {
            if (isSpeechEnabled) {
              this.store.dispatch(SideMenuActions.sideMenuInitOption({ option: SideMenuOption.Customizations }));
            }
          });
        })
      ),
    {
      dispatch: false
    }
  );
  public sideMenuSettingsNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute([`/${VIRoutingMap.settings.path}`]),
      switchMap(() => {
        return [SideMenuActions.sideMenuInitOption({ option: SideMenuOption.Settings })];
      })
    )
  );
  public sideMenuMediaNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute([
        `/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`,
        `/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryProjects.path}`,
        `/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.gallerySamples.path}`,
        `/${VIRoutingMap.mediaVideo.path}`,
        `/${VIRoutingMap.mediaProject.path}`,
        `/${VIRoutingMap.edgeExtensionVideo.path}`
      ]),
      switchMap(() => {
        return [SideMenuActions.sideMenuInitOption({ option: SideMenuOption.Media })];
      })
    )
  );

  private get customizationRoutes() {
    return [
      `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}`,
      `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}/${VIRoutingMap.customizationPeopleUnknown.path}`,
      `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguage.path}`,
      `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationBrands.path}`,
      `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationLanguageId.path}`
    ];
  }

  constructor(private store: Store<IState>, private actions$: Actions, private speechPageStoreService: SpeechPageStoreService) {}

  private handleCustomizationsOptionSelected() {
    this.speechPageStoreService.isSpeechEnabled().subscribe(isSpeechEnabled => {
      const defaultPage = isSpeechEnabled ? CustomizationPage.Speech : CustomizationPage.Language;
      this.store.dispatch(AccountsActions.navigateToCustomization({ page: defaultPage }));
    });
  }
}
