import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { take, map, filter, withLatestFrom, switchMap } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';

import { CoreStoreService } from '../services/core-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export const RouteSelectAccountResolver: ResolveFn<Observable<boolean>> = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(RouteSelectAccountResolverService).resolve(next);
};

@Injectable({
  providedIn: 'root'
})
export class RouteSelectAccountResolverService {
  constructor(private coreStore: CoreStoreService, private logger: LoggerService) {
    this.logger.log('[RouteSelectAccountResolver] init');
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.coreStore.coreMetadataLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      withLatestFrom(this.coreStore.selectAccountIds$),
      withLatestFrom(this.coreStore.selectedAccountId$),
      switchMap(([[, accountIds], selectedAccountId]) => {
        const accountId = route?.params?.accountId;
        if (accountId) {
          this.logger.log('[RouteSelectAccountResolver] accountId: ' + accountId);
          if (selectedAccountId === accountId) {
            return of(true);
          } else if (accountIds.includes(accountId)) {
            this.logger.log('[RouteSelectAccountResolver] selectAccount: ' + accountId);
            this.coreStore.selectAccount(accountId);
            return of(true);
          } else {
            this.logger.log('[RouteSelectAccountResolver] wait isFullMetadataLoaded');
            return this.coreStore.isFullMetadataLoaded$.pipe(
              filter(loaded => loaded),
              take(1),
              withLatestFrom(this.coreStore.selectAccountIds$),
              map(([, fullAccountIds]) => {
                if (fullAccountIds.includes(accountId)) {
                  this.logger.log('[RouteSelectAccountResolver] isFullMetadataLoaded selectAccount: ' + accountId);
                  this.coreStore.selectAccount(accountId);
                  return true;
                }

                this.logger.log('[RouteSelectAccountResolver] isFullMetadataLoaded pageNotFoundError');
                this.coreStore.navigate([`/${VIRoutingMap.pageNotFoundError.path}`]);
                return false;
              })
            );
          }
        } else {
          return of(true);
        }
      })
    );
  }
}
