import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { take, switchMap, filter, catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ConfigService } from '../../core/services/config/config.service';
import { LoggerService } from '../../core/services/logger/logger.service';
import { isUserNotAuthenticatedError, isAccessTokenRequest } from '../../api/utils/request.function';
import { AuthSessionService } from '../services/auth-session.service';
import { AccessTokenService } from '../services/access-token.service';
import { EventCategory, TrackService } from '../../core/services/track';

@Injectable()
export class AuthSessionInterceptor implements HttpInterceptor {
  private refreshSessionInProgress = false;
  private refreshSessionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private apimBase: string;

  constructor(
    public auth: AuthService,
    public configService: ConfigService,
    private logger: LoggerService,
    private authSessionService: AuthSessionService,
    private accessTokenService: AccessTokenService,
    private trackService: TrackService
  ) {
    this.apimBase = this.configService.Config?.api?.apimBase;
  }

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // If its not api route , continue
    if (!request.url.includes('/api') && !request.url.includes(this.apimBase)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError(error => {
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          this.logger.log(`[SessionInterceptor] not 401 error: ${error.status}`);
          this.trackService.track('auth_session_interceptor.error.skip_refresh_token', {
            category: EventCategory.AUTH,
            data: {
              error
            }
          });
          // eslint-disable-next-line deprecation/deprecation
          return throwError(error);
        }

        // If it is not embed mode we want to refresh the session
        if (isUserNotAuthenticatedError(error) && !this.auth.isEmbedMode() && this.auth.isAuthenticated()) {
          if (!this.refreshSessionInProgress) {
            this.refreshSessionInProgress = true;
            this.refreshSessionSubject.next(null);
            this.authSessionService
              .tryRefreshSession()
              .pipe(take(1))
              // eslint-disable-next-line deprecation/deprecation
              .subscribe(
                (refreshSession: boolean) => {
                  this.refreshSessionInProgress = false;
                  if (refreshSession) {
                    this.refreshSessionSubject.next('success');
                    return this.handleRefreshTokenRequest(request, next);
                  } else {
                    this.refreshSessionSubject.next('failed');
                    // eslint-disable-next-line deprecation/deprecation
                    return throwError(error);
                  }
                },
                err => {
                  this.refreshSessionInProgress = false;
                  this.refreshSessionSubject.next('failed');
                  // eslint-disable-next-line deprecation/deprecation
                  return throwError(err);
                }
              );
          }

          return this.refreshSessionSubject.pipe(
            filter(accessToken => accessToken !== null),
            take(1),
            switchMap(accessToken => {
              this.trackService.track('auth_session_interceptor.error.refresh_session_fallback.refresh_in_progress', {
                category: EventCategory.AUTH
              });
              if (accessToken === 'failed') {
                // eslint-disable-next-line deprecation/deprecation
                return throwError(error);
              }

              return this.handleRefreshTokenRequest(request, next);
            })
          );
        }

        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (isAccessTokenRequest(request)) {
          // We do another check to see if refresh token failed
          // eslint-disable-next-line deprecation/deprecation
          return throwError(error);
        }
        // eslint-disable-next-line deprecation/deprecation
        return throwError(error);
      })
    );
  }

  private handleRefreshTokenRequest(request: HttpRequest<object>, next: HttpHandler) {
    if (isAccessTokenRequest(request)) {
      return next.handle(request);
    } else {
      return next.handle(this.accessTokenService.addAuthenticationToken(request));
    }
  }
}
