import { IHttpWrapper } from '../../interfaces';
import { getRequestSettings } from '../../utils/request.function';
import { IBrandsSettings } from '../../../../../apps/web/src/customization/components/brand/brand-container/interfaces';

import BrandContractV2 = Microsoft.VideoIndexer.Contracts.BrandContractV2;

export class CustomizationBrandsModelsApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route("v2/Accounts/{accountId}/Customization/Brands")]
  public getList(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/brands/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.BrandContractV2[]>(url, requestSettings);
  }

  // [HttpPost]
  // [Route("v2/Accounts/{accountId}/Customization/Brands")]
  public addBrand(accountId: string, brand: BrandContractV2, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/brands/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.BrandContractV2>(url, brand, requestSettings);
  }

  // [HttpPut]
  // [Route("v2/Accounts/{accountId}/Customization/Brands/{brandId}")]
  public updateBrand(accountId: string, brand: BrandContractV2, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/brands/${brand.id}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.BrandContractV2>(url, brand, requestSettings);
  }

  // [HttpDelete]
  // [Route("v2/Accounts/{accountId}/Customization/Brands/{brandId}")]
  public deleteBrand(accountId: string, brandId: number, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/brands/${brandId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);
    return this.ApiWrapper.HttpClient.delete<void>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("v2/Accounts/{accountId}/Customization/BrandsModelSettings")]
  public getBrandsModelSettings(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/BrandsModelSettings`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache) as object;

    return this.ApiWrapper.HttpClient.get<IBrandsSettings>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("v2/Accounts/{accountId}/Customization/BrandsModelSettings")]
  public updateBrandsModelSettings(accountId: string, brandSettings: IBrandsSettings, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/brandsModelSettings`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache) as object;

    return this.ApiWrapper.HttpClient.put<IBrandsSettings>(url, brandSettings, requestSettings);
  }
}
