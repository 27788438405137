import { Component, OnInit, Input } from '@angular/core';

import { IAction } from '../../interfaces';
import { FocusableComponent } from '../../../accessibility/focusable/focusable.component';

@Component({
  selector: 'app-vi-action-link',
  templateUrl: './action-link.component.html',
  styleUrls: ['./action-link.component.scss']
})
export class ActionLinkComponent extends FocusableComponent implements OnInit {
  // Input
  @Input() public action: IAction;

  // If to render opener or not. used for action menus.
  @Input() public hasOpenerIcon = false;

  // The width of the action button
  @Input() public width = 'auto';

  // If we show the opener up or down
  @Input() public expanded = false;

  // size class [small/default]
  @Input() public sizeClass = '';

  // If button is responsive it will hide its title
  // and opener if the screen is small
  @Input() public responsive = true;

  // For tabindex
  @Input() public activeContainer = false;

  // Is the button disabled or not.
  @Input() public isDisabled = false;

  @Input() public showAccessibilityTooltip = false;

  constructor() {
    super();
  }

  public ngOnInit() {}
}
