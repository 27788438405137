import { ProgressStyle } from 'src/libs/vi-ui/src/lib/components/progress-bar/interfaces';

import { IAction } from '@common/modules/shared/interfaces';

export enum UIGridCellTypes {
  TEXT = 'TEXT',
  EDIT = 'EDIT',
  PROGRESS_BAR = 'PROGRESS_BAR',
  ACTION_MENU = 'ACTION_MENU',
  SORT_MENU = 'SORT_MENU',
  CREATE = 'CREATE',
  BUTTON = 'BUTTON',
  PERSONA = 'PERSONA',
  LINK = 'LINK',
  MERGE = 'MERGE'
}

export enum IMaxWidthCalcType {
  PARENT = 'PARENT',
  PARENT_OF_PARENT = 'PARENT_OF_PARENT'
}

export interface IUIGridCell {
  id: string;
  value: string;
  type: UIGridCellTypes;
  name: string;
  title?: string;
  key?: string;
  placeholder?: string;
  url?: string;
  edit?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  loading?: boolean;
  action?: IAction;
  actionsList?: IAction[];
  width?: string;
  minWidth?: string;
  status?: string;
  maxWidthCalcType?: IMaxWidthCalcType;
}

export interface IUISortCell extends IUIGridCell {
  selectedColumn: string;
  selectedDirection: string;
}

export interface IUIProgressCell extends IUIGridCell {
  progressStyle?: ProgressStyle;
  leftTitleStyle?: ProgressStyle;
  leftTitle?: string;
  rightTitle?: string;
  progress: number;
}

export interface IEventCell {
  cell: IUIGridCell;
  action?: IAction;
  value?: string;
  edit?: boolean;
  index?: number;
  rowsIds?: number[];
  modelIds?: number[];
}
