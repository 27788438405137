import { IAction } from '@common/modules/shared/interfaces';

import { IndexingPreset } from './interfaces';
import { UIIndexingActionType } from '../interfaces';

export const presetsAction: IAction = {
  type: UIIndexingActionType.INDEX,
  value: UIIndexingActionType.INDEX,
  selected: false,
  id: 'index'
};

// Basic presets

export const basicAudioOnlyPreset: IAction = {
  key: 'IndexingPresetBasicAudioLabel',
  selected: true,
  type: UIIndexingActionType.PRESET_AUDIO_BASIC,
  value: IndexingPreset.AUDIO_BASIC,
  id: 'audio-basic',
  groupKey: 'LabelIndexingPresetAudioOnly'
};

export const basicVideoOnlyPreset: IAction = {
  key: 'IndexingPresetBasicVideoLabel',
  selected: false,
  type: UIIndexingActionType.PRESET_VIDEO_BASIC,
  value: IndexingPreset.VIDEO_BASIC,
  id: 'video-basic',
  groupKey: 'LabelIndexingPresetVideoOnly'
};

export const basicPreset: IAction = {
  key: 'IndexingPresetBasicVideoAudioLabel',
  selected: true,
  type: UIIndexingActionType.PRESET_VIDEO_AUDIO_BASIC,
  value: IndexingPreset.VIDEO_AUDIO_BASIC,
  id: 'basic',
  groupKey: 'LabelIndexingPresetVideoAudio'
};

// Standard presets

export const standardAudioOnlyPreset: IAction = {
  key: 'IndexingPresetStandardAudioLabel',
  selected: true,
  type: UIIndexingActionType.PRESET_AUDIO_STANDARD,
  value: IndexingPreset.AUDIO_STANDARD,
  id: 'standard-audio',
  groupKey: 'LabelIndexingPresetAudioOnly'
};

export const standardVideoOnlyPreset: IAction = {
  key: 'IndexingPresetStandardVideoLabel',
  selected: true,
  type: UIIndexingActionType.PRESET_VIDEO_STANDARD,
  value: IndexingPreset.VIDEO_STANDARD,
  id: 'video-standard',
  groupKey: 'LabelIndexingPresetVideoOnly'
};

export const standardPreset: IAction = {
  key: 'IndexingPresetStandardVideoAudioLabel',
  selected: true,
  type: UIIndexingActionType.PRESET_VIDEO_AUDIO_STANDARD,
  value: IndexingPreset.VIDEO_AUDIO_STANDARD,
  id: 'standard',
  groupKey: 'LabelIndexingPresetVideoAudio'
};

// Advanced presets

export const advancedAudioOnlyPreset: IAction = {
  key: 'IndexingPresetAdvancedAudioLabel',
  selected: false,
  type: UIIndexingActionType.PRESET_AUDIO_ADVANCED,
  value: IndexingPreset.AUDIO_ADVANCED,
  id: 'advanced-audio',
  groupKey: 'LabelIndexingPresetAudioOnly'
};

export const advancedVideoOnlyPreset: IAction = {
  key: 'IndexingPresetAdvancedVideoLabel',
  selected: false,
  type: UIIndexingActionType.PRESET_VIDEO_ADVANCED,
  value: IndexingPreset.VIDEO_ADVANCED,
  id: 'advanced-video',
  groupKey: 'LabelIndexingPresetVideoOnly'
};

export const advancedPreset: IAction = {
  key: 'IndexingPresetAdvancedVideoAudioLabel',
  selected: false,
  type: UIIndexingActionType.PRESET_VIDEO_AUDIO_ADVANCED,
  value: IndexingPreset.VIDEO_AUDIO_ADVANCED,
  id: 'advanced',
  groupKey: 'LabelIndexingPresetVideoAudio'
};

export const presets: IAction[] = [
  // video + audio
  {
    ...standardPreset,
    value: IndexingPreset.DEFAULT,
    id: 'default'
  },
  { ...advancedPreset },

  // video only
  { ...standardVideoOnlyPreset },
  { ...advancedVideoOnlyPreset },

  // audio only
  { ...basicAudioOnlyPreset },
  { ...standardAudioOnlyPreset },
  { ...advancedAudioOnlyPreset }
];

export const presetsWithBasicVideo: IAction[] = [
  // video + audio
  { ...basicPreset },
  {
    ...standardPreset,
    value: IndexingPreset.DEFAULT,
    id: 'default'
  },
  { ...advancedPreset },

  // video only
  { ...basicVideoOnlyPreset },
  { ...standardVideoOnlyPreset },
  { ...advancedVideoOnlyPreset },

  // audio only
  { ...basicAudioOnlyPreset },
  { ...standardAudioOnlyPreset },
  { ...advancedAudioOnlyPreset }
];
