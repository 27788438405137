import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList
} from '@angular/core';

import { Observable, Subject, take, takeUntil, withLatestFrom } from 'rxjs';

import { startCase } from 'lodash-es';
import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';
import { ShimmerElementType } from 'src/libs/vi-ui/src/lib/components/shimmer/interfaces';

import { IAction } from '@common/modules/shared/interfaces';
import { RAI_LEARN_MORE, UPLOAD_INDEXING_PRESET } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { ExcludableAIsModels, SupportedAIModels } from '@common/modules/api/interfaces';
import { CheckboxSize } from '@common/modules/shared/components/checkbox-with-label/interfaces';

import { EdgeExtensionsStoreService } from '../../../../../core/services/edge-extensions-store.service';
import { IndexingStoreService } from '../../../../services/indexing-store.service';
import { SpecialLanguageKey } from './../../../../../core/languages';
import { IndexingPreset } from './interfaces';
import { resources } from './resources';
import { presets, presetsAction, presetsWithBasicVideo } from './actions';
import { UIIndexingActionType } from '../interfaces';
import { PresetHelperService } from '../../../../services/preset-helper.service';
import { SupportedAIsMap } from '../../../../interfaces';
import { ExcludeAIsHelperService } from '../../../../services/exclude-ais-helper.service';

@Component({
  selector: 'vi-indexing-presets',
  templateUrl: './indexing-presets.component.html',
  styleUrls: ['./indexing-presets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingPresetsComponent implements OnInit, OnDestroy {
  @Input() public indexingPreset: IndexingPreset;
  @Input() public excludeRAI: boolean = false;
  @Input() public isDisabled = false;
  @Output() public presetChange = new EventEmitter<IndexingPreset>();
  @Output() public excludeRAIChange = new EventEmitter<boolean>();
  @Output() public excludeAIsChange = new EventEmitter<ExcludableAIsModels[]>();

  @ViewChild('Info', { read: ElementRef }) public info: ElementRef;
  @ViewChild('ModelInfo', { read: ElementRef }) public modelInfo: ElementRef;
  @ViewChildren('AICheckbox', { read: ElementRef }) public aIsCheckbox: QueryList<ElementRef>;

  public resources = resources;
  public presetsAction = presetsAction;
  public presetsOptions = presets;
  public edgePresetsOptions = [];
  public selectedPreset: IAction;
  public showPresetTooltip: boolean;
  public showAIInfoTooltip: boolean;
  public showCantExcludeRAITooltip: boolean;
  public indexingPresetsLink = UPLOAD_INDEXING_PRESET;
  public RAILink = RAI_LEARN_MORE;
  public dropdownLabelId = 'indexing-label';
  public dropdownId = 'indexing-input';
  public linkSize = '13-18';
  public hasSelectedEdgeExtension = false;
  public isExcludeAIsEnabled = false;
  public isAccountLimitedWithFaces = false;
  public checkboxSize = CheckboxSize.Small;
  public isLoadSupportedAIsError$: Observable<boolean>;
  public isSupportedAIsLoaded$: Observable<boolean>;
  public ShimmerElementType = ShimmerElementType;

  public supportedAIsMap = {} as SupportedAIsMap;
  public supportedAIs: SupportedAIModels[];
  public isLoadSupportedAIsError = false;

  public readonly LOADING_SHIMMER_ELEMENTS = 21;

  private destroy$ = new Subject<void>();
  private selectedLanguage: string = 'en-US';
  private excludeAIs: ExcludableAIsModels[] = [];
  private modelOnHover: ExcludableAIsModels;

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private cdr: ChangeDetectorRef,
    private edgeExtensionService: EdgeExtensionsStoreService,
    private presetHelperService: PresetHelperService,
    private featureSwitchService: FeatureSwitchService,
    private coreStore: CoreStoreService,
    private excludeAIsHelperService: ExcludeAIsHelperService
  ) {}

  public ngOnInit(): void {
    this.isExcludeAIsEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);
    this.initPresets();
    this.initTranslations();
    this.setSelectedPreset();
    this.initEdge();

    if (this.isExcludeAIsEnabled) {
      this.initAIs();
    }

    if (this.disableExcludeRAI && !this.isExcludeAIsEnabled) {
      this.toggleRAI(false);
    }

    this.indexingStore.selectedLanguage$.pipe(take(1)).subscribe(language => {
      this.selectedLanguage = language;
      this.handleLanguageChange();
    });

    this.coreStore.isAccountLimitedWithFaces$.pipe(take(1)).subscribe(isAccountLimitedWithFaces => {
      this.isAccountLimitedWithFaces = isAccountLimitedWithFaces;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get excludeRAITooltipText() {
    if (this.selectedPreset.value === IndexingPreset.VIDEO_ADVANCED) {
      return this.resources.IndexingPresetExcludeRAIVideoAdvancedTooltipText;
    } else {
      return this.resources.IndexingPresetExcludeRAITooltipText;
    }
  }

  public get disableExcludeRAI() {
    return (
      this.isDisabled ||
      [UIIndexingActionType.PRESET_VIDEO_AUDIO_ADVANCED, UIIndexingActionType.PRESET_VIDEO_ADVANCED].includes(
        this.selectedPreset.type as UIIndexingActionType
      )
    );
  }

  public get presets() {
    return this.hasSelectedEdgeExtension ? this.edgePresetsOptions : this.presetsOptions;
  }

  public set presets(presets: IAction[]) {
    if (this.hasSelectedEdgeExtension) {
      this.edgePresetsOptions = presets;
    } else {
      this.presetsOptions = presets;
    }
  }

  public togglePresetTooltip(isHover: boolean) {
    this.showPresetTooltip = !!isHover;
  }

  public toggleAIInfoTooltip(isHover: boolean, model: ExcludableAIsModels) {
    this.showAIInfoTooltip = !!isHover;
    this.modelOnHover = isHover ? model : null;
  }

  public selectPreset(preset: IAction) {
    this.selectedPreset = preset;
    this.presetChange.emit(preset.value);

    if (this.disableExcludeRAI && !this.isExcludeAIsEnabled) {
      this.toggleRAI(false);
    }

    if (this.isExcludeAIsEnabled) {
      // Reset excludeAIs if the preset is changed
      this.resetExcludeAIs();
      this.indexingStore.loadSupportedAIs();
    }
  }

  public onExcludeAI(checked: boolean, model: ExcludableAIsModels) {
    const excludeAIsSet = new Set(this.excludeAIs);
    if (checked) {
      // Check the model and all the models it depends on
      this.excludeAIsHelperService.checkModel(model, this.supportedAIsMap, excludeAIsSet);
      this.excludeAIsHelperService.markRequiredModelsAsChecked(this.supportedAIsMap, model, excludeAIsSet);
    } else {
      this.excludeAIsHelperService.uncheckModel(model, this.supportedAIsMap, excludeAIsSet);
      this.excludeAIsHelperService.uncheckDependentModels(this.supportedAIsMap, model, excludeAIsSet);
    }

    this.excludeAIs = Array.from(excludeAIsSet);
    this.excludeAIsChange.emit(this.excludeAIs);

    this.indexingStore.updateSupportedAIsState(this.excludeAIsHelperService.getSupportedAIsState(this.supportedAIsMap));
  }

  public toggleRAI(excludeRAI: boolean) {
    this.excludeRAI = excludeRAI;
    this.excludeRAIChange.emit(this.excludeRAI);

    if (this.isExcludeAIsEnabled) {
      this.isLoadSupportedAIsError ? this.toggleSensitiveAIsOnError(excludeRAI) : this.toggleExcludeSensitiveAIs(excludeRAI);
    }
  }

  public toggleExcludeSensitiveAIs(excludeRAI: boolean) {
    const excludeAIsSet = new Set(this.excludeAIs);
    if (excludeRAI) {
      this.excludeAIsHelperService.disableSensitiveAIs(excludeAIsSet, this.supportedAIsMap);
    } else {
      this.excludeAIsHelperService.enableSensitiveAIs(excludeAIsSet, this.supportedAIsMap);
    }

    this.excludeAIs = Array.from(excludeAIsSet);
    this.excludeAIsChange.emit(this.excludeAIs);

    this.indexingStore.updateSupportedAIsState(this.excludeAIsHelperService.getSupportedAIsState(this.supportedAIsMap));
  }

  public get hideExcludeRAI() {
    return this.selectedPreset.type === UIIndexingActionType.PRESET_AUDIO_BASIC;
  }

  public onHoverSupportedAICheckbox(isHover: boolean, model: ExcludableAIsModels) {
    this.modelOnHover = isHover ? model : null;
  }

  public onHoverRAICheckbox(isHover: boolean) {
    this.showCantExcludeRAITooltip = isHover && this.disableExcludeRAI;
  }

  public shouldShowRAIExcludedTooltip(model: ExcludableAIsModels): boolean {
    return (
      this.excludeRAI && model === this.modelOnHover && this.excludeAIsHelperService.isSensitiveAI(model) && !this.shouldShowAIInfoTooltip(model)
    );
  }

  public shouldShowAIInfoTooltip(model: ExcludableAIsModels): boolean {
    return this.modelOnHover === model && this.showAIInfoTooltip;
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources);
    this.presetsOptions.forEach(preset => {
      preset.title = this.resources[preset.key];
      preset.label = this.resources[preset.key.replace(/Label/i, 'Details')];
      preset.group = this.resources[preset.groupKey];
    });

    this.edgePresetsOptions?.forEach(preset => {
      preset.title = this.resources[preset.key];
      preset.label = this.resources[preset.key.replace(/Label/i, 'EdgeDetails')];
      preset.group = this.resources[preset.groupKey];
    });
  }

  private setSelectedPreset() {
    // Edge extension only supports standard audio only for multi-lid
    if (this.hasSelectedEdgeExtension && this.isAutoDetectLanguage) {
      this.presetsOptions.forEach((action: IAction) => {
        action.selected = false;
        if (action.type === UIIndexingActionType.PRESET_AUDIO_STANDARD) {
          action.isDisabled = this.isAutoDetectLanguage;
          action.selected = true;
          this.selectPreset(action);
        }
      });
      return;
    }

    this.selectedPreset = this.presets.find(preset => preset.selected);
    if (this.indexingPreset) {
      this.selectedPreset = this.presets.find(preset => preset.value.toLowerCase() === this.indexingPreset.toLowerCase());
    }
  }

  private handleLanguageChange() {
    const actionsToDisable = this.presets.filter((action: IAction) =>
      [
        UIIndexingActionType.PRESET_VIDEO_BASIC,
        UIIndexingActionType.PRESET_VIDEO_STANDARD,
        UIIndexingActionType.PRESET_VIDEO_ADVANCED,
        UIIndexingActionType.PRESET_AUDIO_BASIC,
        UIIndexingActionType.PRESET_VIDEO_AUDIO_BASIC
      ].includes(action.type as UIIndexingActionType)
    );
    actionsToDisable.forEach((action: IAction) => (action.isDisabled = this.isAutoDetectLanguage));
    this.presets = [...this.presets];
    this.setSelectedPreset();
    this.cdr.detectChanges();
  }

  private initEdge() {
    this.edgeExtensionService.hasSelectedEdgeExtension$.pipe(takeUntil(this.destroy$)).subscribe(hasSelectedEdgeExtension => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
      this.setSelectedPreset();
    });
  }

  private initPresets() {
    this.edgePresetsOptions = this.presetHelperService.getEdgePresets();
    if (this.featureSwitchService.featureSwitch(FeatureSwitch.BasicVideoPreset)) {
      this.presetsOptions = presetsWithBasicVideo;
    }
  }

  private initAIs() {
    this.indexingStore.isLoadSupportedAIsError$.pipe(takeUntil(this.destroy$)).subscribe(isLoadSupportedAIsError => {
      this.isLoadSupportedAIsError = isLoadSupportedAIsError;
    });

    this.isSupportedAIsLoaded$ = this.indexingStore.isSupportedAIsLoaded$;

    this.indexingStore.selectedSupportedAIs$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.indexingStore.excludeAIs$))
      .subscribe(([supportedAIs, excludeAIs]) => {
        this.supportedAIsMap = {} as SupportedAIsMap;
        Object.entries(supportedAIs).map(([ai, value]) => {
          this.supportedAIsMap[ai] = {
            ...value,
            displayName: this.translateSupportedAIName(ai as SupportedAIModels, value.displayName, value.isRequired),
            info: value.info ? this.translate.instant(value.info) : null,
            tooltip: this.getSensitiveAIsTooltip(ai as ExcludableAIsModels)
          };
        });

        this.excludeAIs = excludeAIs;

        this.supportedAIs = Object.keys(this.supportedAIsMap) as SupportedAIModels[];
        this.supportedAIs.sort((firstAI, secondAI) =>
          this.excludeAIsHelperService.compareSupportedAIs(this.supportedAIsMap[firstAI], this.supportedAIsMap[secondAI])
        );

        this.cdr.detectChanges();
      });
  }

  private translateSupportedAIName(aiName: SupportedAIModels, displayName: string, isRequired: boolean) {
    let modelDisplayName = this.translate.instant(displayName);

    // if the displayName is not translated, use the startCase function as a fallback.
    if (modelDisplayName === displayName) {
      modelDisplayName = startCase(aiName);
    }

    return isRequired ? this.translate.instant('RequiredFormat', { value: modelDisplayName }) : modelDisplayName;
  }

  private resetExcludeAIs() {
    this.excludeRAI = false;
    this.excludeRAIChange.emit(this.excludeRAI);

    this.excludeAIs = [];
    this.excludeAIsChange.emit(this.excludeAIs);
  }

  private get isAutoDetectLanguage() {
    return this.selectedLanguage === SpecialLanguageKey.AUTO || this.selectedLanguage === SpecialLanguageKey.MULTI;
  }

  private toggleSensitiveAIsOnError(excludeRAI: boolean) {
    if (excludeRAI) {
      // in case of an error loading supported AIs, we can't calculate the sensitive AIs so we exclude just the root sensitive models
      this.excludeAIs = this.excludeAIsHelperService.ROOT_SENSITIVE_MODELS;
    } else {
      this.excludeAIs = [];
    }

    this.excludeAIsChange.emit(this.excludeAIs);
  }

  private getSensitiveAIsTooltip(modelName: ExcludableAIsModels) {
    if (this.excludeAIsHelperService.isSensitiveAI(modelName)) {
      return {
        show: (ai: ExcludableAIsModels) => this.shouldShowRAIExcludedTooltip(ai),
        text: this.resources.IndexingPresetExcludeSensitiveModelsTooltip
      };
    }
  }
}
