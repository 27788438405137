import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';

export const selectEdgeSupportedLanguagesState = createSelector(selectCoreState, state => state.edgeSupportedLanguages);

export const selectEdgeSupportedLanguages = createSelector(selectEdgeSupportedLanguagesState, state => Object.values(state.entities));

export const selectEdgeSupportedLanguagesError = createSelector(selectEdgeSupportedLanguagesState, state => state.error);

export const selectEdgeSupportedLanguagesLoaded = createSelector(selectEdgeSupportedLanguagesState, state => state.loaded);
