import { createAction, props } from '@ngrx/store';

import { IFileBlob } from '../../indexing/interfaces';
import { IUploadTrainingDataFile } from '../../customization/components/language/model-file/interfaces';

import LanguageModel = Microsoft.VideoIndexer.Contracts.LanguageModel;
import AddLanguageModel = Microsoft.VideoIndexer.Contracts.AddLanguageModel;
import TrainingDataFile = Microsoft.VideoIndexer.Contracts.TrainingDataFile;
import TrainingDataFromEdits = Microsoft.VideoIndexer.Contracts.TrainingDataFromEdits;

export const loadLanguageModels = createAction('[LanguageModel/API] Load Language Models');

export const failLoadLanguageModels = createAction('[LanguageModel] Fail Load Language Models');

export const upsertLanguageModel = createAction('[LanguageModel] Upsert Language Model', props<{ model: LanguageModel }>());

export const upsertLanguageModels = createAction('[LanguageModel] Upsert Language Models', props<{ models: LanguageModel[] }>());

export const deleteLanguageModel = createAction('[LanguageModel/API] Delete Language Model', props<{ model: LanguageModel }>());

export const addLanguageModel = createAction('[LanguageModel/API] Add Language Model', props<{ model: AddLanguageModel }>());

export const updateLanguageModel = createAction(
  '[LanguageModel/API] Update Language Model',
  props<{ model: AddLanguageModel; modelId: string; oldModel: LanguageModel }>()
);

export const removeUpdatingFile = createAction('[LanguageModel] Remove Update Language Model', props<{ modelId: string; id: number }>());

export const uploadLanguageModelFile = createAction('[LanguageModel/API] Upload Language Model File', props<{ file: IFileBlob; modelId: string }>());

export const cancelLanguageModelFile = createAction('[LanguageModel/API] Cancel Language Model File', props<{ file: IFileBlob; modelId: string }>());

export const uploadFileToServer = createAction(
  '[LanguageModel/API] Upload File To Server',
  props<{ file: IFileBlob; modelId: string; fileId: number }>()
);

export const updateUploadProgress = createAction(
  '[LanguageModel] Update Upload Progress',
  props<{ file: IUploadTrainingDataFile; modelId: string }>()
);

export const updateLanguageModelFileContent = createAction(
  '[LanguageModel] Update Language Model File Content',
  props<{ file: TrainingDataFile; modelId: string }>()
);

export const loadLanguageModelFileContent = createAction(
  '[LanguageModel/API] Load Language Model File Content',
  props<{ fileId: string; modelId: string }>()
);

export const updateLanguageModelCrisEditsContent = createAction(
  '[LanguageModel] Update Language Model CrisEdits Content',
  props<{ crisEdits: TrainingDataFromEdits[]; modelId: string }>()
);

export const loadLanguageModelCrisEditsContent = createAction(
  '[LanguageModel/API] Load Language Model Cris Edits Content',
  props<{ modelId: string }>()
);

export const downloadFile = createAction('[LanguageModel/API] Download File', props<{ fileId: string; fileName: string; modelId: string }>());

export const downloadFromEditFile = createAction(
  '[LanguageModel] Download From Edit File',
  props<{ fileId: string; fileName: string; modelId: string }>()
);

export const deleteFile = createAction('[LanguageModel/API] Delete File', props<{ fileId: string; modelId: string }>());

export const deleteLanguageModelFile = createAction('[LanguageModel] Delete Language Model File', props<{ fileId: string; modelId: string }>());

export const toggleLanguageModels = createAction('[LanguageModel] Toggle Language Model', props<{ modelId: string }>());

export const clearLanguageModels = createAction('[LanguageModel] Clear Language Models');

export const pullTrainingModel = createAction('[LanguageModel/API] Pull Train Language Model', props<{ model: LanguageModel }>());

export const trainModel = createAction('[LanguageModel/API] Train Language Model', props<{ model: LanguageModel }>());
