import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CustomMissingTranslationHandler } from './services/missing-translate-handler.service';
import { environment } from '../../../environments/environment';
import { UrlService } from '../core/services/url/url.service';
import { LoggerService } from '../core/services/logger/logger.service';

export function createTranslateLoader(http: HttpClient) {
  const base = `${window.viewContext?.codeLocation ? window.viewContext.codeLocation : ''}/${
    environment?.localhost ? '' : `${window.viewContext.clientVersion}/`
  }`;
  return new TranslateHttpLoader(http, base + 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    })
  ],
  exports: [TranslateModule],
  providers: [UrlService],
  declarations: []
})
export class TranslationModule {
  constructor(private logger: LoggerService) {
    this.logger.log('[TranslationModule] init');
  }
}
