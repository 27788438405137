import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { INotification } from '@common/modules/core/services/toast/interfaces';

// Notifications common
export const initNotifications = createAction('[Notifications/API] Init Notifications');
export const loadNotifications = createAction('[Notifications/API] Load Notifications');
export const reloadNotifications = createAction('[Notifications] Reload Notifications');
export const removeNotifications = createAction('[Notifications] Remove Notifications', props<{ ids: string[] }>());

// Banners
export const clearNotificationsBanners = createAction('[Notifications] Clear Notifications Banners');
export const updateNotificationsBanners = createAction(
  '[Notifications] Update Notifications Banners',
  props<{ notifications: Update<INotification>[] }>()
);
export const addNotificationsBanners = createAction('[Notifications] Add Notifications Banners', props<{ notifications: INotification[] }>());
export const removeNotificationsBanners = createAction('[Notifications] Remove Notifications Banners', props<{ ids: string[] }>());

// Alerts
export const clearNotificationsAlerts = createAction('[Notifications] Clear Notifications Alerts');
export const updateNotificationsAlerts = createAction(
  '[Notifications] Update Notifications Alerts',
  props<{ notifications: Update<INotification>[] }>()
);
export const addNotificationsAlerts = createAction('[Notifications] Add Notifications Alerts', props<{ notifications: INotification[] }>());
export const removeNotificationsAlerts = createAction('[Notifications] Remove Notifications Alerts', props<{ ids: string[] }>());

// Messages
export const clearNotificationsMessages = createAction('[Notifications] Clear Notifications Messages');
export const updateNotificationsMessages = createAction(
  '[Notifications] Update Notifications Messages',
  props<{ notifications: Update<INotification>[] }>()
);
export const addNotificationMessages = createAction('[Notifications] Add Notifications Messages', props<{ notifications: INotification[] }>());
export const removeNotificationsMessages = createAction('[Notifications] Remove Notifications Messages', props<{ ids: string[] }>());
