import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject, takeUntil } from 'rxjs';

import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { resources } from './resources';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { RowValueColor } from '../indexing-summary/interfaces';
import { IndexingMode } from '../../../interfaces';

@Component({
  selector: 'vi-indexing-review-page',
  templateUrl: './indexing-review-page.component.html',
  styleUrls: ['./indexing-review-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingReviewPageComponent implements OnInit, OnDestroy {
  public filesNames: string = '';
  public resources = resources;
  public numOfFiles: number;
  public filesLabel = '';
  public summaryTitle = '';
  public isMultipleFileUpload: boolean;
  public showConsent: boolean = false;

  // styling
  public RowValueColor = RowValueColor;

  // user consent
  public userConsent: boolean;
  public isUSAccount$: Observable<boolean>;
  public isTrialAccount$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private coreStore: CoreStoreService,
    private trackService: TrackService
  ) {}

  public ngOnInit(): void {
    this.trackService.track('indexing_review_page.init', { category: EventCategory.INDEXING_REVIEW });
    this.translate.translateResourcesInstant(this.resources);
    this.isUSAccount$ = this.coreStore.isUSAccount$;
    this.isTrialAccount$ = this.coreStore.isTrialAccount$;
    this.indexingStore.userConsent$.pipe(takeUntil(this.destroy$)).subscribe(consent => {
      this.userConsent = consent;
    });
    this.handleFiles();
    this.summaryTitle = this.resources.IndexingReviewPageTitle;
    // show consent only when indexing mode is upload
    this.indexingStore.indexingMode$.pipe(takeUntil(this.destroy$)).subscribe(mode => {
      this.showConsent = mode === IndexingMode.Upload;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onConsentChanged(consent: boolean) {
    this.trackService.track('upload_dialog.confirm_terms.checked', { category: EventCategory.UPLOAD, data: { checked: consent } });
    this.indexingStore.updateUserConsent(consent);
  }

  private handleFiles() {
    this.indexingStore.allValidFilesNames$.pipe(takeUntil(this.destroy$)).subscribe(filesNames => {
      this.filesNames = filesNames?.join(', ') || '';
      this.numOfFiles = filesNames?.length;
      this.isMultipleFileUpload = this.numOfFiles > 1;
      this.filesLabel = this.isMultipleFileUpload
        ? [this.numOfFiles, this.resources.IndexingMultiFilesLabel].join(' ')
        : this.resources.IndexingSingleFileLabel;
    });
  }
}
