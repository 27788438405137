import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as InvitationsActions from '../actions/invitations.actions';

import InvitationContract = Microsoft.VideoIndexer.Contracts.InvitationContract;

export interface IState extends EntityState<InvitationContract> {
  loaded: boolean;
  error: boolean;
  saving: boolean;
  isUserNotAllowedError: boolean;
}

function selectInvitationId(a: InvitationContract): number {
  return a.invitationId;
}

export const adapter: EntityAdapter<InvitationContract> = createEntityAdapter<InvitationContract>({
  selectId: selectInvitationId
});

const initialState: IState = adapter.getInitialState({
  loaded: false,
  saving: false,
  error: false,
  isUserNotAllowedError: false
});

const invitationsReducer = createReducer(
  initialState,
  on(InvitationsActions.loadInvitations, state => {
    return {
      ...state,
      loaded: false,
      error: false
    };
  }),
  on(InvitationsActions.loadInvitationsSuccess, (state, { invitations }) => {
    const newState = {
      ...state,
      loaded: true
    };
    return adapter.setAll(invitations, newState);
  }),
  on(InvitationsActions.loadInvitationsFailed, (state, { isUserNotAllowedError }) => {
    return {
      ...state,
      loaded: true,
      error: true,
      isUserNotAllowedError: isUserNotAllowedError
    };
  }),
  on(InvitationsActions.inviteToAccountInProgress, state => {
    return {
      ...state,
      saving: true
    };
  }),
  on(InvitationsActions.inviteToAccountSuccess, (state, { invitations }) => {
    const newState = {
      ...state,
      saving: false
    };
    return adapter.upsertMany(invitations, newState);
  }),
  on(InvitationsActions.deleteInvitationSuccess, (state, { invitationId }) => {
    return adapter.removeOne(invitationId, state);
  }),
  on(InvitationsActions.invitationsError, (state, { isUserNotAllowedError }) => {
    return {
      ...state,
      error: true,
      isUserNotAllowedError: isUserNotAllowedError,
      saving: false
    };
  })
);

export function reducer(state: IState, action: Action) {
  return invitationsReducer(state, action);
}
