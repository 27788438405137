import { Injectable, Inject } from '@angular/core';

import { WINDOW } from '../../core/services/window.token';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService {
  constructor(@Inject(WINDOW) protected window) {
    super(window);
  }

  public get storage(): Storage {
    return this.window.localStorage;
  }
}
