import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as LogoActions from '../actions/logo-groups.actions';

import LogoGroup = Microsoft.VideoIndexer.Contracts.Customization.LogoGroupContract;

export interface IState extends EntityState<LogoGroup> {
  // additional entities state properties
  selectedAccountId: string;
  loaded: boolean;
  loading: boolean;
  error: boolean;
}

export function selectAccountId(a: LogoGroup): string {
  return a.id;
}

export const adapter: EntityAdapter<LogoGroup> = createEntityAdapter<LogoGroup>({
  selectId: selectAccountId
});

export const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedAccountId: null,
  loaded: false,
  loading: false,
  error: false
});

const logoGroupsReducer = createReducer(
  initialState,
  on(LogoActions.loadLogoGroupsSuccess, (state, { groups }) => {
    return adapter.upsertMany(groups, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(LogoActions.loadLogoGroupsInProgress, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(LogoActions.clearLogoGroups, (state, {}) => {
    return adapter.removeAll({
      ...state,
      selectedAccountId: null,
      loaded: false,
      loading: false,
      error: false
    });
  }),
  on(LogoActions.loadLogoGroupFailed, state => {
    return {
      ...state,
      error: true,
      loading: false
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return logoGroupsReducer(state, action);
}
