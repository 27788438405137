export enum GalleryTab {
  Library = 'Library',
  Project = 'Projects',
  Sample = 'Samples'
}

export enum VideoType {
  Library = 'Library',
  Project = 'Project',
  Sample = 'Sample',
  Search = 'Search'
}

export enum GalleryView {
  Grid = 'Grid',
  List = 'List',
  Search = 'Search'
}

export enum SearchParams {
  Query = 'query',
  Scope = 'scope',
  People = 'people',
  Face = 'face',
  Owner = 'owner',
  TextScope = 'textScope',
  Language = 'language'
}
