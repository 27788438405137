import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';
import { VIThemes } from '@common/modules/core/services/interfaces';

import { IVIThemeOption } from '../../interfaces';

export const languageFilterAction: IAction = {
  titleAttribute: '',
  type: UIActionType.LANGUAGE,
  value: UIActionType.LANGUAGE,
  selected: false,
  selectable: false,
  id: 'langFilter'
};

export const themeOptions: IVIThemeOption[] = [
  {
    themeName: VIThemes.DEFAULT,
    selected: false,
    svgPath: VIThemes.DEFAULT
  },
  {
    themeName: VIThemes.DARK,
    selected: false,
    svgPath: VIThemes.DARK
  },
  {
    themeName: VIThemes.LEGACY,
    selected: false,
    svgPath: VIThemes.LEGACY
  },
  {
    themeName: VIThemes.DARKLEGACY,
    selected: false,
    svgPath: VIThemes.DARKLEGACY
  }
];
