import { Directive, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { AriaLivePoliteness, LiveAnnouncer } from '@angular/cdk/a11y';

import { AriaLivePolitenessEnum } from '../editor/interfaces';
import { getKeyCode, KeyCode } from '../utils/event';
import { LoggerService } from '../core/services/logger/logger.service';

@Directive({
  selector: '[appAccessibilityAnnouncer]'
})
export class AnnouncerDirective implements OnInit, OnDestroy {
  @Input('appAccessibilityAnnouncer') public appAccessibilityAnnouncer: string;
  @Input() public listenedEvent: string;
  @Input() public announceText: string;

  private announceEventRef;
  private announceEnterEventRef;

  constructor(private liveAnnouncer: LiveAnnouncer, private el: ElementRef, private loggerService: LoggerService) {}

  public ngOnInit(): void {
    this.announceEventRef = this.announceEvent.bind(this);
    this.announceEnterEventRef = this.announceEnterEvent.bind(this);
    if (this.listenedEvent) {
      switch (this.listenedEvent) {
        case 'enter':
          this.el.nativeElement.addEventListener('keyup', this.announceEnterEventRef);
          break;
        default:
          this.el.nativeElement.addEventListener(this.listenedEvent, this.announceEventRef);
          break;
      }
    }

    if (this.appAccessibilityAnnouncer) {
      this.announce(this.appAccessibilityAnnouncer);
    }
  }

  public announceEnterEvent(e: KeyboardEvent) {
    try {
      const keyCode = getKeyCode(e);

      if (keyCode === KeyCode.Enter) {
        this.liveAnnouncer.announce(this.announceText);
      }
    } catch (error) {
      this.loggerService.log('Please add key to dictionary.', error);
    }
  }
  public announceEvent() {
    this.liveAnnouncer.announce(this.announceText);
  }

  public announce(text: string, politeness: AriaLivePoliteness = AriaLivePolitenessEnum.OFF) {
    this.liveAnnouncer.announce(text, politeness);
  }

  public ngOnDestroy(): void {
    if (this.listenedEvent) {
      this.el.nativeElement.removeEventListener(this.listenedEvent, this.announceEventRef);
      this.el.nativeElement.removeEventListener('keyup', this.announceEnterEventRef);
    }
  }
}
