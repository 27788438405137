@if (iconClass) {
<i class="{{iconClass}}"></i>
}
<a class="vi-link"
  class="size-{{size}}"
  [ngClass]="{'with-inline': inline, 'with-high-contrast': highContrast, 'accessible': accessibilityMode}"
  [target]="role !== 'link' ? '' : target"
  [attr.role]="role"
  [attr.aria-label]="text"
  [attr.title]="text"
  (viUiAccessibleClick)="linkClicked.emit(href)"
  [href]="href">
  {{ text }}</a>
