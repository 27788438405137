import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { UIInviteActionType } from '../../../../interfaces';

export const roleAssignOptionsMainAction: IAction = {
  value: 'roleAssignOptions',
  id: 'roleAssignOptions'
};

export const ownerAction: IAction = {
  title: '',
  key: 'Owner',
  value: UIInviteActionType.OWNER,
  type: UIInviteActionType.ROLE_ASSIGNMENT,
  id: 'owner'
};

export const contributorAction = {
  title: '',
  key: 'InviteContributor',
  value: UIInviteActionType.CONTRIBUTOR,
  type: UIInviteActionType.ROLE_ASSIGNMENT,
  id: 'contributor'
};

export const roleAssignmentsActions: IAction[] = [
  ownerAction,
  contributorAction,
  {
    title: '',
    key: 'Remove',
    value: UIInviteActionType.REMOVE,
    type: UIInviteActionType.ROLE_ASSIGNMENT,
    id: 'contributor'
  }
];

export const invitationsOptionsMainAction: IAction = {
  title: '',
  key: 'InviteOptions',
  value: 'InviteOptions',
  id: 'InviteOptions',
  selectable: true,
  hasDrop: true
};

export const invitationsActions: IAction[] = [
  {
    title: '',
    key: 'InviteCopyInviteUrlLabel',
    value: UIActionType.COPY,
    type: UIInviteActionType.INVITATION,
    selectable: true,
    id: 'owner'
  },
  {
    title: '',
    key: 'Remove',
    value: UIInviteActionType.REMOVE,
    type: UIInviteActionType.INVITATION,
    selectable: true,
    id: 'contributor'
  }
];
