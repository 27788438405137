export const resources = {
  BrandAddBrandAction: '',
  KeywordAddKeyword: '',
  LabelAddLabel: '',
  ShowMore: '',
  KeywordRemoveTitle: '',
  KeywordsEnterKeyword: '',
  LabelsEnterLabel: '',
  BrandsEnterBrand: ''
};
