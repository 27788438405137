import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';

import { IState } from '../../core/reducers';
import * as fromCore from '../../core/selectors';
import * as PersonActions from '../actions/person-model.actions';
import * as fromCustomizationData from '../selectors';

@Injectable()
export class PersonModelEffects {
  public loadPersonModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PersonActions.loadPersonModels),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCustomizationData.isPeopleModelsLoaded)),
      switchMap(([[{ force }, accountId], isLoaded]) => {
        // Return empty in case the models already loaded in the store.
        if (isLoaded && !force) {
          return EMPTY;
        }

        this.store.dispatch(PersonActions.loadPersonModelsInProgress());

        return this.apiService.Account.Customization.PeopleModels.getList(accountId).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.PersonModel[]) => {
            return [PersonActions.upsertPersonModels({ models: result })];
          }),
          catchError((error: HttpErrorResponse) => {
            return [PersonActions.failLoadPersonModels()];
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService, private store: Store<IState>) {}
}
