import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ElementRef,
  HostBinding,
  HostListener,
  AfterViewInit
} from '@angular/core';

import { resources } from './resources';
import { UtilsService } from '../../services/utils.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { Direction } from '../../interfaces';

@Component({
  selector: 'app-vi-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification-tooltip.component.scss']
})
export class NotificationTooltipComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public notificationText = '';
  @Input() public duration = 3000;
  @Input() public hasExitButton = false;
  @Input() public addOverlay = true;
  @Input() public hasCheckbox = false;
  @Input() public checkboxText = '';
  @Input() public checkboxTextId: string;
  @Input() public addAnimation = true;
  // when stretch true the tooltip width wil be according to the content
  @Input() public stretch = false;
  @Input() public direction = Direction.RIGHT;
  // Pass target element to locate the tooltip when using left direction/relocate right direction/center arrow
  @Input() public targetElement: ElementRef;
  // center the tooltip arrow to the center of the target element
  @Input() public centerArrow = false;
  @Input() public tooltipClass = '';
  @Input() public showArrow = true;
  @Input() public showNotificationDown = false;
  @Input() public ignoreApplyDirection = false;

  @Output() public notificationDismiss = new EventEmitter<void>();
  @Output() public selectCheckboxOutput = new EventEmitter<boolean>();
  @Output() public escPressed = new EventEmitter<boolean>();

  @HostBinding('style.left') public leftStyle: string;
  @HostBinding('style.top') public topStyle: string;
  @HostBinding('style.right') public rightStyle: string;
  @HostBinding('class.is-visible') public isVisible: boolean;

  public startAnimation = false;
  public checkboxSelected = false;
  public resources = resources;

  private dismissTimeout;
  private animationTimeout;
  private animationDuration = 500;

  constructor(private cdr: ChangeDetectorRef, private translate: TranslateHelperService, private el: ElementRef, private utils: UtilsService) {}

  public ngOnInit() {
    // Translate resources
    this.translate.translateResources(this.resources);
  }

  @HostListener('document:keydown.escape', ['$event']) public onKeyupHandler(event: KeyboardEvent) {
    this.escPressed.emit();
  }

  public ngAfterViewInit(): void {
    // Set tooltip direction must be in AfterViewInit so view will be loaded first
    this.applyDirection().then(() => {
      this.cdr.markForCheck();
      this.isVisible = true;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.notificationText) {
      // Text has been changed, apply timeout again
      clearTimeout(this.animationTimeout);
      clearTimeout(this.dismissTimeout);

      this.isVisible = false;
      this.initNotification();
    }
  }

  public applyDirection(): Promise<void> {
    return this.utils.getContainerRectAsync(this.el.nativeElement).then(tooltipElement => {
      this.utils.getBodyRectAsync().then(bodyRect => {
        // apply direction if needed
        switch (this.direction) {
          case Direction.RIGHT:
            this.locateRightDirectionTooltip(tooltipElement, bodyRect);
            break;
          case Direction.LEFT:
            this.locateLeftDirectionTooltip(tooltipElement, bodyRect);
            break;
        }

        // when tooltip over body element bottom, show tooltip above the target element
        if (!this.ignoreApplyDirection && tooltipElement?.bottom > bodyRect?.bottom && !this.showNotificationDown) {
          this.topStyle = `-${tooltipElement?.height}px`;
        }
      });
    });
  }

  public dismiss(): void {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }

    this.startAnimation = false;
    this.cdr.markForCheck();

    this.notificationText = '';
    this.notificationDismiss.emit();
  }

  public selectCheckbox() {
    this.selectCheckboxOutput.emit(this.checkboxSelected);
  }

  public get checkboxTextElementId() {
    return `notification${this.checkboxTextId}`;
  }

  private initNotification() {
    // Show popup according to duration input
    if (this.addAnimation) {
      this.animationTimeout = setTimeout(() => {
        this.startAnimation = true;
        this.cdr.markForCheck();

        this.dismissTimeout = setTimeout(() => {
          this.dismiss();
        }, this.animationDuration);
      }, this.duration);
    }
  }

  private locateRightDirectionTooltip(tooltipRect: DOMRect, bodyRect: DOMRect) {
    let leftLocation = 0;
    if (this.centerArrow) {
      leftLocation = this.applyCenterArrow(0, Direction.RIGHT);
    }
    // check if relocate (change direction) is needed
    if (tooltipRect.right + leftLocation > bodyRect.right) {
      this.changeDirection(Direction.LEFT, tooltipRect);
    }
  }

  private changeDirection(newDirection: Direction, tooltipRect: DOMRect) {
    this.direction = newDirection;
    let leftLocation = 0;
    switch (newDirection) {
      case Direction.LEFT:
        leftLocation = this.applyLeftDirection(tooltipRect);
        break;
      case Direction.RIGHT:
        this.leftStyle = '0px';
        break;
    }

    if (this.centerArrow) {
      this.applyCenterArrow(leftLocation, newDirection);
    }

    this.cdr.detectChanges();
  }

  private locateLeftDirectionTooltip(tooltipRect: DOMRect, bodyRect: DOMRect) {
    let leftLocation = this.applyLeftDirection(tooltipRect);

    if (this.centerArrow) {
      leftLocation = this.applyCenterArrow(leftLocation, Direction.LEFT);
    }

    const tooltipLeft = tooltipRect.left + leftLocation;
    // check if relocate (change direction) is needed
    if (tooltipLeft < bodyRect.left) {
      this.changeDirection(Direction.RIGHT, tooltipRect);
    }
  }

  private applyLeftDirection(tooltip: DOMRect): number {
    if (this.direction === Direction.LEFT && this.targetElement?.nativeElement) {
      const tooltipTargetGap = Math.abs(tooltip.width - this.targetElement.nativeElement.offsetWidth);
      this.leftStyle = `-${tooltipTargetGap}px`;
      return -tooltipTargetGap;
    }
    return 0;
  }

  private applyCenterArrow(leftLocation: number, direction: Direction) {
    // can't center arrow to target when there is no target
    if (!this.targetElement?.nativeElement) {
      return;
    }
    const arrowPadding = 12;
    const arrowWidth = 20;
    const arrowCenter = arrowWidth / 2;
    const targetCenter = this.targetElement.nativeElement.offsetWidth / 2;
    // direction to move tooltip is according to the tooltip direction
    const shiftDirection = direction === Direction.RIGHT ? -1 : 1;
    leftLocation += shiftDirection * (arrowPadding + arrowCenter - targetCenter);
    this.leftStyle = `${leftLocation}px`;
    return leftLocation;
  }
}
