import { cloneDeep } from 'lodash-es';
import { ApplicationInsights, IExtendedTelemetryItem, IExceptionTelemetry, IPageViewTelemetry } from '@microsoft/1ds-analytics-web-js';

import { defaultParams } from './config';
import { merge } from '../../../../utils/object';
import { EventType, IOneDSClientConfiguration } from './interfaces';

export { EventType };

export class OneDSClient {
  public config: IOneDSClientConfiguration;
  private client: ApplicationInsights;
  private commonData: Object;

  constructor(params: IOneDSClientConfiguration) {
    this.initialize(params);
  }

  public trackEvent(event: IExtendedTelemetryItem, properties?: object) {
    this.client.trackEvent({
      ...event,
      data: merge(this.commonData, event.data, properties)
    });
  }

  public trackException(event: IExceptionTelemetry, properties?: object) {
    const exception = {
      message: event?.exception?.message,
      stack: (<Error>event?.exception)?.stack,
      severityLevel: event.severityLevel || 1
    };

    this.trackEvent({ name: EventType.Exception }, merge(event.properties, properties, exception));
  }

  public trackPageView(event: IPageViewTelemetry, properties?: object) {
    this.client.trackPageView({
      ...event,
      properties: merge(this.commonData, event.properties, properties)
    });
  }

  private initialize(params: IOneDSClientConfiguration) {
    this.client = new ApplicationInsights();
    this.config = merge(defaultParams, { ...params });
    // deep clone common data object so we won't accidentally mutate constructor arguments
    this.commonData = cloneDeep(this.config.commonData);

    const { onEventSent, onEventDiscarded, telemetryInitializers, commonData, ...extendedConfiguration } = this.config;

    telemetryInitializers.forEach(telemetryInitializer => this.client.addTelemetryInitializer(telemetryInitializer));

    this.client.initialize(extendedConfiguration, []);

    const propertyManager = this.client.getPropertyManager();
    if (propertyManager) {
      const propertiesContext = propertyManager.getPropertiesContext();
      if (propertiesContext) {
        propertiesContext.app.id = params.appId;
      }
    }

    this.client.addNotificationListener({
      eventsSent: onEventSent,
      eventsDiscarded: onEventDiscarded
    });
  }
}
