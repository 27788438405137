export enum UIIndexingActionType {
  INDEX = 'INDEX',

  PRESET_VIDEO_AUDIO_BASIC = 'PRESET_VIDEO_AUDIO_BASIC',
  PRESET_VIDEO_AUDIO_STANDARD = 'PRESET_VIDEO_AUDIO_STANDARD',
  PRESET_VIDEO_AUDIO_ADVANCED = 'PRESET_VIDEO_AUDIO_ADVANCED',

  PRESET_VIDEO_BASIC = 'PRESET_VIDEO_BASIC',
  PRESET_VIDEO_STANDARD = 'PRESET_VIDEO_STANDARD',
  PRESET_VIDEO_ADVANCED = 'PRESET_VIDEO_ADVANCED',

  PRESET_AUDIO_BASIC = 'PRESET_AUDIO_BASIC',
  PRESET_AUDIO_STANDARD = 'PRESET_AUDIO_STANDARD',
  PRESET_AUDIO_ADVANCED = 'PRESET_AUDIO_ADVANCED',

  INDEX_DEFAULT = 'INDEX_DEFAULT',
  INDEX_AUDIO_ONLY = 'INDEX_AUDIO_ONLY',
  INDEX_ADVANCED_AUDIO = 'INDEX_ADVANCED_AUDIO',
  INDEX_ADVANCED_VIDEO = 'INDEX_ADVANCED_VIDEO',
  INDEX_ADVANCED = 'INDEX_ADVANCED',
  INDEX_BASIC_AUDIO = 'INDEX_BASIC_AUDIO',
  INDEX_VIDEO_ONLY = 'INDEX_VIDEO_ONLY',
  STREAM = 'STREAM',
  STREAM_SINGLE = 'STREAM_SINGLE',
  STREAM_ADAPTIVE = 'STREAM_ADAPTIVE',
  STREAM_NO_STREAM = 'STREAM_NO_STREAM',
  CUSTOMIZATION_MODEL = 'CUSTOMIZATION_MODEL',
  CUSTOMIZATION_PEOPLE_MODEL = 'CUSTOMIZATION_PEOPLE_MODEL',
  CUSTOMIZATION_LANGUAGE_MODEL = 'CUSTOMIZATION_LANGUAGE_MODEL',
  BRAND_MODEL = 'BRAND_MODEL',
  LOGO_GROUP = 'LOGO_GROUP'
}
