import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const cancelAction: IAction = {
  title: 'Cancel',
  key: 'Cancel',
  selected: false,
  type: UIActionType.CANCEL,
  value: 'Cancel',
  selectable: true,
  hasDrop: false,
  isDisabled: false,
  id: 'Cancel'
};

export const deleteAccountAction: IAction = {
  title: '',
  key: 'SettingsDeleteAccount',
  selected: false,
  type: UIActionType.DELETE,
  value: 'Delete account',
  selectable: true,
  hasDrop: false,
  isDisabled: true,
  id: 'deleteBtn'
};
