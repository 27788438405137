import { AfterViewChecked, ComponentRef, Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';

import { from } from 'rxjs';

import { DynamicTooltipComponent } from '../components/dynamic-tooltip/dynamic-tooltip.component';
import { UtilsService } from '../services/utils.service';

@Directive({
  selector: '[viDynamicTooltip]'
})
export class DynamicTooltipDirective implements OnChanges, AfterViewChecked {
  @Input() public showTooltip = false;
  @Input() public message: string;
  @Input() public showTooltipDown = false;

  private elementRect: DOMRect;
  private dynamicTooltipCompRef: ComponentRef<DynamicTooltipComponent>;

  constructor(private viewContainerRef: ViewContainerRef, private utils: UtilsService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.showTooltip) {
      return;
    }
    if (changes.showTooltip.currentValue) {
      this.createTooltipComponent();
    } else {
      this.removeTooltipComponent();
    }
  }

  public ngAfterViewChecked(): void {
    from(this.utils.getContainerRectAsync(this.viewContainerRef.element.nativeElement))
      .pipe()
      .subscribe(rect => {
        this.elementRect = rect;
      });
  }

  private createTooltipComponent() {
    this.dynamicTooltipCompRef = this.viewContainerRef.createComponent(DynamicTooltipComponent);
    this.dynamicTooltipCompRef.instance.message = this.message;
    this.dynamicTooltipCompRef.instance.showTooltipDown = this.showTooltipDown;
    document.addEventListener('mousemove', this.moveMouseHandler);
  }

  private removeTooltipComponent() {
    this.viewContainerRef.remove();
    document.removeEventListener('mousemove', this.moveMouseHandler);
  }

  private moveMouseHandler = mouseEvent => {
    const mouseOffset = 32;
    const tooltipWidth = 166;
    // tooltip transform is relative to element and mouseEvent is relative to document.
    // So set top/let according elementRect.top/left
    const tooltipTop = mouseEvent.clientY - this.elementRect.top - mouseOffset;
    let tooltipLeft = mouseEvent.clientX - this.elementRect.left - mouseOffset;
    if (tooltipLeft < 0) {
      tooltipLeft = 0;
    }
    if (this.elementRect.right < tooltipWidth + mouseEvent.clientX - mouseOffset) {
      tooltipLeft = this.elementRect.right - this.elementRect.left - tooltipWidth;
    }
    this.dynamicTooltipCompRef.instance.tooltipTransform = `translateY(${tooltipTop}px) translateX(${tooltipLeft}px)`;
    this.dynamicTooltipCompRef.instance.cdr.detectChanges();
  };
}
