import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { IFile } from '../../../../interfaces';
import { IndexingStoreService } from '../../../../services/indexing-store.service';
import { resources } from './resources';

@Component({
  selector: 'vi-indexing-file-names',
  templateUrl: './file-names.component.html',
  styleUrls: ['./file-names.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileNamesComponent implements OnInit, OnDestroy {
  @Output() public addFilesClicked = new EventEmitter<void>();

  public files: IFile[];
  public resources = resources;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private storeService: IndexingStoreService,
    private trackService: TrackService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.translate.translateResourcesInstant(resources);
    this.storeService.allFiles$.pipe(takeUntil(this.destroy$)).subscribe(files => {
      this.files = [...files];
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public fileId(_: number, file: IFile) {
    return file.id;
  }

  public onFileNameChanged(file: IFile) {
    this.storeService.updateFile(file.id, { name: file.name });
    this.trackService.track('upload_dialog.filename.changed', {
      category: EventCategory.UPLOAD
    });
  }

  public onAddFilesClicked() {
    this.addFilesClicked.emit();
  }
}
