import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { NavigationState } from '@common/modules/core/services/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { SidebarPages } from '../../interfaces';
import { closeAction } from './actions';
import { sidebarContent, sidebarPage } from './animations';
import { resources } from './resources';

@Component({
  selector: 'app-vi-sidebar',
  templateUrl: './sidebar-container.component.html',
  styleUrls: ['./sidebar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sidebarContent, sidebarPage]
})
export class SidebarContainerComponent extends FocusableComponent implements OnInit, OnDestroy {
  // Outputs
  @Output() public openAbout = new EventEmitter();

  public SidebarPages = SidebarPages;
  public NavigationState = NavigationState;
  public content: SidebarPages;
  public navigationState: NavigationState;
  public contentInAnimation = false;
  public resources = resources;
  public closeAction: IAction = closeAction;
  private readonly ANIMATION_DELAY = 180;
  private callbackAfterClose;
  private prevNavigationState: NavigationState;
  private destroy$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private focusManager: FocusManagerService,
    private coreStore: CoreStoreService,
    private trackService: TrackService
  ) {
    super();
  }

  public ngOnInit() {
    this.trackService.track('sidebar_container.init');
    this.setTranslations();

    this.coreStore.sidebarStateStatus$.pipe(takeUntil(this.destroy$)).subscribe(newNavigation => {
      this.prevNavigationState = this.navigationState;
      this.navigationState = newNavigation;
      if (this.navigationState === NavigationState.OPEN) {
        this.setFocus();
        this.setAnimation();
      }
      this.cdr.detectChanges();
    });

    this.coreStore.sidebarPage$.pipe().subscribe((page: SidebarPages) => {
      if (this.content !== page) {
        this.setFocus();
        this.setAnimation();
      }
      this.content = page;
      this.cdr.detectChanges();
    });

    this.coreStore.sidebarCallbackAfterClose$.pipe().subscribe(callbackAfterClose => {
      this.callbackAfterClose = callbackAfterClose;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    if (this.callbackAfterClose) {
      this.callbackAfterClose();
    }
    this.cdr.detectChanges();
    this.coreStore.clearSidebar();

    this.trackService.track('sidebar_container.close', {
      category: EventCategory.SIDE_BAR
    });
  }

  public handleOpenAbout() {
    this.openAbout.emit();
    this.trackService.track('sidebar_container.open_about.click', {
      category: EventCategory.SIDE_BAR
    });
  }

  private setFocus() {
    // focus first element on sidebar (close button).
    // add timing only if pageInOut(out => in) animation plays.
    const timing = this.navigationState === this.prevNavigationState ? 0 : this.ANIMATION_DELAY;
    setTimeout(() => {
      this.focusManager.focusByQuery('#viSidebarClose .action-button', this.cdr);
    }, timing);
  }

  private setAnimation() {
    this.contentInAnimation = false;
    this.cdr.detectChanges();
    this.contentInAnimation = true;
  }

  private setTranslations() {
    this.translate.translateResources(this.resources).subscribe(() => {
      this.closeAction.titleAttribute = this.resources[this.closeAction.titleAttribute] || '';
      this.cdr.detectChanges();
    });
  }
}
