import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Errors } from '@common/modules/core/services/toast/errors';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { SpeechErrorTexts } from '../../reducers/speech/interfaces';
import { resources } from './resources';

@Injectable({
  providedIn: 'root'
})
export class SpeechErrorsService {
  private resources = resources;
  constructor(private translate: TranslateHelperService) {
    this.translate.translateResourcesInstant(this.resources);
  }

  public getSpeechDatasetDeleteErrorTexts(err: HttpErrorResponse, datasetName: string): SpeechErrorTexts {
    const errorTexts = {
      title: '',
      content: ''
    };
    if (err?.error?.ErrorType === Errors.DATASET_IN_USE.key) {
      errorTexts.title = this.translate.instant('SpeechInUseFailedDeleteDatasetTitle', { datasetName: datasetName });
      errorTexts.content = this.resources.SpeechInUseFailedDeleteDatasetText;
    } else {
      errorTexts.title = this.translate.instant('SpeechGeneralFailedDeleteDatasetTitle', { datasetName: datasetName });
      errorTexts.content = this.resources.SpeechGeneralFailedDeleteDatasetText;
    }
    return errorTexts;
  }

  public getSpeechModelDeleteErrorTexts(err: HttpErrorResponse, modelId: string, modelName: string): SpeechErrorTexts {
    const errorTexts = {
      title: '',
      content: ''
    };
    if (err?.error?.ErrorType === Errors.MODEL_IN_USE.key) {
      errorTexts.title = this.resources.SpeechOnIndexingFailedDeleteModelTitle;
      errorTexts.content = this.translate.instant('SpeechOnIndexingFailedDeleteModelText', { modelId: modelId });
    } else {
      errorTexts.title = this.translate.instant('SpeechGeneralFailedDeleteModelTitle', { modelName: modelName });
      errorTexts.content = this.resources.SpeechGeneralFailedDeleteModelText;
    }
    return errorTexts;
  }
}
