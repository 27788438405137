export enum CheckboxSize {
  Small = 'small',
  Large = 'large'
}

export enum LabelSize {
  Small = '12-16',
  Medium = '13-18',
  Large = '14-20'
}
