import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const closeAction: IAction = {
  icon: 'i-vi-close-big',
  title: '',
  key: 'Close',
  selected: false,
  type: UIActionType.CLOSE,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'sideBarClose',
  titleAttribute: 'SideBarCloseLabel'
};
