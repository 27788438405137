export const resources = {
  InsightsErrorTypes_BREAKDOWN_NOT_FOUND: '',
  InsightsErrorTypes_VIDEO_REMOVED: '',
  InsightsErrorTypes_USER_NOT_ALLOWED: '',
  InsightsErrorTypes_EMPTY_INSIGHTS: '',
  InsightsErrorTypes_GENERAL: '',
  InsightsErrorTypes_USER_NOT_SIGNED_IN: '',
  ErrorTypes_GENERAL: '',
  ErrorTypes_INVALID_INPUT: '',
  ErrorTypes_USER_NOT_AUTHENTICATED: '',
  ErrorTypes_USER_NOT_SIGNED_IN: '',
  ErrorTypes_USER_NOT_SIGNED_IN_AAD: '',
  ErrorTypes_USER_NOT_REGISTERED: '',
  ErrorTypes_USER_NOT_ALLOWED: '',
  ErrorTypes_USER_NOT_ADMIN: '',
  ErrorTypes_USER_NOT_FOUND: '',
  ErrorTypes_USER_SUSPENDED: '',
  ErrorTypes_ACCESS_TOKEN_VALIDATION_FAILED: '',
  ErrorTypes_ACCESS_TOKEN_VALIDATION_FAILED_Embed: '',
  ErrorTypes_ACCESS_TOKEN_VALIDATION_FAILED_Player: '',
  ErrorTypes_CUSTOM_INDEX_LOCATION_LOADING_ERROR: '',
  ErrorTypes_ANTI_FORGERY_TOKEN_VALIDATION_FAILED: '',
  ErrorTypes_VIDEO_UPLOAD_LIMIT_EXCEEDED: '',
  ErrorTypes_ACCOUNT_UPLOAD_QUOTA_EXCEEDED: '',
  ErrorTypes_BREAKDOWN_NOT_FOUND: '',
  ErrorTypes_NOT_FOUND: '',
  ErrorTypes_URL_UNREACHABLE: '',
  ErrorTypes_OFFLINE: '',
  ErrorTypes_ALREADY_EXISTS: '',
  ErrorTypes_WIKI_ALREADY_EXISTS: '',
  FacesCustomizationError_DeleteModel_ACCOUNT_NOT_FOUND: '',
  FacesCustomizationError_DeleteModel_NOT_FOUND: '',
  FacesCustomizationError_DeleteModel_GENERAL: '',
  FacesCustomizationError_EditModel_ACCOUNT_NOT_FOUND: '',
  FacesCustomizationError_EditModel_NOT_FOUND: '',
  FacesCustomizationError_EditModel_ALREADY_EXISTS: '',
  FacesCustomizationError_EditModel_INVALID_INPUT: '',
  FacesCustomizationError_EditModel_GENERAL: '',
  FacesCustomizationError_AddModel_GENERAL: '',
  FacesCustomizationError_AddModel_INVALID_INPUT: '',
  FacesCustomizationError_AddModel_ALREADY_EXISTS: '',
  FacesCustomizationError_AddModel_ACCOUNT_NOT_FOUND: '',
  FacesCustomizationError_AddModel_ACCOUNT_REACHED_PERSON_MODELS_QUOTA: '',
  FacesCustomizationError_GetPeople_NOT_FOUND: '',
  FacesCustomizationError_GetPeople_INVALID_INPUT: '',
  FacesCustomizationError_EditPeople_NOT_FOUND: '',
  FacesCustomizationError_EditPeople_INVALID_INPUT: '',
  FacesCustomizationError_DeletePeople_NOT_FOUND: '',
  FacesCustomizationError_AddPeople_NOT_FOUND: '',
  FacesCustomizationError_AddPeople_INVALID_INPUT: '',
  FacesCustomizationError_EditPeople_ALREADY_EXISTS: '',
  FacesCustomizationError_GetModel_ACCESS_TOKEN_VALIDATION_FAILED: '',
  FacesCustomizationError_GetPeople_ACCESS_TOKEN_VALIDATION_FAILED: '',
  FacesCustomizationError_EditFace_INVALID_INPUT: '',
  FacesCustomizationError_EditFace_NOT_FOUND: '',
  FacesCustomizationError_GetFace_NOT_FOUND: '',
  FacesCustomizationError_AddFace_NOT_FOUND: '',
  FacesCustomizationError_AddFace_INVALID_INPUT: '',
  FacesCustomizationError_AddFace_INVALID_PICTURE_SIZE: '',
  FacesCustomizationError_AddFace_INVALID_PICTURE: '',
  FacesCustomizationError_DeleteFace_NOT_FOUND: '',
  EditorError_SaveProject_TOO_MANY_SOURCE_VIDEOS: ''
};
