import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { orderBy } from 'lodash-es';

import { ApiService } from '@common/modules/api/services/api.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import * as LanguageIdActions from '../actions/language-id.actions';
import * as SupportedLanguageIDActions from '../actions/supported-languages-ids.actions';
import { isSupportedLanguageDialect } from '../utils';

@Injectable()
export class SupportedLanguagesIdsEffects {
  public loadSupportedLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportedLanguageIDActions.loadSupportedLanguages),
      switchMap(() => {
        const isSupportedLanguagesFeatureEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.SupportedLanguages);
        const isAutoDetectFeatureEnable = this.featureSwitchService.featureSwitch(FeatureSwitch.AutoDetectLanguage);
        if (!isSupportedLanguagesFeatureEnabled) {
          return EMPTY;
        }
        return this.apiService.SupportedLanguages.getSupportedLanguages().pipe(
          switchMap((supportedLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]) => {
            this.logger.log('[SupportedLanguagesIdsEffects] load supported languages');
            this.trackService.track('supportedLanguages.load.success', {
              category: EventCategory.SUPPORTED_LANGUAGES
            });
            supportedLanguages.forEach(l => {
              l.name = this.translateService.getLocaleLanguage(l.languageCode);
              l.isDialect = isSupportedLanguageDialect(
                supportedLanguages.map(l => l.languageCode),
                l.languageCode
              );
            });
            supportedLanguages = orderBy(supportedLanguages, language => language.name);
            return isAutoDetectFeatureEnable
              ? [
                  SupportedLanguageIDActions.upsertSupportedLanguages({ supportedLanguages }),
                  LanguageIdActions.loadSelectedLanguages(),
                  LanguageIdActions.loadDefaultLanguages()
                ]
              : [SupportedLanguageIDActions.upsertSupportedLanguages({ supportedLanguages })];
          }),
          catchError(error => {
            this.logger.error('[SupportedLanguagesIdsEffects]', error);
            this.trackService.track('supportedLanguages.load.failed', {
              category: EventCategory.SUPPORTED_LANGUAGES,
              error: JSON.stringify(error)
            });
            return [SupportedLanguageIDActions.loadSupportedLanguageFailed()];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private logger: LoggerService,
    private featureSwitchService: FeatureSwitchService,
    private trackService: TrackService,
    private translateService: TranslateHelperService
  ) {}
}
