export const resources = {
  GalleryItemPlayVideoTitle: '',
  GalleryItemEditVideoTitle: '',
  GalleryUploadedBy: '',
  GalleryDeleteVideoDialogTitle: '',
  IndexedBy: '',
  GalleryItemLastModified: '',
  Reindex: '',
  IndexingSettings: '',
  IndexingSettingsModalHeader: '',
  Editor: '',
  Delete: '',
  Close: '',
  Play: '',
  Tiles: '',
  List: '',
  VideoOptions: '',
  ReIndexDialogTitle: '',
  GalleryDeleteVideoSuccess: '',
  GalleryDeleteVideoSuccessTitle: '',
  GalleryItemFlaggedRequestedReviewApproval: '',
  GalleryItemFlaggedRequestedReview: '',
  GalleryItemFlaggedOffensiveContent: '',
  GalleryListItemMediaThumbnail: '',
  VideoUnavailable: '',
  GallerySearchExpandVideoItem: '',
  CopyFileId: '',
  CopyFileIDSuccess: '',
  OpenInNewTab: '',
  GalleryDeleteVideoErrorTitle: '',
  GalleryDeleteVideoErrorText: '',
  GalleryVideoCurrentlyBeingIndexedErrorTitle: '',
  GalleryVideoCurrentlyBeingIndexedErrorText: '',
  CouldntLoadMaxConfidence: ''
};
