import { Injectable } from '@angular/core';

import { IUIEntityInsight } from '@common/modules/insights/interfaces';

import { IStyle } from '../../interfaces';

@Injectable()
export class InsightsImagesService {
  constructor() {}

  public getSpritePosition(i: number, imageSize: number, spriteSize: number) {
    return (i % spriteSize) * imageSize;
  }

  public getThumbStyle(person: IUIEntityInsight, positionSize): IStyle {
    // Return the x position of the image based on the sprite image index
    return {
      backgroundImage: `url(${person.spriteUrl})`,
      backgroundPosition: `-${positionSize}px 0`
    };
  }
}
