import { Sort } from '@angular/material/sort';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

import { FocusableComponent } from '../focusable';
import { ISelectionState, ITableColumnConfig, ITableSort, ITableStyleType } from './interfaces';

@Component({
  selector: 'vi-ui-table',
  templateUrl: `./table.component.html`,
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> extends FocusableComponent implements OnInit, AfterViewInit {
  @ViewChildren('componentCell', { read: ViewContainerRef }) containers: QueryList<ViewContainerRef>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public items: T[];
  @Input() public columnsConfig: ITableColumnConfig[];

  @Input() public styleType = ITableStyleType.DEFAULT;
  @Input() public selectable = false;
  @Input() public allowMultiSelect = true;
  @Input() public initialSelection = [];

  @Output() public itemSelected = new EventEmitter<ISelectionState<T>>();
  @Output() public allItemSelected = new EventEmitter<boolean>();
  @Output() public sort = new EventEmitter<ITableSort>();

  public selection: SelectionModel<T>;
  public dataSource: T[];
  public ITableStyleType = ITableStyleType;
  public SELECT_COLUMN = 'select';
  public showHeaderSelection = false;

  constructor() {
    super();
  }

  public get displayedColumns() {
    const itemsColumns = this.columnsConfig.map(column => column.key);
    if (this.selectable) {
      return [this.SELECT_COLUMN, ...itemsColumns];
    }
    return itemsColumns;
  }

  public ngOnInit() {
    this.dataSource = this.items.slice();
    if (this.selectable) {
      this.selection = new SelectionModel<T>(this.allowMultiSelect, this.initialSelection);
    }
  }

  public ngAfterViewInit() {
    this.setCustomColumns();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.length;
    return numSelected == numRows;
  }

  public toggleAllRows() {
    const isAllSelected = this.isAllSelected();
    isAllSelected ? this.selection.clear() : this.items.forEach(item => this.selection.select(item));
    this.allItemSelected.emit(!isAllSelected);
  }

  public toggleRow(item: T) {
    this.selection.toggle(item);
    this.itemSelected.emit({ item, isSelected: this.selection.isSelected(item) });
  }

  public sortData(sort: Sort) {
    const column = this.columnsConfig[sort.active];
    if (!column.sortMethod) {
      this.sort.emit({ key: column.key, direction: sort.direction });
      return;
    }
    const data = this.items.slice();
    if (!column.sortable || !sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((item1, item2) => {
      const isAsc = sort.direction === 'asc';
      const comparisonResult = column.sortMethod(item1, item2);
      return comparisonResult * (isAsc ? 1 : -1);
    });
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public setItemHovered(row, isHovered: boolean) {
    row.isHovered = isHovered;
  }

  /**
   * Sets columns that show component in the cells.
   *
   * For each column that should show component, it creates an instance of the component and assigns it to the corresponding container.
   * It also sets the component's inputs based on the `componentInputs` configuration.
   */
  private setCustomColumns() {
    let index = 0;

    this.columnsConfig.forEach(column => {
      if (!column?.component) {
        return;
      }

      const componentClass = column.component;

      this.items.forEach(item => {
        const container = this.containers.toArray()[index++];
        const component = container.createComponent(componentClass);

        Object.entries(column.componentInputs).forEach(([key, factory]) => {
          component.instance[key] = factory(item);
        });
      });
    });
  }
}
