export enum UIHeaderActionType {
  SETTINGS = 'SETTINGS',
  SIGN_OUT = 'SIGN_OUT',
  HELP = 'HELP',
  MODEL_CUSTOMIZATION = 'MODEL_CUSTOMIZATION',
  INVITE = 'INVITE',
  UPLOAD = 'UPLOAD',
  CONNECT_TO_AZURE = 'CONNECT_TO_AZURE',
  HOME = 'HOME',
  ACCOUNTS = 'ACCOUNTS',
  MENU = 'MENU',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SWITCH_DIRECTORY = 'SWITCH_DIRECTORY'
}

export enum HeaderPreset {
  FULL = 'FULL',
  LEAN = 'LEAN'
}
