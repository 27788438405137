<ng-template #menuItemTemplateRef
  let-item="item"
  let-level="level">
  <li *ngIf="!item.hidden"
    class="menu-item level-{{level}}"
    [ngClass]="{'selected': selectedItemId === item.id}"
    [attr.disabled]="item.disable ? item.disable : null"
    [attr.aria-label]="item.text"
    tabindex="0"
    (viUiAccessibleClick)="onSelectItem(item)">
    <button [ngClass]="{'flat-nav-bar': isFlat, 'hidden': !item.children }"
      [attr.disabled]="item.disable ? item.disable : null"
      [attr.aria-expanded]="item.expanded"
      [attr.aria-label]="item.expandTitleAttribute"
      [attr.title]="item.expanded? item.collapseTitleAttribute: item.expandTitleAttribute"
      tabindex="-1">
      <i [ngClass]="{'i-vi-arrow-down': item.expanded,'i-vi-arrow-right': !item.expanded}">
      </i>
    </button>
    <span role="text"
      [attr.title]="item.text">
      {{item.text}}
    </span>
  </li>
  <li>
    <ul class="sub-menu-item"
      *ngIf="item.children && item.expanded">
      <ng-template ngFor
        let-child
        [ngForOf]="item.children">
        <ng-template [ngTemplateOutlet]="menuItemTemplateRef"
          [ngTemplateOutletContext]="{item : child, level: level + 1}">
        </ng-template>
      </ng-template>
    </ul>
  </li>
</ng-template>

<ul class="nav-bar">
  <ng-container *ngFor="let item of items">
    <ng-template [ngTemplateOutlet]="menuItemTemplateRef"
      [ngTemplateOutletContext]="{item: item, level: 0}">
    </ng-template>
  </ng-container>
</ul>