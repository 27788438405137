import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { switchMap, catchError } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import * as EdgeExtensionsActions from '../../core/actions/edge-extensions.actions';
import * as EdgeSupportedLanguagesActions from '../actions/edge-supported-languages.actions';
import { isSupportedLanguageDialect } from '../utils';

@Injectable()
export class EdgeSupportedLanguagesEffects {
  public loadEdgeSupportedLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeExtensionsActions.handshakeSuccess),
      switchMap(() => {
        return this.apiService.SupportedLanguages.getSupportedLanguages().pipe(
          switchMap((supportedLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]) => {
            this.trackService.track('edge.load_supported_languages.success', {
              category: EventCategory.EDGE
            });
            supportedLanguages.forEach(l => {
              l.name = this.translateService.getLocaleLanguage(l.languageCode);
              l.isDialect = isSupportedLanguageDialect(
                supportedLanguages.map(l => l.languageCode),
                l.languageCode
              );
            });
            supportedLanguages.sort((a, b) => a.name.localeCompare(b.name));
            return [EdgeSupportedLanguagesActions.edgeUpsertSupportedLanguages({ supportedLanguages })];
          }),
          catchError(error => {
            this.trackService.track('edge.load_supported_languages.failed', {
              category: EventCategory.EDGE,
              data: { error }
            });
            return [EdgeSupportedLanguagesActions.edgeLoadSupportedLanguageFailed()];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private trackService: TrackService,
    private translateService: TranslateHelperService
  ) {}
}
