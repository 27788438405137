import { BasicFilterData } from '@common/modules/core/data-sources/interfaces';

export enum CustomizationFilterType {
  name = 'name',
  lastModifierUserName = 'lastModifierUserName',
  files = 'files',
  languageModel = 'languageModel',
  supportedLanguages = 'supportedLanguages',
  model = 'model',
  languageModelName = 'languageModelName',
  tags = 'tags',
  enabled = 'enabled'
}

export function nameFilter(filterValue: string, item: BasicFilterData): boolean {
  if (!item[CustomizationFilterType.name]) {
    // eslint-disable-next-line no-console
    console.error('Missing name data');
    return false;
  }
  return !filterValue || item[CustomizationFilterType.name].toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) !== -1;
}

export function ownerFilter(filterValue: string, item: BasicFilterData): boolean {
  if (!item[CustomizationFilterType.lastModifierUserName]) {
    // eslint-disable-next-line no-console
    console.error('Missing owner data');
    return false;
  }
  return !filterValue || item[CustomizationFilterType.lastModifierUserName].toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) !== -1;
}

export function categoryFilter(filterValue: string, item: BasicFilterData, ...args): boolean {
  if (!item[CustomizationFilterType.tags]) {
    // eslint-disable-next-line no-console
    console.error('Missing tags data');
    return false;
  }
  return !filterValue || item[CustomizationFilterType.tags].toString().toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) !== -1;
}
