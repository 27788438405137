import { Observable, Subject } from 'rxjs';

import { IEventProperties } from '../../../../../core/services/track';

export type BoundingBoxPageType =
  | Microsoft.VideoIndexer.Contracts.IObservedPeoplePage
  | Microsoft.VideoIndexer.Contracts.IBoundingBoxesPage
  | Microsoft.VideoIndexer.Contracts.IDetectedObjectsPage;

export interface IBodyTrackingPluginOptions {
  containerNameId: string;
  getFirstPage: () => Observable<BoundingBoxPageType>;
  getPageByTime: (time: number) => Observable<BoundingBoxPageType>;
  exposeResizeFunction: boolean;
  currentSelectedItem: () => number;
  boundingBoxLabel: string;
  type: BoundingBoxType;
  typeLabel: string;
  isOn: boolean;
  colorMap: IBoundingBoxColorMap;
  clearDataSubject: Subject<number>;
  track: (message: string, additionalProperties?: IEventProperties) => void;
  labelGenerator: (id: number, label?: string) => string;
  boundingBoxesFilterTypes?: IFilterItem[];
  boundingBoxFilterLabel?: string;
  filterDialogLabels?: IFilterItemsDialogLabels;
}

export interface IBoundingBoxColorMap {
  outerBoundingBoxColor: string;
  innerBoundingBoxColor: string;
  outerBoundingBoxColorSelected: string;
  fontColor: string;
  fontColorSelected: string;
}
export interface ITrackedItemSnapshot {
  colorMap: IBoundingBoxColorMap;
  label: string;
  instance: IInstance;
}

export interface ITimeToInstance {
  [time: number]: IInstance;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IRelativePoint extends IPoint {
  w: number;
  h: number;
}

export interface IInstance {
  startSeconds: number;
  endSeconds: number;
  points: IPoint[];
  relativePoint: IRelativePoint;
  start: number;
  end: number;
  topLeft?: number[];
  bottomRight?: number[];
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export enum BoundingBoxType {
  FACE = 'Face',
  BODY = 'Body',
  OBJECT = 'Object'
}

export enum captionsConfigurationOptions {
  AUDIO_EFFECTS = 'AudioEffects',
  SPEAKERS = 'Speakers'
}

export enum BoundingBoxOptionType {
  TOGGLE = 'Toggle',
  FILTER = 'Filter'
}

export const bodyTrackingButtonComponent = 'BodyTrackingButton';

export interface IFilterItemsDialogLabels {
  title: string;
  selectAllCheckbox: string;
  doneBtn: string;
  cancelBtn: string;
  searchPlaceholder: string;
  nextBtn: string;
  prevBtn: string;
  noResultsFound: string;
}

export interface IFilterItem {
  type: string;
  displayName: string;
}
