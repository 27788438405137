<div>
  <vi-ui-textarea [value]="metadata"
    [disabled]="isDisabled"
    [label]="resources.Metadata"
    [description]="isDisabled? '' : resources?.FileInformationMetadataDescription"
    (focusedOut)="onMetadataChanged($event)">
  </vi-ui-textarea>

  <vi-ui-textarea class="description"
    *ngIf="(isMultiFileMode$ | async) !== true"
    [value]="description"
    [label]="resources.Description"
    [disabled]="isDisabled"
    (focusedOut)="onDescriptionChanged($event)">
  </vi-ui-textarea>
</div>