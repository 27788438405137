import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';

import * as fromCore from '../../core/selectors';
import * as BrandActions from '../actions/brands-model.actions';
import * as fromCustomizationData from '../selectors';
import { IState } from '../../core/reducers';
import { CustomizationUtilsService } from '../services/customization-utils.service';
import { BrandsService } from '../services/brands.service';
import { IBrandsSettings } from '../../customization/components/brand/brand-container/interfaces';

@Injectable()
export class BrandModelEffects {
  public loadBrandsModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.loadBrandModels),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCustomizationData.isBrandModelsLoaded)),
      switchMap(([[, accountId], isLoaded]) => {
        this.logger.log('[BrandModelEffects] load brands model');
        // Return empty in case the models already loaded in the store.
        if (isLoaded) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.BrandsModels.getList(accountId).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.BrandContractV2[]) => {
            return [BrandActions.upsertBrandModels({ brands: result })];
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            return [BrandActions.failLoadBrandModels()];
          })
        );
      })
    )
  );

  public loadBrandsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.loadBrandsSettings),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([, accountId]) => {
        this.logger.log('[BrandModelEffects] load brands settings');
        return this.apiService.Account.Customization.BrandsModels.getBrandsModelSettings(accountId).pipe(
          switchMap((result: IBrandsSettings) => {
            return [BrandActions.upsertBrandsSettings({ settings: result })];
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            return EMPTY;
          })
        );
      })
    )
  );

  public createBrandModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.addBrandModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ brand }, accountId]) => {
        return this.apiService.Account.Customization.BrandsModels.addBrand(accountId, brand).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.BrandContractV2) => {
            this.logger.log('[BrandModelEffects] create brand models');
            this.customizationUtilsService.displayToast({ BrandsAddBrandSuccess: '' }, { name: result.name });
            this.trackService.track('models.brands.add_brand.success', {
              category: EventCategory.CUSTOMIZATION,
              brand: this.getBrandTraceData(result)
            });
            return [BrandActions.upsertBrandModel({ brand: result })];
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            this.brandsService.handleBrandsError(error, brand);
            this.trackService.track('models.brands.add_brand.failed', {
              category: EventCategory.CUSTOMIZATION,
              brand: this.getBrandTraceData(brand)
            });
            return [BrandActions.discardBrandSaving({ brand: brand })];
          })
        );
      })
    )
  );

  public updateBrandsModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.updateBrandModel),
      withLatestFrom(this.store.select(fromCustomizationData.getBrands)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[{ brand }, brands], accountId]) => {
        return this.apiService.Account.Customization.BrandsModels.updateBrand(accountId, brand).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.BrandContractV2) => {
            this.logger.log('[BrandModelEffects] update brand models');
            this.customizationUtilsService.displayToast({ BrandsEditBrandSuccess: '' }, { name: result.name });
            this.trackService.track('models.brands.edit_brand.success', {
              category: EventCategory.CUSTOMIZATION,
              brand: this.getBrandTraceData(result)
            });
            return [BrandActions.upsertBrandModel({ brand: result })];
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            this.brandsService.handleBrandsError(error, brand);
            this.trackService.track('models.brands.edit_brand.failed', {
              category: EventCategory.CUSTOMIZATION,
              brand: this.getBrandTraceData(brand)
            });
            const oldBrand = brands.filter(currentBrand => currentBrand.id === brand.id);
            return [BrandActions.upsertBrandModel({ brand: oldBrand[0] })];
          })
        );
      })
    )
  );

  public updateBrandsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.updateBrandsSettings),
      withLatestFrom(this.store.select(fromCustomizationData.getBrandsSettings)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[{ settings }, oldSettings], accountId]) => {
        return this.apiService.Account.Customization.BrandsModels.updateBrandsModelSettings(accountId, settings).pipe(
          switchMap((result: IBrandsSettings) => {
            this.logger.log('[BrandModelEffects] update brand settings');
            this.customizationUtilsService.displayToast({ AvertSuccessfullySubmitted: '' }, {});
            this.trackService.track('models.brands.active_suggestion.success', {
              category: EventCategory.CUSTOMIZATION,
              isActivated: settings.useBuiltIn
            });
            return [BrandActions.upsertBrandsSettings({ settings: result })];
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            this.brandsService.handleBrandsError(error, null);
            this.trackService.track('models.brands.active_suggestion.failed', {
              category: EventCategory.CUSTOMIZATION,
              isActivated: settings.useBuiltIn
            });
            return [BrandActions.upsertBrandsSettings({ settings: oldSettings })];
          })
        );
      })
    )
  );

  public deleteBrandModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.deleteBrandModel),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ brand }, accountId]) => {
        return this.apiService.Account.Customization.BrandsModels.deleteBrand(accountId, brand.id).pipe(
          switchMap(() => {
            this.logger.log(`[BrandModelEffects] Delete brand ${brand.id} success`);
            this.trackService.track('models.brands.delete_brand.success', {
              category: EventCategory.CUSTOMIZATION,
              brandId: brand.id
            });
            this.customizationUtilsService.displayToast({ BrandsDeleteBrandSuccess: '' }, { name: brand.name });
            return EMPTY;
          }),
          catchError(error => {
            this.logger.error('[BrandModelEffects]', error);
            this.customizationUtilsService.displayErrorToast(error, { FailDeleteBRANDS: '' }, {});
            this.trackService.track('models.brands.delete_brand.failed', {
              category: EventCategory.CUSTOMIZATION,
              brandId: brand.id
            });
            return [BrandActions.upsertBrandModel({ brand: brand })];
          })
        );
      })
    )
  );
  private customizationUtilsService: CustomizationUtilsService;
  private brandsService: BrandsService;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private logger: LoggerService,
    private trackService: TrackService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    this.customizationUtilsService = this.injector.get(CustomizationUtilsService);
    this.brandsService = this.injector.get(BrandsService);
  }

  private getBrandTraceData(brand: Microsoft.VideoIndexer.Contracts.BrandContractV2): Microsoft.VideoIndexer.Contracts.BrandContractSlim {
    return {
      id: brand.id,
      name: brand.name,
      accountId: brand.accountId,
      create: brand.create,
      lastModified: brand.lastModified,
      enabled: brand.enabled
    };
  }
}
