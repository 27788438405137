<div class="summary">
  <div class="strips-container">
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.eventGridError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.accountDurationQuotaError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.accountDailyCountQuotaError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.accountDailyDurationQuotaError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.amsUnReachableError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.amsAadAppNotInTenantError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.managedIdentityMissingError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.storageUnReachableError}"></ng-container>
    <ng-container [ngTemplateOutlet]="errorStrip"
      [ngTemplateOutletContext]="{error: errors?.storageAccessDeniedError}"></ng-container>
  </div>
  <span class="percentage">
    {{totalUploadProgress}}&percnt;
  </span>
  <span class="progress-title">{{progressTitle}}</span>
</div>
<vi-ui-progress-bar [value]="totalUploadProgress"
  [progressAriaLabel]="resources.Progress"></vi-ui-progress-bar>
<div class="details">
  <div class="row">
    <vi-ui-label [value]="filesLabel + ':'"></vi-ui-label>
    <div>
      <ng-container *ngFor="let file of files, index as i">
        <span [ngClass]="{'failed': file.uploadingState === UploadingState.Failed }">{{file.name}}<span
            [ngClass]="{'hide-failed': file.uploadingState !== UploadingState.Failed }">&nbsp;({{resources.Failed | lowercase}})</span>
        </span>
        <span *ngIf="i < numOfFiles - 1 ">,&nbsp;</span>
      </ng-container>
    </div>
  </div>
  <vi-indexing-summary></vi-indexing-summary>
</div>

<ng-template #errorStrip
  let-error="error">
  <vi-ui-alert-message *ngIf="error?.show | async"
    [type]="error?.type"
    [content]="error?.content"
    [linkText]="error?.linkText"
    [isButton]="error?.isButton"
    (buttonClicked)="error?.onCLick()">
  </vi-ui-alert-message>
</ng-template>