<div [appAccessibilityContentOnHoverHandler]
  [attr.aria-hidden]="activeContainer"
  (contentOnHover)="onMouseOver($event)">
  <div class="overlay"
    [attr.role]="roleType"
    [ngClass]="{selected:keyframe?.selected, 'is-video-thumbnail':keyframe?.isVideoThumbnail, 'is-in-time-frame': isInCurrentTimeFrame(), accessible: accessibilityMode}"
    [attr.tabindex]="activeContainer ? -1 : 0"
    [attr.aria-label]="keyframe?.title"
    (appAccessibleClick)="selectKf(keyframe)"
    [attr.aria-selected]="isActiveSelection ? !!keyframe?.selected: null">
  </div>
  <div class="img"
    #keyframeImage
    role="img"
    [attr.aria-label]="keyframe?.title"
    [attr.alt]="resources?.KeyframeProfilePictureLabel"
    [ngStyle]="keyframe?.style">
  </div>
  <app-vi-notification-tooltip *ngIf="hasTooltip && showTooltip"
    (escPressed)="showTooltip = false"
    [targetElement]="keyframeRef"
    [addAnimation]="false"
    [notificationText]="keyframe?.title">
  </app-vi-notification-tooltip>
</div>
