/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';

import PaginatedResult = Microsoft.VideoIndexer.Contracts.PaginatedResult;
import UnknownPersonsJob = Microsoft.VideoIndexer.Contracts.UnknownPersonsJob;
import UnknownPerson = Microsoft.VideoIndexer.Contracts.UnknownPerson;
import UnknownPersonDetails = Microsoft.VideoIndexer.Contracts.UnknownPersonDetails;

export const selectUnknownPersonsTab = createAction('[UnknownPersons] Select Unknown Persons tab');
export const openUnknownPersonDetailsDialog = createAction('[UnknownPersons] Open Unknown Person Details Dialog', props<{ id: string }>());

export const submitUnknownPersonsJob = createAction('[UnknownPersons/API] Submit Unknown Persons Job');
export const submitUnknownPersonsJobSucceeded = createAction(
  '[UnknownPersons] Submit Unknown Persons Job succeeded',
  props<{ job?: UnknownPersonsJob }>()
);
export const submitUnknownPersonsJobFailed = createAction(
  '[UnknownPersons] Submit Unknown Persons Job failed',
  props<{ error?: any; job: UnknownPersonsJob }>()
);
export const getUnknownPersonsJob = createAction('[UnknownPersons/API] Get Unknown Persons Job');
export const getUnknownPersonsJobSucceeded = createAction(
  '[UnknownPersons/API] Get Unknown Persons Job Succeeded',
  props<{ job: UnknownPersonsJob }>()
);
export const getUnknownPersonsJobFailed = createAction('[UnknownPersons/API] Get Unknown Persons Job Failed', props<{ error: any }>());

export const listUnknownPersons = createAction('[UnknownPersons/API] List Unknown Persons', props<{ searchValue?: string }>());
export const listUnknownPersonsSucceeded = createAction(
  '[UnknownPersons] List Unknown Persons succeeded',
  props<{ unknownPersons: PaginatedResult<UnknownPerson> }>()
);
export const listUnknownPersonsFailed = createAction('[UnknownPersons] List Unknown Persons failed', props<{ error?: any }>());

export const selectUnknownPerson = createAction('[UnknownPersons] Select Unknown Person', props<{ unknownPerson: UnknownPersonDetails }>());
export const getUnknownPerson = createAction('[UnknownPersons/API] Get Unknown Person', props<{ id: string }>());
export const getUnknownPersonSucceeded = createAction(
  '[UnknownPersons] Get Unknown Person succeeded',
  props<{ unknownPerson: UnknownPersonDetails }>()
);
export const getUnknownPersonFailed = createAction('[UnknownPersons] Get Unknown Person failed', props<{ error?: any }>());

export const updateUnknownPerson = createAction('[UnknownPersons/API] Update Unknown Person', props<{ unknownPerson: Partial<UnknownPerson> }>());
export const updateUnknownPersonSucceeded = createAction(
  '[UnknownPersons] Update Unknown Person succeeded',
  props<{ unknownPerson: Partial<UnknownPerson> }>()
);
export const updateUnknownPersonFailed = createAction('[UnknownPersons] Update Unknown Person failed', props<{ error?: any }>());

export const openDeleteUnknownPeopleDialog = createAction('[UnknownPersons/API] Open Delete Unknown Person Dialog', props<{ personIds: string[] }>());

export const deleteUnknownPerson = createAction('[UnknownPersons/API] Delete Unknown Person', props<{ id: string }>());
export const deleteUnknownPersonSucceeded = createAction('[UnknownPersons] Delete Unknown Person succeeded', props<{ id: string }>());
export const deleteUnknownPersonFailed = createAction('[UnknownPersons] Delete Unknown Person failed', props<{ error?: any }>());

export const deleteUnknownPersonFace = createAction(
  '[UnknownPersons/API] Delete Unknown Person Face',
  props<{ unknownPersonId: string; faceId: string }>()
);
export const deleteUnknownPersonFaceSucceeded = createAction(
  '[UnknownPersons] Delete Unknown Person Face succeeded',
  props<{ unknownPersonId: string; faceId: string }>()
);
export const deleteUnknownPersonFaceFailed = createAction('[UnknownPersons] Delete Unknown Person Face failed', props<{ error?: any }>());

export const moveUnknownPersonToModel = createAction(
  '[UnknownPersons/API] Move Unknown Person to model',
  props<{ personName: string; unknownPerson: UnknownPersonDetails }>()
);
export const moveUnknownPersonToModelSucceeded = createAction(
  '[UnknownPersons] Move Unknown Person to model succeeded',
  props<{ unknownPerson: UnknownPerson; personName: string }>()
);
export const moveUnknownPersonToModelFailed = createAction('[UnknownPersons] Move Unknown Person to model failed', props<{ error?: any }>());

export const searchUnknownPersons = createAction('[UnknownPersons] Search unknown persons', props<{ searchValue: string }>());
export const clearUnknownPersons = createAction('[UnknownPersons] Clear Unknown Persons State');

export const clearTableListError = createAction('[UnknownPersons] Clear Unknown Persons Table List Error State');
