import { createAction, props } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import { IIndexingSettings, SupportedAIsMap } from '../../interfaces';
import { ReIndexPages, FailureCode } from '../../components/re-index/interfaces';

export const openReIndexDialog = createAction('[ReIndex] Open Re-Index Dialog', props<{ videoId: string }>());
export const resetReIndexState = createAction('[ReIndex] Reset Re-Index State');
export const updateReIndexPage = createAction('[ReIndex] Update ReIndex Page', props<{ page: ReIndexPages }>());
export const updateReIndexIndexingSettings = createAction('[ReIndex] Update Indexing Settings', props<{ settings: Partial<IIndexingSettings> }>());
export const updateReIndexIndexingAdvancedSettings = createAction('[ReIndex] Update Indexing Advanced Settings');
export const initReIndexIndexingSettings = createAction(
  '[ReIndex] Init Indexing Settings',
  props<{ videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2; streamingPreset: string; reviewState: string; moderationState: string }>()
);
export const getVideoIndexFailed = createAction('[ReIndex] Get Video Index Failed', props<{ errorType: APIErrors | FailureCode }>());
export const loadVideoIndex = createAction('[ReIndex] Load Video Index');
export const reIndexVideo = createAction('[ReIndex] Re-Index Video');
export const reIndexVideoFailed = createAction('[ReIndex] Re-Index Video Failed', props<{ errorType: APIErrors | FailureCode }>());
export const reIndexVideoSucceeded = createAction('[ReIndex] Re-Index Video Succeeded');
export const initVideoId = createAction('[ReIndex] Init Video Id', props<{ videoId: string }>());
export const indexingSettingsLoaded = createAction('[ReIndex] Indexing Settings Loaded');
export const updateOriginalLanguageId = createAction('[ReIndex] Update Original Language Id', props<{ languageId: string }>());
export const validateReIndexSettingReady = createAction('[ReIndex] Validate Re-Index Setting Ready');
export const updateReIndexSupportedAIs = createAction('[ReIndex] Update Supported AIs', props<{ supportedAIs: SupportedAIsMap }>());
export const updateReIndexSupportedAIsState = createAction(
  '[ReIndex] Update Supported AI Status',
  props<{ supportedAIs: { name: string; checked: boolean; disabled: boolean }[] }>()
);
