import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { UIIndexingActionType } from '../interfaces';

export const logoGroupAction: IAction = {
  title: '',
  value: UIIndexingActionType.LOGO_GROUP,
  type: UIIndexingActionType.LOGO_GROUP,
  key: 'UploadServiceDefaultName',
  selected: true,
  id: 'logoGroup'
};

export const manageLogoGroupsAction: IAction = {
  title: '',
  key: 'IndexingLogosManageLogoGroups',
  type: UIActionType.NAVIGATE,
  value: CustomizationPage.Logos,
  id: 'manageLogoGroupsButton',
  icon: 'i-vi-customization'
};
