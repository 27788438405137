import { Injectable, Inject } from '@angular/core';

import { WINDOW } from '../../core/services/window.token';

@Injectable({
  providedIn: 'root'
})
export abstract class StorageService {
  private storageAvailable: boolean;

  constructor(@Inject(WINDOW) protected window) {
    this.setStorageAvailable();
  }

  public setItem(name: string, value: string) {
    if (!this.storageAvailable) {
      return;
    }
    this.storage.setItem(name, value);
  }

  public removeItem(name: string) {
    if (!this.storageAvailable) {
      return;
    }
    this.storage.removeItem(name);
  }

  public getItem(name: string): string {
    if (!this.storageAvailable) {
      return '';
    }
    return this.storage.getItem(name);
  }

  public clear() {
    this.storage.clear();
  }

  public abstract get storage();

  private setStorageAvailable() {
    const testStorage = `test-storage`;
    try {
      this.storage.setItem(testStorage, testStorage);
      this.storage.removeItem(testStorage);
      this.storageAvailable = true;
    } catch (e) {
      this.storageAvailable = false;
    }
  }
}
