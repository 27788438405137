import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import { tap, withLatestFrom } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { TrackService } from '@common/modules/core/services/track';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { EnvironmentNames } from '@common/modules/shared/environments';

import { VIRoutingMap } from '../../app/routing/routes';
import { IState } from '../reducers';
import { IVIRoute } from '../../app/routing/interfaces';
import * as RouterActions from '../actions/router.actions';
import * as fromRouter from '../reducers/router';

@Injectable()
export class AppRouterEffects {
  public navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.Go),
        withLatestFrom(this.store.select(fromRouter.getQueryParams)),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        tap(([[{ path, queryParams, extras, removeParams }, routerQueryParams], routerState]) => {
          if (!this.authService.isAuthenticated() && routerState?.state?.data?.name === VIRoutingMap.login.name) {
            // No navigation when user is not authenticated from login page
            return;
          }

          if (
            !removeParams &&
            !queryParams &&
            (path[0]?.includes(VIRoutingMap.galleryLibrary.path) ||
              path[0]?.includes(VIRoutingMap.galleryProjects.path) ||
              path[0]?.includes(VIRoutingMap.gallerySamples.path))
          ) {
            return this.router.navigate(path, { queryParams: routerQueryParams, ...extras });
          }

          if (routerQueryParams['env'] === EnvironmentNames.Localhost) {
            queryParams = { ...queryParams, env: EnvironmentNames.Localhost };
          }

          // If contain invitation
          if (routerQueryParams.returnUrl?.includes('accountId') && routerQueryParams.returnUrl?.includes('invitationId')) {
            queryParams = { ...queryParams, invitation: routerQueryParams.returnUrl };
          }

          return this.router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  public navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.Back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  public navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.Forward),
        tap(({}) => this.location.forward())
      ),
    { dispatch: false }
  );

  public route$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        withLatestFrom(this.store.select(fromRouter.getRouterState)),
        tap(([, routerState]) => {
          // Track route
          const routeData = routerState?.state.data as IVIRoute;
          // If route data is part of RoutingMap
          if (routeData?.name && routeData?.traceName) {
            this.trackingService.trackPageView(`route.${routeData.traceName}`);
            this.logger.log('[AppRouterEffects] router route:' + routeData.name);
          } else {
            this.trackingService.trackPageView(`unknown tracking data: ${routerState?.state.filteredUrl}`);
            this.logger.log('[AppRouterEffects] unknown tracking data');
          }

          const queryParams = routerState?.state?.queryParams;
          this.logger.log(queryParams);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store<IState>,
    private navigationService: AppNavigationService,
    private trackingService: TrackService,
    private authService: AuthService
  ) {}
}
