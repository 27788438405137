import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { locationsUS } from '../../api/services/locations-us';
import { ISubscriptions } from '../../insights/interfaces';
import { browserDetect, notSupportedBrowsers } from '../../utils/browserDetect';
import { IArrToString, IBrowserDetails } from '../interfaces';
import {
  FF_SUPPORT,
  PAID_SERVICE_AGREEMENT,
  PAID_SUPPORT,
  PORTAL,
  PRIVACY_STATEMENT,
  TRIAL_SERVICE_AGREEMENT,
  TRIAL_SUPPORT,
  US_PORTAL
} from '../links';
import { ConfigService } from '../../core/services/config/config.service';
import { EnvironmentNames } from '../environments';
import { PortalCreateVIDevAccountFlag } from '../arm';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private browserDetails: IBrowserDetails;

  constructor(@Inject(DOCUMENT) private document: Document, private config: ConfigService) {
    this.init();
  }

  public get azurePortalLink() {
    return this.config.isFairFaxEnv() ? US_PORTAL : PORTAL;
  }

  public getBrowserDetails() {
    return this.browserDetails;
  }

  public getLinkByValue(isTrial: boolean, linkName: string): string {
    switch (linkName) {
      case 'PrivacyStatement':
        return PRIVACY_STATEMENT;

      case 'ServiceAgreement':
        if (!isTrial) {
          return PAID_SERVICE_AGREEMENT;
        } else {
          return TRIAL_SERVICE_AGREEMENT;
        }
      case 'Support':
        if (this.config.isFairFaxEnv()) {
          return FF_SUPPORT;
        } else if (!isTrial) {
          return PAID_SUPPORT;
        } else {
          return TRIAL_SUPPORT;
        }
      default:
        return linkName;
    }
  }

  public parseArrayToString(arrOutput: IArrToString[]): string {
    let currentString = '';
    if (arrOutput) {
      for (const item of arrOutput) {
        currentString += item.name + ',';
      }
    }

    return currentString;
  }

  public getContainerRectAsync(el: HTMLElement): Promise<DOMRect> {
    return new Promise((resolve, reject) => {
      const rect = el && el.getBoundingClientRect();
      resolve(rect);
    });
  }

  public getBodyRectAsync(): Promise<DOMRect> {
    return new Promise((resolve, reject) => {
      const rect = this.document && this.document.body && this.document.body.getBoundingClientRect();
      resolve(rect);
    });
  }

  public unsubscribeSubscriptions(subscriptions: ISubscriptions): void {
    const keys = Object.keys(subscriptions);
    keys.forEach(k => {
      if (subscriptions[k]) {
        subscriptions[k].unsubscribe();
      }
    });
  }

  public isIOSDevice() {
    const browserOS: string = this.browserDetails?.os.toLowerCase();
    return browserOS.includes('iphone') || browserOS.includes('ipod') || browserOS.includes('ipad');
  }
  public isMobile() {
    return this.browserDetails?.browser.toLowerCase() === 'mobile';
  }

  public isBrowserSupported(): boolean {
    if (this.browserDetails?.browser in notSupportedBrowsers && this.browserDetails?.browser <= notSupportedBrowsers[this.browserDetails?.browser]) {
      return false;
    }
    return true;
  }

  public isAccountOnUSDataCenter(location: string, isTrial: boolean) {
    // Trial account are on Eat US / West US
    if (isTrial) {
      return true;
    }

    return locationsUS.includes(location);
  }

  /**
   * Gets the parent resource ID (stringified) for the given ARM ID.
   * @example
   * /subscriptions/1234/resourceGroups/rg/providers/Microsoft.ParentProvider/parentResource/providers/Microsoft.ChildProvider/childResource
   * will return - /subscriptions/1234/resourceGroups/rg/providers/Microsoft.ParentProvider/parentResource
   */
  public getParentResourceId(resourceId: string = '') {
    const result = resourceId.match(/.+?(?=\/providers)/g);

    if (!result) {
      return '';
    }

    return (result[0] || '') + (result[1] || '');
  }

  public azurePortalResourceLink(tenantId: string, resourceId: string, queryParams?: string) {
    queryParams = queryParams ? `?${queryParams}` : '';
    return `${this.azurePortalLink}/${queryParams}#@${tenantId}/resource/${resourceId}`;
  }

  public getUpdateAccountPortalUrl(tenantId: string, resourceId: string) {
    let queryParams = 'vi_update_to_amsless_account=true';

    if (this.isDevOrStagingEnv) {
      queryParams += `&${PortalCreateVIDevAccountFlag}`;
    }

    return `${this.azurePortalResourceLink(tenantId, resourceId, queryParams)}/overview`;
  }

  public getRequestMigrationInAzurePortalUrl(tenantId: string, resourceId: string) {
    let queryParams = 'vi_optin_to_ams_migration=true';

    if (this.isDevOrStagingEnv) {
      queryParams += `&${PortalCreateVIDevAccountFlag}`;
    }

    return `${this.azurePortalResourceLink(tenantId, resourceId, queryParams)}/overview`;
  }

  public getViResourceOverviewPortalUrl(tenantId: string, resourceId: string) {
    const queryParams = this.isDevOrStagingEnv ? `${PortalCreateVIDevAccountFlag}` : null;
    return `${this.azurePortalResourceLink(tenantId, resourceId, queryParams)}/overview`;
  }

  private init() {
    this.browserDetails = browserDetect();
  }

  private get isDevOrStagingEnv() {
    return this.config.isEnvType(EnvironmentNames.Dev) || this.config.isEnvType(EnvironmentNames.Staging);
  }
}
