import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, filter, take, map } from 'rxjs';

import * as fromCustomizationDataState from '../../reducers/index';
import * as fromCustomizationData from '../../selectors';
import * as SpeechPageActions from '../../actions/speech/speech-page.actions';
import { SpeechTab } from '../../../customization/components/speech/speech-container/interfaces';
import { SpeechPermission } from '../../interfaces';

@Injectable()
export class SpeechPageStoreService {
  constructor(private readonly store: Store<fromCustomizationDataState.IState>) {}

  public selectSpeechTab(tab: SpeechTab) {
    this.store.dispatch(SpeechPageActions.selectSpeechTab({ tab }));
  }

  public getSpeechSelectedTab(): Observable<SpeechTab> {
    return this.store.select(fromCustomizationData.getSpeechSelectedTab);
  }

  public isSpeechEnabled(): Observable<boolean> {
    return this.getSpeechPermission().pipe(
      filter(permission => permission !== SpeechPermission.UNDEFINED),
      take(1),
      map(permission => permission === SpeechPermission.PERMITTED)
    );
  }

  public getSpeechPermission(): Observable<SpeechPermission> {
    return this.store.select(fromCustomizationData.getSpeechPermission);
  }
}
