import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';

export const getAmsMigrationState = createSelector(selectCoreState, state => state.amsMigration);

export const getAmsMigrationStatus = createSelector(getAmsMigrationState, state => state.accountMigration);

export const getAmsMigrationsListResult = createSelector(getAmsMigrationState, state => state.videoMigrations);

export const getVideoMigrationResults = createSelector(getAmsMigrationsListResult, state => state.results);

export const getAmsMigrationResultsNextPage = createSelector(getAmsMigrationsListResult, state => state.nextPage);

export const getAmsMigrationsPaginationDone = createSelector(getAmsMigrationResultsNextPage, state => state.done);

export const getIsLoading = createSelector(getAmsMigrationState, state => state.isLoading);

export const getLoadingMore = createSelector(getAmsMigrationState, state => state.loadingMore);
