import { Injectable } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { TimeInterval } from '@common/modules/utils/time';

import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { getKeyCode, KeyCode } from '../../../utils/event';
import { LoggerService } from '../logger/logger.service';
import { FeatureSwitchService } from '../feature-switch/feature-switch.service';
import { ConfigService } from '../config/config.service';
import { FeatureSwitch } from '../interfaces';
import { INotification, NotificationLevel } from './interfaces';
import { Errors } from './errors';
import { resources } from './resources';

@Injectable()
export class ToastService {
  public resources = resources;
  private messageTimeout = TimeInterval.SECOND * 5;
  private positionClass = 'toast-bottom-right';

  constructor(
    private toastr: ToastrService,
    private liveAnnouncer: LiveAnnouncer,
    private translate: TranslateHelperService,
    private loggerService: LoggerService,
    private focusManager: FocusManagerService,
    private featureSwitchService: FeatureSwitchService,
    private configService: ConfigService
  ) {
    this.translate.translateResources(this.resources);
    if (!this.configService.isEmbedMode() && this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
      this.positionClass = 'inline';
    }
  }

  // Given error object , tries to find suitable error message
  public handleError(err: HttpErrorResponse, errorPrefix?: string, values?): string {
    if (!err || !err.status || !err.error) {
      return '';
    }
    let errorObj = err.error;
    // Do this to ensure that errorObj is valid json
    try {
      errorObj = JSON.parse(errorObj);
    } catch {}
    if (!errorObj.ErrorType) {
      return '';
    }
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return '';
    } else {
      // The backend returned an unsuccessful response code.
      const errorType = errorObj.ErrorType;
      if (errorPrefix) {
        if (values) {
          const ErrorResource = {};
          ErrorResource[`${errorPrefix}_${errorType}`] = '';
          this.translate.translateResources(ErrorResource, values);
          return ErrorResource[`${errorPrefix}_${errorType}`] !== `${errorPrefix}_${errorType}`
            ? ErrorResource[`${errorPrefix}_${errorType}`]
            : this.resources[`${errorPrefix}_GENERAL`];
        }
        return this.resources[`${errorPrefix}_${errorType}`];
      }
      switch (err.status) {
        case 401:
          const errorResource = {};
          const errorKey = Errors[errorType]?.key ? `ErrorTypes_${Errors[errorType].key}` : Errors.GENERAL.key;
          errorResource[errorKey] = '';
          this.translate.translateResources(errorResource);
          return errorResource[errorKey] || '';
        default:
          return '';
      }
    }
  }

  // Toast success notification
  public success(message: string, isFullWidth = true) {
    if (!message) {
      return;
    }
    const template = `<span class="vi-message">
                        <i class="vi-delete-parent i-vi-close-big"></i>
                        <span class="vi-text">${message}</span>
                     </span>`;
    this.toastr.success(template, '', {
      timeOut: this.messageTimeout,
      enableHtml: true,
      positionClass: isFullWidth ? 'toast-bottom-full-width' : this.positionClass
    });

    // Accessability announce message
    this.liveAnnouncer.announce(message);
  }

  // Toast notification
  public notify(notification: INotification) {
    if (!notification?.text && !notification?.title) {
      return;
    }

    // In embed mode we show only error/critical notifications as strips
    if (this.configService.isEmbedMode()) {
      if (notification?.level === NotificationLevel.Error || notification?.level === NotificationLevel.Critical) {
        this.error(null, notification?.text, true, false, true);
      }

      return;
    }

    // Toaster service can get angular component on the module declaration.
    // after we move all toast to use the notifications format all of the templates will be deleted and used the component
    const template = `<span class="vi-notification-card">
                        <div class="notification-title ${notification?.icon ? '' : 'no-icon'} ${notification?.title ? '' : 'hide'} ">
                          ${notification?.icon ? `<i class="${notification?.icon}"></i>` : ''}
                          <span class="vi-text">${notification?.title}</span>
                          <i class="vi-delete-parent i-vi-close-big"></i>
                        </div>
                        <div class="notification-message ${notification?.title ? '' : 'show-icon'}">
                          <span class="vi-text ${notification?.text ? '' : 'no-text'}">${notification?.text}</span>
                          <i class="vi-delete-parent i-vi-close-big"></i>
                        </div>
                        ${
                          notification?.link?.src
                            ? `
                        <div class="notification-link">
                          <a href=${notification?.link.src} aria-label=${notification?.link?.text} target="_blank">${notification?.link?.text}</a>
                        </div>
                        `
                            : ''
                        }
                      </span>`;
    this.toastr.success(template, '', {
      timeOut: this.messageTimeout,
      enableHtml: true,
      messageClass: 'vi-notification-card'
    });

    // Accessability announce message
    this.liveAnnouncer.announce(notification?.text);
  }

  // Toast error notification
  public error(
    err?: HttpErrorResponse | ErrorEvent,
    message?: string,
    isFullWidth = true,
    local = false,
    disableTimeOut = false,
    values?,
    errorPrefix?: string
  ) {
    if (!err && !message) {
      return;
    }
    const errorMessage = this.handleError(err as HttpErrorResponse, errorPrefix, values);
    if (errorMessage && !local) {
      message = errorMessage;
    }

    const template = `<span class="vi-message">
                        <i tabindex="0" class="vi-delete-parent i-vi-close-big toast-close"></i>
                        <span class="vi-text">${message}</span>
                      </span>`;

    if (disableTimeOut) {
      this.toastr.error(template, '', {
        disableTimeOut: disableTimeOut,
        enableHtml: true,
        positionClass: isFullWidth ? 'toast-bottom-full-width' : this.positionClass
      });

      this.addToasterAccessibility();
    } else {
      this.toastr.error(template, '', {
        timeOut: this.messageTimeout,
        enableHtml: true,
        positionClass: isFullWidth ? 'toast-bottom-full-width' : this.positionClass
      });
    }

    // Accessability announce message
    this.liveAnnouncer.announce(message);
  }

  public clearAllToasts() {
    this.toastr.clear();
  }

  private addToasterAccessibility() {
    // Add focus + enter key handling
    this.focusManager.focusVia(() => {
      // Get exit button
      const inputElement = document.querySelector('.toast-close') as HTMLElement;

      // Add keyup event to exit on enter
      inputElement.addEventListener(
        'keyup',
        function onEnterPress(e) {
          try {
            const keyCode = getKeyCode(e);

            if (keyCode === KeyCode.Enter) {
              // Stimulate clicking
              inputElement.click();
              inputElement.removeEventListener('keyup', onEnterPress);
            }
          } catch (error) {
            this.loggerService.log('Please add key to dictionary.', error);
          }
        }.bind(this)
      );

      // Return the focus element
      return {
        nativeElement: inputElement
      };
    });
  }
}
