import { ExcludedSelectedDialectLanguages } from './../core/languages';
// TODO: set the function in service/other specific utils for languages
export function isSupportedLanguageDisabled(selectedLanguages: string[], languageCode: Microsoft.VideoIndexer.Contracts.LanguageV2): boolean {
  // Disable the language
  // 1. Get selected languages and disable the selected languages
  // 2. If part of the list disable the duplicated in the list
  // Exception: zh-CN/ zh-TW can be at the same list of zh-HK
  const isDisabled = selectedLanguages?.includes(languageCode) || isSupportedLanguageDialect(selectedLanguages, languageCode);
  return isDisabled;
}

export function isSupportedLanguageDialect(selectedLanguages: string[], languageCode: Microsoft.VideoIndexer.Contracts.LanguageV2) {
  return !!selectedLanguages?.find(selectedLanguage => {
    const languagePrefix = selectedLanguage?.slice(0, selectedLanguage?.indexOf('-')).toLocaleLowerCase();
    const isExcluded = ExcludedSelectedDialectLanguages.find(languageCode => selectedLanguage === languageCode);
    return isExcluded || selectedLanguage === languageCode
      ? false
      : languagePrefix === languageCode?.slice(0, languageCode?.indexOf('-')).toLocaleLowerCase();
  });
}
