import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { PeopleTab } from './../../../customization/components/customization-people-container/interfaces';
import * as fromCustomizationDataState from '../../reducers/index';
import * as PeoplePageActions from '../../actions/people/people-page.actions';
import * as fromPeoplePage from '../../selectors/people/people-page.selectors';

@Injectable()
export class PeoplePageStoreService {
  constructor(private readonly store: Store<fromCustomizationDataState.IState>) {}

  public selectPeopleTab(tab: PeopleTab) {
    this.store.dispatch(PeoplePageActions.selectPeopleTab({ tab }));
  }

  public getSelectedTab(): Observable<PeopleTab> {
    return this.store.select(fromPeoplePage.getSelectedTab);
  }
}
