import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as LanguageIDActions from '../actions/supported-languages-ids.actions';

export interface IState extends EntityState<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> {
  // additional entities state properties
  loaded: boolean;
  error: boolean;
}

export function selectLanguageId(language: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract): Microsoft.VideoIndexer.Contracts.LanguageV2 {
  return language?.languageCode;
}

export const adapter: EntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> =
  createEntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract>({
    selectId: selectLanguageId
  });

export const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false
});

const supportedLanguagesIdsReducer = createReducer(
  initialState,
  on(LanguageIDActions.upsertSupportedLanguages, (state, { supportedLanguages }) => {
    return adapter.upsertMany(supportedLanguages, {
      ...state,
      loaded: true,
      error: false
    });
  }),
  on(LanguageIDActions.loadSupportedLanguageFailed, state => {
    return {
      ...state,
      error: true
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return supportedLanguagesIdsReducer(state, action);
}
