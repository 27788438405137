import { ChangeDetectorRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { EdgeExtensionsStoreService } from './../../../../apps/web/src/core/services/edge-extensions-store.service';

@Directive({
  selector: '[viShowIfHasEdgeExtensions]'
})
export class ShowIfHasEdgeExtensionsDirective implements OnInit {
  private hasEdgeExtensions = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {
    this.edgeExtensionsStore.hasEdgeExtensions$.subscribe(hasEdgeExtensions => {
      this.hasEdgeExtensions = hasEdgeExtensions;
      this.updateRef();
    });
  }

  public ngOnInit(): void {}

  private updateRef(): void {
    if (this.hasEdgeExtensions) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdr.markForCheck();
    } else {
      this.viewContainer.clear();
    }
  }
}
