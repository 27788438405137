import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as SpeechModelsActions from '../../actions/speech/speech-models.actions';

import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;

export interface SpeechModelsState extends EntityState<SpeechModel> {
  // additional entities state properties
  loaded: boolean;
  updating: boolean;
  creating: boolean;
  error: boolean;
  selectedModelId: string;
  isTrainDialogOpened: boolean;
}

function selectModelId(a: SpeechModel): string {
  return a.id;
}

const adapter = createEntityAdapter<SpeechModel>({
  selectId: selectModelId
});

export const modelsInitialState: SpeechModelsState = adapter.getInitialState({
  loaded: false,
  updating: false,
  creating: false,
  error: false,
  supportedLocales: [],
  isSupportedLocalesLoaded: false,
  selectedModelId: null,
  isTrainDialogOpened: false
});

const speechModelsReducer = createReducer(
  modelsInitialState,
  on(SpeechModelsActions.loadSpeechModelsSucceeded, (state, { models }) => {
    return adapter.upsertMany(models, { ...state, loaded: true, error: false });
  }),
  on(SpeechModelsActions.loadSpeechModelsFailed, state => {
    return {
      ...state,
      error: true,
      loaded: true
    };
  }),
  on(SpeechModelsActions.clearSpeechModels, state => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      error: false
    });
  }),
  on(SpeechModelsActions.createSpeechModelStarted, state => {
    return {
      ...state,
      creating: true
    };
  }),
  on(SpeechModelsActions.removeSpeechModel, (state, { modelId }) => {
    return adapter.removeOne(modelId, state);
  }),
  on(SpeechModelsActions.createSpeechModelSucceed, (state, { model }) => {
    return adapter.addOne(model, { ...state, creating: false });
  }),
  on(SpeechModelsActions.createSpeechModelFailed, (state, { modelId }) => {
    return adapter.removeOne(modelId, {
      ...state,
      creating: false
    });
  }),
  on(SpeechModelsActions.updateSpeechModelStarted, state => {
    return {
      ...state,
      updating: true
    };
  }),
  on(SpeechModelsActions.updateSpeechModelSucceed, (state, { model }) => {
    return adapter.updateOne({ id: model.id, changes: model }, { ...state, updating: false });
  }),
  on(SpeechModelsActions.updateSpeechModelFailed, state => {
    return {
      ...state,
      updating: false
    };
  }),
  on(SpeechModelsActions.deleteSpeechModelSucceed, (state, { modelId }) => {
    return adapter.removeOne(modelId, state);
  }),
  on(SpeechModelsActions.getModelStatusSucceeded, (state, { model }) => {
    return adapter.updateOne({ id: model.id, changes: model }, state);
  }),
  on(SpeechModelsActions.openTrainModelDialog, state => {
    return {
      ...state,
      isTrainDialogOpened: true
    };
  }),
  on(SpeechModelsActions.trainModelDialogClosed, state => {
    return {
      ...state,
      isTrainDialogOpened: false
    };
  })
);

export function reducer(state: SpeechModelsState | undefined, action: Action) {
  return speechModelsReducer(state, action);
}
