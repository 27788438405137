import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

export const leaveIndexingAction: IAction = {
  title: '',
  key: 'LeaveIndexingAction',
  type: UIActionType.NAVIGATE,
  value: UIActionType.NAVIGATE,
  id: 'leaveIndexingAction'
};

export const backToIndexingAction: IAction = {
  title: '',
  key: 'BackToIndexingAction',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'backToIndexingAction'
};
