import { OnInit, ChangeDetectorRef, OnDestroy, EventEmitter, Output, ChangeDetectionStrategy, Component } from '@angular/core';

import { Subject, combineLatestWith, takeUntil } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { CoreStoreService } from '../../../../core/services/core-store.service';
import { resources } from './resources';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { IUploadErrorStrip } from '../../upload/summary-page/interfaces';

@Component({
  selector: 'vi-re-index-summary-page',
  templateUrl: './re-index-summary-page.component.html',
  styleUrls: ['./re-index-summary-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReIndexSummaryPageComponent implements OnInit, OnDestroy {
  @Output() public reIndexError = new EventEmitter<void>();

  public resources = resources;

  public file = '';
  public filesLabel = '';

  public showReIndexError = false;
  public AlertMessageType = AlertMessageType;
  public error: Partial<IUploadErrorStrip>;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private coreStoreService: CoreStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.translate.translateResourcesInstant(this.resources);
    this.indexingStore.fileName$.pipe(takeUntil(this.destroy$)).subscribe(fileName => {
      this.file = fileName;
      this.cdr.markForCheck();
    });
    this.handleReIndexErrors();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleReIndexErrors() {
    this.indexingStore.isAmsUnreachableReIndexError$
      .pipe(
        takeUntil(this.destroy$),
        combineLatestWith(
          this.indexingStore.isSourceVideoDeletedError$,
          this.indexingStore.isIndexingInProgressError$,
          this.indexingStore.isFileInReviewError$,
          this.indexingStore.isFileWasReportedError$,
          this.indexingStore.isProjectRenderInProgressError$,
          this.indexingStore.isAmsAadAppNotInTenantReIndexError$,
          this.indexingStore.isGeneralReIndexError$,
          this.indexingStore.isStorageAccessDeniedReIndexError$,
          this.indexingStore.isStorageUnreachableReIndexError$,
          this.indexingStore.isManageIdentityMissingReIndexError$
        )
      )
      .subscribe(
        ([
          isAmsUnreachableReIndexError,
          isSourceVideoDeletedError,
          isIndexingInProgressError,
          isFileInReviewError,
          isFileWasReportedError,
          isProjectRenderInProgressError,
          isAmsAadAppNotInTenantReIndexError,
          isGeneralReIndexError,
          isStorageAccessDeniedReIndexError,
          isStorageUnreachableReIndexError,
          isManageIdentityMissingReIndexError
        ]) => {
          const errorMessages = [
            { showError: isAmsUnreachableReIndexError, content: this.resources.ReIndexAmsUnreachableErrorMsg, linkText: null, onClick: null },
            { showError: isSourceVideoDeletedError, content: this.resources.ReIndexSourceVideoDeletedErrorMsg, linkText: null, onClick: null },
            { showError: isIndexingInProgressError, content: this.resources.ReIndexVideoIndexingInProgressErrorMsg, linkText: null, onClick: null },
            { showError: isFileInReviewError, content: this.resources.ReIndexFileInReviewErrorMsg, linkText: null, onClick: null },
            { showError: isFileWasReportedError, content: this.resources.ReIndexFileWasReportedErrorMsg, linkText: null, onClick: null },
            {
              showError: isProjectRenderInProgressError,
              content: this.resources.ReIndexProjectRenderInProgressErrorMsg,
              linkText: null,
              onClick: null
            },
            {
              showError: isAmsAadAppNotInTenantReIndexError,
              content: this.resources.ReIndexAmsAadAppNotInTenantErrorMsg,
              linkText: null,
              onClick: null
            },
            { showError: isGeneralReIndexError, content: this.resources.ReIndexGeneralErrorMessage, linkText: null, onClick: null },
            {
              showError: isStorageAccessDeniedReIndexError,
              content: this.resources.ReIndexStorageAccessDeniedErrorMsg,
              linkText: this.resources.StorageAccessDeniedErrorMsgLinkText,
              onClick: () => this.coreStoreService.navigateToViResourceOverviewPortalUrl()
            },
            { showError: isStorageUnreachableReIndexError, content: this.resources.ReIndexStorageUnreachableErrorMsg, linkText: null, onClick: null },
            {
              showError: isManageIdentityMissingReIndexError,
              content: this.resources.ReIndexFileManagedIdentityMissingErrorMsg,
              linkText: null,
              onClick: null
            }
          ];

          const error = errorMessages.find(error => error.showError);

          if (error) {
            this.showReIndexError = true;
            this.error = error;
            this.cdr.detectChanges();
          }
        }
      );
  }
}
