import { UnknownPersonTableListError } from 'src/apps/web/src/customization-data/reducers/people/unknown-persons.reducer';

export enum GroupingState {
  Processing = 'Processing',
  Processed = 'Completed',
  Failed = 'Failed'
}

export type EmptyStateType = GroupingState | UnknownPersonTableListError;

export interface IUnknownPeopleEmptyState {
  title: string;
  content: string;
  shouldShowGroupFacesButton: boolean;
}

interface DefaultState {
  default: IUnknownPeopleEmptyState;
}

export type EmptyStateMap = {
  [K in EmptyStateType]?: IUnknownPeopleEmptyState;
} & DefaultState;
