import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { FocusableComponent } from '../focusable';

@Component({
  selector: 'vi-ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent extends FocusableComponent implements OnInit {
  @Input() public checked = false;
  @Input() public title: string;
  @Input() public disabled: boolean = false;
  @Input() public debounceDelay: boolean = true;
  @Output() public checkedChange = new EventEmitter();

  constructor() {
    super();
  }

  public onChange() {
    this.checked = !this.checked;
    if (!this.debounceDelay) {
      this.checkedChange.emit(this.checked);
    }
  }

  public debounceCheckedChange() {
    if (this.debounceDelay) {
      this.checkedChange.emit(this.checked);
    }
  }
}
