import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { ApiService } from '@common/modules/api/services/api.service';

import { AuthService } from '../../auth/services/auth.service';
import { ConfigService } from '../../core/services/config/config.service';
import { AccessTokenService } from '../services/access-token.service';
import {
  forceAccessToken,
  isAccessTokenRequest,
  isArmAccountRequest,
  isNotificationsRequest,
  isSupportedLanguagesRequest,
  isUserNotAuthenticatedError
} from '../../api/utils/request.function';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  private apimBase: string;
  private authorizationApiLocation: string;

  constructor(
    public logger: LoggerService,
    public auth: AuthService,
    public configService: ConfigService,
    private accessTokenService: AccessTokenService,
    private apiService: ApiService
  ) {
    this.apimBase = this.configService.Config?.api?.apimBase;
    this.authorizationApiLocation = this.configService.Config?.api?.authorizationApiLocation;
  }

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    // If its not api route or refresh token, continue
    if (
      this.isNotApimOrExtensionRequest(request) ||
      isAccessTokenRequest(request) ||
      (isArmAccountRequest(request) && !forceAccessToken(request)) ||
      isNotificationsRequest(request) ||
      isSupportedLanguagesRequest(request)
    ) {
      return next.handle(request);
    }

    const token = this.accessTokenService.getAccessTokenFromCache(request);
    if (token.includes('refresh')) {
      this.logger.log('[AccessTokenInterceptor] refresh token');
      return this.accessTokenService.accessTokenAuthorizationHandler(request, next);
    }

    return next.handle(this.accessTokenService.addAuthenticationToken(request)).pipe(
      catchError(error => {
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401 || (isUserNotAuthenticatedError(error) && !this.auth.isEmbedMode())) {
          this.logger.log(`[AccessTokenInterceptor] not 401 error: ${error.status}`);
          // eslint-disable-next-line deprecation/deprecation
          return throwError(error);
        }

        return this.accessTokenService.accessTokenAuthorizationHandler(request, next);
      })
    );
  }

  private isNotApimOrExtensionRequest(request: HttpRequest<object>): boolean {
    return this.apiService.edgeExtensionApiUrl?.length
      ? !request.url.includes(this.apiService.edgeExtensionApiUrl) &&
          !request.url.includes(this.apiService.baseApi) &&
          !request.url.includes(this.authorizationApiLocation)
      : !request.url.includes(this.apiService.baseApi) && !request.url.includes(this.authorizationApiLocation);
  }
}
