import { ISortState } from '@common/modules/shared/components/sort-menu';

export enum IUICellNameType {
  FacesModelName = 'FacesModelName',
  FacesModelCounter = 'FacesModelCounter',
  FacesModelActionBar = 'FacesModelActionBar',
  FacesModelSort = 'FacesModelSort',
  FacesModelExpanded = 'FacesModelExpanded',
  FacesName = 'FacesName',
  FacesScoreCol = 'FacesScoreCol',
  FacesUpdatedBy = 'FacesUpdatedBy',
  FacesLastUpdated = 'FacesLastUpdated',
  FacesActionBar = 'FacesActionBar',
  FacesMerge = 'FacesMerge'
}

export const emptyHeaderTitleCells = [IUICellNameType.FacesMerge];

export const displayedPeopleModelsColumns = {
  FacesModelName: '',
  FacesModelCounter: '',
  FacesModelSort: '',
  FacesModelActionBar: '',
  FacesModelExpanded: ''
};

// Expanded rows
export const displayedPeopleColumns = {
  FacesMerge: '',
  FacesName: '',
  FacesActionBar: '',
  FacesUpdatedBy: '',
  FacesLastUpdated: ''
};

// The order is determined by the object key order
// To be merge with displayedPeopleColumns when ISA FS is removed
export const displayedPeopleColumnsISA = {
  FacesMerge: '',
  FacesName: '',
  FacesActionBar: '',
  FacesScoreCol: '',
  FacesUpdatedBy: '',
  FacesLastUpdated: ''
};

export interface SortableColumn {
  column: IUICellNameType;
  state: ISortState;
}

export interface IPeopleModelsSort {
  [modelId: string]: SortableColumn[];
}

export const PeopleModelsSortParamName = {
  FacesName: 'name',
  FacesScoreCol: 'score'
};

export enum PersonActionId {
  DIALOG_DELETE_PEOPLE = 'DIALOG_DELETE_PEOPLE',
  DELETE_MODEL = 'DELETE_MODEL',
  DIALOG_DELETE_FACE = 'DIALOG_DELETE_FACE',
  UPLOAD_FACES = 'UPLOAD_FACES'
}
