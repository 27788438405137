import { IHttpWrapper, IAPIRequestParams, IAPIPostFaces, IAPIGetFaces, IAPIPostFace } from '../../interfaces';
import { getRequestSettings } from '../../utils/request.function';

export class CustomizationFacesApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/{personId}/Faces")]
  public getList(accountId: string, personModelId: string, personId: string, reqParams?: IAPIGetFaces, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels/${personModelId}/persons/${personId}/faces`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.ListFacesResponse>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/{personId}/Faces/{faceId}")]
  public getFaceBytes(accountId: string, personModelId: string, personId: string, faceId: string, accessToken: string) {
    const url = `${this.apiOperationBase}/${accountId}
    /Customization/PersonModels/${personModelId}/Persons/${personId}/Faces/${faceId}/?accessToken=${accessToken}`;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.Face[]>(url);
  }

  public getFacesSpriteUrl(
    accountId: string,
    personModelId: string,
    personId: string,
    accessToken: string,
    pageSize: number,
    skip: number,
    sourceType?: Microsoft.VideoIndexer.Contracts.KnownPersonFaceSourceType
  ) {
    let sourceTypeParam = ``;
    if (sourceType !== undefined) {
      sourceTypeParam = `&sourceType=${sourceType}`;
    }

    // Total is for cache use only
    // eslint-disable-next-line max-len
    return `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}/Faces/sprite/?total=${skip}&pageSize=${pageSize}&skip=${skip}${sourceTypeParam}&accessToken=${accessToken}`;
  }

  public getFaceUrl(accountId: string, personModelId: string, personId: string, faceId: string, accessToken: string) {
    // eslint-disable-next-line max-len
    return `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}/Faces/${faceId}/?accessToken=${accessToken}`;
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/{personId}/Faces")]
  public create(
    accountId: string,
    personModelId: string,
    personId: string,
    files: FormData | IAPIPostFace,
    reqParams?: IAPIPostFaces,
    reqSettings?: object
  ) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}/Faces`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams);

    // Set header for uploading file - In case there is a content type type of multipart, do not change the header.
    // It will change itself by default
    requestSettings['headers'] = {
      ...requestSettings['headers'],
      Accept: 'application/json'
    };

    return this.ApiWrapper.HttpClient.post<string[]>(url, files, requestSettings as object);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/{personModelId}/Persons/{personId}/Faces/{faceId}")]
  public delete(accountId: string, personModelId: string, personId: string, faceId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}/Faces/${faceId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<Microsoft.VideoIndexer.Contracts.Face>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/{personId}/Faces/{faceId}")]
  public put(accountId: string, personModelId: string, personId: string, faceId: string, reqParams?: IAPIRequestParams, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}/Faces/${faceId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.Face>(url, requestSettings);
  }
}
