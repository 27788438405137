import { createAction, props } from '@ngrx/store';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export const clearGallery = createAction('[Gallery] Clear Gallery');
export const loadCurrentTab = createAction('[Gallery] Load Current Gallery Tab');
export const loadNext = createAction('[Gallery/API] Load Next');
export const loadProcessingVideo = createAction('[Gallery/API] Load Processing Videos', props<{ video: SinglePlaylistSearchResultV2 }>());
export const loadProcessingVideoDone = createAction('[Gallery] Load Processing Video Done', props<{ video: SinglePlaylistSearchResultV2 }>());
export const loadProcessingVideoOrProjectById = createAction('[Gallery] Load Processing Video Or Project By Id', props<{ id: string }>());
export const announceProcessVideos = createAction('[Gallery] Announce processing videos');
