import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { EMPTY } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@common/modules/api/services/api.service';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { IState } from '../../core/reducers';
import * as fromCore from '../../core/selectors';
import * as LogoGroupsActions from '../actions/logo-groups.actions';
import * as fromCustomizationData from '../selectors';

@Injectable()
export class LogoGroupsEffects {
  public loadLogoGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoGroupsActions.logoGroupsLoad),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCustomizationData.isLogoGroupsLoaded)),
      switchMap(([[, accountId], isLoaded]) => {
        this.logger.log('[LogoGroupsEffects] load logo groups');
        // Return empty in case the groups already loaded in the store.
        if (isLoaded) {
          return EMPTY;
        }

        this.store.dispatch(LogoGroupsActions.loadLogoGroupsInProgress());

        return this.apiService.Account.Customization.LogoGroups.list(accountId).pipe(
          switchMap((result: Microsoft.VideoIndexer.Contracts.Customization.LogoGroupContract[]) => {
            this.trackService.track('logo_groups.load.success', {
              category: EventCategory.CUSTOMIZATION
            });
            return [LogoGroupsActions.loadLogoGroupsSuccess({ groups: result })];
          }),
          catchError((error: HttpErrorResponse) => {
            this.trackService.track('logo_groups.load.failed', {
              category: EventCategory.CUSTOMIZATION
            });
            return [LogoGroupsActions.loadLogoGroupFailed()];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private logger: LoggerService,
    private trackService: TrackService
  ) {}
}
