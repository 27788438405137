import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, filter, withLatestFrom, switchMap } from 'rxjs/operators';

import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { CoreStoreService } from '../services/core-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export const NetworkAccessGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(NetworkAccessGuardService).canActivate(next);
};

@Injectable({
  providedIn: 'root'
})
export class NetworkAccessGuardService {
  constructor(private coreStore: CoreStoreService, private trackService: TrackService) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.coreStore.userDetailsLoaded$.pipe(
      filter(loaded => loaded),
      withLatestFrom(this.coreStore.getSignedInUserEmail$),
      switchMap(([, SignedInUserEmail]) => {
        // If user is signed in, check if network access is not allowed, if so, redirect to gallery library
        if (SignedInUserEmail) {
          return this.coreStore.selectedAccountLoaded$.pipe(
            filter(loaded => loaded),
            withLatestFrom(this.coreStore.isNetworkAccessNotAllowedError$),
            withLatestFrom(this.coreStore.selectedAccountId$),
            map(([[, isNetworkAccessNotAllowed], selectedAccountId]) => {
              const urlAccountId = next?.params?.accountId;
              this.trackService.track('network_access.guard.can_activate', {
                category: EventCategory.HTTP,
                data: {
                  blocked: isNetworkAccessNotAllowed,
                  selectedAccountId,
                  urlAccountId
                }
              });

              if (isNetworkAccessNotAllowed && (!urlAccountId || selectedAccountId === urlAccountId)) {
                this.handleNetworkAccessNotAllowed();
                return false;
              }

              return true;
            })
          );
        }
        return of(true);
      })
    );
  }

  private handleNetworkAccessNotAllowed() {
    this.coreStore.navigate([`/${VIRoutingMap.mediaGallery.path}/${VIRoutingMap.galleryLibrary.path}`]);
  }
}
