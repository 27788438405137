import { Observable } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

export enum ProgressTitleType {
  MULTIPLE_IN_PROGRESS = 'MULTIPLE_IN_PROGRESS',
  SINGLE_IN_PROGRESS = 'SINGLE_IN_PROGRESS',
  SINGLE_FAILED = 'SINGLE_FAILED',
  MULTIPLE_SUCCESS = 'MULTIPLE_SUCCESS',
  SINGLE_SUCCESS = 'SINGLE_SUCCESS',
  SINGLE_SUCCESS_WITH_FAILED = 'SINGLE_SUCCESS_WITH_FAILED',
  ALL_SUCCESS = 'ALL_SUCCESS',
  ALL_FAILED = 'ALL_FAILED'
}

export interface IUploadErrorStrip {
  show: Observable<boolean>;
  type: AlertMessageType;
  content: string;
  linkText: string;
  isButton: boolean;
  onCLick: () => void;
}
