import { Directive, ElementRef, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// eslint-disable-next-line deprecation/deprecation
import { CdkTrapFocus, FocusTrapFactory } from '@angular/cdk/a11y';
@Directive({
  selector: '[appCdkTrapFocus]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AccessibleFocusTrap extends CdkTrapFocus implements OnChanges {
  @Input('appCdkTrapFocus') public AppCdkFocusTrap: boolean;
  // eslint-disable-next-line deprecation/deprecation
  constructor(@Inject(DOCUMENT) private document: Document, public elem: ElementRef, _focusTrapFactory: FocusTrapFactory) {
    super(elem, _focusTrapFactory, document);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.AppCdkFocusTrap) {
      this.focusTrap.enabled = this.AppCdkFocusTrap;
      this.setAriaHidden();
    }
  }

  private setAriaHidden(value = false) {
    setTimeout(() => {
      const focusTraps = this.elem.nativeElement?.parentNode?.querySelectorAll('.cdk-focus-trap-anchor');
      focusTraps?.forEach(elem => elem.setAttribute('aria-hidden', value));
    });
  }
}
