import { Injectable, Injector } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, take, tap } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { IDialogButton, IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IInvite } from '@common/modules/core/services/interfaces';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { TrackService } from '@common/modules/core/services/track';

import { VIRoutingMap } from '../../app/routing/routes';
import { resources } from '../resources';
import * as actions from '../actions';
import * as RouterActions from '../actions/router.actions';
import * as fromUser from '../reducers/user.reducer';
import * as InvitationsActions from '../actions/invitations.actions';

@Injectable()
export class CoreEffects {
  public sessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.sessionExpired),
        tap(() => {
          this.openSessionExpiredDialog();
        })
      ),
    {
      dispatch: false
    }
  );

  public loadChunksError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadChunksError),
        tap(() => {
          this.openInternalErrorReloadDialog();
        })
      ),
    {
      dispatch: false
    }
  );

  public openShareAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvitationsActions.openShareAccount),
      switchMap(({ showInviteForm }) => {
        const inviteData: IInvite = {
          id: 'inviteContent',
          showForm: showInviteForm
        };
        this.openInviteDialog(inviteData);
        return [InvitationsActions.loadInvitations()];
      })
    )
  );

  public accountHasRestrictedAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.accountHasRestrictedAccess),
        tap(() => {
          this.openHasRestrictedAccessDialog();
          this.trackService.track('restricted_viewer.has_no_access_pop_up');
        })
      ),
    {
      dispatch: false
    }
  );

  private resources = resources;

  constructor(
    private logger: LoggerService,
    private actions$: Actions,
    private store: Store<fromUser.IState>,
    private dialogService: DialogService,
    private injector: Injector,
    private trackService: TrackService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private init() {
    // translation
    const translationService = this.injector.get(TranslateHelperService);
    translationService
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {});
    this.logger.log('[CoreEffects] On Init Effects');
  }

  private openSessionExpiredDialog() {
    const button: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: this.resources.SessionExpiredButton,
        value: null,
        id: 'sessionExpired_btn'
      }
    };
    const dialogData = this.dialogService.getDialogData(
      'session-expired-dialog',
      this.resources.SessionExpiredTitle,
      this.resources.SessionExpiredContent,
      null,
      button
    );

    this.dialogService.closeAllDialogs(this.resources.ForceCloseDialog);

    const dialogRef = this.dialogService.openDialog(dialogData, '440px', '212px', 'session-expired-container');

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(
          RouterActions.Go({
            path: [VIRoutingMap.login.path],
            queryParams: null,
            extras: null,
            removeParams: true
          })
        );
      });
  }

  private openHasRestrictedAccessDialog() {
    const button: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: this.resources.Close,
        value: null,
        id: 'close_btn'
      }
    };
    const dialogData = this.dialogService.getDialogData(
      'has-no-access-dialog',
      this.resources.RestrictedViewerPopupTitle,
      this.resources.RestrictedViewerPopupContent,
      null,
      button
    );

    const dialogWidth = '440px';
    const dialogHeight = '240px';
    const dialogPanelClass = 'has-no-access-container';

    this.dialogService.openDialog(dialogData, dialogWidth, dialogHeight, dialogPanelClass);
  }

  private openInternalErrorReloadDialog() {
    const button: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: this.resources.InternalErrorReloadButton,
        value: null,
        id: 'InternalErrorReload_btn'
      }
    };
    const dialogData = this.dialogService.getDialogData(
      'internal-error-reload-dialog',
      this.resources.InternalErrorReloadTitle,
      this.resources.InternalErrorReloadContent,
      null,
      button
    );

    this.dialogService.closeAllDialogs(this.resources.ForceCloseDialog);

    const dialogRef = this.dialogService.openDialog(dialogData, '440px', '212px', 'internal-error-reload-container');

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        window.location.reload();
      });
  }

  private async openInviteDialog(inviteData: IInvite) {
    const button: IDialogButton = {
      type: ActionButtonType.SECONDARY,
      action: {
        title: resources.Close,
        value: null,
        id: 'cancelInviteBtn'
      }
    };

    const { InviteComponent } = await import('../../invite/components');

    const inviteDialogData: IDialogData = {
      class: 'invite-dialog',
      title: resources.InviteHeader,
      component: InviteComponent,
      componentData: inviteData,
      secondaryButton: button
    };
    this.dialogService.openDialog(inviteDialogData, '540px', 'auto', 'vi-invite', true);
  }
}
