import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { cloneDeep } from 'lodash-es';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { InsightsCommonUtilsService } from '@common/modules/insights-common/insights-common-utils.service';
import { UIActionType } from '@common/modules/insights/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { UnknownPersonService } from '../../../../../customization-data/services/people/unknown-person.service';
import { resources } from './resources';
import { IComponentData, IComponentState, IUnknownPersonFacesAction } from './interfaces';
import { loadAction, cancelAction, moveToModelAction, facesActions, barActions } from './actions';

import UnknownPersonFace = Microsoft.VideoIndexer.Contracts.UnknownPersonFace;
import UnknownPersonDetails = Microsoft.VideoIndexer.Contracts.UnknownPersonDetails;

@Component({
  selector: 'vi-unknown-person-details-dialog',
  templateUrl: './unknown-person-details-dialog.component.html',
  styleUrls: ['./unknown-person-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnknownPersonDetailsDialogComponent implements OnInit, OnDestroy {
  @Input() public data: IComponentData;
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public fallbackUrl: string;
  public state: IComponentState;
  public ActionButtonType = ActionButtonType;
  public barActions = cloneDeep(barActions);
  public facesActions = cloneDeep(facesActions);
  public loadFacesAction = cloneDeep(loadAction);
  public moveToModelAction = cloneDeep(moveToModelAction);
  public cancelAction = cloneDeep(cancelAction);
  public unknownPerson: UnknownPersonDetails;
  public faces: IUnknownPersonFacesAction[] = [];
  public resources = resources;
  public FACE_PAGE_SIZE = 25;
  public facesPageIndex = 1;
  public METADATA_MAX_LENGTH = 1000;
  public METADATA_INPUT_HEIGHT = '132px';
  public METADATA_BUTTON_WIDTH = '82px';
  public CANCEL_BUTTON_MIN_WIDTH = '138px';
  public PRIMARY_BUTTON_MIN_WIDTH = '75px';

  private destroy$ = new Subject<void>();

  constructor(
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private translateService: TranslateHelperService,
    private unknownPersonService: UnknownPersonService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.initTranslations();

    this.state = {
      moveToModel: false,
      editMetadata: false,
      metadata: '',
      personName: ''
    };

    this.fallbackUrl = this.insightsCommonUtilsService.FaceFallbackUrl;

    this.data.unknownPerson$.pipe(takeUntil(this.destroy$)).subscribe(unknownPerson => {
      this.unknownPerson = unknownPerson;
      this.initFaces(unknownPerson);
      this.cdr.detectChanges();
    });
  }

  public get loadedFaces() {
    return this.faces?.slice(0, this.loadedFacesCount);
  }

  public get facesCount() {
    return this.faces?.length;
  }

  public get loadedFacesCount() {
    return this.FACE_PAGE_SIZE * this.facesPageIndex;
  }

  public get showLoadMoreFaces() {
    return this.facesCount > this.loadedFacesCount;
  }

  public get canSaveMetadata() {
    return this.state.metadata !== this.unknownPerson?.metadata;
  }

  public get canMoveToModel() {
    return this.state.personName;
  }

  public onFacesActionClicked(action: IAction, data: UnknownPersonFace | IAction | null | IUnknownPersonFacesAction) {
    if (!action) {
      return;
    }
    switch (action.type) {
      case UIActionType.NAVIGATE:
        window.open(`/accounts/${this.data.accountId}/videos/${(data as UnknownPersonFace).videoId}`, '_blank');
        break;
      case UIActionType.DELETE_FACE:
        action.value = { unknownPersonId: this.unknownPerson?.id, faceId: (data as IUnknownPersonFacesAction).originalId };
        this.componentEventEmitter.emit({
          isDialogClose: false,
          action
        });
        break;
      case UIActionType.OPEN:
        window.open((data as IAction).value, '_blank');
        break;
    }
  }

  public loadMoreFaces() {
    this.facesPageIndex++;
    this.loadFacesAction = cloneDeep(loadAction);
    this.loadFacesAction.title = `+ ${this.facesCount - this.loadedFacesCount}`;
  }

  public saveMetadata() {
    // @TODO: implement
  }

  public resetMetadata() {
    this.state.editMetadata = false;
    this.state.metadata = this.unknownPerson?.metadata;
  }

  public onActionBarClicked(action: IAction) {
    switch (action.type) {
      case UIActionType.EDIT_METADATA:
        this.state.editMetadata = !this.state.editMetadata;
        break;
      case UIActionType.TOGGLE:
        this.state.moveToModel = !this.state.moveToModel;
        if (this.state.moveToModel) {
          action.isDisabled = true;
        }
        break;
      case UIActionType.DELETE_PERSON:
        this.componentEventEmitter.emit({
          isDialogClose: false,
          action
        });
        break;
    }
  }

  public moveToModel(action: IAction) {
    const payload = {
      unknownPerson: this.unknownPerson,
      personName: this.state.personName
    };

    this.componentEventEmitter.emit({
      isDialogClose: false,
      action: { ...action, value: payload }
    });
  }

  public close(action: IAction) {
    this.componentEventEmitter.emit({
      isDialogClose: true,
      action
    });
  }

  private initFaces(unknownPerson: UnknownPersonDetails) {
    this.faces = unknownPerson?.faces.map(
      (face, index) =>
        ({
          id: `face_${face.id}`,
          originalId: face.id,
          value: this.unknownPersonService.getThumbnailUrlWithToken(this.data.accountId, face.videoId, face.thumbnailId),
          title: '',
          titleAttribute: `face_${index + 1}`,
          type: UIActionType.IMAGE,
          videoId: face.videoId
        } as IUnknownPersonFacesAction)
    );

    this.loadFacesAction.title = `+ ${this.facesCount - this.loadedFacesCount}`;
  }

  private initTranslations() {
    this.translateService.translateResourcesInstant(resources);

    [this.moveToModelAction, this.cancelAction, ...this.barActions, ...this.facesActions].forEach(
      action => (action.title = this.resources[action.key])
    );
  }
}
