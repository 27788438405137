<span href="javascript:void(0)"
  class="add"
  [attr.tabindex]="activeContainer ? -1 : 0"
  (appAccessibleClick)="changeState(null, $event)"
  (keyup.esc)="reset()"
  role="button"
  id="addButton"
  [ngSwitch]="addCapsuleMode"
  [attr.title]="text"
  [attr.aria-label]="text">

  <i class="i-vi-add"></i>

  <span *ngSwitchCase="UIAddCapsuleMode.DEFAULT"
    [title]="text"
    class="add-text"
    aria-hidden="true"
    role="none">{{ text }}</span>
  <span *ngSwitchCase="UIAddCapsuleMode.ADDING"
    [title]="resources?.CapsuleInputAdding"
    aria-hidden="true"
    role="none">{{ resources?.CapsuleInputAdding }}</span>

  <input #addCapsuleInput
    *ngSwitchCase="UIAddCapsuleMode.CLICKED"
    (mouseup)="handleInputClick($event)"
    (keyup.enter)="add(addCapsuleInput.value)"
    placeholder="{{ EnterText }}"
    autocomplete="off"
    type="text"
    [attr.title]="EnterText"
    [attr.tabindex]="activeContainer ? -1 : 0">
</span>
