import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';

export const getInvitationsState = createSelector(selectCoreState, state => state.invitations);

export const selectInvitationLoaded = createSelector(getInvitationsState, state => state.loaded);

export const selectInvitationsError = createSelector(getInvitationsState, state => state.error);

export const selectInviteSaving = createSelector(getInvitationsState, state => state.saving);

export const selectInvitations = createSelector(getInvitationsState, state => state.entities);

export const selectInvitationsIds = createSelector(getInvitationsState, state => state.ids);

export const selectInvitationsLength = createSelector(selectInvitationsIds, ids => ids?.length);

export const selectUIInvitations = createSelector(selectInvitations, invitations => {
  return Object.values(invitations).map(invitation => {
    return {
      email: invitation.email,
      invitationId: invitation.invitationId,
      removing: false,
      role: invitation.role
    };
  });
});
