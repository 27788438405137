import { IHttpWrapper, IAPIGetPeople, IAPIPeopleCustomization, IRequestParam } from '../../interfaces';
import { concatParamsListToURL, getRequestSettings } from '../../utils/request.function';
import { environment } from '../../../../../environments/environment';

export class CustomizationPeopleApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  private get apiInternalBase(): string {
    return this.ApiWrapper.GetInternalApiBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route("api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons")]
  public getList(accountId: string, personModelId: string, reqParams?: IAPIGetPeople, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels/${personModelId}/persons`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.ListPersonsResponse>(url, requestSettings);
  }

  public getTestPeopleByModel() {
    let base = '';
    if (environment.production && window.viewContext) {
      base = window.viewContext.codeLocation;
    }
    const url = `${base}assets/testPersons.json`;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.Person[]>(url);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons")]
  public create(accountId: string, personModelId: string, reqParams?: IAPIPeopleCustomization, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/persons`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.Person>(url, null, requestSettings);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/Merge[?name][&personId]")]
  public merge(accountId: string, personModelId: string, paramsList?: IRequestParam[], reqSettings?: object) {
    let url = `${this.apiInternalBase}/${accountId}/Customization/PersonModels/${personModelId}/persons/merge`;
    url = concatParamsListToURL(url, paramsList);
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.Person>(url, null, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/{personModelId}/Persons/{personId}")]
  public delete(accountId: string, personModelId: string, personId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<Microsoft.VideoIndexer.Contracts.Person>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}/Persons/{personId}")]
  public put(accountId: string, personModelId: string, personId: string, reqParams?: IAPIPeopleCustomization, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/PersonModels/${personModelId}/Persons/${personId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.Person>(url, null, requestSettings);
  }
}
