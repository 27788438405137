import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';

@Component({
  selector: 'app-vi-spinner',
  templateUrl: './spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  // Privates
  @Input() public size = 'small';
  @Input() public invert = false;
  @Input() public label?: string;
  @Input() public labelSize?: string = 'normal';
  public resources = resources;

  constructor(private translate: TranslateHelperService) {}

  public ngOnInit() {
    // Translate resources
    this.translate.translateResources(this.resources);
  }
}
