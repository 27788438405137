<div class="container"
  role="region"
  [attr.aria-hidden]="!(navigationState  === NavigationState.OPEN)"
  [@pageInOut]="(navigationState === NavigationState.OPEN) ? 'in' : 'out'"
  [ngClass]="{'show-display': (navigationState === NavigationState.OPEN), 'accounts-list': (content === SidebarPages.ACCOUNTS)}">
  <div [appCdkTrapFocus]="navigationState === NavigationState.OPEN"
    *ngIf="(navigationState === NavigationState.OPEN)">
    <div class="sidebar-header">
      <div class="title"
        [ngSwitch]="content">
        <h2 *ngSwitchCase="SidebarPages.HELP">
          {{resources.HeaderHelpLinkAria}}
        </h2>
        <h2 *ngSwitchCase="SidebarPages.ACCOUNTS">
          {{resources.SidebarAccounts}}
        </h2>
        <h2 *ngSwitchCase="SidebarPages.SETTINGS">
          {{resources.SidebarUserSettings}}
        </h2>
        <h2 *ngSwitchCase="SidebarPages.NOTIFICATIONS">
          {{resources.SidebarUserNotifications}}
        </h2>
      </div>
      <app-vi-action-button id="viSidebarClose"
        class="close-sidebar"
        [action]="closeAction"
        (actionClick)="close()">
      </app-vi-action-button>
    </div>
    <div class="content"
      [ngSwitch]="content"
      [@contentInOut]="contentInAnimation? 'in' : 'out'">
      <app-vi-help *ngSwitchCase="SidebarPages.HELP"
        class="content-in"
        (openAbout)="handleOpenAbout()"
        [ngClass]="{accessible: accessibilityMode}">
      </app-vi-help>

      <app-accounts-list *ngSwitchCase="SidebarPages.ACCOUNTS"
        class="content-in"
        (close)="close()"
        [ngClass]="{accessible: accessibilityMode}">
      </app-accounts-list>

      <app-user-settings *ngSwitchCase="SidebarPages.SETTINGS"
        class="content-in">
      </app-user-settings>

      <vi-notifications-panel *ngSwitchCase="SidebarPages.NOTIFICATIONS"
        class="content-in">
      </vi-notifications-panel>
    </div>
  </div>
</div>
