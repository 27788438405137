import { Component, ChangeDetectionStrategy, QueryList, OnDestroy, Input, ContentChildren, AfterContentInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { AccordionItemComponent } from './../accordion-item/accordion-item.component';
import { AccordionMode } from './interfaces';

@Component({
  selector: 'vi-ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
  @Input() mode = AccordionMode.DEFAULT;

  @ContentChildren(AccordionItemComponent) contentChildren: QueryList<AccordionItemComponent>;
  private openedItemId: number;
  private destroy$ = new Subject<void>();
  private accordionItemListener$: Subject<void>;

  constructor() {}

  public ngOnDestroy(): void {
    this.stopItemsListeners();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterContentInit() {
    this.setListeners();
    this.contentChildren.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleContentChanges();
    });
  }

  private handleContentChanges(): void {
    // stop listeners for old content children
    if (this.accordionItemListener$) {
      this.stopItemsListeners();
    }
    // add listeners to the relevant items in the accordion
    this.setListeners();
  }

  private setListeners() {
    this.accordionItemListener$ = new Subject<void>();

    this.contentChildren?.forEach((item: AccordionItemComponent, index: number) => {
      item.toggle.pipe(takeUntil(this.accordionItemListener$)).subscribe((value: boolean) => {
        this.handleClickedItem(item, value, index);
      });
    });
  }

  private handleClickedItem(item: AccordionItemComponent, value: boolean, index: number) {
    // default can open selected item only if it is closed
    const isItemNotOpened = value && index !== this.openedItemId;
    // other modes can close selected  item
    if (!this.isDefaultMode || isItemNotOpened) {
      item.opened = value;
      this.openedItemId = index;
      // multiple mode allow having multiple opened items
      if (!this.isMultipleMode) {
        this.closeNotSelectedItems();
      }
    }
  }

  private closeNotSelectedItems() {
    this.contentChildren?.forEach((item: AccordionItemComponent, index: number) => {
      if (index !== this.openedItemId) {
        item.opened = false;
      }
    });
  }

  private get isDefaultMode() {
    return this.mode === AccordionMode.DEFAULT;
  }

  private get isMultipleMode() {
    return this.mode === AccordionMode.MULTIPLE;
  }

  private stopItemsListeners() {
    this.accordionItemListener$.next();
    this.accordionItemListener$.complete();
  }
}
