import { Injectable, NgZone } from '@angular/core';

import { TrackService } from '../track';

@Injectable()
export class ZoneWrapperService {
  constructor(private zone: NgZone, private track: TrackService) {}

  public runOutsideAngular(callback: () => void, onError = _ => null) {
    try {
      this.zone.runOutsideAngular(callback);
    } catch (error) {
      this.track.trackError(error);
      onError(error);
    }
  }

  public run(callback: () => void, onError = _ => null) {
    try {
      this.zone.run(callback);
    } catch (error) {
      this.track.trackError(error);
      onError(error);
    }
  }
}
