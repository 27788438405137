import { Observable } from 'rxjs';

import { IHttpWrapper, IAPIPostPeopleModel, IAPIGetPeopleModel } from '../../interfaces';
import { getRequestSettings } from '../../utils/request.function';
import { environment } from '../../../../../environments/environment';

export class CustomizationPeopleModelsApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels")]
  public getList(accountId: string, reqParams?: IAPIGetPeopleModel, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.PersonModel[]>(url, requestSettings);
  }

  public getTestPeopleModels(): Observable<Microsoft.VideoIndexer.Contracts.ListPersonModelsResponse> {
    let base = '';
    if (environment.production && window.viewContext) {
      base = window.viewContext.codeLocation;
    }
    const url = `${base}assets/testPersonModels.json`;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.ListPersonModelsResponse>(url);
  }

  // [HttpPost]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels")]
  // Update person model name
  public post(accountId: string, reqParams?: IAPIPostPeopleModel, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.post<Microsoft.VideoIndexer.Contracts.PersonModel>(url, null, requestSettings);
  }

  // [HttpPut]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}")]
  public put(accountId: string, personModelId: string, reqParams?: IAPIPostPeopleModel, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels/${personModelId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, reqParams) as object;

    return this.ApiWrapper.HttpClient.put<Microsoft.VideoIndexer.Contracts.PersonModel>(url, null, requestSettings);
  }

  // [HttpDelete]
  // [Route("Api/v2/Accounts/{accountId}/Customization/PersonModels/{personModelId}")]
  public delete(accountId: string, personModelId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/customization/personModels/${personModelId}`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    return this.ApiWrapper.HttpClient.delete<Microsoft.VideoIndexer.Contracts.PersonModel>(url, requestSettings);
  }
}
