import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';
import { EventCategory } from '@common/modules/core/services/track/interfaces';

import { TrackService } from './../../../../../common/modules/core/services/track/track.service';
import { CoreStoreService } from '../services/core-store.service';
import { VIRoutingMap } from '../../app/routing/routes';

export const AmsMigrationReportGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AmsMigrationReportGuardService).canActivate();
};

@Injectable({
  providedIn: 'root'
})
export class AmsMigrationReportGuardService {
  constructor(private featureSwitchService: FeatureSwitchService, private coreService: CoreStoreService, private trackService: TrackService) {}

  canActivate(): Observable<boolean> {
    const allowed = this.featureSwitchService.featureSwitch(FeatureSwitch.MigrationReport);
    if (!allowed) {
      this.handleNotAllowedStatus('MigrationReport Feature switch is off');
    }
    return of(allowed);
  }

  private handleNotAllowedStatus(error?) {
    this.trackService.track('ams.migration.page.guard.can_activate.failed', {
      category: EventCategory.AMS_MIGRATION,
      data: { error }
    });
    this.coreService.navigate([`${VIRoutingMap.pageNotFoundError}`]);
  }
}
