import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { getDateString } from '@common/modules/utils/time';
import { INotification } from '@common/modules/core/services/toast/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

@Component({
  selector: 'vi-notification-card',
  templateUrl: './notification-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: INotification;

  public progressAriaLabel: string;
  constructor(private trackService: TrackService, private translate: TranslateHelperService) {}

  public ngOnInit(): void {
    this.progressAriaLabel = this.translate.instant('Progress');
  }
  public getDateString() {
    if (!this.notification?.startDate) {
      return '';
    }

    return getDateString(new Date(this.notification?.startDate));
  }

  public linkClicked() {
    this.trackService.track('notifications.card.open_link', {
      category: EventCategory.NOTIFICATIONS,
      data: {
        notificationId: this.notification.id,
        link: this.notification?.link?.src
      }
    });
    if (this.notification.link.callback) {
      this.notification.link.callback();
    } else if (this.notification.link.src) {
      window.open(this.notification.link?.src, '_blank');
    }
  }

  public get progressText() {
    return this.translate.instant('PercentageComplete', { percentage: this.notification?.progress });
  }
}
