import { capitalize } from 'lodash-es';

import { JWTTokenPattern, msCropEmailPattern, msCorpFullNameEmailPattern } from './regexes';

export function formatFileName(fileName, fileType): string {
  return `${fileName.replace(/\s/g, '_')}.${fileType}`;
}

export function getFileNameWithoutExtension(filename: string) {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

export function isEmptyGuid(comparable: string): boolean {
  return comparable === guid(0);
}

export function guid(zero?: 0): string {
  if (zero === 0) {
    return '00000000-0000-0000-0000-000000000000';
  }

  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function capitalizeWithNumericPrefix(currentString: string): string {
  // a-zA-Z - Latin alphabet
  // а-яА-Я - Cyrillic letters
  // First, trim white spaces
  currentString = currentString?.trim();
  const regExp = new RegExp(/^\d/g);
  const regExpValue = regExp.exec(currentString);
  // Split the string according to first word if defined regExp
  return regExpValue ? currentString.toLowerCase() : capitalize(currentString);
}

export function padWithZero(d: number): string {
  return d < 10 ? '0' + d.toString() : d.toString();
}

// eslint-disable-next-line max-len
const EMAIL_REGEX: RegExp = new RegExp(
  // eslint-disable-next-line max-len
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export function isEmail(text: string): boolean {
  return EMAIL_REGEX.test(text);
}

export function removeHtmlTags(text: string) {
  return text.replace(/<[^>]*>/g, '');
}

export function isFriendlyMsCorpEmailAddress(email: string): boolean {
  const friendlyMsCropEmailRegex: RegExp = new RegExp(msCorpFullNameEmailPattern);
  return friendlyMsCropEmailRegex.test(email);
}

export function isMsCorpEmailAddress(email: string): boolean {
  const friendlyMsCropEmailRegex: RegExp = new RegExp(msCropEmailPattern);
  return friendlyMsCropEmailRegex.test(email);
}

export function zeroPad(num: number, places: number) {
  return String(num).padStart(places, '0');
}

export function replaceJWTToken(originalString: string) {
  return originalString?.replace(new RegExp(`${JWTTokenPattern}`, 'g'), '_REDACTED_ACCESS_TOKEN');
}
