/* eslint-disable */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TrackService } from '../core/services/track';
import { IUILanguage } from '../insights/components/action-bar/interfaces';
import { BoundingBoxType } from '../player/components/player/Plugins/body-tracking/interfaces';
import { BoundingBoxPropMsgName } from './interfaces';

@Injectable()
export class CommunicationService {
  public changeLanguageSubject: Subject<IUILanguage>;
  public jumpToSubject: Subject<number>;
  public refreshTracksSubject: Subject<void>;
  public timeUpdateSubject: Subject<number>;
  public pauseSubject: Subject<void>;
  public markSelectedPersonSubject: BehaviorSubject<number>;
  public markSelectedFaceSubject: BehaviorSubject<number>;
  public markSelectedLogoSubject: BehaviorSubject<number>;
  public markSelectedDetectedObjectSubject: BehaviorSubject<number>;

  public onPlayerPausedSubject: Subject<number>;
  public onFaceEditingSubject: Subject<number>;
  constructor(private track: TrackService) {
    this.changeLanguageSubject = new Subject<IUILanguage>();
    this.jumpToSubject = new Subject<number>();
    this.timeUpdateSubject = new Subject<number>();
    this.refreshTracksSubject = new Subject<void>();
    this.pauseSubject = new Subject<void>();
    this.markSelectedPersonSubject = new BehaviorSubject<number>(null);
    this.markSelectedFaceSubject = new BehaviorSubject<number>(null);
    this.markSelectedDetectedObjectSubject = new BehaviorSubject<number>(null);
    this.onPlayerPausedSubject = new Subject<number>();
    this.onFaceEditingSubject = new Subject<number>();
  }

  public jumpTo(time: number): void {
    if (time === 0) {
      time = 0.1;
    }

    // If embedded with angular / not from iframe
    if (this.isIframe()) {
      // Use post message
      if ('postMessage' in window) {
        window.parent.postMessage({ time: time, origin: window.location.origin }, '*');
      }
    }
    this.jumpToSubject.next(time);
  }

  public markSelectedItem(bbType: BoundingBoxType, id: number): void {
    let propMsgName = '';
    switch (bbType) {
      case BoundingBoxType.BODY:
        propMsgName = BoundingBoxPropMsgName.PERSON_ID;
        this.markSelectedPersonSubject.next(id);
        break;
      case BoundingBoxType.FACE:
        propMsgName = BoundingBoxPropMsgName.FACE_ID;
        this.markSelectedFaceSubject?.next(id);
        break;
      case BoundingBoxType.OBJECT:
        propMsgName = BoundingBoxPropMsgName.OBJECT_ID;
        // TODO: add to insights and select the first as default.
        this.markSelectedDetectedObjectSubject?.next(id);
        break;
    }

    // If embedded with angular / not from iframe
    if (this.isIframe()) {
      // Use post message
      if ('postMessage' in window) {
        window.parent.postMessage({ [propMsgName]: id, origin: window.location.origin }, '*');
      }
    }
  }

  public refreshTracks(): void {
    // If embedded with angular / not from iframe
    if (this.isIframe()) {
      // Use post message
      if ('postMessage' in window) {
        window.parent.postMessage({ refreshTracks: true, origin: window.location.origin }, '*');
      }
    }
    this.refreshTracksSubject.next();
  }

  public faceUpdated(id: number): void {
    // If embedded with angular / not from iframe
    if (this.isIframe()) {
      // Use post message
      if ('postMessage' in window) {
        window.parent.postMessage({ face_updated_id: id, origin: window.location.origin }, '*');
      }
    }
    this.onFaceEditingSubject.next(id);
  }

  public pause(): void {
    // If embedded with angular / not from iframe
    if (this.isIframe()) {
      // Use post message
      if ('postMessage' in window) {
        window.parent.postMessage({ pause: true, origin: window.location.origin }, '*');
      }
    }
    this.pauseSubject.next();
  }

  public isIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public changeLanguage(languageObjToPlayer: IUILanguage) {
    if (!languageObjToPlayer) {
      return;
    }
    // Send to player through postMessage
    if ('postMessage' in window) {
      languageObjToPlayer.key = languageObjToPlayer.value;
      window.parent.postMessage({ language: languageObjToPlayer, origin: window.location.origin }, '*');
    }
    this.changeLanguageSubject.next(languageObjToPlayer);
  }

  public renderVideoAndDownload() {
    if ('postMessage' in window) {
      window.parent.postMessage({ renderAndDownload: '1', origin: window.location.origin }, '*');
    }
  }
}
