import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';

import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { CoreStoreService } from 'src/apps/web/src/core/services/core-store.service';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { guid } from '@common/modules/utils/string';
import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { AlertMessageType } from '@common/modules/shared/components/alert-message/interfaces';

import { resources } from './resources';
import { managePeopleModelsAction, peopleModelAction } from './actions';
import { IndexingStoreService } from '../../../../services/indexing-store.service';
import { CustomizationDataStoreService } from '../../../../../customization-data/services/customization-data-store.service';
import { FaceGateStripMode } from '../../indexing-face-gate-strip/interfaces';

@Component({
  selector: 'vi-indexing-settings-people-models',
  templateUrl: './indexing-settings-people-models.component.html',
  styleUrls: ['./indexing-settings-people-models.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSettingsPeopleModelsComponent implements OnInit, OnDestroy {
  @Input() public isDisabled = false;
  @Output() public managePeopleModelClicked = new EventEmitter<CustomizationPage>();
  @Output() public navigateToCreateAccount = new EventEmitter<void>();

  public AlertMessageType = AlertMessageType;
  public ActionButtonType = ActionButtonType;
  public isLoading: boolean;
  public isError: boolean;
  public selectedPeopleModelId: string;
  public resources = resources;
  public dropdownId: string = 'peopleModelsDropdown_' + guid();
  public peopleModelAction: IAction = peopleModelAction;
  public managePeopleModelsAction: IAction = managePeopleModelsAction;
  public peopleModelActions: IAction[] = [];
  public selectedPeopleModel: IAction = null;
  public showFaceGateWarning: boolean = false;
  public FaceGateStripMode = FaceGateStripMode;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private customizationDataStoreService: CustomizationDataStoreService,
    private coreStore: CoreStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.translate.translateResourcesInstant(this.resources);
    this.managePeopleModelsAction.title = this.resources.ManagePeopleModels;

    this.loadPeopleModels();

    this.indexingStore.selectedPeopleModel$.pipe(takeUntil(this.destroy$)).subscribe(selectedModel => {
      if (selectedModel) {
        this.selectedPeopleModelId = selectedModel.id;
        this.initSelectedPeopleModelId();
        this.cdr.detectChanges();
      }
    });

    this.coreStore.showFaceGateWarning$.pipe(takeUntil(this.destroy$)).subscribe(showFaceGateWarning => {
      this.showFaceGateWarning = showFaceGateWarning;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadPeopleModels() {
    this.customizationDataStoreService.peopleModels
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.customizationDataStoreService.isPeopleModelsLoaded()),
        withLatestFrom(this.customizationDataStoreService.peopleModelsError)
      )
      .subscribe(([[models, isLoaded], isError]) => {
        if (isLoaded) {
          this.peopleModelActions = [];

          if (isError) {
            this.isError = true;
          } else {
            models.map((model: Microsoft.VideoIndexer.Contracts.PersonModel) => {
              this.peopleModelActions.push({
                title: model.name,
                value: model.name,
                id: model.id
              });
            });
          }

          this.isLoading = false;
          this.initSelectedPeopleModelId();
          this.cdr.detectChanges();
        }
      });
  }

  public get isPeopleModelsDropdownDisable() {
    return this.isDisabled || this.isLoading || this.isError;
  }

  public onSelectPeopleModel() {
    this.indexingStore.updatePeopleModel(this.selectedPeopleModel?.id);
  }

  public onManagePeopleModels() {
    this.managePeopleModelClicked.emit(CustomizationPage.People);
  }

  public initSelectedPeopleModelId() {
    if (!this.selectedPeopleModelId || !this.peopleModelActions?.length) {
      return;
    }

    this.selectedPeopleModel = this.peopleModelActions.find(model => model.id === this.selectedPeopleModelId);
  }

  public onNavigateToCreateAccount() {
    this.navigateToCreateAccount.emit();
  }
}
