import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromAccounts from './accounts.reducer';
import * as fromUser from './user.reducer';
import * as fromSidebar from './sidebar.reducer';
import * as fromInvitations from './invitations.reducer';
import * as fromRoleAssignments from './roleAssignments.reducer';
import * as fromSideMenu from './side-menu.reducer';
import * as fromCustomizationView from './customization-view.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromDirectories from './directories.reducer';
import * as fromEdgeExtensions from './edge-extensions.reducer';
import * as fromAmsMigration from './ams-migration.reducer';

export interface IState {
  accounts: fromAccounts.IState;
  user: fromUser.IState;
  sidebar: fromSidebar.IState;
  invitations: fromInvitations.IState;
  roleAssignments: fromRoleAssignments.IState;
  sideMenu: fromSideMenu.IState;
  customizationView: fromCustomizationView.IState;
  notifications: fromNotifications.IState;
  directories: fromDirectories.IState;
  edgeExtensions: fromEdgeExtensions.IState;
  amsMigration: fromAmsMigration.IState;
}

export const reducers: ActionReducerMap<IState> = {
  accounts: fromAccounts.reducer,
  user: fromUser.reducer,
  sidebar: fromSidebar.reducer,
  invitations: fromInvitations.reducer,
  roleAssignments: fromRoleAssignments.reducer,
  sideMenu: fromSideMenu.reducer,
  customizationView: fromCustomizationView.reducer,
  notifications: fromNotifications.reducer,
  directories: fromDirectories.reducer,
  edgeExtensions: fromEdgeExtensions.reducer,
  amsMigration: fromAmsMigration.reducer
};

export const selectCoreState = createFeatureSelector<IState>('core');
