import { createSelector } from '@ngrx/store';

import { INotification, NotificationLevel } from '@common/modules/core/services/toast/interfaces';

import { selectCoreState } from '../reducers';
import * as fromBannersNotifications from '../reducers/notifications/notifications-banners.reducer';

export const getNotificationsState = createSelector(selectCoreState, state => state.notifications);

export const getNotificationsLoadingState = createSelector(getNotificationsState, state => state.viewState?.loading);

// Banners
export const selectBannersNotifications = createSelector(getNotificationsState, state => state.banners);

export const selectAllBannersNotifications = createSelector(selectBannersNotifications, state => Object.values(state.entities));

export const getNotClearedBannersNotifications = createSelector(selectAllBannersNotifications, notifications =>
  notifications.filter(n => !n.cleared)
);

export const getNotSeenBannersNotifications = createSelector(getNotClearedBannersNotifications, notifications => notifications.filter(n => !n.seen));

export const getNotToastedBannersNotifications = createSelector(selectAllBannersNotifications, notifications =>
  notifications.filter(n => !n.toasted)
);

// get the selectors
const { selectIds } = fromBannersNotifications.adapter.getSelectors(selectBannersNotifications);

// select the array of user ids
export const selectBannersNotificationsIds = selectIds as (state: object) => string[];

// Alerts
export const selectAlertsNotifications = createSelector(getNotificationsState, state => state.alerts);

export const selectAllAlertsNotifications = createSelector(selectAlertsNotifications, state => Object.values(state.entities));

export const getNotSeenAlertsNotifications = createSelector(selectAllAlertsNotifications, notifications => notifications.filter(n => !n.seen));

export const getNotToastedAlertsNotifications = createSelector(selectAllAlertsNotifications, notifications => notifications.filter(n => !n.toasted));

export const getNotClearedAlertsNotifications = createSelector(selectAllAlertsNotifications, notifications => notifications.filter(n => !n.cleared));

// Messages
export const selectMessagesNotifications = createSelector(getNotificationsState, state => state.messages);

export const selectAllMessagesNotifications = createSelector(selectMessagesNotifications, state => Object.values(state.entities));

export const getNotSeenMessagesNotifications = createSelector(selectAllMessagesNotifications, notifications => notifications.filter(n => !n.seen));

export const getNotToastedMessagesNotifications = createSelector(selectAllMessagesNotifications, notifications =>
  notifications.filter(n => !n.toasted)
);

export const getNotClearedMessagesNotifications = createSelector(selectAllMessagesNotifications, notifications =>
  notifications.filter(n => !n.cleared)
);

// Notifications panel
export const getNotificationPanelAlerts = createSelector(getNotClearedBannersNotifications, selectAllAlertsNotifications, (banners, alerts) => {
  const allNotifications = [...banners, ...alerts];
  const criticalNotifications = allNotifications
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Critical.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const errorNotifications = allNotifications
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Error.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const warningNotifications = allNotifications
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Warning.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const infoNotifications = allNotifications
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Info.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const noneNotifications = allNotifications
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.None.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  return [...criticalNotifications, ...errorNotifications, ...warningNotifications, ...infoNotifications, ...noneNotifications];
});

export const getNotificationPanelMessages = createSelector(getNotClearedMessagesNotifications, messages => {
  const criticalNotifications = messages
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Critical.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const errorNotifications = messages
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Error.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const warningNotifications = messages
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Warning.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const infoNotifications = messages
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.Info.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  const noneNotifications = messages
    ?.filter(n => n.level?.toString()?.toLowerCase() === NotificationLevel.None.toString()?.toLowerCase())
    ?.sort(compareNotificationsStartDate.bind(this));
  return [...criticalNotifications, ...errorNotifications, ...warningNotifications, ...infoNotifications, ...noneNotifications];
});

export const getNotClearedNotificationsCount = createSelector(
  getNotClearedBannersNotifications,
  getNotClearedAlertsNotifications,
  getNotClearedMessagesNotifications,
  (banners, alerts, messages) => banners.length + alerts.length + messages.length
);

function compareNotificationsStartDate(a: INotification, b: INotification) {
  const date1 = new Date(a?.startDate);
  const date2 = new Date(b?.startDate);

  return date2.getTime() - date1.getTime();
}
