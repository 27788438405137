import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import * as ProjectsActions from '../actions/projects.actions';
import { emptyProjectItem, GalleryProcessingProgress, INextPage } from '../models/gallery';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<SinglePlaylistSearchResultV2> {
  // additional entities state properties
  selectedVideoId: string;
  loaded: boolean;
  loading: boolean;
  nextPage?: INextPage;
  loadingNext: boolean;
  error: boolean;
  errorType?: APIErrors;
}

function selectVideoId(a: SinglePlaylistSearchResultV2): string {
  return a.id;
}

const adapter: EntityAdapter<SinglePlaylistSearchResultV2> = createEntityAdapter<SinglePlaylistSearchResultV2>({
  selectId: selectVideoId
});

const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedVideoId: null,
  loaded: false,
  loading: false,
  nextPage: {
    pageSize: null,
    skip: null,
    done: false
  },
  loadingNext: false,
  error: false,
  errorType: null
});

const projectsReducer = createReducer(
  initialState,
  on(ProjectsActions.createProjectItem, (state, {}) => {
    return adapter.upsertOne(emptyProjectItem, state);
  }),
  on(ProjectsActions.updateVideo, (state, { video }) => {
    return adapter.updateOne({ id: video.id, changes: video }, state);
  }),
  on(ProjectsActions.upsertVideos, (state, { videos, nextPage }) => {
    return adapter.upsertMany(videos, {
      ...state,
      loaded: true,
      loading: false,
      nextPage,
      loadingNext: false
    });
  }),
  on(ProjectsActions.deleteVideo, (state, { id }) => {
    return adapter.updateOne({ id, changes: { processingProgress: GalleryProcessingProgress.DELETING } }, state);
  }),
  on(ProjectsActions.deleteVideoSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(ProjectsActions.deleteVideoFailed, (state, { id }) => {
    return adapter.updateOne({ id, changes: { processingProgress: GalleryProcessingProgress.PROCESSED } }, state);
  }),
  on(ProjectsActions.deleteVideos, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(ProjectsActions.clearProjects, (state, {}) => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      loading: false,
      nextPage: {
        pageSize: null,
        skip: null,
        done: false
      },
      loadingNext: false,
      error: false,
      errorType: null
    });
  }),
  on(ProjectsActions.loadProjectsNextPage, (state, {}) => {
    return {
      ...state,
      loadingNext: true
    };
  }),
  on(ProjectsActions.loadProjectsError, (state, { errorType }) => {
    return {
      ...state,
      error: true,
      errorType
    };
  }),
  on(ProjectsActions.loadingProjects, (state, {}) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(ProjectsActions.updateVideoName, (state, { id, name }) => {
    return adapter.updateOne({ id: id, changes: { name: name } }, state);
  }),
  on(ProjectsActions.updateThumbnailId, (state, { id, thumbnailId }) => {
    return adapter.updateOne({ id: id, changes: { thumbnailId: thumbnailId } }, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return projectsReducer(state, action);
}
