import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType, UIActionIcon } from '@common/modules/insights/interfaces';

export const backToUpload: IAction = {
  title: '',
  type: UIActionType.PREVIOUS,
  value: UIActionType.PREVIOUS,
  icon: UIActionIcon.PREVIOUS,
  selected: false,
  selectable: false,
  id: 'backToUploadButton'
};
