import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AccessibilityUtilsService } from '@common/modules/accessibility/accessibility-utils.service';

import { IUIPreset, IUIPresetInsight, IUITab, ISubscriptions, PresetInsightType } from '../../../insights/interfaces';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { Direction, IAction } from '../../interfaces';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { InsightsCommonDataService } from '../../../insights-common/insights-common-data.service';
import { InsightsCommonService } from '../../../insights-common/insights-common.service';
import { InsightsService } from '../../../insights/services/insights.service';
import { UrlService } from '../../../core/services/url/url.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { EventCategory, TrackService } from '../../../core/services/track';
import { ITabListTab } from '../tablist/interfaces';
import { ActionButtonType } from '../action-button/interfaces';
import { resources } from './resources';

@Component({
  selector: 'app-vi-insights-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss']
})
export class PresetsComponent implements OnInit, OnDestroy {
  // Input
  @Input() public presets: IUIPreset[];
  @Input() public presetInsights: IUIPresetInsight[];
  @Input() public title = 'View Insights';
  @Input() public action?: IAction;
  @Input() public hasOpenerIcon = false;
  @Input() public tooltipText = '';
  @Input() public sizeClass = '';
  @Input() public calcWidth = true;
  @Input() public isDisabled = false;
  @Input() public responsiveState = '';
  @Input() public internal = false;
  @Input() public buttonType: ActionButtonType;

  // Output
  @Output() public presetChange: EventEmitter<IUIPreset> = new EventEmitter<IUIPreset>();
  @Output() public presetInsightChange: EventEmitter<IUIPresetInsight> = new EventEmitter<IUIPresetInsight>();
  @Output() public actionChange = new EventEmitter<IAction>();
  @Output() public widthCalculated = new EventEmitter<number>();
  @Output() public focusInActionButton = new EventEmitter();

  // Const
  public readonly PRESET_ID_PREFIX = 'checkboxId';
  public readonly OPTION_ID_SUFFIX = '_preset_item';
  public readonly MENU_ID = 'presetList';

  // Public
  public resources = resources;
  public dropDownWidth = 0;
  public expanded = false;
  public customInsightsExpanded = false;
  public showTooltip = false;
  public showTabTooltip = false;
  public width = 'auto';
  public maxWidth = '50vw';
  public Direction = Direction;
  public PresetInsightType = PresetInsightType;

  @ViewChild('customInsightsPresetsButton') public customInsightsPresetsButton: ElementRef;
  @ViewChild('customInsightsPresetsContainer') public customInsightsPresetsContainer: ElementRef;
  @ViewChild('insightsPresetsContainer') public insightsPresetsContainer: ElementRef;
  @ViewChild('PresetsContainer') public presetsContainer: ElementRef;
  @ViewChild('PresetButton', { read: ElementRef }) presetButton: ElementRef;

  // Private
  private tabPressedMap: Map<IUITab, boolean> = new Map<IUITab, boolean>();
  private tabs: ITabListTab<IUITab>[] = [];
  private subscriptions: ISubscriptions = {};

  // Const
  private readonly PRESET_BUTTON_HEIGHT = 36;
  private readonly PRESETS_TITLE = 3;
  private readonly MIN_AMOUNT_SHOW_CUSTOM_INSIGHTS = 1;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private commonUtilsService: InsightsCommonUtilsService,
    private dataService: InsightsCommonDataService,
    private commonInsights: InsightsCommonService,
    private insightsService: InsightsService,
    private cdr: ChangeDetectorRef,
    private urlUtility: UrlService,
    private translate: TranslateHelperService,
    private focusManager: FocusManagerService,
    private localStorageService: LocalStorageService,
    private trackService: TrackService,
    private renderer: Renderer2,
    private accessibilityUtils: AccessibilityUtilsService
  ) {}

  public ngOnInit() {
    this.translate.translateResources(this.resources);
    if (this.calcWidth) {
      this.calcDropDownWidth();
    }

    this.initConfigurations();
  }

  public ngOnDestroy(): void {
    this.commonUtilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  public hideTooltip() {
    this.showTabTooltip = false;
    this.cdr.markForCheck();

    // Update local storage
    this.localStorageService.setItem(`tab${IUITab.INSIGHTS}tooltipShown`, this.tabPressedMap.get(IUITab.INSIGHTS).toString());
    this.localStorageService.setItem(`tab${IUITab.TIMELINE}tooltipShown`, this.tabPressedMap.get(IUITab.TIMELINE).toString());
  }

  public selectPreset(preset: IUIPreset) {
    this.presetChange.emit(preset);

    this.trackService.track('preset.select_preset', {
      category: EventCategory.PRESET,
      preset: preset?.value
    });
  }

  public selectPresetInsight(ins: IUIPresetInsight) {
    this.presetInsightChange.emit(ins);

    this.trackService.track('preset.select_insights', {
      category: EventCategory.PRESET,
      data: { preset: ins?.value, selected: ins?.selected }
    });
  }

  public convertPresetToString(preset: IUIPreset): string {
    let str = '';
    for (const element of this.presetInsights) {
      if (preset?.insights?.includes(element?.value)) {
        str += `${this.PRESET_ID_PREFIX + element?.value} `;
      }
    }
    return str.slice(0, -1);
  }

  public onCustomInsightContainerOut(event: MouseEvent) {
    // If the focus moves to the custom insights preset button
    if (event.relatedTarget === this.customInsightsPresetsButton.nativeElement) {
      this.customInsightsExpanded = true;
      this.placeCustomInsightsMenu();
    } else {
      this.customInsightsExpanded = false;
    }
  }

  public onCustomInsightArrow(open: boolean, event: KeyboardEvent) {
    event.stopPropagation();
    if (open) {
      this.customInsightsExpanded = true;
      this.placeCustomInsightsMenu();
      // Focus first element
      this.focusOnTarget(this.customInsightsPresetsContainer.nativeElement.querySelector('li [id^="checkboxId"]'));
    } else {
      // close
      // Focus preset button
      this.focusManager.focusVia(() => {
        setTimeout(() => {
          this.customInsightsExpanded = false;
          this.cdr.detectChanges();
        });
        return this.customInsightsPresetsButton;
      });
    }
  }

  public onCustomInsightHover(mouseOver: boolean, event: MouseEvent) {
    // If user hover over the custom insights button / custom menu
    if (mouseOver) {
      // Show custom insights list
      this.customInsightsExpanded = true;
    } else {
      // If hovering over different element
      if (event.relatedTarget !== null) {
        // If this element is the custom insights menu list - keep open
        const parents = this.accessibilityUtils.getParents(event.relatedTarget);
        this.customInsightsExpanded = parents?.indexOf(this.customInsightsPresetsContainer?.nativeElement) !== -1;
      } else {
        this.customInsightsExpanded = false;
      }
    }

    this.placeCustomInsightsMenu();
  }

  public handleActionButtonClick(event: MouseEvent | KeyboardEvent) {
    if (!event) {
      // Resolve a11y problem - since it is a menu with an extra dropdown, when the custom dropdown is closed
      // After selecting one of the items, the focus disappear from the menu, causing the toggle to work
      // Only in this case the focus should be returned to the triggered button
      if (!this.customInsightsExpanded && document.querySelector('.dropdown.presets.custom-container')) {
        this.focusOnTarget(this.presetButton.nativeElement.querySelector('button'));
        return;
      }
    }
    this.actionChange.emit(this.action);
    this.expanded = !this.expanded;
    this.customInsightsExpanded = false;
    this.cdr.detectChanges();
  }

  public handleArrowUp(event: KeyboardEvent) {
    event.preventDefault();
    const prev = (<HTMLElement>event.target)?.previousElementSibling || this.presetsContainer?.nativeElement?.lastElementChild;
    this.focusOnTarget(prev);
  }

  public handleArrowDown(event: KeyboardEvent) {
    event.preventDefault();
    const next = (<HTMLElement>event.target)?.nextElementSibling || this.presetsContainer?.nativeElement?.firstElementChild;
    this.focusOnTarget(next);
  }

  public get isCustomPresetsListEnabled() {
    return this.commonInsights.customInsightsCount > this.MIN_AMOUNT_SHOW_CUSTOM_INSIGHTS;
  }

  public focusOnTarget(target: Element) {
    if (target) {
      this.focusManager.focusVia(() => {
        return new ElementRef(target);
      }, this.cdr);
    }
  }

  public handleEscClick(e: KeyboardEvent) {
    if (this.expanded) {
      this.expanded = !this.expanded;
      this.customInsightsExpanded = false;
      // Focus main action
      this.focusManager.focusByQuery('#presets', this.cdr);
    }
  }

  public generateTooltipText(): string {
    const itemResources = { TooltipStatus: '' };
    this.translate.translateResources(itemResources, {
      statusType: this.resources.View,
      currentStatus: `<strong>${this.commonInsights.getPresetsName()}</strong>`
    });

    return itemResources.TooltipStatus;
  }

  public tooltipNotificationTextOnTabChange() {
    const itemResources = { ActionBarPresetChangeNotification: '' };
    this.translate.translateResources(itemResources, {
      'preset-name': this.commonInsights.getPresetsName()
    });

    return itemResources.ActionBarPresetChangeNotification;
  }

  public onMouseOver(state: boolean) {
    this.showTooltip = state;
  }

  public handleFocusInActionButton() {
    this.focusInActionButton.emit();
  }

  public getActionButtonWidth(): string {
    if (this.action.title) {
      return `${this.dropDownWidth}px`;
    } else {
      return 'auto';
    }
  }

  private initConfigurations() {
    this.showTabTooltip = false;
    // If theres a widgets param, dont show tab popup
    const widgetParam = this.urlUtility.getQueryParam('widgets');
    if (!widgetParam) {
      this.initTabTooltip();
    }
  }

  private initTabTooltip() {
    // Get tabs
    this.tabs = this.dataService.getTabs();

    // Init tabPressedMap object
    for (const tab of this.tabs) {
      // Set tab tooltip shown value according to local storage.
      // By default is false
      let isTooltipShown = 'false';

      isTooltipShown = this.localStorageService.getItem(`tab${tab.value}tooltipShown`);

      this.tabPressedMap.set(tab.value, isTooltipShown === 'true');
    }

    // Subscribe to tab change event
    this.subscriptions.onTabChange = this.insightsService.tabChangeSubject.subscribe((newTab: ITabListTab<IUITab>) => {
      // Get value from local storage
      if (this.tabPressedMap.get(newTab.value)) {
        return;
      }

      // Set pressed map = tooltip is shown
      this.tabPressedMap.set(newTab.value, true);
      this.showTabTooltip = true;
      this.cdr.markForCheck();
    });
  }

  private calcDropDownWidth() {
    this.dropDownWidth = 0;
    const padding = 9;
    const checkboxSize = 20;

    this.presets.forEach(preset => {
      const textWidth = this.commonUtilsService.measureText(preset.name, 14) + 2 * padding;
      if (this.dropDownWidth < textWidth) {
        this.dropDownWidth = textWidth;
      }
    });
    this.presetInsights.forEach(insight => {
      const textWidth = this.commonUtilsService.measureText(insight.name, 14) + 2 * padding + checkboxSize;
      if (this.dropDownWidth < textWidth) {
        this.dropDownWidth = textWidth;
      }
    });

    // Calc button text value width
    let mainActionWidth = this.commonUtilsService.measureText(this.title, 14);
    // i-span-i padding
    mainActionWidth += 18;
    // Add opener icon padding
    mainActionWidth += 32;
    // Add left icon padding
    mainActionWidth += 32;
    mainActionWidth += 2 * padding + 12;

    // Calc total max width
    this.dropDownWidth = Math.max(this.dropDownWidth, mainActionWidth);
    if (this.dropDownWidth) {
      this.width = `${this.dropDownWidth}px`;
    }
    this.widthCalculated.emit(this.dropDownWidth);
  }

  private placeCustomInsightsMenu() {
    if (!this.customInsightsExpanded) {
      return;
    }
    // First, render the screen to verify the menu is shown
    this.cdr.detectChanges();
    // Move the list to the left point of the last item
    const top = (this.presets.length + this.PRESETS_TITLE) * this.PRESET_BUTTON_HEIGHT - this.insightsPresetsContainer.nativeElement.scrollTop;
    this.renderer.setStyle(this.customInsightsPresetsContainer.nativeElement, 'top', `${top}px`);

    // Get custom preset trigger button and move menu according to width
    const clientRect = this.customInsightsPresetsButton.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.customInsightsPresetsContainer.nativeElement, 'right', `${clientRect.width}px`);
  }
}
