import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ChangeDetectorRef, ViewChild, HostBinding } from '@angular/core';

import { take } from 'rxjs/operators';

import { find } from 'lodash-es';

import { IUIFilterIssue, IUIFilterItem } from '@common/modules/editor/interfaces';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';

import { UIActionType, ISubscriptions } from '../../../insights/interfaces';
import { InsightsCommonUtilsService } from '../../../insights-common/insights-common-utils.service';
import { IAction } from '../../../shared/interfaces';
import { SearchbarService } from '../../../shared/components/searchbar/services/searchbar.service';
import { UIFilterType } from './interfaces';
import { DataService } from '../../services/data.service';
import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { FocusableComponent } from '../../../accessibility/focusable/focusable.component';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { SelectComponentSize } from '../fluent-ui-select';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent extends FocusableComponent implements OnInit, OnDestroy {
  // Input
  // Get filterItem and use it fire its details to parent when filtering
  @Input() public filterItem: IUIFilterItem;
  // Filter selection options
  @Input() public filterIssues: IUIFilterIssue[];
  @Input() public includeExcludeOptions?: IAction[];
  @Input() public isDisabled = false;
  @Input() public widthPercent = '';
  @Input() public associatedLabel = '';
  @Input() public disableAddButton = true;
  @Input() public disableRemoveButton = true;
  @Input() public ariaLabelledBy = '';

  // Output
  @Output() public addItem = new EventEmitter<number>();
  @Output() public removeItem = new EventEmitter<number>();
  @Output() public filterTextChange = new EventEmitter<IUIFilterItem>();

  @HostBinding('style.width') get isfullWidth() {
    if (this.widthPercent) {
      return this.widthPercent;
    }
    return '100%';
  }

  // Public
  public UIFilterType = UIFilterType;
  public filterType = this.UIFilterType.TEXT;
  public enableMultipleSelection = true;
  public placeholderAction: IAction;
  public searchbarPlaceholder = '';
  public filterActions: IAction[] = [];
  public actionList: IAction[] = [];
  public selectedAction: IAction | IAction[];
  public selFilterAction: IAction;
  public initValue: string = '';
  // Set ids for search and filter after get filter type
  public filterActionsId: string;
  public searchInputId: string;
  public includeExcludeOptionsId: string;
  public resources = resources;
  public SelectComponentSize = SelectComponentSize;

  // Private
  private selIncludeExcludeOption: IAction;
  private selectedActionText: string;
  private selectedActionValue = {};
  private subscriptions: ISubscriptions = {};
  private textFilter: string;

  @ViewChild('searchBarForTextFilter', { static: false }) private searchBarForTextFilter: SearchbarComponent;

  constructor(
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private searchbarService: SearchbarService,
    private translateService: TranslateHelperService
  ) {
    super();
  }

  public ngOnInit() {
    this.subscriptions.translateResources = this.translateService
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
    this.logger.log(this.filterItem);

    this.initFilterActions();
    this.initPlaceholderAction();
    const action = this.filterIssues.find(f => f.filterAction.type === this.filterItem.actionType);
    this.selFilterAction = action.filterAction;
    this.initValue = this.filterItem.text;

    if (!this.filterItem.text) {
      this.searchbarPlaceholder = action?.placeHolderText;
    }
    if (this.includeExcludeOptions) {
      this.selIncludeExcludeOption = this.includeExcludeOptions[0];
    }
    this.setFilterSubjectSubscription();

    this.includeExcludeOptionsId = `includeExcludeOptions_${this.filterItem.index}`;
    this.filterActionsId = `filterActions_${this.filterItem.index}`;
    // Include the filter item id in order to listen to the right event
    this.searchInputId = `${this.filterItem.id}_${this.filterItem.index}`;

    if (this.filterItem.actionType === UIActionType.LANGUAGE) {
      this.actionList = this.dataService.getLanguages();

      // Update selected action to be the first action from action list
      this.selectedAction = this.actionList.find(a => a.value === this.filterItem.value);
      if (!this.selectedAction) {
        this.selectedAction = this.actionList[0];
      }
      this.filterType = UIFilterType.DROP_DOWN;
    } else {
      // this.selFilterAction.value = this.filterItem.text;
      setTimeout(() => {
        if (this.searchBarForTextFilter) {
          this.searchBarForTextFilter.setInput(this.filterItem.text);
        }
      });
    }
  }

  public ngOnDestroy() {
    this.insightsCommonUtilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  public add() {
    this.addItem.emit();
  }

  public remove() {
    this.removeItem.emit(this.filterItem.index);
  }

  // Handles filter action selection
  public handleSelectFilterAction(filterAction: IAction) {
    this.selFilterAction = filterAction;
    const selFilterIssue = find(this.filterIssues, filterIssue => {
      return filterIssue.filterAction.type === this.selFilterAction.type;
    });

    // this.selectedActionText = this.selectedActionValue = filterAction.value;
    this.filterType = selFilterIssue.type;
    if (selFilterIssue.enableMultipleSelection !== undefined) {
      this.enableMultipleSelection = selFilterIssue.enableMultipleSelection;
    }
    if (selFilterIssue.type === UIFilterType.DROP_DOWN) {
      this.actionList = this.dataService.getLanguages();

      // If there is a placeholder - update it
      if (selFilterIssue.placeHolderKey) {
        this.placeholderAction.title = selFilterIssue.placeHolderText;
        this.placeholderAction = Object.assign({}, this.placeholderAction);
      }
      // Update selected action to be the first action from action list - default english
      this.selectedAction = this.actionList.find(a => a.value === 'en-US');
      this.selFilterAction.value = this.selectedAction.value;
    } else {
      this.searchbarPlaceholder = selFilterIssue.placeHolderText;
      // Reset search text
      if (this.searchBarForTextFilter) {
        this.searchBarForTextFilter.resetInput();
      }
    }

    // Emit filterTextChange event with empty values (clear previous filter action)
    this.filterTextChange.emit({
      id: this.filterItem.id,
      index: this.filterItem.index,
      // includeExcludeType: this.selIncludeExcludeOption ? this.selIncludeExcludeOption.value : null,
      actionType: this.selFilterAction.type,
      text: '',
      value: this.selFilterAction.value
    });

    this.cdr.detectChanges();
  }

  public getPlaceholderAction(): IAction {
    return this.placeholderAction;
  }

  public getSubActionID(): string {
    return `${this.searchInputId}_${this.selFilterAction.id}`;
  }

  public getIsSearchable(): boolean {
    return true;
  }

  // Handles include\exclude action selection
  public handleSelectIncludeExcludeOption(option: IAction) {
    if (this.selIncludeExcludeOption.value !== option.value) {
      this.selIncludeExcludeOption = option;
      if (this.selectedActionText.length) {
        // Include/Exclude filter has been toggled, emit filterTextChange event
        this.filterTextChange.emit({
          id: this.filterItem.id,
          index: this.filterItem.index,
          // includeExcludeType: option.value,
          actionType: this.selFilterAction.type,
          text: option.value,
          value: option.value
        });
      }
    }
  }

  // Handles select change of item action
  public handleFilterTextChange(action: IAction) {
    // Verify selected action has been changed
    this.selectedAction = action;
    this.selectedActionText = '';
    this.selectedActionValue = [];
    this.filterTextChange.emit({
      id: this.filterItem.id,
      index: this.filterItem.index,
      // includeExcludeType: this.selIncludeExcludeOption ? this.selIncludeExcludeOption.value : null,
      actionType: this.selFilterAction.type,
      text: action.value,
      value: action.value
    });
  }

  private initFilterActions() {
    this.filterIssues.forEach(filterIssue => {
      // Insert filter action if its type is not DROP_DOWN  or its type is DROP_DOWN and it has data
      let actionFilterHasSubActions = false;
      const subActions = this.dataService.getLanguages();
      if (filterIssue.type === UIFilterType.DROP_DOWN) {
        actionFilterHasSubActions = subActions !== undefined && subActions.length !== 0;
      }
      if (filterIssue.type !== UIFilterType.DROP_DOWN || actionFilterHasSubActions) {
        this.filterActions.push(filterIssue.filterAction);
      }
    });
  }

  // Place holder action is the action that is displayed as header of sub actions drop down
  private initPlaceholderAction() {
    this.placeholderAction = {
      title: '',
      selected: false,
      type: UIActionType.PRESETS,
      value: -1,
      icon: 'i-vi-search',
      selectable: true,
      id: UIActionType.PRESETS.toString()
    };
  }

  // Listens for text changes for filters of type UIEditorFilterType.TEXT (transcript, ocr...)
  private setFilterSubjectSubscription() {
    this.subscriptions.onFilter = this.searchbarService.filter(this.searchInputId).subscribe(searchEvent => {
      // Check that query filter has been changed  (no need to filter on existing filter)
      if (searchEvent && searchEvent.id === this.searchInputId && this.textFilter !== searchEvent.query) {
        this.selectedActionText = searchEvent.query;
        this.textFilter = searchEvent.query;
        // Text has been changed for filters of type UIEditorFilterType.TEXT (transcript, ocr...) , emit filterTextChange event
        this.filterTextChange.emit({
          id: this.filterItem.id,
          index: this.filterItem.index,
          // includeExcludeType: this.selIncludeExcludeOption ? this.selIncludeExcludeOption.value : null,
          actionType: this.selFilterAction.type,
          text: this.selectedActionText,
          value: this.selectedActionText
        });
      }
    });
  }
}
