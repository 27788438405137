import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { filter, Observable, of, switchMap, take, withLatestFrom } from 'rxjs';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { VIRoutingMap } from '../../../app/routing/routes';
import { EdgeExtensionsStoreService } from '../../../core/services/edge-extensions-store.service';

export const LiveEdgeGuard: ResolveFn<Observable<boolean>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(LiveEdgeGuardService).canActivate(route);
};

@Injectable({
  providedIn: 'root'
})
export class LiveEdgeGuardService {
  constructor(
    private featureSwitchService: FeatureSwitchService,
    private coreStore: CoreStoreService,
    private edgeExtensionsStore: EdgeExtensionsStoreService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.featureSwitchService.featureSwitch(FeatureSwitch.LiveEdge)) {
      return this.canNotActivate();
    }
    return this.edgeExtensionsStore.edgeExtensionsMetadataLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      withLatestFrom(this.coreStore.selectAccountIds$),
      switchMap(([loaded, accountIds]) => this.checkAccountExistence$(route, accountIds)),
      switchMap(isAccountExist => (isAccountExist ? this.checkExtensionConnectedToIotResource$(route) : of(false))),
      switchMap(isIotResourceHub => (isIotResourceHub ? this.checkLiveModuleLoaded$() : of(false)))
    );
  }

  /**
   * Check if the account ID from the route parameters exists in the logged-in user's account IDs.
   * If the account belongs to the user, selects the account and loads the edge extensions.
   * Otherwise, the route cannot be activated.
   */
  private checkAccountExistence$(route: ActivatedRouteSnapshot, accountIds: string[]): Observable<boolean> {
    const accountId = route?.params?.accountId;
    if (!accountIds.includes(accountId)) {
      return this.canNotActivate();
    }
    this.coreStore.selectAccount(accountId, false, true);

    return this.edgeExtensionsStore.selectEdgeExtensionsLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      switchMap(() => of(true))
    );
  }

  /**
   * Select extension and checks if the extension is connected to an IoT resource.
   */
  private checkExtensionConnectedToIotResource$(route: ActivatedRouteSnapshot): Observable<boolean> {
    const extensionId = route?.params?.extensionId;
    this.edgeExtensionsStore.selectEdgeExtensionByPrincipalId(extensionId);

    return this.edgeExtensionsStore.selectedEdgeExtensionIotHubResourceId$.pipe(
      switchMap(selectedEdgeExtensionIotHubResourceId => {
        //TODO: Add a check for selectedEdgeExtensionIotHubResourceId
        // if (!selectedEdgeExtensionIotHubResourceId) {
        //   return this.canNotActivate();
        // }
        return of(true);
      })
    );
  }

  /**
   * Checks if the live mode is loaded.
   */
  private checkLiveModuleLoaded$(): Observable<boolean> {
    return this.edgeExtensionsStore.isLiveModuleLoaded$.pipe(
      filter(loaded => loaded),
      take(1),
      switchMap(() => {
        return of(true);
      })
    );
  }

  private canNotActivate(): Observable<boolean> {
    this.coreStore.navigate([`/${VIRoutingMap.pageNotFoundError.path}`]);
    return of(false);
  }
}
