export enum ReIndexPages {
  IndexingForm = 'IndexingForm',
  ReIndexReview = 'ReIndexReview',
  ReIndexSummary = 'ReIndexSummary'
}

export enum FailureCode {
  NONE = 'None',
  GENERAL = 'General',
  INVALID_INPUT = 'InvalidInput',
  UNSUPPORTED_FILE_TYPE = 'UnsupportedFileType',
  AMS_NOT_AVAILABLE = 'AmsNotAvailable',
  AMS_NOT_AVAILABLE_OR_FILE_NOT_ACCESSIBLE = 'AmsNotAvailableOrFileNotAccessible',
  EVENT_GRID_NOT_REGISTERED = 'EventGridNotRegistered',
  TIMEOUT = 'Timeout',
  UPLOAD_FAILED = 'UploadFailed',
  AMS_GATE_WAY_TIMEOUT = 'AmsGatewayTimeout',
  VIDEO_DELETED = 'VideoDeleted',
  TRANSIENT_ERROR = 'TransientError',
  AMS_JOB_EXTERNAL_CANCELLATION = 'AmsJobExternalCancellation',
  VIDEO_ALREADY_FAILED = 'VideoAlreadyFailed',
  ASSET_WAS_NOT_FOUND = 'AssetWasNotFound',
  AMS_QUOTA_EXCEEDED = 'AmsQuotaExceeded',
  UNABLE_TO_REACH_STREAMING_END_POINT = 'UnableToReachStreamingEndpoint',
  VIDEO_URL_UNREACHABLE = 'VideoUrlUnreachable',
  READ_ONLY_DISABLED_SUBSCRIPTION = 'ReadOnlyDisabledSubscription'
}
