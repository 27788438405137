import { Injectable } from '@angular/core';

import { emailPattern, JWTTokenPattern, sasTokenPattern } from '@common/modules/utils/regexes';

import { LoggerService } from '../logger/logger.service';
import { ISanitizerConfig } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class SanitizerService {
  private static readonly MASK_CHAR = '*';
  private static readonly JWT_CHAR_OFFSET = 2;

  private config: ISanitizerConfig[] = [
    {
      // Email regex pattern
      regex: emailPattern,
      mask: (match: string) => SanitizerService.MASK_CHAR.repeat(match.length)
    },
    {
      // JWT regex pattern
      regex: RegExp(JWTTokenPattern, 'g'),
      mask: (match: string) =>
        `${match.slice(0, SanitizerService.JWT_CHAR_OFFSET)}${SanitizerService.MASK_CHAR.repeat(match.length - SanitizerService.JWT_CHAR_OFFSET)}`
    },
    {
      // SAS token regex pattern to find the sig parameter
      regex: sasTokenPattern,
      mask: (_: string, p1: string, p2: string, p3: string) => `${p1}${p2}${SanitizerService.MASK_CHAR.repeat(p3.length)}`
    }
  ];

  constructor(private logger: LoggerService) {}

  sanitizeObject(data: object): object {
    try {
      let dataString = JSON.stringify(data);
      for (const { regex, mask } of this.config) {
        dataString = dataString.replace(regex, mask);
      }
      return JSON.parse(dataString);
    } catch (e) {
      this.logger.error('[SanitizerService] Failed to sanitize data', e);
      return data;
    }
  }
}
