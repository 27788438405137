import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { TrainModelDialogSource } from './../../interfaces';
import * as fromCustomizationDataState from '../../reducers/index';
import * as fromCustomizationData from '../../selectors';
import * as actions from '../../actions/speech/speech-models.actions';
import { IUISpeechModelTableDetail } from '../../interfaces';

import SpeechModel = Microsoft.VideoIndexer.Contracts.CustomSpeechModel;
import SpeechModelInput = Microsoft.VideoIndexer.Contracts.CustomSpeechModelInput;

@Injectable()
export class SpeechModelStoreService {
  constructor(private readonly store: Store<fromCustomizationDataState.IState>) {}

  public loadSpeechData() {
    this.store.dispatch(actions.loadSpeechModels());
  }

  public openEditSpeechModelDialog(model: IUISpeechModelTableDetail) {
    this.store.dispatch(actions.openEditSpeechModelDialog({ model }));
  }

  public openViewModelDialog(model: IUISpeechModelTableDetail) {
    this.store.dispatch(actions.openViewModelDialog({ model }));
  }

  public openDeleteModelDialog(model: IUISpeechModelTableDetail) {
    this.store.dispatch(actions.openSpeechDeleteModelDialog({ model }));
  }

  public createModel(properties: SpeechModelInput) {
    this.store.dispatch(actions.createSpeechModel({ properties }));
  }

  public updateModel(modelId: string, properties: Pick<SpeechModel, 'displayName' | 'description' | 'customProperties'>) {
    this.store.dispatch(actions.updateSpeechModel({ modelId, properties }));
  }

  public isUpdating(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechModelUpdating);
  }

  public getSelectedModel(): Observable<SpeechModel> {
    return this.store.select(fromCustomizationData.getSelectedSpeechModel);
  }

  public isSpeechModelsLoaded(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechModelsLoaded);
  }

  public getSpeechModels(): Observable<SpeechModel[]> {
    return this.store.select(fromCustomizationData.getSpeechModels);
  }

  public get trainedSpeechModels$(): Observable<SpeechModel[]> {
    return this.store.select(fromCustomizationData.getTrainedSpeechModels);
  }

  public isSpeechModelTrainDialogOpened(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechModelTrainDialogOpened);
  }

  public isSpeechModelError(): Observable<boolean> {
    return this.store.select(fromCustomizationData.isSpeechModelError);
  }

  public openTrainModelDialog(source: TrainModelDialogSource) {
    this.store.dispatch(actions.openTrainModelDialog({ source }));
  }
}
