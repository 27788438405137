import { Injectable } from '@angular/core';

import { IAction } from '@common/modules/shared/interfaces';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { IndexingPreset } from '../components/shared/settings';
import {
  basicPreset,
  basicVideoOnlyPreset,
  basicAudioOnlyPreset,
  standardAudioOnlyPreset
} from '../components/shared/settings/indexing-presets/actions';

@Injectable()
export class PresetHelperService {
  public readonly displayNameKey = 'DisplayName';

  constructor(private featureSwitchService: FeatureSwitchService) {}

  public getEdgePresets(): IAction[] {
    const edgePresetsOptions = [];
    if (this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeBasicPreset)) {
      edgePresetsOptions.push({ ...basicPreset, id: 'default', value: IndexingPreset.DEFAULT });
      edgePresetsOptions.push(basicVideoOnlyPreset);
      edgePresetsOptions.push(basicAudioOnlyPreset);
    } else {
      edgePresetsOptions.push({ ...basicAudioOnlyPreset, id: 'default', value: IndexingPreset.DEFAULT });
    }

    if (this.featureSwitchService.featureSwitch(FeatureSwitch.EdgeMultiLid)) {
      edgePresetsOptions.push(standardAudioOnlyPreset);
    }

    return edgePresetsOptions;
  }
}
