import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { IVIThemeOption } from '../../../interfaces';
import { svgElements } from './rect-elements';
import { IUIRectElement } from '../../../interfaces';

@Component({
  selector: 'vi-theme-option',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './theme-option.component.html',
  styleUrls: ['./theme-option.component.scss']
})
export class ThemeOptionComponent implements OnInit {
  @Input() public theme: IVIThemeOption;
  @Input() public activeContainer = true;
  @Input() public selected = false;
  @Input() public accessibilityMode = true;
  @Output() public select = new EventEmitter<void>();

  public titleValue = '';
  public base = '';
  public svgElements: IUIRectElement[];
  constructor(private translate: TranslateHelperService, private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    // traslate theme name
    this.getThemeTranslation();
    this.svgElements = cloneDeep(svgElements);
  }

  public selectTheme() {
    this.select.emit();
  }

  public handleFocus() {
    this.cdr.detectChanges();
  }

  private getThemeTranslation() {
    const themeTranslations = {};
    themeTranslations[`${this.theme.themeName}Theme`] = '';
    this.translate.translateResources(themeTranslations);
    this.titleValue = themeTranslations[`${this.theme.themeName}Theme`];
  }
}
