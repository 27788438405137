import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as LanguageIDActions from '../actions/language-id.actions';

export interface IState extends EntityState<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> {
  // additional entities state properties
  loaded: boolean;
  error: boolean;
  defaultLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[];
}

export function selectLanguageId(l: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract): Microsoft.VideoIndexer.Contracts.LanguageV2 {
  return l?.languageCode;
}

export const adapter: EntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> =
  createEntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract>({
    selectId: selectLanguageId
  });

export const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false,
  defaultLanguages: []
});

const languagesIdReducer = createReducer(
  initialState,
  on(LanguageIDActions.setSelectedLanguages, (state, { selectedLanguages }) => {
    return adapter.setAll(selectedLanguages, {
      ...state,
      loaded: true
    });
  }),
  on(LanguageIDActions.upsertDefaultLanguages, (state, { defaultLanguages }) => {
    return {
      ...state,
      defaultLanguages: defaultLanguages
    };
  }),
  on(LanguageIDActions.clearLanguageIdDatasets, state => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      error: false
    });
  }),
  on(LanguageIDActions.deleteSelectedLanguage, (state, { languageCode }) => {
    return adapter.removeOne(languageCode, state);
  }),
  on(LanguageIDActions.addSelectedLanguage, (state, { selectedLanguage }) => {
    return adapter.addOne(selectedLanguage, { ...state });
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return languagesIdReducer(state, action);
}
