<div [appAccessibilitySubMenuHandler]="columns?.length && expanded"
  (toggleFunc)="handleActionButtonClick($event)"
  [toggleFuncOnNullRelatedTarget]="true"
  (keyup.esc)="handleEscClick($event)">
  <div [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="onMouseOver($event)">
    <app-vi-action-button [sizeClass]="sizeClass"
      #SortButton
      [uid]="buttonId"
      [minWidth]="minWidth"
      [action]="sortAction"
      [hasOpenerIcon]="true"
      [expanded]="expanded"
      [responsiveState]="responsiveState"
      [internal]="false"
      [ariaOwns]="MENU_ID"
      [type]="buttonType"
      [isDisabled]="isDisabled"
      [showAccessibilityTooltip]="true"
      (actionClick)="handleActionButtonClick($event)"
      (focusIn)="handleFocusInActionButton()">
    </app-vi-action-button>
  </div>
  <div *ngIf="expanded"
    class="dropdown columns {{sizeClass}}"
    data-stop-propagation="true">
    <div class="sub-dropdown">
      <ul #ColumnsContainer
        [attr.id]="MENU_ID"
        class="columns-list"
        role="listbox"
        (keyup.arrowDown)="handleArrowDown($event)"
        (keyup.arrowUp)="handleArrowUp($event)">
        <li *ngFor="let column of columns"
          tabindex="0"
          (appAccessibleClick)="selectColumn(column)"
          id="{{column.id}}"
          role="option"
          class="column"
          [ngClass]="{ selected: column.selected }"
          [attr.aria-selected]="column.selected"
          attr.aria-label="{{column.title}}"
          [attr.title]="column.title">
          <label data-stop-propagation="true">
            <i [ngClass]="{
                'i-vi-ok': column.selected
              }"
              *ngIf="column.selected"></i>
            <span [innerHTML]="column.title"></span>
          </label>
        </li>
      </ul>
    </div>
    <div class="sub-dropdown">
      <ul #ColumnsContainer
        [attr.id]="MENU_ID"
        class="direction-list"
        role="listbox"
        (keyup.arrowDown)="handleArrowDown($event)"
        (keyup.arrowUp)="handleArrowUp($event)">
        <li *ngFor="let direction of directions"
          tabindex="0"
          (appAccessibleClick)="selectDirection(direction)"
          id="{{direction.id}}"
          role="option"
          class="direction"
          [ngClass]="{ selected: direction.selected }"
          [attr.aria-selected]="direction.selected"
          attr.aria-label="{{direction.title}}"
          [attr.title]="direction.title">
          <label data-stop-propagation="true">
            <i [ngClass]="{
                'i-vi-ok': direction.selected
              }"
              *ngIf="direction.selected"></i>
            <span [innerHTML]="direction.title"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</div>