export const matchLastToken = (string: string): string => string.match(/\/([^\/]+)\/?$/)[1];

export const toFixed = (number: number, precision: number = 0): number => Number(number.toFixed(precision));

export const normalizeEntryName = (entry: string, options = { maxLength: 10 }) =>
  matchLastToken(entry)
    .replace(/[^\w\s]/gi, '_')
    .slice(-options.maxLength);

export const getPerformanceApi = () => window.performance || window.webkitPerformance || window.msPerformance || window.mozPerformance;
