import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IApiSasContract } from '@common/modules/api/interfaces';

import { IUISpeechDatasetInput, UploadDataDetails } from '../../../customization/interfaces';
import { ISpeechDatasetDetails } from '../../reducers/speech/interfaces';
import { IFileBlob } from '../../../indexing/interfaces';
import { IUISpeechDatasetTableDetail } from '../../interfaces';

import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;
import SpeechDatasetFileContent = Microsoft.VideoIndexer.Contracts.SpeechDatasetFileContent;

export const loadSpeechDatasets = createAction('[SpeechDataset/API] Load Speech datasets');
export const loadSpeechDatasetsSucceeded = createAction('[SpeechDataset] Load Speech datasets succeeded', props<{ datasets: SpeechDataset[] }>());
export const loadSpeechDatasetsFailed = createAction('[SpeechDataset] Load Speech datasets failed');
export const clearSpeechDatasets = createAction('[SpeechModel]  Clear Speech datasets');
export const openViewSpeechDatasetDialog = createAction('[SpeechDataset] Open View Speech Dataset Dialog', props<{ datasetId: string }>());
export const loadSpeechDatasetFiles = createAction('[SpeechDataset/API] Load Speech dataset files', props<{ datasetId: string }>());
export const loadSpeechDatasetFilesStarted = createAction('[SpeechDataset] Load Speech dataset files started');
export const loadSpeechDatasetFilesSucceeded = createAction(
  '[SpeechDataset] Load Speech dataset files succeeded',
  props<{ datasetId: string; files: SpeechDatasetFileContent[] }>()
);
export const loadSpeechDatasetFilesFailed = createAction('[SpeechDataset] Load Speech dataset files failed', props<{ datasetId: string }>());
export const uploadButtonClicked = createAction('[SpeechDataset] Upload Button Clicked');
export const startUploadingDataset = createAction('[SpeechDataset/API] Start Uploading Dataset', props<{ uploadDetails: UploadDataDetails }>());
export const addUploadingDataset = createAction('[SpeechDataset] Add Uploading Dataset', props<{ dataset: ISpeechDatasetDetails }>());
export const getDatasetSasURL = createAction('[SpeechDataset/API] Get Dataset Sas URL', props<{ uploadDetails: UploadDataDetails }>());
export const getDatasetSasURLSucceeded = createAction(
  '[SpeechDataset] Get Dataset Sas URL Succeeded',
  props<{ uploadDetails: UploadDataDetails; file: IFileBlob; sas: IApiSasContract }>()
);
export const getDatasetSasURLFailed = createAction(
  '[SpeechDataset] Get Dataset Sas URL Succeeded Failed',
  props<{ datasetId: string; datasetName: string }>()
);

export const uploadDatasetToBlobSucceeded = createAction(
  '[SpeechDataset/API] Upload Dataset File to Blob Succeeded',
  props<{ datasetInput: IUISpeechDatasetInput }>()
);
export const uploadDatasetToBlobFailed = createAction(
  '[SpeechDataset] Upload Dataset File to Blob Failed',
  props<{ datasetId: string; datasetName: string }>()
);
export const removeSpeechDataset = createAction('[SpeechDataset] Remove Speech Dataset', props<{ datasetId: string }>());
export const createDatasetStarted = createAction('[SpeechDataset] Create Dataset Started');
export const createDatasetSucceeded = createAction('[SpeechDataset] Create Dataset Succeeded', props<{ datasetDetails: SpeechDataset }>());
export const createDatasetFailed = createAction('[SpeechDataset] Create Dataset Failed', props<{ datasetId: string; datasetName: string }>());
export const toggleSpeechDatasetSelection = createAction('[SpeechDataset] Toggle Speech Dataset Selection', props<{ datasetId: string }>());
export const clearSpeechDatasetSelection = createAction('[SpeechDataset] Clear Speech datasets selection');
export const openEditSpeechDatasetDialog = createAction(
  '[SpeechDataset] Open edit Speech dataset dialog',
  props<{ dataset: IUISpeechDatasetTableDetail }>()
);

export const updateSpeechDataset = createAction(
  '[SpeechDataset/API] Update Speech datasets',
  props<{ datasetId: string; properties: Pick<SpeechDataset, 'displayName' | 'description' | 'customProperties'> }>()
);
export const updateSpeechDatasetStarted = createAction('[SpeechDataset] Update Speech dataset started');
export const updateSpeechDatasetSucceed = createAction('[SpeechDataset] Update Speech dataset Succeed', props<{ dataset: SpeechDataset }>());
export const updateSpeechDatasetFailed = createAction('[SpeechDataset] Update Speech dataset Failed');
export const openDeleteSpeechDatasetDialog = createAction(
  '[SpeechDataset] Open Delete Speech Dataset Dialog',
  props<{ dataset: IUISpeechDatasetTableDetail }>()
);
export const deleteSpeechDataset = createAction('[SpeechDataset/API] Delete Speech datasets', props<{ dataset: IUISpeechDatasetTableDetail }>());
export const deleteSpeechDatasetStarted = createAction('[SpeechDataset] Delete Speech dataset started');
export const deleteSpeechDatasetSucceed = createAction('[SpeechDataset] Delete Speech dataset Succeed', props<{ datasetId: string }>());
export const deleteSpeechDatasetFailed = createAction(
  '[SpeechDataset] Delete Speech dataset Failed',
  props<{ error: HttpErrorResponse; datasetId: string; datasetName: string }>()
);
export const downloadSpeechDataset = createAction('[SpeechDataset/API] Download Speech datasets', props<{ datasetId: string }>());
export const downloadSpeechDatasetStarted = createAction('[SpeechDataset] Download Speech dataset started');
export const downloadSpeechDatasetSucceed = createAction('[SpeechDataset] Download Speech dataset Succeed');
export const downloadSpeechDatasetFailed = createAction('[SpeechDataset] Download Speech dataset Failed');
export const getDatasetStatus = createAction('[SpeechDataset/API] Get Speech dataset status', props<{ datasetId: string }>());
export const getDatasetStatusSucceeded = createAction('[SpeechDataset] Get Speech dataset status Succeeded ', props<{ dataset: SpeechDataset }>());
export const getDatasetStatusFailed = createAction('[SpeechDataset] [SpeechDataset] Get Speech dataset status Failed');
