import { Injectable } from '@angular/core';

import { values } from 'lodash-es';

import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { IAction } from '@common/modules/shared/interfaces';

@Injectable()
export class CustomizationUtilsService {
  constructor(private toastService: ToastService, private translateService: TranslateHelperService) {}

  public nameValidation(name: string) {
    return name && name.length < 100;
  }

  public disableActionsList(actions: IAction[], isDisabled: boolean) {
    actions.forEach((a: IAction) => {
      a.isDisabled = isDisabled;
    });
  }

  public displayToast(itemResources, translateValues) {
    this.translateService.translateResources(itemResources, translateValues).subscribe(() => {
      this.toastService.success(values(itemResources)[0], false);
    });
  }

  public displayErrorToast(error, itemResources, translateValues, isFullWidth = true) {
    this.translateService.translateResources(itemResources, translateValues).subscribe(() => {
      this.toastService.error(error, values(itemResources)[0], isFullWidth);
    });
  }
}
