import { PrivacyMode } from '@common/modules/core/interfaces';
import { APIErrors } from '@common/modules/core/services/toast/errors';
import { StreamingPreset } from '@common/modules/shared/interfaces';
import { ExcludableAIsModels, SupportedAIModels } from '@common/modules/api/interfaces';

import { IndexingPreset } from './components/shared/settings';
import { LanguageName } from '../core/interfaces';

export enum ErrorType {
  FileSize = 'FileSize',
  FileExtension = 'FileExtension'
}

export enum UploadingStateError {
  BlobStorageFailed = 'BlobStorageFailed',
  BlobStorageInitFailed = 'BlobStorageInitFailed',
  GetSasFailed = 'GetSasFailed',
  GetSasInitFailed = 'GetSasInitFailed',
  UploadToServerInitFailed = 'UploadToServerInitFailed',
  UploadToEdgeInitFailed = 'UploadToEdgeInitFailed',
  UploadToServerFailed = 'UploadToServerFailed',
  UploadToEdgeFailed = 'UploadToEdgeFailed',
  GetVideoInfoFailed = 'getVideoInfoFailed'
}

export interface IFileBlob extends File {
  uploadUrl?: string;
  sasRelativePath?: string;
  baseUrl?: string;
  relativePath?: string;
  sasToken?: string;
  blobPath?: string;
}

export interface IFile {
  id: string;
  name: string;
  fileContent?: File;
  error?: {
    errorType: ErrorType;
  };
  progress?: number;
  uploadingState?: UploadingState;
  uploadingStateError?: UploadingStateError;
  uploadingErrorType?: APIErrors;
  url?: string;
  contentMD5?: string;
  size?: number;
}

export interface IIndexingSettings {
  name?: string;
  privacy?: PrivacyMode;
  languageId?: string;
  indexingPreset?: IndexingPreset;
  excludeRAI?: boolean;
  excludeAIs?: ExcludableAIsModels[];
  streamingPreset?: StreamingPreset;
  peopleModelId?: string;
  brandsCategories?: string;
  logoGroupId?: string;
  customizationModelOption?: string;
  metadata?: string;
  description?: string;
}

export type SupportedAIsMap = {
  [key in SupportedAIModels]?: IUISupportedAis;
};

export interface IUISupportedAis extends Microsoft.VideoIndexer.Contracts.SupportedAIs {
  displayName: string;
  checked: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  info?: string;
  tooltip?: {
    show: (ai: ExcludableAIsModels) => boolean;
    text: string;
  };
}

export enum UploadMode {
  URL = 'URL',
  File = 'File'
}

export enum IndexingMode {
  Upload = 'UPLOAD',
  ReIndex = 'RE_INDEX'
}

export enum AdvancedSettings {
  General = 'GENERAL',
  FileNames = 'FILE_NAMES',
  IndexingPreset = 'INDEXING_PRESET',
  PeopleModels = 'PEOPLE_MODELS',
  BrandCategories = 'BRAND_CATEGORIES',
  LogoGroups = 'LOGO_GROUPS',
  FileInfo = 'FILE_INFO'
}

export interface IIndexingLanguage {
  name: string;
  languageCode: string;
  id: string;
  disabled: boolean;
}

export enum IndexingLanguageType {
  SpeechModel = 'SpeechModel',
  LanguageModel = 'LanguageModel',
  Auto = 'Auto',
  Language = 'Language',
  KeepSource = 'KeepSource'
}

export enum UploadingState {
  Idle = 'IDLE',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export enum LanguageAutoDetectMode {
  None = 'None',
  Single = 'Single',
  Multi = 'Multi'
}

export const defaultBrandCategory = 'Default';

export interface ILanguage {
  key: Microsoft.VideoIndexer.Contracts.LanguageV2;
  name: LanguageName;
  rtl?: boolean;
  sourceLanguage?: boolean;
  supportedUserLocale?: boolean;
  supportedCustomization?: boolean;
  padding?: number;
  type?: IndexingLanguageType;
}
