<ul class="tabs"
  role="tablist"
  [title]="resources?.InsightsTablistLabel"
  [attr.aria-label]="resources?.InsightsTablistLabel">
  <li *ngFor="let tab of tabs"
    role="none">
    <button [id]="getId(tab)"
      class="tab {{tab.styleClass}}"
      role="tab"
      (appAccessibleClick)="selectTab(tab)"
      [ngClass]="{selected: tab.selected, truncate: tab.isTruncate}"
      [attr.aria-selected]="tab.selected"
      [title]="tab.text"
      [attr.aria-label]="tab.text"
      [attr.disabled]="isDisabled || tab.disabled ? '' : null">
      {{tab.text}}
      <span class="preview"
        *ngIf="tab.preview">({{resources?.Preview}})</span>
    </button>
  </li>
</ul>
