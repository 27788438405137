import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { EMPTY, Observable, mergeMap, withLatestFrom } from 'rxjs';

import { UtilsService } from '@common/modules/shared/services/utils.service';

import { IState } from '../reducers';
import * as actions from '../actions';
import * as fromCore from '../selectors';

@Injectable({
  providedIn: 'root'
})
export class EdgeExtensionsStoreService {
  constructor(private readonly store: Store<IState>, private utilsService: UtilsService) {}

  public get edgeExtensionsMetadataLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.isEdgeExtensionsMetadataLoaded);
  }

  public get accountEdgeExtensions$(): Observable<Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension[]> {
    return this.store.select(fromCore.selectedAccountEdgeExtensions);
  }

  public get accountEdgeExtensionUrl$(): Observable<string> {
    return this.store.select(fromCore.selectedEdgeExtensionEndpointUrl);
  }

  public get selectedEdgeExtensionId$(): Observable<string> {
    return this.store.select(fromCore.selectedEdgeExtensionId);
  }

  public get selectedEdgeExtensionUrl$() {
    return this.store.select(fromCore.selectedEdgeExtension).pipe(
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      mergeMap(([extension, account]) => {
        if (!extension || !account) {
          return EMPTY;
        }

        return [this.utilsService.azurePortalResourceLink(account.tenantId, extension.id)];
      })
    );
  }

  public get selectedEdgeExtensionClusterUrl$() {
    return this.store.select(fromCore.selectedEdgeExtension).pipe(
      withLatestFrom(this.store.select(fromCore.selectedAccountContract)),
      mergeMap(([extension, account]) => {
        if (!extension || !account) {
          return EMPTY;
        }

        const clusterResourceId = this.utilsService.getParentResourceId(extension.id);

        return [this.utilsService.azurePortalResourceLink(account.tenantId, clusterResourceId)];
      })
    );
  }

  public get selectEdgeExtensionsLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.selectEdgeExtensionsLoaded);
  }

  public get selectEdgeExtensions$(): Observable<Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension[]> {
    return this.store.select(fromCore.selectEdgeExtensions);
  }

  public get hasSelectedEdgeExtension$(): Observable<boolean> {
    return this.store.select(fromCore.hasSelectedEdgeExtension);
  }

  public get hasEdgeExtensions$(): Observable<boolean> {
    return this.store.select(fromCore.hasEdgeExtensions);
  }

  public get selectedEdgeExtensionUniqueId$(): Observable<string> {
    return this.store.select(fromCore.selectedEdgeExtensionUniqueId);
  }

  public get selectedEdgeExtensionName$(): Observable<string> {
    return this.store.select(fromCore.selectedEdgeExtensionName);
  }

  public get selectedEdgeExtensionIotHubResourceId$(): Observable<string> {
    return this.store.select(fromCore.selectedEdgeExtensionIotHubResourceId);
  }

  public get isSelectedEdgeExtensionError$(): Observable<boolean> {
    return this.store.select(fromCore.isSelectedEdgeExtensionError);
  }

  public get isLiveModuleLoaded$(): Observable<boolean> {
    return this.store.select(fromCore.isLiveModuleLoaded);
  }

  public clearSelectedEdgeExtension() {
    this.store.dispatch(actions.clearSelectedEdgeExtension());
  }

  public clearEdgeExtensions() {
    this.store.dispatch(actions.clearSelectedEdgeExtension());
    this.store.dispatch(actions.clearEdgeExtensions());
  }

  public selectEdgeExtension(id: string) {
    this.store.dispatch(actions.selectEdgeExtension({ id }));
  }

  public selectEdgeExtensionClicked(id: string) {
    this.store.dispatch(actions.selectEdgeExtensionClicked({ id }));
  }

  public selectEdgeExtensionByPrincipalId(principalId: string) {
    this.store.dispatch(actions.selectEdgeExtensionByPrincipalId({ principalId }));
  }

  public uploadFile(file: File, name?: string) {
    this.store.dispatch(actions.uploadFile({ file, name }));
  }

  public liveModuleLoaded() {
    this.store.dispatch(actions.liveModuleLoaded());
  }
}
