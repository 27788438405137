import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { map } from 'lodash-es';

import { getSeconds, humanizeFromNow } from '@common/modules/utils/time';
import { StreamingPreset } from '@common/modules/shared/interfaces';

import * as fromMedia from '../reducers/media.reducer';

export interface IState {
  media: fromMedia.IState;
}

export const reducers: ActionReducerMap<IState> = {
  media: fromMedia.reducer
};

export const selectMediaState = createFeatureSelector<IState>(fromMedia.mediaFeatureKey);

export const getMediaIndexState = createSelector(selectMediaState, state => state.media);

export const getMediaIndexIds = createSelector(getMediaIndexState, state => state.ids as string[]);

export const selectedMediaIndex = createSelector(getMediaIndexState, state => state.entities[state.selectedIndexId]);

export const selectedMediaIndexId = createSelector(getMediaIndexState, state => state.selectedIndexId);

export const selectedMediaIndexSaving = createSelector(getMediaIndexState, state => state.saving);

export const selectedMediaIndexError = createSelector(getMediaIndexState, state => state.error);

export const selectAllEntities = createSelector(getMediaIndexState, state => state.entities);

export const selectAllIndexes = createSelector(selectAllEntities, entities => map(entities, index => ({ ...index })));

export const selectedMediaIndexSpeakers = createSelector(selectedMediaIndex, index => index?.videos[0]?.insights?.speakers || []);

export const selectedMediaInsightsLogos = createSelector(selectedMediaIndex, index => index?.videos[0]?.insights?.logos || []);

export const selectMediaIndexDuration = createSelector(selectedMediaIndex, index => {
  return index?.duration ? getSeconds(index?.duration) : index?.durationInSeconds;
});

export const selectIndex = createSelector(selectAllIndexes, selectedMediaIndexId, (indexes, selectedId) =>
  indexes.find(index => index.id === selectedId)
);

export const selectIndexLanguage = createSelector(selectedMediaIndex, videoIndex => {
  if (!videoIndex) {
    return 'en-US';
  } else {
    return videoIndex.videos[0].language;
  }
});

export const selectIsBase = createSelector(selectIndex, videoIndex => {
  if (!videoIndex) {
    return true;
  } else {
    return videoIndex.isBase;
  }
});

export const selectMediaDetails = createSelector(selectIndex, videoIndex => {
  if (!videoIndex) {
    return {
      mediaName: '',
      privacyMode: 'Public' as Microsoft.VideoIndexer.Contracts.PrivacyMode,
      userName: '',
      createTimeFormated: undefined,
      moderationState: undefined,
      accountId: '',
      videoId: '',
      isBase: false
    };
  }
  return {
    createTimeFormated: humanizeFromNow(videoIndex.created),
    moderationState: videoIndex.videos && videoIndex.videos[0] && videoIndex.videos[0].moderationState ? videoIndex.videos[0].moderationState : null,
    userName: videoIndex.userName,
    privacyMode: videoIndex.privacyMode,
    mediaName: videoIndex.name,
    accountId: videoIndex.accountId,
    videoId: videoIndex.id,
    isBase: videoIndex.isBase
  };
});

export const selectMediaActionsData = createSelector(selectIndex, videoIndex => {
  if (!videoIndex) {
    return {
      name: '',
      accountId: '',
      id: '',
      isBase: false,
      isEditable: false,
      videos: [],
      privacyMode: undefined
    };
  }
  return {
    name: videoIndex.name,
    accountId: videoIndex.accountId,
    id: videoIndex.id,
    isBase: videoIndex.isBase,
    isEditable: videoIndex.isEditable,
    videos: videoIndex.videos,
    privacyMode: videoIndex.isBase ? videoIndex.privacyMode : 'Private'
  };
});

export const selectMediaAlertData = createSelector(selectIndex, videoIndex => {
  return {
    accountId: videoIndex ? videoIndex.accountId : '',
    id: videoIndex ? videoIndex.id : '',
    moderationState: videoIndex ? videoIndex.videos[0].moderationState : undefined,
    reviewState: videoIndex ? videoIndex.videos[0].reviewState : undefined,
    isNoStreaming: isNoStreaming(videoIndex)
  };
});

export const selectInsightsFilterValue = createSelector(getMediaIndexState, state => state.insightsFilterValue);

// eslint-disable-next-line deprecation/deprecation
export const selectFilteredLogos = createSelector(
  selectedMediaInsightsLogos,
  selectInsightsFilterValue,
  (logos: Microsoft.VideoIndexer.Contracts.Logo[], value) => {
    const mappedLogos = logos.map((l, i) => {
      return { ...l, spriteIndex: i };
    });

    if (!value) {
      return mappedLogos;
    }

    return [
      ...mappedLogos?.filter(l => {
        const appearances = l.instances?.filter(appearance => {
          return appearance.matchedValue?.toLowerCase().includes(value?.toLowerCase());
        });
        return l.name?.toLowerCase().includes(value?.toLowerCase()) || appearances?.length;
      })
    ];
  }
);

// Util function
const isNoStreaming = videoIndex => {
  if (!videoIndex) {
    return false;
  }
  // If there is a streaming preset data
  if (videoIndex?.videos[0]?.streamingPreset) {
    return videoIndex?.videos[0]?.streamingPreset === StreamingPreset.NoStreaming;
  } else {
    // No streaming preset data, base on published URL
    return !videoIndex?.videos[0]?.publishedUrl;
  }
};
