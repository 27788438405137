import { NavigationState, VIThemes } from '@common/modules/core/services/interfaces';
export interface ISidebarNavigationOptions {
  state: NavigationState;
  page?: SidebarPages;
  callbackAfterClose?: () => void;
}

export interface ISidebarNavigationOptions {
  state: NavigationState;
  page?: SidebarPages;
  callbackAfterClose?: () => void;
}

export enum SidebarPages {
  ACCOUNTS = 'ACCOUNTS',
  SETTINGS = 'SETTINGS',
  HELP = 'HELP',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ANY = 'ANY'
}

export interface IVIThemeOption {
  selected: boolean;
  themeName: VIThemes;
  svgPath: VIThemes;
}

export interface IUIRectElement {
  class: string;
  x: string;
  y: string;
  height: string;
  width: string;
}

export enum UINotificationsActionType {
  CLEAR = 'CLEAR'
}
