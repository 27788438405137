import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../../actions/people/unknown-person.actions';

import UnknownPersonsJob = Microsoft.VideoIndexer.Contracts.UnknownPersonsJob;
import UnknownPerson = Microsoft.VideoIndexer.Contracts.UnknownPerson;
import UnknownPersonDetails = Microsoft.VideoIndexer.Contracts.UnknownPersonDetails;

export enum UnknownPersonTableListError {
  LOAD_MORE_ERROR = 'LOAD_MORE_ERROR',
  SEARCH_ERROR = 'SEARCH_ERROR',
  GET_LIST_ERROR = 'GET_LIST_ERROR'
}
export interface IState extends EntityState<UnknownPerson> {
  page: number;
  pageSize: number;
  isLastPage: boolean;
  loaded: boolean;
  jobDataLoaded: boolean;
  tableLoaded: boolean;
  tableListError: UnknownPersonTableListError;
  searching: boolean;
  updating: boolean;
  error: boolean;
  job: UnknownPersonsJob;
  selectedUnknownPerson: UnknownPersonDetails;
}

function selectUnknownPersonId(a: UnknownPerson): string {
  return a.id;
}

const adapter = createEntityAdapter<UnknownPerson>({
  selectId: selectUnknownPersonId
});

export const initialState: IState = adapter.getInitialState({
  page: 0,
  pageSize: 25,
  isLastPage: true,
  loaded: false,
  jobDataLoaded: false,
  tableLoaded: false,
  tableListError: null,
  searching: false,
  updating: false,
  error: false,
  job: null,
  selectedUnknownPerson: null
});

const unknownPersonsReducer = createReducer(
  initialState,

  on(actions.clearUnknownPersons, (_, {}) => {
    return adapter.removeAll({
      ...initialState
    });
  }),

  on(actions.submitUnknownPersonsJobSucceeded, (state, { job }) => {
    return {
      ...state,
      job,
      jobDataLoaded: true,
      searching: false
    };
  }),

  on(actions.submitUnknownPersonsJobFailed, (state, { error, job }) => {
    return {
      ...state,
      jobDataLoaded: true,
      searching: false
    };
  }),

  on(actions.getUnknownPersonsJobSucceeded, (state, { job }) => {
    return {
      ...state,
      job,
      jobDataLoaded: true,
      searching: false
    };
  }),

  on(actions.getUnknownPersonsJobFailed, (state, { error }) => {
    return {
      ...state,
      jobDataLoaded: true,
      searching: false
    };
  }),

  on(actions.getUnknownPersonSucceeded, (state, { unknownPerson }) => {
    return adapter.updateOne({ id: unknownPerson.id, changes: unknownPerson }, state);
  }),

  on(actions.listUnknownPersonsSucceeded, (state, { unknownPersons }) => {
    const newState = {
      ...state,
      page: unknownPersons.results?.length ? state.page + 1 : state.page,
      isLastPage: unknownPersons.nextPage?.done,
      loaded: true,
      tableLoaded: true,
      tableListError: null
    };
    return adapter.addMany(unknownPersons.results, newState);
  }),

  on(actions.listUnknownPersonsFailed, (state, { error }) => {
    const newState = { ...state, loaded: true, tableLoaded: true };
    if (state.page > 0) {
      return {
        ...newState,
        tableListError: UnknownPersonTableListError.LOAD_MORE_ERROR
      };
    }
    // Stimulate empty list
    return adapter.removeAll({
      ...newState,
      isLastPage: true,
      tableListError: state.searching ? UnknownPersonTableListError.SEARCH_ERROR : UnknownPersonTableListError.GET_LIST_ERROR
    });
  }),

  on(actions.selectUnknownPerson, (state, { unknownPerson }) => {
    return {
      ...state,
      selectedUnknownPerson: unknownPerson
    };
  }),

  on(actions.updateUnknownPersonSucceeded, (state, { unknownPerson }) => {
    return adapter.updateOne({ id: unknownPerson.id, changes: unknownPerson }, { ...state, updating: false });
  }),

  on(actions.deleteUnknownPersonSucceeded, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),

  on(actions.moveUnknownPersonToModel, (state, {}) => {
    return {
      ...state,
      updating: true
    };
  }),

  on(actions.moveUnknownPersonToModelSucceeded, (state, { unknownPerson }) => {
    return {
      ...state,
      updating: false
    };
  }),

  on(actions.moveUnknownPersonToModelFailed, (state, {}) => {
    return {
      ...state,
      updating: false
    };
  }),
  on(actions.searchUnknownPersons, (state, {}) => {
    return adapter.removeAll({
      ...initialState,
      loaded: state.loaded,
      jobDataLoaded: state.jobDataLoaded,
      tableLoaded: false,
      searching: true
    });
  }),

  on(actions.clearTableListError, state => {
    return {
      ...state,
      tableListError: null
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return unknownPersonsReducer(state, action);
}
