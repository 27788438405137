import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ToastService } from '@common/modules/core/services/toast/toast.service';

import { resources } from './resources';

@Injectable()
export class UserService {
  public resources = resources;

  constructor(private translate: TranslateHelperService, private toastService: ToastService) {
    this.setTranslationsSubscription();
  }

  public toastUserSettingsSaveSuccessfully() {
    this.toastService.success(this.resources.AppSettingsSaveSuccessfully, false);
  }

  private setTranslationsSubscription() {
    this.translate
      .translateResources(this.resources)
      .pipe(take(1))
      .subscribe(() => {});
  }
}
