// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * mapping for Arm accounts region
 * when calling data place APIs, we will use this location
 * instead of the location of the account
 */
export const devEnvironmentMapping = [
  {
    url: 'vi-fe-dev1.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev1'
  },
  {
    url: 'vi-fe-dev2.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev2'
  },
  {
    url: 'vi-fe-dev3.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev3'
  },
  {
    url: 'vi-fe-dev4.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev4'
  },
  {
    url: 'vi-fe-dev5.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev5'
  },
  {
    url: 'vi-fe-dev6.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev6'
  },
  {
    url: 'vi-fe-dev9.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev9'
  },
  {
    url: 'vi-fe-dev10.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev10'
  },
  {
    url: 'vi-fe-dev11.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev11'
  },
  {
    url: 'vi-fe-dev12.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev12'
  },
  {
    url: 'vi-fe-dev13.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev13'
  },
  {
    url: 'vi-fe-dev15.cloudapp.azure.com',
    location: 'westus2',
    name: 'dev15'
  }
];

export const environment = {
  production: false,
  logger: true,
  apiBase: 'https://localhost:44303/',
  localhost: false,
  debug: true,
  mapArmLocation: true
};
