import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { AccessibilityUtilsService } from './accessibility-utils.service';

@Directive({
  selector: '[appAccessibilitySubMenuHandler]'
})
export class SubMenuHandlerDirective {
  @Input('appAccessibilitySubMenuHandler') public appAccessibilitySubMenuHandler: boolean;
  @Input() public toggleFuncOnNullRelatedTarget = false;
  @Output() public toggleFunc: EventEmitter<Function> = new EventEmitter();

  constructor(private elRef: ElementRef, private accessibilityUtils: AccessibilityUtilsService) {}

  @HostListener('focusout', ['$event']) public onFocusOut(event: FocusEvent) {
    if (this.appAccessibilitySubMenuHandler) {
      // e.relatedTarget property of focusOut event returns which element is being entered
      const relatedTarget = event.relatedTarget;
      const htmlPageElement = document?.querySelector('html');
      // If related target exist and not an html page
      if (relatedTarget !== null && relatedTarget !== htmlPageElement) {
        const parents = this.accessibilityUtils.getParents(relatedTarget);

        // If sub window focused out-
        if (parents.indexOf(this.elRef.nativeElement) === -1) {
          // toggle sub window
          this.toggleFunc.emit();
        }
      } else if (this.toggleFuncOnNullRelatedTarget) {
        this.toggleFunc.emit();
      }
    }
  }
}
