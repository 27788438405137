import { UIActionType } from '@common/modules/insights/interfaces';
import { UIFilterType } from '@common/modules/shared/components/filter-item/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import * as utils from '@common/modules/utils/time';

export const MAX_FILTERS = 5;

export enum IGalleryTab {
  Library = 0,
  Projects,
  Samples
}

export enum IGalleryView {
  Grid = 'Grid',
  List = 'List',
  Search = 'Search'
}

export enum UIGalleryActionType {
  INDEXING_SETTINGS = 'INDEXING_SETTINGS',
  DELETE_VIDEO = 'DELETE_VIDEO',
  REINDEX_VIDEO = 'REINDEX_VIDEO',
  EDIT_VIDEO = 'EDIT_VIDEO',
  PLAY_VIDEO = 'PLAY_VIDEO',
  COPY_FILE_ID = 'COPY_FILE_ID',
  OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB',
  PEOPLE_FILTER = 'PEOPLE_FILTER',
  TOPICS_FILTER = 'TOPICS_FILTER',
  KEYWORDS_FILTER = 'KEYWORDS_FILTER',
  EMOTIONS_FILTER = 'EMOTIONS_FILTER',
  SENTIMENTS_FILTER = 'SENTIMENTS_FILTER',
  LABELS_FILTER = 'LABELS_FILTER',
  SPEAKERS_FILTER = 'SPEAKERS_FILTER',
  TRANSCRIPT_FILTER = 'TRANSCRIPT_FILTER',
  OCR_FILTER = 'OCR_FILTER',
  FILE_NAME_FILTER = 'FILE_NAME_FILTER',
  CUSTOM_TAGS_FILTER = 'CUSTOM_TAGS_FILTER',
  NAMED_PEOPLE_FILTER = 'NAMED_PEOPLE_FILTER',
  BRANDS_FILTER = 'BRANDS_FILTER',
  NAMED_LOCATIONS_FILTER = 'NAMED_LOCATIONS_FILTER',
  SEARCH_CHANNEL_FILTER = 'SEARCH_CHANNEL_FILTER',
  SEARCH_OWNER_FILTER = 'SEARCH_OWNER_FILTER',
  ALL_INSIGHTS_FILTER = 'ALL_INSIGHTS_FILTER',
  DETECTED_OBJECTS_FILTER = 'DETECTED_OBJECTS_FILTER'
}

export enum UIGalleryTabsActionType {
  GRID_VIEW = 'GRID_VIEW',
  LIST_VIEW = 'LIST_VIEW'
}

export interface IVideoItem {
  name: string;
  createTime: number;
  createDate: string;
  createTimeFormated: string;
  lastIndexTimeFormatted: string;
  lastIndexedDate?: string;
  lastModifiedTimeFormatted?: string;
  privacyModeText: string;
  lastModified: string;
  userName: string;
  shortName: string;
  privacyMode: Microsoft.VideoIndexer.Contracts.PrivacyMode;
  accountId: string;
  sourceLanguage: string;
  sourceLanguages: string[];
  shortUserName: string;
  indexingPreset: string;
  streamingPreset?: string;
  state: string;
  moderationState: Microsoft.VideoIndexer.Contracts.ModerationState;
  reviewState: Microsoft.VideoIndexer.Contracts.ReviewState;
  reviewStateText?: string;
  processingProgress: string;
  progress: number;
  searchable?: boolean;
  uploadedByMe: boolean;
  isOwned: boolean;
  durationInSeconds: number;
  durationText: string;
  id: string;
  isBase: boolean;
  thumbnail: string;
  personModelId?: string;
  lastIndexed: string;
  deleteInProcess?: boolean;
  type?: VideoItemType;
  searchMatches?: Microsoft.VideoIndexer.Contracts.SearchMatch[];
  isExpanded?: boolean;
  thumbnailId?: string;
  isEditorSupported?: boolean;
  isEmptyProject?: boolean;
  maxConfidence?: Microsoft.VideoIndexer.Contracts.MaxConfidence;
  info?: {
    mediasCount: number;
    participantsCount: number;
    facesCount: number;
    snippetsCount: number;
    highlights: string;
  };
}

export const emptyProjectItem: Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2 = {
  accountId: 'accountId',
  id: 'emptyProjectItem',
  partition: '',
  externalId: '',
  metadata: '',
  name: 'Create new project',
  description: '',
  created: '',
  lastModified: '',
  lastIndexed: '',
  privacyMode: null,
  userName: '',
  isOwned: false,
  isBase: false,
  state: null,
  moderationState: null,
  reviewState: null,
  processingProgress: '',
  durationInSeconds: 0,
  thumbnailVideoId: '',
  thumbnailId: '',
  searchMatches: null,
  indexingPreset: '',
  streamingPreset: '',
  sourceLanguage: null,
  sourceLanguages: null,
  personModelId: null,
  isEmptyProject: true
};

export enum VideoItemType {
  Account = 'Account',
  Project = 'Project',
  Sample = 'Sample'
}

export enum IGalleryFilterType {
  DROP_DOWN = 'DROP_DOWN',
  TEXT = 'TEXT',
  TIME = 'TIME'
}

export enum IGalleryFilterInsightType {
  People = 'people',
  Owner = 'owner',
  Language = 'language'
}

export interface IGalleryFilter {
  placeHolderText: string;
  placeHolderKey: string;
  filterAction: IAction;
  type: UIFilterType;
  enableMultipleSelection?: boolean; // Relevant only for DROP_DOWN
  placeHolderIcon?: string;
}

export interface INextPage {
  pageSize: number;
  skip: number;
  done: boolean;
}

export interface IPlayerPlaylistItemTimeRange {
  end: number;
  start: number;
}

export interface IDelete {
  id: string;
  index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2;
}

export class EventTimeRange {
  public start: string;
  public end: string;
  public startInSeconds: number;
  public endInSeconds: number;
  public fStart: string;
  public fEnd: string;
  public fStartInSeconds: number;
  public fEndInSeconds: number;

  constructor(tr?: Microsoft.VideoIndexer.Contracts.ElementInstance) {
    this.startInSeconds = tr ? utils.getSeconds(tr.start) : -1;
    this.endInSeconds = tr ? utils.getSeconds(tr.end) : -1;
    this.start = tr ? tr.start.toString() : '';
    this.end = tr ? tr.end.toString() : '';
    this.fStart = tr ? tr.adjustedStart.toString() : '';
    this.fEnd = tr ? tr.adjustedEnd.toString() : '';
    this.fStartInSeconds = tr ? utils.getSeconds(tr.adjustedStart) : -1;
    this.fEndInSeconds = tr ? utils.getSeconds(tr.adjustedEnd) : -1;
  }
}

export const scopeFilterIssue: IGalleryFilter[] = [
  {
    placeHolderText: 'Enter scope',
    placeHolderKey: '',
    type: UIFilterType.DROP_DOWN,
    enableMultipleSelection: false,
    filterAction: {
      icon: '',
      title: 'Scope',
      key: 'VideosGalleryScopeFilter',
      type: UIGalleryActionType.SEARCH_CHANNEL_FILTER,
      value: '',
      selected: false,
      selectable: true,
      hasDrop: true,
      id: 'channel',
      applyValue: true
    }
  }
];

export const peopleFilterIssue: IGalleryFilter = {
  placeHolderText: 'Enter person name',
  placeHolderKey: 'VideosGalleryEnterPersonNamePlaceholder',
  type: UIFilterType.TEXT,
  filterAction: {
    title: 'People',
    key: 'VideosGalleryPeopleFilter',
    selected: false,
    type: UIGalleryActionType.PEOPLE_FILTER,
    value: IGalleryFilterInsightType.People,
    icon: '',
    selectable: true,
    id: 'people'
  }
};

export const ownerFilterIssue: IGalleryFilter = {
  placeHolderText: 'Enter owner name',
  placeHolderKey: 'VideosGalleryEnterOwnerNamePlaceholder',
  type: UIFilterType.TEXT,
  filterAction: {
    title: 'Owner',
    key: 'VideosGalleryOwnerFilter',
    selected: false,
    type: UIGalleryActionType.SEARCH_OWNER_FILTER,
    value: IGalleryFilterInsightType.Owner,
    icon: '',
    selectable: true,
    id: 'owner'
  }
};

export const languageFilterIssue: IGalleryFilter = {
  placeHolderText: 'Enter language',
  placeHolderKey: 'VideosGalleryEnterLanguageNamePlaceholder',
  type: UIFilterType.DROP_DOWN,
  enableMultipleSelection: false,
  filterAction: {
    icon: '',
    title: 'Language',
    key: 'EditorTranslate',
    type: UIActionType.LANGUAGE,
    value: IGalleryFilterInsightType.Language,
    selected: false,
    selectable: true,
    hasDrop: true,
    id: 'language',
    applyValue: true
  }
};

export const filterIssues: IGalleryFilter[] = [peopleFilterIssue, ownerFilterIssue, languageFilterIssue];

export enum GalleryProcessingProgress {
  PROCESSED = '100%',
  DELETING = 'Deleting'
}
