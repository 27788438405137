import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ILanguageListContract } from '../reducers/supported-languages.reducer';

export const upsertSupportedLanguages = createAction(
  '[SupportedLanguage] Upsert Language Model',
  props<{ supportedLanguages: ILanguageListContract[] }>()
);

export const updateSupportedLanguage = createAction(
  '[SupportedLanguage] Update Language Models',
  props<{ supportedLanguage: Update<ILanguageListContract> }>()
);
