export const resources = {
  IndexingPaidHighRiskFaceGateMessage: '',
  IndexingPaidFaceGateLink: '',
  IndexingPaidFaceGateMessage: '',
  IndexingTrialFaceGateMessage: '',
  IndexingOldTrialFaceGateMessage: '',
  IndexingOldPaidHighRiskFaceGateMessage: '',
  IndexingOldPaidFaceGateMessage: '',
  IndexingPeopleModelsTrialFaceGateMessage: '',
  IndexingPeopleModelsPaidFaceGateMessage: '',
  IndexingPeopleModelsPaidHighRiskFaceGateMessage: '',
  IndexingPeopleModelsTrialFaceGateLink: '',
  CreateAccountWithAzure: ''
};
