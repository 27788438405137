/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnDestroy
} from '@angular/core';

import { FocusableComponent } from '@vi-ui/core';
import { provideFluentDesignSystem, fluentSelect, fluentOption, selectStyles, OptionStyles } from '@fluentui/web-components';
import { SelectPosition } from '@microsoft/fast-foundation';
import { css } from '@microsoft/fast-element';

import { guid } from '@common/modules/utils/string';

import { IAction, IGroupAction } from '../../interfaces';
import { indicatorSvg, viOptionStyle, viSelectStyle } from './styles';
import { SelectComponentAppearance, SelectComponentIndicatorSize, SelectComponentSize } from './interfaces';

@Component({
  selector: 'vi-fluent-ui-select',
  templateUrl: './fluent-ui-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./fluent-ui-select.component.scss']
})
export class FluentUiSelectComponent extends FocusableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  // Main user action
  @Input() public action: IAction;
  @Input() public selectedAction: IAction;
  // Action list
  @Input() public actionList: IAction[] = [];

  @Input() public placeholder = '';
  @Input() public position: SelectPosition = SelectPosition.below;
  @Input() public group = false;
  @Input() public isDisabled = false;
  @Input() public isError = false;
  @Input() public ariaLabelledBy = null;
  @Input() public ariaLabel? = null;
  @Input() public appearance = SelectComponentAppearance.Outline;
  @Input() public type: string = ''; //TODO: remove it when we upgrading to latest version of fluent-ui. this is a workaround for underline appearance
  @Input() public size: SelectComponentSize = SelectComponentSize.Medium;
  @Input() public indicatorSize: SelectComponentIndicatorSize = SelectComponentIndicatorSize.Medium;
  @Input() public isIconOnly = false;
  @Input() public placeholderAsFirstAction = false;
  @Input() public id = guid();

  @Output() public selectedActionChange = new EventEmitter<IAction>(true);
  @Output() public hovered = new EventEmitter<boolean>();
  @Output() public menuOpen = new EventEmitter<boolean>();

  @ViewChild('FluentSelect', { read: ElementRef, static: false }) public fluentSelect: ElementRef;

  private listboxState$: MutationObserver;

  public constructor() {
    super();
  }

  public ngOnInit() {
    provideFluentDesignSystem().register(
      fluentSelect({
        indicator: indicatorSvg,
        styles: (ctx, def) => css`
          ${selectStyles(ctx, def as any)}
          ${viSelectStyle}
        `
      }),
      fluentOption({
        styles: (ctx, def) => css`
          ${OptionStyles(ctx, def as any)}
          ${viOptionStyle}
        `
      })
    );

    if (!this.selectedAction) {
      this.selectedAction = this.actionList[0];
    }

    if (this.group) {
      // Create group actions
      this.createGroupActions();
    }
  }

  public ngAfterViewInit() {
    this.listboxState$ = new MutationObserver(mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
          this.menuOpen.emit(this.fluentSelect.nativeElement.open);
        }
      }
    });

    this.listboxState$.observe(this.fluentSelect.nativeElement, {
      attributeFilter: ['aria-expanded']
    });
  }

  public ngOnDestroy() {
    this.listboxState$.disconnect();
  }

  public ngOnChanges(change: SimpleChanges) {
    if (change?.actionList?.currentValue && change?.actionList?.previousValue) {
      if (this.selectedAction) {
        // The fluent select stores the selectedIndex. When the list changes, we need to manually update this value as it won't automatically refresh.
        const selectedIndex = this.actionList.findIndex(action => action.id === this.selectedAction.id);
        this.fluentSelect.nativeElement.selectedIndex = selectedIndex;

        // fallback to the first action if the selected action is not found in the list
        if (selectedIndex === -1) {
          this.selectedActionChange.emit(this.actionList[0]);
        }
      }

      // If list has been updated and has group, create group actions
      if (this.group) {
        this.createGroupActions();
      }
    }
  }

  public handleOnHover(isHover) {
    this.hovered.emit(isHover);
  }

  public handleOnChange(event: CustomEvent) {
    const index = event?.detail._selectedIndex;
    this.selectedActionChange.emit(this.actionList[index]);
  }

  public get icon() {
    return this.selectedAction?.icon || this.action?.icon;
  }

  private createGroupActions() {
    let currentGroup = '';
    const items = [];

    for (const action of this.actionList) {
      if (action.group !== currentGroup) {
        currentGroup = action.group;
        const groupItem: IGroupAction = {
          id: currentGroup,
          isGroup: true,
          value: currentGroup,
          title: currentGroup,
          isDisabled: true
        };
        items.push(groupItem);
      }

      action.titleAttribute = `${currentGroup} ${action.title}`;
      items.push(action);
    }

    this.actionList = items;
  }
}
