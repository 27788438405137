import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { StorageCacheKey } from '@common/modules/shared/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { DefaultSupportedLanguages } from '../../core/languages';
import * as fromCore from '../../core/selectors';
import * as LanguageIDActions from '../actions/language-id.actions';
import { IState } from '../reducers';
import * as fromLanguageId from '../selectors/language-id.selectors';
import * as fromSupportedLanguagesIds from '../selectors/supported-languages-ids.selectors';

@Injectable()
export class LanguageIdEffects {
  public loadDefaultLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageIDActions.loadDefaultLanguages),
      switchMap(() => {
        const defaultLanguages = cloneDeep(DefaultSupportedLanguages);
        defaultLanguages.forEach(l => {
          l.name = this.translateService.getLocaleLanguage(l.languageCode);
        });
        return [LanguageIDActions.upsertDefaultLanguages({ defaultLanguages })];
      })
    )
  );

  public loadSelectedLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageIDActions.loadSelectedLanguages),
      withLatestFrom(this.store.select(fromSupportedLanguagesIds.selectSupportedLanguagesIds)),
      withLatestFrom(this.store.select(fromLanguageId.selectDefaultLanguages)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[[, supportedLanguagesIds], defaultLanguages], accountId]) => {
        this.logger.log('[LanguageIdEffects] load selected languages');
        this.trackService.track('languageId.load.success', {
          category: EventCategory.Language_ID
        });

        // 1. In case there is value for the current accountId return the value including empty array
        //    Save the default langs in the local storage. Default langs can't be empty.
        // 2. If no value, return the default supported languages
        const selectedLocalLanguagesId = this.localStorageService.getItem(`${StorageCacheKey.LanguageId}_${accountId}`);
        if (!selectedLocalLanguagesId && accountId && defaultLanguages?.length) {
          this.localStorageService.setItem(`${StorageCacheKey.LanguageId}_${accountId}`, JSON.stringify(defaultLanguages.map(l => l.languageCode)));
          return [LanguageIDActions.setSelectedLanguages({ selectedLanguages: Object.values(defaultLanguages) })];
        }
        const selectedLanguages = [];
        if (selectedLocalLanguagesId?.length && supportedLanguagesIds?.length) {
          const parsedLangs = JSON.parse(selectedLocalLanguagesId);
          // Set the list by the order user selected.
          parsedLangs.forEach(languageCode => {
            const selectedLanguage = supportedLanguagesIds.find(language => language.languageCode === languageCode);
            if (selectedLanguage) {
              selectedLanguages.push(selectedLanguage);
            }
          });
        }
        return [LanguageIDActions.setSelectedLanguages({ selectedLanguages: selectedLanguages })];
      })
    )
  );

  public deleteSelectedLanguageId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageIDActions.deleteSelectedLanguage),
      withLatestFrom(this.store.select(fromLanguageId.selectSelectedLanguages)),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[{ languageCode }, selectedLanguages], accountId]) => {
        // After delete from store, update the langs list in the local storage
        this.localStorageService.setItem(`${StorageCacheKey.LanguageId}_${accountId}`, JSON.stringify(selectedLanguages.map(l => l.languageCode)));
        this.logger.log(`[LanguageIdEffects] Delete language ${languageCode} success`);
        this.trackService.track('languageId.delete_language.success', {
          category: EventCategory.Language_ID,
          data: {
            languageCode: languageCode
          }
        });
        return [LanguageIDActions.deleteSelectedLanguageSuccess()];
      })
    )
  );

  public addSelectedLanguageId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageIDActions.addSelectedLanguage),
        withLatestFrom(this.store.select(fromLanguageId.selectSelectedLanguages)),
        withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
        tap(([[{ selectedLanguage }, selectedLanguages], accountId]) => {
          // After delete from store, update the langs list in the local storage
          this.localStorageService.setItem(`${StorageCacheKey.LanguageId}_${accountId}`, JSON.stringify(selectedLanguages.map(l => l.languageCode)));
          this.logger.log(`[LanguageIdEffects] Add language ${selectedLanguage.languageCode} success`);
          this.trackService.track('languageId.add_language.success', {
            category: EventCategory.Language_ID,
            data: {
              languageCode: selectedLanguage.languageCode
            }
          });
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private store: Store<IState>,
    private actions$: Actions,
    private logger: LoggerService,
    private localStorageService: LocalStorageService,
    private trackService: TrackService,
    public translateService: TranslateHelperService
  ) {}
}
