import { css } from '@microsoft/fast-element';

export const viSelectStyle = css`
  :host-context(.indicator-small) {
    .indicator {
      svg {
        transform: scale(0.7);
      }
    }
  }
  .control .indicator {
    margin-inline-start: 8px;
  }
  .start {
    margin-inline-end: 8px;
  }
  .listbox::-webkit-scrollbar {
    width: 4px;
  }
  .listbox::-webkit-scrollbar-thumb {
    border-left: 0px solid transparent !important;
    background-clip: padding-box !important;
    background-color: var(--type-disabled-alt) !important;
  }
`;

export const viOptionStyle = css`
  .start ::slotted([slot='start']) {
    margin-inline-end: 8px;
  }
`;

export const indicatorSvg = `
<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 9.71484L0.152344 3.85547L0.855469 3.15234L6 8.28516L11.1445 3.15234L11.8477 3.85547L6 9.71484Z"></path>
</svg>
`;
