export const resources = {
  AppSettingsPrivate: '',
  AppSettingsPublic: '',
  UploadPrivacyLabel: '',
  UploadStreaming: '',
  UploadServiceSingleBiterate: '',
  UploadServiceNoStreaming: '',
  IndexingFileNameLabel: '',
  UploadEnterURLLabel: '',
  Add: '',
  UploadGuidelinesLink2: '',
  UploadEnterUrlPlaceholder: '',
  UploadEnterAnotherUrlPlaceholder: '',
  IndexingMultiFilesLabel: '',
  IndexingMultiFilesLabelDesc: '',
  IndexingAddFilesButton: '',
  IndexingMaxFilesLimitTooltip: '',
  IndexingRemoveFileButtonTooltip: '',
  IndexingInvalidUrlValidationError: '',
  UploadInvalidUrl: '',
  IndexingDuplicateFileValidationError: '',
  UploadInvalidFileExtensionMessage: '',
  UploadInvalidURLFileSizeMessage: '',
  UploadInvalidFileSizeMessage: ''
};
