import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { FeatureSwitch, IUserDetails } from '@common/modules/core/services/interfaces';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { VIRoutingMap } from '../../../app/routing/routes';
import { signOutAction, switchDirectoryAction } from '../header/actions';
import { resources } from './resources';

@Component({
  selector: 'app-vi-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit {
  // Input
  @Input() public userDetails: IUserDetails;
  @Input() public show: boolean;

  @Output() public close = new EventEmitter();

  // Public
  public resources = resources;

  public signOutAction = signOutAction;
  public switchDirectoryAction = switchDirectoryAction;
  public ready = false;
  public ActionButtonType = ActionButtonType;
  public isSwitchDirectoryEnabled = false;
  private dialogRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateHelperService,
    private coreStore: CoreStoreService,
    private dialog: MatDialog,
    private focusManager: FocusManagerService,
    private dialogService: DialogService,
    private featureSwitchService: FeatureSwitchService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.setTranslation();
    this.isSwitchDirectoryEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.SwitchDirectory) && this.authService.isAad();
  }

  public closeMenu() {
    this.close.emit();
  }

  public signOut() {
    this.coreStore.navigate([`/${VIRoutingMap.logout.path}`]);
  }

  public async openSwitchDirectoryDialog() {
    const onCloseCallBack = (): void => {
      this.focusManager.focusByQuery(`#userButton`, this.cdr);
    };

    // lazy load SwitchDirectoryComponent component
    const { SwitchDirectoryComponent } = await import('../../../shell/components/switch-directory/switch-directory.component');

    const dialogData = this.dialogService.getDialogData(
      'switch-directory-dialog',
      this.resources.SwitchDirectory,
      null,
      null,
      null,
      null,
      SwitchDirectoryComponent
    );

    this.dialogRef = this.dialog.open(DialogComponent, {
      width: '90vw',
      height: '',
      maxHeight: '90vh',
      maxWidth: '980px',
      minHeight: '276px',
      data: dialogData,
      disableClose: true,
      ariaLabel: dialogData.title,
      panelClass: 'switch-directory-container'
    });

    this.dialogService.addBackgroundToOverlay();

    if (onCloseCallBack) {
      this.dialogRef.afterClosed().subscribe(() => {
        onCloseCallBack();
      });
    }
  }

  private setTranslation(): void {
    this.translate.translateResourcesInstant(this.resources);
    this.signOutAction.title = this.resources.HeaderSignOut;
    this.switchDirectoryAction.title = this.resources.SwitchDirectory;
    this.ready = true;
  }
}
