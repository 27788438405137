import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * Prettify a JSON object so it can be rendered in HTML while preserving spaces
 */
@Pipe({
  name: 'jsonPrettify'
})
export class JsonPrettifyPipe implements PipeTransform {
  transform(json: object): string {
    if (!json) {
      return null;
    }

    return JSON.stringify(json, undefined, 4).replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
  }
}
