import { SpecialLanguageKey } from '../../core/languages';
import { IIndexingLanguage } from '../interfaces';

// Keep source language id (used in the UI for ReIndex)
export const KEEP_SOURCE_ID = 'keepSource';

// Sort by language title function
// Put first keep source and then auto detects (single and multi) at the top of the list.
export const sortLanguageCompare = (a: IIndexingLanguage, b: IIndexingLanguage) =>
  a.languageCode === KEEP_SOURCE_ID
    ? -1
    : b.languageCode === KEEP_SOURCE_ID
    ? 1
    : a.languageCode === SpecialLanguageKey.AUTO && b.languageCode === SpecialLanguageKey.MULTI
    ? a.name.localeCompare(b.name)
    : a.languageCode === SpecialLanguageKey.MULTI || a.languageCode === SpecialLanguageKey.AUTO
    ? -1
    : b.languageCode === SpecialLanguageKey.MULTI || b.languageCode === SpecialLanguageKey.AUTO
    ? 1
    : a.name.localeCompare(b.name);
