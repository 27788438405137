import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, withLatestFrom, EMPTY, catchError, tap, take, of, filter, delay, exhaustMap, takeUntil } from 'rxjs';

import { ToastService } from '@common/modules/core/services/toast/toast.service';
import { INotification, NotificationLevel } from '@common/modules/core/services/toast/interfaces';
import { TimeInterval } from '@common/modules/utils/time';
import { ApiService } from '@common/modules/api/services/api.service';
import { IApiSasContract } from '@common/modules/api/interfaces';
import { EventCategory } from '@common/modules/core/services/track';
import { TrackService } from '@common/modules/core/services/track/track.service';
import { TRANSLATION_DELAY } from '@common/modules/translation/variables';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { DownloadService } from '@common/modules/shared/components/download-menu/download-service.service';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import { guid } from '@common/modules/utils/string';

import { NotificationMessageType, NotificationType, NotificationIcon } from './../../../../../../common/modules/core/services/toast/interfaces';
import { UIDatasetState, SpeechObjectState } from './../../interfaces';
import { ISpeechDatasetDetails } from '../../reducers/speech/interfaces';
import { IState } from '../../../core/reducers';
import * as fromCore from '../../../core/selectors';
import * as fromCustomizationData from '../../selectors';
import * as SpeechDatasetsActions from '../../actions/speech/speech-datasets.actions';
import * as SpeechModelsActions from '../../actions/speech/speech-models.actions';
import { resources } from './resources';
import { UploadService } from '../../../indexing/services/upload.service';
import { AzureBlobStorageService } from '../../../indexing/services/azure-blob-storage.service';
import { IFileBlob } from '../../../indexing/interfaces';
import { IUISpeechDatasetInput } from '../../../customization/interfaces';
import { SpeechErrorsService } from '../../services/speech/speech-errors.service';

import SpeechDataset = Microsoft.VideoIndexer.Contracts.SpeechDataset;
import SpeechDatasetFileContent = Microsoft.VideoIndexer.Contracts.SpeechDatasetFileContent;
import SpeechDatasetInput = Microsoft.VideoIndexer.Contracts.SpeechDatasetInput;

@Injectable()
export class SpeechDatasetsEffects {
  public readonly GET_DATASET_STATUS_DELAY = TimeInterval.SECOND * 10;

  public loadSpeechDatasets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.loadSpeechDatasets),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      withLatestFrom(this.store.select(fromCustomizationData.isSpeechDatasetsLoaded)),
      switchMap(([[, accountId], isLoaded]) => {
        // Return empty in case the models already loaded in the store.
        if (isLoaded || !accountId) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.Speech.listDatasets(accountId).pipe(
          switchMap((datasets: SpeechDataset[]) => {
            this.trackService.track('speech.load_datasets.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });

            return [SpeechDatasetsActions.loadSpeechDatasetsSucceeded({ datasets })];
          }),
          catchError(error => {
            this.trackService.track('speech.load_datasets.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: { error: error }
            });
            return [SpeechDatasetsActions.loadSpeechDatasetsFailed()];
          })
        );
      })
    )
  );

  public loadSpeechDatasetFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.openViewSpeechDatasetDialog, SpeechDatasetsActions.loadSpeechDatasetFiles),
      switchMap(({ datasetId }) => of(datasetId).pipe(withLatestFrom(this.store.select(fromCustomizationData.getSpeechDatasetById(datasetId))))),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[datasetId, dataset], accountId]) => {
        if (!datasetId || !accountId || dataset.filesLoaded) {
          return EMPTY;
        }

        this.store.dispatch(SpeechDatasetsActions.loadSpeechDatasetFilesStarted());
        return this.apiService.Account.Customization.Speech.getDatasetFiles(accountId, datasetId).pipe(
          switchMap(files => {
            return this.apiService.Account.Customization.Speech.getDatasetFilesContent(files).pipe(
              switchMap(contents => {
                const files = contents as SpeechDatasetFileContent[];
                this.trackService.track('speech.load_dataset_files.succeeded', {
                  category: EventCategory.CUSTOMIZATION
                });
                return [SpeechDatasetsActions.loadSpeechDatasetFilesSucceeded({ datasetId: datasetId, files })];
              })
            );
          }),
          catchError(error => {
            this.trackService.track('speech.load_dataset_files.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [SpeechDatasetsActions.loadSpeechDatasetFilesFailed({ datasetId })];
          })
        );
      })
    )
  );

  public updateSpeechDataset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.updateSpeechDataset),
      switchMap(({ datasetId, properties }) =>
        of(datasetId).pipe(withLatestFrom(this.store.select(fromCustomizationData.getSpeechDatasetById(datasetId))), withLatestFrom(of(properties)))
      ),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([[[datasetId, datasetBeforeUpdate], properties], accountId]) => {
        if (!datasetId || !accountId) {
          return EMPTY;
        }

        this.store.dispatch(SpeechDatasetsActions.updateSpeechDatasetStarted());

        return this.apiService.Account.Customization.Speech.updateDataset(accountId, datasetId, properties).pipe(
          switchMap(dataset => {
            this.trackService.track('speech.update_dataset.succeeded', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                fields: {
                  name: datasetBeforeUpdate.displayName !== properties.displayName,
                  description: datasetBeforeUpdate.description !== properties.description,
                  nameLength: properties.displayName.length,
                  descriptionLength: properties.description?.length
                }
              }
            });
            return [SpeechDatasetsActions.updateSpeechDatasetSucceed({ dataset })];
          }),
          catchError(error => {
            this.trackService.track('speech.update_dataset.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            const title = this.resources.SpeechFailedUpdateDatasetTitle;
            const text = this.resources.SpeechFailedUpdateDatasetText;
            const errorNotification = this.getErrorNotification(datasetId, title, text);
            this.toastService.notify(errorNotification);
            return [SpeechDatasetsActions.updateSpeechDatasetFailed()];
          })
        );
      })
    )
  );

  public deleteSpeechDataset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.deleteSpeechDataset),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ dataset }, accountId]) => {
        if (!dataset.id || !accountId) {
          return EMPTY;
        }

        this.store.dispatch(SpeechDatasetsActions.deleteSpeechDatasetStarted());

        return this.apiService.Account.Customization.Speech.deleteDataset(accountId, dataset.id).pipe(
          switchMap(() => {
            this.trackService.track('speech.delete_dataset.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });
            return [
              SpeechDatasetsActions.deleteSpeechDatasetSucceed({ datasetId: dataset.id }),
              SpeechModelsActions.clearSpeechModels(),
              SpeechModelsActions.loadSpeechModels()
            ];
          }),
          catchError(error => {
            this.trackService.track('speech.delete_dataset.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: { error: error }
            });
            return [SpeechDatasetsActions.deleteSpeechDatasetFailed({ error, datasetId: dataset.id, datasetName: dataset.name })];
          })
        );
      })
    )
  );

  public onDeleteDatasetFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SpeechDatasetsActions.deleteSpeechDatasetFailed),
        tap(({ error, datasetId, datasetName }) => {
          const errorTexts = this.speechErrorsService.getSpeechDatasetDeleteErrorTexts(error, datasetName);
          const errorNotification = this.getErrorNotification(datasetId, errorTexts.title, errorTexts.content);
          this.toastService.notify(errorNotification);
        })
      ),
    {
      dispatch: false
    }
  );

  public downloadSpeechDataset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SpeechDatasetsActions.downloadSpeechDataset),
        switchMap(({ datasetId }) => of(datasetId).pipe(withLatestFrom(this.store.select(fromCustomizationData.getSpeechDatasetById(datasetId))))),
        withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
        tap(([[datasetId, dataset], accountId]) => {
          if (!datasetId || !accountId) {
            return EMPTY;
          }

          if (!dataset.filesLoaded) {
            this.store.dispatch(SpeechDatasetsActions.loadSpeechDatasetFiles({ datasetId }));
            this.store
              .select(fromCustomizationData.getSpeechDatasetById(datasetId))
              .pipe(
                filter(datasetContract => datasetContract.filesLoaded),
                take(1)
              )
              .subscribe(datasetContract => {
                this.trackService.track('speech.download_dataset.succeeded', {
                  category: EventCategory.CUSTOMIZATION
                });
                this.downloadDatasetLanguageData(datasetContract.languageData, datasetContract.displayName);
              });
          } else {
            this.trackService.track('speech.download_dataset.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });
            this.downloadDatasetLanguageData(dataset.languageData, dataset.displayName);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public startUploadingDataset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.startUploadingDataset),
      switchMap(({ uploadDetails }) => {
        const uploadingDataset: ISpeechDatasetDetails = {
          id: uploadDetails.id,
          properties: {
            acceptedLineCount: 0,
            rejectedLineCount: 0,
            duration: ''
          },
          displayName: uploadDetails.displayName,
          description: uploadDetails.description,
          locale: uploadDetails.locale,
          kind: uploadDetails.kind,
          status: UIDatasetState.UPLOADING,
          disabled: true,
          lastActionDateTime: new Date().toString(),
          createdDateTime: new Date().toString(),
          customProperties: {}
        };
        return [SpeechDatasetsActions.addUploadingDataset({ dataset: uploadingDataset }), SpeechDatasetsActions.getDatasetSasURL({ uploadDetails })];
      })
    )
  );

  public getSasURL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.getDatasetSasURL),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ uploadDetails }, accountId]) => {
        if (!uploadDetails || !accountId) {
          return EMPTY;
        }
        return this.uploadService.getSas(accountId, uploadDetails.file.name, uploadDetails.file.size).pipe(
          switchMap((sas: IApiSasContract) => {
            this.trackService.track('speech.upload_dataset_get_sas.succeeded', {
              category: EventCategory.CUSTOMIZATION
            });
            const file: IFileBlob = this.azureBlobService.generateFile(uploadDetails.file);
            file.baseUrl = sas.baseUrl;
            file.sasToken = sas.sasToken;
            file.blobPath = sas.baseUrl + sas.sasToken;
            return [SpeechDatasetsActions.getDatasetSasURLSucceeded({ uploadDetails, file, sas })];
          }),
          catchError(error => {
            this.trackService.track('speech.upload_dataset_get_sas.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: { error: error }
            });

            return [SpeechDatasetsActions.getDatasetSasURLFailed({ datasetId: uploadDetails.id, datasetName: uploadDetails.displayName })];
          })
        );
      })
    )
  );

  public uploadToBlobStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.getDatasetSasURLSucceeded),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ uploadDetails, file, sas }, accountId]) => {
        if (!uploadDetails || !accountId) {
          return EMPTY;
        }
        return this.azureBlobService.uploadToBlobStorage(file, sas).pipe(
          switchMap((event: HttpEvent<object>) => {
            switch (event.type) {
              case HttpEventType.Response:
                this.trackService.track('speech.upload_dataset_to_blob.succeeded', {
                  category: EventCategory.CUSTOMIZATION,
                  data: {
                    source: file.baseUrl
                  }
                });
                const newDatasetDetails: IUISpeechDatasetInput = {
                  ...uploadDetails,
                  contentUrl: file.blobPath
                };
                return [SpeechDatasetsActions.uploadDatasetToBlobSucceeded({ datasetInput: newDatasetDetails })];
              default:
                return EMPTY;
            }
          }),
          catchError(error => {
            this.trackService.track('speech.upload_dataset_to_blob.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                source: file.baseUrl,
                error: error
              }
            });

            return [SpeechDatasetsActions.uploadDatasetToBlobFailed({ datasetId: uploadDetails.id, datasetName: uploadDetails.displayName })];
          })
        );
      })
    )
  );

  public createSpeechDataset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.uploadDatasetToBlobSucceeded),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ datasetInput }, accountId]) => {
        if (!datasetInput || !accountId) {
          return EMPTY;
        }

        this.store.dispatch(SpeechDatasetsActions.createDatasetStarted());

        return this.apiService.Account.Customization.Speech.createDataset(accountId, datasetInput as SpeechDatasetInput).pipe(
          switchMap((datasetDetails: SpeechDataset) => {
            this.trackService.track('speech.create_dataset.succeeded', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                kind: datasetDetails.kind,
                locale: datasetDetails.locale,
                nameLength: datasetDetails.displayName.length,
                descriptionLength: datasetDetails.description?.length
              }
            });
            return [
              SpeechDatasetsActions.removeSpeechDataset({ datasetId: datasetInput.id }),
              SpeechDatasetsActions.createDatasetSucceeded({ datasetDetails }),
              SpeechDatasetsActions.getDatasetStatus({ datasetId: datasetDetails.id })
            ];
          }),
          catchError(error => {
            this.trackService.track('speech.create_dataset.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [SpeechDatasetsActions.createDatasetFailed({ datasetId: datasetInput.id, datasetName: datasetInput.displayName })];
          })
        );
      })
    )
  );

  public onUploadDatasetFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SpeechDatasetsActions.getDatasetSasURLFailed,
          SpeechDatasetsActions.uploadDatasetToBlobFailed,
          SpeechDatasetsActions.createDatasetFailed
        ),
        tap(({ datasetId, datasetName }) => {
          const title = this.translationService.instant('SpeechFailedUploadDatasetTitle', { datasetName: datasetName });
          const text = this.resources.SpeechFailedUploadDatasetText;
          const errorNotification = this.getErrorNotification(datasetId, title, text);
          this.notificationService.notify(errorNotification);
        })
      ),
    {
      dispatch: false
    }
  );

  public getDatasetsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.loadSpeechDatasetsSucceeded),
      exhaustMap(({ datasets }) => {
        const processingDatasets = datasets.filter(dataset => this.isDatasetProcessing(dataset));
        if (processingDatasets.length === 0) {
          return EMPTY;
        }
        const actionsList = [];
        processingDatasets.forEach(dataset => {
          actionsList.push(SpeechDatasetsActions.getDatasetStatus({ datasetId: dataset.id }));
        });
        return actionsList;
      })
    )
  );

  public getDatasetStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpeechDatasetsActions.getDatasetStatus),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      delay(this.GET_DATASET_STATUS_DELAY),
      switchMap(([{ datasetId }, accountId]) => {
        if (!datasetId || !accountId) {
          return EMPTY;
        }

        return this.apiService.Account.Customization.Speech.getDataset(accountId, datasetId).pipe(
          takeUntil(this.actions$.pipe(ofType(SpeechDatasetsActions.clearSpeechDatasets))),
          switchMap((dataset: SpeechDataset) => {
            this.trackService.track('speech.get_dataset_status.succeeded', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                status: dataset.status
              }
            });
            const actionsList = [];
            if (this.isDatasetProcessing(dataset)) {
              actionsList.push(SpeechDatasetsActions.getDatasetStatus({ datasetId }));
            }
            actionsList.push(SpeechDatasetsActions.getDatasetStatusSucceeded({ dataset }));
            return actionsList;
          }),
          catchError(error => {
            this.trackService.track('speech.get_dataset_status.failed', {
              category: EventCategory.CUSTOMIZATION,
              data: {
                error: error
              }
            });
            return [SpeechDatasetsActions.getDatasetStatusFailed()];
          })
        );
      })
    )
  );

  private resources = resources;
  private translationService: TranslateHelperService;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<IState>,
    private trackService: TrackService,
    private injector: Injector,
    private downloadService: DownloadService,
    private uploadService: UploadService,
    private azureBlobService: AzureBlobStorageService,
    private notificationService: NotificationsService,
    private toastService: ToastService,
    private speechErrorsService: SpeechErrorsService
  ) {
    setTimeout(() => {
      this.init();
    }, TRANSLATION_DELAY);
  }

  private downloadDatasetLanguageData(languageData = '', name: string) {
    this.downloadService.downloadFile(languageData, `${name}-dataset`, 'txt', {});
  }

  private init() {
    this.translationService = this.injector.get(TranslateHelperService);
    this.translationService.translateResources(this.resources);
  }

  private isDatasetProcessing(dataset) {
    return dataset.status === SpeechObjectState.WAITING || dataset.status === SpeechObjectState.PROCESSING;
  }

  private getErrorNotification(datasetId: string, title: string, text: string): INotification {
    return {
      id: `failed_dataset_${datasetId}_${guid()}`,
      messageType: NotificationMessageType.Custom,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Message,
      level: NotificationLevel.Error,
      icon: NotificationIcon.Error,
      title: title,
      text: text
    };
  }
}
