import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';

import { getKeyCode, KeyCode } from '../../utils';

export enum AlertMessageType {
  WARNING = 'warning',
  WARNING_INFO = 'warning-info',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}

@Component({
  selector: 'vi-ui-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertMessageComponent implements AfterViewInit, OnDestroy {
  @Input() public type: AlertMessageType;
  @Input() public content: string;
  @Input() public contentTitle: string;
  @Input() public linkText?: string;
  @Input() public linkPath?: string;
  @Input() public isContentHtml = false;
  // isButton override linkPath. Only one of these inputs can be used.
  @Input() public isButton = false;
  @Output() public buttonClicked = new EventEmitter<void>();
  @Output() public contentLinkClicked = new EventEmitter<void | Event>();

  constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    if (this.isContentHtml) {
      this.bindClickEvent();
    }
  }

  public ngOnDestroy() {
    const links = this.el.nativeElement.getElementsByClassName('link');

    for (const link of links) {
      link.removeEventListener('mouseup', this.handleLinkClick.bind(this));
      link.removeEventListener('keyup', this.handleLinkClick.bind(this));
    }
  }

  public onClick() {
    this.buttonClicked.emit();
  }

  private bindClickEvent() {
    const links = this.el.nativeElement.getElementsByClassName('link');

    for (const link of links) {
      link.addEventListener('mouseup', this.handleLinkClick.bind(this));
      link.addEventListener('keyup', this.handleEnterPressed.bind(this));
    }
  }

  private handleLinkClick(event: MouseEvent) {
    if (event && (event?.button || event?.buttons) === 0) {
      this.contentLinkClicked.emit();
    }
  }

  private handleEnterPressed(event: KeyboardEvent) {
    try {
      const key = getKeyCode(event);
      if (key === KeyCode.Enter) {
        this.contentLinkClicked.emit(event);
      }
    } catch (error) {}
  }
}
