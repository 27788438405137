import { fromNow } from '@common/modules/utils/time';
import { IndexState } from '@common/modules/shared/interfaces';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export function sortByUploaded(list: SinglePlaylistSearchResultV2[]) {
  if (list.length) {
    // Get processing videos
    const processing = list.filter(v => {
      return v.state === IndexState.Processing || v.state === IndexState.Uploaded;
    });

    // Get processed videos
    const ready = list.filter(v => {
      return v.state !== IndexState.Processing && v.state !== IndexState.Uploaded;
    });

    // Sort all videos by created time
    const orderReady = ready.sort((a, b) => compareVideos(a, b));
    const orderProcessing = processing.sort((a, b) => compareVideos(a, b));

    return [...orderProcessing, ...orderReady];
  } else {
    return list;
  }
}

export function compareVideos(a: SinglePlaylistSearchResultV2, b: SinglePlaylistSearchResultV2) {
  const lastModifiedA = fromNow(a.lastModified);
  const lastModifiedB = fromNow(b.lastModified);
  if (lastModifiedA < lastModifiedB) {
    return 1;
  } else if (lastModifiedA > lastModifiedB) {
    return -1;
  }
  return 0;
}
