import { Injectable } from '@angular/core';

import { Subject, Observable, fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { PresetType } from '@azure/video-indexer-widgets';
import { remove, find, cloneDeep } from 'lodash-es';

import { IUIPresetInsight, IUIPreset, UIPresetInsight, IUITab, PresetInsightType } from '../insights/interfaces';
import { InsightsCommonUtilsService } from './insights-common-utils.service';
import { ApiService } from '../api/services/api.service';
import { presetInsights, presets } from '../insights/presetInsights';
import { ILanguage } from '../../../apps/web/src/core/interfaces';
import { SupportedLanguages } from '../../../apps/web/src/core/languages';
import { IInsightJsonKeyType, IUIInsightJsonKey } from './interfaces';
import { FramePatternType } from '../timeline/interfaces';
import { FeatureSwitchService } from '../core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '../core/services/interfaces';

@Injectable()
export class InsightsCommonService {
  public presetInsight: Subject<IUIPresetInsight | void>;
  public presetInsightsObj: IUIPresetInsight[];
  public presetsObj: IUIPreset[];
  public currentPresetsName: string;
  public SupportedLanguages: ILanguage[];

  private videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2;
  private customInsightsObj: IUIPresetInsight[] = [];

  constructor(private utils: InsightsCommonUtilsService, private apiService: ApiService, private featureSwitchService: FeatureSwitchService) {
    this.init();
  }

  public init() {
    this.presetInsightsObj = cloneDeep(presetInsights);
    this.presetsObj = cloneDeep(presets);
    this.currentPresetsName = this.presetsObj[0].name;
    this.presetInsight = new Subject<IUIPresetInsight>();
    this.SupportedLanguages = SupportedLanguages;
    this.customInsightsObj = [];
  }

  public presetInsightsSubject(): Subject<IUIPresetInsight | void> {
    return this.presetInsight;
  }
  public getPresetInsights() {
    return this.presetInsightsObj;
  }

  public getPresets() {
    return this.presetsObj;
  }

  public getPresetsName() {
    return this.currentPresetsName;
  }

  public setPresetsName(newPreset: string) {
    this.currentPresetsName = newPreset;
  }

  public getSupportedLanguages() {
    return this.SupportedLanguages;
  }

  public get customInsights() {
    return this.customInsightsObj;
  }

  public get customInsightsCount() {
    return this.customInsightsObj?.length;
  }

  public setVideoIndex(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    this.videoIndex = videoIndex;
  }

  public onTimeChange() {
    if (!window) {
      return;
    }
    const source = fromEvent(window, 'message');
    return source.pipe(distinctUntilChanged());
  }

  public filterPresetInsights(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    // Reset preset insights object
    this.presetInsightsObj = cloneDeep(presetInsights);

    remove(this.presetInsightsObj, (insight: IUIPresetInsight) => {
      let hasData;
      if (insight.dataKey === 'namedEntities') {
        hasData =
          this.utils.hasData(IUIInsightJsonKey.brands, videoIndex) ||
          this.utils.hasData(IUIInsightJsonKey.namedLocations, videoIndex) ||
          this.utils.hasData(IUIInsightJsonKey.namedPeople, videoIndex);
      } else if (insight.dataKey === 'postProduction') {
        hasData =
          this.featureSwitchService.featureSwitch(FeatureSwitch.SlateDetection) &&
          (this.utils.hasData(IUIInsightJsonKey.clapperboards, videoIndex) || this.hasColorBars(videoIndex));
      } else {
        hasData = this.utils.hasData(insight.dataKey, videoIndex);
      }
      // remove if sentiment has only neutral data
      if (hasData && insight.dataKey === 'sentiments') {
        return this.sentimentHasOnlyNeutralData(videoIndex);
      }

      // remove matched person if OP don't have matched person data
      if (hasData && insight.value === UIPresetInsight.MATCHED_PERSON) {
        return this.isOPWithoutMatchedFaces(videoIndex);
      }
      return !hasData;
    });

    // Hide the sentiments if we have emotions.
    const sentiments = find(this.presetInsightsObj, p => p.dataKey === 'sentiments');
    const emotions = find(this.presetInsightsObj, p => p.dataKey === 'emotions');
    const keywords = find(this.presetInsightsObj, p => p.dataKey === 'keywords');

    if (sentiments && emotions) {
      sentiments.selected = false;
    }

    // Hide keyframes if we have scenes
    const scenes = find(this.presetInsightsObj, p => p.dataKey === 'scenes');
    const keyframes = find(this.presetInsightsObj, p => p.dataKey === 'shots');

    if (scenes && keyframes) {
      keyframes.selected = false;
    }
    // Filter keywords by default
    if (keywords) {
      keywords.selected = false;
    }

    // Hide matched person by default
    const matchedPerson = find(this.presetInsightsObj, p => p.value === UIPresetInsight.MATCHED_PERSON);

    if (matchedPerson) {
      matchedPerson.selected = false;
    }

    // Add custom insights data
    this.presetInsightsObj = [...this.presetInsightsObj, ...this.customInsightsObj];
  }

  public updatePresetInsightsState(presetInsightsType: UIPresetInsight, isDisabled: boolean): void {
    if (!presetInsightsType) {
      return;
    }
    for (const iterator of this.presetInsightsObj) {
      if (presetInsightsType === iterator.value) {
        iterator.disabled = isDisabled;
        iterator.selected = !isDisabled;
        return;
      }
    }
  }

  public removeCapsule(type: UIPresetInsight, id: number, accountId: string, videoId: string): Observable<void> {
    let ob = new Observable<void>();
    switch (type) {
      case UIPresetInsight.KEYWORDS:
        ob = this.apiService.Account.Video.removeKeywords(accountId, videoId, id, null);
        break;
      case UIPresetInsight.BRANDS:
        ob = this.apiService.Account.Video.removeBrands(accountId, videoId, { brandId: id }, null);
        break;
      case UIPresetInsight.LABELS:
        ob = this.apiService.Account.Video.removeLabels(accountId, videoId, id, null);
        break;
    }
    return ob;
  }

  public addCapsule(type: UIPresetInsight, value: string, lang: Microsoft.VideoIndexer.Contracts.LanguageV2, accountId: string, videoId: string) {
    return this.apiService.Account.Video.addKeywords(accountId, videoId, { keyword: value, language: lang });
  }

  public filterPresetInsightsForEditor() {
    const defaultInsightsForEditor = [UIPresetInsight.TRANSCRIPT, UIPresetInsight.PEOPLE];
    this.presetInsightsObj.forEach(insight => {
      insight.selected = defaultInsightsForEditor.indexOf(insight.value) !== -1;
    });
  }

  public addPresetInsight(presetInsight: UIPresetInsight) {
    const insightsForEditor = [presetInsight];
    this.presetInsightsObj.forEach(insight => {
      if (!insight.selected) {
        insight.selected = insightsForEditor.indexOf(insight.value) !== -1;
      }
    });
  }

  public addNewPresetCustomInsights(key: string, title: string, presetList: PresetType[]) {
    const uiPresetInsights: IUIPresetInsight = {
      name: title,
      value: key as UIPresetInsight,
      selected: true,
      dataKey: key as IInsightJsonKeyType,
      config: true,
      ranking: 1,
      availableTabs: [IUITab.INSIGHTS, IUITab.TIMELINE],
      type: PresetInsightType.CUSTOM
    };

    this.customInsightsObj.push(uiPresetInsights);

    this.presetInsightsObj = [...this.presetInsightsObj, uiPresetInsights];

    // Add to preset list
    for (const preset of presetList) {
      // find preset from list
      const currentPreset = find(this.presetsObj, tempPreset => tempPreset.value === preset);
      currentPreset?.insights.push(key);
    }
  }

  public setPreset(preset: IUIPreset) {
    this.utils.resetPresets(this.presetsObj);
    this.utils.resetPresets(this.presetInsightsObj);
    preset.selected = true;
    this.setPresetsName(preset.name);
    preset.insights.forEach(insight => {
      this.presetInsightsObj.forEach(ins => {
        if (ins.value === insight) {
          ins.selected = true;
        }
      });
    });
  }

  public hasColorBars(videoIndex) {
    for (const video of videoIndex.videos) {
      if (video?.insights?.framePatterns) {
        const colorBars = video?.insights?.framePatterns.filter(pattern => pattern.patternType === FramePatternType.COLOR_BARS);
        if (colorBars.length) {
          return true;
        }
      }
    }
    return false;
  }

  private sentimentHasOnlyNeutralData(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2): boolean {
    let value = true;
    for (const video of videoIndex?.videos) {
      const sentiments = video.insights.sentiments;
      value = value && sentiments?.length === 1 && sentiments[0]?.sentimentType === 'Neutral';
    }
    return value;
  }

  private isOPWithoutMatchedFaces(videoIndex: Microsoft.VideoIndexer.Contracts.PlaylistContractV2): boolean {
    let value = true;
    for (const video of videoIndex?.videos) {
      if (!video.insights?.observedPeople) {
        continue;
      }
      for (const observedPeople of video.insights?.observedPeople) {
        value = value && !observedPeople?.matchingFace;
      }
    }
    return value;
  }
}
