import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, Input } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { NavigationState, IUserDetails, FeatureSwitch } from '@common/modules/core/services/interfaces';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { AccountResourceType } from '@common/modules/auth/interfaces';

import { SidebarPages, ISidebarNavigationOptions } from '../../../sidebar/interfaces';
import { HeaderPreset, UIHeaderActionType } from './interfaces';
import { resources } from './resources';
import { CoreStoreService } from '../../../core/services/core-store.service';
import { VIRoutingMap } from '../../../app/routing/routes';
import {
  accountsAction,
  connectToAzureAction,
  helpAction,
  modelCustomizationAction,
  settingsAction,
  menuAction,
  menuActionList,
  sidemenuToggleAction,
  menuWithoutAccountsActionList,
  notificationsAction,
  menuWithNotificationsActionList,
  menuWithNotificationsWithoutAccountsActionList
} from './actions';
import { NotificationsStoreService } from '../../../core/services/notifications-store.service';

@Component({
  selector: 'app-vi-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends FocusableComponent implements OnInit, OnDestroy {
  @Input() public isUserAuthenticated: boolean;
  @Input() public isZeroAccounts = false;
  @Input() public preset: HeaderPreset = HeaderPreset.FULL;

  // Buttons Actions
  public userDetails: IUserDetails;
  public settings = settingsAction;
  public notifications = notificationsAction;
  public sidemenuToggle: IAction = sidemenuToggleAction;
  public connectToAzureAction: IAction;
  public help: IAction = helpAction;
  public modelCustomization: IAction = modelCustomizationAction;
  public accounts: IAction = accountsAction;
  public menu: IAction = menuAction;
  public menuType: ActionButtonType = ActionButtonType.HEADER_BUTTON;
  public userMenuExpanded = false;
  public resources = resources;
  public readonly logoText = 'Azure AI Video Indexer';
  public readonly logoTextShort = 'Video Indexer';
  public selectedAccountId: string;
  public coreMetadataLoaded$: Observable<boolean>;
  public VIRoutingMap = VIRoutingMap;
  public isArmAccount = true;
  public showSkipMainContent = false;
  public mainContentTriggerTabIndex = 0;
  public isSideMenuEnabled = false;
  public isSideMenuOpened = false;
  public readonly logoTextTitle = 'Azure AI Video Indexer';
  public readonly logoTextShortTitle = 'Video Indexer';
  public ActionButtonType = ActionButtonType;
  public isMobile = false;
  public isNotificationCenterEnabled = false;
  public notClearedNotificationsCount$: Observable<number>;
  public notificationsLoading$: Observable<boolean>;
  public readonly HeaderPreset = HeaderPreset;

  protected destroy$ = new Subject();
  private selectedAction: IAction;
  private sidebarActions: IAction[] = [this.accounts, this.help, this.settings, this.notifications];

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private navigationService: AppNavigationService,
    private focusManager: FocusManagerService,
    private coreStoreService: CoreStoreService,
    private notificationsStore: NotificationsStoreService,
    private trackService: TrackService,
    private utilService: UtilsService,
    private featureSwitchService: FeatureSwitchService
  ) {
    super();
  }

  public get menuList() {
    if (this.isNotificationCenterEnabled) {
      return !this.isZeroAccounts ? menuWithNotificationsActionList : menuWithNotificationsWithoutAccountsActionList;
    }

    return !this.isZeroAccounts ? menuActionList : menuWithoutAccountsActionList;
  }

  public get showSideMenuToggle() {
    return this.isUserAuthenticated && !this.isZeroAccounts && this.preset === this.HeaderPreset.FULL;
  }

  public ngOnInit() {
    this.setTranslations();
    this.isMobile = this.utilService.isMobile();

    this.coreStoreService.userDetails$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.userDetails = {
        email: user.email,
        imageUrl: user.image,
        name: user.name
      };
    });

    this.coreMetadataLoaded$ = this.coreStoreService.coreMetadataLoaded$.pipe(takeUntil(this.destroy$));
    this.coreStoreService.selectedAccountId$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.selectedAccountId = res;
    });
    this.coreStoreService.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe(account => {
      this.isArmAccount = account?.resourceType === AccountResourceType.ARM;
      this.cdr.detectChanges();
    });
    if (this.featureSwitchService.featureSwitch(FeatureSwitch.NotificationCenter)) {
      this.isNotificationCenterEnabled = true;
      this.notClearedNotificationsCount$ = this.notificationsStore.notClearedNotificationsCount$.pipe(takeUntil(this.destroy$));
      this.notificationsLoading$ = this.notificationsStore.getNotificationsLoadingState$.pipe(takeUntil(this.destroy$));
    }

    this.coreStoreService.selectSideMenuOpen().subscribe(open => {
      this.isSideMenuOpened = open;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public handleToggleUserMenu(event: Event): void {
    // Toggle menu only when userDetails exist and isn't empty object
    if (!this.userDetails || !Object.keys(this.userDetails).length) {
      return;
    }

    // close sidebar and prevent default only on clicking user button
    if (event) {
      event.preventDefault();
      this.closeSidebar();

      if (this.selectedAction) {
        this.selectedAction.selected = false;
      }
    }
    this.userMenuExpanded = !this.userMenuExpanded;

    this.trackService.track('header.toggle_user_menu', {
      category: EventCategory.HEADER,
      data: { value: this.userMenuExpanded }
    });
  }

  public handleToggleSideMenu() {
    this.isSideMenuOpened = !this.isSideMenuOpened;
    this.coreStoreService.sideMenuToggled(this.isSideMenuOpened);
    this.trackService.track('header.toggle_side_menu.click', {
      category: EventCategory.HEADER,
      data: { value: this.isSideMenuOpened }
    });
  }

  public setTranslations(): void {
    this.translate.translateResources(resources).subscribe(() => {
      // Actions
      connectToAzureAction.title = this.resources.VIHeaderCreatePro;
      this.connectToAzureAction = connectToAzureAction;

      [this.modelCustomization, this.accounts, this.help, this.settings, this.sidemenuToggle, this.notifications].forEach(c => {
        c.titleAttribute = this.resources[c.key];
      });

      this.menuList.forEach(item => {
        item.title = this.resources[item.key];
      });

      this.cdr.detectChanges();
    });
  }

  public connectToAzure() {
    this.navigationService.createAccountSubject.next(NavigationState.OPEN);
    this.trackService.track('header.click_connect_to_azure', {
      category: EventCategory.HEADER,
      action: 'keydown'
    });
  }

  public onHeaderFocus($event: FocusEvent) {
    const relatedTarget: Element = $event?.relatedTarget as Element;

    // IF event came from header-content, return
    if (relatedTarget && relatedTarget?.classList?.contains('header-skip-main-content')) {
      return;
    }
    // Add skip content popup
    this.showSkipMainContent = true;

    // Disable focus div so when user will press tab it wont get focus again
    this.mainContentTriggerTabIndex = -1;
    // focus it
    setTimeout(() => {
      this.focusManager.focusByQuery('.header-skip-main-content', this.cdr);
    });
  }

  public onBlurSkipContent() {
    if (this.showSkipMainContent) {
      this.showSkipMainContent = false;

      setTimeout(() => {
        // Return focus trigger to skip to main content after use will move focus to other element
        this.mainContentTriggerTabIndex = 0;
        this.cdr.detectChanges();
      }, 200);
    }
  }

  public onEnterSkipContent() {
    this.showSkipMainContent = false;

    // Focus main action
    this.focusManager.focusByQuery('.main-content-action', this.cdr);

    // Return focus trigger to skip to main content
    this.mainContentTriggerTabIndex = 0;
  }

  public handleButtonClick(action: IAction) {
    // Toggle sidebar with the appropriate page or upload popup
    let navigationOptions: ISidebarNavigationOptions;
    switch (action.value) {
      case UIHeaderActionType.HELP:
        navigationOptions = {
          page: SidebarPages.HELP,
          state: NavigationState.TOGGLE,
          callbackAfterClose: () => {
            this.focusManager.focusByQuery('#headerHelpBtn .action-button');
            this.selectedAction.selected = false;
          }
        };
        break;
      case UIHeaderActionType.ACCOUNTS:
        this.coreStoreService.clearAccountsFilterValue();
        navigationOptions = {
          page: SidebarPages.ACCOUNTS,
          state: NavigationState.TOGGLE,
          callbackAfterClose: () => {
            // the next elements aren't shown at the same time.
            // One's shown in desktop view, the other in mobile. So, only one will be in focus at a time
            this.focusManager.focusByQuery('#headerAccountsBtn .button-container');
            this.focusManager.focusByQuery('#mobileMenu');
            this.selectedAction.selected = false;
          }
        };
        break;
      case UIHeaderActionType.SETTINGS:
        navigationOptions = {
          page: SidebarPages.SETTINGS,
          state: NavigationState.TOGGLE,
          callbackAfterClose: () => {
            // the next elements aren't shown at the same time.
            // One's shown in desktop view, the other in mobile. So, only one will be in focus at a time
            this.focusManager.focusByQuery('#headerSettingsBtn .action-button');
            this.focusManager.focusByQuery('#mobileMenu');
            this.selectedAction.selected = false;
          }
        };
        break;
      case UIHeaderActionType.NOTIFICATIONS:
        navigationOptions = {
          page: SidebarPages.NOTIFICATIONS,
          state: NavigationState.TOGGLE,
          callbackAfterClose: () => {
            // the next elements aren't shown at the same time.
            // One's shown in desktop view, the other in mobile. So, only one will be in focus at a time
            this.focusManager.focusByQuery('#headerNotificationsBtn .action-button');
            this.focusManager.focusByQuery('#mobileMenu');
            this.selectedAction.selected = false;
          }
        };
        break;
      case UIHeaderActionType.CONNECT_TO_AZURE:
        this.connectToAzure();
        break;
      default:
        return;
    }
    this.coreStoreService.setSidebar(navigationOptions);
    this.setSelectedAction(action);
    this.trackService.track('header.click_button', {
      category: EventCategory.HEADER,
      action: 'keydown',
      page: navigationOptions?.page
    });
  }

  public handleCustomization() {
    if (this.selectedAction) {
      this.selectedAction.selected = false;
    }
    this.navigate(VIRoutingMap.customization.path);
  }

  public handleLogoClick() {
    if (this.selectedAction) {
      // clear selected state from the current action to 'reset' the header's button state
      this.selectedAction.selected = false;
    }

    this.navigate(VIRoutingMap.galleryHomeFullRoute.path);
  }

  public navigate(route: string) {
    route = route.replace(':accountId', this.selectedAccountId);
    this.coreStoreService.navigate([`/${route}`], null, null, true);
    this.closeSidebar();
  }

  public showConnectToAzure() {
    return !this.isArmAccount;
  }

  public ToggleMobileMenu(isMenuOpened: boolean) {
    this.menu = { ...this.menu, selected: isMenuOpened };
  }

  private setSelectedAction(action: IAction) {
    this.sidebarActions.forEach(act => {
      if (action.value !== act.value) {
        act.selected = false;
      } else {
        act.selected = !act.selected;
        this.selectedAction = act;
      }
    });
  }

  private closeSidebar() {
    this.coreStoreService.clearSidebar();
    this.trackService.track('header.close_sidebar', {
      category: EventCategory.HEADER,
      action: 'keydown'
    });
  }
}
