import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IStripData, MessageType } from '@common/modules/shared/components/strip/interfaces';
import { StripService } from '@common/modules/shared/components/strip/strip.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { DataService } from '@common/modules/shared/services/data.service';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { NotificationLevel } from '@common/modules/core/services/toast/interfaces';

@Component({
  selector: 'vi-header-strips',
  templateUrl: './header-strips.component.html',
  styleUrls: ['./header-strips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderStripsComponent implements OnInit, OnDestroy {
  public notificationsStrips: IStripData[] = [];
  public strips: IStripData[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private stripService: StripService,
    private cdr: ChangeDetectorRef,
    private trackService: TrackService,
    private dataService: DataService,
    private focusManager: FocusManagerService,
    @Inject(DOCUMENT) private document
  ) {}

  public ngOnInit(): void {
    this.initStrips();
    this.onResize();
  }

  public closeStrip($event, id: number) {
    // on click close focus should move to main content
    this.focusManager.focusByQuery('.main-content-action', this.cdr);
    this.deleteStrip($event, id);
  }

  public closeNotificationStrip($event, id: number) {
    // on click close focus should move to main content
    this.focusManager.focusByQuery('.main-content-action', this.cdr);
    this.deleteNotificationStrip($event, id);
  }

  public linkClicked($event, strip) {
    const noop = () => {};
    const callback = strip?.linkCallback || noop;
    callback($event);
  }

  public actionClicked($event, strip) {
    const noop = () => {};
    const callback = strip?.actionCallback || noop;
    callback($event);
  }

  public isProgressNumber(value?): boolean {
    return value !== null && value >= 0;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initStrips() {
    this.stripService.triggerStripData.pipe(takeUntil(this.destroy$)).subscribe(stripData => {
      if (!stripData) {
        return;
      }

      const existingStrip = this.strips.find(strip => {
        return strip.id === stripData.id;
      });

      if (!!existingStrip && !stripData.show) {
        // exists and should be removed
        this.deleteStrip(null, stripData.id);
      } else if (!existingStrip && stripData.show) {
        // isn't already exists and should be shown
        this.addStrip(stripData);
      }
    });

    this.stripService.triggerNotificationsStripData.pipe(takeUntil(this.destroy$)).subscribe(stripData => {
      if (!stripData) {
        return;
      }

      const existingStrip = this.notificationsStrips.find(strip => {
        return strip.id === stripData.id;
      });

      if (!!existingStrip && !stripData.show) {
        // exists and should be removed
        this.deleteNotificationStrip(null, stripData.id);
      } else if (!existingStrip && stripData.show) {
        // isn't already exists and should be shown
        this.addNotificationStrip(stripData);
      }
    });
  }

  private addStrip(stripData: IStripData) {
    this.strips = [...this.strips, stripData];
    this.strips = this.strips.sort(this.compareStrips);
    this.setStripsHeight();
  }

  private addNotificationStrip(stripData: IStripData) {
    this.notificationsStrips = [...this.notificationsStrips, stripData];
    this.notificationsStrips = this.notificationsStrips.sort(this.compareNotificationStrips);
    this.setStripsHeight();
  }

  private compareStrips(stripA: IStripData, stripB: IStripData) {
    const typeA = stripA?.messageType;
    const typeB = stripB?.messageType;
    if (typeA === MessageType.WARNING && typeB === MessageType.ERROR) {
      return 1;
    } else if (typeA === MessageType.ERROR && typeB === MessageType.WARNING) {
      return -1;
    }
    return 0;
  }

  private compareNotificationStrips(stripA: IStripData, stripB: IStripData) {
    const typeA = stripA?.notificationLevel;
    const typeB = stripB?.notificationLevel;
    if (
      (typeA === NotificationLevel.Warning ||
        typeA === NotificationLevel.Info ||
        typeA === NotificationLevel.Success ||
        typeB === NotificationLevel.Error) &&
      typeB === NotificationLevel.Critical
    ) {
      return 1;
    } else if (
      (typeA === NotificationLevel.Warning || typeA === NotificationLevel.Info || typeA === NotificationLevel.Success) &&
      typeB === NotificationLevel.Error
    ) {
      return 1;
    } else if ((typeA === NotificationLevel.Info || typeA === NotificationLevel.Success) && typeB === NotificationLevel.Warning) {
      return 1;
    } else if (
      typeA === NotificationLevel.Error &&
      (typeB === NotificationLevel.Warning || typeB === NotificationLevel.Info || typeB === NotificationLevel.Success)
    ) {
      return -1;
    } else if (
      typeA === NotificationLevel.Critical &&
      (typeB === NotificationLevel.Warning || typeB === NotificationLevel.Info || typeB === NotificationLevel.Success || NotificationLevel.Error)
    ) {
      return -1;
    } else if (typeA === NotificationLevel.Warning && (typeB === NotificationLevel.Info || typeB === NotificationLevel.Success)) {
      return -1;
    }
    return 0;
  }

  private setStripsHeight() {
    this.cdr.detectChanges();
    setTimeout(() => {
      // init height with 2 because the shadow of the last strip
      let height = this.strips?.length || this.notificationsStrips?.length ? 2 : 0;
      const stripElements = this.document.querySelectorAll('.strip');
      stripElements.forEach(element => {
        height += element.clientHeight;
      });
      this.document.documentElement.style.setProperty('--header-strip-height', `${height}px`);
    });
  }

  private onResize() {
    this.dataService.onResizeEventStream(375, 0).subscribe(() => {
      this.setStripsHeight();
    });
  }

  private deleteStrip($event, id: number) {
    const removedStrip = this.strips.find(strip => strip.id === id);
    if (removedStrip?.closeCallback) {
      removedStrip?.closeCallback($event);
    }
    this.trackService.track(`header.strip.close`, {
      category: EventCategory.HEADER,
      data: {
        trackingName: removedStrip?.trackingName
      }
    });
    this.strips = this.strips.filter((strip: IStripData) => strip.id !== id);
    this.setStripsHeight();
  }

  private deleteNotificationStrip($event, id: number) {
    const removedStrip = this.notificationsStrips.find(strip => strip.id === id);
    if (removedStrip?.closeCallback) {
      removedStrip?.closeCallback($event);
    }
    this.trackService.track(`header.strip.closed`, {
      category: EventCategory.HEADER,
      data: {
        trackingName: removedStrip?.trackingName
      }
    });
    this.notificationsStrips = this.notificationsStrips.filter((strip: IStripData) => strip.id !== id);
    this.setStripsHeight();
  }
}
