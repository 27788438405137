import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { combineLatestWith, filter, Observable, Subject, take, takeUntil } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

import { IDialogEvent } from '@common/modules/shared/components/dialog/interfaces';
import { UPLOAD_FORMAT_LINK } from '@common/modules/shared/links';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { CustomizationPage, FeatureSwitch } from '@common/modules/core/services/interfaces';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { FeatureSwitchService } from './../../../../../../../common/modules/core/services/feature-switch/feature-switch.service';
import { CoreStoreService } from '../../../../core/services/core-store.service';
import { FileHelperService } from '../../../services/file-helper.service';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { UploadPages, UploadSourceActions } from '../interfaces';
import { resources } from './resources';
import { IFile, UploadMode } from '../../../interfaces';
import * as actions from './actions';
import { FaceGateStripMode } from '../../shared/indexing-face-gate-strip/interfaces';
import { backToSettingsAction } from '../../shared/actions';

@Component({
  selector: 'vi-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  @Output() public componentEventEmitter = new EventEmitter<IDialogEvent>();

  public readonly UPLOAD_BUTTON_MIN_WIDTH = '139px';
  public readonly BACK_TO_SETTINGS_BUTTON_MIN_WIDTH = '141px';
  public readonly CANCEL_BUTTON_MIN_WIDTH = '82px';
  public readonly RUN_IN_BACK_BUTTON_MIN_WIDTH = '161px';
  public readonly CLOSE_BUTTON_MIN_WIDTH = '75px';

  public displayedUploadPage: UploadPages;
  public filesType: string;
  public isUploadInProgress: boolean = false;
  public filesExist: boolean = false;
  public isUploadingDone: boolean = false;
  public totalUploadProgress: number = 0;
  public files: IFile[] = [];
  public uploadAndIndexAction = actions.uploadAndIndexAction;
  public reviewAndUploadAction = actions.reviewAndUploadAction;
  public cancelAction = actions.cancelAction;
  public closeAction = actions.closeAction;
  public closeFromHeaderAction = actions.closeFromHeaderAction;
  public runInBackAction = actions.runInBackAction;
  public backToSettingsACtion = backToSettingsAction;
  public isUSAccount$: Observable<boolean>;
  public isTrialAccount$: Observable<boolean>;
  public userConsent: boolean;
  public indexingFormChanged: boolean = false;
  public showConfirmExit: boolean = false;
  public customizationPageToNavigate: CustomizationPage = null;
  public navigateToCreateAccount: boolean = false;
  public showUploadFileError: boolean;
  public isMultiFileMode: boolean = false;
  public isExcludeAIsEnable: boolean = false;
  public isSupportedAIsLoaded: boolean = false;

  public resources = resources;
  public UploadPages = UploadPages;
  public ActionButtonType = ActionButtonType;
  public FaceGateStripMode = FaceGateStripMode;
  public AlertMessageType = AlertMessageType;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private coreStore: CoreStoreService,
    private fileHelperService: FileHelperService,
    private trackService: TrackService,
    private cdr: ChangeDetectorRef,
    private featureSwitchService: FeatureSwitchService
  ) {}

  public ngOnInit(): void {
    this.initTranslations();
    this.isExcludeAIsEnable = this.featureSwitchService.featureSwitch(FeatureSwitch.ExcludeAIs);
    this.filesType = this.fileHelperService.getSupportedMediaTypesPattern();
    this.isUSAccount$ = this.coreStore.isUSAccount$;
    this.isTrialAccount$ = this.coreStore.isTrialAccount$;
    this.indexingStore.userConsent$.pipe(takeUntil(this.destroy$)).subscribe(consent => {
      this.userConsent = consent;
      this.cdr.detectChanges();
    });
    this.indexingStore.isSupportedAIsLoaded$.pipe(takeUntil(this.destroy$)).subscribe(isLoaded => {
      this.isSupportedAIsLoaded = isLoaded;
    });

    this.indexingStore.isIndexingProcessDirty$.pipe(takeUntil(this.destroy$)).subscribe(isDirty => {
      this.indexingFormChanged = isDirty;
    });
    this.indexingStore.displayedUploadPage$.pipe(takeUntil(this.destroy$)).subscribe(page => {
      this.displayedUploadPage = page;
      this.cdr.detectChanges();
    });
    this.indexingStore.allValidFiles$.pipe(takeUntil(this.destroy$)).subscribe(files => {
      this.files = files;
      this.filesExist = !files.length;
    });
    this.indexingStore.isUploadingDone$.pipe(takeUntil(this.destroy$)).subscribe(isDone => {
      this.isUploadingDone = isDone;
      this.cdr.detectChanges();
    });
    this.indexingStore.totalUploadProgress$.pipe(takeUntil(this.destroy$)).subscribe(progress => {
      this.totalUploadProgress = progress;
      this.cdr.detectChanges();
    });
    this.indexingStore.isUploadInProgress$.pipe(takeUntil(this.destroy$)).subscribe(isUploading => {
      this.isUploadInProgress = isUploading;
      if (isUploading) {
        this.indexingStore.updateUploadPage(UploadPages.UploadSummary);
      }
      this.cdr.detectChanges();
    });
    this.indexingStore.isMultiFileMode$
      .pipe(
        filter(v => v),
        take(1)
      )
      .subscribe(isMultiFileMode => {
        this.isMultiFileMode = isMultiFileMode;
      });
    this.indexingStore.showUploadFileError$
      .pipe(takeUntil(this.destroy$), combineLatestWith(this.indexingStore.isMultiFileMode$))
      .subscribe(([showUploadFileError]) => {
        this.showUploadFileError = showUploadFileError && this.isMultiFileMode;
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public switchPage(page: UploadPages) {
    this.indexingStore.updateUploadPage(page);
  }

  public onUploadSourceButtonClicked(action: UploadSourceActions) {
    if (action === UploadSourceActions.URL) {
      this.trackService.track('upload_dialog.upload_by_url_button.clicked', {
        category: EventCategory.UPLOAD
      });
      this.indexingStore.updateUploadMode(UploadMode.URL);
      this.switchPage(UploadPages.IndexingForm);
    } else if (action === UploadSourceActions.UploadGuide) {
      this.trackService.track('upload_dialog.upload_guide_button.clicked', {
        category: EventCategory.UPLOAD
      });
      this.switchPage(UploadPages.UploadGuide);
    }
  }

  public onUseSavedIndexingSettingsChanged(useSavedIndexingSettings: boolean) {
    this.indexingStore.updateUseSavedIndexingSettings(useSavedIndexingSettings);
  }

  public onOpenUploadGuidelineLink() {
    this.trackService.track('upload_dialog.upload_guide_link.clicked', {
      category: EventCategory.UPLOAD
    });
    window.open(UPLOAD_FORMAT_LINK, '_blank');
  }

  public uploadClicked() {
    this.trackService.track('upload_dialog.upload_and_index_button.clicked', {
      category: EventCategory.UPLOAD
    });
    this.indexingStore.uploadFiles();
  }

  public close() {
    this.componentEventEmitter.emit({
      isDialogClose: true,
      dialogEventData: { customizationPageToNavigate: this.customizationPageToNavigate, navigateToCreateAccount: this.navigateToCreateAccount }
    });
  }

  public confirmClose(pageToNavigate: CustomizationPage = null, navigateToCreateAccount: boolean = false) {
    this.customizationPageToNavigate = pageToNavigate;
    this.navigateToCreateAccount = navigateToCreateAccount;
    this.showConfirmExit = this.indexingFormChanged;

    if (!this.showConfirmExit) {
      this.close();
    } else {
      // This trace was emitted here and not inside the confirm exit component so we can differentiate upload_dialog event from re_index_dialog trace
      this.trackService.track('upload_dialog.confirm_exit.init', {
        category: EventCategory.UPLOAD
      });
    }
  }

  public leaveIndexing() {
    this.trackService.track('upload_dialog.confirm_exit.leave.clicked', {
      category: EventCategory.UPLOAD
    });
    this.close();
  }

  public onBackToIndexing() {
    this.trackService.track('upload_dialog.confirm_exit.cancel.clicked', {
      category: EventCategory.UPLOAD
    });
    this.showConfirmExit = !this.showConfirmExit;
  }

  public onFilesSelected(files: File[]) {
    const filesWithProps = files.map(file => this.fileHelperService.extractFileProperties(file));
    this.indexingStore.addFilesForUpload(filesWithProps);
    this.indexingStore.updateUploadMode(UploadMode.File);
    this.switchPage(UploadPages.IndexingForm);
  }

  public get showFooter(): boolean {
    return [UploadPages.IndexingForm, UploadPages.UploadSummary, UploadPages.UploadReview].includes(this.displayedUploadPage);
  }

  public get showUploadButton(): boolean {
    if (this.isExcludeAIsEnable) {
      return this.displayedUploadPage === UploadPages.UploadReview;
    }
    return !this.isUploadInProgress && !this.isUploadingDone;
  }

  public get showReviewAndUploadButton(): boolean {
    return this.isExcludeAIsEnable && !this.isUploadInProgress && !this.isUploadingDone && this.displayedUploadPage === UploadPages.IndexingForm;
  }

  public get showBackToSettingButton(): boolean {
    return this.isExcludeAIsEnable && this.displayedUploadPage === UploadPages.UploadReview;
  }

  public onBackToSettingsClicked() {
    this.switchPage(UploadPages.IndexingForm);
  }

  public onReviewAndUploadClicked() {
    this.switchPage(UploadPages.UploadReview);
  }

  public onConsentChanged(consent: boolean) {
    this.trackService.track('upload_dialog.confirm_terms.checked', { category: EventCategory.UPLOAD, data: { checked: consent } });
    this.indexingStore.updateUserConsent(consent);
  }

  public get isUploadDisabled(): boolean {
    return this.isUploadInProgress || this.filesExist || !this.userConsent;
  }

  public get isReviewAndUploadDisabled(): boolean {
    return this.isUploadInProgress || this.filesExist || !this.isSupportedAIsLoaded;
  }

  public onNavigateToCreateAccount() {
    this.confirmClose(null, true); // Nav to create account dialog
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources, { formatsLink: UPLOAD_FORMAT_LINK });

    for (const action in actions) {
      if (actions[action].id !== 'closeFromHeader') {
        actions[action].title = resources[actions[action].key];
      }
    }

    backToSettingsAction.title = resources[backToSettingsAction.key];
  }
}
