<div class="content-container">
  <!-- Main View -->
  <main class="main-view">
    <vi-ui-alert-message [type]="AlertMessageType.ERROR"
      [content]="data?.isClassic ? resources?.accountUnavailableDialog_Classic_Text : resources?.accountUnavailableDialog_Arm_Text">
    </vi-ui-alert-message>
    <vi-ui-link class="link"
      [inline]="true"
      [href]="link"
      [text]="data?.isClassic ? resources?.accountUnavailableDialog_Classic_Link : resources?.accountUnavailableDialog_Arm_Link">
    </vi-ui-link>
  </main>
</div>