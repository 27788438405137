import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { MediaCommonStoreService } from '@common/modules/media-data-common/services/media-common-store.service';
import { IUpdateInsights } from '@common/modules/insights/interfaces';
import * as fromMedia from '@common/modules/media-data-common/selectors/media.selectors';
import * as MediaActions from '@common/modules/media-data-common/actions/media.actions';

import * as LibraryActions from '../../gallery/core/actions/library.actions';
import * as LanguageModelActions from '../../customization-data/actions/language-model.actions';
import * as ProjectsActions from '../../gallery/core/actions/projects.actions';

@Injectable()
export class MediaStoreService {
  constructor(private readonly store: Store<fromMedia.IState>, private mediaCommonStoreService: MediaCommonStoreService) {}

  public getMediaIndex() {
    return this.store.select(fromMedia.selectIndex);
  }

  public getMediaDetails() {
    return this.store.select(fromMedia.selectMediaDetails);
  }

  public getMediaActionsData() {
    return this.store.select(fromMedia.selectMediaActionsData);
  }

  public getMediaAlertData() {
    return this.store.select(fromMedia.selectMediaAlertData);
  }

  public getMediaSaving() {
    return this.store.select(fromMedia.selectedMediaIndexSaving);
  }

  public getMediaError() {
    return this.store.select(fromMedia.selectedMediaIndexError);
  }

  public updateMediaIndex(id, index, newIndex, isBase = true) {
    this.store.dispatch(MediaActions.updateMediaIndex({ id, index, newIndex }));
    if (newIndex.name) {
      this.updateIndexName(id, newIndex.name, isBase);
    }
  }

  public updateIndexName(id: string, name: string, isBase = true) {
    if (!name) {
      return;
    }
    const action = isBase ? LibraryActions.updateVideoName({ id, name: name }) : ProjectsActions.updateVideoName({ id, name: name });
    this.store.dispatch(action);
  }

  public updateThumbnailId(id: string, thumbnailId: string, isBase: boolean) {
    if (!thumbnailId) {
      return;
    }
    // Update thumbnail id in gallery video item
    const galleryAction = isBase
      ? LibraryActions.updateThumbnailId({ id, thumbnailId: thumbnailId })
      : ProjectsActions.updateThumbnailId({ id, thumbnailId: thumbnailId });
    // Update thumbnail id in media index
    const mediaIndexAction = MediaActions.updateThumbnailId({ id, thumbnailId });

    [galleryAction, mediaIndexAction].forEach(action => {
      this.store.dispatch(action);
    });
  }

  public updateInsights(id: string, data: IUpdateInsights) {
    this.store.dispatch(MediaActions.updateInsights({ id, data }));
  }

  public updateFace(id: string, face: Microsoft.VideoIndexer.Contracts.Face) {
    this.store.dispatch(MediaActions.updateFace({ id, face }));
  }

  public updateTranscriptLine(id: string, line: Microsoft.VideoIndexer.Contracts.TranscriptLine) {
    this.store.dispatch(MediaActions.updateTranscriptLine({ id, line }));
  }

  public updateAllTranscriptLines(id: string, transcript: Microsoft.VideoIndexer.Contracts.TranscriptLine[]) {
    this.store.dispatch(MediaActions.updateAllTranscriptLines({ id, transcript }));
  }

  public updateOcrLine(id: string, line: Microsoft.VideoIndexer.Contracts.Ocr) {
    this.store.dispatch(MediaActions.updateOcrLine({ id, line: line }));
  }

  public upsertIndex(index: Microsoft.VideoIndexer.Contracts.PlaylistContractV2) {
    this.mediaCommonStoreService.upsertIndex(index);
  }

  public selectIndex(id: string) {
    this.mediaCommonStoreService.selectIndex(id);
  }

  public loadVideoIndex(selectAccount = true) {
    this.store.dispatch(MediaActions.loadVideoIndex({ selectAccount }));
  }

  public loadVideoProject() {
    this.store.dispatch(MediaActions.loadProjectIndex());
  }

  public requestReview(accountId: string, videoId: string) {
    this.store.dispatch(MediaActions.requestReview({ accountId: accountId, videoId: videoId }));
  }

  public clearLanguageModels() {
    this.store.dispatch(LanguageModelActions.clearLanguageModels());
  }

  public removeMediaIndex(id: string) {
    this.store.dispatch(MediaActions.removeMediaIndex({ id }));
  }
}
