import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const runInBackAction: IAction = {
  title: '',
  key: 'UploadRunInBackground',
  type: UIActionType.HIDE,
  value: UIActionType.HIDE,
  id: 'runInBack'
};

export const uploadAndIndexAction: IAction = {
  title: '',
  key: 'UploadAndIndexButton',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'uploadButtonAction'
};

export const reviewAndUploadAction: IAction = {
  title: '',
  key: 'ReviewAndUploadButton',
  type: UIActionType.PREVIEW,
  value: UIActionType.PREVIEW,
  id: 'reviewAndUploadBtn'
};

export const cancelAction: IAction = {
  title: '',
  key: 'Cancel',
  type: UIActionType.CANCEL,
  value: UIActionType.CANCEL,
  id: 'cancel'
};

export const closeAction: IAction = {
  title: '',
  key: 'Close',
  type: UIActionType.CLOSE,
  value: UIActionType.CLOSE,
  id: 'close'
};

export const closeFromHeaderAction: IAction = {
  title: '',
  key: 'Close',
  type: UIActionType.CLOSE,
  value: '',
  icon: 'i-vi-close-big',
  id: 'closeFromHeader',
  titleAttribute: 'Close'
};
