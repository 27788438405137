import { createSelector } from '@ngrx/store';

import { IAction } from '@common/modules/shared/interfaces';

import { selectCoreState } from '../reducers';

export const selectLogoGroupsState = createSelector(selectCoreState, state => state.logoGroups);

export const selectLogoGroups = createSelector(selectLogoGroupsState, state => Object.values(state.entities));

export const getLogoGroupsActions = createSelector(selectLogoGroups, logoGroups => {
  const actions: IAction[] = [];
  logoGroups.forEach(logoGroup => {
    const logoGroupAction: IAction = {
      title: logoGroup.name,
      value: logoGroup.name,
      id: logoGroup.id
    };
    actions.push(logoGroupAction);
  });
  return actions;
});

export const selectLogoGroupError = createSelector(selectLogoGroupsState, state => state.error);

export const isLogoGroupsLoaded = createSelector(selectLogoGroupsState, state => state.loaded);

export const isLogoGroupsLoading = createSelector(selectLogoGroupsState, state => state.loading);

export const getLogoGroupById = (id: string) => createSelector(selectLogoGroupsState, state => state.entities[id]);
