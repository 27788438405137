import { createAction, props } from '@ngrx/store';

import { IBrandsSettings } from '../../customization/components/brand/brand-container/interfaces';

import BrandContractV2 = Microsoft.VideoIndexer.Contracts.BrandContractV2;

export const loadBrandModels = createAction('[BrandModel/API] Load Brand Models');
export const loadBrandsSettings = createAction('[BrandModel] load brands settings');
export const failLoadBrandModels = createAction('[BrandModel] Fail load Brand Models');
export const upsertBrandModel = createAction('[BrandModel] Upsert Brand Model', props<{ brand: BrandContractV2 }>());
export const updateBrandModel = createAction('[BrandModel] Update Brand Model', props<{ brand: BrandContractV2 }>());
export const discardBrandSaving = createAction('[BrandModel] Discard Brand Models saving', props<{ brand: BrandContractV2 }>());
export const updateBrandsSettings = createAction('[BrandModel] Update Brands settings', props<{ settings: IBrandsSettings }>());
export const upsertBrandModels = createAction('[BrandModel] Upsert Brand Models', props<{ brands: BrandContractV2[] }>());
export const upsertBrandsSettings = createAction('[BrandModel] upsert brands settings', props<{ settings: IBrandsSettings }>());
export const deleteBrandModel = createAction('[BrandModel/API] Delete Brand Model', props<{ brand: BrandContractV2 }>());
export const addBrandModel = createAction('[BrandModel/API] Add Brand Model', props<{ brand: BrandContractV2 }>());
export const brandModelsLoaded = createAction('[BrandModel] Brand Models Loaded');
export const clearBrandModels = createAction('[BrandModel] Clear Brand Models');
