import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { IApiSasContract } from '@common/modules/api/interfaces';
import { browserDetect } from '@common/modules/utils/browserDetect';

import { IFileBlob } from '../interfaces';

interface IUploadFileBlob {
  file: IFileBlob;
  fileUrl: string;
  totalBytesRemaining: number;
  bytesUploaded: number;
  currentFilePointer: number;
  maxBlockSize: number;
  blockIds: string[];
  cancelled: boolean;
  reader: FileReader;
}

@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageService {
  private readonly defaultBlockSize = 1024 * 4 * 1024; // 4 MB
  private readonly blockIdPrefix = 'block-';

  constructor(private apiService: ApiService) {}

  public uploadToBlobStorage(file: IFileBlob, sas: IApiSasContract) {
    // Check that file exists
    if (!file || !sas) {
      return;
    }
    const fileBlob = this.initParameters(file, sas);

    // The file is less than 256MB
    if (file.size < 1024 * 1024 * 256) {
      return this.apiService.Account.uploadToBlobStorage(fileBlob.fileUrl, fileBlob.file, this.getContentType(fileBlob.file.type));
    }

    // The file is larger than 256MB than upload in blocks.
    return this.uploadInBlocks(fileBlob);
  }

  public getContentType(type: string) {
    if (!type || type === 'text/plain') {
      return 'video/mp4';
    }
    return type;
  }

  public generateFile(fileParts: IFileBlob): File {
    let file;
    const currentBrowser = browserDetect();
    // File API is not supported on edge browser under version 19
    if (currentBrowser.browser === 'Edge' && currentBrowser.version < 19) {
      const fileBlob = new Blob([fileParts], { type: fileParts.type });
      file = this.blobToFile(fileBlob, fileParts.name);
    } else {
      file = new File([fileParts], fileParts.name, { type: fileParts.type });
    }

    return file;
  }

  private initParameters(file: IFileBlob, sas: IApiSasContract): IUploadFileBlob {
    return {
      currentFilePointer: 0,
      bytesUploaded: 0,
      blockIds: [],
      cancelled: false,
      totalBytesRemaining: file.size,
      reader: null,
      maxBlockSize: file.size < this.defaultBlockSize ? file.size : this.defaultBlockSize,
      file,
      fileUrl: sas.baseUrl + sas.sasToken
    };
  }

  private uploadInBlocks(blobFile: IUploadFileBlob): Observable<object> {
    return new Observable(obs => {
      // Init reader
      blobFile.reader = new FileReader();
      // On loaded block ended
      blobFile.reader.onloadend = event => {
        this.onReaderLoadEnd(event, obs, blobFile);
      };

      // Read file
      this.readFileInBlocks(obs, blobFile);
    });
  }

  private onReaderLoadEnd(event, obs, blobFile: IUploadFileBlob) {
    // event not ready state or cancelled
    if (event.target.readyState !== 2 || blobFile.cancelled) {
      return;
    }

    const url = blobFile.fileUrl + '&comp=block&blockid=' + blobFile.blockIds[blobFile.blockIds.length - 1];

    // convert buffer bytes to blob and add it to form data
    const uploaded = new Uint8Array(event.target.result);
    const blob = new Blob([uploaded.buffer]);

    // upload one block to blob storage
    this.apiService.Account.uploadToBlobStorage(url, blob, blobFile.file.type).subscribe({
      next: (res: HttpResponse<object>) => {
        if (res.type !== HttpEventType.Response) {
          return;
        }
        blobFile.bytesUploaded += uploaded.length;
        const prog = {
          type: HttpEventType.UploadProgress,
          loaded: parseFloat(blobFile.bytesUploaded.toString()).toFixed(2),
          total: parseFloat(blobFile.file.size.toString()).toFixed(2)
        };
        obs.next(prog);
        this.readFileInBlocks(obs, blobFile);
      },
      error: error => {
        obs.error(error);
      }
    });
  }

  private readFileInBlocks(obs, blobFile: IUploadFileBlob) {
    // Cancel upload
    if (blobFile.cancelled) {
      return;
    }

    // Finish put all blocks
    if (blobFile.totalBytesRemaining <= 0) {
      this.commitBlockList(obs, blobFile);
      return;
    }

    // Read next block from remaining bytes;
    const fileContent = blobFile.file.slice(blobFile.currentFilePointer, blobFile.currentFilePointer + blobFile.maxBlockSize);
    const blockId = this.blockIdPrefix + this.pad(blobFile.blockIds.length, 6);
    blobFile.blockIds.push(btoa(blockId));
    blobFile.reader.readAsArrayBuffer(fileContent);

    // Update parameters to read next block
    blobFile.currentFilePointer += blobFile.maxBlockSize;
    blobFile.totalBytesRemaining -= blobFile.maxBlockSize;
    blobFile.maxBlockSize = blobFile.totalBytesRemaining < blobFile.maxBlockSize ? blobFile.totalBytesRemaining : blobFile.maxBlockSize;
  }

  private pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  private commitBlockList(obs, blobFile: IUploadFileBlob) {
    const url = blobFile.fileUrl + '&comp=blocklist';
    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < blobFile.blockIds.length; i++) {
      requestBody += '<Latest>' + blobFile.blockIds[i] + '</Latest>';
    }
    requestBody += '</BlockList>';
    this.apiService.Account.commitUploadBlockList(url, requestBody, blobFile.file.type).subscribe({
      next: res => {
        obs.next(res);
      },
      error: error => {
        obs.error(error);
      }
    });
  }

  private blobToFile(blobFile: Blob, fileName: string): File {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const blob: any = blobFile;
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob as File;
  }
}
