import { UIActionIcon, UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { UIGalleryActionType, UIGalleryTabsActionType } from './gallery';

export const indexingSummaryAction: IAction = {
  icon: UIActionIcon.INDEXING_SETTINGS,
  key: 'IndexingSettings',
  title: '',
  type: UIActionType.INDEXING_SETTINGS,
  selected: false,
  value: UIGalleryActionType.INDEXING_SETTINGS,
  selectable: true,
  id: 'INDEXING_SETTINGS',
  titleAttribute: 'gallery-indexing-settings'
};

export const galleryActionsAmsless: IAction[] = [
  {
    icon: UIActionIcon.INDEX,
    key: 'Reindex',
    title: '',
    type: UIActionType.RE_INDEX,
    selected: false,
    value: UIGalleryActionType.REINDEX_VIDEO,
    selectable: true,
    id: 'REINDEX',
    titleAttribute: 'gallery-reindex' // for all actions
  },
  {
    icon: UIActionIcon.TRASH,
    key: 'Delete',
    title: 'Delete',
    type: UIActionType.DELETE,
    selected: false,
    value: UIGalleryActionType.DELETE_VIDEO,
    selectable: true,
    id: 'DELETE'
  },
  {
    icon: UIActionIcon.COPY,
    key: 'CopyFileId',
    title: '',
    type: UIActionType.COPY,
    selected: false,
    value: UIGalleryActionType.COPY_FILE_ID,
    selectable: true,
    id: 'copyFileId'
  },
  {
    icon: UIActionIcon.OPEN,
    key: 'OpenInNewTab',
    title: '',
    type: UIActionType.OPEN_IN_NEW_TAB,
    selected: false,
    value: UIGalleryActionType.OPEN_IN_NEW_TAB,
    selectable: true,
    id: 'openInNewTab'
  }
];

export const galleryActions: IAction[] = [
  {
    icon: UIActionIcon.INDEX,
    key: 'Reindex',
    title: '',
    type: UIActionType.RE_INDEX,
    selected: false,
    value: UIGalleryActionType.REINDEX_VIDEO,
    selectable: true,
    id: 'REINDEX',
    titleAttribute: 'gallery-reindex' // for all actions
  },
  {
    icon: UIActionIcon.EDITOR,
    key: 'Editor',
    title: '',
    type: UIActionType.EDITOR,
    selected: false,
    value: UIGalleryActionType.EDIT_VIDEO,
    selectable: true,
    id: 'EDITOR'
  },
  {
    icon: UIActionIcon.TRASH,
    key: 'Delete',
    title: 'Delete',
    type: UIActionType.DELETE,
    selected: false,
    value: UIGalleryActionType.DELETE_VIDEO,
    selectable: true,
    id: 'DELETE'
  },
  {
    icon: UIActionIcon.COPY,
    key: 'CopyFileId',
    title: '',
    type: UIActionType.COPY,
    selected: false,
    value: UIGalleryActionType.COPY_FILE_ID,
    selectable: true,
    id: 'copyFileId'
  },
  {
    icon: UIActionIcon.OPEN,
    key: 'OpenInNewTab',
    title: '',
    type: UIActionType.OPEN_IN_NEW_TAB,
    selected: false,
    value: UIGalleryActionType.OPEN_IN_NEW_TAB,
    selectable: true,
    id: 'openInNewTab'
  }
];

export const galleryProjectActions: IAction[] = [
  {
    icon: UIActionIcon.PLAY,
    key: 'Play',
    title: 'Play',
    type: UIActionType.PLAY,
    selected: false,
    value: UIGalleryActionType.PLAY_VIDEO,
    selectable: true,
    id: 'PLAY',
    titleAttribute: 'gallery-play'
  },
  {
    icon: UIActionIcon.TRASH,
    key: 'Delete',
    title: 'Delete',
    type: UIActionType.DELETE,
    selected: false,
    value: UIGalleryActionType.DELETE_VIDEO,
    selectable: true,
    id: 'DELETE'
  }
];

export const galleryPlayAction: IAction = {
  icon: UIActionIcon.OPEN,
  key: 'GalleryActionPlay',
  title: 'Play',
  type: UIActionType.OPEN,
  selected: false,
  value: UIGalleryActionType.PLAY_VIDEO,
  selectable: true,
  id: 'PLAY'
};

export const galleryGridViewAction: IAction = {
  icon: UIActionIcon.GRID,
  key: 'Tiles',
  title: '',
  type: UIActionType.GRID,
  selected: false,
  value: UIGalleryTabsActionType.GRID_VIEW,
  selectable: true,
  id: 'grid-view',
  titleAttribute: 'GalleryLayoutLabel'
};

export const galleryListViewAction: IAction = {
  icon: UIActionIcon.LIST,
  key: 'List',
  title: '',
  type: UIActionType.LIST,
  selected: false,
  value: UIGalleryTabsActionType.LIST_VIEW,
  selectable: true,
  id: 'list-view',
  titleAttribute: 'GalleryLayoutLabel'
};

export const galleryGridViewMainAction: IAction = {
  ...galleryGridViewAction,
  id: 'grid-view-main'
};

export const galleryListViewMainAction: IAction = {
  ...galleryListViewAction,
  id: 'list-view-main'
};

export const galleryViewActions: IAction[] = [galleryGridViewAction, galleryListViewAction];

export const galleryTabsNoTitleActions: IAction[] = [
  {
    icon: UIActionIcon.GRID,
    key: 'GalleryActionGrid',
    type: UIActionType.GRID_MINI,
    selected: false,
    value: UIGalleryTabsActionType.GRID_VIEW,
    selectable: true,
    id: 'GRID'
  },
  {
    icon: UIActionIcon.LIST,
    key: 'GalleryActionList',
    type: UIActionType.LIST_MINI,
    selected: false,
    value: UIGalleryTabsActionType.LIST_VIEW,
    selectable: true,
    id: 'LIST'
  }
];

export const cancelButtonAction: IAction = {
  title: 'Cancel',
  key: 'Cancel',
  selected: false,
  type: UIActionType.CANCEL,
  value: 'Cancel',
  selectable: true,
  hasDrop: false,
  id: 'Cancel'
};

export const deleteButtonAction: IAction = {
  title: 'Delete',
  key: 'Delete',
  selected: false,
  type: UIActionType.DELETE,
  value: 'Delete',
  selectable: true,
  hasDrop: false,
  id: 'Delete'
};

export const mainAction: IAction = {
  icon: 'i-vi-more',
  titleAttribute: 'Video options',
  key: 'VideoOptions',
  title: '',
  selected: false,
  type: UIActionType.EDIT,
  value: UIActionType.EDIT,
  selectable: true,
  hasDrop: true,
  id: 'galleryVideo'
};

export const searchAction: IAction = {
  title: 'Search',
  key: 'Search',
  type: UIActionType.SEARCH,
  value: UIActionType.SEARCH,
  id: 'search'
};

export const clearFiltersAction: IAction = {
  title: 'Clear filters',
  key: 'ClearFilters',
  type: UIActionType.CLEAR_FILTERS,
  value: UIActionType.CLEAR_FILTERS,
  id: 'clearFilters'
};
