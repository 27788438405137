export const resources = {
  IndexingManageSpeechModels: '',
  UploadOrValue: '',
  UploadEditLanguagesIdsList: '',
  UploadMultiLanguagePreviewFeature: '',
  UploadMultiLanguageSelectedLanguagesIds: '',
  VIUploadCustomizationApplied: '',
  UploadVideoSourceLanguage: '',
  ReindexModalKeepSourceValue: ''
};
