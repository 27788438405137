<div class="accounts-list-container"
  *ngIf="ready">
  <div class="selected-account"
    [attr.id]="'viAccount' + selectedAccount?.id"
    [attr.aria-label]="resources?.AccountListSelectedAccountInfoLabel"
    [title]="resources?.AccountListSelectedAccountInfoLabel">
    <span class="selected-account-title">
      {{ resources?.SelectedAccount }}:
    </span>
    <div class="ac-item account-selected"
      id="viAccountSelected">
      <span class="account-name truncate"
        title="{{ selectedAccount?.name }}">
        {{ selectedAccount?.name }}
      </span>
      <span *ngIf="isTrial(selectedAccount)"
        class="trial-badge"
        [title]="getTranslationLocationTitle(selectedAccount)">
        {{ resources?.HeaderTrial }}
      </span>
      <span *ngIf="!isTrial(selectedAccount)"
        class="{{getBadgeClass(selectedAccount)}}"
        [title]="getBadgeText(selectedAccount)"
        [attr.aria-label]="getBadgeText(selectedAccount)">
        {{ getBadgeText(selectedAccount) }}
      </span>
    </div>
    <div class="selected-account-details-line region"
      *ngIf="!isTrial(selectedAccount)">
      <span class="region-title">{{ resources?.Region }}:</span>
      <span class="region">{{(selectedAccount)?.location}}</span>
    </div>
    <div class="selected-account-details-line">
      <span class="account-id-title">{{ resources?.ID }}:</span>
      <span class="account-id">{{(selectedAccount)?.id}}</span>
    </div>

    <vi-role-based-view-control-wrapper [requiredPermission]="AccountPermission.CONTRIBUTOR"
      [currentPermission]="currentPermission$ | async">
      <div class="selected-options">
        <div *ngIf="(userRule$ | async) === 'Owner' && (selectedAccount)?.resourceType !== AccountResourceType.ARM"
          tabindex="0"
          class="option share"
          role="button"
          [title]="resources.SidebarShareAccount"
          [attr.aria-label]="resources.SidebarShareAccount"
          (appAccessibleClick)="openInvite()">
          <i class="i-vi-share"></i>
          <span class="truncate">{{ resources.SidebarShareAccount }}</span>
        </div>
        <div *ngIf="(selectedAccount)?.resourceType === AccountResourceType.ARM"
          tabindex="0"
          class="option copy"
          role="button"
          id="copyBtnAccountList"
          [title]="resources.SettingsAccountIDCopyLabel"
          [attr.aria-label]="resources.SettingsAccountIDCopyLabel"
          (appAccessibleClick)="copyAccountId()">
          <i class="i-vi-copy"></i>
          <span class="truncate">{{ resources.SettingsAccountIDCopyLabel }}</span>
        </div>
        <div tabindex="0"
          class="option settings"
          role="button"
          [title]="resources.AccountSettingsTitle"
          [attr.aria-label]="resources.AccountSettingsTitle"
          (appAccessibleClick)="openAccountSettings()">
          <i class="i-vi-account-prefs"></i>
          <span class="truncate">{{ resources.AccountSettingsTitle }}</span>
        </div>
      </div>
    </vi-role-based-view-control-wrapper>

    <vi-role-based-view-control-wrapper [requiredPermission]="AccountPermission.RESTRICTED_VIEWER"
      [currentPermission]="currentPermission$ | async"
      [exact]="true">
      <div class="access-row">
        <span class="title">{{resources?.AccessTitle}}</span>
        <span class="access-value">{{resources?.ViewOnly}}</span>
      </div>
    </vi-role-based-view-control-wrapper>
  </div>

  <vi-ui-alert-message *ngIf="armAccountsError"
    class="arm-account-error-container"
    [content]="resources?.ArmAccountsErrorLoading"
    [type]="'error'">
  </vi-ui-alert-message>

  <div *ngIf="(isZeroAccounts$ | async) === false && showAccountsFilter"
    class="filter-accounts">
    <input type="text"
      [(ngModel)]="accountFilterValue"
      (ngModelChange)="filterAccounts()"
      autocomplete="off"
      placeholder="{{resources?.FindAccount}}"
      class="ms-TextField-field x-hidden-focus"
      aria-invalid="false">
    <div *ngIf="(accounts$ | async)?.length === 0"
      class="empty-accounts">
      {{ resources?.NoResultsFound }}
    </div>
  </div>
  <ul id="accountList"
    [ngClass]="{ 'arm-loading': !armAccountsLoaded,
                'arm-error': armAccountsError,
                'no-accounts': ((accounts$ | async)?.length === 0),
                'trial-account-selected': isTrial(selectedAccount),
                'no-filter-accounts': !showAccountsFilter }"
    [attr.aria-activedescendant]="'viAccount' + (selectedAccount)?.id"
    role="listbox"
    [title]="resources?.AccountListLabel"
    [attr.aria-label]="resources?.AccountListLabel">
    <ng-container *ngFor="let account of accounts$ | async; index as i">
      <li *ngIf="account.id !== (selectedAccount)?.id"
        role="option"
        tabindex="0"
        [attr.aria-selected]="false">
        <div class="ac-item"
          id="viAccount{{ account.id }}"
          (appAccessibleClick)="chooseAccount(account)">
          <span class="account-name truncate"
            [title]="account.name"
            [attr.aria-label]="account.name">
            {{ account.name }}
          </span>
          <span [ngClass]="isTrial(account) ? 'trial-badge' : 'location-text'"
            [title]="getTranslationLocationTitle(account)"
            [attr.aria-label]="getTranslationLocationTitle(account)">
            {{ isTrial(account) ? resources?.HeaderTrial : account.location }}
          </span>
          <span *ngIf="!isTrial(account)"
            class="{{getBadgeClass(account)}} margin-left-5px"
            [title]="getBadgeText(account)"
            [attr.aria-label]="getBadgeText(account)">
            {{ getBadgeText(account) }}
          </span>
        </div>
      </li>
    </ng-container>
    <div *ngIf="!armAccountsLoaded && !armAccountsError"
      class="arm-account-loader-container">
      <app-vi-spinner [size]="'tiny'"></app-vi-spinner>
      <label>{{ resources?.ArmAccountsLoading }}</label>
    </div>
  </ul>

  <div class="wrapper-btn">
    <app-vi-action-button [action]="createAccount"
      [type]="ActionButtonType.SECONDARY"
      (appAccessibleClick)="openConnectToAzure()"
      [width]="BUTTON_WIDTH">
    </app-vi-action-button>
  </div>
</div>