import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';

import { IAction } from '@common/modules/shared/interfaces';

import * as LanguageIdActions from '../actions/language-id.actions';
import { IState } from '../reducers';
import * as fromLanguageId from '../selectors/language-id.selectors';

@Injectable()
export class LanguageIdStoreService implements OnDestroy {
  protected destroy$ = new Subject<void>();

  constructor(private readonly store: Store<IState>) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public deleteSelectedLanguage(languageCode: Microsoft.VideoIndexer.Contracts.LanguageV2) {
    this.store.dispatch(LanguageIdActions.deleteSelectedLanguage({ languageCode: languageCode }));
  }

  public addSelectedLanguage(selectedLanguage: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract) {
    this.store.dispatch(LanguageIdActions.addSelectedLanguage({ selectedLanguage }));
  }

  public get selectedLanguagesId$(): Observable<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]> {
    return this.store.select(fromLanguageId.selectSelectedLanguages);
  }

  public get selectedLanguagesIdNames$(): Observable<string[]> {
    return this.store.select(fromLanguageId.selectSelectedLanguagesNames);
  }

  public get selectedLanguagesIdKeys$(): Observable<string[]> {
    return this.store.select(fromLanguageId.selectSelectedLanguagesKeys);
  }

  public get defaultLanguagesNames$(): Observable<string[]> {
    return this.store.select(fromLanguageId.selectDefaultLanguagesNames);
  }

  public get selectSupportedLanguagesIdsActions$(): Observable<IAction[]> {
    return this.store.select(fromLanguageId.selectSupportedLanguagesIdsActions);
  }

  public get supportedLanguagesIds$(): Observable<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]> {
    return this.store.select(fromLanguageId.selectSupportedAutoDetectLanguagesIds);
  }
}
