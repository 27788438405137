import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';

import { IState } from '../reducers';
import * as fromRouter from '../reducers/router';

export const RouteResolver: ResolveFn<Promise<boolean>> = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(RouteResolverService).resolve();
};

@Injectable({
  providedIn: 'root'
})
export class RouteResolverService {
  constructor(private store: Store<IState>, private logger: LoggerService) {
    this.logger.log('[RouteResolver] init');
  }

  // check if need to navigate on client route
  public resolve(): Promise<boolean> {
    return new Promise(resolve =>
      this.store
        .select(fromRouter.getQueryParams)
        .pipe(take(1))
        .subscribe(queryParams => {
          if (queryParams?.clientRoute) {
            this.logger.log('[RouteResolver] clientRoute: false');
            of(false);
            resolve(false);
          } else {
            this.logger.log('[RouteResolver] clientRoute: true');
            this.logger.log(queryParams);
            resolve(true);
          }
        })
    );
  }
}
