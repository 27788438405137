import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { VideoReviewState } from '@common/modules/shared/interfaces';
import { IUpdateInsights } from '@common/modules/insights/interfaces';

import { UpdateVideoIndexAction } from '../interfaces';

import PlaylistContractV2 = Microsoft.VideoIndexer.Contracts.PlaylistContractV2;

export const upsertMediaIndex = createAction('[Media] Upsert Media Index', props<{ mediaIndex: PlaylistContractV2 }>());

export const updateIndex = createAction('[Media] Update Media Index', props<{ mediaIndex: Update<PlaylistContractV2> }>());

export const updateMediaIndex = createAction(
  '[Media/API] Update Media Index',
  props<{ id: string; index: PlaylistContractV2; newIndex: PlaylistContractV2 }>()
);

export const updateVideoIndex = createAction(
  '[Media/API] Update Video Index',
  props<{
    action: UpdateVideoIndexAction;
    index?: PlaylistContractV2;
  }>()
);

export const updateVideoIndexSucceed = createAction(
  '[Media] Update Video Index Succeed',
  props<{
    action: UpdateVideoIndexAction;
  }>()
);

export const assignNewSpeaker = createAction(
  '[Media/API] Assign New Speaker',
  props<{
    addSpeakerAction: UpdateVideoIndexAction;
    updateTranscriptLineAction: UpdateVideoIndexAction;
  }>()
);

export const updateProjectMediaIndex = createAction(
  '[Media/API] Update Project Media Index',
  props<{ id: string; index: PlaylistContractV2; newIndex: PlaylistContractV2 }>()
);

export const updateIndexSaving = createAction('[Media] Update Media Index Saving', props<{ saving: boolean }>());

export const loadMediaFailure = createAction('[Media] Load Media Failure', props<{ error }>());

export const selectMediaIndex = createAction('[Media] Select Media Index', props<{ id: string }>());

export const loadVideoIndex = createAction('[Media API] Get Video Index', props<{ selectAccount?: boolean }>());

export const loadProjectIndex = createAction('[Media API] Get Project Index');

export const requestReview = createAction('[Media] Request Review', props<{ accountId: string; videoId: string }>());

export const updateMediaReviewState = createAction('[Media] Update Request Review', props<{ videoId: string; reviewState: VideoReviewState }>());

export const removeMediaIndex = createAction('[Media] Remove Media Index', props<{ id: string }>());

export const updateThumbnailId = createAction('[Media] Update Media Index Thumbnail Id', props<{ id: string; thumbnailId: string }>());

export const updateInsights = createAction('[Media] Update Insights In Media Index', props<{ id: string; data: IUpdateInsights }>());

export const updateFace = createAction('[Media] Update Face In Media Index', props<{ id: string; face: Microsoft.VideoIndexer.Contracts.Face }>());

export const updateTranscriptLine = createAction(
  '[Media] Update Transcript Line In Media Index',
  props<{ id: string; line: Microsoft.VideoIndexer.Contracts.TranscriptLine }>()
);

export const updateAllTranscriptLines = createAction(
  '[Media] Update All Transcript Lines In Media Index',
  props<{ id: string; transcript: Microsoft.VideoIndexer.Contracts.TranscriptLine[] }>()
);

export const updateOcrLine = createAction(
  '[Media] Update OCR Line In Media Index',
  props<{ id: string; line: Microsoft.VideoIndexer.Contracts.Ocr }>()
);

export const setInsightsFilterValue = createAction('[Media] Set insights filter value', props<{ value: string }>());
