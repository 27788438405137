import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';

import { ISubscriptions } from '@common/modules/insights/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { resources } from './resources';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit, OnDestroy {
  public APIErrors = APIErrors;
  public resources = resources;
  public redirectUrl = '';
  public errorType = '';
  public traceId = '';

  private subscriptions: ISubscriptions = {};

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateHelperService,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirectUrl'];
      this.traceId = params['traceId'] || '';
      this.errorType = params['errorType'];

      this.setTranslationsSubscription();
    });
  }

  public ngOnDestroy() {
    // Unsubscribe all subscriptions.
    this.utilsService.unsubscribeSubscriptions(this.subscriptions);
  }

  private setTranslationsSubscription() {
    this.translateService
      .translateResources(this.resources, { traceId: this.traceId })
      .pipe(take(1))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }
}
