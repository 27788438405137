import { Directive, TemplateRef, ViewContainerRef, OnInit, Input } from '@angular/core';

import { EdgeExtensionsStoreService } from './../../../../apps/web/src/core/services/edge-extensions-store.service';

@Directive({
  selector: '[viShowIfEdge]'
})
export class ShowIfEdgeDirective implements OnInit {
  @Input('viShowIfEdge') show = true;
  private hasSelectedEdgeExtension = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private edgeExtensionsService: EdgeExtensionsStoreService
  ) {
    this.edgeExtensionsService.hasSelectedEdgeExtension$.subscribe(hasSelectedEdgeExtension => {
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
    });
  }

  public ngOnInit(): void {
    if (this.show) {
      this.showIfEdge();
    } else {
      this.hideIfEdge();
    }
  }

  private hideIfEdge() {
    if (!this.hasSelectedEdgeExtension) {
      this.showElement();
    } else if (this.hasSelectedEdgeExtension) {
      this.hideElement();
    }
  }

  private showIfEdge() {
    if (!this.hasSelectedEdgeExtension) {
      this.hideElement();
    } else if (this.hasSelectedEdgeExtension) {
      this.showElement();
    }
  }

  private hideElement() {
    this.viewContainer.clear();
  }

  private showElement() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
