import { ITelemetryItem } from '@microsoft/1ds-core-js';
import { IExtendedConfiguration, IExtendedTelemetryItem } from '@microsoft/1ds-analytics-web-js';

export enum EventType {
  Exception = 'Ms.Web.ClientError',
  Performance = 'Ms.Web.PageViewPerformance'
}

type ITelemetryInitializer = (item: IExtendedTelemetryItem) => boolean | void;

export interface IOneDSClientConfiguration extends IExtendedConfiguration {
  /**
   * app id to identify the origin of the data in upstream service for privacy matters
   */
  appId: string;
  /**
   * Common data to be added to all events
   */
  commonData?: Object;
  onEventSent?: (events: IExtendedTelemetryItem[]) => void;
  onEventDiscarded?: (events: ITelemetryItem[]) => void;
  telemetryInitializers?: ITelemetryInitializer[];
  /**
   * Configuration for metrics account
   */
  metrics?: {
    namespace: string;
    dimensions?: { [dimension: string]: string };
  };
  /**
   * Instrumentation key of resource. Either this or connectionString must be specified.
   */
  instrumentationKey?: string;
}
