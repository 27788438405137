import { Injectable, Injector } from '@angular/core';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { MonitoringErrorHandlerService } from '@common/modules/core/services/monitoring-error-handler/monitoring-error-handler.service';

import { CoreStoreService } from '../services/core-store.service';

@Injectable()
export class LoadChunksErrorHandler extends MonitoringErrorHandlerService {
  constructor(public trackService: TrackService, private logger: LoggerService, private injector: Injector) {
    super(trackService);
    this.logger.log('[LoadChunksErrorHandler] init');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any): void {
    const chunkFailedMessage = new RegExp(/Loading chunk \d|\w failed/);

    if (chunkFailedMessage.test(error?.message)) {
      this.logger.log('[LoadChunksErrorHandler] chunk Failed Message');

      this.trackService.trackError(error, {
        category: EventCategory.EXCEPTIONS
      });

      const coreStore = this.injector.get(CoreStoreService);
      coreStore.loadChunksError();
    } else {
      super.handleError(error);
    }
  }
}
