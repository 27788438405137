import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { guid } from '@common/modules/utils/string';

import { IUIPresetInsight } from '../../../insights/interfaces';
import { Direction, IAction } from '../../interfaces';
import { FocusManagerService } from '../../../accessibility/focus-manager.service';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { EventCategory, TrackService } from '../../../core/services/track';
import { ActionButtonType } from '../action-button/interfaces';
import { resources } from './resources';
import { directionActions, sortAction } from './actions';

@Component({
  selector: 'vi-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortMenuComponent implements OnInit {
  // Input
  @Input() public columns: IAction[];
  @Input() public minWidth = '';
  @Input() public selectedColumn = '';
  @Input() public selectedDirection = '';
  @Input() public calcWidth = true;
  @Input() public isDisabled = false;
  @Input() public responsiveState = '';
  @Input() public sizeClass = '';
  @Input() public titleAttribute = '';
  @Input() public buttonType: ActionButtonType;

  // Output
  @Output() public presetInsightChange: EventEmitter<IUIPresetInsight> = new EventEmitter<IUIPresetInsight>();
  @Output() public actionChange = new EventEmitter<IAction[]>();
  @Output() public widthCalculated = new EventEmitter<number>();
  @Output() public focusInActionButton = new EventEmitter();

  // Actions
  public sortAction: IAction = cloneDeep(sortAction);
  public directions: IAction[] = cloneDeep(directionActions);

  // Const
  public readonly MENU_ID = 'sortMenu';

  // Public
  public buttonId = `sortBtn_${guid()}`;
  public resources = resources;
  public expanded = false;
  public showTooltip = false;
  public showTabTooltip = false;
  public width = 'auto';
  public maxWidth = '50vw';
  public Direction = Direction;

  @ViewChild('ColumnsContainer') public columnsContainer: ElementRef;

  // Private
  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private focusManager: FocusManagerService,
    private trackService: TrackService
  ) {}

  public ngOnInit() {
    this.translate.translateResources(this.resources);
    // Actions
    this.directions.forEach(c => (c.title = this.resources[c.key]));
    this.sortAction.title = this.resources[this.sortAction.key];
    this.sortAction.titleAttribute = this.titleAttribute;

    // Set selected column and direction
    this.setSelectedActions();
  }

  public selectColumn(column: IAction) {
    this.columns.forEach(p => (p.selected = false));
    column.selected = true;
    let direction = this.directions.find(p => p.selected === true);
    if (!direction) {
      direction = this.directions[0];
      direction.selected = true;
    }
    this.actionChange.emit([column, direction]);
    this.trackService.track('people.sort.column.click', {
      category: EventCategory.CUSTOMIZATION,
      data: { direction: direction?.value, column: column?.value }
    });
  }

  public selectDirection(direction: IAction) {
    this.directions.forEach(p => (p.selected = false));
    direction.selected = true;
    let column = this.columns.find(p => p.selected === true);
    if (!column) {
      column = this.columns[0];
      column.selected = true;
    }
    this.actionChange.emit([column, direction]);

    this.trackService.track('people.sort.direction.click', {
      category: EventCategory.CUSTOMIZATION,
      data: { direction: direction?.value, column: column?.value }
    });
  }

  public handleActionButtonClick(e: MouseEvent | KeyboardEvent) {
    this.expanded = !this.expanded;
    this.cdr.detectChanges();
  }

  public handleArrowUp(event: KeyboardEvent) {
    event.preventDefault();
    const prev = (<HTMLElement>event.target)?.previousElementSibling || this.columnsContainer?.nativeElement?.lastElementChild;
    this.focusOnTarget(prev);
  }

  public handleArrowDown(event: KeyboardEvent) {
    event.preventDefault();
    const next = (<HTMLElement>event.target)?.nextElementSibling || this.columnsContainer?.nativeElement?.firstElementChild;
    this.focusOnTarget(next);
  }

  public focusOnTarget(target: Element) {
    if (target) {
      this.focusManager.focusVia(() => {
        return new ElementRef(target);
      }, this.cdr);
    }
  }

  public handleEscClick(e: KeyboardEvent) {
    if (this.expanded) {
      this.expanded = !this.expanded;
      // Focus main action
      this.focusManager.focusByQuery(`#${this.buttonId}`, this.cdr);
    }
  }

  public onMouseOver(state: boolean) {
    this.showTooltip = state;
  }

  public handleFocusInActionButton() {
    this.focusInActionButton.emit();
  }

  private setSelectedActions() {
    if (this.selectedColumn && this.selectedDirection) {
      const column = this.columns.find(p => p.value === this.selectedColumn);
      const direction = this.directions.find(p => p.value === this.selectedDirection);
      if (column) {
        column.selected = true;
      }
      if (direction) {
        direction.selected = true;
      }
    }
  }
}
