import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromLibrary from './library.reducer';
import * as fromProjects from './projects.reducer';
import * as fromSamples from './samples.reducer';
import * as fromSearch from './search.reducer';
import * as fromGalleryView from './gallery-view.reducer';

export interface IState {
  library: fromLibrary.State;
  projects: fromProjects.State;
  samples: fromSamples.State;
  search: fromSearch.IState;
  galleryView: fromGalleryView.State;
}

export const reducers: ActionReducerMap<IState> = {
  library: fromLibrary.reducer,
  projects: fromProjects.reducer,
  samples: fromSamples.reducer,
  search: fromSearch.reducer,
  galleryView: fromGalleryView.reducer
};

export const selectGalleryState = createFeatureSelector<IState>('gallery');
