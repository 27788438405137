<!-- the 'position' attribute is used to force the positioning of the options list.
  the 'positionAttribute' attribute reflects the placement for the listbox when the select is open. -->
<fluent-select #FluentSelect
  class="{{size}} indicator-{{indicatorSize}} {{type}}"
  [ngClass]="{'icon-only': isIconOnly, 'placeholder-as-first-action': placeholderAsFirstAction, 'error': isError}"
  [attr.id]="id"
  [attr.aria-label]="ariaLabel"
  [attr.appearance]="appearance"
  position="force"
  [positionAttribute]="position"
  [disabled]="isDisabled"
  [attr.aria-labelledby]="ariaLabelledBy || null"
  (change)="handleOnChange($event)">
  <ng-container *ngIf="icon">
    <ng-container [ngTemplateOutlet]="isIconOnly ? iconOnlyTemplate : iconAndTextTemplate"></ng-container>
  </ng-container>
  <div class="selected-value-container"
    slot="selected-value"
    [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="handleOnHover($event)">
    <div>{{selectedAction?.title || placeholder}}</div>
  </div>
  <fluent-option *ngFor="let item of actionList"
    [ngClass]="{'group': item?.isGroup}"
    [value]="item?.value"
    [disabled]="item?.isDisabled"
    [attr.selected]="item?.value === selectedAction?.value || null"
    [attr.title]="item?.title">
    <i *ngIf="item?.icon"
      slot="start"
      [attr.class]="item.icon"></i>
    {{item?.title}}
  </fluent-option>
</fluent-select>

<ng-template #iconOnlyTemplate>
  <i slot="selected-value"
    [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="handleOnHover($event)"
    [attr.class]="icon"></i>
</ng-template>

<ng-template #iconAndTextTemplate>
  <i class="icon-with-text"
    slot="start"
    [appAccessibilityContentOnHoverHandler]
    (contentOnHover)="handleOnHover($event)"
    [attr.class]="icon"></i>
</ng-template>
