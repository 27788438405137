import { AccountPermission, IAction } from '@common/modules/shared/interfaces';

import { APIErrors } from './errors';

export interface IError {
  key: APIErrors;
  msg: string;
  actionLink?: string;
  action?: string;
  actionState?: string;
}

export interface IAPIError {
  ErrorType: APIErrors;
  Message: string;
}

export interface IErrorTypes {
  GENERAL: IError;
  INVALID_INPUT: IError;
  USER_NOT_AUTHENTICATED: IError;
  USER_NOT_SIGNED_IN: IError;
  USER_NOT_SIGNED_IN_AAD: IError;
  USER_NOT_REGISTERED: IError;
  USER_NOT_ALLOWED: IError;
  USER_NOT_ADMIN: IError;
  USER_NOT_FOUND: IError;
  USER_SUSPENDED: IError;
  ACCESS_TOKEN_VALIDATION_FAILED: IError;
  ANTI_FORGERY_TOKEN_VALIDATION_FAILED: IError;
  VIDEO_UPLOAD_LIMIT_EXCEEDED: IError;
  ACCOUNT_UPLOAD_QUOTA_EXCEEDED: IError;
  BREAKDOWN_NOT_FOUND: IError;
  NOT_FOUND: IError;
  URL_UNREACHABLE: IError;
  OFFLINE: IError;
  ALREADY_EXISTS: IError;
  WIKI_ALREADY_EXISTS: IError;
  VIDEO_ALREADY_IN_PROGRESS: IError;
  VIDEO_ALREADY_EXISTS: IError;
  ACCOUNT_NOT_FOUND: IError;
  EVENT_GRID_NOT_REGISTERED: IError;
  QuotaViolation: IError;
  VIDEO_REMOVED: IError;
  EMPTY_INSIGHTS: IError;
  Unauthorized: IError;
  INVALID_PICTURE_SIZE: IError;
  AMS_UNREACHABLE: IError;
  CUSTOM_VISION_MODEL_NOT_FOUND: IError;
  CUSTOM_VISION_CHARACTER_NOT_FOUND: IError;
  CUSTOM_VISION_MODELS_TRAIN_LIMIT_EXCEEDED: IError;
  CUSTOM_VISION_MODEL_TRAIN_OPERATION_NOT_FOUND: IError;
  TOO_MANY_SOURCE_VIDEOS: IError;
  CUSTOM_VISION_ACCOUNT_NOT_FOUND: IError;
  CUSTOM_VISION_UNSUPPORTED_LOCATION: IError;
  INVALID_URL: IError;
  CUSTOM_VISION_VIDEO_INDEXER_LOCATION_CORRELATION: IError;
  CUSTOM_VISION_INVALID_TRAINING_KEY: IError;
  CUSTOM_VISION_INVALID_PREDICTION_KEY: IError;
  OPERATION_NOT_SUPPORTED_ON_TRIAL: IError;
  VIDEO_NAME_TOO_LONG: IError;
  AMS_AAD_APPLICATION_NOT_IN_TENANT: IError;
  VIDEO_INDEXING_IN_PROGRESS: IError;
  INVALID_INVITEE_FRIENDLY_EMAIL_ADDRESS: IError;
  LOCATION_NOT_FOUND: IError;
  FACE_IDENTIFICATION_NOT_ALLOWED: IError;
  FACE_CUSTOMIZATION_NOT_ENABLED: IError;
  DATASET_IN_USE: IError;
  MODEL_IN_USE: IError;
  STORAGE_UNREACHABLE: IError;
}

export interface IAPIErrorContract {
  statusCode?: number;
  ErrorType: string;
  Message: string;
}

export enum NotificationType {
  // Banner will be shown on top of the page as a strip and on the notifications panel
  Banner = 'BANNER',
  // Alert will be shown as a toast on the top right page corner and on the notifications panel
  Alert = 'ALERT',
  // Message will be shown as a toast on the top right page corner and on the notifications panel
  Message = 'MESSAGE',
  // Toast will be shown as a toast on the top right page corner (will not shown in the notifications panel)
  Toast = 'TOAST'
}

export enum NotificationFilterType {
  Default = 'Default',
  Tenant = 'Tenant',
  Region = 'Region',
  AccountType = 'AccountType'
}

export enum NotificationMessageType {
  Custom = 'Custom',
  ServiceOutage = 'ServiceOutage',
  PartialOutage = 'PartialOutage',
  RegionalOutage = 'RegionalOutage',
  ServiceRestored = 'ServiceRestored',
  DependencyOutageAzureMediaServices = 'DependencyOutageAzureMediaServices',
  DependencyOutageAzureKubernetesService = 'DependencyOutageAzureKubernetesService',
  DependencyOutageAzureNetworking = 'DependencyOutageAzureNetworking',
  DependencyOutageAzureStorage = 'DependencyOutageAzureStorage',
  DependencyOutageAzureCompute = 'DependencyOutageAzureCompute',
  FacialRecognition = 'FacialRecognition',
  ReleaseNotes = 'ReleaseNotes',
  WelcomeMessage = 'WelcomeMessage',
  UpdateAccount = 'UpdateAccount'
}

export interface INotificationLink {
  text: string;
  src?: string;
  callback?: () => void;
}

export interface INotificationButton {
  action: IAction;
  callback?: () => void;
}

export enum NotificationLevel {
  None = 'None',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Critical = 'Critical'
}

export enum NotificationIcon {
  None = '',
  Info = 'i-vi-info',
  Success = 'i-vi-select',
  Warning = 'i-vi-warning',
  Error = 'i-vi-error',
  Upload = 'i-vi-cloudupload'
}

export interface IBaseNotification {
  id: string;
  messageType: NotificationMessageType;
  startDate: Date;
  endDate: Date;
  type?: NotificationType;
  level?: NotificationLevel;
  title?: string;
  text?: string;
  region?: string;
  filterType?: NotificationFilterType;
  permission?: AccountPermission;
}

export interface INotification extends IBaseNotification {
  link?: INotificationLink;
  button?: INotificationButton;
  icon?: NotificationIcon;
  seen?: boolean;
  toasted?: boolean;
  cleared?: boolean;
  progress?: number;
  resolveAccountData?: boolean;
}

export interface INotificationsResponse {
  notifications: IBaseNotification[];
  eTag: string;
}

export interface INotificationMap {
  type: NotificationType;
  level: NotificationLevel;
  icon: NotificationIcon;
  link?: INotificationLink;
  button?: INotificationButton;
}
