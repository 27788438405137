import { IAction } from '@common/modules/shared/interfaces';

import { UIHeaderActionType } from './interfaces';

export const connectToAzureAction: IAction = {
  title: '',
  key: 'HeaderCreatePro',
  icon: 'i-vi-add-big',
  value: UIHeaderActionType.CONNECT_TO_AZURE,
  selected: false,
  selectable: false,
  id: 'connectToAzureButton'
};

export const sidemenuToggleAction: IAction = {
  title: '',
  key: 'HeaderToggleSidemenu',
  icon: 'i-vi-hamburger',
  value: '',
  selected: false,
  selectable: false,
  id: 'headerToggleSidemenu'
};

export const helpAction: IAction = {
  titleAttribute: 'Help',
  key: 'HeaderHelp',
  icon: 'i-vi-help-menu',
  value: UIHeaderActionType.HELP,
  selected: false,
  selectable: false,
  id: 'help'
};

export const helpLinkAction: IAction = {
  title: '',
  key: 'HeaderHelpLinkAria',
  icon: 'i-vi-help',
  value: UIHeaderActionType.HELP,
  selected: false,
  selectable: true,
  id: 'helpMobile'
};

export const notificationsEmptyTitleAction: IAction = {
  title: '',
  titleAttribute: '',
  key: 'SidebarUserNotifications',
  icon: 'i-vi-notification',
  value: UIHeaderActionType.NOTIFICATIONS,
  selected: false,
  selectable: true,
  id: 'notificationsBtn'
};

export const notificationsAction: IAction = {
  ...notificationsEmptyTitleAction,
  titleAttribute: 'Notifications'
};

export const settingsEmptyTitleAction: IAction = {
  titleAttribute: '',
  key: 'HeaderUserSettings',
  icon: 'i-vi-settings',
  value: UIHeaderActionType.SETTINGS,
  selected: false,
  selectable: true,
  id: 'settings'
};

export const settingsAction: IAction = {
  ...settingsEmptyTitleAction,
  titleAttribute: 'Settings'
};

export const signOutAction: IAction = {
  title: 'Sign Out',
  key: 'HeaderSignOut',
  value: UIHeaderActionType.SIGN_OUT,
  id: 'signOut'
};

export const switchDirectoryAction: IAction = {
  title: 'Switch Directory',
  icon: '',
  key: 'SwitchDirectory',
  value: UIHeaderActionType.SWITCH_DIRECTORY,
  id: 'switchDirectoryButton'
};

export const modelCustomizationAction: IAction = {
  icon: 'i-vi-customization',
  titleAttribute: 'Content model customization',
  key: 'HeaderContentModelCustomization',
  value: UIHeaderActionType.MODEL_CUSTOMIZATION,
  selected: false,
  selectable: false,
  id: 'modelCustomization'
};

export const accountsAction: IAction = {
  icon: 'i-vi-vi-accounts',
  titleAttribute: 'Accounts',
  key: 'HeaderUserAccounts',
  value: UIHeaderActionType.ACCOUNTS,
  selected: false,
  selectable: false,
  hasDrop: true,
  id: 'accounts'
};

export const menuAction: IAction = {
  icon: 'i-vi-menu',
  key: '',
  value: UIHeaderActionType.MENU,
  selected: false,
  selectable: false,
  id: 'mobileMenu'
};

export const menuActionList: IAction[] = [
  {
    icon: 'i-vi-vi-accounts',
    title: '',
    key: 'HeaderAccounts',
    value: UIHeaderActionType.ACCOUNTS,
    selected: false,
    selectable: true,
    id: 'accountsMobile'
  },
  settingsEmptyTitleAction,
  helpLinkAction
];

export const menuWithNotificationsActionList: IAction[] = [
  {
    icon: 'i-vi-vi-accounts',
    title: '',
    key: 'HeaderAccounts',
    value: UIHeaderActionType.ACCOUNTS,
    selected: false,
    selectable: true,
    id: 'accountsMobile'
  },
  notificationsEmptyTitleAction,
  settingsEmptyTitleAction,
  helpLinkAction
];

export const menuWithoutAccountsActionList: IAction[] = [connectToAzureAction, settingsEmptyTitleAction, helpLinkAction];
export const menuWithNotificationsWithoutAccountsActionList: IAction[] = [
  connectToAzureAction,
  notificationsEmptyTitleAction,
  settingsEmptyTitleAction,
  helpLinkAction
];
