import { OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Component } from '@angular/core';

import { IUserDetails } from '@common/modules/core/services/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  // Input
  @Input() public userDetails: IUserDetails;

  public resources = resources;
  public ready = false;

  constructor(private translate: TranslateHelperService, private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.translate.translateResources(resources).subscribe(() => {
      this.ready = true;
      this.cdr.detectChanges();
    });
  }

  public getUserTitle(): string {
    return `${this.userDetails?.name} (${this.userDetails?.email})`;
  }
}
