import { IUIRectElement } from '../../../interfaces';
export const svgElements: IUIRectElement[] = [
  { class: 'svg-fill-main', x: '1', y: '1', width: '64', height: '40' },
  { class: 'svg-fill-container', x: '0.5', y: '0.5', width: '65', height: '41' },
  { class: 'svg-fill-sidemenu', x: '1', y: '7', width: '7', height: '34' },
  { class: 'svg-fill-shadow', x: '46', y: '7', width: '1', height: '34' },
  { class: 'svg-fill-sidebar', x: '48', y: '7', width: '17', height: '34' },
  { class: 'svg-fill-header', x: '1', y: '1', width: '64', height: '6' },
  { class: 'svg-fill-title', x: '11', y: '10', width: '32', height: '3' },
  { class: 'svg-fill-subtitle', x: '11', y: '16', width: '24', height: '3' },
  { class: 'svg-fill-primary', x: '11', y: '22', width: '28', height: '3' },
  { class: 'svg-fill-highlight', x: '11', y: '31', width: '10', height: '3' }
];
