import { Injectable } from '@angular/core';

import {
  INotification,
  NotificationIcon,
  NotificationLevel,
  NotificationMessageType,
  NotificationType
} from '@common/modules/core/services/toast/interfaces';
import { NotificationsService } from '@common/modules/notifications/services/notifications.service';
import { guid } from '@common/modules/utils/string';

@Injectable()
export class UnknownPersonNotificationService {
  constructor(private notificationService: NotificationsService) {}

  public notify(title: string, text: string, toasted: boolean, level: NotificationLevel, icon: NotificationIcon) {
    const notification: INotification = {
      messageType: NotificationMessageType.Custom,
      id: `unknown_people_${guid()}`,
      startDate: new Date(),
      endDate: new Date(),
      type: NotificationType.Message,
      title: title,
      toasted: toasted,
      text: text,
      level: level,
      icon: icon
    };

    this.notificationService.notify(notification);
  }
}
