export const resources = {
  HeaderUploadVideo: '',
  HeaderContentModelCustomization: '',
  HeaderHelpLinkAria: '',
  HeaderHelp: '',
  HeaderUserSettings: '',
  HeaderSignOut: '',
  VIHeaderCreatePro: '',
  HeaderUserImageAlt: '',
  HeaderAccounts: '',
  HeaderUserAccounts: '',
  HeaderToggleSidemenu: '',
  HeaderComponentSkipMainContent: '',
  SidebarUserNotifications: ''
};
