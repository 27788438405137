import { Injectable, ViewContainerRef } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { LazyLoadService } from '@common/modules/core/services/lazy-load/lazy-load.service';

@Injectable({
  providedIn: 'root'
})
export class VIMainSidebarService {
  public isOpenSubject: BehaviorSubject<boolean>;

  private viSidebar: ViewContainerRef;

  constructor(private logger: LoggerService, private lazyLoadService: LazyLoadService) {
    this.isOpenSubject = new BehaviorSubject<boolean>(false);
  }

  public closeSidebar() {
    this.viSidebar.clear();
    this.isOpenSubject.next(false);
  }

  public toggleSidebar() {
    this.isOpenSubject.pipe(take(1)).subscribe(open => {
      this.isOpenSubject.next(!open);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async openSidebar(LazyModule: any, LazyComponent: any, data?) {
    this.viSidebar.clear();
    await this.lazyLoadService.loadComponent(LazyModule, LazyComponent, this.viSidebar, data);
    this.isOpenSubject.next(true);
  }

  public setVISidebarElement(element) {
    this.viSidebar = element;
  }
}
