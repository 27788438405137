import { createAction, props } from '@ngrx/store';

import { APIErrors } from '@common/modules/core/services/toast/errors';

import { INextPage } from '../models/gallery';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export const loadLibrary = createAction('[Library/API] Load Library');
export const loadingLibrary = createAction('[Library] Loading Library');
export const loadLibraryNextPage = createAction('[Library/API] Load Next Page');
export const upsertVideos = createAction('[Library] Upsert Videos', props<{ videos: SinglePlaylistSearchResultV2[]; nextPage?: INextPage }>());
export const updateVideo = createAction('[Library] Update Video', props<{ video: SinglePlaylistSearchResultV2 }>());
export const updateVideoName = createAction('[Library] Update Video Name', props<{ id: string; name: string }>());
export const updateThumbnailId = createAction('[Library] Update Thumbnail Id', props<{ id: string; thumbnailId: string }>());
export const deleteVideo = createAction('[Library/API] Delete Video', props<{ id: string; name: string }>());
export const deleteVideoSuccess = createAction('[Library] Delete Video Success', props<{ id: string }>());
export const deleteVideoFailed = createAction('[Library] Delete Video Failed', props<{ id: string }>());
export const deleteVideos = createAction('[Library/API] Delete Videos', props<{ ids: string[] }>());
export const clearLibrary = createAction('[Library] Clear Library');
export const loadLibraryError = createAction('[Library] Load Library Error', props<{ errorType?: APIErrors }>());
export const loadVideo = createAction('[Library/API] Load video', props<{ id: string }>());
export const loadVideoError = createAction('[Library] Load Video Error');
