<div *ngIf="!disabled"
  [style.min-height.px]="height">
  <div #showMoreContainer
    class="height-container">
    <ng-content select=".left-component"></ng-content>
    <div class="description-content">
      <h5 class="c-heading selected-item-name"
        *ngIf="showName">
        <span [title]="selectedItem?.name">{{selectedItem?.name}}</span>
        <i [title]="infoTitle"
          class="i-vi-info"
          [attr.tabindex]="activeContainer ? -1 : 0"></i>
      </h5>
      <span [id]="descriptionID()"
        class="action c-action-menu"
        *ngIf="selectedItem?.description">
        <a href="javascript:void(0)"
          class="c-hyperlink c-subheading-4"
          [title]="showButtonText"
          (appAccessibleClick)="toggleShowDescription()"
          role="button"
          [attr.tabindex]="activeContainer ? -1 : 0">
          <span>{{showButtonText}}</span>
          <i [ngClass]="{'i-vi-arrow-down': !showDescription, 'i-vi-arrow-up': showDescription }"></i>
        </a>
      </span>
      <ng-content select=".right-component"></ng-content>
    </div>
  </div>
  <!-- BIO -->
  <div #textContainer
    class="c-content-toggle"
    *ngIf="showDescription && selectedItem.description">
    <ng-content select="picture"></ng-content>
    <span>
      <p id="{{type}}ContentToggleDefaultParagraphId"
        data-f-expanded="false"
        [ngClass]="{'no-link': !hasLink()}">
        {{getItemDescriptions()}}</p>
      <a *ngIf="hasLink()"
        target="_blank"
        [href]="getDescriptionLink()"
        class="continue-reading"
        role="link"
        [attr.tabindex]="activeContainer ? -1 : 0"
        [title]="resources?.ReadMore">{{resources.ReadMore}}</a>
    </span>
  </div>
</div>