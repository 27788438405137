import { Injectable } from '@angular/core';

import { guid } from '@common/modules/utils/string';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';
import { FeatureSwitch } from '@common/modules/core/services/interfaces';

import { IFile, ErrorType } from '../interfaces';
import { getFileName } from '../utils/file';
import { getFileType, CloudMediaFileFormats, EdgeMediaFileFormats, ImageFileFormats } from '../utils/mediaFormats';
import { EdgeExtensionsStoreService } from '../../core/services/edge-extensions-store.service';

@Injectable()
export class FileHelperService {
  // Max size 2 GB from file system
  private readonly MAX_FILE_SIZE_FILE_SYSTEM = Math.pow(1024, 3) * 2;
  private readonly MAX_VIDEOS_CLOUD_LIMIT = 10;
  private readonly MAX_VIDEOS_EDGE_LIMIT = 10;
  private isImageUploadSupported: boolean = false;
  private hasSelectedEdgeExtension: boolean = false;
  private filesType: string;
  constructor(private edgeExtensionsStoreService: EdgeExtensionsStoreService, private featureSwitchService: FeatureSwitchService) {
    this.isImageUploadSupported = this.featureSwitchService.featureSwitch(FeatureSwitch.ImageUploadSupport);
    this.edgeExtensionsStoreService.hasSelectedEdgeExtension$.subscribe((hasSelectedEdgeExtension: boolean) => {
      this.filesType = this.getSupportedMediaTypesPattern();
      this.hasSelectedEdgeExtension = hasSelectedEdgeExtension;
    });
  }

  public get maxVideosLimit(): number {
    return this.hasSelectedEdgeExtension ? this.MAX_VIDEOS_EDGE_LIMIT : this.MAX_VIDEOS_CLOUD_LIMIT;
  }

  public extractFileProperties(file: File): IFile {
    const fileResult: IFile = {
      id: guid(),
      name: getFileName(file?.name),
      size: file?.size,
      fileContent: file
    };
    const errorType = this.validateFile(file);
    if (errorType) {
      fileResult.error = { errorType };
    }

    return fileResult;
  }

  public validateFile(file: File): ErrorType | null {
    let errorType: ErrorType | null = null;
    const fileExtension = getFileType(file.name)?.toLowerCase();
    const fileType = file?.type;
    // Separate type and subtype
    const typeArr = fileType.split('/');
    // In case file has wrong type (includes type/*) or wrong extension
    if (!fileExtension || (!this.filesType.includes(fileExtension) && (!fileType || !this.filesType.includes(`${typeArr[0]}/*`)))) {
      errorType = ErrorType.FileExtension;
      // If file size is bigger than 2GB
    } else if (file.size > this.MAX_FILE_SIZE_FILE_SYSTEM) {
      errorType = ErrorType.FileSize;
    }

    return errorType;
  }

  public getSupportedMediaTypesPattern(): string {
    let mediaTypes: string[];
    if (this.hasSelectedEdgeExtension) {
      mediaTypes = Object.values(EdgeMediaFileFormats);
    } else {
      mediaTypes = Object.values(CloudMediaFileFormats);
      if (this.isImageUploadSupported) {
        mediaTypes.push('image/*');
        mediaTypes = mediaTypes.concat(Object.values(ImageFileFormats));
      }
      mediaTypes.push('audio/*');
    }
    return `.${mediaTypes.join(',.')}`;
  }
}
