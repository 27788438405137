import { Injectable } from '@angular/core';

import { catchError, firstValueFrom, map, switchMap, withLatestFrom } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { CustomizationDataStoreService } from '../../customization-data/services/customization-data-store.service';
import { IndexingPreset, presetToRequiredAIsMap } from '../../indexing/components/shared/settings';

@Injectable({
  providedIn: 'root'
})
export class IndexingSummaryService {
  constructor(
    private customizationDataStoreService: CustomizationDataStoreService,
    private apiService: ApiService,
    private trackService: TrackService
  ) {
    this.customizationDataStoreService.loadLogoGroups();
    this.customizationDataStoreService.loadPersonModels();
  }

  public getLogoGroupNameById(logoGroupId: string): Promise<string> {
    return firstValueFrom(
      this.customizationDataStoreService.logoGroups.pipe(
        withLatestFrom(this.customizationDataStoreService.isLogoGroupsLoaded()),
        withLatestFrom(this.customizationDataStoreService.logoGroupsError),
        switchMap(async ([[, isLoaded], isError]) => {
          if (isError) {
            return Promise.reject();
          }
          if (isLoaded) {
            const logoGroup = await firstValueFrom(this.customizationDataStoreService.getLogoGroupById(logoGroupId));
            return logoGroup?.name || '';
          }
        })
      )
    );
  }

  public getPeopleModelNameById(personModelId: string): Promise<string> {
    return firstValueFrom(
      this.customizationDataStoreService.peopleModels.pipe(
        withLatestFrom(this.customizationDataStoreService.isPeopleModelsLoaded()),
        withLatestFrom(this.customizationDataStoreService.peopleModelsError),
        switchMap(async ([[, isLoaded], isError]) => {
          if (isError) {
            return Promise.reject();
          }
          if (isLoaded) {
            const personModel = await firstValueFrom(this.customizationDataStoreService.getPeopleModelById(personModelId));
            return personModel?.name || '';
          }
        })
      )
    );
  }

  public getSupportedAINamesByPreset(accountId: string, preset: IndexingPreset): Promise<string[]> {
    return firstValueFrom(
      this.apiService.Account.getAccountSupportedAIs(accountId, { indexingPreset: preset }).pipe(
        map(supportedAIs => {
          const aiNames = supportedAIs ? this.getSupportedAIsNames(supportedAIs.aIs) : [];
          this.trackService.track('indexing_summary_service.get_supported_ai_names_by_preset.success', {
            category: EventCategory.INDEXING_SETTINGS,
            data: aiNames
          });
          return aiNames;
        }),
        catchError(error => {
          this.trackService.track('indexing_summary_service.get_supported_ai_names_by_preset.failed', {
            category: EventCategory.INDEXING_SETTINGS,
            data: error
          });
          return Promise.reject();
        })
      )
    );
  }

  public getExcludedAIsNames(excludedAIs: ExcludableAIsModels[], supportedAIs: string[]): ExcludableAIsModels[] {
    return excludedAIs.filter(ai => supportedAIs.includes(ai));
  }

  public getIncludedAisNames(preset: IndexingPreset, supportedAIs: string[], excludedAIs: string[]): string[] {
    const RequiredAIs = presetToRequiredAIsMap[preset];
    return (RequiredAIs as string[]).concat(supportedAIs.filter(ai => !excludedAIs.includes(ai)));
  }

  private getSupportedAIsNames(supportedAIs: Microsoft.VideoIndexer.Contracts.SupportedAIs[]): string[] {
    return supportedAIs.map(ai => ai.name);
  }
}
