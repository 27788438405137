import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { ExcludableAIsModels } from '@common/modules/api/interfaces';

import { IndexingPreset, presetToRequiredAIsMap } from '../components/shared/settings/indexing-presets/interfaces';
import { IUISupportedAis, SupportedAIsMap } from '../interfaces';

@Injectable()
export class ExcludeAIsHelperService {
  public readonly DISPLAY_NAME_KEY = 'DisplayName';

  public readonly ROOT_SENSITIVE_MODELS = [
    ExcludableAIsModels.Emotions,
    ExcludableAIsModels.Faces,
    ExcludableAIsModels.Labels,
    ExcludableAIsModels.ObservedPeople
  ];

  private readonly ROOT_FACE_GATED_MODELS = [ExcludableAIsModels.Faces];
  private readonly INFO_MODELS = [ExcludableAIsModels.Entities, ExcludableAIsModels.Clapperboard];

  private _sensitiveAIs: ExcludableAIsModels[] = [];
  private _faceGatedAIs: ExcludableAIsModels[] = [];

  constructor() {}

  get sensitiveAIs() {
    return this._sensitiveAIs;
  }

  get faceGatedAIs() {
    return this._faceGatedAIs;
  }

  set sensitiveAIs(value: ExcludableAIsModels[]) {
    this._sensitiveAIs = value;
  }

  set faceGatedAIs(value: ExcludableAIsModels[]) {
    this._faceGatedAIs = value;
  }

  public getSupportedAIsMap(
    supportedAIs: Microsoft.VideoIndexer.Contracts.SupportedAIs[],
    preset: IndexingPreset,
    isAccountFaceGated: boolean,
    excludeAIs: ExcludableAIsModels[]
  ): SupportedAIsMap {
    this.resetAIsLists();
    let supportedAIsMap = {} as SupportedAIsMap;
    const requiredAIs = this.getRequiredAIsByPreset(preset);
    requiredAIs.forEach(requiredAI => {
      supportedAIsMap[requiredAI.name] = requiredAI;
    });

    const supportedAIsResponseAsMap = this.supportedAIsResponseAsMap(supportedAIs);
    this.updateSensitiveAIsList(supportedAIsResponseAsMap);

    // uncheck the excluded AIs
    this.uncheckModels(excludeAIs, supportedAIsResponseAsMap);

    // if account is face gated, update face gated AIs list, and unchecked and disable them
    if (isAccountFaceGated) {
      this.updateFaceGateAIsList(supportedAIsResponseAsMap);
    }

    supportedAIsMap = { ...supportedAIsMap, ...supportedAIsResponseAsMap };
    return supportedAIsMap;
  }

  public compareSupportedAIs(firstAI: IUISupportedAis, secondAI: IUISupportedAis) {
    // Sort by disabled state first (putting disabled AIs first)
    if (firstAI.isRequired !== secondAI.isRequired) {
      return firstAI.isRequired ? -1 : 1;
    }

    // If disabled state is the same, sort by displayName
    return firstAI.displayName.localeCompare(secondAI.displayName);
  }

  public getRequiredAIsByPreset(preset: IndexingPreset): IUISupportedAis[] {
    const requiredAIs = presetToRequiredAIsMap[preset];

    return requiredAIs.map(requiredAI => ({
      name: requiredAI,
      displayName: requiredAI + this.DISPLAY_NAME_KEY,
      disabled: true,
      checked: true,
      isRequired: true,
      mandatoryParents: [],
      optionalParents: [],
      requireAtLeastOneOptionalParent: false,
      descendants: []
    }));
  }

  public supportedAIsResponseAsMap(supportedAIs: Microsoft.VideoIndexer.Contracts.SupportedAIs[]): SupportedAIsMap {
    const supportedAIsResponsesMap = {} as SupportedAIsMap;
    supportedAIs.forEach((supportedAI: Microsoft.VideoIndexer.Contracts.SupportedAIs) => {
      supportedAIsResponsesMap[supportedAI.name] = {
        name: supportedAI.name,
        mandatoryParents: supportedAI.mandatoryParents,
        optionalParents: supportedAI.optionalParents,
        descendants: supportedAI.descendants,
        requireAtLeastOneOptionalParent: supportedAI.requireAtLeastOneOptionalParent,
        checked: true,
        disabled: false,
        displayName: supportedAI.name + this.DISPLAY_NAME_KEY,
        info: this.getModelInfo(supportedAI.name)
      };
    });
    return supportedAIsResponsesMap;
  }

  public isSensitiveAI(modelName: ExcludableAIsModels): boolean {
    return this.sensitiveAIs.includes(modelName) && !this.faceGatedAIs.includes(modelName);
  }

  public updateFaceGateAIsList(supportedAIsMap: SupportedAIsMap) {
    this.faceGatedAIs = this.updateAIList(supportedAIsMap, this.ROOT_FACE_GATED_MODELS, true);
  }

  public updateSensitiveAIsList(supportedAIsMap: SupportedAIsMap) {
    this.sensitiveAIs = this.updateAIList(cloneDeep(supportedAIsMap), this.ROOT_SENSITIVE_MODELS);
  }

  public updateAIList(supportedAIsMap: SupportedAIsMap, models: ExcludableAIsModels[], shouldDisable: boolean = false): ExcludableAIsModels[] {
    const fullModels = new Set<ExcludableAIsModels>();

    models.forEach(model => {
      if (supportedAIsMap[model]) {
        this.uncheckModel(model, supportedAIsMap, fullModels);
        this.disableModel(model, supportedAIsMap);
        this.uncheckDependentModels(supportedAIsMap, model, fullModels, shouldDisable);
      }
    });

    return Array.from(fullModels);
  }

  public uncheckDependentModels(
    supportedAIsMap: SupportedAIsMap,
    modelName: ExcludableAIsModels,
    excludeAIsSet: Set<ExcludableAIsModels>,
    shouldDisable: boolean = false
  ) {
    // get all models that are immediately dependent on the current model
    const aiToUncheck = supportedAIsMap[modelName].descendants;

    // if there are no models to uncheck, return
    if (aiToUncheck.length === 0) {
      return;
    }

    // for each model that is immediately dependent on the current model check if it should be unchecked
    aiToUncheck.forEach(ai => {
      // if the model should be unchecked, uncheck it and add it to the set of models to uncheck.
      // continue to check if there are models that are dependent on the current ai
      if (this.shouldUncheckModel(ai, supportedAIsMap)) {
        this.uncheckModel(ai, supportedAIsMap, excludeAIsSet);
        if (shouldDisable) {
          this.disableModel(ai, supportedAIsMap);
        }
        this.uncheckDependentModels(supportedAIsMap, ai, excludeAIsSet);
      }
    });
  }

  public uncheckModel(aiToUncheck: ExcludableAIsModels, supportedAIsMap: SupportedAIsMap, excludeAIsSet: Set<ExcludableAIsModels>) {
    if (supportedAIsMap[aiToUncheck]) {
      supportedAIsMap[aiToUncheck].checked = false;
      excludeAIsSet?.add(aiToUncheck);
    }
  }

  public checkModel(aiToCheck: ExcludableAIsModels, supportedAIsMap: SupportedAIsMap, excludeAIsSet: Set<ExcludableAIsModels>) {
    if (supportedAIsMap[aiToCheck]) {
      supportedAIsMap[aiToCheck].checked = true;
      excludeAIsSet?.delete(aiToCheck);
    }
  }

  public checkModels(
    aisToCheck: ExcludableAIsModels[],
    supportedAIsMap: SupportedAIsMap,
    excludeAIsSet: Set<ExcludableAIsModels>,
    shouldEnable: boolean = false
  ) {
    aisToCheck.forEach(ai => {
      if (supportedAIsMap[ai].disabled && !shouldEnable) {
        return;
      }

      this.checkModel(ai, supportedAIsMap, excludeAIsSet);
      if (shouldEnable) {
        this.enableModel(ai, supportedAIsMap);
      }
    });
  }

  public uncheckModels(
    aisToUncheck: ExcludableAIsModels[],
    supportedAIsMap: SupportedAIsMap,
    excludeAIsSet: Set<ExcludableAIsModels> = null,
    shouldDisable: boolean = false
  ) {
    aisToUncheck.forEach(ai => {
      this.uncheckModel(ai, supportedAIsMap, excludeAIsSet);

      if (shouldDisable) {
        this.disableModel(ai, supportedAIsMap);
      }
    });
  }

  public enableModel(aiToEnable: ExcludableAIsModels, supportedAIsMap: SupportedAIsMap) {
    if (supportedAIsMap[aiToEnable]) {
      supportedAIsMap[aiToEnable].disabled = false;
    }
  }

  public disableModel(aiToDisable: ExcludableAIsModels, supportedAIsMap: SupportedAIsMap) {
    if (supportedAIsMap[aiToDisable]) {
      supportedAIsMap[aiToDisable].disabled = true;
    }
  }

  public markRequiredModelsAsChecked(
    supportedAIsMap: SupportedAIsMap,
    modelName: ExcludableAIsModels,
    excludeAIsSet: Set<ExcludableAIsModels>,
    shouldEnable: boolean = false
  ) {
    const aisToCheck = [
      ...supportedAIsMap[modelName].mandatoryParents,
      ...(supportedAIsMap[modelName].requireAtLeastOneOptionalParent ? supportedAIsMap[modelName].optionalParents : [])
    ];

    if (aisToCheck.length === 0) {
      return;
    }
    this.checkModels(aisToCheck, supportedAIsMap, excludeAIsSet, shouldEnable);
    aisToCheck.forEach(ai => this.markRequiredModelsAsChecked(supportedAIsMap, ai, excludeAIsSet, shouldEnable));
  }

  public disableSensitiveAIs(excludeAIsSet: Set<ExcludableAIsModels>, supportedAIsMap: SupportedAIsMap) {
    this.uncheckModels(this.sensitiveAIs, supportedAIsMap, excludeAIsSet, /* should disable */ true);

    // check if uncheck sensitive models with the existing excludeAIs list affect other models
    this.sensitiveAIs.forEach(ai => {
      this.uncheckDependentModels(supportedAIsMap, ai, excludeAIsSet);
    });
  }

  public enableSensitiveAIs(excludeAIsSet: Set<ExcludableAIsModels>, supportedAIsMap: SupportedAIsMap) {
    // enable all sensitive AIs that are not face gated. if the account is not face gated, the face gated AIs list will be empty
    const AIsToEnable = this.sensitiveAIs.filter(ai => !this.faceGatedAIs.includes(ai));
    this.checkModels(AIsToEnable, supportedAIsMap, excludeAIsSet, /* should enable */ true);
  }

  public getSupportedAIsState(supportedAIs: SupportedAIsMap) {
    return Object.keys(supportedAIs).map(ai => ({
      name: ai,
      checked: supportedAIs[ai].checked,
      disabled: supportedAIs[ai].disabled
    }));
  }

  private shouldUncheckModel(modelName: ExcludableAIsModels, supportedAIsMap: SupportedAIsMap): boolean {
    const mandatoryParents = supportedAIsMap[modelName].mandatoryParents;
    const optionalParents = supportedAIsMap[modelName].optionalParents;
    const requireAtLeastOneOptionalParent = supportedAIsMap[modelName].requireAtLeastOneOptionalParent;

    // The model should be unchecked if any of its mandatory parent models are unchecked
    for (const parent of mandatoryParents) {
      if (!supportedAIsMap[parent].checked) {
        return true;
      }
    }

    // if require at least one optional parent, the model should be unchecked if all of its optional parent models are unchecked
    if (requireAtLeastOneOptionalParent) {
      return !optionalParents.some(parent => supportedAIsMap[parent].checked);
    }

    return false;
  }

  private getModelInfo(modelName: ExcludableAIsModels) {
    return this.INFO_MODELS.includes(modelName) ? `${modelName}TooltipInfo` : '';
  }

  private resetAIsLists() {
    this.sensitiveAIs = [];
    this.faceGatedAIs = [];
  }
}
