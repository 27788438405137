import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { find } from 'lodash-es';

import { ISearchEvent } from '../../../../insights/interfaces';

@Injectable()
export class SearchbarService {
  public lastFilter: string;
  public filterSubject: BehaviorSubject<ISearchEvent>;
  private searchEvents: ISearchEvent[] = [];

  constructor() {
    this.filterSubject = new BehaviorSubject<ISearchEvent>(null);
  }

  public updateSearchEvent(srchEvent: ISearchEvent) {
    const searchEvent = find(this.searchEvents, srch => {
      return srch.id === srchEvent.id;
    });

    if (searchEvent) {
      // searchEvent exists , update its query
      searchEvent.query = srchEvent.query;
    } else {
      // searchEvent does not exist , add it to searchEvents array
      this.searchEvents.push(srchEvent);
    }
    this.filterSubject.next(srchEvent);
  }

  public filter(id: string): Observable<ISearchEvent> {
    // Find searchEvent that is relevant to given id
    const searchEvent = find(this.searchEvents, srch => {
      return srch.id.toString() === id;
    });
    if (searchEvent) {
      this.filterSubject.next(searchEvent);
    }
    return this.filterSubject;
  }
}
