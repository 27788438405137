import { HttpErrorResponse } from '@angular/common/http';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { immerOn } from 'ngrx-immer/store';

import { VideoReviewState } from '@common/modules/shared/interfaces';

import * as MediaActions from '../actions/media.actions';

import PlaylistContractV2 = Microsoft.VideoIndexer.Contracts.PlaylistContractV2;

export const mediaFeatureKey = 'media';

export interface IState extends EntityState<PlaylistContractV2> {
  selectedIndexId: string;
  saving: boolean;
  error: HttpErrorResponse;
  insightsFilterValue: string;
}

export function selectIndexId(a: PlaylistContractV2): string {
  return a.id;
}

export const adapter: EntityAdapter<PlaylistContractV2> = createEntityAdapter<PlaylistContractV2>({
  selectId: selectIndexId
});

export const initialState: IState = adapter.getInitialState({
  // additional entity state properties
  selectedIndexId: null,
  saving: false,
  error: null,
  insightsFilterValue: ''
});

const mediaReducer = createReducer(
  initialState,
  on(MediaActions.upsertMediaIndex, (state, { mediaIndex }) => {
    return adapter.upsertOne(mediaIndex, state);
  }),
  on(MediaActions.updateIndex, (state, { mediaIndex }) => {
    return adapter.updateOne(mediaIndex, { ...state, saving: true });
  }),
  on(MediaActions.updateIndexSaving, (state, { saving }) => {
    return {
      ...state,
      saving: saving
    };
  }),
  on(MediaActions.selectMediaIndex, (state, { id }) => {
    return {
      ...state,
      selectedIndexId: id,
      insightsFilterValue: ''
    };
  }),
  on(MediaActions.loadMediaFailure, (state, { error }) => {
    return {
      ...state,
      error: error
    };
  }),
  on(MediaActions.loadVideoIndex, state => {
    return {
      ...state,
      error: null
    };
  }),
  on(MediaActions.loadProjectIndex, state => {
    return {
      ...state,
      error: null
    };
  }),
  immerOn(MediaActions.requestReview, (state: IState, { videoId }) => {
    state.entities[videoId].videos[0].reviewState = VideoReviewState.IN_PROGRESS;
    return state;
  }),
  immerOn(MediaActions.updateMediaReviewState, (state: IState, { videoId, reviewState }) => {
    state.entities[videoId].videos[0].reviewState = reviewState;
    return state;
  }),
  on(MediaActions.removeMediaIndex, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  immerOn(MediaActions.updateThumbnailId, (state: IState, { id, thumbnailId }) => {
    state.entities[id].videos[0].thumbnailId = thumbnailId;
    return state;
  }),
  immerOn(MediaActions.updateInsights, (state: IState, { id, data }) => {
    state.entities[id].videos[0].insights[data.type as string] = data.insights;
    return state;
  }),
  immerOn(MediaActions.updateFace, (state: IState, { id, face }) => {
    const index = state.entities[id].videos[0].insights.faces.findIndex(faceElement => faceElement.id === face.id);
    state.entities[id].videos[0].insights.faces[index] = face;
    return state;
  }),
  immerOn(MediaActions.updateTranscriptLine, (state: IState, { id, line }) => {
    const index = state.entities[id].videos[0].insights.transcript.findIndex(transcriptElement => transcriptElement.id === line.id);
    state.entities[id].videos[0].insights.transcript[index] = { ...line };
    return state;
  }),
  immerOn(MediaActions.updateOcrLine, (state: IState, { id, line }) => {
    const index = state.entities[id].videos[0].insights.ocr.findIndex(ocrElement => ocrElement.id === line.id);
    state.entities[id].videos[0].insights.ocr[index] = { ...line };
    return state;
  }),
  immerOn(MediaActions.updateAllTranscriptLines, (state: IState, { id, transcript }) => {
    state.entities[id].videos[0].insights.transcript = transcript;
    return state;
  }),
  on(MediaActions.setInsightsFilterValue, (state, { value }) => {
    return {
      ...state,
      insightsFilterValue: value
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return mediaReducer(state, action);
}

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectIndexIds = selectIds;

// select the dictionary of user entities
export const selectIndexEntities = selectEntities;

// select the array of users
export const selectAllIndexes = selectAll;

// select the total user count
export const selectIndexTotal = selectTotal;
