export enum JobType {
  UnknownPersonsGrouping = 'UnknownPersonsGrouping'
}

export enum ActionId {
  MANAGE = 'MANAGE',
  DELETE_PERSON = 'DELETE_PERSON',
  REGROUP = 'REGROUP',
  DELETE_PEOPLE = 'DELETE_PEOPLE'
}
