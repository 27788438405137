import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { IUserSettings } from '@common/modules/core/services/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { DataService } from '@common/modules/shared/services/data.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';
import { ThemesService } from '@common/modules/core/services/themes/themes.service';
import { ConfigService } from '@common/modules/core/services/config/config.service';
import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { UserType } from '@common/modules/auth/interfaces';

import { CoreStoreService } from '../../../core/services/core-store.service';
import { DEFAULT_LOCALE_LANGUAGE, DEFAULT_LOCALE_LANGUAGE_KEY } from '../../../core/supportedLocaleLanguages';
import { languageFilterAction, themeOptions } from './actions';
import { resources } from './resources';
import { IVIThemeOption } from '../../interfaces';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent extends FocusableComponent implements OnInit {
  public settings: IUserSettings = {
    getNewsUpdates: false,
    autoCompleteSearch: false,
    showSamplesTab: false,
    showGalleryByDefault: false,
    locale: 'en-US',
    userType: null
  };

  public resources = resources;
  public ready = false;
  public languagesActions: IAction[];
  public themes: IVIThemeOption[];
  public selectedTheme: IVIThemeOption;
  public selectedLanguage: IAction;
  public languageFilterAction = languageFilterAction;
  public darkMode = false;
  public isFairFaxEnv = false;
  public autoPlayVideosSettings: boolean = true;
  public showArcExtensions: boolean = false;
  public languageSelectId = 'LanguageSelect';

  private isEdgeEnabled = false;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private coreStoreService: CoreStoreService,
    private localStorageService: LocalStorageService,
    private trackService: TrackService,
    private themesService: ThemesService,
    private config: ConfigService
  ) {
    super();
  }

  public ngOnInit() {
    this.trackService.track('user_settings.init');
    this.isFairFaxEnv = this.config.isFairFaxEnv();
    this.themes = cloneDeep(themeOptions);
    const autoPlayVideosStorage = this.localStorageService.getItem('portal.AutoPlayVideos');
    this.autoPlayVideosSettings = !autoPlayVideosStorage || autoPlayVideosStorage == 'true';

    // TODO - hide edge extensions toggle until we have a better solution
    // this.isEdgeEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.Edge);
    // if (this.isEdgeEnabled) {
    //   this.showArcExtensions = this.localStorageService.getItem(StorageCacheKey.ShowEdgeExtensions) === 'true';
    // }

    // Set language base to user saved selection.
    this.coreStoreService.userSettings$.pipe().subscribe(settings => {
      this.settings = settings;
      const activeTheme = this.themes?.find(theme => theme.themeName === this.themesService.activeTheme);
      this.selectTheme(activeTheme);
      this.setLanguage();
      this.setTranslation();
    });
  }

  public languageChanged(langAction: IAction) {
    this.settings.locale = langAction.value;
    this.settingsChange();
  }

  public onAutoPlaySettingsChange() {
    this.localStorageService.setItem('portal.AutoPlayVideos', this.autoPlayVideosSettings.toString());
  }

  // TODO - hide edge extensions toggle until we have a better solution
  // public onShowArcSettingsChange() {
  //   this.localStorageService.setItem(StorageCacheKey.ShowEdgeExtensions, this.showArcExtensions.toString());
  //   this.showArcExtensions ? this.edgeExtensionsStore.loadEdgeExtensions() : this.edgeExtensionsStore.clearEdgeExtensions();
  // }

  public settingsChange() {
    this.coreStoreService.saveUserSettings(this.settings);

    this.trackService.track('user_settings.change_settings.click', {
      category: EventCategory.SHELL,
      data: {
        settings: { ...this.settings }
      }
    });
  }

  public onClickTheme(theme: IVIThemeOption) {
    this.selectTheme(theme);
  }

  public showEdgeExtensionsToggle(): boolean {
    return this.isEdgeEnabled && this.settings.userType === UserType.MicrosoftCorpAad;
  }

  private selectTheme(theme: IVIThemeOption) {
    if (!theme || theme === this.selectedTheme) {
      return;
    }
    if (this.selectedTheme) {
      this.selectedTheme.selected = false;
    }
    theme.selected = true;
    this.selectedTheme = theme;
    this.themesService.activeTheme = theme.themeName;
    this.cdr.markForCheck();
  }

  private setLanguage(): void {
    // GET all languages as actions
    this.languagesActions = this.dataService.getLanguages(false, 'supportedUserLocale');
    // Translate the names of languages
    this.translate.translateLanguages(this.languagesActions, 'Language');
    // GET selected language by language key
    this.selectedLanguage = this.getSelectedLanguage();
  }

  private setTranslation(): void {
    this.translate.translateResources(this.resources).subscribe(() => {
      this.languageFilterAction.titleAttribute = this.resources.SettingsLanguageSettingsTitle;
      this.ready = true;
      this.cdr.detectChanges();
    });
  }

  private getSelectedLanguage(): IAction {
    let locale = this.localStorageService.getItem('vi.locale')?.toLowerCase() || this.settings.locale.toLowerCase() || DEFAULT_LOCALE_LANGUAGE_KEY;

    // Default value for the english is en, should be en-US. (The key value)
    locale = locale === DEFAULT_LOCALE_LANGUAGE ? DEFAULT_LOCALE_LANGUAGE_KEY : locale;
    return this.languagesActions.find(l => {
      return l.value.toLowerCase() === locale.toLowerCase();
    });
  }
}
