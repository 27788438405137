<button tabindex="0"
  [attr.id]="buttonId"
  role="button"
  (click)="handleClick($event)"
  (keydown.enter)="handleClick($event)"
  [attr.aria-pressed]="isVisible"
  [attr.aria-disabled]="isDisabled"
  [attr.disabled]="isDisabled ? '' : null"
  [attr.aria-label]="isVisible ? expandedTxt : collapsedTxt"
  [title]="isVisible ? expandedTxt : collapsedTxt">
  <i aria-hidden="true"
    *ngIf="showIcon"
    [ngClass]="{'i-vi-filter': !isVisible, 'i-vi-filter-off': isVisible}">
  </i>
  <span aria-hidden="true">{{ isVisible ? expandedTxt : collapsedTxt}}</span>
  <i aria-hidden="true"
    Class="i-vi-forward"></i>
</button>
