import { HttpErrorResponse } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { IApiSasContract } from '@common/modules/api/interfaces';
import { APIErrors } from '@common/modules/core/services/toast/errors';

import { UploadMode, IIndexingSettings, UploadingStateError, SupportedAIsMap } from './../../interfaces';
import { UploadPages } from '../../components/upload/interfaces';
import { IFile } from '../../interfaces';
import { IFileBlob } from '../../interfaces';

export const openUploadDialog = createAction('[Upload] Open Upload Dialog');

// Get Video Info
export const getVideoInfoFailed = createAction('[Upload] Get Video Info Failed', props<{ errorType: APIErrors }>());
export const getVideoInfoSuccess = createAction('[Upload] Get Video Info Success', props<{ file: IFile; contentMD5?: string }>());
// Get Sas
export const getFileSas = createAction('[Upload] Get File Sas', props<{ storeFile: IFile }>());
export const getFileSasFailed = createAction(
  '[Upload] Get File Sas Failed',
  props<{ storeFile: IFile; uploadingStateError: UploadingStateError; error?: HttpErrorResponse }>()
);
export const getFileSasSuccess = createAction(
  '[Upload] Get File Sas Success',
  props<{ blobFile: IFileBlob; sas: IApiSasContract; storeFile: IFile }>()
);
// Upload to azure blob storage
export const uploadFileToBlobStorage = createAction('[Upload] Upload To Blob Storage Files');
export const uploadFileToBlobStorageFailed = createAction(
  '[Upload] Upload Files To Blob Storage Failed',
  props<{ storeFile: IFile; uploadingStateError: UploadingStateError; error?: HttpErrorResponse }>()
);
export const uploadFileToBlobStorageSuccess = createAction(
  '[Upload] Upload Files To Blob Storage Success',
  props<{
    storeFile: IFile;
    fileUrl: string;
  }>()
);
// Upload Files
export const uploadFiles = createAction('[Upload] Upload Files');
export const uploadFilesFinished = createAction('[Upload] Upload Files Finished');
// Upload file to server
export const uploadFileToServer = createAction(
  '[Upload] Upload Files To Server',
  props<{
    storeFile: IFile;
    fileUrl: string;
    contentMD5?: string;
  }>()
);
export const initiateUploadFiles = createAction('[Upload] Initiate Upload Files');
export const uploadFileToServerFailed = createAction(
  '[Upload] Upload File To Server Failed',
  props<{ storeFile: IFile; uploadingStateError: UploadingStateError; error?: HttpErrorResponse }>()
);
export const uploadFileFinished = createAction('[Upload] Upload File To Server Finished', props<{ file?: IFile }>());
// Upload state changes
export const initUploadDialogState = createAction('[Upload] Init Upload Dialog State', props<{ indexingSettings: Partial<IIndexingSettings> }>());
export const updateUploadPage = createAction('[Upload] Update Upload Page', props<{ page: UploadPages }>());
export const addUploadFiles = createAction('[Upload] Add Upload Files', props<{ files: IFile[] }>());
export const addUploadFilesSuccess = createAction('[Upload] Add Upload Files Success', props<{ files: IFile[] }>());
export const addUrlFileForUpload = createAction('[Upload] Add URL File For Upload', props<{ file: IFile }>());
export const urlValidationFailed = createAction('[Upload] URL Validation Failed', props<{ errorType: APIErrors }>());
export const removeFile = createAction('[Upload] Remove File', props<{ fileId: string }>());
export const updateFile = createAction('[Upload] Update File', props<{ fileId: string; file: Partial<IFile> }>());
export const uploadingFilesInProgress = createAction('[Upload] Uploading Files In Progress');
export const updateUploadMode = createAction('[Upload] Update Upload Mode', props<{ mode: UploadMode }>());
export const updateUploadIndexingSettings = createAction('[Upload] Update Indexing Settings', props<{ settings: Partial<IIndexingSettings> }>());
export const updateUserConsent = createAction('[Indexing View] Update user consent', props<{ consent: boolean }>());
export const updateUserConsentSuccess = createAction('[Indexing View] Update user consent updateUserConsentSuccess', props<{ consent: boolean }>());
export const updateUrlValidationLoading = createAction('[Upload] Update URL Validation Loading', props<{ loading: boolean }>());
export const updateUrlValidationError = createAction('[Upload] Update URL Validation Error', props<{ errorType: APIErrors }>());
export const uploadDialogClosed = createAction('[Upload] Upload Dialog Closed');
export const showIndexingStartedForSingleToast = createAction('[Upload] Show Indexing Started For Single Toast', props<{ file: IFile }>());
export const showIndexingStartedForMultiToast = createAction('[Upload] Show Indexing Started For Multi Toast', props<{ numberFiles: number }>());
export const showUploadFailedToast = createAction('[Upload] Show Upload Failed Toast', props<{ file: IFile; errorType: APIErrors }>());
export const updateAccountQuotaOnUploadFailure = createAction('[Upload] Update Account Quota On Upload Failure', props<{ file: IFile }>());
export const updateUseSavedIndexingSettings = createAction('[Upload] Update Use Saved Indexing Settings', props<{ use: boolean }>());
export const updateUploadSupportedAIs = createAction('[Upload] Update Supported AIs', props<{ supportedAIs: SupportedAIsMap }>());
export const updateUploadSupportedAIsState = createAction(
  '[Upload] Update Supported AI Status',
  props<{ supportedAIs: { name: string; checked: boolean; disabled: boolean }[] }>()
);
