import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { AlertMessageType } from './interfaces';

@Component({
  selector: 'app-vi-alert-message',
  templateUrl: './alert-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() public type: AlertMessageType;
  @Input() public content: string;
  @Input() public contentTitle: string;
  @Input() public linkText?: string;
  @Input() public linkPath?: string;
  @Input() public isContentHtml = false;
  // isButton override linkPath. Only one of these inputs can be used.
  @Input() public isButton = false;
  @Output() public buttonClicked = new EventEmitter<void>();

  constructor() {}

  public ngOnInit() {}

  public onClick() {
    this.buttonClicked.emit();
  }
}
