import { props, createAction } from '@ngrx/store';

import { SupportedAIModels } from '@common/modules/api/interfaces';

import { IIndexingSettings, SupportedAIsMap } from '../../interfaces';

export const updateIndexingSettings = createAction(
  '[Indexing Form] Update Indexing Settings',
  ({ settings, isDirty = true }: { settings: Partial<IIndexingSettings>; isDirty?: boolean }) => ({
    settings,
    isDirty
  })
);

export const updateSupportedAIs = createAction('[Indexing Form] Update Supported AIs', props<{ supportedAIs: SupportedAIsMap }>());

export const updateSupportedAIsState = createAction(
  '[Indexing Form] Update Supported AI State',
  props<{ supportedAIs: { name: SupportedAIModels; checked: boolean; disabled: boolean }[] }>()
);

export const loadSupportedAIs = createAction('[Indexing Form/ API] Load Supported AIs');

export const loadSupportedAIsFailed = createAction('[Indexing Form/ API] Load Supported AIs Failed');
