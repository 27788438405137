const dataBrowser = [
  {
    string: navigator.userAgent,
    subString: 'Mobile',
    identity: 'mobile'
  },
  {
    string: navigator.userAgent,
    subString: 'Chrome',
    identity: 'Chrome'
  },
  {
    string: navigator.userAgent,
    subString: 'OmniWeb',
    versionSearch: 'OmniWeb/',
    identity: 'OmniWeb'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.vendor,
    subString: 'Apple',
    identity: 'Safari',
    versionSearch: 'Version'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.vendor,
    subString: 'iCab',
    identity: 'iCab'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.vendor,
    subString: 'KDE',
    identity: 'Konqueror'
  },
  {
    string: navigator.userAgent,
    subString: 'Firefox',
    identity: 'Firefox'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.vendor,
    subString: 'Camino',
    identity: 'Camino'
  },
  {
    // for newer Netscapes (6+)
    string: navigator.userAgent,
    subString: 'Netscape',
    identity: 'Netscape'
  },
  {
    string: navigator.userAgent,
    subString: 'MSIE',
    identity: 'Explorer',
    versionSearch: 'MSIE'
  },
  {
    string: navigator.userAgent,
    subString: 'Gecko',
    identity: 'Mozilla',
    versionSearch: 'rv'
  },
  {
    // for older Netscapes (4-)
    string: navigator.userAgent,
    subString: 'Mozilla',
    identity: 'Netscape',
    versionSearch: 'Mozilla'
  }
];
const dataOS = [
  {
    string: navigator.userAgent,
    subString: 'Android',
    identity: 'Android'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.platform,
    subString: 'Win',
    identity: 'Windows'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.platform,
    subString: 'Mac',
    identity: 'Mac'
  },
  {
    string: navigator.userAgent,
    subString: 'iPhone',
    identity: 'iPhone/iPod'
  },
  {
    // eslint-disable-next-line deprecation/deprecation
    string: navigator.platform,
    subString: 'Linux',
    identity: 'Linux'
  }
];

let browser;
let version;
let OS;
let isApproved;
let versionSearchString;
export function browserDetect() {
  browser = searchString(dataBrowser) || 'An unknown browser';
  // eslint-disable-next-line deprecation/deprecation
  version = searchVersion(navigator.userAgent) || searchVersion(navigator.appVersion) || 'an unknown version';
  OS = searchString(dataOS) || 'an unknown OS';
  isApproved = searchList(browser, version);

  if (OS === 'iPad') {
    document.documentElement.className += ' iOS';
  }
  if (browser === 'Explorer') {
    document.documentElement.className += ' ie';
  }
  if (browser === 'Explorer' && version === 10) {
    document.documentElement.className += ' ie10';
  }
  // Check browser === 'Edge'
  const isEdgeBrowser = /Edg(e)?/i.test(navigator.userAgent);
  if (isEdgeBrowser) {
    const edgeVersion = navigator.userAgent.match(/Edg(e)?\/([0-9.]*)/i);
    browser = 'Edge';
    version = edgeVersion && edgeVersion[1] ? edgeVersion[1] : version;
  }

  return { browser: browser, version: version, isApproved: isApproved, os: OS };
}

function searchList(brName: string, brVersion) {
  if (brName === 'Firefox') {
    return brVersion >= 3.5;
  } else if (brName === 'Explorer') {
    return brVersion >= 7;
  } else {
    return false;
  }
}

function searchString(data) {
  for (const dataItem of data) {
    const dataString = dataItem.string;
    const dataProp = dataItem.prop;
    if (dataString === 'iPad') {
      return 'iPad';
    }
    versionSearchString = dataItem.versionSearch || dataItem.identity;
    if (dataString) {
      if (dataString.indexOf(dataItem.subString) !== -1) {
        return dataItem.identity;
      }
    } else if (dataProp) {
      return dataItem.identity;
    }
  }
}

function searchVersion(dataString: string) {
  const index = dataString.indexOf(versionSearchString);
  if (index === -1) {
    return;
  }
  return parseFloat(dataString.substring(index + versionSearchString.length + 1));
}

// Define list of unsupported browsers (do not support css grid)
export const notSupportedBrowsers = {
  Chrome: 57.0,
  Explorer: 16.0,
  Firefox: 52.0,
  Safari: 10.1,
  Edge: 16
};
