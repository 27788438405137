export const resources = {
  Dismiss: '',
  Accept: '',
  FaceGatePopupDescription: '',
  AccountName: '',
  TermsOfUse: '',
  TypeYourEmailAddress: '',
  FaceGatePopupTermsOfUse: '',
  FaceGatePopupAgreement: '',
  TypeYourEmailAddressFoAddedSecurity: '',
  FaceGatePopupTitle: '',
  FaceGatePopupAgreementTitle: '',
  FaceGatePopupEmailDoesNotMatch: '',
  SelectAUseCase: '',
  FaceGatePopupSensitiveUseCase: '',
  IdentityVerification: '',
  TouchlessAccessControl: '',
  Personalization: '',
  DetectDuplicateOrBlockedUsers: '',
  FaceInMediaOrEntertainmentVideo: '',
  CelebrityRecognition: '',
  DemoUse: ''
};
