import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';

import { EMPTY, switchMap, withLatestFrom } from 'rxjs';

import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

import { VIRoutingMap } from '../../../app/routing/routes';
import { IState } from '../../../core/reducers';
import { ofRoute } from '../../../core/services/operations';
import * as RouterActions from '../../../core/actions/router.actions';
import * as fromCore from '../../../core/selectors';
import * as PeoplePageActions from '../../actions/people/people-page.actions';
import * as fromRouter from '../../../core/reducers/router';
import * as UnknownPersonActions from '../../actions/people/unknown-person.actions';
import { PeopleTab } from '../../../customization/components/customization-people-container/interfaces';

@Injectable()
export class PeopleViewEffects {
  public selectPeopleTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PeoplePageActions.selectPeopleTab),
      withLatestFrom(this.store.select(fromCore.selectCurrentAccountId)),
      switchMap(([{ tab }, accountId]) => {
        if (!accountId) {
          return EMPTY;
        }
        const path = `${VIRoutingMap.customization.path.replace(':accountId', accountId)}/${VIRoutingMap.customizationPeople.path}${
          tab !== PeopleTab.PEOPLE ? '/' + tab.toLowerCase() : ''
        }`;

        return [
          RouterActions.Go({
            path: [path]
          }),
          UnknownPersonActions.clearUnknownPersons()
        ];
      })
    )
  );

  public initSelectedPeopleTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      ofRoute([
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}`,
        `/${VIRoutingMap.customization.path}/${VIRoutingMap.customizationPeople.path}/${VIRoutingMap.customizationPeopleUnknown.path}`
      ]),
      withLatestFrom(this.store.select(fromRouter.getRouterState)),
      switchMap(([, routerState]) => {
        const peoplePath = routerState.state.filteredUrl.substring(routerState.state.filteredUrl.lastIndexOf('/') + 1).toLowerCase();
        switch (peoplePath) {
          case VIRoutingMap.customizationPeople.path:
            return [PeoplePageActions.initSelectedPeopleTab({ tab: PeopleTab.PEOPLE })];
            break;
          case VIRoutingMap.customizationPeopleUnknown.path:
            return [PeoplePageActions.initSelectedPeopleTab({ tab: PeopleTab.UNKNOWN_PEOPLE })];
        }
      })
    )
  );

  constructor(private actions$: Actions, private store: Store<IState>, private featureSwitchService: FeatureSwitchService) {}
}
