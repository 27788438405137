import { Directive, Input, HostListener, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appViImageIconFallback]'
})
export class ImageIconFallbackDirective implements OnInit {
  @Input('appViImageIconFallback') public fallbackIconClass: string;

  @Input('appViImageIconFallbackRadius') public fallbackIconRadius = '50%';

  private originImgDisplay = '';
  private fallbackElement;
  private wrapperClassName;

  constructor(private renderer: Renderer2) {}

  public ngOnInit() {
    // Save class name for icon wrapper
    this.wrapperClassName = this.fallbackIconClass + '-wrapper';
  }

  @HostListener('load', ['$event.target']) public onLoad(target) {
    // Show the img if fallback icon had shown on error before
    const parentElement = target.parentElement;
    if (parentElement.getElementsByClassName(this.wrapperClassName)[0]) {
      // Show img
      this.renderer.setStyle(target, 'display', this.originImgDisplay);

      // Remove icon
      this.renderer.removeChild(parentElement, this.fallbackElement);
    }
  }

  @HostListener('error', ['$event.target']) public onError(target) {
    const parentElement = target.parentElement;

    // Insert fallback element only if it isn't exist.
    if (parentElement.getElementsByClassName(this.wrapperClassName)[0]) {
      return;
    }

    this.createFallbackElement();

    // Insert the icon wrapper where the img
    this.renderer.insertBefore(parentElement, this.fallbackElement, target);

    // Save origin img display value
    this.originImgDisplay = getComputedStyle(target).display;
    // Hide img
    this.renderer.setStyle(target, 'display', 'none');
  }

  private createFallbackElement() {
    // create the element only once
    if (this.fallbackElement) {
      return;
    }
    // create icon
    const icon = this.renderer.createElement('i');
    this.renderer.setAttribute(icon, 'aria-hidden', 'true');
    this.renderer.addClass(icon, this.fallbackIconClass);

    // create icon wrapper as circle
    this.fallbackElement = this.renderer.createElement('div');
    this.renderer.addClass(this.fallbackElement, this.wrapperClassName);
    this.renderer.setStyle(this.fallbackElement, 'border', '1px solid white');
    this.renderer.setStyle(this.fallbackElement, 'border-radius', this.fallbackIconRadius);
    this.renderer.setStyle(this.fallbackElement, 'width', '100%');
    this.renderer.setStyle(this.fallbackElement, 'height', '100%');
    this.renderer.appendChild(this.fallbackElement, icon);

    // locate the icon in the center of the wrapper
    this.renderer.setStyle(this.fallbackElement, 'display', 'flex');
    this.renderer.setStyle(icon, 'margin', 'auto');
  }
}
