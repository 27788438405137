import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../../reducers';
import { UnknownPersonTableListError } from '../../reducers/people/unknown-persons.reducer';

export const getState = createSelector(selectCoreState, state => state.unknownPersons);
export const getJob = createSelector(getState, state => state.job);
export const getEntities = createSelector(getState, state => state.entities);
export const list = createSelector(getEntities, entities => Object.values(entities));
export const getSelected = createSelector(getState, state => state.selectedUnknownPerson);
export const getById = id => createSelector(getState, state => state.entities[id]);
export const getPaginationState = createSelector(getState, state => ({ page: state.page, done: state.isLastPage, pageSize: state.pageSize }));
export const getIsUpdating = createSelector(getState, state => state.updating);
export const getIsLoaded = createSelector(getState, state => state.loaded);
export const getIsJobLoaded = createSelector(getState, state => state.jobDataLoaded);
export const getIsTableLoaded = createSelector(getState, state => state.tableLoaded);
export const getIsSearching = createSelector(getState, state => state.searching);
export const isLoadMoreError = createSelector(getState, state => state.tableListError === UnknownPersonTableListError.LOAD_MORE_ERROR);
export const isSearchError = createSelector(getState, state => state.tableListError === UnknownPersonTableListError.SEARCH_ERROR);
export const isGetListError = createSelector(getState, state => state.tableListError === UnknownPersonTableListError.GET_LIST_ERROR);
