import { toFixed } from '../../utils';
import { BaseTiming } from '../base';
import { INavigationTimingOptions, INavigationTimingResult } from './interfaces';

export { INavigationTimingOptions, INavigationTimingResult };
export class NavigationTiming extends BaseTiming {
  constructor(private options?: INavigationTimingOptions) {
    super();
  }

  public getTimings(): Partial<INavigationTimingResult> {
    const entry = this.performance.getEntriesByType('navigation');
    if (!entry) {
      return {};
    }
    const timing = entry[0] as PerformanceNavigationTiming;

    if (!timing) {
      return {};
    }

    return {
      // Time spent unloading documents
      unloadEventTime: toFixed(timing.unloadEventEnd - timing.unloadEventStart),
      // Time consumed preparing the new page
      readyStart: toFixed(timing.fetchStart - timing.startTime),
      // Time Spent on application cache
      appcacheTime: toFixed(timing.domainLookupStart - timing.fetchStart),
      // TCP connection time
      connectTime: toFixed(timing.connectEnd - timing.connectStart),
      // DNS query time
      lookupDomainTime: toFixed(timing.domainLookupEnd - timing.domainLookupStart),
      // Time spent during redirection
      redirectTime: toFixed(timing.redirectEnd - timing.redirectStart),
      // Time spent during the request
      requestTime: toFixed(timing.responseEnd - timing.requestStart),
      // Time spent constructing the DOM tree
      domReadyTime: toFixed(timing.domComplete - timing.domInteractive),
      // Request to completion of the DOM loading
      initDomTreeTime: toFixed(timing.domInteractive - timing.responseEnd),
      // Load event time
      loadEventTime: toFixed(timing.loadEventEnd - timing.loadEventStart),
      // Total time from start to load
      loadTime: toFixed(timing.loadEventEnd - timing.fetchStart)
    };
  }
}
