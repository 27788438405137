import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { switchMap } from 'rxjs/operators';

import { SupportedLanguages } from '../../core/languages';
import * as LanguageActions from '../actions/language-model.actions';
import * as SupportedLanguagesActions from '../actions/supported-languages.actions';

@Injectable()
export class SupportedLanguagesEffects {
  public loadSupportedLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguageModels),
      switchMap(() => {
        const supportedLanguages = SupportedLanguages.filter(supportedLanguage => supportedLanguage.supportedCustomization).map(
          supportedLanguage => ({
            ...supportedLanguage,
            isExpanded: true,
            isCreating: false,
            editMode: false
          })
        );
        return [SupportedLanguagesActions.upsertSupportedLanguages({ supportedLanguages: supportedLanguages })];
      })
    )
  );

  constructor(private actions$: Actions) {}
}
