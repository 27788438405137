import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/edge-extensions.actions';
import * as AccountActions from '../actions/accounts.actions';

import IConnectedClusterExtension = Microsoft.VideoIndexer.Contracts.IConnectedClusterExtension;

export interface IState extends EntityState<IConnectedClusterExtension> {
  loaded: boolean;
  error: boolean;
  selectedExtensionId: string;
  isEdgeExtensionsNeededForInit: boolean;
  handshakeDone: boolean;
  handshakeFailed: boolean;
  handshakeInProgress: boolean;
  isLiveModuleLoaded: boolean;
}

function selectEdgeExtensionId(a: IConnectedClusterExtension): string {
  return a.id;
}

export const adapter: EntityAdapter<IConnectedClusterExtension> = createEntityAdapter<IConnectedClusterExtension>({
  selectId: selectEdgeExtensionId
});

const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false,
  selectedExtensionId: '',
  isEdgeExtensionsNeededForInit: true,
  handshakeDone: false,
  handshakeFailed: false,
  handshakeInProgress: false,
  isLiveModuleLoaded: false
});

const edgeExtensionsReducer = createReducer(
  initialState,
  on(AccountActions.loadEdgeExtensionsSuccess, (state, { extensions }) => {
    const newState = {
      ...state,
      loaded: true
    };
    return adapter.setAll(extensions, newState);
  }),
  on(actions.clearEdgeExtensions, state => {
    const newState = {
      ...state,
      ...initialState,
      isEdgeExtensionsNeededForInit: false
    };
    return adapter.removeAll(newState);
  }),
  on(AccountActions.loadEdgeExtensionsFailed, state => {
    return {
      ...state,
      loaded: true,
      error: true
    };
  }),
  on(actions.selectEdgeExtension, (state, { id }) => {
    return {
      ...state,
      selectedExtensionId: id,
      handshakeDone: false
    };
  }),
  on(actions.clearSelectedEdgeExtension, state => {
    return {
      ...state,
      selectedExtensionId: ''
    };
  }),
  on(actions.loadEdgeExtensionsNotNeededForInit, state => {
    return {
      ...state,
      isEdgeExtensionsNeededForInit: false
    };
  }),
  on(actions.handshakeSuccess, state => {
    return {
      ...state,
      handshakeDone: true,
      handshakeInProgress: false
    };
  }),
  on(actions.handshakeFailed, state => {
    return {
      ...state,
      handshakeDone: true,
      handshakeFailed: true,
      handshakeInProgress: false
    };
  }),
  on(actions.handshakeEdgeExtension, state => {
    return {
      ...state,
      handshakeDone: false,
      handshakeFailed: false,
      handshakeInProgress: true
    };
  }),
  on(actions.liveModuleLoaded, state => {
    return {
      ...state,
      isLiveModuleLoaded: true
    };
  })
);

export function reducer(state: IState, action: Action) {
  return edgeExtensionsReducer(state, action);
}
