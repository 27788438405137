import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { appRoutes } from './app.routes';
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false, // <-- debugging purposes only
      paramsInheritanceStrategy: 'always', // <-- To get data aggregated from parent routes (for NGRX router)
      // https://angular.io/api/router/ExtraOptions#paramsInheritanceStrategy
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
