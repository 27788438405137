import { IHttpWrapper, IAzureLocation, IAmILoggedInResult } from '../interfaces';
import { getRequestSettings } from '../utils/request.function';
import { amILoggedInSuffix } from '../utils/api-utils';

export class UserApi {
  private apiBaseRoute = '/users';

  constructor(private ApiWrapper: IHttpWrapper) {}

  // [HttpGet]
  // [Route("{apiAuthBase}{apiAuthRoute}/Users/me/AccessToken")]
  public getUserAccessToken(allowEdit?: boolean, reqSettings?: object) {
    const url = `${this.apiAuthBase()}/me/accessToken/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;
    if (allowEdit) {
      requestSettings['params'].allowEdit = allowEdit;
    }

    return this.ApiWrapper.HttpClient.get<string>(url, requestSettings);
  }

  // [HttpGet]
  // [Route("{apiInternalBase}/Users/me")]
  public getUser(reqSettings?: object) {
    const url = `${this.apiAuthBase()}/me/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, { ...reqSettings, withCredentials: true }) as object;

    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.UserContract>(url, requestSettings);
  }

  // [HttpPut]
  // [Route("{apiInternalBase}/Users/me")]
  public saveUser(userSettings: Microsoft.VideoIndexer.Contracts.UserContract, reqSettings?: object) {
    const url = `${this.apiInternalBaseDefaultLocation}/me/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.put(url, userSettings, requestSettings);
  }

  // [HttpDelete]
  // [Route("{apiInternalBase}/Users/{userId}")]
  public deleteUser(userId: string, reqSettings?: object) {
    const url = `${this.apiInternalBase}/${userId}/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings);

    return this.ApiWrapper.HttpClient.delete(url, requestSettings);
  }

  // [HttpGet]
  // [Route("{apiInternalBase}/Locations")]
  public getVILocations(allowEdit?: boolean, reqSettings?: object) {
    const url = `${this.ApiWrapper.GetInternalApiBase()}/locations/`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;
    if (allowEdit) {
      requestSettings['params'].allowEdit = allowEdit;
    }
    return this.ApiWrapper.HttpClient.get<IAzureLocation[]>(url, requestSettings);
  }

  // GET [Route("/auth/amILoggedIn")]
  public amILoggedIn() {
    const url = `${window.location.origin}${amILoggedInSuffix}`;
    return this.ApiWrapper.HttpClient.get<IAmILoggedInResult>(url);
  }

  private get apiInternalBase(): string {
    return this.ApiWrapper.GetInternalApiBase(null, null, true) + this.apiBaseRoute;
  }

  private get apiInternalBaseDefaultLocation(): string {
    return this.ApiWrapper.GetInternalApiBase(null, true, true) + this.apiBaseRoute;
  }

  private apiAuthBase(isAuthPath: boolean = false): string {
    return this.ApiWrapper.GetAuthApiBase(isAuthPath) + this.apiBaseRoute;
  }
}
