import { createSelector } from '@ngrx/store';

import { map } from 'lodash-es';

import { selectCoreState } from '../reducers';

export const getPersonModelsState = createSelector(selectCoreState, state => state.personModels);

export const getPeopleModels = createSelector(getPersonModelsState, state => map(state.entities, entity => ({ ...entity })));

export const getPeopleError = createSelector(getPersonModelsState, state => state.error);

export const isPeopleModelsLoaded = createSelector(getPersonModelsState, state => state.loaded);

export const isPeopleModelsLoading = createSelector(getPersonModelsState, state => state.loading);

export const getPersonModelById = (id: string) => createSelector(getPersonModelsState, state => state.entities[id]);
