import { createSelector } from '@ngrx/store';

import { IUIAccountRoleAssignment } from '@common/modules/shared/interfaces';

import { getEdgeExtensionsState } from './edge-extensions.selectors';
import { getUserState } from './user.selectors';
import { getInvitationsState, selectUIInvitations } from './invitations.selectors';
import { getRoleAssignmentsState, selectUIRoleAssignments } from './roleAssignments.selectors';
import { selectUIAccounts, selectCurrentAccount, getAccountsState, selectedAccountContract, selectAllAccounts } from './accounts.selectors';

export const getAllUserDetails = createSelector(getUserState, selectUIAccounts, selectCurrentAccount, (userState, accounts, selectedAccount) => ({
  ...userState.user,
  accounts: accounts,
  selectedAccountId: selectedAccount?.id
}));

export const isCoreMetadataLoaded = createSelector(
  getAccountsState,
  getUserState,
  getEdgeExtensionsState,
  (accountState, userState, extensionsState) =>
    accountState.loaded &&
    userState.loaded &&
    (!accountState.isArmAccountNeededForInit || accountState.armAccountsLoaded) &&
    (!extensionsState.isEdgeExtensionsNeededForInit || extensionsState.loaded)
);

export const isEdgeExtensionsMetadataLoaded = createSelector(
  getAccountsState,
  getUserState,
  getEdgeExtensionsState,
  (accountState, userState, extensionsState) => accountState.loaded && userState.loaded && accountState.armAccountsLoaded && extensionsState.loaded
);

// isFullMetadataLoaded - should be used only for cases when we need to load all metadata for the app including load arm accounts
export const isFullMetadataLoaded = createSelector(
  getAccountsState,
  getUserState,
  (accountState, userState) => accountState.loaded && userState.loaded && accountState.armAccountsLoaded
);

export const getRoleByUser = createSelector(getUserState, selectedAccountContract, (userState, selectedAccount) => {
  if (selectedAccount?.owners?.find(o => o.id === userState.user.id)) {
    return 'Owner';
  }

  if (selectedAccount?.contributors?.find(o => o.id === userState.user.id)) {
    return 'Contributor';
  }

  return 'Reader';
});

export const isZeroAccounts = createSelector(isCoreMetadataLoaded, selectAllAccounts, (loaded, accounts) => loaded && !Object.keys(accounts)?.length);

export const selectInvitationsListLoaded = createSelector(
  getInvitationsState,
  getRoleAssignmentsState,
  (invitations, roleAssignments) => invitations.loaded && roleAssignments.loaded
);

export const selectInviteError = createSelector(
  getInvitationsState,
  getRoleAssignmentsState,
  (invitations, roleAssignments) => invitations.error || roleAssignments.error
);

export const selectInviteIsNotAllowedError = createSelector(
  getInvitationsState,
  getRoleAssignmentsState,
  (invitations, roleAssignments) => invitations.isUserNotAllowedError || roleAssignments.isUserNotAllowedError
);

export const selectAllInvitationsEmails = createSelector(selectUIInvitations, selectUIRoleAssignments, (invitations, roleAssignments) => {
  let invitationsEmails = [];
  let roleAssEmails = [];
  if (invitations) {
    invitationsEmails = invitations.map(invitation => invitation.email);
  }
  if (roleAssignments) {
    roleAssEmails = roleAssignments.map(assignment => assignment.user.email);
  }
  return invitationsEmails.concat(roleAssEmails);
});

export const selectUIRolesWithoutUser = createSelector(getUserState, selectUIRoleAssignments, (userState, roles) => {
  return roles.filter((role: IUIAccountRoleAssignment) => role.user.email !== userState.user.email);
});
