import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { UPLOAD_FORMAT_LINK } from '@common/modules/shared/links';
import { FILE_UPLOAD_SVG } from '@common/modules/shared/svg-shapes';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { InsightsCommonUtilsService } from './../../../../../../../common/modules/insights-common/insights-common-utils.service';
import { UploadSourceActions } from '../interfaces';
import * as actions from './actions';
import { resources } from './resources';

@Component({
  selector: 'vi-upload-source',
  templateUrl: './upload-source.component.html',
  styleUrls: ['./upload-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadSourceComponent extends FocusableComponent implements OnInit {
  @Input() public filesType: string;
  @Output() public buttonClicked = new EventEmitter<UploadSourceActions>();
  @Output() public filesSelected = new EventEmitter<File[]>();
  @Output() public useSavedIndexingSettingsChanged = new EventEmitter<boolean>();

  public FILE_UPLOAD_SVG = FILE_UPLOAD_SVG;
  public isDragOver: boolean = false;
  public UploadSourceActions = UploadSourceActions;

  // Buttons properties
  public readonly BUTTON_MARGIN = 18;
  public readonly MIN_BUTTON_WIDTH = 143;
  public buttonSize: string;
  public browseForFileAction: IAction = actions.browseForFileAction;
  public enterFileUrlAction: IAction = actions.enterFileUrlAction;
  public ActionButtonType = ActionButtonType;
  public resources = resources;
  public useSavedIndexingSettings = false;

  constructor(
    private translate: TranslateHelperService,
    private insightsCommonUtilsService: InsightsCommonUtilsService,
    private trackService: TrackService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initTranslations();
    this.calcButtonsSize();
  }

  public onButtonClicked(action: UploadSourceActions) {
    this.buttonClicked.emit(action);
  }

  public uploadChooseFile(fileInput: HTMLInputElement) {
    fileInput.click();
  }

  public onDrop($event) {
    $event.preventDefault();
    this.isDragOver = false;
    if ($event.dataTransfer?.items?.length > 0) {
      const files = Array.from($event.dataTransfer.items)
        .filter((file: DataTransferItem) => file.kind === 'file')
        .map((file: DataTransferItem) => file.getAsFile());
      if (files.length > 0) {
        this.onVideoFilesSelected(files);
      }
      this.trackService.track('upload_dialog.add_fs_files.dropped', {
        category: EventCategory.UPLOAD,
        data: { filesCount: files.length }
      });
    }
  }

  public onDragOver($event) {
    this.isDragOver = true;
    $event.preventDefault();
  }

  public onDragLeave($event) {
    this.isDragOver = false;
    $event.preventDefault();
  }

  public onFileBrowse($event) {
    const files: File[] = Array.from($event.target.files);
    if (files.length > 0) {
      this.onVideoFilesSelected(files);
      this.trackService.track('upload_dialog.add_fs_files.browsed', {
        category: EventCategory.UPLOAD,
        data: { filesCount: files.length }
      });
    }
  }

  public onUseSavedIndexingSettingsChange(use: boolean) {
    this.useSavedIndexingSettings = use;
    this.useSavedIndexingSettingsChanged.emit(use);
    this.trackService.track('upload_dialog.use_saved_indexing_settings.checked', {
      category: EventCategory.UPLOAD,
      data: { use }
    });
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources, { formatsLink: UPLOAD_FORMAT_LINK });
    const keys = Object.keys(actions);
    for (const key of keys) {
      actions[key].title = this.resources[actions[key].key];
    }
  }

  /**
   * Calculate the size of the buttons according to the longest text
   */
  private calcButtonsSize() {
    this.buttonSize =
      Math.max(
        this.insightsCommonUtilsService.measureText(this.browseForFileAction.title, 14) + this.BUTTON_MARGIN,
        this.insightsCommonUtilsService.measureText(this.enterFileUrlAction.title, 14) + this.BUTTON_MARGIN,
        this.MIN_BUTTON_WIDTH
      ) + 'px';
  }

  private onVideoFilesSelected(files: File[]) {
    this.filesSelected.emit(files);
  }
}
