import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { resources } from './resources';
import * as actions from './actions';

@Component({
  selector: 'vi-confirm-exit-indexing',
  templateUrl: './confirm-exit-indexing.component.html',
  styleUrls: ['./confirm-exit-indexing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmExitIndexingComponent implements OnInit {
  @Output() backToIndexing = new EventEmitter<void>();
  @Output() leaveIndexing = new EventEmitter<IAction>();

  public resources = resources;

  // Constants
  public readonly LEAVE_INDEXING_MIN_BUTTON_WIDTH: string = '77px';
  public readonly BACK_TO_INDEXING_MIN_BUTTON_WIDTH: string = '82px';
  // Buttons properties
  public leaveIndexingAction: IAction = actions.leaveIndexingAction;
  public backToIndexingAction: IAction = actions.backToIndexingAction;
  public ActionButtonType = ActionButtonType;

  constructor(private translate: TranslateHelperService) {}

  public ngOnInit(): void {
    this.initTranslations();
  }

  public onBackToIndexing() {
    this.backToIndexing.emit();
  }

  public onLeavePage(action: IAction) {
    this.leaveIndexing.emit(action);
  }

  private initTranslations() {
    this.translate.translateResourcesInstant(resources);
    this.leaveIndexingAction.title = this.resources.ConfirmExitIndexingLeaveButton;
    this.backToIndexingAction.title = this.resources.Cancel;
  }
}
