import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { INotification } from '@common/modules/core/services/toast/interfaces';

import * as NotificationsActions from '../../actions/notifications.actions';

export interface MessagesState extends EntityState<INotification> {
  // additional entities state properties
}

function selectAccountId(a: INotification): string {
  return a.id;
}

export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>({
  selectId: selectAccountId
});

export const initialMessagesState: MessagesState = adapter.getInitialState({
  // additional entity state properties
});

const notificationsMessagesReducer = createReducer(
  initialMessagesState,
  on(NotificationsActions.addNotificationMessages, (state, { notifications }) => {
    return adapter.upsertMany(notifications, state);
  }),
  on(NotificationsActions.updateNotificationsMessages, (state, { notifications }) => {
    return adapter.updateMany(notifications, state);
  }),
  on(NotificationsActions.removeNotificationsMessages, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(NotificationsActions.clearNotificationsMessages, state => {
    return adapter.removeAll(state);
  }),
  on(NotificationsActions.removeNotifications, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  })
);

export function reducer(state: MessagesState | undefined, action: Action) {
  return notificationsMessagesReducer(state, action);
}
