import { createReducer, Action, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { INotification } from '@common/modules/core/services/toast/interfaces';

import * as NotificationsActions from '../../actions/notifications.actions';

export interface BannersState extends EntityState<INotification> {
  // additional entities state properties
}

function selectAccountId(a: INotification): string {
  return a.id;
}

export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>({
  selectId: selectAccountId
});

export const initialBannersState: BannersState = adapter.getInitialState({
  // additional entity state properties
});

const notificationsBannersReducer = createReducer(
  initialBannersState,
  on(NotificationsActions.addNotificationsBanners, (state, { notifications }) => {
    return adapter.upsertMany(notifications, state);
  }),
  on(NotificationsActions.updateNotificationsBanners, (state, { notifications }) => {
    return adapter.updateMany(notifications, state);
  }),
  on(NotificationsActions.removeNotificationsBanners, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(NotificationsActions.clearNotificationsBanners, state => {
    return adapter.removeAll(state);
  }),
  on(NotificationsActions.removeNotifications, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  })
);

export function reducer(state: BannersState | undefined, action: Action) {
  return notificationsBannersReducer(state, action);
}
