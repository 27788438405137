import { props } from '@ngrx/store';
import { createAction } from '@ngrx/store';

import { IndexingMode, AdvancedSettings } from './../../interfaces';

export const updateIndexingMode = createAction('[Indexing View] Update Indexing Mode', props<{ mode: IndexingMode }>());

export const updateSelectedAdvancedSettings = createAction(
  '[Indexing View] Update Selected Advanced Settings',
  props<{ settings: AdvancedSettings }>()
);

export const toggleAdvancedMode = createAction('[Indexing View] Toggle Advanced Mode');

export const resetIndexingViewState = createAction('[Indexing View] Reset Indexing View State');
