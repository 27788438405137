export const resources = {
  Close: '',
  UserNotInvitedToAccount: '',
  SettingsAccountTabHeader: '',
  SettingsIotConnectionsTabHeader: '',
  AccountSettingsTitle: '',
  SettingsUpdateAccountSuccess: '',
  SettingsUpdateAccountNameError: '',
  ErrorTypes_ACCESS_TOKEN_VALIDATION_FAILED: '',
  ErrorTypes_GENERAL: '',
  SettingsUpdateAccountError: '',
  UserInvitedToAccount: '',
  DeleteAccountDeleteAccountError: '',
  DeleteAccountPullOutFailed: '',
  Settings: '',
  AccountSettings: '',
  ErrorTypes_USER_NOT_ADMIN: '',
  BuyWizard_ContentMigration_AfterSignedIn: '',
  ConfigureConnectionDialogHeader: '',
  ConfigureConnectionSuccessLabel: '',
  ConfigureConnectionSuccessContentLabel: '',
  DeleteAccountDelete: '',
  DeleteAccountDialogOptionsHeader: ''
};
