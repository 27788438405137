import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from '@common/modules/api/services/api.service';
import { INetworkFileInfo, IRequestParam, IUploadRequestParams } from '@common/modules/api/interfaces';
import { LocalStorageService } from '@common/modules/shared/services/local-storage.service';

import { SessionStorageService } from '../../../../../common/modules/shared/services/session-storage.service';
import { IRequestShortcutConfig } from '../../shell/angular.interfaces';
import { IIndexingSettings } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public uploadPlayList: BehaviorSubject<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>;
  private consentSessionKey = 'uploadConsent';

  constructor(
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
    this.uploadPlayList = new BehaviorSubject<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2>(null);
  }

  public get hasUserConsent() {
    return this.sessionStorageService.getItem(this.consentSessionKey) === 'true';
  }

  public getVideoInfo(accountId: string, url: string): Observable<HttpResponse<INetworkFileInfo>> {
    return this.apiService.Account.getVideoInfo(accountId, url);
  }

  public uploadToServer(
    accountId: string,
    urlParams: IUploadRequestParams,
    contentMD5?: string,
    paramsList?: IRequestParam[]
  ): Observable<Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2> {
    const requestSettings: IRequestShortcutConfig = {};
    if (contentMD5) {
      requestSettings.headers = {
        'Content-MD5': contentMD5
      };
    }
    return this.apiService.Account.upload(accountId, urlParams, requestSettings, paramsList);
  }

  public getSas(accountId: string, fileName: string, fileSize: number) {
    return this.apiService.Account.getSas(accountId, {
      videoSize: fileSize.toString(),
      fileName: fileName,
      allowEdit: true
    });
  }

  public updateUserConsent(consent: boolean) {
    this.sessionStorageService.setItem(this.consentSessionKey, consent.toString());
  }

  public setSavedIndexingSettings(accountId: string, settings: IIndexingSettings) {
    this.localStorageService.setItem(this.indexingSettingsKey(accountId), JSON.stringify(settings));
  }

  public getSavedIndexingSettings(accountId: string) {
    const settings = this.localStorageService.getItem(this.indexingSettingsKey(accountId));

    return settings ? JSON.parse(settings) : {};
  }

  private indexingSettingsKey(accountId: string) {
    return `vi.${accountId}.indexingSettings`;
  }
}
