import { createAction, props } from '@ngrx/store';

import { INextPage } from '../models/gallery';

import SinglePlaylistSearchResultV2 = Microsoft.VideoIndexer.Contracts.SinglePlaylistSearchResultV2;

export const loadSamples = createAction('[Samples/API] Load Samples');
export const loadingSamples = createAction('[Samples] Loading Samples');
export const loadSamplesNextPage = createAction('[Samples/API] Load Next Page');
export const upsertVideos = createAction('[Samples] Upsert Videos', props<{ videos: SinglePlaylistSearchResultV2[]; nextPage?: INextPage }>());
export const updateVideo = createAction('[Samples] Update Video', props<{ video: SinglePlaylistSearchResultV2 }>());
export const clearSamples = createAction('[Samples/API] Clear Samples');
export const loadSamplesError = createAction('[Samples] Load Samples Error');
export const deleteVideoSuccess = createAction('[Samples] Delete Video Success', props<{ id: string }>());
