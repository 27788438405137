import { indexingSummaryData, indexingSetting, IndexingSettingKeys } from './interfaces';

export const models: indexingSetting[] = [
  { key: IndexingSettingKeys.IncludedAIs, text: '', value: '' },
  { key: IndexingSettingKeys.ExcludedAIs, text: '', value: '' }
];

export const dataset: indexingSummaryData = {
  videoThumbnailUrl: '',
  videoTitle: '',
  videoDescription: [
    { key: IndexingSettingKeys.IndexedBy, text: '', value: '' },
    { key: IndexingSettingKeys.GalleryItemLastModified, text: '', value: '' }
  ],
  indexingSettingsDetails: [
    { key: IndexingSettingKeys.ReindexModalVideoSourceLanguage, text: '', value: '' },
    {
      key: IndexingSettingKeys.IndexingPresetLabel,
      text: '',
      value: '',
      children: models,
      childrenClassName: 'models-settings'
    },
    { key: IndexingSettingKeys.UploadPrivacyLabel, text: '', value: '' },
    { key: IndexingSettingKeys.UploadStreaming, text: '', value: '' },
    { key: IndexingSettingKeys.UploadPeopleModel, text: '', value: '' },
    { key: IndexingSettingKeys.UploadLogoGroupOption, text: '', value: '' },
    { key: IndexingSettingKeys.BrandsModel, text: '', value: '' }
  ]
};
