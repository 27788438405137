import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/edge-supported-languages.actions';
import * as edgeExtensionsActions from '../../core/actions/edge-extensions.actions';

export interface IState extends EntityState<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> {
  // additional entities state properties
  loaded: boolean;
  error: boolean;
}

export function selectLanguageId(language: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract): Microsoft.VideoIndexer.Contracts.LanguageV2 {
  return language?.languageCode;
}

export const adapter: EntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract> =
  createEntityAdapter<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract>({
    selectId: selectLanguageId
  });

export const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false
});

const edgeSupportedLanguagesIdsReducer = createReducer(
  initialState,
  on(actions.edgeUpsertSupportedLanguages, (state, { supportedLanguages }) => {
    return adapter.upsertMany(supportedLanguages, {
      ...state,
      loaded: true,
      error: false
    });
  }),
  on(actions.edgeLoadSupportedLanguageFailed, state => {
    return {
      ...state,
      error: true
    };
  }),
  on(edgeExtensionsActions.handshakeEdgeExtension, state => {
    return adapter.removeAll({
      ...state,
      loaded: false,
      error: false
    });
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return edgeSupportedLanguagesIdsReducer(state, action);
}
