<div class="indexing-summary">
  <ng-container *ngFor="let row of indexingSettingInfo">
    <div class="row"
      *ngIf="row.show">
      <vi-ui-label [value]="row.label"></vi-ui-label>
      <div>
        <span class="row-value-{{rowValueColor}}">{{row.value}}</span>
        <div class="sub-rows"
          *ngIf="row.subRows">
          <ng-container *ngFor="let subRow of row.subRows">
            <div class="sub-row"
              *ngIf="subRow.show">
              <span class="sub-row-label">{{subRow.label}}</span>
              <span>{{subRow.value}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>