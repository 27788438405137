import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';

export const cancelDeleteAction: IAction = {
  title: '',
  key: 'SettingsRecoverAccount',
  selected: false,
  type: UIActionType.CANCEL,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'recover'
};

export const okAction: IAction = {
  title: '',
  key: 'OK',
  selected: false,
  type: UIActionType.CLICK,
  value: 'OK',
  selectable: true,
  hasDrop: false,
  isDisabled: false,
  id: 'OK'
};
