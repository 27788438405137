import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as RoleAssignments from '../actions/roleAssignments.actions';

import AccountRoleAssignment = Microsoft.VideoIndexer.Contracts.AccountRoleAssignment;

export interface IState extends EntityState<AccountRoleAssignment> {
  loaded: boolean;
  error: boolean;
  isUserNotAllowedError: boolean;
}

function selectId(a: AccountRoleAssignment): number {
  return a.id;
}

export const adapter: EntityAdapter<AccountRoleAssignment> = createEntityAdapter<AccountRoleAssignment>({
  selectId: selectId
});

const initialState: IState = adapter.getInitialState({
  loaded: false,
  error: false,
  isUserNotAllowedError: false
});

const roleAssignmentsReducer = createReducer(
  initialState,
  on(RoleAssignments.loadRoleAssignmentsInProgress, state => {
    return {
      ...state,
      loaded: false,
      error: false
    };
  }),
  on(RoleAssignments.loadRoleAssignmentsSuccess, (state, { roleAssignments }) => {
    return adapter.setAll(roleAssignments, {
      ...state,
      loaded: true
    });
  }),
  on(RoleAssignments.loadRoleAssignmentsFailed, (state, { isUserNotAllowedError }) => {
    return {
      ...state,
      loaded: true,
      error: true,
      isUserNotAllowedError: isUserNotAllowedError
    };
  }),
  on(RoleAssignments.deleteRoleAssignmentSuccess, (state, { roleAssignmentId }) => {
    return adapter.removeOne(roleAssignmentId, state);
  }),
  on(RoleAssignments.roleAssignmentsError, (state, { isUserNotAllowedError }) => {
    return {
      ...state,
      error: true,
      isUserNotAllowedError: isUserNotAllowedError
    };
  })
);

export function reducer(state: IState, action: Action) {
  return roleAssignmentsReducer(state, action);
}
