import { IAction } from '../../interfaces';
import { UIActionType } from '../../../insights/interfaces';

export const closeAction: IAction = {
  icon: 'i-vi-close-big',
  title: '',
  key: 'Close',
  selected: false,
  type: UIActionType.CLOSE,
  value: '',
  selectable: true,
  hasDrop: false,
  id: 'CLOSE',
  titleAttribute: 'Close'
};
