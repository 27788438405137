import { createSelector } from '@ngrx/store';

import { selectCoreState } from '../reducers';

export const selectSupportedLanguagesIdsState = createSelector(selectCoreState, state => state.supportedLanguagesIds);

export const selectSupportedLanguagesIds = createSelector(selectSupportedLanguagesIdsState, state => Object.values(state.entities));

export const selectSupportedLanguagesIdsError = createSelector(selectSupportedLanguagesIdsState, state => state.error);

export const selectSupportedLanguagesIdsSuccess = createSelector(selectSupportedLanguagesIdsState, state => state.loaded);

export const selectSpeechDatasetLocalesForLanguage = createSelector(selectSupportedLanguagesIds, entities => {
  return entities.reduce((acc, curr) => {
    if (curr.isSupportedForLanguageDataset) {
      return acc.concat([curr.languageCode]);
    }
    return acc;
  }, []);
});

export const selectSpeechDatasetLocalesForPronunciation = createSelector(selectSupportedLanguagesIds, entities => {
  return entities.reduce((acc, curr) => {
    if (curr.isSupportedForPronunciationDataset) {
      return acc.concat([curr.languageCode]);
    }
    return acc;
  }, []);
});

export const selectSpeechModelsLocales = createSelector(selectSupportedLanguagesIds, entities => {
  return entities.reduce((acc, curr) => {
    if (curr.isSupportedForCustomModels) {
      return acc.concat([curr.languageCode]);
    }
    return acc;
  }, []);
});
