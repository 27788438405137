import { IAction } from '../shared/interfaces';
import { UIActionType, UIActionIcon } from '../insights/interfaces';
import { IDialogButton } from './components/dialog/interfaces';
import { ActionButtonType } from './components/action-button/interfaces';

export const actions: IAction[] = [
  {
    icon: 'i-vi-download',
    title: 'Download',
    key: 'Download',
    type: UIActionType.DOWNLOAD,
    value: UIActionType.DOWNLOAD,
    selected: false,
    selectable: true,
    hasDrop: true,
    id: 'download',
    isDisabled: false
  },
  {
    icon: 'i-vi-translate',
    title: 'Translate',
    key: 'Translate',
    isButtonAction: true,
    type: UIActionType.LANGUAGE,
    value: UIActionType.LANGUAGE,
    selected: false,
    selectable: true,
    hasDrop: true,
    id: 'language',
    isDisabled: false
  },
  {
    icon: 'i-vi-layers',
    title: 'View',
    key: 'View',
    type: UIActionType.PRESETS,
    value: UIActionType.PRESETS,
    selected: false,
    selectable: true,
    hasDrop: false,
    id: 'presets',
    isDisabled: false
  },
  {
    icon: UIActionIcon.EDIT,
    title: 'Edit',
    key: 'Edit',
    type: UIActionType.EDIT,
    value: UIActionType.EDIT,
    selected: false,
    selectable: true,
    hasDrop: false,
    id: 'edit',
    hasPressedMode: true,
    isDisabled: false
  },
  {
    icon: 'i-vi-layers',
    title: 'View',
    key: 'View',
    type: UIActionType.PRESETS,
    value: UIActionType.PRESETS,
    selected: false,
    selectable: true,
    hasDrop: true,
    id: 'presets',
    hasPressedMode: true
  }
];

export const GeneralCancelDialogButton: IDialogButton = {
  type: ActionButtonType.SECONDARY,
  action: {
    title: 'Cancel',
    key: 'Cancel',
    selected: false,
    type: UIActionType.CANCEL,
    value: 'Cancel',
    selectable: true,
    hasDrop: false,
    id: 'Cancel'
  }
};

export const UpdateAccountAction: IAction = {
  title: '',
  key: 'UpdateAccountNotificationButtonText',
  type: UIActionType.UPDATE,
  value: UIActionType.UPDATE,
  id: 'update-account'
};
