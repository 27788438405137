import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';
import { guid } from '@common/modules/utils/string';

import { CheckboxSize } from './interfaces';

@Component({
  selector: 'vi-checkbox-with-label',
  templateUrl: './checkbox-with-label.component.html',
  styleUrls: ['./checkbox-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxWithLabelComponent extends FocusableComponent {
  @Input() public checked = false;
  @Input() public label: string;
  @Input() public disabled = false;
  @Input() public size?: CheckboxSize = CheckboxSize.Large;
  @Input() public labelSize?: '12-16' | '13-18' | '14-20' = '14-20';
  @Input() public ariaLabel?: string;
  @Output() public checkedChange = new EventEmitter<boolean>(false);

  public id = `checkboxWithLabel${guid()}`;

  constructor() {
    super();
  }

  public toggleCheckbox() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
