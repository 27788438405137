import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bold'
})
export class BoldPipe implements PipeTransform {
  public transform(value: string, boldItems: string[], title: string): string {
    if (!boldItems.length) {
      return value;
    }
    const boldItemsStr = boldItems.join('|');
    const regex = new RegExp(`\\b${boldItemsStr}\\b`, 'ig');

    // Verify there is no existing HTML elements
    const testDiv = document.createElement('div');
    // eslint-disable-next-line @microsoft/sdl/no-inner-html
    testDiv.insertAdjacentHTML('beforeend', value);

    let newValue = '';
    // Go over child nodes, and update only pure text element
    testDiv?.childNodes.forEach(node => {
      const text = node?.textContent;
      // If node is text type, and matches regex
      if (text && node?.nodeName === '#text' && regex.test(text)) {
        newValue += text.replace(regex, `<b title="${title}">$&</b>`);
      } else {
        newValue += (node as Element)?.outerHTML || text;
      }
    });

    return newValue;
  }
}
