import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { FocusableComponent } from '@common/modules/accessibility/focusable/focusable.component';

import { IUIKeyframe } from './interfaces';
import { resources } from './resources';
import { TranslateHelperService } from '../../../translation/services/translate-helper.service';
import { getSeconds } from '../../../utils/time';

@Component({
  selector: 'app-vi-insights-keyframe',
  templateUrl: './keyframe.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./keyframe.component.scss']
})
export class KeyframeComponent extends FocusableComponent implements OnInit {
  @Input() public keyframe: IUIKeyframe;
  @Input() public hasTooltip = true;
  @Input() public activeContainer = false;
  @Input() public selected = false;
  @Input() public isActiveSelection = true;
  @Input() public roleType = 'button';
  @Input() public isVideoThumbnail = false;
  @Input() public currentStartTimeFrame: number;
  @Input() public currentEndTimeFrame: number;
  @Output() public select = new EventEmitter<IUIKeyframe>();

  @ViewChild('keyframeImage') public keyframeRef: ElementRef;

  public resources = resources;
  public keyframeStart = -1;
  public keyframeEnd = -1;
  public showTooltip = false;

  constructor(private translate: TranslateHelperService) {
    super();
  }

  public ngOnInit() {
    // Translate
    this.translate.translateResources(this.resources);

    if (this.keyframe) {
      this.keyframeStart = getSeconds(this.keyframe.start);
      this.keyframeEnd = getSeconds(this.keyframe.end);
    }
  }

  public selectKf(kf: IUIKeyframe) {
    this.select.emit(kf);
  }

  public isInCurrentTimeFrame(): boolean {
    return this.keyframeStart >= this.currentStartTimeFrame && this.keyframeEnd <= this.currentEndTimeFrame;
  }

  public onMouseOver(state: boolean) {
    this.showTooltip = state;
  }
}
