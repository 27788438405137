import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, combineLatest, takeUntil } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';
import { CustomizationDataStoreService } from 'src/apps/web/src/customization-data/services/customization-data-store.service';
import { IndexingStoreService } from 'src/apps/web/src/indexing/services/indexing-store.service';

import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { guid } from '@common/modules/utils/string';
import { LOGO_API_LINK } from '@common/modules/shared/links';

import { AdvancedSettings } from '../../../../interfaces';
import { logoGroupAction, manageLogoGroupsAction } from './actions';
import { resources } from './resources';

@Component({
  selector: 'vi-indexing-settings-logo-groups',
  templateUrl: './indexing-settings-logo-groups.component.html',
  styleUrls: ['./indexing-settings-logo-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexingSettingsLogoGroupsComponent implements OnInit, OnDestroy {
  @Input() public isDisabled = false;
  @Input() public isAdvancedIndexingPresetSelected: boolean;
  @Output() public manageLogosClicked = new EventEmitter<CustomizationPage>();

  public ActionButtonType = ActionButtonType;
  public isLoading: boolean;
  public resources = resources;
  public manageLogosGroupsAction: IAction = manageLogoGroupsAction;
  public logosGroupActions: IAction[] = [];
  public logoGroupAction: IAction = logoGroupAction;
  public selectedLogoGroup: IAction = null;
  public menuPlaceholder: string;
  public actionButtonWidth: string = 'max-content';
  public AlertMessageType = AlertMessageType;
  public LOGO_API_LINK = LOGO_API_LINK;

  public readonly dropdownId: string = 'logosGroupsDropdown_' + guid();
  public readonly linkSize = '14-20';

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private customizationDataStoreService: CustomizationDataStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    this.translate.translateResourcesInstant(this.resources);
    this.manageLogosGroupsAction.title = this.resources.IndexingLogosManageLogoGroups;
    this.menuPlaceholder = this.resources.SettingsLoading;
    this.logoGroupAction.title = this.resources.IndexingLogosSelectLogoGroupsLabel;

    this.loadLogoGroups();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSelectLogoGroup() {
    this.indexingStore.updateLogoGroup(this.selectedLogoGroup?.id);
  }

  public handleNavigateToIndexingPresetTab() {
    this.indexingStore.updateSelectedAdvancedSettings(AdvancedSettings.IndexingPreset);
  }

  public loadLogoGroups() {
    combineLatest([
      this.customizationDataStoreService.logoGroupsActions,
      this.customizationDataStoreService.isLogoGroupsLoaded(),
      this.indexingStore.selectLogoGroupId$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([logoGroupsActions, isLoaded, selectedLogoGroupId]) => {
        if (isLoaded && !this.logosGroupActions.length) {
          this.logosGroupActions = logoGroupsActions;

          if (this.logosGroupActions.length) {
            if (selectedLogoGroupId) {
              this.selectedLogoGroup = this.logosGroupActions.find(group => group.id === selectedLogoGroupId);
              this.cdr.detectChanges();
            } else {
              // Set the first item as selected
              this.selectedLogoGroup = this.logosGroupActions[0];
            }
          }

          this.menuPlaceholder = this.resources?.CheckboxSelectLabel;
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      });
  }

  public isDisabledElement() {
    return !!this.logosGroupActions?.length && (this.isDisabled || this.isLoading || !this.isAdvancedIndexingPresetSelected);
  }
}
