import { Injectable } from '@angular/core';

import { ConfigService } from '../config/config.service';

@Injectable()
export class LoggerService {
  public isEnabled = false;
  constructor(private configService: ConfigService) {
    const config = this.configService.Config;
    if (config.logger) {
      this.isEnabled = true;
    }
    this.log('[LoggerService] init');
  }

  public log(...msg) {
    if (!this.isEnabled) {
      return;
    }

    msg.forEach(m => {
      if (typeof m === 'string') {
        // eslint-disable-next-line no-console
        console.log(`%c${m}`, 'background: #222; color: #bada55');
      } else {
        // eslint-disable-next-line no-console
        console.log(m);
      }
    });
  }

  public error(...msg) {
    if (!this.isEnabled) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(msg);
  }
}
