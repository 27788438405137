import { OnInit, ChangeDetectorRef, OnDestroy, EventEmitter, Output, ChangeDetectionStrategy, Component } from '@angular/core';

import { Subject, takeUntil, Observable, withLatestFrom } from 'rxjs';

import { AlertMessageType } from '@vi-ui/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { CoreStoreService } from '../../../../core/services/core-store.service';
import { UploadingState } from './../../../interfaces';
import { resources } from './resources';
import { IndexingStoreService } from '../../../services/indexing-store.service';
import { IFile } from '../../../interfaces';
import { ProgressTitleType, IUploadErrorStrip } from './interfaces';

@Component({
  selector: 'vi-upload-summary-page',
  templateUrl: './upload-summary-page.component.html',
  styleUrls: ['./upload-summary-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadSummaryPageComponent implements OnInit, OnDestroy {
  @Output() public createAccountClicked = new EventEmitter<void>();

  public resources = resources;
  public totalUploadProgress: number = 0;
  public isMultipleFileUpload: boolean;
  public files: IFile[] = [];
  public numOfFiles: number;
  public filesLabel = '';
  public progressTitle = '';
  public UploadingState = UploadingState;
  public isEventGridError$ = new Observable<boolean>();
  public isAmsAadAppNotInTenantError$ = new Observable<boolean>();
  public isAmsUnreachableError$ = new Observable<boolean>();
  public isStorageUnreachableError$ = new Observable<boolean>();
  public isAccountDurationQuota$ = new Observable<boolean>();
  public isAccountDailyCountQuota$ = new Observable<boolean>();
  public isAccountDailyDurationQuota$ = new Observable<boolean>();
  public isStorageAccessDeniedError$ = new Observable<boolean>();
  public isManagedIdentityMissingError$ = new Observable<boolean>();
  public AlertMessageType = AlertMessageType;
  public errors: { [key: string]: IUploadErrorStrip };

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateHelperService,
    private indexingStore: IndexingStoreService,
    private coreStoreService: CoreStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.translate.translateResourcesInstant(this.resources);
    this.handleProgress();
    this.handleFiles();
    this.handleErrors();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createAccount() {
    this.createAccountClicked.emit();
  }

  private handleProgress() {
    this.indexingStore.totalUploadProgress$.pipe(takeUntil(this.destroy$)).subscribe(progress => {
      this.totalUploadProgress = progress;
      this.cdr.detectChanges();
    });
  }

  private handleFiles() {
    this.indexingStore.allValidFiles$
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.indexingStore.isUploadingDone$))
      .subscribe(([files, isUploadingDone]) => {
        this.files = files;
        this.numOfFiles = files?.length;
        this.isMultipleFileUpload = this.numOfFiles > 1;
        const failedFilesCount = this.files.filter(file => file.uploadingState === UploadingState.Failed).length;
        this.filesLabel = this.isMultipleFileUpload ? this.resources.IndexingMultiFilesLabel : this.resources.IndexingSingleFileLabel;
        this.progressTitle = this.getProgressTitle(isUploadingDone, failedFilesCount);
        this.cdr.detectChanges();
      });
  }

  private getProgressTitle(isUploadingDone: boolean = false, failedFilesCount: number = 0): string {
    const succeededFilesCount = this.numOfFiles - failedFilesCount;
    const progressTitleCase = this.isMultipleFileUpload
      ? this.getProgressTitleCaseForMultipleFiles(isUploadingDone, failedFilesCount, succeededFilesCount)
      : this.getProgressTitleCaseForSingleFile(isUploadingDone, failedFilesCount);

    switch (progressTitleCase) {
      case ProgressTitleType.MULTIPLE_IN_PROGRESS:
        return `${this.translate.instant('UploadingNumberFiles', { number: this.numOfFiles })}...`;
      case ProgressTitleType.SINGLE_IN_PROGRESS:
        return `${this.resources.Uploading}...`;
      case ProgressTitleType.SINGLE_FAILED:
        return this.resources.SingleFileNotUploaded;
      case ProgressTitleType.MULTIPLE_SUCCESS:
        return `${this.translate.instant('NumberFilesUploaded', { number: succeededFilesCount })}, ${this.translate.instant('NumberNotUploaded', {
          number: failedFilesCount
        })}`;
      case ProgressTitleType.SINGLE_SUCCESS:
        return this.resources.SingleFileUploaded;
      case ProgressTitleType.SINGLE_SUCCESS_WITH_FAILED:
        return `${this.translate.instant('SingleFileUploaded')}, ${this.translate.instant('NumberNotUploaded', { number: failedFilesCount })}`;
      case ProgressTitleType.ALL_SUCCESS:
        return this.translate.instant('NumberFilesUploaded', { number: succeededFilesCount });
      case ProgressTitleType.ALL_FAILED:
        return this.translate.instant('NumberFilesNotUploaded', { number: failedFilesCount });
    }
  }

  private getProgressTitleCaseForMultipleFiles(isUploadingDone: boolean, failedFiles: number, succeededFilesCount: number): ProgressTitleType {
    const isMultipleFilesSucceeded = succeededFilesCount > 1;

    switch (true) {
      case !isUploadingDone:
        return ProgressTitleType.MULTIPLE_IN_PROGRESS;
      case failedFiles === 0:
        return ProgressTitleType.ALL_SUCCESS;
      case failedFiles === this.numOfFiles:
        return ProgressTitleType.ALL_FAILED;
      case isMultipleFilesSucceeded:
        return ProgressTitleType.MULTIPLE_SUCCESS;
      case !isMultipleFilesSucceeded:
        return ProgressTitleType.SINGLE_SUCCESS_WITH_FAILED;
    }
  }

  private getProgressTitleCaseForSingleFile(isUploadingDone: boolean, failedFiles: number): ProgressTitleType {
    switch (true) {
      case !isUploadingDone:
        return ProgressTitleType.SINGLE_IN_PROGRESS;
      case failedFiles === 0:
        return ProgressTitleType.SINGLE_SUCCESS;
      case failedFiles === 1:
        return ProgressTitleType.SINGLE_FAILED;
    }
  }

  private handleErrors() {
    this.isEventGridError$ = this.indexingStore.isEventGridError$;
    this.isAmsAadAppNotInTenantError$ = this.indexingStore.isAmsAadAppNotInTenantError$;
    this.isAmsUnreachableError$ = this.indexingStore.isAmsUnreachableError$;
    this.isStorageUnreachableError$ = this.indexingStore.isStorageUnreachableError$;
    this.isAccountDurationQuota$ = this.indexingStore.isAccountDurationQuota$;
    this.isAccountDailyCountQuota$ = this.indexingStore.isAccountDailyCountQuota$;
    this.isAccountDailyDurationQuota$ = this.indexingStore.isAccountDailyDurationQuota$;
    this.isStorageAccessDeniedError$ = this.indexingStore.isStorageAccessDeniedError$;
    this.isManagedIdentityMissingError$ = this.indexingStore.isManagedIdentityMissingError$;
    this.getErrors();
  }

  private getErrors() {
    this.errors = {
      eventGridError: {
        show: this.isEventGridError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.EventGridErrorMsg,
        linkText: '',
        isButton: false,
        onCLick: null
      },
      accountDurationQuotaError: {
        show: this.isAccountDurationQuota$,
        type: AlertMessageType.WARNING,
        content: `${this.resources?.AccountDurationQuotaErrorMsg},`,
        linkText: this.resources?.CreateUnlimitedAccountWithAzure,
        isButton: true,
        onCLick: () => this.createAccount()
      },
      accountDailyCountQuotaError: {
        show: this.isAccountDailyCountQuota$,
        type: AlertMessageType.WARNING,
        content: `${this.resources?.AccountDailyCountQuotaErrorMsg},`,
        linkText: this.resources?.CreateAccountWithAzureSubscription,
        isButton: true,
        onCLick: () => this.createAccount()
      },
      accountDailyDurationQuotaError: {
        show: this.isAccountDailyDurationQuota$,
        type: AlertMessageType.WARNING,
        content: `${this.resources?.AccountDailyDurationQuotaErrorMsg},`,
        linkText: this.resources?.CreateAccountWithAzureSubscription,
        isButton: true,
        onCLick: () => this.createAccount()
      },
      amsUnReachableError: {
        show: this.isAmsUnreachableError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.AmsUnreachableErrorMsg,
        linkText: '',
        isButton: false,
        onCLick: null
      },
      amsAadAppNotInTenantError: {
        show: this.isAmsAadAppNotInTenantError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.AmsAadAppNotInTenantErrorMsg,
        linkText: '',
        isButton: false,
        onCLick: null
      },
      storageUnReachableError: {
        show: this.isStorageUnreachableError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.StorageUnreachableErrorMsg,
        linkText: '',
        isButton: false,
        onCLick: null
      },
      storageAccessDeniedError: {
        show: this.isStorageAccessDeniedError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.UploadFileStorageAccessDeniedErrorMsg,
        linkText: this.resources?.StorageAccessDeniedErrorMsgLinkText,
        isButton: false,
        onCLick: () => this.coreStoreService.navigateToViResourceOverviewPortalUrl()
      },
      managedIdentityMissingError: {
        show: this.isManagedIdentityMissingError$,
        type: AlertMessageType.ERROR,
        content: this.resources?.UploadFileManagedIdentityMissingErrorMsg,
        linkText: '',
        isButton: false,
        onCLick: null
      }
    };
  }
}
