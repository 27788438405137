import { IUIPresetInsight, IUIPostProductionInsights, UIPresetInsight, IUICustomPresetInsight } from '../insights/interfaces';
import { IUIVideoTimeline, IUIEditorFilterItem, UITimelineScrollItemSelectState } from '../editor/interfaces';
import {
  TimelineTranscriptLine,
  TimelineSpeakerLine,
  TimelineKeyframeLine,
  TimelineFaceLine,
  TimelineOcrLine,
  TimelineEmotionLine,
  TimelineCapsuleLine,
  TimelineSpokenLanguageLine,
  TimelineBaseScrollLine,
  TimelineLogoLine
} from './classes';
import { UIScrollItem } from './components/scroll-item/classes';
import { TimelineAcousticEventLine } from './classes/timeline-acoustic-event-line.class';
import { TimelineObservedPeopleLine } from './classes/timeline-observed-people-line.class';
import { TimelineColorBarsLine } from './classes/timeline-color-bars-line.class';
import { TimelineClapperboardLine } from './classes/timeline-clapperboards-line.class';
import { TimelineDetectedObjectsLine } from './classes/TimelineDetectedObjects';
import { TimelineCustomInsightsLine } from './classes/timeline-custom-insights-line.class';

export interface ITime {
  adjustedStart: number | string;
  startText: string;
  from: number;
  to: number;
}
export interface ITimelineData {
  textFilter: string;
  editorFilterItems: IUIEditorFilterItem[];
  keyframesRef: IUIPresetInsight;
  keywordsRef: IUIPresetInsight;
  observedPeopleRef: IUIPresetInsight;
  detectedObjectsRef: IUIPresetInsight;
  acousticEventsRef: IUIPresetInsight;
  labelsRef: IUIPresetInsight;
  peopleRef: IUIPresetInsight;
  namedEntitiesRef: IUIPresetInsight;
  speakersRef: IUIPresetInsight;
  ocrRef: IUIPresetInsight;
  transcriptRef: IUIPresetInsight;
  spokenLanguageRef: IUIPresetInsight;
  emotionsRef: IUIPresetInsight;
  postProductionRef: IUIPresetInsight;
  logosRef: IUIPresetInsight;
  customInsightsRef: Map<string, IUICustomPresetInsight>;
  edit: boolean;
  selectedSegments: ITimelineSegment;
  durationOffset: number;
}

export interface ITimelineSegment {
  timeRanges: Microsoft.VideoIndexer.Contracts.SegmentTimeRange[];
  videoId: string;
  uniqueVideoId: string;
  selectState: UITimelineScrollItemSelectState;
}

export interface IAllSelectedToggle {
  isSelected: boolean;
  count: number;
}

export interface ITimeToIndex {
  [time: number]: number;
}

export interface IIndexToLineBlock {
  [index: number]: number[];
}

export interface IDataForService {
  videoData: IUIVideoTimeline;
  sortedAndFlattenInsightsObj: ISortedAndFlattenInsightsObj;
  isInsightsSet: boolean;
  isGroupedInsightsSet: boolean;
  isScrollItemsSet: boolean;
  groupedInsights: TimelineBaseScrollLine[][];
  scrollItems: UIScrollItem[];
  keywords: string[];
  namedEntities: IEntityItem[];
  editorFilterItems?: IUIEditorFilterItem[];
  durationOffset: number;
}

export interface IEntityItem {
  name: string;
  subType: string;
}

export interface ISortedAndFlattenInsightsObj {
  transcript: TimelineTranscriptLine[];
  spokenLanguage: TimelineSpokenLanguageLine[];
  speakers: TimelineSpeakerLine[];
  keyframes: TimelineKeyframeLine[];
  observedPeople: TimelineObservedPeopleLine[];
  faces: TimelineFaceLine[];
  ocr: TimelineOcrLine[];
  logos: TimelineLogoLine[];
  emotions: TimelineEmotionLine[];
  labels: TimelineCapsuleLine[];
  keywords: TimelineCapsuleLine[];
  namedEntities: TimelineCapsuleLine[];
  audioEffects: TimelineAcousticEventLine[];
  clapperboards: TimelineClapperboardLine[];
  colorBars: TimelineColorBarsLine[];
  detectedObjects: TimelineDetectedObjectsLine[];
  custom: Map<string, TimelineCustomInsightsLine[]>;
}

export enum JsonPatchOperation {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  MOVE = 'move',
  COPY = 'copy',
  TEST = 'test'
}

export interface ITranscriptLineInfo {
  id?: number;
  index: number;
  adjustedStart: string;
  adjustedEnd: string;
  text?: string;
  speakerId?: number;
}

export interface IEditableTimelineLine {
  originalText: string;
}

export enum FramePatternType {
  ROLLING_CREDITS = 'RollingCredits',
  BLACK = 'Black',
  COLOR_BARS = 'ColorBars'
}

export type TimelineInsightType = IUIPostProductionInsights | UIPresetInsight | string;
