import { IUIEntityInsight, IUIInsight } from '../../interfaces';

export interface IUIDetectedObjectGroup extends IUIInsight {
  objects: IUIDetectedObject[];
  className: string;
}

export interface IUIDetectedObject extends IUIEntityInsight {
  className: string;
  displayClassName: string;
}

export const DETECTED_OBJECT_WIDTH_TINY = 54;
export const DETECTED_OBJECT_WIDTH = 60;
export const DETECTED_OBJECT_WIDTH_LARGE = 72;
export const SPRITE_WIDTH = 100;
