import { IHttpWrapper } from '../interfaces';
import { getRequestSettings } from '../utils/request.function';

export class SupportedLanguagesApi {
  constructor(private ApiWrapper: IHttpWrapper) {}
  // [HttpGet]
  // [Route("{apiOperationBase}/SupportedLanguages")]
  public getSupportedLanguages() {
    const url = `${this.ApiWrapper.GetApiOperationsBase()}/SupportedLanguages`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache) as object;
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]>(url, requestSettings);
  }
}
