import { createSelector } from '@ngrx/store';

import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { selectCoreState } from '../reducers';
import { isSupportedLanguageDisabled } from '../utils';
import { selectSupportedLanguagesIds, selectSupportedLanguagesIdsState } from './supported-languages-ids.selectors';

export const selectLanguageIdState = createSelector(selectCoreState, state => state.languageId);

export const selectSelectedLanguages = createSelector(selectLanguageIdState, state => Object.values(state.entities));

export const selectSelectedLanguagesKeys = createSelector(selectLanguageIdState, state => Object.values(state.ids));

export const selectSelectedLanguagesNames = createSelector(selectSelectedLanguages, entities => Object.values(entities).map(l => l.name));

export const selectDefaultLanguages = createSelector(selectLanguageIdState, state => Object.values(state.defaultLanguages));

export const selectDefaultLanguagesNames = createSelector(selectLanguageIdState, state => Object.values(state.defaultLanguages).map(l => l.name));

export const selectSupportedLanguagesIdsActions = createSelector(
  selectSupportedLanguagesIdsState,
  selectSelectedLanguagesKeys,
  (state, selectedLanguages) => {
    const actions: IAction[] = [];
    Object.values(state.entities)
      .filter(entity => entity.isAutoDetect)
      .forEach(lang => {
        const isDisabled = isSupportedLanguageDisabled(selectedLanguages, lang.languageCode);
        const uiLanguage: IAction = {
          title: lang.name,
          type: UIActionType.CHANGE_LANGUAGE,
          value: lang.languageCode,
          id: lang.name,
          isDisabled: isDisabled,
          selectable: !isDisabled,
          selected: !isDisabled
        };
        actions.push(uiLanguage);
      });
    return actions;
  }
);

export const selectSupportedAutoDetectLanguagesIds = createSelector(selectSupportedLanguagesIds, supportedLanguages =>
  supportedLanguages.filter(language => language.isAutoDetect)
);
