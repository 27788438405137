import { Action, createReducer, on } from '@ngrx/store';

import * as PeoplePageActions from '../../actions/people/people-page.actions';
import { PeopleTab } from '../../../customization/components/customization-people-container/interfaces';

export interface IState {
  selectedTab: PeopleTab;
}

export const peoplePageInitialState: IState = {
  selectedTab: PeopleTab.PEOPLE
};

const peoplePageReducer = createReducer(
  peoplePageInitialState,
  on(PeoplePageActions.initSelectedPeopleTab, (state, { tab }) => {
    return {
      ...state,
      selectedTab: tab
    };
  }),
  on(PeoplePageActions.selectPeopleTab, (state, { tab }) => {
    return {
      ...state,
      selectedTab: tab
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return peoplePageReducer(state, action);
}
