import { HttpRequest, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

import { merge } from 'lodash-es';

import { APIErrors, unauthorizedErrorTypes } from '@common/modules/core/services/toast/errors';
import { GuidPattern } from '@common/modules/utils/regexes';
import { AccountPermission } from '@common/modules/shared/interfaces';

import { IRequestParam } from '../interfaces';

export const AccessTokenHeaderName = 'Authorization';

export function getRequestSettings(cache: object, reqSettings?: object, reqParams?: Params): object {
  // Define the default request config object
  const defaultsSettings: object = {
    params: {}
  };

  // If has Cache
  if (cache) {
    defaultsSettings['cache'] = cache;
  }

  // Remove null params from reqParams
  if (reqParams) {
    Object.keys(reqParams).forEach(param => {
      if (reqParams[param] === null || reqParams[param] === undefined) {
        delete reqParams[param];
      }
    });
  }

  // If extra request params provided - merge them into defaults
  if (reqParams) {
    merge(defaultsSettings['params'], reqParams);
  }

  return merge(reqSettings, defaultsSettings);
}

export function tryGetParam(request: HttpRequest<object>, param: string): string {
  // Try get the param from request params
  if (request.params.has(param)) {
    return request.params.get(param);
  }

  // Try get it from body
  if (!!request.body && typeof request.body === 'object' && request.body[param]) {
    return request.body[param];
  }

  // try get it from route.
  return captureParamFromUrl(request.url, param) || '';
}

/**
 *  Add list of params to the url. e.g. ?excludeAI=Faces&excludeAI=Labels...
 * @param url - the url to add the params to
 * @param params - list of params to add. e.g. [{key: 'excludeAI', value: 'Faces'}, {key: 'excludeAI', value: 'Labels'}]
 */
export function concatParamsListToURL(url: string, params: IRequestParam[]): string {
  if (params?.length) {
    let httpParams = new HttpParams();
    for (const param of params) {
      httpParams = httpParams.append(param.key, param.value);
    }
    // check if url already has params and add '?' if needed
    url += url.includes('?') ? '&' : '?';
    url = url + httpParams.toString();
  }

  return url;
}

export function captureParamFromUrl(url: string, param: string): string {
  const idRegex = url.includes('videos')
    ? /(videos|[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})\/([0-9a-zA-Z]{10,12})/
    : /(Projects|[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})\/([0-9a-zA-Z]{10,12})/;
  let regex = idRegex;
  if (param === 'accountId') {
    const accountRegex = /(accounts|insights|player)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})/;
    regex = accountRegex;
  } else if (param === 'accountName') {
    const armAccountRegex = /(accounts)\/([-\w\._\(\)]+$)/;
    regex = armAccountRegex;
  } else if (param === 'location') {
    const locationRegex = /(.+)\/(.+)\/accounts/;
    regex = locationRegex;
  }

  const match = url.match(regex);

  return (match && match[2]) || '';
}

export function ignoreAccountId(url: string) {
  // If it is a join request - ignore account id
  const inviteIdPattern = '[0-9]{5}';
  const regex = `${GuidPattern}/join/${inviteIdPattern}`;
  return url.match(regex);
}

export function isProjectRequest(request: HttpRequest<object>) {
  return request?.url?.toLowerCase().includes('/projects/') || request.params.get('isBase') === 'false';
}

export function isVideosRequest(request: HttpRequest<object>) {
  return request?.url?.toLowerCase().includes('/videos/');
}
export function isThumbnailsRequest(request: HttpRequest<object>) {
  return request?.url?.toLowerCase().includes('/thumbnails/');
}

export function isAccessTokenRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/accessToken') || request.url.includes('/accesstoken');
}

export function isUsersRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/users/me');
}

export function isReportAbuseRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/reportAbuse');
}

export function isArmAccountRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/arm/');
}

export function isNotificationsRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/api/notifications');
}

export function isSupportedLanguagesRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/SupportedLanguages');
}

export function isCustomizationRequest(request: HttpRequest<object>): boolean {
  return request.url.includes('/customization/');
}

export function isReIndexRequest(request: HttpRequest<object>): boolean {
  return request.url.toLowerCase().includes('/reindex');
}

export function forceAccessToken(request: HttpRequest<object>) {
  return !!request.params.get('forceToken');
}

export function ignoreEditRoutes(request: HttpRequest<object>): boolean {
  return isReportAbuseRequest(request);
}

export function getPermissionParam(request: HttpRequest<object>): AccountPermission {
  return request.params.get('permission') as AccountPermission;
}

export function hasAllowEdit(request: HttpRequest<object>): boolean {
  const tokenSplit = request.headers.get(AccessTokenHeaderName)?.split('.');
  let tokenString = '';
  // Decode the token value to get the parameters in it.
  if (tokenSplit && tokenSplit.length >= 3) {
    tokenString = atob(tokenSplit[1]);
  }

  return (
    // Check the request method. for example - PUT should be with allowEdit=true
    isEditRequest(request) ||
    // Check allowEdit=true in url, params or in header with refresh
    request.params.get('allowEdit')?.toLocaleString() === 'true' ||
    request.url.includes('allowEdit=true') ||
    (request.headers.has(AccessTokenHeaderName) && request.headers.get(AccessTokenHeaderName).includes('refresh?allowEdit=true')) ||
    // Check the parameters in the decoded token
    tokenString.includes('"AllowEdit":"True"') ||
    tokenString.includes('"Permission":"Contributor"') ||
    tokenString.includes('"Permission":"Owner"')
  );
}

export function isEditRequest(request: HttpRequest<object>): boolean {
  return (
    !ignoreEditRoutes(request) &&
    (request.method.toLocaleLowerCase() === 'put' ||
      request.method.toLocaleLowerCase() === 'post' ||
      request.method.toLocaleLowerCase() === 'patch' ||
      request.method.toLocaleLowerCase() === 'delete')
  );
}

export function getRequestVideoId(request: HttpRequest<object>): string {
  if ((isProjectRequest(request) && hasAllowEdit(request)) || isThumbnailsRequest(request) || !isVideosRequest(request)) {
    return '';
  }
  return tryGetParam(request, 'id') || tryGetParam(request, 'videoId');
}

export function getRequestAccountId(request: HttpRequest<object>): string {
  return ignoreAccountId(request.url) ? '' : tryGetParam(request, 'accountId') || tryGetParam(request, 'accountName');
}

export function addAuthenticationToken(request: HttpRequest<object>, accessToken: string): HttpRequest<object> {
  // If access token is empty - send as empty access token
  if (!accessToken) {
    return request;
  }

  // We clone the request, because the original request is immutable
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export function isUserNotAuthenticatedError(error): boolean {
  return (
    error?.status === 401 &&
    (error?.error?.ErrorType === APIErrors.USER_NOT_AUTHENTICATED || error?.error?.ErrorType === APIErrors.USER_NOT_SIGNED_IN)
  );
}

export function getErrorType(error): APIErrors {
  // Implicit unauthorized - from external services
  if (!error?.error?.ErrorType && error?.status === 403) {
    return APIErrors.USER_NOT_ALLOWED;
  }

  return error?.error?.ErrorType;
}

export function isUserNotAuthenticatedOrUnauthorizedError(errorType: APIErrors, accountErrorType: APIErrors): boolean {
  return unauthorizedErrorTypes.includes(errorType) || unauthorizedErrorTypes.includes(accountErrorType);
}
