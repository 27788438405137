import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import { initialUploadState, UploadState, reducer as uploadReducer } from './upload.reducer';
import { initialReIndexState, ReIndexState, reducer as reIndexReducer } from './re-index.reducer';
import { initialIndexingViewState, IndexingViewState, reducer as indexingViewStateReducer } from './indexing-view.reducer';

export interface IState {
  upload: UploadState;
  reIndex: ReIndexState;
  viewState: IndexingViewState;
}

export const initialState: IState = {
  upload: initialUploadState,
  reIndex: initialReIndexState,
  viewState: initialIndexingViewState
};

export const reducers: ActionReducerMap<IState> = {
  upload: uploadReducer,
  reIndex: reIndexReducer,
  viewState: indexingViewStateReducer
};

export const selectIndexingState = createFeatureSelector<IState>('indexing');
