import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

import { take } from 'rxjs/operators';

import { orderBy } from 'lodash-es';

import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { DialogService } from '@common/modules/shared/components/dialog/dialog.service';
import { GeneralCancelDialogButton } from '@common/modules/shared/actions';
import { UIActionType } from '@common/modules/insights/interfaces';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';

import { Customization_Error_Types } from '../../customization/interfaces';
import { CustomizationUtilsService } from './customization-utils.service';
import { IFileBlob } from '../../indexing/interfaces';
import { DeleteBrandModelAction } from '../../customization/components/brand/actions';
import { resources } from './resources';

@Injectable()
export class LanguageCustomizationService {
  public resources = resources;

  constructor(
    private customizationUtilsService: CustomizationUtilsService,
    private translateService: TranslateHelperService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private focusManager: FocusManagerService
  ) {
    this.translateService.translateResources(this.resources).pipe(take(1)).subscribe();
  }

  public handleLanguageError(error: HttpErrorResponse, model: Microsoft.VideoIndexer.Contracts.LanguageModel) {
    switch (error.error.ErrorType) {
      case Customization_Error_Types.NOT_FOUND:
        this.customizationUtilsService.displayErrorToast(error, { LanguageGetModelNotFoundError: '' }, { name: model.name || '' });
        break;
      case Customization_Error_Types.ALREADY_EXISTS:
        this.customizationUtilsService.displayErrorToast(error, { LanguageCreateModelAlreadyExistsError: '' }, { name: model.name });
        break;
      default:
        this.customizationUtilsService.displayErrorToast(error, { ErrorTypes_GENERAL: '' }, {});
    }
  }

  public handleLanguageFileError(error: HttpErrorResponse, currentFile: IFileBlob, downloadMode = false) {
    switch (error.error.ErrorType) {
      case Customization_Error_Types.ALREADY_EXISTS:
        this.customizationUtilsService.displayErrorToast(error, { UploadBoxFileAlreadyExistsError: '' }, { currentFileName: currentFile.name });
        break;
      case Customization_Error_Types.INVALID_INPUT:
        this.customizationUtilsService.displayErrorToast(error, { UploadBox_UploadFile_INVALID_INPUT: '' }, {});
        break;
      case Customization_Error_Types.NOT_FOUND:
        const errorResource = downloadMode ? { LanguageDownloadFileContentNotFoundError: '' } : { LanguageGetFileContentNotFoundError: '' };
        if (currentFile) {
          this.customizationUtilsService.displayErrorToast(error, errorResource, { value: currentFile.name });
        } else {
          // Cris from edits
          const transcriptEditName = { Language_From_Transcript_File: '' };
          this.translateService.translateResources({ Language_From_Transcript_File: '' }, {}).subscribe(() => {
            this.customizationUtilsService.displayErrorToast(error, errorResource, {
              value: transcriptEditName.Language_From_Transcript_File
            });
          });
        }
        break;
      default:
        this.customizationUtilsService.displayErrorToast(error, { ErrorTypes_GENERAL: '' }, {});
    }
  }

  public convertTranscriptEditToString(transcriptEdits: Microsoft.VideoIndexer.Contracts.TrainingDataFromEdits[], asHTML: boolean = false): string {
    let outputString = '';

    // Sort transcript edits by video id
    transcriptEdits = orderBy(
      transcriptEdits,
      (transcriptEdit: Microsoft.VideoIndexer.Contracts.TrainingDataFromEdits) => {
        return transcriptEdit.videoId;
      },
      ['desc']
    );

    let oldVideoId = '';
    transcriptEdits.forEach(transcriptEdit => {
      if (asHTML) {
        outputString += `<div class="video-edits edit-line_${transcriptEdit.videoId}">`;
        // eslint-disable-next-line max-len
        outputString +=
          transcriptEdit.videoId !== oldVideoId
            ? `<span class="video-header">${this.resources.Language_From_Edits_Header}: '${
                transcriptEdit.videoName ? transcriptEdit.videoName : this.resources.LabelUnknown
              }'</span>`
            : '';
        outputString +=
          transcriptEdit.videoId !== oldVideoId
            ? // eslint-disable-next-line max-len
              `<span class="video-header">${this.resources.LabelCrisFromEditsEditFrom}: ${this.datePipe.transform(
                transcriptEdit.createDate,
                'fullDate'
              )}</span>`
            : '';
        const valueAsDiff = this.getNewValueAsDiff(transcriptEdit.originalValue, transcriptEdit.currentValue, asHTML);
        outputString += `<span class="video-old-value">${valueAsDiff[0]}</span>`;
        outputString += `<span class="video-new-value">${valueAsDiff[1]}</span>`;
        outputString += '</div>';
      } else {
        outputString +=
          transcriptEdit.videoId !== oldVideoId
            ? `${this.resources.Language_From_Edits_Header}: '${transcriptEdit.videoName ? transcriptEdit.videoName : this.resources.LabelUnknown}'\n`
            : '';
        outputString +=
          transcriptEdit.videoId !== oldVideoId
            ? `${this.resources.LabelCrisFromEditsEditFrom}: ${this.datePipe.transform(transcriptEdit.createDate, 'fullDate')}\n`
            : '';
        const valueAsDiff = this.getNewValueAsDiff(transcriptEdit.originalValue, transcriptEdit.currentValue, asHTML);
        outputString += `${valueAsDiff[0]}\n`;
        outputString += `${valueAsDiff[1]}\n`;
      }

      oldVideoId = transcriptEdit.videoId;
    });

    return outputString;
  }

  public handelDeleteDialog(isModel = true, callBack: () => void) {
    const tempResources = {
      LanguageValidateVideoIndexingEmptyFileMsg: '',
      LanguageValidateVideoIndexingEmptyModelMsg: '',
      LanguageDeleteFileAction: '',
      LanguageModelDeleteModelHeader: ''
    };
    this.translateService
      .translateResources(tempResources, {})
      .pipe(take(1))
      .subscribe(() => {
        const primaryButton = {
          type: ActionButtonType.PRIMARY,
          action: DeleteBrandModelAction
        };
        const deleteModelDialog = this.dialogService.getDialogData(
          'delete-language-model-or-file-dialog',
          isModel ? tempResources.LanguageModelDeleteModelHeader : tempResources.LanguageDeleteFileAction,
          isModel ? tempResources.LanguageValidateVideoIndexingEmptyModelMsg : tempResources.LanguageValidateVideoIndexingEmptyFileMsg,
          GeneralCancelDialogButton,
          primaryButton
        );

        const dialogRef = this.dialogService.openDialog(deleteModelDialog, '440px');

        dialogRef.componentInstance.actionChange.pipe(take(1)).subscribe(res => {
          // After closing the dialog handle the action according to its type
          if (res && res.action && res.action.type === UIActionType.DELETE) {
            callBack();
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          // Add accessability
        });
      });
  }

  public handelModelAdding(success: boolean, ref: string) {
    setTimeout(() => {
      const buttonToFocus = success ? `#languageListItemMenu${ref}` : `#${ref}_languageAddBtn`;

      this.focusManager.focusByQuery(buttonToFocus);
    }, 10);
  }

  private getNewValueAsDiff(originValue: string, newValue: string, asHTML: boolean = false): string[] {
    const MARK_FOUND_AS_DIFF = '---found as diff---';
    let isSingleWord = false;
    let newAsDiff = '';
    let oldAsDiff = '';

    const arrNewAsDiff = [];
    const arrOldAsDiff = [];
    // If new value is empty
    if (newValue === '') {
      return [originValue, newValue];
    }

    // if old value is empty
    if (originValue === '') {
      return asHTML ? [originValue, `<span class="added-value">${newValue}</span>`] : [originValue, newValue];
    }

    // Parse both strings
    let arrOriginValue = originValue.split(' ');
    let arrNewValue = newValue.split(' ');

    // If both values are single word  -> its single word / Japanese / Chinese, split each character
    if (arrOriginValue.length === 1 && arrNewValue.length === 1) {
      arrOriginValue = originValue.split('');
      arrNewValue = newValue.split('');
      isSingleWord = true;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < arrNewValue.length; index++) {
      const newElement = arrNewValue[index];

      for (let originIndex = 0; originIndex < arrOriginValue.length; originIndex++) {
        const oldElement = arrOriginValue[originIndex];

        // Check if value exist on origin array
        if (newElement === oldElement) {
          // same value, no change
          arrNewAsDiff.push(newElement);
          arrOldAsDiff.push(oldElement);

          // Go to next element and remove the selected string from origin array
          arrOriginValue[originIndex] = MARK_FOUND_AS_DIFF;
          break;
        } else if (originIndex === arrOriginValue.length - 1) {
          // Different value
          // If we loop over all origin string and haven't found the new word, it was added
          arrNewAsDiff.push(asHTML ? `<span class="added-value">${newElement}</span>` : newElement);
        }
      }
    }

    // If there were strings that is not marked as diff, they were deleted
    for (let index = 0; index < arrOriginValue.length; index++) {
      const element = arrOriginValue[index];

      if (element !== MARK_FOUND_AS_DIFF) {
        // Push to the old as diff array the string as deleted
        arrOldAsDiff.splice(index, 0, asHTML ? `<span class="removed-value">${element}</span>` : element);
      }
    }

    newAsDiff = isSingleWord ? arrNewAsDiff.join('') : arrNewAsDiff.join(' ');
    oldAsDiff = isSingleWord ? arrOldAsDiff.join('') : arrOldAsDiff.join(' ');

    return [oldAsDiff, newAsDiff];
  }
}
