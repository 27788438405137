import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { combineLatestWith, take, takeUntil } from 'rxjs/operators';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { AppNavigationService } from '@common/modules/core/services/app/app-navigation.service';
import { FeatureSwitch, NavigationState, SideMenuOption, SideSubMenuType } from '@common/modules/core/services/interfaces';
import { DialogComponent } from '@common/modules/shared/components/dialog/dialog.component';
import { IDialogButton, IDialogData } from '@common/modules/shared/components/dialog/interfaces';
import { IAction } from '@common/modules/shared/interfaces';
import { FocusManagerService } from '@common/modules/accessibility/focus-manager.service';
import { AuthService } from '@common/modules/auth/services/auth.service';
import { UtilsService } from '@common/modules/shared/services/utils.service';
import { ThemesService } from '@common/modules/core/services/themes/themes.service';
import { ActionButtonType } from '@common/modules/shared/components/action-button/interfaces';
import { PageTimingService } from '@common/modules/core/services/page-timing/service/page-timing.service';
import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';
import { FeatureSwitchService } from '@common/modules/core/services/feature-switch/feature-switch.service';

// eslint-disable-next-line max-len
import { CoreStoreService } from '../core/services/core-store.service';
import { resources } from './resources';
import * as fromRouter from '../core/reducers/router';
import { HeaderPreset } from '../header/components/header/interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  public progressBarColor = 'var(--highlight)';
  public ready = false;
  public resources = resources;
  public isUploadShow = false;
  public isAuthenticated = false;
  public isSideMenuOpened = false;
  public isSubMenuOpened = false;
  public subMenuType: SideSubMenuType;
  public sideMenuEnabled = false;
  public stripShow = false;
  public isMobile = false;
  public userDetailsError: Observable<boolean>;
  public isZeroAccounts$: Observable<boolean>;
  public isNewUploadExperienceEnabled = false;
  public headerPreset: HeaderPreset = HeaderPreset.FULL;
  public showSideMenu: boolean = true;

  public readonly HeaderPreset = HeaderPreset;
  public readonly MAIN_ROLE = 'main';
  public readonly NO_ROLE = 'none';

  private dialogRef;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly routerStore: Store<fromRouter.IState>,
    private logger: LoggerService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateHelperService,
    private navigationService: AppNavigationService,
    private coreStore: CoreStoreService,
    private focusManager: FocusManagerService,
    private dialog: MatDialog,
    private authService: AuthService,
    private utilsService: UtilsService,
    private themesService: ThemesService,
    private pageTiming: PageTimingService,
    private featureSwitchService: FeatureSwitchService,
    private toastrService: ToastrService
  ) {}

  public get subMenuTypeClass() {
    return `${this.subMenuType?.toLocaleLowerCase()}-sub-menu`;
  }

  public ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.pageTiming.init();
    this.themesService.initThemes();
    this.isMobile = this.utilsService.isMobile();
    this.isNewUploadExperienceEnabled = this.featureSwitchService.featureSwitch(FeatureSwitch.NewUploadExperience);
    this.authService
      .isUserAuthenticated()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUserAuthenticated: boolean) => {
        this.isAuthenticated = isUserAuthenticated;
        this.cdr.detectChanges();
      });

    this.routerStore
      .select(fromRouter.getRouterState)
      .pipe(takeUntil(this.destroy$))
      .subscribe(routerState => {
        this.showSideMenu = routerState?.state?.data?.showHeaderButtons !== false;
        this.headerPreset = routerState?.state?.data?.showHeaderButtons !== false ? HeaderPreset.FULL : HeaderPreset.LEAN;
      });

    this.ready = !this.authService.isAuthenticated();
    this.userDetailsError = this.coreStore.userDetailsError$.pipe(takeUntil(this.destroy$));
    this.isZeroAccounts$ = this.coreStore.isZeroAccounts$.pipe(takeUntil(this.destroy$));
    this.coreStore.coreMetadataLoaded$.pipe(take(2)).subscribe(isLoaded => {
      if (isLoaded) {
        this.addListeners();
        this.isAuthenticated = this.authService.isAuthenticated();
        this.ready = true;
      }
    });

    this.coreStore
      .selectSideMenuSelectedOption()
      .pipe(combineLatestWith(this.coreStore.isExtensionsSubMenuOpened$))
      .subscribe(([sideMenuOption, isExtensionsSubMenuOpened]) => {
        const isCustomizationSubMenuOpened = sideMenuOption === SideMenuOption.Customizations;
        this.isSubMenuOpened = isExtensionsSubMenuOpened || isCustomizationSubMenuOpened;
        this.subMenuType = isExtensionsSubMenuOpened ? SideSubMenuType.Extensions : SideSubMenuType.Customizations;
      });

    this.coreStore.selectSideMenuOpen().subscribe(isOpen => {
      this.isSideMenuOpened = isOpen;
      this.cdr.detectChanges();
      this.logger.log('[AppComponent] sideMenuEnabled open:', this.isSideMenuOpened);
    });

    this.setTranslations();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public addListeners() {
    // Open connect to azure window
    this.navigationService.createAccountSubject.subscribe((options: NavigationState) => {
      if (options && options === NavigationState.OPEN) {
        this.openConnectToAzure();
      }
    });
  }

  public handleShowAbout() {
    this.openAbout();
  }

  public async openConnectToAzure() {
    const emptyPrimary: IAction = Object.create(null);

    const primaryButton: IDialogButton = {
      type: ActionButtonType.PRIMARY,
      action: emptyPrimary
    };

    const onCloseCallBack = (): void => {
      this.focusManager.focusByQuery(`#connectToAzureButton`, this.cdr);
    };

    const { CreateArmAccountDialogComponent } = await import(
      '../shell/connect-to-azure/components/create-arm-account-dialog/create-arm-account-dialog.component'
    );

    const dialogData: IDialogData = {
      class: 'connect-to-azure-dialog',
      component: CreateArmAccountDialogComponent
    };

    this.openDialog(dialogData, '580px', '', 'create-arm-account-container', '95vh', onCloseCallBack);
  }

  private setTranslations(): void {
    this.translate.translateResourcesInstant(resources);
    this.cdr.detectChanges();
  }

  private async openAbout() {
    // lazy load about components
    const { AboutPageComponent, AboutHeaderComponent } = await import('../about');

    const aboutDialogData: IDialogData = {
      class: 'about-dialog',
      component: AboutPageComponent,
      headerComponent: AboutHeaderComponent
    };
    this.openDialog(aboutDialogData, '818px', '', 'about-dialog-container');
  }

  private openDialog(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogData: IDialogData,
    width: string,
    height?: string,
    panelClass?: string,
    maxHeight?: string,
    onCloseCallBack?: () => void
  ) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      width: width,
      height: height,
      maxHeight: maxHeight,
      data: dialogData,
      disableClose: false,
      ariaLabel: dialogData.title,
      panelClass: panelClass
    });

    if (onCloseCallBack) {
      this.dialogRef.afterClosed().subscribe(() => {
        onCloseCallBack();
      });
    }
  }
}
