import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, NavigationActionTiming, RouterState } from '@ngrx/router-store';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { storeFreeze } from 'ngrx-store-freeze';
import { ToastrModule } from 'ngx-toastr';

import { AccessibilityModule } from '@common/modules/accessibility/accessibility.module';
import { AuthModule } from '@common/modules/auth/auth.module';
import { CommonCoreModule } from '@common/modules/core/common.core.module';
import { EnsureModuleLoadedOnceGuard } from '@common/modules/core/ensureModuleLoadedOnceGuard';
import { LoggerService } from '@common/modules/core/services/logger/logger.service';
import { InsightsCommonModule } from '@common/modules/insights-common/insights-common.module';
import { NotificationsModule } from '@common/modules/notifications/notifications.module';

import { AccountsEffects } from './effects/accounts.effects';
import { AmsMigrationEffects } from './effects/ams-migration.effects';
import { CoreEffects } from './effects/core.effects';
import { DirectoriesEffects } from './effects/directories.effects';
import { EdgeExtensionsEffects } from './effects/edge-extensions.effects';
import { InvitationsEffects } from './effects/invitations.effect';
import { NotificationsEffects } from './effects/notifications.effects';
import { RoleAssignmentsEffects } from './effects/roleAssignments.effect';
import { AppRouterEffects } from './effects/router.effects';
import { SideMenuEffects } from './effects/side-menu.effect';
import { UserEffects } from './effects/user.effects';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoadChunksErrorHandler } from './handlers/load-chunks-error.handler';
import { reducers } from './reducers';
import { routerReducers } from './reducers/router';
import { VIRouterSerializer } from './reducers/router/vi-router-serialzer';
import { AuthenticatedResolverService } from './resolvers/authenticated.resolver';
import { MediaMetadataResolverService } from './resolvers/media-metadata.resolver';
import { RouteResolverService } from './resolvers/route.resolver';
import { UserMetadataResolverService } from './resolvers/user-metadata.resolver';
import { CoreStoreService } from './services/core-store.service';
import { NotificationsHandlerService } from './services/notifications-handler.service';
import { NotificationsStoreService } from './services/notifications-store.service';
import { SurveyService } from './services/survery.service';
import { UserService } from './services/user.service';
import { environment } from '../../../../environments/environment';
import { CustomizationDataModule } from '../customization-data/customization-data.module';
import { HeaderModule } from '../header/header.module';
import { IndexingModule } from '../indexing/indexing.module';
import { MediaDataModule } from '../media-data/media-data.module';
import { ShellModule } from '../shell/shell.module';
import { SidebarModule } from '../sidebar/sidebar.module';
import { EdgeExtensionsStoreService } from './services/edge-extensions-store.service';
import { EdgeExtensionsApiHandlerService } from './services/edge-extensions-api.handler.service';
import { EdgeExtensionsMediaMetadataResolverService } from './resolvers/edge-extensions-media-metadata.resolver';
import { RouteSelectAccountResolverService } from './resolvers/route-select-account.resolver';
import { GalleryStoreService } from '../gallery/services/gallery-store.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const metaReducers: Array<MetaReducer<any>> = !environment.production ? [storeFreeze] : [];

@NgModule({
  imports: [
    AuthModule,
    ToastrModule.forRoot(),
    CommonCoreModule,
    StoreModule.forRoot(routerReducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: VIRouterSerializer,
      navigationActionTiming: NavigationActionTiming.PreActivation,
      routerState: RouterState.Minimal
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: false,
          features: {
            pause: false,
            lock: true,
            persist: true
          },
          connectInZone: true
        })
      : [],
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature([
      CoreEffects,
      UserEffects,
      AppRouterEffects,
      AccountsEffects,
      SideMenuEffects,
      InvitationsEffects,
      RoleAssignmentsEffects,
      NotificationsEffects,
      DirectoriesEffects,
      EdgeExtensionsEffects,
      AmsMigrationEffects
    ]),
    CustomizationDataModule,
    MediaDataModule,
    AccessibilityModule,
    NotificationsModule
  ],
  exports: [
    HeaderModule,
    IndexingModule,
    InsightsCommonModule, // not needed here
    SidebarModule,
    LoadingBarRouterModule,
    ShellModule
  ],
  declarations: [],
  providers: [
    CoreStoreService,
    NotificationsStoreService,
    EdgeExtensionsStoreService,
    UserMetadataResolverService,
    RouteResolverService,
    AuthenticatedResolverService,
    MediaMetadataResolverService,
    EdgeExtensionsMediaMetadataResolverService,
    RouteSelectAccountResolverService,
    UserService,
    AuthGuardService,
    SurveyService,
    { provide: ErrorHandler, useClass: LoadChunksErrorHandler },
    NotificationsHandlerService,
    EdgeExtensionsApiHandlerService,
    GalleryStoreService
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private logger: LoggerService) {
    super(parentModule);
    this.logger.log('[CoreModule] init');
  }
}
