import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NO_ACCESS_SVG } from '@common/modules/shared/svg-shapes';

@Component({
  selector: 'vi-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() shouldShowIcon: boolean = true;
  @Input() svgIcon = NO_ACCESS_SVG;
  @Input() iconHeight = 70;
  @Input() iconWidth = 44;
  @Input() linkText: string;
  @Input() linkPath: string;

  constructor() {}
}
