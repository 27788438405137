import { createAction, props } from '@ngrx/store';

export const clearLanguageIdDatasets = createAction('[LanguageID] Clear LanguageId datasets');

export const loadSelectedLanguages = createAction('[LanguageID] Load Selected AutoDetect Languages');

export const setSelectedLanguages = createAction(
  '[LanguageID] Set Selected AutoDetect Languages',
  props<{ selectedLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[] }>()
);

export const deleteSelectedLanguage = createAction(
  '[LanguageID] Delete Selected Language Id',
  props<{ languageCode: Microsoft.VideoIndexer.Contracts.LanguageV2 }>()
);

export const deleteSelectedLanguageSuccess = createAction('[LanguageID] Delete Selected Language Id Success');

export const addSelectedLanguage = createAction(
  '[LanguageID] Add Selected Language Id',
  props<{ selectedLanguage: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract }>()
);

export const loadDefaultLanguages = createAction('[LanguageID] Load Default AutoDetect Languages');

export const upsertDefaultLanguages = createAction(
  '[LanguageID] Upsert Default AutoDetect Languages',
  props<{ defaultLanguages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[] }>()
);
