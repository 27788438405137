import { ITimeable, IPageTimingOptions, IPageTimingResult } from './interfaces';
import { NavigationTiming, PaintTiming, ResourceTiming } from './plugins';
import { getPerformanceApi } from './utils';

export class PageTiming {
  private plugins: { [name: string]: ITimeable } = {};

  constructor(private options: IPageTimingOptions) {
    if (!this.isSupported) {
      return;
    }

    const { resourceTimingOptions, paintTimingOptions, navigationTimingOptions } = options;

    if (navigationTimingOptions) {
      this.plugins.navigation = new NavigationTiming(navigationTimingOptions);
    }

    if (resourceTimingOptions) {
      this.plugins.resource = new ResourceTiming(resourceTimingOptions);
    }

    if (paintTimingOptions) {
      this.plugins.paint = new PaintTiming(paintTimingOptions);
    }
  }

  public collect() {
    const noop = _ => null;
    window.addEventListener('load', () => {
      // timeout is used to to let the Performance API finish calculating all timings
      setTimeout(() => {
        (this.options.onTimingsCollected || noop)(this.getTimings());
      });
    });
  }

  public get isSupported() {
    return !!this.performance;
  }

  public getTimings(): Partial<IPageTimingResult> {
    if (!this.isSupported) {
      return {};
    }

    return Object.keys(this.plugins).reduce(
      (acc, plugin) => ({
        ...acc,
        // For better seperation of each plugin, data will be nested under a dedicated key
        [plugin]: this.plugins[plugin].getTimings()
      }),
      {}
    );
  }

  private get performance(): Performance {
    return getPerformanceApi();
  }
}
