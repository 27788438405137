import { Injectable } from '@angular/core';

import { AccountPermission } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor() {}

  public get accessLevels() {
    return {
      [AccountPermission.OWNER]: 0,
      [AccountPermission.CONTRIBUTOR]: 1,
      [AccountPermission.READER]: 1,
      [AccountPermission.RESTRICTED_VIEWER]: 2
    };
  }

  public isValidPermission(permission: AccountPermission) {
    return this.accessLevels.hasOwnProperty(permission);
  }

  public ensurePermission(permission: AccountPermission, requiredPermission: AccountPermission) {
    if (!this.isValidPermission(permission) || !this.isValidPermission(requiredPermission)) {
      return false;
    }
    return this.accessLevels[permission] <= this.accessLevels[requiredPermission];
  }
}
