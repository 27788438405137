<div class="container"
  *ngIf="!!unknownPerson"
  [ngClass]="{'updating': data.updating$ | async }">
  <div class="row actions">
    <div class="action-bar">
      <app-vi-action-button *ngFor="let action of barActions"
        [responsiveState]="'customization-responsive'"
        [action]="action"
        [isDisabled]="action?.isDisabled || (data.updating$ | async) === true"
        (actionClick)="onActionBarClicked($event)">
      </app-vi-action-button>
    </div>
  </div>

  <div class="main">
    <div *ngIf="state.moveToModel"
      class="row move-to-model-form">
      <vi-ui-label [required]="true"
        [for]="'personNameInput'"
        [disabled]="(data.updating$ | async) === true"
        [value]="resources?.UnknownPersonDialogPersonNameTitle"></vi-ui-label>
      <p class="person-name-desc">{{ resources?.UnknownPersonDialogNamePersonDescription }}</p>
      <vi-ui-input class="person-name"
        [id]="'personNameInput'"
        [value]="state.personName"
        [disabled]="(data.updating$ | async) === true"
        [required]="false"
        (inputChanged)="state.personName=$event">
      </vi-ui-input>
    </div>
    <div>
      <div *ngIf="!state.editMetadata && state.metadata?.length"
        class="row metadata">
        <vi-ui-label [value]="resources?.Metadata"></vi-ui-label>
        <p>{{unknownPerson?.metadata}}</p>
      </div>
      <!-- @TODO: Enable when BE add support for PATCH API -->
      <div *ngIf="false && state.editMetadata"
        class="row metadata">
        <vi-ui-textarea [value]="state.metadata"
          [height]="METADATA_INPUT_HEIGHT"
          [label]="resources?.Metadata"
          [maxLength]="METADATA_MAX_LENGTH"
          (inputChanged)="state.metadata=$event">
        </vi-ui-textarea>
        <div class="metadata-actions">
          <app-vi-action-button [action]="saveAction"
            [isDisabled]="!canSaveMetadata"
            [type]="ActionButtonType.PRIMARY"
            [minWidth]="METADATA_BUTTON_WIDTH"
            (actionClick)="saveMetadata()">
          </app-vi-action-button>
          <app-vi-action-button [action]="cancelAction"
            [type]="ActionButtonType.SECONDARY"
            [minWidth]="METADATA_BUTTON_WIDTH"
            (actionClick)="resetMetadata($event)">
          </app-vi-action-button>
        </div>
      </div>
    </div>

    <div class="row faces">
      <vi-ui-label [disabled]="(data.updating$ | async) === true"
        [value]="'UnknownPersonDialogFacesTitle' | translate : { number: faces.length}"></vi-ui-label>
      <div class="faces-container">
        <app-actions-menu class="row"
          *ngFor="let face of loadedFaces"
          id="face_{{face.id}}"
          [calcWidth]="false"
          [action]="face"
          [actionList]="facesActions"
          [isDisabled]="(data.updating$ | async) === true"
          [sizeClass]="'left'"
          [fallbackUrl]="fallbackUrl"
          (actionChange)="onFacesActionClicked($event, face)">
        </app-actions-menu>

        <app-vi-action-button *ngIf="showLoadMoreFaces"
          class="load-more-faces"
          [minWidth]="'68px'"
          [action]="loadFacesAction"
          [type]="ActionButtonType.SECONDARY"
          (actionClick)="loadMoreFaces()">
        </app-vi-action-button>
      </div>
    </div>
  </div>

  <section class="footer">
    <div *ngIf="(data.updating$ | async) === true">
      <span class="spinner-container">
        <app-vi-spinner></app-vi-spinner>
      </span>
      {{ resources?.UnknownPersonDialogSpinnerTitle }}
    </div>
    <div class="buttons-container">
      <app-vi-action-button *ngIf="state.moveToModel"
        class="primary"
        [action]="moveToModelAction"
        [isDisabled]="!canMoveToModel || (data.updating$ | async) === true"
        [type]="ActionButtonType.PRIMARY"
        [minWidth]="CANCEL_BUTTON_MIN_WIDTH"
        (actionClick)="moveToModel($event)">
      </app-vi-action-button>
      <app-vi-action-button [action]="cancelAction"
        [type]="ActionButtonType.SECONDARY"
        [isDisabled]="(data.updating$ | async) === true"
        [minWidth]="PRIMARY_BUTTON_MIN_WIDTH"
        (actionClick)="close($event)">
      </app-vi-action-button>
    </div>
  </section>
</div>
<div class="spinner-container"
  *ngIf="!unknownPerson">
  <app-vi-spinner></app-vi-spinner>
</div>