import { ITimeable, ITimingResult } from '../interfaces';
import { getPerformanceApi } from '../utils';

export abstract class BaseTiming implements ITimeable {
  constructor() {}

  get performance(): Performance {
    return getPerformanceApi();
  }

  public abstract getTimings(): ITimingResult;
}
