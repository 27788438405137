import { createSelector } from '@ngrx/store';

import { selectIndexingState } from '../reducers';

export const selectReIndexState = createSelector(selectIndexingState, state => state.reIndex);
export const isIndexingSettingLoaded = createSelector(selectReIndexState, state => state.isIndexingSettingsLoaded);
export const isReIndexProcessing = createSelector(selectReIndexState, state => state.isProcessing);
export const selectVideoId = createSelector(selectReIndexState, state => state.videoId);
export const selectVideoIndex = createSelector(selectReIndexState, state => state.videoIndex);
export const selectOriginalLanguageId = createSelector(selectReIndexState, state => state?.originalLanguageId);
export const selectReIndexErrorType = createSelector(selectReIndexState, state => state?.reIndexErrorType);
export const selectReviewState = createSelector(selectReIndexState, state => state?.reviewState);
export const selectModerationState = createSelector(selectReIndexState, state => state?.moderationState);
export const selectReIndexDisplayedPage = createSelector(selectReIndexState, state => state.displayedReIndexPage);
