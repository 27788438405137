export interface ISignInURLs {
  base: string;
  returnUrl: string;
  consent: string;
}

export type IVendorWindowSizes = {
  [Provider in LoginProvider]: IWindowSize;
};

export interface IWindowSize {
  width: number;
  height: number;
}

export enum LoginProvider {
  OpenIdConnect = 'OpenIdConnect',
  Microsoft = 'Microsoft',
  LinkedIn = 'LinkedIn',
  Google = 'Google',
  Facebook = 'Facebook'
}
