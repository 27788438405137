export enum ProgressBarMode {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate',
  BUFFER = 'buffer',
  QUERY = 'query'
}

export enum ProgressStyle {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}
