import { IFile } from '../interfaces';

export const MAX_VIDEO_NAME_LENGTH = 80;

/**
 * Check if url is valid
 * @param url
 * @returns
 */
export const isValidURL = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Get file name from url
 * Supports url with query params
 * @param url
 * @returns file name if found, empty string otherwise
 */
export const getFileNameFromURL = (url: string): string => {
  if (!url) {
    return '';
  }
  const parts = url.split('?');
  const fileName = parts[0].split('/').pop();
  const fileArr = fileName.split('.');
  if (fileArr.length > 1) {
    return decodeURI(fileArr.slice(0, -1).join('.')).trim().substring(0, MAX_VIDEO_NAME_LENGTH);
  }
  return '';
};

export const getDefaultFileName = (fileList: IFile[], prefix: string) => {
  let maxIndex = 0;
  for (const file of fileList) {
    const fileName = file.name;
    const match = fileName.match(new RegExp(`${prefix} (\\d+)`));
    if (match) {
      const index = parseInt(match[1], 10);
      maxIndex = Math.max(maxIndex, index);
    }
  }

  const newIndex = maxIndex + 1;
  const defaultFileName = `${prefix} ${newIndex}`;

  return defaultFileName;
};
/**
 * Get file name from full file name with extension
 * @param fullFileName
 * @returns
 */
export const getFileName = (fullFileName: string): string => {
  if (!fullFileName) {
    return '';
  }

  const fileArr = fullFileName.split('.');
  let fileName;
  if (fileArr.length === 1) {
    fileName = fileArr[0];
  } else {
    fileName = fileArr.slice(0, -1).join('.');
  }
  return fileName.trim().substring(0, MAX_VIDEO_NAME_LENGTH);
};
