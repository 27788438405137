import { UIGalleryActionType } from '../../../../apps/web/src/gallery/core/models/gallery';
import { IAction } from '../interfaces';

export const videoSearchOptions: IAction[] = [
  {
    icon: '',
    title: '',
    key: 'GalleryServiceEntireVideo',
    type: UIGalleryActionType.ALL_INSIGHTS_FILTER,
    value: 'All',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceFileNameOnly',
    type: UIGalleryActionType.FILE_NAME_FILTER,
    value: 'name',
    // in case the UI value is different than the API value (e.g. 'name' vs 'file name')
    label: 'file name',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceTagsOnly',
    type: UIGalleryActionType.CUSTOM_TAGS_FILTER,
    value: 'metadata',
    // in case the UI value is different than the API value (e.g. 'name' vs 'file name')
    label: 'tag',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceDetectedObjectsOnly',
    type: UIGalleryActionType.DETECTED_OBJECTS_FILTER,
    value: 'detectedObjects',
    label: 'Detected objects',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceVisualTextOnly',
    type: UIGalleryActionType.OCR_FILTER,
    value: 'ocr',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceTranscriptOnly',
    type: UIGalleryActionType.TRANSCRIPT_FILTER,
    value: 'transcript',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceAnnotationsOnly',
    type: UIGalleryActionType.LABELS_FILTER,
    value: 'annotations',
    label: 'labels',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceNamedLocationsEntitiesOnly',
    type: UIGalleryActionType.NAMED_LOCATIONS_FILTER,
    value: 'namedLocations',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceNamedPeopleEntitiesOnly',
    type: UIGalleryActionType.NAMED_PEOPLE_FILTER,
    value: 'namedPeople',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'GalleryServiceBrandsEntitiesOnly',
    type: UIGalleryActionType.BRANDS_FILTER,
    value: 'brands',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  },
  {
    icon: '',
    title: '',
    key: 'TopicsOnly',
    type: UIGalleryActionType.TOPICS_FILTER,
    value: 'topics',
    selected: false,
    selectable: true,
    hasDrop: false,
    id: '',
    applyValue: true
  }
];
