import { Injectable } from '@angular/core';

import { SessionStorageService } from '../../shared/services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(private sessionStorageService: SessionStorageService) {}

  public get(key: string) {
    return this.sessionStorageService.getItem(key);
  }

  public set(key, val) {
    this.sessionStorageService.setItem(key, val);
  }

  public removeAll() {
    this.sessionStorageService.clear();
  }

  public removeAllAccessTokens() {
    for (const element in this.sessionStorageService.storage) {
      if (element.includes('/accessToken')) {
        this.sessionStorageService.removeItem(element);
      }
    }
  }

  public removeUserAccessTokens() {
    for (const element in this.sessionStorageService.storage) {
      if (element.includes('/users/me/accessToken')) {
        this.sessionStorageService.removeItem(element);
      }
    }
  }

  public removeItem(key: string) {
    this.sessionStorageService.removeItem(key);
  }
}
