/* eslint-disable max-len */
export const NO_ACCESS_SVG =
  'M6,16C6,7.2,13.2,0,22,0s16,7.2,16,16v8h-6v-8c0-5.5-4.5-10-10-10s-10,4.5-10,10v8H6V16z M0,28c0-1.1,0.9-2,2-2h40c1.1,0,2,0.9,2,2v32c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V28z M24.3,46.5c2.2-0.9,3.7-3,3.7-5.5c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,2.5,1.5,4.6,3.7,5.5L17,53h10L24.3,46.5z';
export const FILE_UPLOAD_SVG =
  'M44,12H34c-1.1,0-2-0.9-2-2V0L44,12z M34,14c-2.2,0-4-1.8-4-4V0H2C0.9,0,0,0.9,0,2v52c0,1.1,0.9,2,2,2h40c1.1,0,2-0.9,2-2V14H34z M22,41c-7.2,0-13-5.8-13-13s5.8-13,13-13s13,5.8,13,13S29.2,41,22,41z M22.7,20.3l6.4,6.4c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L23,23.4V36c0,0.6-0.4,1-1,1s-1-0.4-1-1V23.4l-4.7,4.7c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l6.4-6.4C21.7,19.9,22.3,19.9,22.7,20.3z';
export const NOTIFICATIONS_EMPTY_STATE_SVG_PATH1 =
  'M76.9002 63.3101C75.5802 62.6901 74.4502 61.7401 73.6002 60.5701C73.1502 59.9501 72.9402 59.2001 72.9402 58.4401V43.4601C72.9402 32.2601 65.1002 22.4701 54.3002 20.4001C53.3702 19.0201 51.7902 18.1201 50.0002 18.1201C48.2102 18.1201 46.6302 19.0301 45.7002 20.4101C35.1202 22.4401 27.0702 31.7901 27.0702 42.9301V58.4301C27.0702 59.1901 26.8602 59.9501 26.4102 60.5601C25.5602 61.7301 24.4302 62.6701 23.1102 63.3001C22.3102 63.6701 21.7902 64.5601 21.9902 65.5401C22.1802 66.4801 23.0702 67.1201 24.0302 67.1201H75.9902C76.9502 67.1201 77.8402 66.4801 78.0302 65.5401C78.2302 64.5601 77.7102 63.6701 76.9102 63.3001L76.9002 63.3101Z';
export const NOTIFICATIONS_EMPTY_STATE_SVG_PATH2 =
  'M50 76.2499C54.38 76.2499 58.03 73.2099 58.99 69.1299H41C41.96 73.2099 45.62 76.2499 49.99 76.2499H50Z';
export const ADD_NEW_PATH_1 =
  'M75 25H25V75H53.6085C52.5781 72.8856 52 70.5103 52 68C52 59.1634 59.1634 52 68 52C70.5103 52 72.8856 52.5781 75 53.6085V25Z';
export const ADD_NEW_PATH_2 =
  'M75 55.8729C72.9408 54.6818 70.55 54 68 54C60.268 54 54 60.268 54 68C54 70.55 54.6818 72.9408 55.8729 75C58.2936 79.1846 62.818 82 68 82C75.732 82 82 75.732 82 68C82 62.818 79.1846 58.2936 75 55.8729ZM69 63C69 62.4477 68.5523 62 68 62C67.4477 62 67 62.4477 67 63V67H63C62.4477 67 62 67.4477 62 68C62 68.5523 62.4477 69 63 69H67V73C67 73.5523 67.4477 74 68 74C68.5523 74 69 73.5523 69 73V69H73C73.5523 69 74 68.5523 74 68C74 67.4477 73.5523 67 73 67H69V63Z';
export const ERROR_SVG =
  'M50.6667 14.3V8L46 15.7H49.3333V22L54 14.3H50.6667ZM38 26H44V34H56V26H62V34H74V37C74 39.7614 71.7614 42 69 42H31C28.2386 42 26 39.7614 26 37V34H38V26ZM40.6667 16L41.3333 18.6667L40 19.3333L43.3333 22L42.6667 19.3333L44 18.6667L40.6667 16ZM58.6667 18.6667L59.3333 16L56 18.6667L57.3333 19.3333L56.6667 22L60 19.3333L58.6667 18.6667ZM70 56C70 66.7104 61.5811 75.4544 51 75.9754V79C51 79.5523 50.5523 80 50 80C49.4477 80 49 79.5523 49 79V75.9754C38.4189 75.4544 30 66.7104 30 56V44H70V56ZM50 66C53.3137 66 56 63.3137 56 60C56 56.6863 53.3137 54 50 54C46.6863 54 44 56.6863 44 60C44 63.3137 46.6863 66 50 66Z';
export const NO_RESULTS =
  'M19 20H38L44 25H81C82.1046 25 83 25.8954 83 27V36H44L37 30H17V22C17 20.8954 17.8954 20 19 20ZM36 32H17V74C17 75.1046 17.8954 76 19 76H81C82.1046 76 83 75.1046 83 74V38H43L36 32ZM43 55C43 56.6569 41.6569 58 40 58C38.3431 58 37 56.6569 37 55C37 53.3431 38.3431 52 40 52C41.6569 52 43 53.3431 43 55ZM53 55C53 56.6569 51.6569 58 50 58C48.3431 58 47 56.6569 47 55C47 53.3431 48.3431 52 50 52C51.6569 52 53 53.3431 53 55ZM60 58C61.6569 58 63 56.6569 63 55C63 53.3431 61.6569 52 60 52C58.3431 52 57 53.3431 57 55C57 56.6569 58.3431 58 60 58Z';
