export enum UIShellActionType {
  AZURE_SUBSCRIPTION = 'AZURE_SUBSCRIPTION',
  ACCOUNT_REGION = 'ACCOUNT_REGION',
  AMS_ACCOUNT = 'AMS_ACCOUNT',
  SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION',
  SELECT_LOCATION = 'SELECT_LOCATION',
  SELECT_SUBSCRIPTION_RESOURCE = 'SELECT_SUBSCRIPTION_RESOURCE',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  SELECT_THEME = 'SELECT_THEME'
}

export enum Error_Types {
  USER_NOT_SIGNED_IN = 'USER_NOT_SIGNED_IN',
  INVALID_INPUT = 'INVALID_INPUT',
  AMS_AAD_APPLICATION_NOT_IN_TENANT = 'AMS_AAD_APPLICATION_NOT_IN_TENANT',
  AMS_AAD_INVALID_APPLICATION_KEY = 'AMS_AAD_INVALID_APPLICATION_KEY',
  AMS_INVALID_AAD_CONNECTION = 'AMS_INVALID_AAD_CONNECTION',
  AMS_IN_ANOTHER_LOCATION = 'AMS_IN_ANOTHER_LOCATION'
}

export enum SideMenuWidthSize {
  Opened = 216,
  Closed = 49
}

export enum BadgeClasses {
  Trial = 'trial-badge',
  Classic = 'classic-badge',
  Hybrid = 'hybrid-badge',
  NoBadge = 'no-badge'
}

export const EXTENSIONS_SUB_MENU_WIDTH = 212;
