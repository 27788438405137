import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IItemExpanded } from './interfaces';

@Component({
  selector: 'app-expanded-item',
  templateUrl: './expanded-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./expanded-item.component.scss']
})
export class ExpandedItemComponent implements OnInit {
  @Input() public item: IItemExpanded;
  @Input() public expanded = false;

  constructor() {}

  public ngOnInit() {}
}
