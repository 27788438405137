import { Type } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

export interface ITableColumnConfig {
  name: string;
  key: string;
  width?: string;
  padding?: string;
  component?: Type<object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentInputs?: { [key: string]: (item) => any };
  sortable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortMethod?: (a: any, b: any) => number;
}

export enum ITableStyleType {
  DEFAULT = 'default',
  COMPACT = 'compact'
}

export interface ITableSort {
  key: string;
  direction: SortDirection;
}

export interface ISelectionState<T> {
  item: T;
  isSelected: boolean;
}
