import { createSelector } from '@ngrx/store';

import { selectGalleryState } from '../reducers';

export const getSearchState = createSelector(selectGalleryState, state => state.search);

export const isSearchLoading = createSelector(getSearchState, state => state.loading);

export const isSearching = createSelector(getSearchState, state => state.searching);

export const isSearchNextPageDone = createSelector(getSearchState, state => state.nextPage.done);

export const selectSearchResults = createSelector(getSearchState, state => state.entities);

export const selectSearchParams = createSelector(getSearchState, state => state.params);

export const selectSearchResultsVideos = createSelector(selectSearchResults, videos => Object.values(videos));

export const getSearchStateNextPage = createSelector(getSearchState, state => state.nextPage);

export const isSearchStateNextPageDone = createSelector(getSearchStateNextPage, nextPage => nextPage.done);

export const isSearchNextPageLoading = createSelector(getSearchState, state => state.loadingNext);

export const isSearchError = createSelector(getSearchState, state => state?.error);
