export const resources = {
  IndexingGeneralSettings: '',
  IndexingFileNamesSettings: '',
  IndexingIndexingPresetsSettings: '',
  IndexingPeopleModelsSettings: '',
  IndexingBrandCategoriesSettings: '',
  IndexingLogoGroupsSettings: '',
  IndexingBatchInfoSettings: '',
  IndexingFileInfoSettings: '',
  IndexingBackToBasicButton: '',
  IndexingAdvancedSettingsButton: ''
};
