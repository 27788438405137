import { createSelector } from '@ngrx/store';

import { map } from 'lodash-es';

import { selectCoreState } from '../reducers';

export const getBrandModelsState = createSelector(selectCoreState, state => state.brandModels);

export const getBrands = createSelector(getBrandModelsState, state => map(state.entities, entity => ({ ...entity })));

export const getBrandsCount = (enabled: boolean) => createSelector(getBrands, brands => brands.filter(b => b.enabled === enabled).length);

export const getBrandsByType = (enabled: boolean) => createSelector(getBrands, brands => brands.filter(b => b.enabled === enabled));

export const getBrandsIsSaving = (enabled: boolean) =>
  createSelector(getBrandModelsState, state => (enabled ? state.savingIncluded : state.savingExcluded));

export const getBrandsSettings = createSelector(getBrandModelsState, state => state.settings);

export const getBrandsError = createSelector(getBrandModelsState, state => state.error);

export const isBrandModelsLoaded = createSelector(getBrandModelsState, state => state.loaded);

export const isBrandModelsLoading = createSelector(getBrandModelsState, state => !state.loaded);

export const getBrandModelDuplication = (brand: Microsoft.VideoIndexer.Contracts.BrandContractV2) =>
  createSelector(getBrands, brands => brands.filter(b => b.name === brand.name));

export const getBrandModelUrlDuplication = (brand: Microsoft.VideoIndexer.Contracts.BrandContractV2) =>
  createSelector(getBrands, brands => brands.filter(b => b.referenceUrl === brand.referenceUrl));
