import { IAction } from '@common/modules/shared/interfaces';
import { UIActionType, UIActionIcon } from '@common/modules/insights/interfaces';

export const backToBasic: IAction = {
  title: '',
  type: UIActionType.PREVIOUS,
  value: UIActionType.PREVIOUS,
  icon: UIActionIcon.PREVIOUS,
  selected: false,
  selectable: false,
  key: 'IndexingBackToBasicButton',
  id: 'backToBasicButton'
};

export const advancedSettings: IAction = {
  title: '',
  type: UIActionType.CLICK,
  value: '',
  icon: UIActionIcon.SETTINGS,
  selected: false,
  selectable: false,
  key: 'IndexingAdvancedSettingsButton',
  id: 'advancedSettingsButton'
};
