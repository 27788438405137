export enum KeyCode {
  Tab = '9',
  Enter = '13',
  ShiftLeft = '16',
  ESCAPE = '27',
  ArrowUp = '38',
  ArrowDown = '40'
}

export function getKeyCode(event: KeyboardEvent): string {
  if (!event) {
    return '';
  }

  const keyName = event.code || event.key;
  if (keyName !== undefined) {
    if (KeyCode.hasOwnProperty(keyName)) {
      return KeyCode[keyName];
    } else {
      throw new Error(`Key ${keyName} not exist at KeyCode table. Please add it`);
    }
  }
  // @todo - remove when Edge that to support event.code
  // eslint-disable-next-line deprecation/deprecation
  if (event.keyCode) {
    // eslint-disable-next-line deprecation/deprecation
    return event.keyCode.toString();
  }

  return '';
}

export function isMainButtonPressed(event: MouseEvent): boolean {
  // MouseEvent.button with 0 value means that the main button pressed.
  // It's usually the left button or the un-initialized state
  return event && event.button === 0;
}
