import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';

import { AccessibleFocusAreaComponent } from './accessible-focus-area/accessible-focus-area.component';
import { SubMenuHandlerDirective } from './sub-menu-handler.directive';
import { AccessibilityUtilsService } from './accessibility-utils.service';
import { AnnouncerDirective } from './announcer.directive';
import { AccessibilityTooltipDirective } from './accessibility-tooltip.directive';
import { FocusableComponent } from './focusable/focusable.component';
import { LoggerService } from '../core/services/logger/logger.service';
import { ContentOnHoverHandlerDirective } from './content-on-hover-handler.directive';
import { AccessibleClickDirective } from './accessible-click.directive';
import { AccessibleFocusTrap } from './accessibility-cdk-focus-trap.directive';

@NgModule({
  imports: [A11yModule],
  exports: [
    A11yModule,
    SubMenuHandlerDirective,
    AnnouncerDirective,
    AccessibilityTooltipDirective,
    AccessibleFocusAreaComponent,
    FocusableComponent,
    ContentOnHoverHandlerDirective,
    AccessibleClickDirective,
    AccessibleFocusTrap
  ],
  declarations: [
    AnnouncerDirective,
    AccessibleFocusAreaComponent,
    SubMenuHandlerDirective,
    AccessibilityTooltipDirective,
    FocusableComponent,
    ContentOnHoverHandlerDirective,
    AccessibleClickDirective,
    AccessibleFocusTrap
  ],
  providers: [AccessibilityUtilsService]
})
export class AccessibilityModule {
  constructor(private logger: LoggerService) {
    this.logger.log('[AccessibilityModule] init');
  }
}
