import { Params } from '@angular/router';

import { AccountPermission } from '@common/modules/shared/interfaces';

import { IHttpWrapper } from '../interfaces';
import { getRequestSettings } from '../utils/request.function';

import AmsMigrationsListResult = Microsoft.VideoIndexer.Contracts.AmsMigrationsListResult;
import AmsAccountMigration = Microsoft.VideoIndexer.Contracts.AmsAccountMigration;

export class AmsMigrationApi {
  constructor(private ApiWrapper: IHttpWrapper) {}

  // [HttpGet]
  public getStatus(accountId: string, reqParams?: Params, reqSettings?: object) {
    const url = `${this.apiOperationBase(reqParams?.location)}/accounts/${accountId}/AMSAssetsMigration`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, {
      permission: AccountPermission.CONTRIBUTOR,
      ...reqParams
    }) as object;

    return this.ApiWrapper.HttpClient.get<AmsAccountMigration>(url, requestSettings);
  }

  // [HttpGet]
  public getVideoMigrations(accountId: string, reqParams?: Params, reqSettings?: object) {
    const url = `${this.apiOperationBase()}/accounts/${accountId}/VideoAMSAssetMigrations`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings, {
      permission: AccountPermission.CONTRIBUTOR,
      ...reqParams
    }) as object;
    return this.ApiWrapper.HttpClient.get<AmsMigrationsListResult>(url, requestSettings);
  }

  private get apiInternalsBase(): string {
    return this.ApiWrapper.GetInternalApiBase();
  }

  private apiOperationBase(location?: string): string {
    return this.ApiWrapper.GetApiOperationsBase(null, location, true);
  }
}
