import { Action } from '@ngrx/store';

import { IUploadRequestParams } from '@common/modules/api/interfaces';
import { StreamingPreset } from '@common/modules/shared/interfaces';

import * as actions from '../../actions';
import { IFile, IIndexingSettings, ILanguage, IndexingLanguageType, UploadingState } from '../../../interfaces';

const EDGE_CUSTOM_LANGUAGE_IDS: Microsoft.VideoIndexer.Contracts.LanguageV2[] = [
  'ar-EG',
  'he-IL',
  'de-DE',
  'en-US',
  'fr-FR',
  'es-ES',
  'it-IT',
  'zh-Hans'
];
export const CONCURRENT_EDGE_UPLOADS = 1;

export const getEdgeUploadBatchActions = (idleFiles: IFile[], inProgressFilesCount: number): Action[] => {
  const uploadBatchActions = [];
  idleFiles
    .slice(0, CONCURRENT_EDGE_UPLOADS - inProgressFilesCount)
    .forEach(file =>
      uploadBatchActions.push(
        actions.updateFile({ fileId: file.id, file: { uploadingState: UploadingState.InProgress } }),
        actions.uploadFileToEdge({ storeFile: file })
      )
    );
  return uploadBatchActions;
};
export const getCustomEdgeLanguagesParam = (language: ILanguage, languages: Microsoft.VideoIndexer.Contracts.SupportedLanguageContract[]): string => {
  if (language.type === IndexingLanguageType.Auto) {
    const languageIdKeys = languages
      .filter(l => l.isAutoDetect)
      .map(l => l.languageCode)
      .filter(l => EDGE_CUSTOM_LANGUAGE_IDS.includes(l));
    return languageIdKeys.join(',');
  }
  return null;
};

export const convertToEdgeUploadParams = (
  indexingSettings: IIndexingSettings,
  language: Microsoft.VideoIndexer.Contracts.LanguageV2,
  storeFile: IFile,
  customLanguages: string
): Partial<IUploadRequestParams> => {
  return {
    name: storeFile.name,
    indexingPreset: indexingSettings.indexingPreset,
    streamingPreset: StreamingPreset.Default,
    language: language || 'en-US',
    description: indexingSettings.description,
    metadata: indexingSettings.metadata,
    ...(customLanguages && { customLanguages }),
    // in case of upload from url, we need to pass the url
    ...(storeFile.url && { videoUrl: storeFile.url })
  };
};
