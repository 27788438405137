import { Injectable, Optional } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { EventCategory, TrackService } from '@common/modules/core/services/track';

import { AuthService } from '../services/auth.service';
import { LoggerService } from '../../core/services/logger/logger.service';
import { CoreStoreService } from '../../../../apps/web/src/core/services/core-store.service';

@Injectable()
export class AuthSessionService {
  constructor(
    public auth: AuthService,
    private logger: LoggerService,
    @Optional() private coreStore: CoreStoreService,
    private trackService: TrackService
  ) {
    this.logger.log('[AuthSessionService] init');
  }

  public tryRefreshSession(): Observable<boolean> {
    return this.auth.refreshSession().pipe(
      switchMap(() => {
        return this.auth.checkIfUserAuthenticated().pipe(
          take(1),
          map((isUserAuthenticated: boolean) => {
            if (isUserAuthenticated) {
              this.trackService.track('auth_session_service.error.refresh_session_fallback.refresh_success', {
                category: EventCategory.AUTH
              });
              this.logger.log('[AuthSessionService] refresh cookie success');
              this.auth.login();
              return true;
            } else {
              this.trackService.track('auth_session_service.error.refresh_session_fallback.refresh_failed', {
                category: EventCategory.AUTH
              });
              this.logger.log('[AuthSessionService] refresh cookie failed user not authenticated');
              this.auth.logout();
              this.coreStore?.sessionExpired();
              return false;
            }
          })
        );
      }),
      catchError(err => {
        this.trackService.track('auth_session_service.error.refresh_session_fallback.refresh_failed', {
          category: EventCategory.AUTH
        });
        this.logger.log('[AuthSessionService] refresh cookie error');
        this.auth.logout();
        this.coreStore?.sessionExpired();
        // eslint-disable-next-line deprecation/deprecation
        return throwError(err);
      })
    );
  }
}
