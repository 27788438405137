import { IAction, UIActionTypeOptions } from '../shared/interfaces';
import { ITimelineSegment } from '../timeline/interfaces';
import { UIFilterType } from '../shared/components/filter-item/interfaces';

export interface IUIVideoHeader {
  thumbnail: string;
  name: string;
  duration: number;
  privacyMode: Microsoft.VideoIndexer.Contracts.PrivacyMode;
  isTimelineCollapsed: boolean;
  actionMenu: any;
  publishedUrl: string;
  viewToken: string;
  publishedProxyUrl: string;
  contentLoadState: UITimelineContentLoadState;
  selectedSegments: ITimelineSegment;
  durationOffset: number;
  state: string;
  accountId: string;
  errorType?: VideoErrorType;
}

export enum VideoErrorType {
  INDEX = 'INDEX',
  STREAMING = 'STREAMING'
}

export interface IUIVideoTimeline {
  playlistId: string;
  videos: Microsoft.VideoIndexer.Contracts.VideoContract[];
  videosRanges: Microsoft.VideoIndexer.Contracts.VideoRange[];
  isBase: boolean;
  dir: string;
  durationInSeconds: number;
}

export interface IUIVideoItem {
  id: string;
  uniqueId: string;
  videoHeader: IUIVideoHeader;
  videoTimeline?: IUIVideoTimeline;
}

export interface IVideoExpandedState {
  id: string;
  isCollapsed: boolean;
}

export enum UIEditorActionType {
  DELETE_VIDEO = 'DELETE_VIDEO',
  SELECT_ALL = 'SELECT_ALL',
  DESELECT_ALL = 'DESELECT_ALL',
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  ALL_INSIGHTS_FILTER = 'ALL_INSIGHTS_FILTER',
  PEOPLE_FILTER = 'PEOPLE_FILTER',
  TOPICS_FILTER = 'TOPICS_FILTER',
  KEYWORDS_FILTER = 'KEYWORDS_FILTER',
  EMOTIONS_FILTER = 'EMOTIONS_FILTER',
  SENTIMENTS_FILTER = 'SENTIMENTS_FILTER',
  LABELS_FILTER = 'LABELS_FILTER',
  SPEAKERS_FILTER = 'SPEAKERS_FILTER',
  TRANSCRIPT_FILTER = 'TRANSCRIPT_FILTER',
  OCR_FILTER = 'OCR_FILTER',
  NAMED_ENTITIES_FILTER = 'NAMED_ENTITIES_FILTER',
  ACOUSTIC_EVENT_FILTER = 'ACOUSTIC_EVENT_FILTER',
  TIME_FILTER = 'TIME_FILTER',
  INCLUDE_FILTER = 'INCLUDE_FILTER',
  EXCLUDE_FILTER = 'EXCLUDE_FILTER',
  BACK = 'BACK',
  CHANGE_PRIVACY = 'CHANGE_PRIVACY',
  DOWNLOAD = 'DOWNLOAD',
  SAVE = 'SAVE',
  PUBLISH = 'PUBLISH',
  SEARCH_PEOPLE_FILTER = 'SEARCH_PEOPLE_FILTER',
  SEARCH_CHANNEL_FILTER = 'SEARCH_CHANNEL_FILTER',
  SEARCH_OWNER_FILTER = 'SEARCH_OWNER_FILTER',
  RENDER_AND_DOWNLAD = 'RENDER_AND_DOWNLAD',
  INDEXING_PRESET_DEFAULT = 'INDEXING_PRESET_DEFAULT',
  INDEXING_PRESET_AUDIO_ONLY = 'INDEXING_PRESET_AUDIO_ONLY',
  INDEXING_PRESET_NOISE_REDUCE = 'INDEXING_PRESET_NOISE_REDUCE',
  INDEXING_PRESET_VIDEO_ONLY = 'INDEXING_PRESET_VIDEO_ONLY',
  INDEXING_STREAMING_SINGLE_BITRATE = 'INDEXING_STREAMING_SINGLE_BITRATE',
  INDEXING_STREAMING_ADAPTIVE_BITRATE = 'INDEXING_STREAMING_ADAPTIVE_BITRATE',
  INDEXING_STREAMING_NO = 'INDEXING_STREAMING_NO',
  PROCEED_RENDER = 'PROCEED_RENDER',
  CANCEL_DOWNLOAD = 'CANCEL_DOWNLOAD',
  CANCEL_RENDER = 'CANCEL_RENDER',
  REMOVE_USER_FEEDBACK_STRIP = 'REMOVE_USER_FEEDBACK_STRIP',
  BRANDS_FILTER = 'BRANDS_FILTER',
  NAMED_PEOPLE_FILTER = 'NAMED_PEOPLE_FILTER',
  NAMED_LOCATIONS_FILTER = 'NAMED_LOCATIONS_FILTER'
}

export enum UIEditorFilterType {
  DROP_DOWN = 'DROP_DOWN',
  TEXT = 'TEXT',
  TIME = 'TIME'
}

export enum UITimelineContentLoadState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  LOAD_ERROR = 'LOAD_ERROR'
}

export enum UITimelineScrollItemSelectState {
  NOT_SELECTED = 'NOT_SELECTED',
  SEMI_HOVER = 'SEMI_HOVER',
  SEMI_SELECTED = 'SEMI_SELECTED',
  SELECTED = 'SELECTED'
}

export type IncludeExcludeType = UIEditorActionType.INCLUDE_FILTER | UIEditorActionType.EXCLUDE_FILTER;

export enum UIProjectRenderStatus {
  NOT_STARTED,
  IN_PROGRESS,
  SUCCEEDED,
  SUCCEEDED_NOT_SHOW_STRIP
}

export interface IUIEditorFilterItem {
  id: string;
  index: number;
  includeExcludeType: IncludeExcludeType;
  actionType: UIActionTypeOptions;
  text: string[];
  value: any[];
}

export interface IUIFilterItem {
  id: string;
  index: number;
  actionType: UIActionTypeOptions;
  text: string;
  value: any;
}

export interface IUIFilterIssue {
  placeHolderText: string;
  placeHolderKey: string;
  filterAction: IAction;
  type: UIFilterType;
  enableMultipleSelection?: boolean; // Relevant only for UIFilterType = DROP_DOWN
  placeHolderIcon?: string;
}

export interface IAppearance {
  from: number;
  to: number;
}

export interface ITimeFilter {
  includeExcludeType: IncludeExcludeType;
  appearances: IAppearance[];
  insightType: UIActionTypeOptions;
}

export interface ITextFilter {
  includeExcludeType: IncludeExcludeType;
  insightType: UIActionTypeOptions;
  wordArr: string[];
}

export interface IEditorFilterForTimeline {
  timeFilters: ITimeFilter[];
  textFilters: ITextFilter[];
}

export interface IUIInsightsDataToFilter {
  people: IAction[];
  namedEntities: IAction[];
  labels: IAction[];
  keywords: IAction[];
  speakers: IAction[];
  emotions: IAction[];
  acousticEvents: IAction[];
}

export interface IAddVideosParams {
  accountId: string;
  topicsFilter: string;
  sourceLanguage?: string;
}

export interface IVideoSelectAllScrollItemsToggle {
  uniqueId: string;
  isSelected: boolean;
}

export interface CapsuleInsights {
  subType?: string;
  name?: string;
  text?: string;
  id: number;
  instances: Microsoft.VideoIndexer.Contracts.ElementInstance[];
}

export enum AriaLivePolitenessEnum {
  OFF = 'off',
  POLITE = 'polite',
  ASSERTIVE = 'assertive'
}
