import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { TranslateHelperService } from '@common/modules/translation/services/translate-helper.service';

import { IAction } from '../../../shared/interfaces';
import { UIActionType, UIActionIcon } from '../../../insights/interfaces';
import { FocusableComponent } from '../../../accessibility/focusable/focusable.component';
import { ActionButtonType } from './interfaces';
import { resources } from './resources';

@Component({
  selector: 'app-vi-action-button',
  templateUrl: './action-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent extends FocusableComponent implements OnInit, AfterViewInit {
  // Input
  @Input() public action: IAction;

  // If to render opener or not. used for action menus.
  @Input() public hasOpenerIcon = false;

  // the height of the action button
  @Input() public height = null;

  // the width of the action button
  @Input() public width = 'auto';

  @Input() public maxWidth = 'auto';

  @Input() public minWidth = 'auto';

  // If we show the opener up or down
  @Input() public expanded = false;

  // size class [small/default]
  @Input() public sizeClass = '';

  // If button is internal and responsive hide its title
  // and opener if the screen is medium.
  @Input() public internal = false;

  // Is the button disabled or not.
  @Input() public isDisabled = false;

  // if the button already has a notification tooltip (hasTooltip = true), hide the title attribute.
  @Input() public hasNotificationTooltip = false;

  @Input() public type: ActionButtonType;

  @Input() public selected = false;

  // For tabindex
  @Input() public activeContainer = false;

  // if the button controls an action menu we link it with the aria-owns attribute
  @Input() public ariaOwns?: string;

  @Input() public showAccessibilityTooltip = false;

  // Fallback url for image
  @Input() public fallbackUrl?: string;

  // Id of button
  @Input() public uid?: string;

  // Specific Class
  @Input() public class = '';

  // loading state - Show spinner at the image location
  @Input() public isLoading = false;

  @Input() public whiteFocus = false;

  // represents a class that defines the button's breakpoints.
  @Input() public responsiveState = '';

  @Input() public ariaLabelledBy?: string;

  @Input() public isLink = false;

  // show the button as focused when init. only for init so no need manage the focus state.
  @Input() focusOnInit = false;

  // Output
  @Output() public actionClick = new EventEmitter<IAction>();
  @Output() public mouseOver = new EventEmitter<IAction>();
  @Output() public mouseLeave = new EventEmitter<IAction>();
  @Output() public focusIn = new EventEmitter<IAction>();

  @ViewChild('button', { static: true }) button: ElementRef<HTMLButtonElement>;

  public mainActionIcon: string;
  public UIActionType = UIActionType;
  public UIActionIcon = UIActionIcon;
  public resources = resources;

  constructor(private translate: TranslateHelperService) {
    super();
  }

  public ngOnInit() {
    this.mainActionIcon = this.action ? this.action.icon : '';
    if (!this.uid) {
      this.uid = this.action ? this.action.id : '';
    }

    this.translate.translateResourcesInstant(resources);
  }

  public ngAfterViewInit() {
    if (this.focusOnInit) {
      this.button.nativeElement.focus();
    }
  }

  public selectAction(action: IAction, e: MouseEvent) {
    this.actionClick.emit(action);
  }

  public handleMouseOver() {
    this.mouseOver.emit();
  }

  public handleMouseLeave() {
    this.mouseLeave.emit();
  }

  public handleFocusIn() {
    this.focusIn.emit();
  }

  public isUIActionType(actionType: UIActionType): boolean {
    return this.action?.type === actionType;
  }
}
