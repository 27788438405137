import { IHttpWrapper } from '@common/modules/api/interfaces';

import { getRequestSettings } from '../../utils/request.function';

export class CustomizationLogoGroupsApi {
  private apiBaseRoute = '/accounts';
  constructor(private ApiWrapper: IHttpWrapper) {}

  private get apiOperationBase(): string {
    return this.ApiWrapper.GetApiOperationsBase(null, null, true) + this.apiBaseRoute;
  }

  // [HttpGet]
  // [Route("Api/v2/Accounts/{accountId}/Customization/CustomLogos/Groups")]
  public list(accountId: string, reqSettings?: object) {
    const url = `${this.apiOperationBase}/${accountId}/Customization/CustomLogos/Groups`;
    const requestSettings = getRequestSettings(this.ApiWrapper.Cache, reqSettings) as object;

    // eslint-disable-next-line max-len
    return this.ApiWrapper.HttpClient.get<Microsoft.VideoIndexer.Contracts.Customization.LogoGroupContract[]>(url, requestSettings);
  }
}
