import { CustomizationPage } from '@common/modules/core/services/interfaces';
import { UIActionType } from '@common/modules/insights/interfaces';
import { IAction } from '@common/modules/shared/interfaces';

import { UIIndexingActionType } from '../interfaces';

export const peopleModelAction: IAction = {
  title: '',
  value: UIIndexingActionType.CUSTOMIZATION_PEOPLE_MODEL,
  type: UIIndexingActionType.CUSTOMIZATION_PEOPLE_MODEL,
  selected: false,
  selectable: false,
  id: 'peopleModel'
};

export const managePeopleModelsAction: IAction = {
  title: '',
  key: 'ManagePeopleModels',
  type: UIActionType.NAVIGATE,
  value: CustomizationPage.People,
  id: 'managePeopleModelsButton',
  icon: 'i-vi-customization'
};
