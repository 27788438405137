import { createReducer, Action, on } from '@ngrx/store';

import { CustomizationPage } from '@common/modules/core/services/interfaces';

import * as CustomizationViewActions from '../actions/customization-view.actions';

export interface IState {
  // additional entities state properties
  selectedPage: CustomizationPage;
}

export const initialState: IState = {
  // additional entity state properties
  selectedPage: null
};

const viewReducer = createReducer(
  initialState,
  on(CustomizationViewActions.initPage, (state, { page }) => {
    return {
      ...state,
      selectedPage: page
    };
  })
);

export function reducer(state: IState | undefined, action: Action) {
  return viewReducer(state, action);
}
