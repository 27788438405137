import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavBarItem } from './interfaces';

@Component({
  selector: 'vi-ui-nav-bar',
  templateUrl: './nav-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Input() public items: NavBarItem[];
  @Input() public selectedItemId?: string;

  @Output() public itemSelected = new EventEmitter<string>();
  @Output() public itemExpanded = new EventEmitter<string>();

  public isFlat: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    this.isFlat = !this.items.some((item: NavBarItem) => item.children !== undefined);
  }

  public onSelectItem(selectedItem: NavBarItem) {
    if (selectedItem.children !== undefined) {
      this.toggleItem(selectedItem);
      return;
    }

    this.selectedItemId = selectedItem.id;
    this.itemSelected.emit(this.selectedItemId);
    this.cdr.detectChanges();
  }

  public toggleItem(itemToToggle: NavBarItem) {
    itemToToggle.expanded = !itemToToggle.expanded;

    if (itemToToggle.expanded) {
      this.itemExpanded.emit(itemToToggle.id);
    }

    this.cdr.detectChanges();
  }
}
